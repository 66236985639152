import { IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import { useDevice } from '../hooks/useDevice';
import { useDynamicBanner } from '../hooks/useDynamicBanner';

interface BannerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  bannerClickListener?: () => void;
}

const DynamicBanner: React.FC<BannerProps> = ({
  logAnalyticsEngagement,
  bannerClickListener,
}) => {
  const { isApp } = useDevice();
  const dynamicBannerContent = useDynamicBanner();

  return dynamicBannerContent ? (
    <IonRow
      className="header-promotion header-promotion-mobile"
      onClick={async () => {
        bannerClickListener && bannerClickListener();
        logAnalyticsEngagement &&
          (await logAnalyticsEngagement(AnalyticsEngagementId.PROMOTION));
      }}
      style={{
        backgroundColor: 'var(--ion-color-tertiary-shade)',
        height: isApp ? 100 : undefined,
        paddingLeft: '15px',
        paddingRight: '15px',
        position: 'relative',
        zIndex: 10,
      }}
    >
      {typeof dynamicBannerContent === 'string' ? (
        <p style={isApp ? { marginTop: 50 } : {}}>{dynamicBannerContent}</p>
      ) : (
        dynamicBannerContent
      )}
    </IonRow>
  ) : null;
};

export default DynamicBanner;
