import { Deal } from '@faredrop/graphql-sdk';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';

interface State {
  isInitialized: boolean;
  notifications: Deal[];
}

export interface NotificationContextValue extends State {
  clearNotifications: () => Promise<void>;
}

interface NotificationProviderProps {
  children: ReactNode;
}

type InitializePayload = {
  isInitialized: boolean;
  notifications: Deal[];
};

type ClearPayload = {
  notifications: Deal[];
};

enum NotificationActionType {
  Initialize = 'INITIALIZE',
  Clear = 'CLEAR',
}

type NotificationAction = {
  type: NotificationActionType;
  payload?: InitializePayload | ClearPayload;
};

const initialState: State = {
  isInitialized: false,
  notifications: [],
};

const reducer = (state: State, action: NotificationAction): State => {
  switch (action.type) {
    case NotificationActionType.Initialize: {
      const payload: InitializePayload = action.payload as InitializePayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
    case NotificationActionType.Clear: {
      const payload: ClearPayload = action.payload as ClearPayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
  }
};

const NotificationContext = createContext<NotificationContextValue>({
  ...initialState,
  clearNotifications: () => Promise.resolve(),
});

export const NotificationProvider: FC<NotificationProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    try {
      dispatch({
        type: NotificationActionType.Initialize,
        payload: {
          isInitialized: true,
          // TODO: initialize to something not empty for the badge to pop up on the fab button
          notifications: [],
        },
      });
    } catch (error) {
      dispatch({
        type: NotificationActionType.Initialize,
        payload: {
          isInitialized: false,
          notifications: [],
        },
      });
    }
  }, []);

  const clearNotifications = async () => {
    dispatch({
      type: NotificationActionType.Clear,
      payload: {
        notifications: [],
      },
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        clearNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
