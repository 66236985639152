// cspell:ignore ABCEGHJKLMNPRSTVXY ABCEGHJ NPRSTV iatas

import { Airport, AirportBase, FareDropApiClient } from '@faredrop/graphql-sdk';
import { SupportedCountryCodes } from '@faredrop/types';
import {
  airportIATAsToBeDeprecated,
  findDistanceInMilesFromLatLng,
  getAssetsBaseUrl,
} from '@faredrop/utilities';
import FlexSearch, { Index } from 'flexsearch';
import deburr from 'lodash/deburr';
import { FC, ReactNode, useRef } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { $enum } from 'ts-enum-util';
import useAnalytics from '../hooks/analytics';
import useAuth from '../hooks/auth';
import useUser from '../hooks/user';
import { GeocoderOption, DropdownOptionWithSubtext } from '../types/types';

interface State {
  isInitialized: boolean;
  origins: Airport[];
  destinations: Airport[];
  searchingAirports: boolean;
}

interface ISearchAirportsOptions {
  originsOnly?: boolean;
  destinationsOnly?: boolean;
  domesticOnly?: boolean;
  useMapBoxFallback?: boolean;
}

export interface AirportSearchContextValue extends State {
  searchAirports: (
    searchText: string,
    options?: ISearchAirportsOptions
  ) => Promise<GeocoderOption[]>;
  findClosestHighTierAirport: (
    iata: string,
    domesticOnly?: boolean
  ) => Promise<string | undefined>;
  getLocalOrigins: () => Promise<Airport[]>;
  getAirport: (iata: string) => Promise<Airport | undefined>;
}

interface AirportSearchProviderProps {
  children: ReactNode;
}

type InitializePayload = {
  isInitialized: boolean;
  origins: Airport[];
  destinations: Airport[];
};

type SearchingAirportsPayload = {
  searchingAirports: boolean;
};

enum AirportSearchActionType {
  Initialize = 'INITIALIZE',
  SearchingAirports = 'SEARCHING_AIRPORTS',
}

type GeocoderResult = {
  properties: {
    category?: string;
  };
  text: string;
  center: number[];
  place_name: string;
};

type AirportSearchAction = {
  type: AirportSearchActionType;
  payload?: InitializePayload | SearchingAirportsPayload;
};

const initialState: State = {
  isInitialized: false,
  origins: [],
  destinations: [],
  // This initial value of true will prevent the "no results" text from showing up
  // until the first search for airports has completed
  searchingAirports: true,
};

export const formatSubAirportsString = (airport: Airport) => {
  let str = ``;
  // For each subAirport, grab the IATA and append to list of IATAs
  airport.subAirports?.map((subAirport) => {
    str = `${str}${subAirport.iata}, `;
  });
  return `${airport.name} (${str.slice(0, -2)})`;
};

interface IFormatGeoCoderOptionOptions {
  useParentAirports?: boolean;
}
export const formatGeoCoderOption = (
  option: GeocoderOption,
  options?: IFormatGeoCoderOptionOptions
) => {
  // If region (i.e. CHI), format entry with pattern: NAME (IATA1, IATA2, IATA3, ...)
  return !options?.useParentAirports && option.airport?.subAirports != null
    ? ({
        text: formatSubAirportsString(option.airport),
        subtext: option.subtext,
      } as DropdownOptionWithSubtext)
    : ({
        text:
          option.airport?.name && option.airport?.iata
            ? `${option.airport?.name} (${option.airport?.iata})`
            : undefined,
        subtext: option.subtext,
      } as DropdownOptionWithSubtext);
};

const deburrAndFormatAirportText = (name: string) => {
  return deburr(name)
    .toLowerCase()
    .replace(/[^\w\s]|_/g, '')
    .replace(/\s+/g, '');
};

const reducer = (state: State, action: AirportSearchAction): State => {
  switch (action.type) {
    case AirportSearchActionType.Initialize: {
      const payload: InitializePayload = action.payload as InitializePayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
    case AirportSearchActionType.SearchingAirports: {
      const payload: SearchingAirportsPayload =
        action.payload as SearchingAirportsPayload;

      return {
        ...state,
        ...payload,
        searchingAirports: payload.searchingAirports,
      };
    }
  }
};

const buildAirportIndex = (airports: Airport[], index: Index<unknown>) => {
  return airports.map((airport: Airport, i: number) => {
    let indexString = '';
    // If an origin has subAirports, add each subAirport's name and IATA to the index
    if (airport.subAirports) {
      indexString = airport.iata;
      airport.subAirports.map((subAirport: AirportBase) => {
        indexString = `${indexString} ${subAirport.iata} ${subAirport.name}`;
      });
    } else {
      indexString = `${airport.iata} ${airport.name}`;
    }
    index.add(i, `${indexString} ${airport.city} ${airport.country}`);
  });
};

const mergeAirports = (a: Airport[], b: Airport[]) => {
  const airports = [...a];
  b.forEach((b) => {
    if (!airports.some((a) => a.iata === b.iata)) {
      airports.push(b);
    }
  });
  return airports;
};

const getDomesticAirports = (airports: Airport[], homeCountryCode?: string) => {
  return airports.filter((a) => {
    a.countryCode !== homeCountryCode;
  });
};

const AirportSearchContext = createContext<AirportSearchContextValue>({
  ...initialState,
  searchAirports: () => Promise.resolve([]),
  findClosestHighTierAirport: () => Promise.resolve(undefined),
  getLocalOrigins: () => Promise.resolve([]),
  getAirport: () => Promise.resolve(undefined),
});

export const AirportSearchProvider: FC<AirportSearchProviderProps> = (
  props
) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getIdToken, isAuthenticated, isInitialized } = useAuth();
  const { logAnalyticsError } = useAnalytics();
  const userState = useUser();

  const airportsIndex = useRef<Index<number>>();
  const originsIndex = useRef<Index<number>>();
  const destinationsIndex = useRef<Index<number>>();
  const domesticIndex = useRef<Index<number>>();
  const domesticOriginIndex = useRef<Index<number>>();
  const domesticDestinationIndex = useRef<Index<number>>();

  const getOriginsAndDestinations = async () => {
    try {
      let destinations: Airport[] = [];
      let origins: Airport[] = [];
      if (isAuthenticated) {
        const gqlClient = FareDropApiClient(getIdToken);
        destinations = (await gqlClient.destinations()).data.destinations;
        origins = (await gqlClient.origins()).data.origins;
      } else {
        origins = await getLocalOrigins();
      }

      // Filter out soon to be deprecated origins
      origins = origins.filter(
        (airport) => !airportIATAsToBeDeprecated.includes(airport.iata)
      );

      return { origins, destinations } as const;
    } catch (error) {
      await logAnalyticsError('getOriginsAndDestinations');
      // No need to litter with these if due to being not logged in
      if ((error as Error).name !== 'AuthenticationError') {
        throw error;
      }

      return { origins: [] as Airport[], destination: [] as Airport[] };
    }
  };

  const getLocalOrigins = async () => {
    const raw = await fetch(`${getAssetsBaseUrl()}/data/airports/origins.json`);
    const json = await raw.json();
    if (Array.isArray(json?.data?.origins)) {
      return json.data.origins as Airport[];
    } else {
      throw new Error(`Invalid return type for origins. ${json}`);
    }
  };

  const getAirport = async (iata: string) => {
    const origins = state.origins ?? (await getLocalOrigins());
    return origins.find((o) => o.iata === iata);
  };

  useEffect(() => {
    const initFlexsearch = async (): Promise<void> => {
      airportsIndex.current = FlexSearch.create({
        encode: 'advanced',
        tokenize: 'full',
      });
      originsIndex.current = FlexSearch.create({
        encode: 'advanced',
        tokenize: 'full',
      });
      domesticIndex.current = FlexSearch.create({
        encode: 'advanced',
        tokenize: 'full',
      });

      const { origins, destinations } = await getOriginsAndDestinations();

      if (!origins || origins.length == 0) {
        dispatch({
          type: AirportSearchActionType.Initialize,
          payload: {
            isInitialized: false,
            origins: [],
            destinations: [],
          },
        });
        return;
      }

      const airports = mergeAirports(origins, destinations ?? []);
      buildAirportIndex(airports, airportsIndex.current);
      buildAirportIndex(origins, originsIndex.current);
      buildAirportIndex(
        getDomesticAirports(airports, userState.homeCountryCode),
        domesticIndex.current
      );
      if (destinations) {
        destinationsIndex.current = FlexSearch.create({
          encode: 'advanced',
          tokenize: 'full',
        });
        buildAirportIndex(destinations, destinationsIndex.current);

        domesticDestinationIndex.current = FlexSearch.create({
          encode: 'advanced',
          tokenize: 'full',
        });
        buildAirportIndex(
          getDomesticAirports(destinations, userState.homeCountryCode),
          domesticDestinationIndex.current
        );
      }

      dispatch({
        type: AirportSearchActionType.Initialize,
        payload: {
          isInitialized: true,
          origins,
          destinations: destinations ?? [],
        },
      });
    };

    if (isInitialized) {
      initFlexsearch().catch((error) => {
        console.warn('Failed to initialize airport flex search', error);
        throw error; // Show uh-oh screen
      });
    }
  }, [isInitialized, isAuthenticated]);

  // Build domestic origin index
  useEffect(() => {
    const index = FlexSearch.create<number>({
      encode: 'advanced',
      tokenize: 'full',
    });
    const domesticOrigins = getDomesticAirports(
      state.origins,
      userState.homeCountryCode
    );
    buildAirportIndex(domesticOrigins, index);
    domesticOriginIndex.current = index;
  }, [userState.homeCountryCode, state.origins]);

  const findClosestHighTierAirport = async (
    iata: string,
    domesticOnly = false
  ) => {
    const origins = domesticOnly
      ? getDomesticAirports(state.origins, userState.homeCountryCode)
      : state.origins;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const inputAirport = origins.find((origin) => origin.iata === iata)!;

    let shortestDistance = 1000000;
    let closestSupportedOrigin: Airport | undefined;

    origins
      .filter((origin) => origin.tier && origin.tier <= 2)
      .forEach((origin) => {
        const distanceFromSupposedOrigin = findDistanceInMilesFromLatLng(
          origin.lat ?? 0,
          origin.lng ?? 0,
          inputAirport.lat ?? 0,
          inputAirport.lng ?? 0
        );

        if (
          distanceFromSupposedOrigin &&
          distanceFromSupposedOrigin < shortestDistance
        ) {
          shortestDistance = distanceFromSupposedOrigin;
          closestSupportedOrigin = origin;
        }
      });

    // If the airport is within 240 miles of search result (< 4 hours of driving), return result
    if (closestSupportedOrigin && shortestDistance < 300) {
      return closestSupportedOrigin.iata;
    } else {
      return undefined;
    }
  };

  const searchAirports = async (
    searchText: string,
    options?: ISearchAirportsOptions
  ) => {
    const useDomesticData = options?.domesticOnly && userState.homeCountryCode;

    if (options?.originsOnly && options?.destinationsOnly) {
      throw new Error('Cannot set originsOnly and destinationsOnly');
    }

    dispatch({
      type: AirportSearchActionType.SearchingAirports,
      payload: {
        searchingAirports: true,
      },
    });

    let index: Index<number> | undefined = airportsIndex.current;
    if (useDomesticData) {
      if (options.originsOnly) {
        index = domesticOriginIndex.current;
      } else if (options.destinationsOnly) {
        index = domesticDestinationIndex.current;
      } else {
        index = domesticIndex.current;
      }
    } else {
      if (options?.originsOnly) {
        index = originsIndex.current;
      } else if (options?.destinationsOnly) {
        index = destinationsIndex.current;
      }
    }
    const searchResults = (await index?.search(searchText, {
      limit: 5,
      suggest: true,
    })) as number[];

    const finalResults: {
      supposedAirport: string;
      distance: number;
      airport: string;
      iata: string;
    }[] = [];

    let airports: Airport[] = [];
    if (useDomesticData) {
      if (options.originsOnly) {
        airports = getDomesticAirports(
          state.origins,
          userState.homeCountryCode
        );
      } else if (options.destinationsOnly) {
        airports = getDomesticAirports(
          state.destinations,
          userState.homeCountryCode
        );
      } else {
        airports = mergeAirports(
          getDomesticAirports(state.origins, userState.homeCountryCode),
          getDomesticAirports(state.destinations, userState.homeCountryCode)
        );
      }
    } else {
      if (options?.originsOnly) {
        airports = state.origins;
      } else if (options?.destinationsOnly) {
        airports = state.destinations;
      } else {
        airports = mergeAirports(state.origins, state.destinations);
      }
    }

    const unsortedAirportOptions = (searchResults ?? []).map(
      (i) => airports[i]
    ) as Airport[];

    // Give options weight based on IATA, city, and name matches with searchText
    const optionsWithWeights = unsortedAirportOptions.map((airport) => {
      return {
        airport,
        weight: +[
          // We weight IATA a little bit higher than the other fields, but not high enough to override multiple "hits"
          // Check airport IATA as well as all subAirport IATAs (if they exist)
          +(
            airport.iata
              .toLowerCase()
              .startsWith(searchText.toLowerCase().trim()) ||
            airport.subAirports?.find((subAirport: AirportBase) => {
              return subAirport.iata
                .toLowerCase()
                .startsWith(searchText.toLowerCase().trim());
            }) !== undefined
          ) * 3.0,
          +deburr(airport.city)
            .toLowerCase()
            .includes(deburr(searchText).toLowerCase().trim()),
          // For the full text search, we remove all white space and punctuation in addition to lower casing the strings to help our chances of a direct hit for sorting purposes
          // Check airport name as well as all subAirport names (if they exist)
          +(
            deburrAndFormatAirportText(airport.name ?? '').includes(
              deburrAndFormatAirportText(searchText)
            ) ||
            airport.subAirports?.find((subAirport: AirportBase) => {
              // Grab airport name from subAirport string, deburr and format, and check if includes deburr-ed/formatted searchText
              return deburrAndFormatAirportText(subAirport.name ?? '').includes(
                deburrAndFormatAirportText(searchText)
              );
            }) !== undefined
          ),
        ].reduce((a, b) => a + b, 0),
      };
    });

    // Sort options by weight
    const sortedOptions = optionsWithWeights
      .sort(
        (
          a: { airport: Airport; weight: number },
          b: { airport: Airport; weight: number }
        ) => {
          // Sort by "weight" which is if we have a direct match on the iata, icao, city, or airport name in the search string
          // Next we check to see if there is an IATA code - this will be an airport that is recognized at an international level
          return (
            b.weight - a.weight ||
            +(b.airport.iata.length > 0) - +(b.airport.iata.length > 0)
          );
        }
      )
      .map((option) => option.airport);

    // If our flexSearch returns an empty list of results, let's see if we can leverage
    // the MapBox geocoder to find closest airport to related search text results
    if (
      options?.useMapBoxFallback &&
      sortedOptions.length < 5 &&
      searchText.length > 2
    ) {
      const supportedCountries = useDomesticData
        ? [userState.homeCountryCode]
        : $enum(SupportedCountryCodes).getValues();
      const searchCountries = supportedCountries.join(',');

      // Search searchText for type=place,region,country,poi and country=US and grab top 5 results
      let types = 'place,region,country';
      // If the searchText resembles a US or CA postCode, set the search type to postcode
      if (
        searchText.match(
          /(\d{5}([ -]\d{4})?)|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d)|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])/g
        )
      ) {
        types = 'postcode';
      }

      const geocoderResults: GeocoderResult[] =
        (
          await (
            await fetch(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                searchText
              )}.json?limit=5&types=${types}&country=${searchCountries}&access_token=${
                process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
              }`
            )
          ).json()
        ).features ?? [];

      if (
        types !== 'postcode' &&
        !geocoderResults.some((result) => {
          return (
            result.place_name
              .replace(', United States', '')
              .replace(',', '')
              .toUpperCase() === searchText.replace(',', '').toUpperCase()
          );
        })
      ) {
        for (const result of geocoderResults) {
          let shortestDistance = 1000000;
          let closestSupportedAirport: Airport | undefined;

          airports.forEach((airport) => {
            // Pythagorean theorem
            if (!result || !result.center || result.center.length == 0) {
              return;
            }

            const distanceFromSupposedAirport = findDistanceInMilesFromLatLng(
              airport.lat ?? 0,
              airport.lng ?? 0,
              result.center[1],
              result.center[0]
            );

            if (
              distanceFromSupposedAirport &&
              distanceFromSupposedAirport < shortestDistance
            ) {
              shortestDistance = distanceFromSupposedAirport;
              closestSupportedAirport = airport;
            }
          });

          // If the airport is within 240 miles of search result (< 4 hours of driving), return result
          if (closestSupportedAirport && shortestDistance < 240) {
            finalResults.push({
              supposedAirport: result.place_name.replace(', United States', ''),
              distance: shortestDistance,
              airport: `${closestSupportedAirport.name} (${closestSupportedAirport.iata})`,
              iata: closestSupportedAirport.iata,
            });
          }
        }
      } else {
        // If search type is postcode (i.e. ZIP code) or we have an exact match, show all results in range
        let result: GeocoderResult;
        if (geocoderResults.length == 1) {
          result = geocoderResults[0];
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          result = geocoderResults.find((result) => {
            return (
              result.place_name
                .replace(', United States', '')
                .replace(',', '')
                .toUpperCase() === searchText.replace(',', '').toUpperCase()
            );
          })!;
        }

        airports.forEach((airport) => {
          // Pythagorean theorem
          if (!result || !result.center || result.center.length == 0) {
            return;
          }

          const distanceFromSupposedAirport = findDistanceInMilesFromLatLng(
            airport.lat ?? 0,
            airport.lng ?? 0,
            result.center[1],
            result?.center[0]
          );

          if (
            distanceFromSupposedAirport &&
            distanceFromSupposedAirport < 240
          ) {
            finalResults.push({
              supposedAirport: result.place_name.replace(', United States', ''),
              distance: distanceFromSupposedAirport,
              airport: `${airport.name} (${airport.iata})`,
              iata: airport.iata,
            });
          }
        });
      }

      const geocoderOptions = finalResults
        .sort((a, b) => {
          if (a.distance < b.distance) {
            return -1;
          }

          return 1;
        })
        .map((result) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const airport = airports.find(
            (origin) => origin.iata === result.iata
          )! as Airport;

          return {
            airport,
            subtext: `${Math.round(result.distance)} miles from ${
              result.supposedAirport
            }`,
          } as GeocoderOption;
        });

      // Remove duplicates
      const existingIataResults = new Set();
      const nonDuplicateOptions: GeocoderOption[] = [];
      geocoderOptions.map((option) => {
        if (
          !sortedOptions.some((o) => o.iata === option.airport?.iata) &&
          !existingIataResults.has(option.airport?.iata)
        ) {
          nonDuplicateOptions.push(option);
          existingIataResults.add(option.airport?.iata);
        }
      });

      const iatas: string[] = [];
      const nonDuplicateSortedOptions: Airport[] = [];
      sortedOptions.map((option) => {
        if (!iatas.includes(option.iata)) {
          iatas.push(option.iata);
          nonDuplicateSortedOptions.push(option);
        }
      });

      dispatch({
        type: AirportSearchActionType.SearchingAirports,
        payload: {
          searchingAirports: false,
        },
      });

      return nonDuplicateSortedOptions.length > 0 ||
        nonDuplicateOptions.length > 0
        ? [
            ...nonDuplicateSortedOptions.map((option) => {
              return {
                airport: option,
              } as GeocoderOption;
            }),
            ...nonDuplicateOptions.slice(0, 5),
          ]
        : ([
            {
              subtext: 'No results found',
            },
          ] as GeocoderOption[]);
    }

    dispatch({
      type: AirportSearchActionType.SearchingAirports,
      payload: {
        searchingAirports: sortedOptions.length == 0,
      },
    });

    const iatas: string[] = [];
    const nonDuplicateSortedOptions: Airport[] = [];
    sortedOptions.map((option) => {
      if (!iatas.includes(option.iata)) {
        iatas.push(option.iata);
        nonDuplicateSortedOptions.push(option);
      }
    });

    return nonDuplicateSortedOptions.map((option) => {
      return {
        airport: option,
      } as GeocoderOption;
    });
  };

  return (
    <AirportSearchContext.Provider
      value={{
        ...state,
        searchAirports,
        findClosestHighTierAirport,
        getLocalOrigins,
        getAirport,
      }}
    >
      {children}
    </AirportSearchContext.Provider>
  );
};

export default AirportSearchContext;
