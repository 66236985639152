import { IonRow, IonSkeletonText } from '@ionic/react';

import '../theme/util.css';

import { SupportedCurrencies } from '@faredrop/graphql-sdk';
import { getCurrencySymbol } from '@faredrop/utilities';

interface ContainerProps {
  price?: number;
  meanMinPrice?: number;
  meanPrice?: number;
  currency?: SupportedCurrencies;
}

export enum PriceRangeColors {
  LOW = '#34a853',
  TYPICAL = '#fbbc04',
  HIGH = '#ea4335',
}

const RoutePriceRange: React.FC<ContainerProps> = ({
  price,
  meanMinPrice,
  meanPrice,
  currency,
}) => {
  let descriptor = 'low';
  let pricePosition = 0;

  if (price && meanMinPrice && meanPrice) {
    if (price < meanMinPrice) {
      pricePosition = (price / meanMinPrice) * 20;
    }
    if (price >= meanMinPrice) {
      descriptor = 'typical';
      pricePosition =
        20 + ((price - meanMinPrice) / (meanPrice - meanMinPrice)) * 60;
    }
    if (price > meanPrice) {
      descriptor = 'high';
      const maxPrice = Math.floor(meanPrice / 0.8);
      pricePosition = 80 + ((price - meanPrice) / (maxPrice - meanPrice)) * 20;
    }
  }

  return (
    <IonRow style={{ display: 'flex', flex: 1 }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {pricePosition ? (
          <div
            style={{
              position: 'relative',
              left: `${pricePosition}%`,
              transform: 'translateX(-5px)',
            }}
          >
            <div style={{ animation: 'fadeIn .5s' }}>
              {getCurrencySymbol(currency)}
              {price} is {descriptor}
            </div>
            <div
              style={{
                height: '15px',
                width: '15px',
                borderRadius: '10px',
                backgroundColor: 'white',
                border: '4px solid var(--ion-color-primary)',
                position: 'relative',
                top: '10px',
              }}
            ></div>
          </div>
        ) : (
          <div style={{ height: '31px' }} />
        )}
        <div style={{ display: 'flex', flex: 1 }}>
          <div
            style={{
              backgroundColor: PriceRangeColors.LOW,
              height: '5px',
              borderRadius: '5px',
              width: '33%',
              marginRight: '2px',
              flexBasis: '1px',
              flexGrow: 1,
              flexShrink: 0,
            }}
          ></div>
          <div
            style={{
              backgroundColor: PriceRangeColors.TYPICAL,
              height: '5px',
              borderRadius: '5px',
              width: '33%',
              marginRight: '2px',
              flexBasis: '1px',
              flexGrow: 3,
              flexShrink: 0,
            }}
          ></div>
          <div
            style={{
              backgroundColor: PriceRangeColors.HIGH,
              height: '5px',
              borderRadius: '5px',
              width: '33%',
              flexBasis: '1px',
              flexGrow: 1,
              flexShrink: 0,
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          <div style={{ marginLeft: '20%' }}>
            {meanMinPrice ? (
              <div style={{ animation: 'fadeIn .5s' }}>
                {getCurrencySymbol(currency)}
                {meanMinPrice}
              </div>
            ) : (
              <IonSkeletonText
                animated={true}
                style={{ width: '40px', height: '14px', borderRadius: '10px' }}
              />
            )}
          </div>
          <div style={{ marginRight: '20%' }}>
            {meanPrice ? (
              <div style={{ animation: 'fadeIn .5s' }}>
                {getCurrencySymbol(currency)}
                {meanPrice}
              </div>
            ) : (
              <IonSkeletonText
                animated={true}
                style={{ width: '40px', height: '14px', borderRadius: '10px' }}
              />
            )}
          </div>
        </div>
      </div>
    </IonRow>
  );
};

export default RoutePriceRange;
