import { IonFab, IonFabButton } from '@ionic/react';
import { memo, useMemo, useRef } from 'react';
import useUser from '../hooks/user';

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
  color?: string;
  disabled?: boolean;
  hideDecimal?: number;
  onClick: () => void;
  right?: string;
  top?: string;
  sx?: React.CSSProperties;
}

const FabButton: React.FC<ContainerProps> = ({
  children,
  color,
  disabled,
  hideDecimal,
  onClick,
  right,
  top,
  sx,
}) => {
  const fab = useRef(null);
  const userState = useUser();
  const styles = useMemo(
    () => ({
      position: 'fixed',
      right: right ?? '1.5em',
      opacity: !hideDecimal ? 1.0 : hideDecimal == 0 ? 1.0 : 0,
      transition: 'opacity 400ms',
      ...sx,
    }),
    [hideDecimal]
  );

  return !userState.hasPaymentInfo && !userState.isLimited ? (
    <IonFab ref={fab} style={{ ...styles, top: top ?? '75px' }}>
      <IonFabButton
        color={color ?? 'medium'}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {children}
      </IonFabButton>
    </IonFab>
  ) : (
    <IonFab ref={fab} style={{ ...styles, top: top ?? '1.5em' }}>
      <IonFabButton
        color={color ?? 'medium'}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {children}
      </IonFabButton>
    </IonFab>
  );
};

export default memo(FabButton);
