import { useEffect } from 'react';
import Loading from '../components/Loading';
import useAuth from '../hooks/auth';
import useFareDropApiClient from '../hooks/faredropApiClient';
import usePresentToast from '../hooks/presentToast';

const Logout = () => {
  const { isInitialized } = useAuth();
  const { client } = useFareDropApiClient();
  const { presentError } = usePresentToast();

  useEffect(() => {
    const redirect = async () => {
      if (isInitialized) {
        const customerPortalURL = (await client.customerPortalURL()).data
          .customerPortalURL.url;
        window.location.href = customerPortalURL;
      }
    };

    redirect().catch(() =>
      presentError(
        'Failed to redirect to the billing portal - please refresh and try again'
      )
    );
  }, [isInitialized]);

  return <Loading />;
};

export default Logout;
