// cspell:ignore quot

import { IonContent, IonPage } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import HidingHeader from '../components/HidingHeader';
import MobileHeader from '../components/MobileHeader';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDevice } from '../hooks/useDevice';
import Footer from '../components/Footer';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDynamicBanner } from '../hooks/useDynamicBanner';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const RefundPolicy: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const dynamicBannerContent = useDynamicBanner();

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
        style={{ display: 'flex' }}
      >
        <div
          style={{
            padding: '6em',
            fontFamily: 'nexa',
            fontSize: 14,
            maxWidth: '700px',
            margin: 'auto',
            lineHeight: '1.8em',
          }}
        >
          <h1
            style={{
              fontSize: 32,
              marginBottom: '1em',
              textAlign: 'center',
              fontFamily: 'nexa-bold',
            }}
          >
            Refund Policy
          </h1>
          <p>
            <span>
              YOU ACKNOWLEDGE THAT ONCE AN ORDER FOR A PRODUCT IS PLACED, IT
              CANNOT BE REFUNDED EXCEPT AS PROVIDED HEREIN UNLESS OTHERWISE
              COMMUNICATED BY US TO YOU THROUGH OUR SUPPORT MECHANISMS.
              <br />
              <br />
              <hr style={{ borderTop: '1px solid #ddd' }} />
              <br />
              <br />
              <br />
              <b style={{ fontFamily: 'nexa-bold' }}>General Terms</b>
              <br />
              <br />
              Throughout the site, the terms “we”, “us” and “our” refer to
              FareDrop, Inc. and the terms “you” or “your” refer to the user of
              this webpage or the Products.
              <br />
              <br />
              We sell multiple classes of digital subscriptions: i.e. our
              Limited Subscription and Pro Subscription. The Limited
              Subscription and Pro Subscription may be referred to herein as the
              “Subscriptions” and each a “Subscription.”
              <br />
              <br />
              Each Subscription is sold for a certain time period (e.g. one
              year, one month, etc.) commencing on the date of purchase and
              ending on the anniversary of the time period of such date of
              purchase (e.g. one year later, one month later, etc.) (the
              “Subscription Term”). By default, subscriptions automatically
              renew pursuant to our Terms of Service which are available{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                onClick={() => {
                  goWithStickyParamsPath('/terms-of-service');
                }}
              >
                here
              </a>{' '}
              and which are incorporated herein by this reference.
              <br />
              <br />
              Only regularly priced Products may be refunded. Unfortunately,
              sale Products cannot be refunded.
              <br />
              <br />
              Your Products are eligible for refund ONLY as set out on the
              timelines and terms provided herein.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                Refund Policies for Subscriptions
              </b>
              <br />
              <br />
              <span style={{ textDecoration: 'underline' }}>
                Existing Subscribers
              </span>
              <br />
              <br />
              As used herein, the term &quot;Existing Subscriber&quot; means any
              person who is a subscriber for or purchaser of any Product of
              FareDrop, Inc. including, without limitation, any type of
              Subscription.
              <br />
              <br />
              An Existing Subscriber may return their Products within forty-five
              (45) days of the date of the Product’s purchase or renewal for a
              full refund. The Existing Subscriber may initiate the return
              process on their online Subscription account or by contacting
              support by emailing team@faredrop.com. Failure to timely return
              the Products within the time-frame stated above will result in us
              retaining the full purchase price for the Products.
              <br />
              <br />
              <span style={{ textDecoration: 'underline' }}>
                New Subscribers
              </span>
              <br />
              <br />
              As used herein, the term “New Subscriber” means any person who is
              not a subscriber for or purchaser of any Product of FareDrop, Inc.
              including, without limitation, any type of Subscription and who
              subsequently subscribes for or purchases a Product thereafter.
              <br />
              <br />
              A New Subscriber may return their Products within thirty (30) days
              of the date of the Product’s purchase for a full refund. The New
              Subscriber may initiate the return process on their online
              Subscription account or by contacting support at
              team@faredrop.com. Failure to timely return the Products within
              the time-frame stated above will result in us retaining the full
              purchase price.
              <br />
              <br />
              <span style={{ textDecoration: 'underline' }}>
                Subscription Updates
              </span>
              <br />
              <br />
              You, as a subscriber to a paid Subscription, can upgrade from the
              Subscription to a higher tier Subscription one (1) time at any
              time during the Subscription Term on a pro-rated basis determined
              by (i) applying a credit equal to the price actually paid for the
              Subscription towards the full current price of the higher tier
              Subscription and (ii) paying the Subscription Premium. As used
              herein &quot;Subscription Premium” means the amount by which the
              higher tier Subscription, at the time of the upgrade, exceeds the
              existing Subscription, on a per day basis, multiplied by the
              number of days remaining in the Subscription Term.
              <br />
              <br />
              If you do not enjoy using the higher tier Subscription then you
              may downgrade one time, within 30 days of the date of your
              upgrade, for a full refund of the Subscription Premium paid by
              you. Your downgraded Subscription would remain in effect until the
              completion of your then-current Subscription Term.
              <br />
              <br />
              In the event that you upgrade to the higher tier Subscription more
              than once during a given Subscription Term then, following the
              initial upgrade and refund/downgrade addressed above, you will not
              be able to downgrade to the Subscription for a refund of any kind.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>How Refunds Work</b>
              <br />
              <br />
              To initiate a refund request, please access your online
              subscription account within the above referenced timelines and
              <br />
              <br />
              <ul>
                <li>
                  contact us at{' '}
                  <a
                    style={{ fontFamily: 'nexa-bold' }}
                    href="mailto:team@faredrop.com"
                  >
                    team@faredrop.com
                  </a>{' '}
                  through the email address you use to login to our services
                </li>
                <li>indicate the reason for the refund request.</li>
              </ul>
              <br />
              <br />
              Failure to make a timely and complete claim may result in your
              forfeiture of the right to receive a refund.
              <br />
              <br />
              Once your refund claim is timely submitted and received and
              processed, we will send you an email to notify you that we have
              received your claim. We will also notify you of the approval or
              rejection of your refund request.
              <br />
              <br />
              If you are approved, then we will issue you a refund and terminate
              your Subscription and related login credentials. A credit will
              automatically be applied to your credit card or original method of
              payment. This process can take several days.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                Late or missing refunds (if applicable):
              </b>
              <br />
              <br />
              If you haven’t received a refund yet, first check your bank
              account again. Then contact your credit card company, it may take
              some time before your refund is officially posted. Next contact
              your bank. There is often some processing time before a refund is
              posted. If you’ve done all of this and you still have not received
              your refund yet, please contact us at{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                href="mailto:legal@faredrop.com"
              >
                legal@faredrop.com
              </a>
              .
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              THE REMEDIES PROVIDED FOR ABOVE ARE YOUR SOLE REMEDIES FOR ANY
              DEFECTIVE PRODUCTS, AND EXCLUDE, TO THE FULLEST EXTENT PERMITTED
              BY LAW, ANY OTHER REMEDY AVAILABLE TO YOU BY LAW.
            </span>
          </p>
        </div>
        <Footer bgColor="primary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default RefundPolicy;
