import { IonRow } from '@ionic/react';
import moment from 'moment';

import { Deal, SeatClass, SupportedCurrencies } from '@faredrop/graphql-sdk';
import { getCurrencySymbol } from '@faredrop/utilities';

import useAirports from '../hooks/airports';
import RoutePriceRange, { PriceRangeColors } from './RoutePriceRange';

interface ContainerProps {
  price: number;
  meanMinPrice: number;
  meanPrice: number;
  currency?: SupportedCurrencies;
  seatClass: SeatClass;
  destinationIATA: string;
  deal?: Deal;
}

const DealSearchPriceRange: React.FC<ContainerProps> = ({
  price,
  meanMinPrice,
  meanPrice,
  currency,
  seatClass,
  destinationIATA,
  deal,
}) => {
  const { getAirport } = useAirports();

  let priceColor = PriceRangeColors.LOW;
  let descriptor = 'low';
  if (price >= meanMinPrice) {
    priceColor = PriceRangeColors.TYPICAL;
    descriptor = 'typical';
  }
  if (price > meanPrice) {
    priceColor = PriceRangeColors.HIGH;
    descriptor = 'high';
  }

  return (
    <>
      <IonRow
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '2em',
          flexDirection: 'column',
        }}
      >
        <IonRow
          style={{
            fontSize: '22px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {getCurrencySymbol(currency)}
          {price} is{' '}
          <span
            style={{ color: priceColor, marginLeft: '3px', marginRight: '3px' }}
          >
            {descriptor}
          </span>{' '}
          for {seatClass.toLowerCase()}
        </IonRow>
        <IonRow
          style={{
            textAlign: 'center',
            marginTop: '.75em',
            lineHeight: '1.5em',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          We typically see prices to {getAirport(destinationIATA)?.city} between{' '}
          {getCurrencySymbol(currency)}
          {meanMinPrice} and {getCurrencySymbol(currency)}
          {meanPrice}
        </IonRow>
        {deal && (
          <IonRow
            style={{
              textAlign: 'center',
              lineHeight: '1.5em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {`A deal notification was sent today at ${moment(
              deal.created
            ).format('h:mm a')}`}
          </IonRow>
        )}
      </IonRow>
      <RoutePriceRange
        price={price}
        meanMinPrice={meanMinPrice}
        meanPrice={meanPrice}
      />
    </>
  );
};

export default DealSearchPriceRange;
