import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { SWRConfig } from 'swr';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
// Import Swiper styles
import 'swiper/swiper.min.css';
import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FareDropTabs from './components/FareDropTabs';
import GuardedRoute from './components/GuardedRoute';
import SlideoutMenu from './components/SlideoutMenu';
import { AirportSearchProvider } from './contexts/airportSearchContext';
import { AppStateProvider } from './contexts/appStateContext';
import { AuthProvider } from './contexts/authContext';
import { NotificationProvider } from './contexts/notificationContext';
import { PushNotificationProvider } from './contexts/pushNotificationContext';
import { TabProvider } from './contexts/tabContext';
import useAuth from './hooks/auth';
import About from './pages/About';
import DealsDashboard from './pages/DealsDashboard';
import DepartureAirportsSettings from './pages/DepartureAirportsSettings';
import Error from './pages/Error';
import FAQ from './pages/FAQ';
import ChoosePlanPage from './pages/ChoosePlanPage';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import Logout from './pages/Logout';
import Oops from './pages/Oops';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import PlanAndBillingSettings from './pages/PlanAndBillingSettings';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProfileSettings from './pages/ProfileSettings';
import RefundPolicy from './pages/RefundPolicy';
import DestinationPreferencesSettings from './pages/DestinationPreferencesSettings';
import TermsOfService from './pages/TermsOfService';
import TravelAvailabilitySettings from './pages/TravelAvailabilitySettings';
import { MapBoxProvider } from './contexts/mapBoxContext';
import Welcome from './pages/Welcome';
import './theme/TabBar.css';
import './theme/util.css';
/* Theme variables */
import './theme/variables.css';
import { AnalyticsProvider } from './contexts/analyticsContext';
import BillingRedirect from './pages/BillingRedirect';
import { RouteListenerProvider } from './contexts/routeListenerContext';
import { VersionSupportProvider } from './contexts/versionSupportContext';
import useVersionSupport from './hooks/versionSupport';
import { SnackbarProvider } from 'notistack';
import { Capacitor } from '@capacitor/core';
import { BadUserSettingsToastProvider } from './contexts/BadUserSettingsToastContext';
import { LandingPageNavigationProvider } from './contexts/landingPageNavigation';
import GiftFareDrop from './pages/GiftFareDrop';
import Confirmation from './pages/GiftConfirmation';
import RedeemSubscription from './components/RedeemSubscription';
import { GoogleReCaptchaV2AndV3Provider } from './contexts/GoogleReCaptchaV2AndV3Context';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Percent20Discount from './pages/20PercentDiscount';
import DealPreferences from './pages/DealPreferences';
import YouTubePercent20Discount from './pages/YouTube20PercentDiscount';
import { AllowedPath, STRIPE_COUPON_ID } from '@faredrop/types';
import ErrorBoundary from './components/ErrorBoundary';
import useAnalytics from './hooks/analytics';
import VerifyEmailPage from './pages/VerifyEmailPage';
import { FareDropPublicApiClient } from '@faredrop/graphql-apig-unauthenticated-sdk';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { PushNotifications } from '@capacitor/push-notifications';
import { localStorageProvider } from './utilities/local-storage-cache-provider';
import RegisterPage from './pages/RegisterPage';
import CloseInAppBrowser from './pages/CloseInAppBrowser';
import MilesAndPoints from './pages/MilesAndPoints';
import PasswordResetPage from './pages/PasswordResetPage';
import { GleamProvider } from './contexts/gleamContext';
import Redirecting from './pages/Redirecting';
import StartHere from './pages/StartHere';
import { ChurnKeyProvider } from './contexts/ChurnKeyContext';
import { KlaviyoProvider } from './contexts/klaviyoContext';
import { StripeProvider } from './contexts/stripeContext';
import Reviews from './pages/Reviews';
import RedeemWithCard from './pages/RedeemWithCard';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import UnauthenticatedPurchasePage from './pages/UnauthenticatedPurchasePage';
import RedeemSuccess from './pages/RedeemSuccess';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  getAssetsBaseUrl,
  isGiftPromotion,
  isProductionEnvironment,
} from '@faredrop/utilities';
import { SetupIntentReason } from './components/PaymentForm';
import UnauthenticatedPromotionUpgrade from './pages/UnauthenticatedPromotionUpgrade';
import { RefreshAccountProvider } from './contexts/refreshAccountContext';
import { DeviceProvider } from './contexts/deviceContext';
import DailyDrop from './pages/DailyDrop';
import DailyDropPost from './pages/DailyDropPost';
import Perks from './pages/Perks';
import { ImpactProvider } from './contexts/impactContext';
import InfluencerHome from './pages/InfluencerHome';
import DealDetails from './pages/DealDetails';
import { DealsDashboardProvider } from './contexts/dealsDashboardContext';
import { InactiveAirportsProvider } from './contexts/inactiveAirportsContext';
import { FirstPromoterProvider } from './contexts/firstPromoterContext';
import GiveawayOffer from './pages/GiveawayOffer';
import { isFrontendSubscriptionPromotion } from './utilities/plans-utilities';

const BANNER_TEXT = `Sign up for our FREE Limited Plan`;
const PROMOTION_BANNER_TEXT = `😎 SUMMER SALE: ✅ 50% off Pro, ✅ FREE Travel Day Bundle, ✅ Entered to win an all-inclusive trip to Punta Cana!`;

export interface IBannerOptions {
  text?: string;
  path?: string;
  search?: string;
}

const App: React.FC = () => {
  useVersionSupport();
  const { isAuthenticated, user: authUser } = useAuth();
  const { logAnalyticsError } = useAnalytics();

  const menuRef = useRef<HTMLIonMenuElement>(null);
  const isAndroid = Capacitor.getPlatform() === 'android';
  const isIOS = Capacitor.getPlatform() === 'ios';

  setupIonicReact({
    // Required by Ionic 6
    // Determines which platform styles to use for the whole application
    // Let's use iOS styling for our app regardless of mobile platform type
    mode: 'ios',
  });

  const onMenuClickHandler = async () => {
    await menuRef.current?.open();
  };

  // To link to credit cards in emails, we need to make sure the referrer header is from FareDrop, so our short links have query params to redirect to credit card page
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shortLinkRedirect = queryParams.get('http_redirect');
    if (shortLinkRedirect) {
      window.location.href = shortLinkRedirect;
    }
  }, []);

  return (
    // Top level error boundary to capture any high level ionic errors and analytics initialization errors
    <ErrorBoundary>
      <IonApp style={{ backgroundColor: 'white' }}>
        <AnalyticsProvider>
          {/* Second error boundary that uses analytics error logging */}
          <ErrorBoundary
            logger={async (error: Error, errorInfo, clientRequestId) => {
              const client = FareDropPublicApiClient();
              let appTrackingEnabled = undefined;
              let pushNotificationsEnabled = undefined;

              // Wrapping these in a try/catch to ensure that any of these bricking doesn't cause
              // the error logging event to be sent
              try {
                appTrackingEnabled = isIOS
                  ? await AppTrackingTransparency.getStatus()
                  : 'N/A - not iOS';
                pushNotificationsEnabled =
                  isAndroid || isIOS
                    ? (await PushNotifications.checkPermissions()).receive
                    : 'web';
              } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
              }

              let responses;
              try {
                responses = await Promise.all([
                  logAnalyticsError('ErrorBoundary', error),
                  client.log({
                    obj: JSON.stringify({
                      clientRequestId,
                      // https://stackoverflow.com/questions/51384606/error-boundary-does-not-give-access-to-error-if-it-is-an-instance-of-error-class
                      error: {
                        name: error.name,
                        message: error.message,
                        stack: error.stack,
                      },
                      errorStringified: JSON.stringify(
                        error,
                        Object.getOwnPropertyNames(error)
                      ),
                      errorInfo,
                      appVersion: process.env.REACT_APP_VERSION ?? 'Not found',
                      isAndroid,
                      isIOS,
                      appTrackingEnabled,
                      pushNotificationsEnabled,
                      userAgent: window.navigator.userAgent,
                      authUser,
                      // Filtering out some SWR entries that we know probably won't be very helpful...
                      localStorage: Object.entries(window.localStorage).filter(
                        (entry) =>
                          !entry[0].startsWith('swr-USER_DEALS') &&
                          !entry[0].startsWith('swr-USER_PAST_DEALS') &&
                          !entry[0].startsWith('swr-LANDING_PAGE') &&
                          !entry[0].startsWith('swr-HERO_PHONE_DEALS') &&
                          !entry[0].startsWith('swr-SOCIAL_PROOF') &&
                          !entry[0].startsWith('swr-SWR_TIMEOUT')
                      ),
                    }).slice(0, 100000),
                  }),
                ]);

                return responses[1].data.log.requestId;
              } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
                // If we error for any reason, return the error object(s)
                return JSON.stringify(e);
              }
            }}
          >
            <DeviceProvider>
              <SnackbarProvider>
                <SWRConfig value={{ provider: localStorageProvider }}>
                  <GleamProvider>
                    <MapBoxProvider>
                      <AppStateProvider>
                        <NotificationProvider>
                          <IonReactRouter>
                            <AuthProvider>
                              <RefreshAccountProvider>
                                <ChurnKeyProvider>
                                  <KlaviyoProvider>
                                    <FirstPromoterProvider>
                                      <ImpactProvider>
                                        <AirportSearchProvider>
                                          <VersionSupportProvider>
                                            <RouteListenerProvider>
                                              <LandingPageNavigationProvider>
                                                <SlideoutMenu
                                                  menuRef={menuRef}
                                                />
                                                <DealsDashboardProvider>
                                                  <PushNotificationProvider>
                                                    <BadUserSettingsToastProvider>
                                                      <GoogleReCaptchaProvider
                                                        reCaptchaKey={
                                                          process.env
                                                            .REACT_APP_RECAPTCHA_PUBLIC_KEY
                                                        }
                                                      >
                                                        <GoogleReCaptchaV2AndV3Provider>
                                                          <StripeProvider>
                                                            <InactiveAirportsProvider>
                                                              <TabProvider>
                                                                <FareDropTabs>
                                                                  <IonRouterOutlet
                                                                    id="router"
                                                                    animated={
                                                                      false
                                                                    }
                                                                  >
                                                                    <Switch>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.BASE
                                                                        }
                                                                      >
                                                                        {isAuthenticated ? (
                                                                          <DealsDashboard />
                                                                        ) : isAndroid ||
                                                                          isIOS ? (
                                                                          <LoginPage />
                                                                        ) : (
                                                                          <Home
                                                                            onMenuClickHandler={
                                                                              onMenuClickHandler
                                                                            }
                                                                            bannerOptions={{
                                                                              text:
                                                                                isGiftPromotion() ||
                                                                                isFrontendSubscriptionPromotion()
                                                                                  ? PROMOTION_BANNER_TEXT
                                                                                  : BANNER_TEXT,
                                                                              // path: isFrontendSubscriptionPromotion()
                                                                              //   ? AllowedPath.SUMMER24.slice(
                                                                              //       1
                                                                              //     )
                                                                              //   : undefined,
                                                                              // search:
                                                                              //   isFrontendSubscriptionPromotion()
                                                                              //     ? '?target=register'
                                                                              //     : undefined,
                                                                            }}
                                                                          />
                                                                        )}
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={[
                                                                          AllowedPath.OFFER_AWAY_TOGETHER,
                                                                        ]}
                                                                      >
                                                                        {isAuthenticated ? (
                                                                          <DealsDashboard />
                                                                        ) : isAndroid ||
                                                                          isIOS ? (
                                                                          <LoginPage />
                                                                        ) : (
                                                                          <InfluencerHome
                                                                            influencerName="Nik and Allie"
                                                                            coupon={
                                                                              isProductionEnvironment()
                                                                                ? STRIPE_COUPON_ID.PRODUCTION_30_DOLLARS_AWAY_TOGETHER
                                                                                : STRIPE_COUPON_ID.DEVELOPMENT_30_DOLLARS_AWAY_TOGETHER
                                                                            }
                                                                            discounts="PRO:30:amount"
                                                                            discountAmount={
                                                                              30
                                                                            }
                                                                            imageSource={`${getAssetsBaseUrl()}/influencers/nikandallie.jpg`} // cspell:disable-line
                                                                            onMenuClickHandler={
                                                                              onMenuClickHandler
                                                                            }
                                                                          />
                                                                        )}
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={[
                                                                          AllowedPath.FREE_TRIAL,
                                                                        ]}
                                                                      >
                                                                        {isAuthenticated ? (
                                                                          <DealsDashboard />
                                                                        ) : isAndroid ||
                                                                          isIOS ? (
                                                                          <LoginPage />
                                                                        ) : (
                                                                          // <FreeTrialLandingPage />
                                                                          <Redirect
                                                                            to={
                                                                              AllowedPath.BASE
                                                                            }
                                                                          />
                                                                        )}
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.VERIFY
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <VerifyEmailPage />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.FAQ
                                                                        }
                                                                      >
                                                                        <FAQ
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.ACCOUNT_PROFILE
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <ProfileSettings />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.SETTINGS_DEPARTURE_AIRPORTS
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <DepartureAirportsSettings />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.SETTINGS_DESTINATION_PREFERENCES
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <DestinationPreferencesSettings />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path="/settings/region-preferences"
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.SETTINGS_DESTINATION_PREFERENCES
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.SETTINGS_TRAVEL_AVAILABILITY
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <TravelAvailabilitySettings />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.SETTINGS_DEAL_PREFERENCES
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <DealPreferences />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path="/settings/flight-preferences"
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.SETTINGS_DEAL_PREFERENCES
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.ACCOUNT_PLAN_AND_BILLING
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <PlanAndBillingSettings />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.LOGIN
                                                                        }
                                                                      >
                                                                        <LoginPage />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REGISTER
                                                                        }
                                                                      >
                                                                        <RegisterPage />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.GET_STARTED_COMPLETE
                                                                        }
                                                                      >
                                                                        <CloseInAppBrowser />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.PASSWORD_RECOVERY
                                                                        }
                                                                      >
                                                                        <PasswordRecoveryPage />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.PASSWORD_RESET
                                                                        }
                                                                      >
                                                                        <PasswordResetPage />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.PLANS
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <ChoosePlanPage />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.GET_STARTED_WELCOME_FREE
                                                                        }
                                                                      >
                                                                        <Welcome />
                                                                      </Route>
                                                                      {/* Adding an additional welcome route for paid users for A/B testing conversion tracking */}
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.GET_STARTED_WELCOME_PAID
                                                                        }
                                                                      >
                                                                        <Welcome />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.ABOUT
                                                                        }
                                                                      >
                                                                        <About
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.PRIVACY_POLICY
                                                                        }
                                                                      >
                                                                        <PrivacyPolicy
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.CONTEST_FEEDBACK_RULES
                                                                        }
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.CONTEST_MEET_KARA_AND_NATE
                                                                        }
                                                                      >
                                                                        {/* <MeetKaraAndNateContestRules /> */}
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REFUND_POLICY
                                                                        }
                                                                      >
                                                                        <RefundPolicy
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.TERMS_OF_SERVICE
                                                                        }
                                                                      >
                                                                        <TermsOfService
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.ERROR
                                                                        }
                                                                      >
                                                                        <Error />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.ACCOUNT_SUBSCRIPTION
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <BillingRedirect />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.DEALS
                                                                        }
                                                                      >
                                                                        <GuardedRoute>
                                                                          <DealsDashboard />
                                                                        </GuardedRoute>
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.HOME
                                                                        }
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.SETTINGS
                                                                        }
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.ACCOUNT_PROFILE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.LOGOUT
                                                                        }
                                                                      >
                                                                        <Logout />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.CONFIRMATION
                                                                        }
                                                                      >
                                                                        <Confirmation />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.GIFT
                                                                        }
                                                                      >
                                                                        <GiftFareDrop
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REDEEM
                                                                        }
                                                                      >
                                                                        <RedeemSubscription
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REDEEM_SUCCESS
                                                                        }
                                                                      >
                                                                        <RedeemSuccess />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.MILES_AND_POINTS
                                                                        }
                                                                      >
                                                                        <MilesAndPoints
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.OFFER_BUSINESS_FOR_12
                                                                        }
                                                                      >
                                                                        {/* <BusinessClassForEconomy12Months /> */}
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.OFFER_PERCENT_20
                                                                        }
                                                                      >
                                                                        <Percent20Discount
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.OFFER_YOUTUBE_PERCENT_20
                                                                        }
                                                                      >
                                                                        <YouTubePercent20Discount
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.OFFER_ANTARCTICA
                                                                        }
                                                                      >
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.SUMMER24
                                                                        }
                                                                      >
                                                                        <GiveawayOffer
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REDIRECTING
                                                                        }
                                                                      >
                                                                        <Redirecting />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.START_HERE
                                                                        }
                                                                      >
                                                                        <StartHere
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                          bannerOptions={{
                                                                            text:
                                                                              isGiftPromotion() ||
                                                                              isFrontendSubscriptionPromotion()
                                                                                ? PROMOTION_BANNER_TEXT
                                                                                : BANNER_TEXT,
                                                                            // path: isFrontendSubscriptionPromotion()
                                                                            //   ? AllowedPath.BFCM23.slice(
                                                                            //       1
                                                                            //     )
                                                                            //   : undefined,
                                                                            search:
                                                                              isFrontendSubscriptionPromotion()
                                                                                ? '?target=register'
                                                                                : undefined,
                                                                          }}
                                                                        />
                                                                      </Route>
                                                                      {/* For testing paid ads without option for free plan */}
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.NEW_PLAN
                                                                        }
                                                                      >
                                                                        <Home
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                          hidePlans={[
                                                                            FareDropPlan.Limited,
                                                                          ]}
                                                                          plansParams={[
                                                                            {
                                                                              key: 'hide-plans',
                                                                              value:
                                                                                FareDropPlan.Limited,
                                                                            },
                                                                          ]}
                                                                          bannerOptions={{
                                                                            text:
                                                                              isGiftPromotion() ||
                                                                              isFrontendSubscriptionPromotion()
                                                                                ? PROMOTION_BANNER_TEXT
                                                                                : undefined,
                                                                            // path: isFrontendSubscriptionPromotion()
                                                                            //   ? AllowedPath.BFCM23.slice(
                                                                            //       1
                                                                            //     )
                                                                            //   : undefined,
                                                                            search:
                                                                              isFrontendSubscriptionPromotion()
                                                                                ? '?target=register'
                                                                                : undefined,
                                                                          }}
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REVIEWS
                                                                        }
                                                                      >
                                                                        <Reviews
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.REDEEM_OFFER
                                                                        }
                                                                      >
                                                                        <RedeemWithCard
                                                                          plan={
                                                                            FareDropPlan.Pro
                                                                          }
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.UPGRADE
                                                                        }
                                                                      >
                                                                        {isFrontendSubscriptionPromotion() &&
                                                                        PROMOTION_IS_SHOPIFY_INTEGRATION ? (
                                                                          <UnauthenticatedPromotionUpgrade
                                                                            onMenuClickHandler={
                                                                              onMenuClickHandler
                                                                            }
                                                                            title="Summer Sale!"
                                                                          />
                                                                        ) : (
                                                                          <UnauthenticatedPurchasePage
                                                                            onMenuClickHandler={
                                                                              onMenuClickHandler
                                                                            }
                                                                            title="Limited Time Offer!"
                                                                            subtitle="Unlock FareDrop's full potential today!"
                                                                            notYouRedirectPath={
                                                                              AllowedPath.UPGRADE
                                                                            }
                                                                            setupIntentReason={
                                                                              SetupIntentReason.UPGRADE
                                                                            }
                                                                          />
                                                                        )}
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.WELCOME_BACK
                                                                        }
                                                                      >
                                                                        <UnauthenticatedPurchasePage
                                                                          onMenuClickHandler={
                                                                            onMenuClickHandler
                                                                          }
                                                                          title="Welcome Back!"
                                                                          subtitle="Start receiving flight deal notifications again!"
                                                                          notYouRedirectPath={
                                                                            AllowedPath.WELCOME_BACK
                                                                          }
                                                                          hidePlansOverride={[
                                                                            FareDropPlan.Limited,
                                                                          ]}
                                                                          setupIntentReason={
                                                                            SetupIntentReason.SUBSCRIBE
                                                                          }
                                                                        />
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.BFCM23
                                                                        }
                                                                      >
                                                                        {/* {isFrontendSubscriptionPromotion() &&
                                                                        moment
                                                                          .utc()
                                                                          .isBefore(
                                                                            moment.utc(
                                                                              '2023-12-08'
                                                                            ),
                                                                            'day'
                                                                          ) ? (
                                                                          <PromotionOffer
                                                                            onMenuClickHandler={
                                                                              onMenuClickHandler
                                                                            }
                                                                            bannerText={
                                                                              isGiftPromotion() ||
                                                                              isFrontendSubscriptionPromotion()
                                                                                ? PROMOTION_BANNER_TEXT
                                                                                : BANNER_TEXT
                                                                            }
                                                                          />
                                                                        ) : ( */}
                                                                        <Redirect
                                                                          to={
                                                                            AllowedPath.BASE
                                                                          }
                                                                        />
                                                                        {/* )} */}
                                                                      </Route>
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.DAILY_DROP
                                                                        }
                                                                      >
                                                                        <DailyDrop />
                                                                      </Route>
                                                                      <Route
                                                                        path={`${AllowedPath.DAILY_DROP}/:id`}
                                                                        component={
                                                                          DailyDropPost
                                                                        }
                                                                      />
                                                                      <Route
                                                                        exact
                                                                        path={
                                                                          AllowedPath.PERKS
                                                                        }
                                                                      >
                                                                        <Perks />
                                                                      </Route>
                                                                      <Route
                                                                        path={
                                                                          AllowedPath.DEAL_DETAILS
                                                                        }
                                                                        component={
                                                                          DealDetails
                                                                        }
                                                                      ></Route>
                                                                      <Route>
                                                                        <Oops />
                                                                      </Route>
                                                                    </Switch>
                                                                  </IonRouterOutlet>
                                                                </FareDropTabs>
                                                              </TabProvider>
                                                            </InactiveAirportsProvider>
                                                          </StripeProvider>
                                                        </GoogleReCaptchaV2AndV3Provider>
                                                      </GoogleReCaptchaProvider>
                                                    </BadUserSettingsToastProvider>
                                                  </PushNotificationProvider>
                                                </DealsDashboardProvider>
                                              </LandingPageNavigationProvider>
                                            </RouteListenerProvider>
                                          </VersionSupportProvider>
                                        </AirportSearchProvider>
                                      </ImpactProvider>
                                    </FirstPromoterProvider>
                                  </KlaviyoProvider>
                                </ChurnKeyProvider>
                              </RefreshAccountProvider>
                            </AuthProvider>
                          </IonReactRouter>
                        </NotificationProvider>
                      </AppStateProvider>
                    </MapBoxProvider>
                  </GleamProvider>
                </SWRConfig>
              </SnackbarProvider>
            </DeviceProvider>
          </ErrorBoundary>
        </AnalyticsProvider>
      </IonApp>
    </ErrorBoundary>
  );
};

export default App;
