import { IonCol, IonRow, useIonViewDidEnter } from '@ionic/react';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';
import useAnalytics from '../hooks/analytics';
import useLandingPageDeals from '../hooks/landingPageDeals';
import { useDevice } from '../hooks/useDevice';
import useSearchAirports from '../hooks/useSearchAirports';
import useWindowDimensions from '../hooks/useWindowDimensions';
import './../theme/DealsSection.css';
import './../theme/util.css';
import { DealCard } from './DealCard';
import useLandingPageDefaultDeals from '../hooks/landingPageDefaultDeals';
import { DropdownOptionWithSubtext, LandingDeal } from '../types/types';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import { formatGeoCoderOption } from '../contexts/airportSearchContext';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { sortBySeatClass } from '../utilities/deals-utilities';
import { Airport } from '@faredrop/graphql-sdk';
import SearchBarWithResults from './SearchBarWithResults';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const HomePageDealsSection: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
}) => {
  const { logAnalyticsSearch, logAnalyticsLandingPageEngagement } =
    useAnalytics();
  const [viewLoaded, setViewLoaded] = useState(false);
  const [selectedSearchAirport, setSelectedSearchAirport] = useState<string>();
  const {
    isExtraSmallScreenSize,
    isSmallScreenSizeOrSmaller,
    isExtraLargeScreenSize,
  } = useDevice();
  const slidesRef = useRef<SwiperRef>(null);
  const { searchAirports, getAirport, origins } = useSearchAirports();
  const previousSliderKey = useRef(`${new Date()}`);
  const landingPageDealsState = useLandingPageDeals(selectedSearchAirport);
  const landingPageDefaultDealsState = useLandingPageDefaultDeals();
  const { width } = useWindowDimensions();
  const [defaultDeals, setDefaultDeals] = useState<LandingDeal[]>([]);
  const { smoothScroll } = useLandingPageNavigation();

  useEffect(() => {
    if (!landingPageDefaultDealsState.isInitializing) {
      setDefaultDeals(landingPageDefaultDealsState.randomDeals());
    }
  }, [landingPageDefaultDealsState.isInitializing]);

  // Without this, the slide sizes get calculated before the page is finished loading
  // and only one slide is shown on the screen
  useIonViewDidEnter(() => {
    setViewLoaded(true);
  });

  const onSearchChange = async (searchText?: string) => {
    let result: DropdownOptionWithSubtext[] | undefined = undefined;
    if (searchText) {
      const [origins] = await Promise.all([
        searchAirports(searchText, {
          originsOnly: true,
          useMapBoxFallback: true,
        }),
        logAnalyticsSearch(searchText),
      ]);
      result = origins.map((d) =>
        formatGeoCoderOption(d, { useParentAirports: true })
      );
    } else {
      setSelectedSearchAirport(undefined);
    }
    return result;
  };

  const onSearchSelect = async (option: DropdownOptionWithSubtext) => {
    const split = option.text.split('(');
    const name = split[0].trim();
    const iata = split[1].replace(')', '').trim();

    setSelectedSearchAirport(name);

    let searchText: string | undefined = undefined;
    const airport = await getAirport(iata);
    if (airport) {
      searchText = airport.name;
    }
    return {
      displayText: searchText,
    };
  };

  const onSearchSubmit = async () => {
    logAnalyticsEngagement &&
      (await logAnalyticsEngagement(AnalyticsEngagementId.VIEW_ORIGIN_DEALS));
  };

  const previousSlide = async () => {
    if (slidesRef.current) {
      slidesRef.current?.swiper.slidePrev();
      logAnalyticsEngagement &&
        (await logAnalyticsEngagement(
          AnalyticsEngagementId.PREVIOUS_DEALS_PAGE
        ));
    }
  };

  const nextSlide = async () => {
    if (slidesRef.current) {
      slidesRef.current?.swiper.slideNext();
      logAnalyticsEngagement &&
        (await logAnalyticsEngagement(AnalyticsEngagementId.NEXT_DEALS_PAGE));
    }
  };

  let deals = defaultDeals;
  if (!landingPageDealsState.isInitializing && landingPageDealsState.deals) {
    deals = landingPageDealsState.deals;
  } else if (
    selectedSearchAirport &&
    (landingPageDealsState.deals ?? []).length === 0
  ) {
    deals = [];
  }

  // NOTE: This is probably not perfect!
  // Don't update key if current key is exact first subset of new deals (pagination)
  let sliderKey =
    Array.isArray(deals) && deals.length !== 0
      ? deals
          .map((deal) =>
            !deal
              ? 'empty'
              : `${deal.seatClass}#${deal.originIATA}#${deal.destinationIATA}`
          )
          .join('_')
      : 'empty';
  if (sliderKey.indexOf(previousSliderKey.current) === 0) {
    // Pagination scenario, don't update key and cause a slider rerender
    sliderKey = previousSliderKey.current;
  } else {
    previousSliderKey.current = sliderKey;
  }

  const CARD_WIDTH = 360;
  return (
    <IonRow
      className="check-out-deals-wrapper no-flex-wrap mb-3"
      id="find-deals"
      style={{ paddingTop: '2em' }}
    >
      <IonCol sizeLg="5" sizeXs="12" className="title-and-searchbar-desktop">
        <IonRow>
          <IonCol className="title-col" style={{ marginTop: '25%' }}>
            <IonRow className="mb-1-5">
              <h2 className="title-font check-out-deals-title p-0">
                Check out the latest
                <br />
                deals from your area
              </h2>
            </IonRow>
            <IonRow>
              <IonCol size="10">
                <div
                  style={{ margin: isSmallScreenSizeOrSmaller ? '0 -1em' : 0 }}
                >
                  <SearchBarWithResults
                    style={{
                      fontSize: 14,
                      borderRadius: '30px',
                      height: '50px',
                      width: '100%',
                      border: '2px solid #dadada',
                      background: 'white',
                      maxWidth: 400,
                      minHeight: 50,
                    }}
                    expandedStyle={{
                      borderRadius: '30px 30px 0 0',
                    }}
                    className="origin-search"
                    placeholder="Search by city, airport, or ZIP code"
                    onChange={onSearchChange}
                    onSelect={onSearchSelect}
                    onSubmit={onSearchSubmit}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol sizeXl="7" sizeLg="12">
        <IonRow className="row-vertical-align title-and-searchbar-mobile">
          <IonCol className="title-col">
            <IonRow className="mb-1-5">
              <h2 className="title-font check-out-deals-title p-0">
                Check out the latest
                <br />
                deals from your area
              </h2>
            </IonRow>
            <IonRow className="row-vertical-align">
              <IonCol sizeLg="6" size="11">
                <div>
                  <SearchBarWithResults
                    style={{
                      fontSize: 14,
                      borderRadius: '30px',
                      height: '50px',
                      width: '100%',
                      border: '2px solid #dadada',
                      background: 'white',
                    }}
                    expandedStyle={{
                      borderRadius: '30px 30px 0 0',
                    }}
                    className="origin-search"
                    placeholder="Search by city, airport, or ZIP code"
                    onChange={onSearchChange}
                    onSelect={onSearchSelect}
                    onSubmit={onSearchSubmit}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        {deals.length != 0 && (
          <IonRow className="row-vertical-align carousel-wrapper-row">
            <IonCol>
              <IonRow className="mt-1">
                {viewLoaded && (
                  <Swiper
                    // Setting the key prop here prevents a React error on IonSlides update
                    // where "cannot find Node child"
                    key={sliderKey}
                    ref={slidesRef}
                    className={
                      isSmallScreenSizeOrSmaller
                        ? 'deals-carousel w-100'
                        : 'deals-carousel'
                    }
                    slidesPerView={
                      isExtraSmallScreenSize
                        ? 1
                        : isExtraLargeScreenSize
                        ? (width * 0.6) / CARD_WIDTH
                        : width / CARD_WIDTH
                    }
                    style={{ overflowX: 'scroll', minWidth: '100%' }}
                  >
                    {/* // TODO: BUG: Why doesn't typescript protect us from deal being undefined? */}
                    {[...deals].sort(sortBySeatClass).map((deal, i: number) => {
                      if (!deal || !deal.city) {
                        return;
                      }
                      return (
                        <SwiperSlide
                          key={`${deal.seatClass}#${deal.originIATA}#${deal.destinationIATA}#${i}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <DealCard
                            destinationDeals={{
                              deals: [],
                              activeDeal: deal,
                            }}
                            city={deal.city}
                            country={deal.state ?? deal.country}
                            sx={{ minWidth: 300 }}
                            openModalListener={async () => {
                              smoothScroll('#register');
                              await logAnalyticsLandingPageEngagement(
                                AnalyticsEngagementId.GET_DEALS
                              );
                            }}
                            showGetDealsButton={true}
                            originAirport={
                              origins.find(
                                (o) => o.iata === deal.originIATA
                              ) as Airport | undefined
                            }
                            locked={false}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </IonRow>
              <IonRow className="deals-carousel-arrows">
                <div className="arrow-wrapper" onClick={() => previousSlide()}>
                  <svg
                    width="29"
                    height="16"
                    viewBox="0 0 29 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="2"
                      y1="8"
                      x2="29"
                      y2="8"
                      stroke="#6EB3C1"
                      strokeWidth="2"
                    />
                    <path
                      d="M10 1L2 8L10 15"
                      stroke="#6EB3C1"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
                <div className="arrow-wrapper" onClick={() => nextSlide()}>
                  <svg
                    width="29"
                    height="16"
                    viewBox="0 0 29 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="-1"
                      x2="27"
                      y2="-1"
                      transform="matrix(-1 0 0 1 27 9)"
                      stroke="#6EB3C1"
                      strokeWidth="2"
                    />
                    <path
                      d="M19 1L27 8L19 15"
                      stroke="#6EB3C1"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </IonRow>
            </IonCol>
          </IonRow>
        )}
        {deals.length === 0 && !landingPageDealsState.isInitializing && (
          <IonRow className="row-vertical-align carousel-wrapper-row">
            <IonCol>
              <IonRow className="mt-1">
                <p
                  style={{
                    maxWidth: 500,
                    lineHeight: '2em',
                    color: '#00000050',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--ion-color-primary)',
                      fontSize: '2em',
                    }}
                  >
                    Hmmm.
                  </span>
                  &nbsp;&nbsp;&nbsp; Looks like we haven&apos;t been able to
                  find any great deals departing from that airport yet. But we
                  will keep searching!
                </p>
              </IonRow>
            </IonCol>
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
};

export default HomePageDealsSection;
