import { useEffect } from 'react';

import { AllowedPath } from '@faredrop/types';
import { hasActivePlan } from '@faredrop/utilities';

import useHistoryWithStickyParams from './historyWithStickyParams';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';
import useUser from './user';

const usePromotionRedirect = () => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const userState = useUser();

  useEffect(() => {
    if (
      !isFrontendSubscriptionPromotion(
        hasActivePlan(
          userState.user?.billing.idStripePlan,
          userState.user?.billing.subscriptionStatus
        )
      )
    ) {
      goWithStickyParamsPath(AllowedPath.BASE);
    }
  }, []);
};

export default usePromotionRedirect;
