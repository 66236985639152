import { ReactNode, useEffect, useRef } from 'react';
import useAppState from '../hooks/useAppState';
import useVisibility from '../hooks/visibility';
import './../theme/HeroSection.css';
import './../theme/util.css';

interface TrackVisibilityProps {
  children: ReactNode;
  onVisible: () => Promise<void>;
  options?: IntersectionObserverInit;
  style?: React.CSSProperties;
}

const TrackVisibility: React.FC<TrackVisibilityProps> = ({
  children,
  onVisible,
  options,
  style,
}) => {
  const visibilityOptions = options ?? {
    threshold: 0.4, // Default target visibility percent before callback is fired
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const isRefVisible = useVisibility(contentRef, visibilityOptions);
  const { activeTrigger } = useAppState();

  useEffect(() => {
    callOnVisible().catch((error) =>
      console.warn('Failed to call on visible for visibility tracking', error)
    );
  }, [isRefVisible, activeTrigger]);

  const callOnVisible = async () => {
    if (isRefVisible && onVisible) {
      await onVisible();
    }
  };

  return (
    <div ref={contentRef} style={style}>
      {children}
    </div>
  );
};

export default TrackVisibility;
