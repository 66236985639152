import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useDevice } from '../hooks/useDevice';

type ContainerProps = {
  modalListener?: (state: boolean) => void;
  active: boolean;
  hasToast?: boolean;
  size?: string;
  darkBg?: boolean;
  showAsFullPage?: boolean;
  hideCloseIcon?: boolean;
  height?: string;
  sx?: React.CSSProperties;
  closeButtonMargin?: string;
};

// We've built out this new Modal component because IonModal on mobile manifests as a slide-up drawer
// So in order to have a traditional modal on both mobile and desktop, we have to use this component
const MobileModal: React.FC<ContainerProps> = ({
  active,
  children,
  darkBg,
  hasToast,
  modalListener,
  showAsFullPage,
  size,
  hideCloseIcon,
  height,
  sx,
  closeButtonMargin,
}) => {
  const { isSmallScreenSizeOrSmaller } = useDevice();

  useEffect(() => {
    const content = document.querySelector(
      '#router ion-content'
    ) as HTMLElement;
    const header = document.querySelector('ion-header') as HTMLElement;
    if (active && header != null) {
      content.style.filter = 'blur(3px)';
      header.style.filter = 'blur(3px)';
    } else if (header != null) {
      content.style.filter = 'none';
      header.style.filter = 'none';
    }
  }, [active]);

  return (
    <div
      className="fd-modal-container"
      onClick={(e) => {
        e.stopPropagation();
        if (modalListener) modalListener(false);
      }}
      style={{
        visibility: active ? 'visible' : 'hidden',
        height: active ? height ?? '100%' : 0,
        overflowY: showAsFullPage ? 'scroll' : 'hidden',
        position: showAsFullPage ? 'relative' : 'absolute',
        padding: isSmallScreenSizeOrSmaller ? '1em 0' : 0,
        ...sx,
      }}
    >
      <IonCol size={size ?? '5'} style={{ padding: 0 }}>
        {hasToast && (
          <IonRow
            className="row-vertical-align"
            style={{
              backgroundColor: 'rgba(236, 255, 239, 1)',
              border: '2px solid #4C9457',
              borderRadius: 15,
              justifyContent: 'left',
              paddingLeft: '1em',
              marginBottom: 15,
              padding: '.5em 1em',
              display: active ? 'flex' : 'none',
            }}
          >
            <IonIcon
              icon={checkmarkOutline}
              style={{
                marginRight: '.3em',
                marginTop: 0,
                color: 'rgba(76, 148, 87, 1)',
              }}
            />
            <p style={{ color: 'rgba(76, 148, 87, 1)' }}>
              Your subscription has been cancelled
            </p>
          </IonRow>
        )}
        <IonRow
          className="fd-modal-content"
          style={{
            margin: 0,
            maxHeight: 'calc(100vh - 100px)',
            overflowY: 'scroll',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{
              float: 'right',
              margin: closeButtonMargin ?? '20px',
              position: 'absolute',
              right: '.25em',
              zIndex: 1,
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (modalListener) modalListener(false);
            }}
            className="pointer-on-hover dim-on-hover"
          >
            {!hideCloseIcon && (
              <IonIcon
                icon={closeOutline}
                style={{ margin: darkBg ? 10 : 0 }}
                className="close-icon"
              />
            )}
          </div>
          {children}
        </IonRow>
      </IonCol>
    </div>
  );
};

export default MobileModal;
