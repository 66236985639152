import { AllowedPath } from '@faredrop/types';
import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { TextField } from '@mui/material';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useAuth from '../hooks/auth';
import usePresentToast from '../hooks/presentToast';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';

const DeleteAccount: FC = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const userState = useUser();
  const { presentSuccess } = usePresentToast();
  const { verifyPasswordAndDeleteAccount, clearUser } = useAuth();
  const { isSmallScreenSizeOrSmaller } = useDevice();
  const history = useHistory();
  const [navigateToLogout, setNavigateToLogout] = useState(false);

  // After deleting account, we need to flush the authenticated user from state, so we need to wait until a re-render to navigate to logout screen
  useEffect(() => {
    if (navigateToLogout) {
      history.push({ pathname: AllowedPath.LOGOUT });
      clearUser(); // Clear user state, so authenticated requests aren't made to the server
    }
  }, [navigateToLogout]);

  return (
    <>
      {!showConfirm ? (
        <button
          style={{
            width: '15em',
            margin: '2em auto',
            height: '5em',
            padding: '1em',
            backgroundColor: 'transparent',
            borderRadius: '7px',
            border: '2px solid var(--ion-color-danger)',
            color: 'var(--ion-color-danger)',
          }}
          onClick={async (e) => {
            e.preventDefault();

            setShowConfirm(true);
          }}
        >
          Delete my account
        </button>
      ) : (
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={async (
            values,
            { setErrors, setSubmitting }
          ): Promise<void> => {
            try {
              if (userState.user?.profile.email) {
                const success = await verifyPasswordAndDeleteAccount(
                  values.password
                );

                if (!success) {
                  setErrors({
                    password:
                      'Something went wrong. Please contact team@faredrop.com',
                  });
                } else {
                  presentSuccess('Account data successfully deleted');
                  setNavigateToLogout(true);
                }
              } else {
                setErrors({
                  password:
                    'Something went wrong. Please contact team@faredrop.com',
                });
              }
            } catch (err) {
              setErrors({
                password:
                  'Something went wrong. Please contact team@faredrop.com',
              });
            }
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }): JSX.Element => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <IonRow>
                  <IonCol
                    style={{
                      paddingLeft: 0,
                      paddingBottom: 0,
                      paddingRight: isSmallScreenSizeOrSmaller ? 0 : 'auto',
                    }}
                  >
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      placeholder="*************"
                      value={values.password}
                      variant="outlined"
                      required
                    />
                    <h2 style={{ fontSize: 16 }}>
                      <span
                        style={{
                          fontFamily: 'nexa-bold',
                          color: 'var(--ion-color-danger)',
                        }}
                      >
                        WARNING: You are about delete all of your account data.
                      </span>
                      &nbsp; Doing so will result in any active subscriptions
                      with FareDrop being cancelled immediately. Are you sure
                      that you would like to continue?
                    </h2>
                    <IonRow style={{ display: 'flex' }}>
                      <IonCol sizeXl="6" sizeLg="12">
                        <button
                          style={{
                            margin: '2em auto',
                            borderRadius: 10,
                            width: '100%',
                            backgroundColor: 'var(--ion-color-primary)',
                            height: '5em',
                            color: 'white',
                            fontFamily: 'nexa-bold',
                            fontSize: 14,
                          }}
                          onClick={() => {
                            setShowConfirm(false);
                          }}
                        >
                          <p
                            style={{
                              color: 'white',
                              fontFamily: 'nexa-bold',
                              fontSize: 14,
                            }}
                          >
                            No, never mind!
                          </p>
                        </button>
                      </IonCol>
                      <IonCol sizeXl="6" sizeLg="12">
                        <button
                          disabled={isSubmitting}
                          style={{
                            margin: '2em auto',
                            border: '2px solid var(--ion-color-danger)',
                            borderRadius: 10,
                            width: '100%',
                            backgroundColor: 'white',
                            minHeight: '5.4em',
                          }}
                          type="submit"
                        >
                          {(isSubmitting && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <IonSpinner
                                style={{ margin: 'auto 0' }}
                                color="danger"
                                name="crescent"
                              />
                              <p
                                style={{
                                  margin: 'auto 0 auto 10px',
                                  color: 'var(--ion-color-danger)',
                                  fontFamily: 'nexa-bold',
                                  fontSize: 14,
                                }}
                              >
                                Processing . . .
                              </p>
                            </div>
                          )) || (
                            <p
                              style={{
                                color: 'var(--ion-color-danger)',
                                fontFamily: 'nexa-bold',
                                fontSize: 14,
                              }}
                            >
                              Yes, delete my account
                            </p>
                          )}
                        </button>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DeleteAccount;
