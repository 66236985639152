import { createContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';

import { AllowedPath } from '@faredrop/types';

import useAnalytics from '../hooks/analytics';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useImpact from '../hooks/useImpact';
import useAuth from '../hooks/auth';
import {
  allowedUninitializedBasePaths,
  allowedUninitializedUrls,
} from './authContext';
import { stripTrailingSlash } from '../utilities/utils';

// Since we set the RouterListenerProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const RouteListenerContext = createContext({});

interface RouteListenerProps {
  children: ReactNode;
}

export const RouteListenerProvider: FC<RouteListenerProps> = (props) => {
  const location = useLocation();
  const { logGoogleAnalyticsScreen } = useAnalytics();
  const { goWithStickyParamsLocation, removeUnauthenticatedStickyParams } =
    useHistoryWithStickyParams();
  const { impactIdentify } = useImpact();
  const { user, isInitialized, isAuthenticated } = useAuth();

  // Note: Not using history.listen callback since it doesn't get triggered for the initial route for some browsers (e.g., chrome)
  useEffect(() => {
    // Skip Get Started routes since they are logged manually
    if (location.pathname.indexOf('get-started') === -1) {
      logGoogleAnalyticsScreen(location.pathname).catch((error) =>
        console.warn(
          'Failed to log Google Analytics Screen',
          location?.pathname,
          error
        )
      ); // Async - fire and forget
    }

    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect');
    if (
      redirect &&
      location.pathname !== AllowedPath.LOGIN &&
      // If a non-authenticated user is viewing a deal and then signs up, they should be redirected to the deal, but they need to first register, select a plan, and then see /get-started/welcome page
      location.pathname !== AllowedPath.REGISTER &&
      location.pathname !== AllowedPath.PLANS &&
      location.pathname !== AllowedPath.GET_STARTED_WELCOME_FREE &&
      location.pathname !== AllowedPath.GET_STARTED_WELCOME_PAID
    ) {
      goWithStickyParamsLocation(
        {
          pathname: redirect,
          // We don't want refreshAccount to be sticky, but if refreshAccount is left out here, the account will never be refreshed
          search: queryParams.has('refreshAccount')
            ? '?refreshAccount=true'
            : undefined,
        },
        ['redirect']
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialized) {
      impactIdentify(user?.id, user?.email).catch((e) => {
        console.warn('Failed to call identity for Impact');
        console.warn(e);
      });
    }
  }, [location.pathname, isInitialized]);

  useEffect(() => {
    // Don't allow trailing "/"'s to prevent a user from accessing an unguarded page
    const normalizedPathName = stripTrailingSlash(location.pathname);

    if (
      isInitialized &&
      isAuthenticated &&
      !allowedUninitializedUrls.includes(normalizedPathName as AllowedPath) &&
      !allowedUninitializedBasePaths.some((p) =>
        normalizedPathName.startsWith(p)
      )
    ) {
      removeUnauthenticatedStickyParams();
    }
  }, [isInitialized, isAuthenticated, location.pathname]);

  return (
    <RouteListenerContext.Provider value={{}}>
      {props.children}
    </RouteListenerContext.Provider>
  );
};

export default RouteListenerContext;
