import {
  Deal,
  FareDropRole,
  SeatClass,
  User,
  UserConfigOrigin,
  UserDealsQuery,
} from '@faredrop/graphql-sdk';
import useFareDropApiClientWithTimeout from './faredropApiClientWithTimeout';
import moment from 'moment';
import useSWRInfiniteTimeout from './useSWRInfiniteTimeout';
import { useState } from 'react';
import useAnalytics from './analytics';
import useUser from './user';
import { isMatch } from '../utilities/deals-utilities';
import { buildOriginsKey } from '../utilities/swr-util';
import useUserDeals from './userDeals';
import { isLimitedPlan } from '../utilities/plans-utilities';

export interface UserDealsValue {
  deals?: UserDealsQuery['userDeals'];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  timeout?: boolean;
  userPastDealsKey: (
    userId: string,
    origins: UserConfigOrigin[],
    userRoles: FareDropRole[],
    pageIndex: number
  ) => string[];
  loadNextPage: () => Promise<void>;
}

const KEY_PREFIX = 'USER_PAST_DEALS';

let initialStartDate = moment().valueOf();
const initialEndDate = moment().subtract(2, 'days').valueOf();

const userPastDealsKey = (
  userId: string,
  origins: UserConfigOrigin[],
  userRoles: FareDropRole[],
  pageIndex: number
) => [
  KEY_PREFIX,
  process.env.REACT_APP_VERSION ?? '',
  userId,
  ...buildOriginsKey(origins),
  userRoles
    .map((role) => role.toString())
    .sort()
    .join(','),
  pageIndex.toString(),
];

const getKey = (
  pageIndex: number,
  previousPageData: UserDealsQuery['userDeals'] | null,
  user: User | undefined
) => {
  const profile = user?.profile;
  const userConfigOrigins = user?.configuration?.origins ?? [];
  if (
    !profile?.id ||
    (!profile?.roles.includes(FareDropRole.Global) &&
      !profile?.roles.includes(FareDropRole.Limited) &&
      !profile.roles.includes(FareDropRole.Pro)) ||
    (previousPageData && !previousPageData.length)
  )
    return null; // reached the end
  return userPastDealsKey(
    profile.id,
    userConfigOrigins,
    profile.roles,
    pageIndex
  );
};

const sortPastDeals = (a: Deal, b: Deal) => {
  // We sort here to avoid clumping business deals and economy deals separately in past deals
  if (a.idAirfareSource < b.idAirfareSource) {
    return -1;
  } else if (a.idAirfareSource > b.idAirfareSource) {
    return 1;
  }
  // idAirfareSource is the same - use score as the indicator of how good the deal is (and therefore its priority)
  return (a.score as number) - (b.score as number) ?? 0;
};

const useUserPastDeals = (): UserDealsValue => {
  const { client } = useFareDropApiClientWithTimeout();
  const [haveReachedEnd, setHaveReachedEnd] = useState(false);
  const { logAnalyticsError } = useAnalytics();

  const userState = useUser();
  const userProfile = userState.user?.profile;
  const userConfigOrigins = userState.user?.configuration?.origins ?? [];

  // Economy deals are from the past 2 days
  // Business deals are from the past 7 days
  // Past deals are 2 days ago and beyond
  // It's possible that deals shown in the business section will also appear in past deals, so we need to exclude them from past deals
  const exclude = useUserDeals(SeatClass.Business).deals ?? [];

  const checkExclusion = (deal: Deal) =>
    deal.airfares && // Don't show masked deals in Past FareDrops section
    !exclude.some((excludedDeal) => isMatch(deal, excludedDeal));

  const { data, error, setSize, size, isValidating, timeout } =
    useSWRInfiniteTimeout(
      `${KEY_PREFIX}#${process.env.REACT_APP_VERSION}#${userProfile?.id}`,
      (...args) => getKey(...args, userState.user),
      async (...scopedArgs) => {
        try {
          let pastDeals: UserDealsQuery['userDeals'] = [];
          if (userConfigOrigins.length === 0) return pastDeals;
          const pageIndex = parseInt(scopedArgs[scopedArgs.length - 1]);

          const seatClasses = isLimitedPlan(
            userState.user?.billing.idStripePlan
          )
            ? [SeatClass.Economy]
            : [SeatClass.Economy, SeatClass.Business];

          // Go back week by week until we find past deals for the past month
          if (pageIndex === 0) {
            haveReachedEnd && setHaveReachedEnd(false);
            initialStartDate = moment().valueOf();
            do {
              // Cap scan to 1 month
              const startDateMoment = moment(initialStartDate).subtract(
                1,
                'week'
              );
              if (moment(initialEndDate).diff(startDateMoment, 'months') > 0) {
                return [];
              }

              initialStartDate = startDateMoment.valueOf();
              pastDeals = (
                await client.userDeals({
                  seatClasses,
                  startDate: initialStartDate,
                  endDate: initialEndDate,
                })
              ).data.userDeals.filter(checkExclusion);
            } while (
              pastDeals.length < 5 &&
              moment().diff(moment(initialStartDate), 'months') < 1
            );
          } else {
            const startDate = moment(initialStartDate)
              .subtract(pageIndex, 'week')
              .valueOf();
            const endDate = moment(startDate).add(1, 'week').valueOf();

            pastDeals = (
              await client.userDeals({
                seatClasses,
                startDate,
                endDate,
              })
            ).data.userDeals;

            if (pastDeals.length === 0) {
              setHaveReachedEnd(true);
            }

            pastDeals = pastDeals.filter(checkExclusion);
          }

          return pastDeals.sort(sortPastDeals);
        } catch (error) {
          await logAnalyticsError('getUserPastDeals', error as Error);
          throw error;
        }
      },
      undefined,
      'Failed to get past deals. Please refresh the page'
    );

  const loadNextPage = async () => {
    if (!haveReachedEnd) {
      await setSize(size + 1);
    }
  };

  return {
    deals: data?.flat(),
    isInitializing: !error && !data,
    isLoading: isValidating,
    error,
    timeout,
    userPastDealsKey,
    loadNextPage,
  };
};

export default useUserPastDeals;
