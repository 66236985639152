import { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';

export enum DealsDashboardList {
  INTERNATIONAL_ECONOMY = 'INTERNATIONAL_ECONOMY',
  INTERNATIONAL_BUSINESS = 'INTERNATIONAL_BUSINESS',
  DOMESTIC_ECONOMY = 'DOMESTIC_ECONOMY',
  FILTERED = 'FILTERED',
  PAST = 'PAST',
}

export interface DealsDashboardContextValue {
  lastViewedDealIndex?: number;
  lastViewedDealList?: DealsDashboardList;
  setLastViewedDealIndex: (index: number) => void;
  setLastViewedDealList: (list: DealsDashboardList) => void;
}

// Since we set the DealsDashboardProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const DealsDashboardContext = createContext<DealsDashboardContextValue>({
  lastViewedDealIndex: undefined,
  lastViewedDealList: undefined,
  setLastViewedDealIndex: () => undefined,
  setLastViewedDealList: () => undefined,
});

interface DealsDashboardProps {
  children: ReactNode;
}

export const DealsDashboardProvider: FC<DealsDashboardProps> = (props) => {
  const [lastViewedDealIndex, setLastViewedDealIndex] = useState<number>();
  const [lastViewedDealList, setLastViewedDealList] =
    useState<DealsDashboardList>();

  return (
    <DealsDashboardContext.Provider
      value={{
        lastViewedDealIndex,
        lastViewedDealList,
        setLastViewedDealIndex,
        setLastViewedDealList,
      }}
    >
      {props.children}
    </DealsDashboardContext.Provider>
  );
};

export default DealsDashboardContext;
