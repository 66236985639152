import { IonMenu, IonContent, IonList, IonItem } from '@ionic/react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useAnalytics from '../hooks/analytics';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import './../theme/util.css';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  menuRef: React.RefObject<HTMLIonMenuElement>;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const SlideoutMenu: React.FC<ContainerProps> = ({ menuRef }) => {
  const { logAnalyticsLandingPageEngagement } = useAnalytics();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { smoothScroll } = useLandingPageNavigation();
  return (
    <IonMenu
      ref={menuRef}
      contentId="router"
      side="end"
      swipeGesture={false}
      className="slideout-menu"
    >
      <IonContent>
        <IonList style={{ margin: '2em 2em 0 1em' }}>
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              goWithStickyParamsPath('/login');
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.HEADER_LOGIN
              );
            }}
          >
            Login
          </IonItem>
          <IonItem
            onClick={async () => {
              smoothScroll('#pricing');
              await Promise.all([
                menuRef.current?.close(),
                logAnalyticsLandingPageEngagement(
                  AnalyticsEngagementId.HEADER_PRICING
                ),
              ]);
            }}
            style={{ lineHeight: '4em', cursor: 'pointer' }}
          >
            Pricing
          </IonItem>
          <IonItem
            onClick={async () => {
              smoothScroll('#how-it-works');
              await Promise.all([
                menuRef.current?.close(),
                logAnalyticsLandingPageEngagement(
                  AnalyticsEngagementId.HEADER_HOW_IT_WORKS
                ),
              ]);
            }}
            style={{ lineHeight: '4em', cursor: 'pointer' }}
          >
            How it works
          </IonItem>
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              goWithStickyParamsPath('/faq');
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.HEADER_FAQ
              );
            }}
          >
            FAQ
          </IonItem>
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              goWithStickyParamsPath('/reviews');
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.HEADER_WHY_FAREDROP
              );
            }}
          >
            Why FareDrop
          </IonItem>
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              goWithStickyParamsPath('/gift');
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.HEADER_GIFT
              );
            }}
          >
            Gift FareDrop
          </IonItem>
          {/* NOTE: Our desktop options are getting a bit crowded, so "Become an Affiliate" option is only shown on mobile slide out menu (as well as the footer for both mobile and desktop) */}
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              // Open in new tab so we don't lose sticky query params
              window.open(
                `${getAssetsBaseUrl()}/affiliates/become-an-affiliate.pdf`,
                '_blank'
              );
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.BECOME_AN_AFFILIATE
              );
            }}
          >
            Become an Affiliate
          </IonItem>
          <IonItem
            style={{ lineHeight: '4em', cursor: 'pointer' }}
            onClick={async () => {
              await menuRef.current?.close();
              window.location.href = `${process.env.REACT_APP_DAILY_DROP_REFERRAL_URL}`;
              await logAnalyticsLandingPageEngagement(
                AnalyticsEngagementId.DAILY_DROP
              );
            }}
          >
            <img
              src={`${getAssetsBaseUrl()}/icons/daily-drop.png`}
              style={{
                top: 0,
                right: 0,
                paddingBottom: '3px',
                width: '30px',
                paddingRight: '5px',
              }}
            />
            Daily Drop
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SlideoutMenu;
