import { IonRow } from '@ionic/react';

import './../theme/util.css';
import {
  HIDE_MEMBERSHIP_LINKS,
  SubscriptionPromotionUpgradeCTA,
} from '../utilities/constants';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';

interface ContainerProps {
  linkText?: string;
  message: string;
  isLimitedPlan?: boolean;
  style?: React.CSSProperties;
}

const LimitedUpgradeCTA: React.FC<ContainerProps> = ({
  isLimitedPlan,
  message,
  style,
  linkText,
}) => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const link = linkText ?? 'Upgrade';

  return (
    <>
      {isLimitedPlan && !HIDE_MEMBERSHIP_LINKS && (
        <IonRow
          style={{
            width: '100%',
            borderStyle: 'solid',
            borderColor: 'var(--ion-color-primary)',
            borderWidth: '3px',
            borderRadius: '5px',
            marginTop: '1em',
            ...style,
          }}
        >
          <p
            style={{
              fontFamily: 'nexa-bold',
              color: 'black',
              margin: '1em',
              lineHeight: '1.45',
            }}
          >
            <a
              onClick={() => {
                goWithStickyParamsPath('/plans');
              }}
            >
              {link}
            </a>
            {link.length > 0 ? ' ' : ''}
            {message}
            {isFrontendSubscriptionPromotion(isLimitedPlan)
              ? ` ${SubscriptionPromotionUpgradeCTA}`
              : ''}
          </p>
        </IonRow>
      )}
    </>
  );
};

export default LimitedUpgradeCTA;
