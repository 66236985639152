import { IonContent, IonPage, IonRow } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import HidingHeader from '../components/HidingHeader';
import MobileHeader from '../components/MobileHeader';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDevice } from '../hooks/useDevice';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import './../theme/util.css';
import Footer from '../components/Footer';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import AlternateLandingHeroSection from '../components/AlternateLandingHeroSection';
import CreatedBySection from '../components/CreatedBySection';
import { GetStartedBlock } from '../components/GetStartedBlock';
import useAnalytics from '../hooks/analytics';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const About: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { isLargeScreenSizeOrSmaller, isExtraSmallScreenSize } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const dynamicBannerContent = useDynamicBanner();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { logAnalyticsAboutPageEngagement } = useAnalytics();

  const howItWorks =
    'We have a team of flight experts who look through flight data daily and select only the best deals. If those deals match your travel preferences, we’ll notify you via email or our mobile app. Simply click on your deal notification and book directly with the airline. Enjoy the savings so you can travel more and spend less!';

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
        logAnalyticsEngagement={logAnalyticsAboutPageEngagement}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader
            loggedIn={false}
            logAnalyticsEngagement={logAnalyticsAboutPageEngagement}
          />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <AlternateLandingHeroSection
          logAnalyticsEngagement={logAnalyticsAboutPageEngagement}
          title="FareDrop is helping people spend less and travel more."
          imageSrc={`${getAssetsBaseUrl()}/places/cook-islands.jpg`}
          subtitle="Get notified of the best flight deals from your home airport to your dream destinations. Our users save an average of $450 on every flight booked."
          hideImageOnMobile={true}
        />
        <IonRow className={isExtraSmallScreenSize ? undefined : 'px-3'}>
          <CreatedBySection
            logAnalyticsEngagement={logAnalyticsAboutPageEngagement}
          />
        </IonRow>
        <GetStartedBlock
          content={
            <IonRow
              className="mb-1-5"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1 className="title-font">How it Works</h1>
              {isLargeScreenSizeOrSmaller ? (
                <h6>{howItWorks}</h6>
              ) : (
                <h3>{howItWorks}</h3>
              )}
            </IonRow>
          }
          logAnalyticsEngagement={logAnalyticsAboutPageEngagement}
        />
        <Footer bgColor="secondary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default About;
