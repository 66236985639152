import { createContext, useLayoutEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import useAnalytics from '../hooks/analytics';
import { sleep } from '@faredrop/utilities';

export interface GleamContextValue {
  isGleamReady: boolean;
  trackGleamIdCognito: (idCognito: string) => void;
}

// Since we set the GleamProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const GleamContext = createContext<GleamContextValue>({
  isGleamReady: false,
  trackGleamIdCognito: () => undefined,
});

interface GleamPropsProps {
  children: ReactNode;
}

export const GleamProvider: FC<GleamPropsProps> = (props) => {
  const [gleamInitialized, setGleamInitialized] = useState(false);
  const sourceCheckCount = useRef(0);
  const { logAnalyticsError } = useAnalytics();

  useLayoutEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://js.gleam.io/bYMkF/trk.js';
    script.async = true;

    document.body.appendChild(script);
    checkReady().catch(() => {
      logAnalyticsError(
        'gleamCheckReady',
        new Error('Failed to check if Gleam is ready')
      ).catch((error) =>
        console.warn('Failed to track Gleam is ready error', error)
      );
    }); // Async - fire and forget
  }, []);

  const trackGleamIdCognito = async (idCognito: string) => {
    let attempts = 0;
    let success = false;
    do {
      try {
        attempts++;
        //@ts-expect-error Gleam is sourced
        if (Gleam && typeof Gleam !== 'undefined') {
          //@ts-expect-error Gleam is sourced
          Gleam.push(['idCognito', idCognito]);
          success = true;
        }
      } catch (error) {
        if (!(error instanceof ReferenceError)) {
          throw error;
        }
      }

      await sleep(500);
    } while (attempts < 20 && !success);
  };

  const checkReady = async () => {
    // 8 seconds
    if (sourceCheckCount.current < 16) {
      sourceCheckCount.current += 1;

      try {
        //@ts-expect-error Gleam is sourced
        if (Gleam && typeof Gleam !== 'undefined') {
          setGleamInitialized(true);
        } else {
          setTimeout(checkReady, 500);
        }
      } catch (error) {
        if (error instanceof ReferenceError) {
          setTimeout(checkReady, 500);
        }
      }
    } else {
      await logAnalyticsError(
        'gleamInitialization',
        new Error('Gleam exceeded 8 second initialization limit')
      );
    }
  };

  return (
    <GleamContext.Provider
      value={{
        isGleamReady: gleamInitialized,
        trackGleamIdCognito: trackGleamIdCognito,
      }}
    >
      {props.children}
    </GleamContext.Provider>
  );
};

export default GleamContext;
