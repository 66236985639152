import { Deal, SeatClass, SharedDealQuery } from '@faredrop/graphql-sdk';
import useAnalytics from './analytics';
import useFareDropApiClient from './faredropApiClient';
import useUser from './user';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import { IDestinationDeals } from '../components/DealCard';
import { dealMatchesPreferences } from '../utilities/deals-utilities';

export interface IActiveOrigins {
  [destinationIATA: string]: string; // destinationIATA: originIATA
}

export interface IFilteredDeals {
  updated: IDestinationDeals[];
  filtered: IDestinationDeals[];
}

export interface DealsValue {
  getSharedDeal: (
    idAirfareSource: string,
    originIATA: string,
    destinationIATA: string,
    seatClass: SeatClass,
    sharedBy: string,
    minPrice: number,
    signature: string
  ) => Promise<SharedDealQuery['sharedDeal']>;
  filterAndGroupDeals: (deals: Deal[]) => IFilteredDeals;
}

const useDeals = (): DealsValue => {
  const { client } = useFareDropApiClient();
  const { logAnalyticsError } = useAnalytics();
  const userState = useUser();

  const roles = userState.user?.profile.roles;

  const getSharedDeal = async (
    idAirfareSource: string,
    originIATA: string,
    destinationIATA: string,
    seatClass: SeatClass,
    sharedBy: string,
    minPrice: number,
    signature: string
  ) => {
    try {
      const sharedDealResult = await client.sharedDeal({
        idAirfareSource,
        originIATA,
        destinationIATA,
        seatClass,
        sharedBy,
        minPrice,
        signature,
      });
      return sharedDealResult.data.sharedDeal;
    } catch (error) {
      await logAnalyticsError('getSharedDeal', error as Error);
      throw error;
    }
  };

  const filterAndGroupDeals = (deals: Deal[]): IFilteredDeals => {
    const updated: Deal[] = [];
    const filtered: Deal[] = [];

    deals.forEach((deal) => {
      // Locked business deals should appear in Business section, so don't filter
      if (
        hasSeatClassAccessGQL(roles, deal.seatClass) &&
        !dealMatchesPreferences(deal, userState.user)
      ) {
        // Don't show masked deals in Filtered FareDrops
        if (deal.airfares) {
          filtered.push(deal);
        }
        return;
      }

      updated.push(deal);
    });

    return {
      updated: groupDealsByDestination(updated),
      filtered: groupDealsByDestination(filtered),
    };
  };

  // Deals from our server are already sorted by destinations, so we just need group into IDestinationDeals
  const groupDealsByDestination = (deals: Deal[]) => {
    const groupedDeals: IDestinationDeals[] = [];
    let previousDestinationIATA: string | undefined;
    let outerIndex = -1;
    deals.forEach((deal) => {
      if (deal.destinationIATA !== previousDestinationIATA) {
        previousDestinationIATA = deal.destinationIATA ?? undefined;
        outerIndex++;
      }
      if (groupedDeals[outerIndex] === undefined) {
        groupedDeals[outerIndex] = {
          deals: [deal],
          activeDeal: deal,
          keyDeal: deal,
        };
      } else {
        groupedDeals[outerIndex].deals.push(deal);
      }
    });
    return groupedDeals;
  };

  return {
    getSharedDeal,
    filterAndGroupDeals,
  };
};

export default useDeals;
