import { Airport, UserConfigOrigin } from '@faredrop/graphql-sdk';

export const userConfigOriginFromIata = (iata: string) => {
  return {
    iata,
    businessDealsLastMonth: 0,
    economyDealsLastMonth: 0,
    dealsLastMonth: 0,
  } as UserConfigOrigin;
};

export const createAirportSearchText = (
  airport: Airport,
  includeParentIata = false
) => {
  return `${airport.city}${
    !includeParentIata && airport.subAirports ? '' : ` · ${airport.iata}`
  }`;
};
