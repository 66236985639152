import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { IonButton, IonContent, IonIcon, IonPage, IonRow } from '@ionic/react';
import produce from 'immer';
import { chevronBack } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { $enum } from 'ts-enum-util';

import { FirebasePushID } from '@faredrop/firebase-push-id';
import {
  Deal,
  DealStatus,
  PointsProgram,
  SeatClass as SeatClassGQL,
  SubscriptionStatus,
  SupportedCurrencies,
  TravelPortal,
  VerifiedAirfare,
} from '@faredrop/graphql-sdk';
import {
  AirfareProvider,
  AirportRegion,
  AirportSubRegion,
  AllowedPath,
  SeatClass,
  FlightDesignation,
  VerifiedAirfareAttributes,
} from '@faredrop/types';
import {
  clientDealIsLieFlat,
  getAssetsBaseUrl,
  getFlightDesignationFromProvider,
  getPriceMapping,
  isAirfareExpired,
  isClientVerifiedAirfareAttributeEnabled,
  isLimitedPlan,
} from '@faredrop/utilities';

import './../theme/util.css';

import useTabs from '../hooks/useTabs';
import { Tab } from '../contexts/tabContext';
import useUser from '../hooks/user';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import useDealDetails from '../hooks/useDealDetails';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import DesktopHeader from '../components/DesktopHeader';
import HidingHeader from '../components/HidingHeader';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import Footer from '../components/Footer';
import DealDetailsHero from '../components/DealDetails/DealDetailsHero';
import DealDetailsAirfares from '../components/DealDetails/DealDetailsAirfares';
import DealDetailsPriceRange from '../components/DealDetails/DealDetailsPriceRange';
import DealDetailsStats from '../components/DealDetails/DealDetailsStats';
import useAirports from '../hooks/airports';
import DealDetailsMoreDeals from '../components/DealDetails/DealDetailsMoreDeals';
import useAnalytics from '../hooks/analytics';
import DealDetailsAirfareDetails from '../components/DealDetails/DealDetailsAirfareDetails';
import MobileHeader from '../components/MobileHeader';
import UpgradeModal from '../components/UpgradeModal';
import {
  HIDE_MEMBERSHIP_LINKS,
  QUERY_PARAM_HIDE_EXPIRY_MESSAGING,
} from '../utilities/constants';
import {
  convertProtoBufDealStatusToDealStatus,
  convertProtoBufSeatClassToSeatClass,
  decodeDealQueryParam,
  ensureDestinationsStackOrder,
  isDealExpired,
} from '../utilities/deals-utilities';
import useStatusBar from '../hooks/useStatusBar';
import DealDetailsOtherDepartureAirportDeals from '../components/DealDetails/DealDetailsOtherDepartureAirportDeals';
import useUserDeals from '../hooks/userDeals';
import useDeals from '../hooks/deals';
import PointEstimatePills from './PointEstimatePills';
import RegisterModal from '../components/RegisterModal';
import useAuth from '../hooks/auth';
import DealDetailsRegisterOrUpgrade from '../components/DealDetails/DealDetailsRegisterOrUpgrade';
import DealDetailsNotice from '../components/DealDetails/DealDetailsNotice';
import useOtherDepartureAirportDeals from '../hooks/useOtherDepartureAirportDeals';
import DealDetailsPlansModal from '../components/DealDetails/DealDetailsPlansModal';
import DealDetailsShareModal from '../components/DealDetails/DealDetailsShareModal';
import useKlaviyo from '../hooks/useKlaviyo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

// NOTE: This page has several different states depending on several factors
// 1. Pro account - complete deal visibility, all "more from origin" deals are unlocked, all other "from your airports" deals are unlocked
// 2. Limited account with access to deal - complete deal visibility, all "more from origin" deals are locked if user originally does't have access, all other "from your airports" deals are locked
// 3. Limited account without access to deal - limited deal visibility, all "more from origin" deals are locked if user originally does't have access, all other "from your airports" deals are locked, booking buttons open upgrade modal, and CTA for upgrade
// 4. Limited account with affiliate query param - complete deal visibility, all "more from origin" deals are locked if user originally does't have access, all other "from your airports" deals are locked
// 5. No account - limited visibility, all "more from origin" deals are locked, all other "from your airports" deals are locked, booking buttons open register modal, and CTA for register
// 6. No account with affiliate query param - complete deal visibility, all "more from origin" deals are locked, all other "from your airports" deals are locked, and CTA for register

const DealDetails: React.FC = () => {
  const { updateSelectedTab } = useTabs();
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const {
    isApp,
    isExtraLargeScreenSize,
    isScreenLargerThanSize,
    isSmallScreenSizeOrSmaller,
    isExtraSmallScreenSize,
    isMediumScreenSizeOrSmaller,
  } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const dynamicBannerContent = useDynamicBanner();
  const location = useLocation();
  const userState = useUser();
  const { isInitialized } = useAuth();
  const { trackGoogleFlightsLinkClickKlaviyoEvent } = useKlaviyo();
  const { filterAndGroupDeals } = useDeals();
  const { getAirportOrSubAirport } = useAirports();
  const { logAnalyticsViewAirfare, appTrackingStatus } = useAnalytics();
  const statusBarTop = useStatusBar();
  const [upgradeMessage, setUpgradeMessage] = useState<string>();
  const [registerMessage, setRegisterMessage] = useState<string>();
  const componentRef = useRef<HTMLIonContentElement>(null);
  const [travelPortals, setTravelPortals] = useState<TravelPortal[]>();
  const [hideExpiryMessaging, setHideExpiryMessaging] =
    useState<boolean>(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [dealArgs, setDealArgs] = useState<{
    idAirfareSource: string;
    originIATA: string;
    destinationIATA: string;
    seatClass: SeatClassGQL;
    dealStatus: DealStatus;
  }>();

  const dealsState = useUserDeals(dealArgs?.seatClass);

  const { originIATA, destinationIATA, seatClass } = dealArgs ?? {};

  const { dealDetails, isLoading } = useDealDetails(location.search);

  const { otherDepartureAirportDeals } = useOtherDepartureAirportDeals(
    location.search
  );

  const [selectedAirfare, setSelectedAirfare] = useState<VerifiedAirfare>();

  const noInternet = userState.timeout;
  const deal = dealDetails?.deal;
  const routeAggregation = dealDetails?.routeAggregation;
  const verifiedAirfares = dealDetails?.verifiedAirfares;
  const bestAirfaresByMonth = dealDetails?.airfares;

  const origin = getAirportOrSubAirport(originIATA);
  const destination = getAirportOrSubAirport(destinationIATA);
  const flightDesignation = deal
    ? getFlightDesignationFromProvider(
        $enum(AirfareProvider).asValueOrThrow(deal.provider)
      )
    : undefined;

  // Filter out masked deals and group by destination
  const filteredAndGroupedDeals = filterAndGroupDeals(dealsState.deals ?? []);

  // Ensure that all stacks have the current origin on top
  const dealsInOrder = originIATA
    ? ensureDestinationsStackOrder(
        filteredAndGroupedDeals.updated,
        undefined,
        originIATA
      )
    : [];

  const uniqueDestinationDeals =
    dealsInOrder?.filter(
      (d) => d.activeDeal.destinationIATA !== destinationIATA
    ) ?? [];
  const uniqueOriginDestinationDeals = dealsInOrder
    .find((d) => d.activeDeal.destinationIATA === destinationIATA)
    ?.deals.filter((d) => d.originIATA !== originIATA);

  // Make sure "More deals from x airport" only shows deals from the same dashboard row
  const sameRowDestinationDeals: {
    deals: Deal[];
    activeDeal: Deal;
    keyDeal: Deal;
  }[] = [];
  uniqueDestinationDeals.forEach((dealObj) => {
    if (
      dealObj.activeDeal.originIATA === origin?.iata &&
      ((seatClass === SeatClassGQL.Business &&
        dealObj.activeDeal.seatClass === SeatClassGQL.Business) ||
        (flightDesignation === FlightDesignation.DOMESTIC &&
          getFlightDesignationFromProvider(
            $enum(AirfareProvider).asValueOrThrow(dealObj.activeDeal.provider)
          ) === FlightDesignation.DOMESTIC) ||
        (flightDesignation === FlightDesignation.INTERNATIONAL &&
          getFlightDesignationFromProvider(
            $enum(AirfareProvider).asValueOrThrow(dealObj.activeDeal.provider)
          ) === FlightDesignation.INTERNATIONAL))
    ) {
      sameRowDestinationDeals.push(dealObj);
    }
  });

  const buildRedirectSearchParams = (plan?: string) => {
    const params = new URLSearchParams(location.search);
    params.delete('redirect');
    params.delete('airfare');
    params.delete('selected-plan');

    params.set('redirect', window.location.pathname);
    params.set('cta', FirebasePushID.generate());
    if (selectedAirfare) {
      params.set('airfare', selectedAirfare.departureDate);
    }
    if (plan) {
      params.set('selected-plan', plan);
    }

    return params.toString();
  };

  const goToBookingUrl = (url: string) => {
    window
      .open(`${url}${deal?.currency ? `&curr=${deal.currency}` : ''}`, '_blank')
      ?.focus();
  };

  const goToBookingUrlWithChecks = async (
    verifiedAirfare?: Pick<
      VerifiedAirfare,
      | 'totalPrice'
      | 'returnDate'
      | 'departureDate'
      | 'bookingUrl'
      | 'departureUrl'
    >
  ) => {
    verifiedAirfare = verifiedAirfare || selectedAirfare;
    const url = verifiedAirfare?.bookingUrl || verifiedAirfare?.departureUrl;
    if (url) {
      if (noUser) {
        setShowPlansModal(true);
      } else {
        goToBookingUrl(url);
      }
      if (deal && verifiedAirfare?.totalPrice && verifiedAirfare?.returnDate) {
        const analyticsAirfare = {
          departureDate: verifiedAirfare.departureDate,
          price: verifiedAirfare.totalPrice ?? undefined,
          returnDate: verifiedAirfare.returnDate ?? undefined,
          url,
        };
        await logAnalyticsViewAirfare(deal, analyticsAirfare);
        // NOTE: Unfortunately, we can't make klaviyo hook calls in analytics context due to dependency order
        Capacitor.getPlatform() !== 'ios' || appTrackingStatus === 'authorized'
          ? trackGoogleFlightsLinkClickKlaviyoEvent(analyticsAirfare, deal)
          : undefined;
      }
    } else {
      if (limitedUserWithNoAccess) {
        setUpgradeMessage(
          'Looking to book this deal? Upgrade to a Pro subscription to get full access.'
        );
      } else {
        setRegisterMessage('Booking a FareDrop requires a subscription.');
      }
    }
  };

  useEffect(() => {
    updateSelectedTab(Tab.DEALS).catch((error) => {
      console.warn('Failed to update selected tab', Tab.DEALS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  useEffect(() => {
    // If the dda query param doesn't exist, redirect to the deals page
    const queryParams = new URLSearchParams(location.search);
    const encodedDeal = queryParams.get('dda');
    if (!encodedDeal) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    } else {
      const decoded = decodeDealQueryParam(encodedDeal);
      setDealArgs({
        idAirfareSource: decoded.idAirfareSource,
        originIATA: decoded.originIATA,
        destinationIATA: decoded.destinationIATA,
        seatClass: convertProtoBufSeatClassToSeatClass(decoded.seatClass),
        dealStatus: convertProtoBufDealStatusToDealStatus(decoded.status),
      });
    }

    if (queryParams.has(QUERY_PARAM_HIDE_EXPIRY_MESSAGING)) {
      setHideExpiryMessaging(true);
    }
  }, [location.search]);

  // Update miles and points availability depending on the airfare
  useEffect(() => {
    if (selectedAirfare) {
      const isSelectedAirfareBudget = isClientVerifiedAirfareAttributeEnabled(
        VerifiedAirfareAttributes.BUDGET_AIRLINE,
        selectedAirfare?.attributes ?? []
      );
      const updatedTravelPortals: TravelPortal[] = [];
      for (const travelPortal of dealDetails?.travelPortals ?? []) {
        const updatedTravelPortal = produce(travelPortal, (draft) => {
          const updatedPointsPrograms: PointsProgram[] = [];
          for (const pointsProgram of travelPortal.pointsPrograms) {
            if (
              pointsProgram.originalCurrency ===
              (selectedAirfare?.currency ?? SupportedCurrencies.Usd)
            ) {
              if (
                !isSelectedAirfareBudget ||
                (isSelectedAirfareBudget &&
                  (pointsProgram.budgetAirlines ||
                    (selectedAirfare?.departingRoute &&
                      selectedAirfare?.departingRoute[0].airlineIATA &&
                      pointsProgram.budgetAirlineExceptions?.includes(
                        selectedAirfare.departingRoute[0].airlineIATA
                      ))))
              ) {
                updatedPointsPrograms.push(pointsProgram);
              }
            }
          }
          draft.pointsPrograms = updatedPointsPrograms;
        });

        if (updatedTravelPortal.pointsPrograms.length) {
          updatedTravelPortals.push(updatedTravelPortal);
        }
      }
      setTravelPortals(updatedTravelPortals);
    }
  }, [dealDetails?.travelPortals, selectedAirfare?.departureDate]);

  const showPriceRange = verifiedAirfares?.some(
    (a) => (a.totalPrice ?? 0) < (routeAggregation?.meanMinPrice90Days ?? 0)
  );
  const bookingUrl =
    selectedAirfare?.bookingUrl || selectedAirfare?.departureUrl;

  const noUser = !userState.user && isInitialized;
  const hasAccess = !!deal?.airfares;
  const isLimitedUser = isLimitedPlan(
    userState.user?.billing.idStripePlan ?? undefined
  );
  const limitedUserWithNoAccess = isLimitedUser && !hasAccess;
  const showDueToAccessOrUpgradePush =
    !!bookingUrl || noUser || limitedUserWithNoAccess;
  const dealIsExpired = isDealExpired(deal) && !hideExpiryMessaging;
  const selectedAirfareIsExpired =
    isAirfareExpired(selectedAirfare) && !hideExpiryMessaging;

  return (
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {!isApp && (!isSmallScreenSizeOrSmaller || noUser) ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            promotionText={dynamicBannerContent}
            bannerClickListener={() => {
              goWithStickyParamsLocation({
                pathname: AllowedPath.REGISTER,
                search: buildRedirectSearchParams(),
              });
            }}
          >
            {isExtraLargeScreenSize ? (
              <DesktopHeader
                optionsHidden={noUser}
                loggedIn={!!userState.user}
                optionsExcludeQueryParams={['dda', 'airfare']}
                getStartedPath={AllowedPath.REGISTER}
                search={buildRedirectSearchParams()}
              />
            ) : (
              <MobileHeader optionsExcludeQueryParams={['dda', 'airfare']} />
            )}
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '.5em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          ref={componentRef}
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
        >
          <IonRow
            style={{
              paddingTop: isExtraLargeScreenSize
                ? '6em'
                : !isSmallScreenSizeOrSmaller
                ? '5em'
                : 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isSmallScreenSizeOrSmaller && (
              <IonRow style={{ position: 'absolute', top: 0, width: '100%' }}>
                <img
                  className="alternate-landing-hero"
                  src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom.png`}
                  style={{
                    top: isScreenLargerThanSize(1352) ? '-550px' : '-325px',
                  }}
                />
              </IonRow>
            )}
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '1500px',
                width: isExtraLargeScreenSize
                  ? '85%'
                  : isSmallScreenSizeOrSmaller
                  ? '100%'
                  : '90%',
              }}
            >
              {userState.user?.billing.subscriptionStatus ===
              SubscriptionStatus.Active ? (
                <IonRow
                  style={{
                    display: 'flex',
                    flex: 1,
                    ...(isSmallScreenSizeOrSmaller
                      ? {
                          cursor: 'pointer',
                          position: 'absolute',
                          top:
                            isApp && statusBarTop
                              ? `${statusBarTop}px`
                              : '10px',
                          left: '10px',
                          fontSize: '12px',
                        }
                      : {}),
                  }}
                  onClick={() => {
                    goWithStickyParamsPath(AllowedPath.DEALS, undefined, [
                      'dda',
                      'airfare',
                    ]);
                  }}
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      ...(isSmallScreenSizeOrSmaller
                        ? {
                            display: 'flex',
                            backgroundColor: 'white',
                            padding: '2px 8px 2px 0px',
                            borderRadius: '5px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }
                        : {}),
                    }}
                  >
                    <IonIcon icon={chevronBack} />
                    <div>Back to Dashboard</div>
                  </div>
                </IonRow>
              ) : (
                <IonRow style={{ width: '100%', height: '22px' }}></IonRow>
              )}
              {!!(originIATA && destinationIATA && seatClass) && (
                <DealDetailsHero
                  originIATA={originIATA}
                  destinationIATA={destinationIATA}
                  seatClass={seatClass}
                  deal={deal}
                  onShare={async () => {
                    if (
                      isSmallScreenSizeOrSmaller &&
                      (await Share.canShare()).value
                    ) {
                      await Share.share({
                        url: `${process.env.REACT_APP_NO_LOCALHOST_URL_ORIGIN}${AllowedPath.DEAL_DETAILS}?dda=${deal?.dda}`,
                      });
                    } else {
                      setShowShareModal(true);
                    }
                  }}
                />
              )}
              {(dealIsExpired || noUser) && (
                <DealDetailsNotice
                  deal={deal}
                  noUser={noUser}
                  onSignUpClick={() => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.REGISTER,
                      search: buildRedirectSearchParams(),
                    });
                  }}
                />
              )}
              <DealDetailsAirfares
                deal={deal}
                isLoading={isLoading}
                verifiedAirfares={verifiedAirfares}
                bestAirfaresByMonth={bestAirfaresByMonth}
                airfaresPerView={
                  isExtraLargeScreenSize ? 7 : isExtraSmallScreenSize ? 1 : 5
                }
                onSelect={(departureDate) => {
                  const airfare = verifiedAirfares?.find(
                    (a) => a.departureDate === departureDate
                  );
                  if (airfare) {
                    setSelectedAirfare(airfare);
                  }
                }}
                onBook={goToBookingUrlWithChecks}
              />
              {!!travelPortals?.length &&
                selectedAirfare?.totalPrice != null && (
                  <PointEstimatePills
                    travelPortals={travelPortals}
                    price={selectedAirfare.totalPrice}
                  />
                )}
              {!isLoading &&
                selectedAirfare?.departingRoute &&
                selectedAirfare.returningRoute && (
                  <IonRow style={{ width: '100%' }}>
                    <DealDetailsAirfareDetails
                      hasAccess={hasAccess}
                      airfare={selectedAirfare}
                      isLoading={isLoading}
                      onHiddenDetailClick={() => {
                        if (limitedUserWithNoAccess) {
                          setUpgradeMessage(
                            'You need a Pro subscription to view flight details.'
                          );
                        } else {
                          setRegisterMessage(
                            'Viewing flight details requires a subscription.'
                          );
                        }
                      }}
                    />
                  </IonRow>
                )}
              {!HIDE_MEMBERSHIP_LINKS &&
                !!dealDetails &&
                (noUser || isLimitedUser) && (
                  <DealDetailsRegisterOrUpgrade
                    hasAccess={hasAccess}
                    noUser={noUser}
                    search={buildRedirectSearchParams()}
                  />
                )}
              {destination?.region &&
                routeAggregation?.dealMeanPrice2Years &&
                routeAggregation.lastDealPrice &&
                routeAggregation.bestDealPrice && (
                  <IonRow
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '3em',
                      marginTop: isMediumScreenSizeOrSmaller
                        ? '2em'
                        : undefined,
                    }}
                  >
                    {!!(originIATA && destinationIATA && seatClass) && (
                      <DealDetailsStats
                        normalPrice={deal?.historicalMeanPrice}
                        threshold={getPriceMapping(
                          { iata: originIATA },
                          {
                            iata: destinationIATA,
                            region: $enum(AirportRegion).asValueOrThrow(
                              destination.region
                            ),
                            subRegion: destination.subRegion
                              ? $enum(AirportSubRegion).asValueOrThrow(
                                  destination.subRegion
                                )
                              : undefined,
                          },
                          $enum(SeatClass).asValueOrThrow(
                            seatClass.toLowerCase()
                          ),
                          clientDealIsLieFlat(deal?.attributes ?? [])
                        )}
                        averageDealPrice={
                          routeAggregation?.dealMeanPrice2Years ?? undefined
                        }
                        lastDealPrice={
                          routeAggregation?.lastDealPrice ?? undefined
                        }
                        daysSinceDeal={
                          routeAggregation.daysSinceDeal ?? undefined
                        }
                        bestDealPrice={
                          routeAggregation?.bestDealPrice ?? undefined
                        }
                        daysSinceBestDeal={
                          routeAggregation.daysSinceBestDeal ?? undefined
                        }
                      />
                    )}
                  </IonRow>
                )}
              {showPriceRange && (
                <IonRow
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '3em',
                  }}
                >
                  <DealDetailsPriceRange
                    price={
                      selectedAirfare?.totalPrice
                        ? Math.floor(selectedAirfare?.totalPrice)
                        : undefined
                    }
                    meanMinPrice={
                      routeAggregation?.meanMinPrice90Days ?? undefined
                    }
                    meanPrice={routeAggregation?.meanPrice90Days ?? undefined}
                    currency={routeAggregation?.currency ?? undefined}
                  />
                </IonRow>
              )}
              {dealDetails &&
                showDueToAccessOrUpgradePush &&
                !selectedAirfareIsExpired && (
                  <IonRow
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '3em',
                    }}
                  >
                    <IonButton
                      onClick={async () => await goToBookingUrlWithChecks()}
                    >
                      <p style={{ color: 'white', fontSize: '20px' }}>
                        Book on Google Flights
                      </p>
                    </IonButton>
                  </IonRow>
                )}
              {!!(
                uniqueOriginDestinationDeals?.length ||
                otherDepartureAirportDeals?.length
              ) && (
                <DealDetailsOtherDepartureAirportDeals
                  deals={
                    uniqueOriginDestinationDeals?.length
                      ? uniqueOriginDestinationDeals
                      : otherDepartureAirportDeals ?? []
                  }
                  onSelectDeal={async (deal) => {
                    if (deal.airfares) {
                      goWithStickyParamsLocation(
                        {
                          pathname: AllowedPath.DEAL_DETAILS,
                          search: `?dda=${deal.dda}`,
                        },
                        ['airfare']
                      );
                      if (componentRef.current) {
                        await componentRef.current.scrollToTop();
                      }
                    } else {
                      if (isLimitedUser) {
                        setUpgradeMessage(
                          'Unlock this deal and unlimited deals to over 250 other destinations by upgrading to a Pro subscription.'
                        );
                      } else {
                        setRegisterMessage('Want to see more deals?');
                      }
                    }
                  }}
                />
              )}
              {!!origin &&
                !!flightDesignation &&
                !!seatClass &&
                (!!dealDetails?.otherDeals?.length ||
                  !!sameRowDestinationDeals.length) && (
                  <DealDetailsMoreDeals
                    origin={origin}
                    destinationDeals={
                      sameRowDestinationDeals.length
                        ? sameRowDestinationDeals
                        : dealDetails?.otherDeals
                        ? dealDetails.otherDeals.map((d) => ({
                            deals: [d],
                            activeDeal: d,
                            keyDeal: d,
                          }))
                        : []
                    }
                    areDealsLoaded={
                      dealDetails?.otherDeals
                        ? true
                        : !dealsState.isInitializing
                    }
                    flightDesignation={flightDesignation}
                    seatClass={seatClass}
                    onSelectDeal={async (destinationDeals) => {
                      const activeDeal = destinationDeals.activeDeal;

                      if (activeDeal.airfares) {
                        goWithStickyParamsLocation(
                          {
                            pathname: AllowedPath.DEAL_DETAILS,
                            search: `?dda=${activeDeal.dda}`,
                          },
                          ['airfare']
                        );
                        if (componentRef.current) {
                          await componentRef.current.scrollToTop();
                        }
                      } else {
                        if (isLimitedUser) {
                          setUpgradeMessage(
                            'Unlock this deal and unlimited deals to over 250 other destinations by upgrading to a Pro subscription.'
                          );
                        } else {
                          setRegisterMessage('Want to see more deals?');
                        }
                      }
                    }}
                    onRefresh={
                      dealDetails?.otherDeals
                        ? undefined
                        : async () => {
                            await dealsState.mutate();
                          }
                    }
                  />
                )}
            </IonRow>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          title={upgradeMessage}
          search={buildRedirectSearchParams()}
          onClose={() => {
            setUpgradeMessage(undefined);
          }}
        />
        <RegisterModal
          title={registerMessage}
          search={buildRedirectSearchParams()}
          onClose={() => {
            setRegisterMessage(undefined);
          }}
        />
        <DealDetailsPlansModal
          show={showPlansModal}
          setIsModalActive={() => {
            setShowPlansModal(false);
          }}
          onPlan={async (plan) => {
            goWithStickyParamsLocation({
              pathname: AllowedPath.REGISTER,
              search: buildRedirectSearchParams(plan),
            });
          }}
          onGoToBooking={async () => {
            if (bookingUrl) {
              goToBookingUrl(bookingUrl);
            }
            setShowPlansModal(false);
          }}
        />
        <DealDetailsShareModal
          show={showShareModal}
          deal={dealDetails?.deal}
          setIsModalActive={() => {
            setShowShareModal(false);
          }}
        />
      </IonPage>
    )
  );
};

export default DealDetails;
