// cspell:ignore grecaptcha sitekey

import { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export interface GoogleReCaptchaV2AndV3ContextValue {
  v2Token?: string;
  executeV3: (action: string) => Promise<string | undefined>;
  renderV2: (id: string) => void;
  widgetId: string | undefined;
  isV2Rendered: boolean;
}

// Since we set the GoogleReCaptchaV2AndV3Provider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const GoogleReCaptchaV2AndV3Context =
  createContext<GoogleReCaptchaV2AndV3ContextValue>({
    v2Token: undefined,
    executeV3: async () => '',
    renderV2: () => undefined,
    widgetId: undefined,
    isV2Rendered: false,
  });

interface GoogleReCaptchaV2AndV3Props {
  children: ReactNode;
}

export const GoogleReCaptchaV2AndV3Provider: FC<GoogleReCaptchaV2AndV3Props> = (
  props
) => {
  const [isV2Rendered, setIsV2Rendered] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [v2Token, setV2Token] = useState<string>();
  const [widgetId, setWidgetId] = useState<string>();

  const onV2Execute = (token: string) => {
    setV2Token(token);
  };

  const renderV2 = (id: string) => {
    // @ts-expect-error grecaptcha is sourced
    if (!isV2Rendered && typeof grecaptcha !== 'undefined') {
      setIsV2Rendered(true);
      // @ts-expect-error grecaptcha is sourced
      const updatedWidgetId = grecaptcha.render(id, {
        sitekey: process.env.REACT_APP_RECAPTCHA_V2_PUBLIC_KEY,
        callback: onV2Execute,
      });
      setWidgetId(updatedWidgetId);
    }
  };

  const executeV3 = async (action: string) => {
    let token: string | undefined = undefined;

    // reCaptcha recommends that we have this check
    // Not doing anything with the error right now
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.log('Execute recaptcha not yet available');
    } else {
      token = await executeRecaptcha(action);
    }
    return token;
  };

  return (
    <GoogleReCaptchaV2AndV3Context.Provider
      value={{
        v2Token,
        executeV3,
        renderV2,
        widgetId,
        isV2Rendered,
      }}
    >
      {props.children}
    </GoogleReCaptchaV2AndV3Context.Provider>
  );
};

export default GoogleReCaptchaV2AndV3Context;
