import { IonCol, IonRow } from '@ionic/react';
import React from 'react';

import { getAssetsBaseUrl } from '@faredrop/utilities';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import './../theme/Footer.css';
import './../theme/util.css';
import { FaredropLogo } from './FaredropLogo';

interface ContainerProps {
  bgColor: string;
  isVisible: boolean;
  onLinkPress?: (linkId: AnalyticsEngagementId) => Promise<void>;
}

interface IMenuOption {
  label: string;
  id: AnalyticsEngagementId;
  path?: string;
}

const MENU_OPTIONS: IMenuOption[] = [
  {
    label: 'About',
    id: AnalyticsEngagementId.FOOTER_ABOUT,
  },
  {
    label: 'Privacy Policy',
    id: AnalyticsEngagementId.FOOTER_PRIVACY_POLICY,
  },
  {
    label: 'Terms of Service',
    id: AnalyticsEngagementId.FOOTER_TERMS_OF_SERVICE,
  },
  {
    label: 'Refund Policy',
    id: AnalyticsEngagementId.FOOTER_REFUND_POLICY,
  },
  {
    label: 'Become an Affiliate',
    id: AnalyticsEngagementId.FOOTER_BECOME_AN_AFFILIATE,
    path: `/affiliates/become-an-affiliate.pdf`,
  },
];

const Footer: React.FC<ContainerProps> = ({
  bgColor,
  isVisible,
  onLinkPress,
}) => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const optionLink = (option: IMenuOption) =>
    option.path ?? `/${option.label.split(' ').join('-').toLowerCase()}`;

  const onMenuOptionClick = async (option: IMenuOption) => {
    onLinkPress && (await onLinkPress(option.id));

    const path = optionLink(option);
    if (option.path) {
      // Open in new tab so we don't lose sticky query params
      window.open(`${getAssetsBaseUrl()}${path}`, '_blank');
    } else {
      goWithStickyParamsPath(path);
    }
  };

  return (
    <IonRow
      className="footer-container row-vertical-align"
      style={{
        backgroundColor: `${
          bgColor === 'primary'
            ? 'var(--ion-color-primary)'
            : 'var(--ion-color-secondary)'
        }`,
        display: isVisible ? 'flex' : 'none',
      }}
    >
      <IonCol className="footer-logo-desktop" sizeXs="12" sizeLg="2">
        <FaredropLogo light={bgColor === 'primary'} />
      </IonCol>
      <IonCol className="menu-desktop" sizeXs="12" sizeLg="8">
        <IonRow className="center-content" style={{ opacity: '.7' }}>
          {MENU_OPTIONS.map((option, i) => {
            if (i < MENU_OPTIONS.length - 1) {
              return (
                <React.Fragment key={i}>
                  <a
                    onClick={async () => {
                      await onMenuOptionClick(option);
                    }}
                    className="footer-menu-link"
                    style={{
                      color: `${
                        bgColor === 'primary'
                          ? '#F8F6F3'
                          : 'var(--ion-color-primary)'
                      }`,
                    }}
                  >
                    {option.label}
                  </a>
                  <p
                    style={{
                      lineHeight: '3em',
                      color: `${
                        bgColor === 'primary'
                          ? '#F8F6F3'
                          : 'rgba(114, 114, 114, 1)'
                      }`,
                    }}
                  >
                    •
                  </p>
                </React.Fragment>
              );
            } else {
              return (
                <a
                  key={i}
                  onClick={async () => {
                    await onMenuOptionClick(option);
                  }}
                  className="footer-menu-link"
                  style={{
                    color: `${
                      bgColor === 'primary'
                        ? '#F8F6F3'
                        : 'var(--ion-color-primary)'
                    }`,
                  }}
                >
                  {option.label}
                </a>
              );
            }
          })}
        </IonRow>
      </IonCol>
      {MENU_OPTIONS.map((option) => {
        return (
          <IonCol key={option.id} className="menu-mobile" sizeXs="12">
            <a
              onClick={async () => {
                await onMenuOptionClick(option);
              }}
              className="footer-menu-link"
              style={{
                color:
                  bgColor === 'primary'
                    ? '#F8F6F3'
                    : 'var(--ion-color-primary)',
              }}
            >
              {option.label}
            </a>
          </IonCol>
        );
      })}
      <IonCol sizeXs="12" sizeXl="2">
        <a href="mailto:team@faredrop.com" style={{ lineHeight: '1em' }}>
          <div
            className="contact-us-button-container transparent-button"
            style={{
              color: bgColor === 'primary' ? '#F8F6F3' : '#222222dd',
              borderColor: bgColor === 'primary' ? '#F8F6F3' : '#222222dd',
            }}
            onClick={async () => {
              onLinkPress &&
                (await onLinkPress(AnalyticsEngagementId.FOOTER_CONTACT_US));
            }}
          >
            Contact us
          </div>
        </a>
      </IonCol>
      <IonCol className="footer-logo-mobile" sizeXs="12" sizeLg="2">
        <IonRow className="row-vertical-align">
          <FaredropLogo light={bgColor === 'primary'} />
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default Footer;
