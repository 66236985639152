import { createContext, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { sleep, ScriptLoader } from '@faredrop/utilities';

import useAnalytics from '../hooks/analytics';

interface IFirstPromoter {
  data: {
    tid?: string;
    ref_id?: string;
  };
}

export interface FirstPromoterContextValue {
  getFirstPromoterTID: () => Promise<string>;
  getFirstPromoterReferralId: () => Promise<string | undefined>;
}

// Since we set the FirstPromoterProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const FirstPromoterContext = createContext<FirstPromoterContextValue>({
  getFirstPromoterTID: () => Promise.resolve(''),
  getFirstPromoterReferralId: () => Promise.resolve(undefined),
});

interface FirstPromoterProps {
  children: ReactNode;
}

export const FirstPromoterProvider: FC<FirstPromoterProps> = (props) => {
  const { logAnalyticsError } = useAnalytics();

  const [firstPromoterInitialized, setFirstPromoterInitialized] =
    useState(false);
  const firstPromoterInitializedRef = useRef(false);

  const firstPromoter = useRef<IFirstPromoter>();

  const cachedTID = useRef<string>();
  const cachedReferralID = useRef<string>();

  useEffect(() => {
    const firstPromoterLoader = new ScriptLoader({
      src: 'cdn.firstpromoter.com/fpr.js',
      protocol: 'https:',
      global: 'FPROM',
    });

    firstPromoterLoader
      .load()
      .then((fprom) => {
        firstPromoter.current = fprom as IFirstPromoter;
      })
      .catch((e) => {
        console.warn(
          `First Promoter initialization error: ${JSON.stringify(e)}`
        );
      })
      .finally(() => {
        setFirstPromoterInitialized(true);
        firstPromoterInitializedRef.current = true;
      });
  }, []);

  useEffect(() => {
    if (firstPromoterInitialized) {
      // Cache the TID and referral ID after script loaded
      getFirstPromoterTID().catch((e) => {
        console.warn("Failed to get First Promoter's TID");
        console.warn(e);
      });

      getFirstPromoterReferralId().catch((e) => {
        console.warn("Failed to get First Promoter's Referral ID");
        console.warn(e);
      });
    }
  }, [firstPromoterInitialized]);

  const getFirstPromoterTID = async (): Promise<string> => {
    if (cachedTID.current) {
      return cachedTID.current;
    } else {
      let tid: string = 'fp_' + new Date().getTime();
      await Promise.race([
        new Promise<void>((resolve) => {
          checkReady()
            .then(() => {
              if (!firstPromoter.current) {
                resolve();
              } else {
                if (firstPromoter.current?.data?.tid) {
                  tid = firstPromoter.current?.data?.tid;
                  cachedTID.current = tid;
                }
                resolve();
              }
            })
            .catch(resolve);
        }),
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
      return tid;
    }
  };

  const getFirstPromoterReferralId = async (): Promise<string | undefined> => {
    if (cachedReferralID.current) {
      return cachedReferralID.current;
    } else {
      let referralID: string | undefined = undefined;
      await Promise.race([
        new Promise<void>((resolve) => {
          checkReady()
            .then(() => {
              if (!firstPromoter.current) {
                resolve();
              } else {
                referralID = firstPromoter.current?.data?.ref_id;
                cachedReferralID.current = referralID;
                resolve();
              }
            })
            .catch(resolve);
        }),
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
      return referralID;
    }
  };

  const checkReady = async () => {
    const poller = async () => {
      let count = 0;
      let ready = false;
      do {
        count++;
        if (firstPromoterInitializedRef.current === true) {
          ready = true;
          return;
        }
        await sleep(500);
      } while (!ready && count < 10);
      throw new Error('First Promoter exceeded 5 second initialization limit');
    };

    return new Promise<void>((resolve, reject) => {
      poller()
        .then(resolve)
        .catch((error) => {
          logAnalyticsError('firstPromoterInitialization', error).catch(() => {
            console.warn('Failed to log First Promoter initialization error');
          });
          reject(error);
        });
    });
  };

  return (
    <FirstPromoterContext.Provider
      value={{
        getFirstPromoterTID,
        getFirstPromoterReferralId,
      }}
    >
      {props.children}
    </FirstPromoterContext.Provider>
  );
};

export default FirstPromoterContext;
