import { useEffect } from 'react';
import useAnalyticsService from './analytics';

// Screen names should be logged with the react navigation route listener, but some screens may need be logged manually (e.g., Getting Started steps)
const useAnalyticsScreenName = (screenName: string) => {
  const analyticsService = useAnalyticsService();

  useEffect(() => {
    if (screenName) {
      analyticsService
        .logGoogleAnalyticsScreen(screenName)
        .catch((error) =>
          console.warn(
            'Failed to log Google Analytics Screen',
            screenName,
            error
          )
        ); // Async - fire and forget
      analyticsService.logHotjarAnalyticsScreen(screenName);
      analyticsService.logPixelAnalyticsScreen(screenName);
    }
  }, []);

  return {
    ...analyticsService,
  };
};

export default useAnalyticsScreenName;
