import { createContext } from 'react';
import type { FC, ReactNode } from 'react';

import { Airport } from '@faredrop/graphql-sdk';

import useFareDropPublicApiClient from '../hooks/faredropPublicApiClient';

export interface InactiveAirportsContextValue {
  getInactiveAirport: (airportIATA: string) => Promise<Airport | undefined>;
}

// Since we set the InactiveAirportsProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const InactiveAirportsContext = createContext<InactiveAirportsContextValue>({
  getInactiveAirport: async () => Promise.resolve(undefined),
});

interface InactiveAirportsProps {
  children: ReactNode;
}

// Map of promises for deduplication
const inactiveAirports: { [iata: string]: Promise<Airport | undefined> } = {};

export const InactiveAirportsProvider: FC<InactiveAirportsProps> = (props) => {
  const { client } = useFareDropPublicApiClient();

  const getInactiveAirport = async (airportIATA: string) => {
    let airport = await inactiveAirports[airportIATA];
    if (!airport) {
      try {
        inactiveAirports[airportIATA] = new Promise(
          (resolve: (airport: Airport) => void, reject) => {
            client
              .airport({
                iata: airportIATA,
              })
              .then((result) => {
                resolve(result.data.airport);
              })
              .catch(reject);
          }
        );

        airport = await inactiveAirports[airportIATA];
      } catch (error) {
        console.warn(`Invalid airport IATA ${airportIATA}`);
      }
    }

    return airport;
  };

  return (
    <InactiveAirportsContext.Provider
      value={{
        getInactiveAirport,
      }}
    >
      {props.children}
    </InactiveAirportsContext.Provider>
  );
};

export default InactiveAirportsContext;
