import { IonRow } from '@ionic/react';
import _ from 'lodash';
import {
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { $enum } from 'ts-enum-util';

import { Airport, FlightSegment, VerifiedAirfare } from '@faredrop/graphql-sdk';
import { FlightDirection } from '@faredrop/types';
import { camelCaseToWords } from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import useAirports from '../../hooks/airports';
import usePresentToast from '../../hooks/presentToast';

interface ContainerProps {
  airfare?: VerifiedAirfare;
  selectedSegment?: FlightSegment;
  onSelect?: (segmentIndex: number, flightDirection: FlightDirection) => void;
}

enum RouteProperty {
  departure = 'departingRoute',
  return = 'returningRoute',
}

const DealDetailsRouteDiagram: React.FC<ContainerProps> = ({
  airfare,
  selectedSegment: segmentArg,
  onSelect,
}) => {
  const {
    getAirportOrSubAirport,
    getAirportOrSubAirportWithBackendFallback,
    airports,
  } = useAirports();
  const { presentError } = usePresentToast();

  const [routeAirports, setRouteAirports] = useState<Airport[]>();

  const [selectedRouteProperty, setSelectedRouteProperty] =
    useState<RouteProperty>(RouteProperty.departure);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const routeBarRef = useRef<HTMLIonRowElement>(null);

  useEffect(() => {
    selectSegment(0);
  }, [selectedRouteProperty]);

  // Reset when new date is selected
  useEffect(() => {
    setSelectedRouteProperty(RouteProperty.departure);
    selectSegment(0);
  }, [airfare?.departureDate]);

  const selectedRoute = airfare ? airfare[selectedRouteProperty] : undefined;
  useEffect(() => {
    const buildAirports = async () => {
      if (selectedRoute) {
        const airports: Airport[] = [];
        for (let i = 0; i < selectedRoute.length; i++) {
          if (i === 0) {
            const departureAirport = getAirportOrSubAirport(
              selectedRoute[i].originIATA
            );
            if (departureAirport) {
              airports.push(departureAirport);
            }
          }

          // It's possible that a stop on the route is not an origin or destination, so we need to retrieve it from our backend
          const arrivalAirport =
            await getAirportOrSubAirportWithBackendFallback(
              selectedRoute[i].destinationIATA
            );
          if (arrivalAirport) {
            airports.push(arrivalAirport);
          }
        }
        setRouteAirports(airports);
      }
    };

    // Wait until the airports cache has loaded
    if (airports) {
      buildAirports().catch(() => {
        presentError(
          'Uh oh - airports failed to load. Please refresh the page.'
        );
      });
    }
  }, [selectedRoute, airports]);

  useEffect(() => {
    const index = selectedRoute?.findIndex(
      (s) =>
        s.originIATA === segmentArg?.originIATA &&
        s.destinationIATA === segmentArg.destinationIATA
    );
    if (index != null) {
      setSelectedSegmentIndex(index);
    }
  }, [segmentArg]);

  const selectedSegment = selectedRoute
    ? selectedRoute[selectedSegmentIndex]
    : undefined;
  const segmentWidth =
    routeBarRef.current && routeAirports
      ? routeBarRef.current.offsetWidth / (routeAirports.length - 1)
      : undefined;

  const selectSegment = (index: number) => {
    setSelectedSegmentIndex(index);
    if (onSelect && selectedRoute) {
      onSelect(
        index,
        selectedRouteProperty === RouteProperty.departure
          ? FlightDirection.DEPART
          : FlightDirection.RETURN
      );
    }
  };

  return (
    <IonRow
      ref={routeBarRef}
      style={{ display: 'flex', flex: 1, position: 'relative', width: '100%' }}
    >
      {segmentWidth != null && segmentWidth > 0 && (
        <>
          <IonRow style={{ height: '65px', width: '100%' }}>
            {selectedRoute?.map((segment, index) => {
              const isSelected = index === selectedSegmentIndex;
              return (
                <div
                  key={`${segment.originIATA}#${segment.destinationIATA}`}
                  style={{
                    width: `${segmentWidth}px`,
                    height: '40px',
                    position: 'absolute',
                    left: `${index * segmentWidth}px`,
                    cursor: 'pointer',
                    display: 'flex',
                  }}
                  onClick={() => {
                    selectSegment(index);
                  }}
                >
                  <div
                    style={{
                      width: `${segmentWidth}px`,
                      height: '8px',
                      backgroundColor: isSelected
                        ? 'var(--ion-color-primary)'
                        : '#E5E5E5',
                    }}
                  ></div>
                </div>
              );
            })}
            {routeAirports?.map((airport, index) => {
              const isSelected =
                airport.iata === selectedSegment?.originIATA ||
                airport.iata === selectedSegment?.destinationIATA;
              return (
                <div key={`route-airport-${airport.iata}`}>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                      backgroundColor: isSelected
                        ? 'var(--ion-color-primary)'
                        : '#E5E5E5',
                      position: 'absolute',
                      left: `${index * segmentWidth - 10}px`,
                      top: '-5px',
                    }}
                  ></div>
                  <div
                    style={{
                      width: `${segmentWidth}px`,
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'absolute',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingBottom: '10px',
                      left: `${index * segmentWidth - segmentWidth / 2}px`,
                      top: '25px',
                    }}
                  >
                    <IonRow
                      style={{
                        color: 'var(--ion-color-primary)',
                        fontFamily: 'nexa-bold',
                        fontSize: '16px',
                      }}
                    >
                      {airport.iata}
                    </IonRow>
                    <IonRow style={{ color: '#A2A2A2', fontSize: '14px' }}>
                      {airport?.city}
                    </IonRow>
                  </div>
                </div>
              );
            })}
          </IonRow>
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginTop: '7px',
            }}
          >
            <FormControl>
              <ToggleButtonGroup
                value={[selectedRouteProperty]}
                onChange={(event) => {
                  setSelectedRouteProperty(
                    (event.target as HTMLInputElement).value as RouteProperty
                  );
                }}
                size="small"
                sx={{
                  [`& .${toggleButtonGroupClasses.grouped}`]: {
                    border: 'solid 1px var(--ion-color-primary)',
                    borderRadius: '5px',
                    fontFamily: 'nexa-bold',
                    lineHeight: '1em',
                    padding: '10px',
                    '&.Mui-selected': {
                      backgroundColor: 'rgb(44, 98, 92, .08)',
                      color: 'var(--ion-color-primary)',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'rgb(44, 98, 92, .08)',
                      color: 'var(--ion-color-primary)',
                    },
                    '&:hover': {
                      color: 'var(--ion-color-primary)',
                      backgroundColor: 'white',
                      border: 'solid 1px',
                    },
                  },
                }}
              >
                {Array.from($enum(RouteProperty).entries()).map(
                  ([key, value]) => (
                    <ToggleButton key={key} value={value}>
                      {_.startCase(camelCaseToWords(value))}
                    </ToggleButton>
                  )
                )}
              </ToggleButtonGroup>
            </FormControl>
          </IonRow>
        </>
      )}
    </IonRow>
  );
};

export default DealDetailsRouteDiagram;
