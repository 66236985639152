import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import Loading from '../components/Loading';
import useAuth from '../hooks/auth';
import useUser from '../hooks/user';
import { clearLocalStorageCache } from '../utilities/local-storage-cache-provider';
import StorageService from '../services/StorageService';
import { useDevice } from '../hooks/useDevice';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

const Logout = () => {
  const { cache } = useSWRConfig();
  const { isInitialized, logout, user } = useAuth();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { removeThisDevice } = useUser();
  const { isApp } = useDevice();

  useEffect(() => {
    const logoutUser = async () => {
      await Promise.race([
        user
          ? removeThisDevice()
          : (async () => {
              console.warn(
                'No User - skipping push notification token removal'
              );
            })(),
        // Don't let this page infinitely spin
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
      // There is a bug with SWR cache type, so we need to cast to any
      // Source: https://github.com/vercel/swr/discussions/1494#discussioncomment-1379678
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cache as any).clear();
      clearLocalStorageCache();

      if (isApp) {
        await StorageService.getInstance().clearData();
      }

      await Promise.race([
        logout(true),
        // Don't let this page infinitely spin
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
      goWithStickyParamsLocation({ pathname: AllowedPath.BASE });
    };

    if (isInitialized) {
      logoutUser().catch((error) => {
        console.warn('Failed to logoutUser', error);
        throw error; // Show uh-oh screen
      }); // Fire and forget
    }
  }, [isInitialized]);

  return <Loading />;
};

export default Logout;
