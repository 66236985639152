import { RefObject, useEffect, useState } from 'react';

// /**
//  * Check if an element is in view
//  * Source: https://stackoverflow.com/a/65008608/2437610
//  */
export default function useVisibility<T extends RefObject<Element>>(
  ref: T,
  options?: IntersectionObserverInit
) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [hasReachedDelay, setHasReachedDelay] = useState(false);
  // https://stackoverflow.com/questions/59074487/reference-error-cant-find-variable-intersectionobserver
  const observer =
    'IntersectionObserver' in window
      ? new IntersectionObserver(([entry]) => {
          setIntersecting(entry.isIntersecting);
        }, options)
      : undefined;

  // Add a small delay since the observer tracks intersections even if not in view port right after page refresh
  useEffect(() => {
    setTimeout(() => {
      setHasReachedDelay(true);
    }, 1000);
  }, []);

  useEffect(() => {
    hasReachedDelay &&
      ref.current &&
      observer &&
      observer?.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer && observer?.disconnect();
    };
  }, [hasReachedDelay, ref.current]);

  return isIntersecting;
}
