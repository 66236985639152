import { FareDropApiClient } from '@faredrop/graphql-sdk';
import useAuth from './auth';

export interface FareDropApiClientValue {
  client: ReturnType<typeof FareDropApiClient>;
}

// This should be moved to a utilities package
// Brian hit his 10 min time box to do so, kept getting graphql-tools error
export const fetchWithTimeout = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  timeoutMS = 8000
) => {
  let response;

  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, timeoutMS);

  try {
    response = await fetch(input, { ...init, signal: controller.signal });
  } finally {
    clearTimeout(timeout);
  }

  return response;
};

const useFareDropApiClientWithTimeout = (): FareDropApiClientValue => {
  const { getIdToken } = useAuth();
  return {
    client: FareDropApiClient(getIdToken, undefined, {
      fetch: fetchWithTimeout,
    }),
  };
};

export default useFareDropApiClientWithTimeout;
