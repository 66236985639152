import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import useAnalyticsScreenName from '../hooks/analyticsScreenName';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import './../theme/LoginPage.css';
import './../theme/util.css';

const Oops: React.FC = () => {
  useAnalyticsScreenName('/not-found');
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader menuHidden={true} />
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            transition: 'opacity 800ms',
            textAlign: 'center',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8">
            <IonRow className="row-vertical-align">
              <h1
                className="title-font"
                style={{
                  textAlign: 'center',
                  fontSize: '5em',
                  color: 'var(--ion-color-primary)',
                }}
              >
                Oops!
              </h1>
            </IonRow>
            <IonRow className="row-vertical-align">
              <h4 style={{ fontFamily: 'nexa-bold' }}>
                We can&apos;t seem to find the page you&apos;re looking for.
              </h4>
            </IonRow>
            <IonRow className="row-vertical-align">
              <h2 style={{ margin: 0 }}>Error code: 404</h2>
            </IonRow>
            <IonButton
              style={{
                height: '3em',
                width: '15em',
                marginTop: '2em',
                borderRadius: 8,
                textTransform: 'none',
              }}
              onClick={() => {
                goWithStickyParamsPath('/');
              }}
            >
              Back to Home
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Oops;
