import { IonCol, IonRow, IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { useState } from 'react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';

interface ContainerProps {
  border: boolean;
  data: {
    id: AnalyticsEngagementId;
    question: string;
    answer: string;
  };
  sx?: React.CSSProperties;
  onOpen?: (questionId: AnalyticsEngagementId) => Promise<void>;
}

const QuestionCard: React.FC<ContainerProps> = ({
  border,
  data,
  sx,
  onOpen,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <IonCol
      style={{
        border: border ? '1px solid var(--ion-color-lighter-gray)' : 'none',
        ...sx,
      }}
      className="question-wrapper"
      sizeXs="12"
      sizeLg="5.5"
      onClick={async (e) => {
        e.stopPropagation();
        if (collapsed) {
          onOpen && (await onOpen(data.id));
        }
        setCollapsed(!collapsed);
      }}
    >
      <IonRow
        className={border ? 'm-1' : ''}
        style={{ borderBottom: border ? 'none' : '1px solid #ddd' }}
      >
        <IonCol style={{ padding: border ? 0 : 'inherit' }}>
          <p
            className={border ? 'ml-1 question' : 'question'}
            style={{
              fontFamily: 'nexa-bold',
            }}
          >
            {data.question}
          </p>
        </IonCol>
        <IonIcon
          className="m-a mr-1"
          icon={collapsed ? chevronDownOutline : chevronUpOutline}
          style={{
            opacity: border ? 1.0 : 0.5,
            marginRight: border ? '1rem' : 0,
          }}
        />
      </IonRow>
      <div
        className="collapsible"
        style={
          !collapsed
            ? { maxHeight: '100%' }
            : { overflow: 'hidden', maxHeight: 0 }
        }
      >
        <IonRow style={{ padding: border ? '0 2.5em 2.5em 2.5em' : 0 }}>
          <p style={{ fontFamily: 'nexa', lineHeight: '1.5em' }}>
            {data.answer}
          </p>
        </IonRow>
      </div>
    </IonCol>
  );
};

export default QuestionCard;
