import './../theme/util.css';
import './../theme/LoginPage.css';

import {
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { useDevice } from '../hooks/useDevice';
import MobileHeader from '../components/MobileHeader';
import DesktopHeader from '../components/DesktopHeader';
import { useEffect, useState } from 'react';
import useStripe from '../hooks/useStripe';
import GiftPaperAirplane from '../components/GiftPaperAirplane';
import useAnalytics from '../hooks/analytics';
import Loading from '../components/Loading';
import usePresentToast from '../hooks/presentToast';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

const Confirmation: React.FC = () => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { presentError } = usePresentToast();
  const [confirmationType, setConfirmationType] = useState<string>();
  const [idPaymentIntent, setIdPaymentIntent] = useState<string>(); // "Receipt" number for Gift Plan purchases
  const [isGeneratingReceipt, setIsGeneratingReceipt] = useState(false);
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { getReceipt } = useStripe();
  const { logAnalyticsGiftEngagement } = useAnalytics();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const queryType = queryParams.get('type');
    queryType && setConfirmationType(queryType);

    if (queryType && queryType.indexOf('Plan Gift') > -1) {
      setIsGeneratingReceipt(true);
    }

    const queryCheckoutSessionId = queryParams.get('session_id');
    if (queryCheckoutSessionId) {
      getReceipt(queryCheckoutSessionId)
        .then((receipt) => {
          // We prefer idPaymentIntent because it should be set for a gift purchase
          // and more importantly, it is searchable in the Stripe dashboard
          // Checkout Session ID's are not
          setIdPaymentIntent(receipt.idPaymentIntent ?? receipt.idTransaction);
          setIsGeneratingReceipt(false);
        })
        .catch(() =>
          presentError(
            `Failed to get receipt - please contact support (team@faredrop.com) and reference session ID: ${queryCheckoutSessionId}`
          )
        );
      setIsInitialized(true);
    } else {
      // We should prevent people from navigating directly to this page without the proper query params
      goWithStickyParamsPath(AllowedPath.BASE);
    }
  }, []);

  const isGift = confirmationType?.includes('Plan Gift');
  const confirmationText = confirmationType
    ? `for ${decodeURIComponent(confirmationType)}`
    : '';

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader menuHidden={true} />
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      <IonContent>
        {isInitialized ? (
          <IonRow
            className="row-vertical-align"
            style={{
              transition: 'opacity 800ms',
              textAlign: 'center',
              marginTop: isGift ? '10em' : undefined,
            }}
          >
            <IonCol size="12">
              <IonRow className="row-vertical-align">
                <h1
                  className="title-font"
                  style={{
                    textAlign: 'center',
                    fontSize: '5em',
                    color: 'var(--ion-color-primary)',
                  }}
                >
                  Thank You!
                </h1>
              </IonRow>
              {isGeneratingReceipt && (
                <>
                  <IonRow className="row-vertical-align">
                    <h4 style={{ fontFamily: 'nexa-bold' }}>
                      Hold tight while we generate your receipt...
                    </h4>
                  </IonRow>
                  <IonSpinner
                    name="crescent"
                    style={{ marginTop: '2em' }}
                    color="primary"
                  />
                </>
              )}
              {!isGeneratingReceipt && (
                <>
                  <IonRow className="row-vertical-align">
                    <p style={{ color: 'var(--ion-color-gray)' }}>
                      Your order {confirmationText} has been confirmed. You
                      should receive an email confirmation shortly.
                    </p>
                  </IonRow>
                  {idPaymentIntent && (
                    <>
                      <IonRow className="row-vertical-align">
                        <p>
                          Please save the following receipt number for your
                          records
                        </p>
                      </IonRow>
                      <IonRow className="row-vertical-align">
                        <h4 style={{ fontFamily: 'nexa-bold' }}>
                          {idPaymentIntent}
                        </h4>
                      </IonRow>
                    </>
                  )}
                  <IonButton
                    style={{
                      height: '3em',
                      width: '15em',
                      marginTop: '2em',
                      borderRadius: 8,
                      textTransform: 'none',
                      marginBottom: '10em',
                    }}
                    onClick={() => {
                      goWithStickyParamsPath(AllowedPath.BASE);
                    }}
                  >
                    Back to Home
                  </IonButton>
                </>
              )}
              {isGift && (
                <GiftPaperAirplane
                  logAnalyticsEngagement={logAnalyticsGiftEngagement}
                />
              )}
            </IonCol>
          </IonRow>
        ) : (
          <Loading />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Confirmation;
