import { FareDropApiClient } from '@faredrop/graphql-sdk';
import useAuth from './auth';

export interface FareDropApiClientValue {
  client: ReturnType<typeof FareDropApiClient>;
}

const useFareDropApiClient = (): FareDropApiClientValue => {
  const { getIdToken } = useAuth();
  return { client: FareDropApiClient(getIdToken) };
};

export default useFareDropApiClient;
