import { IonCol, IonRow } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import { useDevice } from '../../hooks/useDevice';

interface ContainerProps {
  normalPrice?: number;
  threshold?: number;
  averageDealPrice?: number;
  lastDealPrice?: number;
  daysSinceDeal?: number;
  bestDealPrice?: number;
  daysSinceBestDeal?: number;
}

const DealDetailsStats: React.FC<ContainerProps> = ({
  normalPrice,
  threshold,
  averageDealPrice,
  lastDealPrice,
  bestDealPrice,
  daysSinceDeal,
  daysSinceBestDeal,
}) => {
  const { isSmallScreenSizeOrSmaller } = useDevice();

  const stats: { title: string; value?: string; subValue?: string }[][] = [];

  const normalPriceStat = {
    title: 'Normal Price',
    value: `$${normalPrice}`,
  };

  const thresholdStat = {
    title: 'Our Threshold',
    value: `$${threshold}`,
  };

  const averageDealStat = {
    title: 'Average Deal',
    value: `$${averageDealPrice}`,
  };

  const lastDealStat = {
    title: 'Last Deal',
    value: `$${lastDealPrice}`,
    subValue:
      daysSinceDeal != null
        ? moment().subtract(daysSinceDeal, 'days').format('MMM DD, YY')
        : undefined,
  };

  const bestDealStat = {
    title: 'Best Deal',
    value: `$${bestDealPrice}`,
    subValue:
      daysSinceBestDeal != null
        ? moment().subtract(daysSinceBestDeal, 'days').format('MMM DD, YY')
        : undefined,
  };

  // Order of stats is different for small devices
  if (isSmallScreenSizeOrSmaller) {
    const tempStats: { title: string; value?: string; subValue?: string }[] =
      [];
    if (normalPrice) {
      tempStats.push(normalPriceStat);
    }

    if (averageDealPrice) {
      tempStats.push(averageDealStat);
    }

    if (lastDealPrice) {
      tempStats.push(lastDealStat);
    }

    if (threshold) {
      tempStats.push(thresholdStat);
    }

    if (bestDealPrice) {
      tempStats.push(bestDealStat);
    }

    // Build [1 x 2 x 2] array
    let rowIndex = 0;
    for (let i = 0; i < tempStats.length; i++) {
      if (
        (rowIndex === 0 && stats[rowIndex]?.length === 1) ||
        stats[rowIndex]?.length === 2
      ) {
        rowIndex++;
      }

      if (!stats[rowIndex]) {
        stats[rowIndex] = [];
      }

      stats[rowIndex].push(tempStats[i]);
    }
  } else {
    stats[0] = [];
    if (normalPrice) {
      stats[0].push(normalPriceStat);
    }

    if (threshold) {
      stats[0].push(thresholdStat);
    }

    if (averageDealPrice) {
      stats[0].push(averageDealStat);
    }

    if (lastDealPrice) {
      stats[0].push(lastDealStat);
    }

    if (bestDealPrice) {
      stats[0].push(bestDealStat);
    }
  }

  return (
    <IonRow
      style={{
        display: 'flex',
        backgroundColor: '#F8F6F3',
        borderRadius: '20px',
        padding: '15px',
      }}
    >
      {!isSmallScreenSizeOrSmaller &&
        stats.flat().map((stat) => (
          <IonCol key={stat.title} className="deal-details-stats-col">
            <IonRow className="deal-details-stats-title">{stat.title}</IonRow>
            <IonRow className="deal-details-stats-value">{stat.value}</IonRow>
            <IonRow className="deal-details-stats-sub-value">
              {stat.subValue}
            </IonRow>
          </IonCol>
        ))}
      {isSmallScreenSizeOrSmaller && (
        <div style={{ flex: 1 }}>
          {stats.map((statsRow, rowIndex) => {
            return (
              <IonRow
                key={`${rowIndex}#${statsRow[0]?.title ?? 'empty'}`}
                style={{ width: '100%' }}
              >
                {statsRow.map((stat) => {
                  return (
                    <IonCol
                      key={stat.title}
                      size={rowIndex === 0 ? undefined : '6'}
                      className="deal-details-stats-col"
                    >
                      <IonRow className="deal-details-stats-title">
                        {stat.title}
                      </IonRow>
                      <IonRow className="deal-details-stats-value">
                        {stat.value}
                      </IonRow>
                      <IonRow className="deal-details-stats-sub-value">
                        {stat.subValue}
                      </IonRow>
                    </IonCol>
                  );
                })}
              </IonRow>
            );
          })}
        </div>
      )}
    </IonRow>
  );
};

export default DealDetailsStats;
