import { IGiftee, IGiftPurchaser } from '@faredrop/types';
import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
} from '@mui/material';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/LoginPage.css';

type PurchaseGiftProps = {
  onSubmit: (purchaser: IGiftPurchaser, giftee?: IGiftee) => Promise<void>;
};

export type PurchaseGiftValues = {
  purchaserEmail: string;
  purchaserFirstName: string;
  purchaserLastName: string;
  gifteeEmail?: string;
  gifteeFirstName?: string;
  gifteeLastName?: string;
};

const PurchaseGift: FC<PurchaseGiftProps> = (props: PurchaseGiftProps) => {
  const [notifyRecipient, setNotifyRecipient] = useState(true);
  const { isSmallScreenSizeOrSmaller } = useDevice();
  const userState = useUser();

  const submitDisabled = (
    values: PurchaseGiftValues,
    touched: FormikTouched<PurchaseGiftValues>,
    errors: FormikErrors<PurchaseGiftValues>
  ) => {
    return (
      values.purchaserEmail === '' ||
      values.purchaserFirstName === '' ||
      values.purchaserLastName === '' ||
      Boolean(touched.purchaserEmail && errors.purchaserEmail) ||
      (notifyRecipient && values.gifteeEmail == '') ||
      (notifyRecipient && values.gifteeFirstName == '')
    );
  };

  return (
    <Formik
      initialValues={{
        purchaserFirstName: userState.user?.profile.firstName ?? '',
        purchaserLastName: userState.user?.profile.lastName ?? '',
        purchaserEmail: userState.user?.profile.email ?? '',
        gifteeEmail: '',
        gifteeFirstName: '',
        gifteeLastName: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        purchaserEmail: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        purchaserFirstName: Yup.string().required('First name is required'),
        purchaserLastName: Yup.string().required('Last name is required'),
        gifteeEmail: Yup.string()
          .test(
            'email-required-if-notify-recipient',
            'Email is required',
            (value) => {
              return !notifyRecipient || (!!value && value.length > 0);
            }
          )
          .email('Must be a valid email')
          .max(255),
        gifteeFirstName: Yup.string().test(
          'first-name-required-if-notify-recipient',
          'First name is required',
          (value) => {
            return !notifyRecipient || (!!value && value.length > 0);
          }
        ),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          const purchaser: IGiftPurchaser = {
            email: values.purchaserEmail,
            firstName: values.purchaserFirstName,
            lastName: values.purchaserLastName,
          };

          let giftee: IGiftee | undefined = undefined;
          if (notifyRecipient && values.gifteeEmail) {
            giftee = {
              email: values.gifteeEmail,
              firstName: values.gifteeFirstName,
              lastName: values.gifteeLastName,
            };
          }

          await props.onSubmit(purchaser, giftee);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: (error as Error).message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <IonRow>
            <IonCol
              sizeXs="12"
              sizeXl="6"
              style={{
                paddingLeft: 0,
                paddingBottom: 0,
                paddingRight: isSmallScreenSizeOrSmaller ? 0 : 'auto',
              }}
            >
              <TextField
                autoFocus
                error={Boolean(
                  touched.purchaserFirstName && errors.purchaserFirstName
                )}
                fullWidth
                helperText={
                  touched.purchaserFirstName && errors.purchaserFirstName
                }
                label="Your First name"
                margin="normal"
                name="purchaserFirstName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="name"
                value={values.purchaserFirstName}
                variant="outlined"
              />
            </IonCol>
            <IonCol
              sizeXs="12"
              sizeXl="6"
              style={{
                paddingRight: 0,
                paddingBottom: 0,
                paddingLeft: isSmallScreenSizeOrSmaller ? 0 : 'auto',
              }}
            >
              <TextField
                error={Boolean(
                  touched.purchaserLastName && errors.purchaserLastName
                )}
                fullWidth
                helperText={
                  touched.purchaserLastName && errors.purchaserLastName
                }
                label="Your Last name"
                margin="normal"
                name="purchaserLastName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="name"
                value={values.purchaserLastName}
                variant="outlined"
              />
            </IonCol>
          </IonRow>
          <TextField
            error={Boolean(touched.purchaserEmail && errors.purchaserEmail)}
            fullWidth
            helperText={touched.purchaserEmail && errors.purchaserEmail}
            label="Your Email"
            margin="normal"
            name="purchaserEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            placeholder="user@gmail.com"
            value={values.purchaserEmail}
            variant="outlined"
          />
          <IonRow style={{ marginTop: '2em' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifyRecipient}
                    onChange={() => {
                      setNotifyRecipient(!notifyRecipient);
                    }}
                    sx={{
                      color: 'var(--ion-color-gray)',
                      '&.Mui-checked': {
                        color: 'var(--ion-color-primary)',
                      },
                    }}
                  />
                }
                label="Notify Recipient"
                sx={{ color: 'gray' }}
              />
            </FormGroup>
          </IonRow>
          {notifyRecipient && (
            <>
              <IonRow>
                <IonCol
                  sizeXs="12"
                  sizeXl="6"
                  style={{
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingRight: isSmallScreenSizeOrSmaller ? 0 : 'auto',
                  }}
                >
                  <TextField
                    error={Boolean(
                      touched.gifteeFirstName && errors.gifteeFirstName
                    )}
                    fullWidth
                    helperText={
                      touched.gifteeFirstName && errors.gifteeFirstName
                    }
                    label="Recipient First name"
                    margin="normal"
                    name="gifteeFirstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="name"
                    value={values.gifteeFirstName}
                    variant="outlined"
                  />
                </IonCol>
                <IonCol
                  sizeXs="12"
                  sizeXl="6"
                  style={{
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: isSmallScreenSizeOrSmaller ? 0 : 'auto',
                  }}
                >
                  <TextField
                    fullWidth
                    label="Recipient Last name"
                    margin="normal"
                    name="gifteeLastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="name"
                    value={values.gifteeLastName}
                    variant="outlined"
                  />
                </IonCol>
              </IonRow>
              <TextField
                error={Boolean(touched.gifteeEmail && errors.gifteeEmail)}
                fullWidth
                helperText={touched.gifteeEmail && errors.gifteeEmail}
                label="Recipient Email"
                margin="normal"
                name="gifteeEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                placeholder="user@gmail.com"
                value={values.gifteeEmail}
                variant="outlined"
              />
            </>
          )}
          {errors.submit && (
            <IonRow>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </IonRow>
          )}
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              className="submit-button no-caps"
              disabled={submitDisabled(values, touched, errors) || isSubmitting}
              style={{
                opacity: submitDisabled(values, touched, errors) ? 0.5 : 1.0,
              }}
              type="submit"
            >
              {(isSubmitting && (
                <IonSpinner color="white" name="crescent" />
              )) || <p>Checkout</p>}
            </button>
          </IonRow>
        </form>
      )}
    </Formik>
  );
};

export default PurchaseGift;
