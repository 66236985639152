import { useState, useEffect } from 'react';
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area'; // cspell:disable-line

export default function useStatusBar() {
  const [statusBarTop, setStatusBarTop] = useState<number>();

  useEffect(() => {
    const getAndSetStatusBarHeight = async () => {
      const { top } = await SafeArea.getSafeAreaInsets();
      setStatusBarTop(top);
    };

    // Async - fire and forget
    getAndSetStatusBarHeight().catch((): void => {
      setStatusBarTop(0);
    });
  }, []);

  return statusBarTop;
}
