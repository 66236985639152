import { createContext, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';
import { $enum } from 'ts-enum-util';

import { AllowedPath, FareDropPlan, STRIPE_PLAN_ID } from '@faredrop/types';
import { hasPlanAccess, stripePlanIDToFareDropPlan } from '@faredrop/utilities';

import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useUser from '../hooks/user';
import usePresentToast from '../hooks/presentToast';
import useAuth from '../hooks/auth';
import useInterval from '../hooks/useInterval';

export interface RefreshAccountValue {
  isAccountRefreshed?: boolean;
  isAccountReady: boolean;
}

// Since we set the RefreshAccountProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const RefreshAccountContext = createContext<RefreshAccountValue>({
  isAccountReady: false,
});

interface RefreshAccountProps {
  children: ReactNode;
}

export const RefreshAccountProvider: FC<RefreshAccountProps> = (props) => {
  const {
    isInitialized,
    isAuthenticated,
    refreshSession,
    currentUserHasSubscription,
  } = useAuth();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const userState = useUser();
  const { presentError } = usePresentToast();
  const location = useLocation();

  const pollAttempts = useRef(0);
  const [intervalDelay, setIntervalDelay] = useState<number | null>(null);
  const [isAccountRefreshed, setIsAccountRefreshed] = useState<boolean>();
  const [isAccountRefreshing, setIsAccountRefreshing] = useState<boolean>();
  const [check, setCheck] = useState<boolean>();

  useEffect(() => {
    if (
      isInitialized &&
      isAuthenticated &&
      !userState.isInitializing &&
      !isAccountRefreshing &&
      location.pathname !== AllowedPath.LOGOUT
    ) {
      const url = new URL(window.location.href);
      if (url.searchParams.has('refreshAccount')) {
        refreshAccount().catch(() =>
          presentError(
            'Failed to retrieve account details - please refresh the page'
          )
        ); // Async - Fire and forget
      } else {
        currentUserHasSubscription()
          .then((hasSubscription) => {
            if (hasSubscription) {
              setIsAccountRefreshed(true);
            }
            setIsAccountRefreshing(false);
          })
          .catch(() => {
            presentError(
              'Failed to refresh account information - please refresh the page'
            );
          });
      }
    }
  }, [
    isInitialized,
    isAuthenticated,
    userState.isInitializing,
    isAccountRefreshing,
    location.pathname,
  ]);

  useEffect(() => {
    if (check != null) {
      const url = new URL(window.location.href);

      const cleanUp = () => {
        url.searchParams.delete('newPlan');
        url.searchParams.delete('refreshAccount');
        window.history.pushState({}, '', url.href);
        setIsAccountRefreshing(false);
      };

      if (pollAttempts.current >= 10) {
        setIsAccountRefreshed(false);
        goWithStickyParamsLocation({
          pathname: '/plans',
          search: `?${new URLSearchParams(window.location.search).toString()}`,
        });
        cleanUp();
      } else {
        userState
          .refreshUser()
          .catch(() => {
            presentError('Failed to refresh account - please refresh the page');
          })
          .finally(() => {
            setIsAccountRefreshing(true);
            cleanUp();
          });
      }
    }
  }, [check]);

  useInterval(async () => {
    await refreshAccount();
  }, intervalDelay);

  const refreshAccount = async () => {
    setIsAccountRefreshing(true);

    const url = new URL(window.location.href);
    const newPlan = url.searchParams.get('newPlan');
    const refreshedFareDropUser = await refreshSession(); // NOTE: This updates state, so we need to wait until the next render to check

    if (
      hasPlanAccess(
        refreshedFareDropUser?.roles,
        newPlan
          ? stripePlanIDToFareDropPlan(
              $enum(STRIPE_PLAN_ID).asValueOrThrow(newPlan)
            )
          : FareDropPlan.LIMITED
      ) ||
      pollAttempts.current >= 10
    ) {
      setIntervalDelay(null);
      setCheck(!check);
    } else if (intervalDelay === null) {
      setIntervalDelay(1000);
    }

    pollAttempts.current++;
  };

  return (
    <RefreshAccountContext.Provider
      value={{
        isAccountRefreshed,
        isAccountReady: isAccountRefreshing === false,
      }}
    >
      {props.children}
    </RefreshAccountContext.Provider>
  );
};

export default RefreshAccountContext;
