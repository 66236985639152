import { Capacitor } from '@capacitor/core';
import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import './../theme/LoginPage.css';
import './../theme/util.css';

const UnsupportedVersion: React.FC = () => {
  const isIos = Capacitor.getPlatform() === 'ios';
  const updateUrl = isIos
    ? process.env.REACT_APP_IOS_URL
    : process.env.REACT_APP_ANDROID_URL;
  const storeType = isIos ? 'App' : 'Play';
  return (
    <IonPage>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            transition: 'opacity 800ms',
            textAlign: 'center',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8">
            <IonRow className="row-vertical-align" style={{ margin: 5 }}>
              <h4 style={{ fontFamily: 'nexa-bold' }}>
                {`This version of FareDrop is out of date. Please update FareDrop from the ${storeType} Store.`}
              </h4>
            </IonRow>
            <IonButton
              style={{
                height: '3em',
                width: '15em',
                marginTop: '2em',
                borderRadius: 8,
                textTransform: 'none',
              }}
              href={updateUrl}
            >
              Update
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default UnsupportedVersion;
