import { IonCol, IonRow } from '@ionic/react';
import {
  alertCircleOutline,
  closeOutline,
  searchOutline,
} from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';

import { UserConfigOrigin } from '@faredrop/graphql-sdk';

import './../theme/util.css';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import useSearchAirports from '../hooks/useSearchAirports';
import { DropdownOptionWithSubtext } from '../types/types';
import AddedDropdownBadge from './AddedDropdownBadge';
import DepartureAirportCard from './DepartureAirportCard';
import Loading from './Loading';
import MapboxMap from './MapboxMap';
import useAirports from '../hooks/airports';
import usePresentToast from '../hooks/presentToast';
import { isLimitedPlan } from '../utilities/plans-utilities';
import LimitedUpgradeCTA from './LimitedUpgradeCTA';
import { formatGeoCoderOption } from '../contexts/airportSearchContext';
import SearchBarWithResults from './SearchBarWithResults';

interface ContainerProps {
  showMap: boolean;
  highlightOnHover?: boolean;
  onChangeHomeAirport?: () => void;
  onSelectHomeAirport?: () => void;
  onInvalidEditOriginConfig?: (iata: string) => void;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const SearchAirportsComponent: React.FC<ContainerProps> = ({
  showMap,
  highlightOnHover,
  onChangeHomeAirport,
  onSelectHomeAirport,
  onInvalidEditOriginConfig,
  logAnalyticsEngagement,
}) => {
  const { origins, getAirport } = useAirports();
  const { isSmallScreenSizeOrSmaller } = useDevice();
  const searchbarRef = useRef<HTMLIonSearchbarElement>(null);
  const { searchAirports } = useSearchAirports();
  const [viewLoaded, setViewLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { presentError } = usePresentToast();

  const userState = useUser();
  const userProfile = userState.user?.profile;
  const userBilling = userState.user?.billing;

  const homeOriginIATA = userState.user?.configuration.homeOriginIATA;

  const sortCards = (
    _originA: UserConfigOrigin,
    _originB: UserConfigOrigin
  ) => {
    const airportA = getAirport(_originA.iata);
    const airportB = getAirport(_originB.iata);

    if (
      !airportA ||
      !airportB ||
      airportA.name == null ||
      airportB.name == null
    ) {
      return -1;
    }

    if (airportA.name < airportB.name) {
      return -1;
    }

    return 1;
  };

  // State cannot directly be manipulated (e.g., w/ sort), so we need to clone the array
  // TypeError: Cannot assign to readonly property
  const userConfigOrigins = userState.user
    ? [...userState.user.configuration.origins].sort(sortCards)
    : [];

  // Set home origin as the first in the list
  if (homeOriginIATA) {
    const homeOriginIndex = userConfigOrigins.findIndex(
      (origin) => origin.iata === homeOriginIATA
    );
    if (homeOriginIndex !== -1) {
      const homeOrigin = userConfigOrigins.splice(homeOriginIndex, 1);
      if (homeOrigin.length > 0) {
        userConfigOrigins.unshift(homeOrigin[0]);
      }
    }
  }

  useEffect(() => {
    if (origins?.length && userProfile?.id && !viewLoaded) {
      setViewLoaded(true);
    }
  }, [origins, userProfile?.id]);

  const onSearchChange = async (searchText?: string) => {
    let result: DropdownOptionWithSubtext[] | undefined = undefined;
    if (searchText) {
      const [origins] = await Promise.all([
        searchAirports(searchText, {
          originsOnly: true,
          useMapBoxFallback: true,
        }),
      ]);
      result = origins.map((d) =>
        formatGeoCoderOption(d, { useParentAirports: true })
      );
    }
    return result;
  };

  const onSearchSelect = async (option: DropdownOptionWithSubtext) => {
    return {
      displayText: option.text,
    };
  };

  const onSearchSubmit = async (searchText?: string) => {
    setLoading(true);
    const split = searchText?.split('(');
    if (split) {
      const iata = split[1].replace(')', '').trim();

      const airport = await getAirport(iata);
      if (airport) {
        if (isLimitedPlan(userState.user?.billing.idStripePlan)) {
          await userState.resetOrigins(airport.iata, [
            {
              iata: airport.iata,
            } as UserConfigOrigin,
          ]);
        } else {
          const origins =
            userConfigOrigins.filter(
              (origin) => origin.iata !== airport?.iata
            ) ?? [];
          const origin = userConfigOrigins.find(
            (origin) => origin.iata === airport?.iata
          );

          if (!origin) {
            const updatedOrigins = [
              {
                iata: airport?.iata,
              } as UserConfigOrigin,
              ...origins,
            ];
            const promises: Promise<void>[] = [
              userState.setOrigins(updatedOrigins),
            ];
            logAnalyticsEngagement &&
              promises.push(
                logAnalyticsEngagement(AnalyticsEngagementId.SELECT_ORIGIN)
              );
            await Promise.all(promises);
          }
        }
      } else {
        presentError('The airport was not found');
      }
    }
    setLoading(false);
  };

  const removeAirport = async (iata: string) => {
    setLoading(true);
    const updatedOrigins =
      userConfigOrigins.filter((origin) => origin.iata != iata) ?? [];
    await userState.removeOrigin(iata, updatedOrigins);
    setLoading(false);
  };

  const updateHomeAirport = async (iata: string) => {
    setLoading(true);
    const promises: Promise<void>[] = [userState.setHomeOrigin(iata)];
    logAnalyticsEngagement &&
      promises.push(
        logAnalyticsEngagement(AnalyticsEngagementId.SELECT_HOME_ORIGIN)
      );
    await Promise.all(promises);
    setLoading(false);
  };

  const addedDropdownBadge = <AddedDropdownBadge />;

  const isLimited = isLimitedPlan(userBilling?.idStripePlan);

  return (
    (!viewLoaded && <Loading />) || (
      <div style={{ paddingBottom: '1em' }}>
        {showMap && (
          <IonCol>
            <MapboxMap searchbarRef={searchbarRef} viewLoaded={viewLoaded} />
          </IonCol>
        )}
        <LimitedUpgradeCTA
          isLimitedPlan={isLimited}
          linkText="Upgrade"
          message="to unlock 10 departure airports."
        />
        <div
          style={{
            margin: isSmallScreenSizeOrSmaller ? '0 -1em' : 0,
            position: 'relative',
          }}
        >
          <SearchBarWithResults
            style={{
              fontSize: 14,
              borderRadius: '30px',
              border: '1px solid #ddd',
              borderBottom: '1px solid #ddd',
              height: '50px',
              color:
                userConfigOrigins.length < 10
                  ? 'inherit'
                  : 'var(--ion-color-danger)',
            }}
            expandedStyle={{
              borderRadius: '30px 30px 0 0',
              borderBottom: 'none',
            }}
            dropdownStyle={{
              maxWidth: 'initial',
            }}
            className="origin-search"
            placeholder={
              userConfigOrigins.length < 10
                ? 'Search by city, airport, or ZIP code'
                : 'Remove an origin below to search for additional airports'
            }
            searchIcon={
              userConfigOrigins.length < 10 ? searchOutline : alertCircleOutline
            }
            cancelButtonIcon={closeOutline}
            color="inverse"
            clearSearchTextOnSubmit={true}
            badge={addedDropdownBadge}
            withBadge={(option: string) => {
              return userConfigOrigins.some((airport) => {
                const origin = getAirport(airport.iata);
                return option && option.includes(origin?.name ?? '');
              });
            }}
            onChange={onSearchChange}
            onSelect={onSearchSelect}
            onSubmit={onSearchSubmit}
          />
        </div>
        <IonRow style={{ margin: '2em 0 0', display: 'flex' }}>
          <h6>
            {userConfigOrigins.length}/{`${isLimited ? 1 : 10}`} Airports
            selected
          </h6>
          {loading && (
            <Loading size="20px" sx={{ width: '20px', margin: 'auto 1em' }} />
          )}
        </IonRow>
        {userConfigOrigins.length > 0 && (
          <IonRow style={{ display: 'flex', marginBottom: '1em' }}>
            <p style={{ margin: 0 }}>
              Select an airport below to make it your home airport
            </p>
          </IonRow>
        )}
        <IonRow
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isSmallScreenSizeOrSmaller ? 'center' : 'left',
            marginBottom: '6em',
          }}
        >
          {userConfigOrigins.map((origin) => {
            return (
              <DepartureAirportCard
                key={origin.iata}
                airport={
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  getAirport(origin.iata)!
                }
                onRemoveCard={removeAirport}
                origin={origin}
                isHomeAirport={origin.iata === homeOriginIATA}
                hideEditHomeAirportButton={
                  userConfigOrigins.length === 1 || loading
                }
                hideRemoveButton={loading}
                onChangeHomeAirport={() =>
                  onChangeHomeAirport && onChangeHomeAirport()
                }
                highlightOnHover={highlightOnHover}
                onSelect={async (iata) => {
                  onSelectHomeAirport && onSelectHomeAirport();
                  await updateHomeAirport(iata);
                }}
                onEditOriginConfig={async (attributes) => {
                  await userState.updateOrigin(origin.iata, attributes);
                }}
                onInvalidEditOriginConfig={() => {
                  onInvalidEditOriginConfig &&
                    onInvalidEditOriginConfig(origin.iata);
                }}
              />
            );
          })}
        </IonRow>
      </div>
    )
  );
};

export default SearchAirportsComponent;
