import { IonRow } from '@ionic/react';
import _ from 'lodash';
import { useRef } from 'react';

import { Airport, SeatClass } from '@faredrop/graphql-sdk';
import { FlightDesignation } from '@faredrop/types';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import DealsSection from '../DealsSection';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { CARD_WIDTH } from '../../utilities/constants';
import { IDestinationDeals } from '../DealCard';
import { useDevice } from '../../hooks/useDevice';
import { DealsDashboardList } from '../../contexts/dealsDashboardContext';

interface ContainerProps {
  origin: Pick<Airport, 'iata' | 'city'>;
  seatClass: SeatClass;
  flightDesignation: FlightDesignation;
  destinationDeals: IDestinationDeals[];
  areDealsLoaded?: boolean;
  onSelectDeal: (
    destinationDeals: IDestinationDeals,
    index: number,
    activeIndex: number,
    isDealLocked: boolean
  ) => Promise<void>;
  onRefresh?: () => Promise<void>;
}

const DealDetailsMoreDeals: React.FC<ContainerProps> = ({
  origin,
  seatClass,
  flightDesignation,
  destinationDeals,
  areDealsLoaded,
  onSelectDeal,
  onRefresh,
}) => {
  const { isExtraSmallScreenSize, isSmallScreenSizeOrSmaller } = useDevice();
  const moreDealsRef = useRef<HTMLIonRowElement>(null);
  const { width: moreDealsWidth } = useContainerDimensions(moreDealsRef);

  const slidesPerView = moreDealsWidth
    ? moreDealsWidth / CARD_WIDTH
    : undefined;

  let dealSectionType =
    flightDesignation === FlightDesignation.DOMESTIC
      ? DealsDashboardList.DOMESTIC_ECONOMY
      : DealsDashboardList.INTERNATIONAL_ECONOMY;
  if (seatClass === SeatClass.Business) {
    dealSectionType = DealsDashboardList.INTERNATIONAL_BUSINESS;
  }

  return (
    <IonRow
      style={{
        display: 'flex',
        marginTop: '3em',
        marginBottom: '6em',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IonRow
        style={{
          fontSize: isExtraSmallScreenSize ? '22px' : '30px',
          lineHeight: isExtraSmallScreenSize ? '22px' : '50px',
          textAlign: 'center',
        }}
      >
        More Deals from {origin.city}
      </IonRow>
      <IonRow ref={moreDealsRef} style={{ width: '100%' }}>
        {slidesPerView != null && slidesPerView > 0 && (
          <DealsSection
            onRefresh={
              onRefresh
                ? async () => {
                    await onRefresh();
                  }
                : undefined
            }
            deals={destinationDeals}
            onSelect={async (
              destinationDeals,
              index,
              activeIndex,
              isDealLocked
            ) => {
              await onSelectDeal(
                destinationDeals,
                index,
                activeIndex,
                isDealLocked
              );
            }}
            viewLoaded={areDealsLoaded ?? false}
            sx={{
              width: '100%',
              marginTop: isSmallScreenSizeOrSmaller
                ? isExtraSmallScreenSize
                  ? 0
                  : '1em'
                : undefined,
              paddingLeft: isSmallScreenSizeOrSmaller ? '2em' : undefined,
            }}
            type={dealSectionType}
            slidesPerView={slidesPerView}
            swiperStyle={{
              padding: 'initial',
            }}
            swiperContainerStyle={{
              paddingTop: 'initial',
            }}
            arrowsStyle={{
              marginRight: 'initial',
            }}
          />
        )}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsMoreDeals;
