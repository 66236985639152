import { CognitoUser } from 'amazon-cognito-identity-js';

export default class NewPasswordRequiredError extends Error {
  user: CognitoUser;

  constructor(user: CognitoUser) {
    super();
    this.name = 'NewPasswordRequiredError';
    this.user = user;
  }
}
