import useSWR from 'swr';

import { Airport } from '@faredrop/graphql-sdk';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import useSearchAirports from './useSearchAirports';
import { LandingDeal } from '../types/types';
import useAnalytics from './analytics';

export interface LandingPageDealsValue {
  deals?: LandingDeal[];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  landingPageDealsKey: (iata: string) => string[];
}

const useLandingPageDeals = (originName?: string): LandingPageDealsValue => {
  const { origins } = useSearchAirports();
  const { logAnalyticsError } = useAnalytics();

  const landingPageDealsKey = (iata: string) => [
    'LANDING_PAGE_DEALS',
    process.env.REACT_APP_VERSION ?? '',
    iata,
  ];

  let origin:
    | Pick<
        Airport,
        'city' | 'country' | 'name' | 'iata' | 'lat' | 'lng' | 'region'
      >
    | undefined;
  if (originName) {
    origin = origins.find((o) => o.name === originName);
  }

  const key = origin?.iata ? landingPageDealsKey(origin.iata) : null;

  const { data, error, isValidating } = useSWR(
    key,
    async (_scopedPrefix, _scopedVersion, scopedIATA) => {
      try {
        if (!scopedIATA) {
          return [];
        }

        const raw = await fetch(
          `${getAssetsBaseUrl()}/data/landing-page-deals/${scopedIATA}.json`
        );
        const json = await raw.json();

        if (Array.isArray(json)) {
          return json as LandingDeal[];
        } else {
          throw new Error(
            `Invalid return type for landing page ${scopedIATA} deals. ${json}`
          );
        }
      } catch (error) {
        await logAnalyticsError('getLandingPageDeals', error as Error);
        throw error;
      }
    }
  );

  return {
    deals: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
    landingPageDealsKey,
  };
};

export default useLandingPageDeals;
