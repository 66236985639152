import { IonHeader, IonRow, IonToolbar } from '@ionic/react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';

import { SubscriptionStatus } from '@faredrop/graphql-sdk';
import {
  AllowedPath,
  FAREDROP_SUBSCRIPTION_STATUS,
  STRIPE_SUBSCRIPTION_STATUS,
} from '@faredrop/types';
import {
  isNumber,
  isNumberAndGreaterThan,
  isNumberAndLessThan,
  isNumberAndLessThanOrEqualTo,
} from '@faredrop/utilities';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import {
  allowedUninitializedBasePaths,
  allowedUninitializedUrls,
} from '../contexts/authContext';
import useStripeHook from '../hooks/useStripe';
import Loading from './Loading';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { stripTrailingSlash } from '../utilities/utils';

interface ContainerProps {
  hideDecimal: number;
  style?: React.CSSProperties;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  children: JSX.Element;
  promotionText?: string | JSX.Element;
  bannerClickListener?: () => void;
  hideToolbar?: boolean;
}

const HidingHeader: React.FC<ContainerProps> = ({
  hideDecimal,
  style,
  logAnalyticsEngagement,
  children,
  promotionText,
  bannerClickListener,
  hideToolbar,
}) => {
  const { isExtraSmallScreenSize, isApp, isMediumScreenSizeOrSmaller } =
    useDevice();
  const userState = useUser();
  const hasPaymentInfo = userState.user?.billing.hasPaymentInfo;
  const location = useLocation();
  const header = useRef(null);
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { isAuthenticated } = useAuth();
  const { createEditPaymentSession } = useStripeHook();
  const [loading, setLoading] = useState(false);
  const [isBannerRoute, setIsBannerRoute] = useState(!hasPaymentInfo);

  useEffect(() => {
    // Don't allow trailing "/"'s to prevent a user from accessing an unguarded page
    const normalizedPathName = stripTrailingSlash(window.location.pathname);

    // Only show banner once logged in
    // TODO: Create bannerContext so that we can support banners for unauthenticated users
    if (
      !allowedUninitializedUrls.includes(location.pathname as AllowedPath) &&
      !allowedUninitializedBasePaths.some((p) =>
        normalizedPathName.startsWith(p)
      )
    ) {
      setIsBannerRoute(true);
    } else {
      setIsBannerRoute(false);
    }
  }, [location.pathname]);

  const showBannerForNoPaymentInfo =
    !hasPaymentInfo &&
    // Only show this banner if the user does not have payment info and
    // the stripePlanEnd is between now and 14 days from now
    // This is to not overly annoy people in the case of 2-week Free Trials
    moment(userState.user?.billing.stripePlanEnd).isAfter(moment()) &&
    moment(userState.user?.billing.stripePlanEnd).isBefore(
      moment().add(14, 'days')
    );

  const bannerColor =
    // Initial payment info passed client side preflight, but failed to process.
    // These are the highest priority banner and thus should be the primary clause for the banner color
    userState.subscriptionStatus === SubscriptionStatus.Incomplete
      ? 'var(--ion-color-danger)'
      : // This handles the case when the free trial is briefly set to Active before a drafted invoice is finalized
      isNumberAndLessThanOrEqualTo(userState.planEndInDays, 0) ||
        (showBannerForNoPaymentInfo &&
          (userState.subscriptionStatus.toLowerCase() as FAREDROP_SUBSCRIPTION_STATUS) ===
            FAREDROP_SUBSCRIPTION_STATUS.ACTIVE)
      ? 'var(--ion-color-danger)'
      : !hasPaymentInfo && isNumberAndGreaterThan(userState.planEndInDays, 3)
      ? // No payment info and more than 3 days until the plan expires
        'var(--ion-color-primary)'
      : !hasPaymentInfo &&
        isNumberAndLessThan(userState.planEndInDays, 3) &&
        isNumberAndGreaterThan(userState.planEndInDays, 0)
      ? // Show yellow - the plan isn getting close to expiring
        'var(--ion-color-sunset)'
      : !hasPaymentInfo || userState.hasDealNotificationsEnabled === false
      ? // If we don't have payment info or they aren't getting notified - its a scary alert
        'var(--ion-color-danger)'
      : 'var(--ion-color-primary)';

  const bannerText =
    // These are the highest priority banner and thus should be the primary clause for the banner text
    isNumberAndLessThanOrEqualTo(userState.planEndInDays, 0) ||
    (showBannerForNoPaymentInfo &&
      !userState.isLimited &&
      (userState.subscriptionStatus.toLowerCase() as STRIPE_SUBSCRIPTION_STATUS) ===
        STRIPE_SUBSCRIPTION_STATUS.ACTIVE) // This handles the case when the free trial is briefly set to Active before a drafted invoice is finalized
      ? `Your membership is about to expire. ${
          isMediumScreenSizeOrSmaller
            ? 'Click to update your payment information!'
            : 'Click here to update your payment information before you lose access!'
        }`
      : showBannerForNoPaymentInfo &&
        isNumber(userState.planEndInDays) &&
        !userState.isLimited
      ? `Your free trial expires ${
          userState.planEndInDays === 0
            ? 'today'
            : `in ${userState.planEndInDays} day${
                isNumberAndGreaterThan(userState.planEndInDays, 1) ? 's' : ''
              }`
        }! ${
          isMediumScreenSizeOrSmaller
            ? 'Click to subscribe!'
            : 'Click here to subscribe to FareDrop for more amazing deals!'
        }`
      : userState.hasDealNotificationsEnabled === false
      ? 'No notification methods enabled! Go to My Account or log in on our mobile app to enable notifications'
      : undefined;

  const showBannerText =
    !promotionText &&
    bannerText &&
    isBannerRoute &&
    isAuthenticated &&
    (showBannerForNoPaymentInfo ||
      (userState.user?.billing?.idStripeCustomer &&
        isNumberAndLessThanOrEqualTo(userState.planEndInDays, 0)) ||
      userState.hasDealNotificationsEnabled === false ||
      userState.subscriptionStatus === SubscriptionStatus.Incomplete);

  let bannerComponent: JSX.Element | undefined = undefined;
  if (promotionText) {
    if (typeof promotionText === 'string') {
      bannerComponent = (
        <p style={isApp ? { marginTop: 50 } : {}}>{promotionText}</p>
      );
    } else {
      bannerComponent = promotionText;
    }
  } else if (showBannerText) {
    if (loading) {
      bannerComponent = <Loading size="small" color="white" />;
    } else {
      bannerComponent = (
        <p style={isApp ? { marginTop: 50 } : {}}>{bannerText}</p>
      );
    }
  }

  const styles = useMemo(() => {
    const top = -hideDecimal * 150 + (promotionText || showBannerText ? 50 : 0);
    return isApp
      ? {}
      : {
          transition: 'top 700ms',
          top: `${top}px`,
          backgroundColor: isExtraSmallScreenSize ? '#ffffff' : 'transparent',
          boxShadow: isExtraSmallScreenSize
            ? '0px 6px 10px rgba(0, 0, 0, 15%)'
            : 'none',
          borderBottom: isExtraSmallScreenSize ? '1px solid #ccc' : 'none',
        };
  }, [hideDecimal, promotionText, showBannerText]);

  return (
    <IonHeader
      ref={header}
      style={{ ...style, position: 'relative', zIndex: 10 }}
      className="ion-no-border"
    >
      {(promotionText || showBannerText) && (
        <IonRow
          className="header-promotion header-promotion-mobile"
          onClick={async () => {
            if (promotionText) {
              bannerClickListener && bannerClickListener();
              logAnalyticsEngagement &&
                (await logAnalyticsEngagement(AnalyticsEngagementId.PROMOTION));
            } else if (showBannerText) {
              setLoading(true);
              if (userState.hasDealNotificationsEnabled === false) {
                goWithStickyParamsPath(AllowedPath.ACCOUNT_PROFILE);
              } else if (
                userState.subscriptionStatus === SubscriptionStatus.Incomplete
              ) {
                try {
                  await createEditPaymentSession(
                    AllowedPath.PLANS.slice(1),
                    [
                      {
                        key: 'refreshAccount',
                        value: 'true',
                      },
                    ],
                    location.pathname.slice(1)
                  );
                } catch (error) {
                  setLoading(false);
                }
              } else if (
                isNumberAndLessThanOrEqualTo(userState.planEndInDays, 0)
              ) {
                goWithStickyParamsPath(AllowedPath.ACCOUNT_PLAN_AND_BILLING);
              } else {
                bannerClickListener && bannerClickListener();
              }
            }
          }}
          style={{
            backgroundColor:
              (promotionText ? 'var(--ion-color-tertiary-shade)' : undefined) ??
              bannerColor ??
              'var(--ion-color-primary)',
            height: isApp ? 100 : undefined,
            paddingLeft: '15px',
            paddingRight: '15px',
            position: 'relative',
            zIndex: 10,
          }}
        >
          {bannerComponent}
        </IonRow>
      )}
      <IonRow
        style={{ ...styles, position: 'absolute', zIndex: 1, width: '100%' }}
      >
        {!hideToolbar && <IonToolbar>{children}</IonToolbar>}
      </IonRow>
    </IonHeader>
  );
};

export default memo(HidingHeader);
