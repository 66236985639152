import { FC } from 'react';

import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';

import './../theme/LoginPage.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import PasswordReset from '../components/PasswordReset';

const PasswordResetPage: FC = () => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const queryParams = new URLSearchParams(location.search);
  // URLSearchParams handles the decoding of these params for us. Calling decodeURI or
  // decodeURIComponent on these params, given that they have already been decoded, actually
  // throws a URI encoding error...
  const queryParamEmail = queryParams.get('email') ?? '';

  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          padding: '15px 40px',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader menuHidden={true} showBackToHome={true} />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            showBackToHome={true}
            ctaHidden={true}
          />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow className="row-vertical-align" style={{ padding: '2em' }}>
          <IonCol sizeXs="12" sizeLg="4">
            <h1
              className="title-font"
              style={{ textAlign: 'center', fontSize: 24 }}
            >
              Password Reset
            </h1>
            <h2 style={{ fontSize: 16 }}>
              Almost there! Last step is to enter your new password.
            </h2>
            <hr />
            <PasswordReset
              onPasswordReset={() => {
                goWithStickyParamsLocation({
                  pathname: '/login',
                  search: queryParamEmail
                    ? `?email=${encodeURIComponent(queryParamEmail)}`
                    : undefined,
                });
              }}
              hideCodeSection={true}
              useLoginFunction={true}
            />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default PasswordResetPage;
