import { IonRow, IonIcon } from '@ionic/react';
import StarIcon from '@material-ui/icons/Star';
import { Tooltip } from '@mui/material';
import { airplane } from 'ionicons/icons';
import _ from 'lodash';

import { Deal } from '@faredrop/graphql-sdk';
import { FareDropPlan } from '@faredrop/types';
import {
  getCurrencySymbol,
  stripePlanIDByFareDropPlan,
} from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import useUser from '../../hooks/user';
import useAirports from '../../hooks/airports';

interface ContainerProps {
  deals: Deal[];
  onSelectDeal: (deal: Deal) => Promise<void>;
}
const DealDetailsOtherDepartureAirportDeals: React.FC<ContainerProps> = ({
  deals,
  onSelectDeal,
}) => {
  const { getAirport } = useAirports();
  const userState = useUser();
  const homeOriginIATA = userState.user?.configuration.homeOriginIATA;

  return (
    <IonRow
      style={{
        display: 'flex',
        marginBottom: '6em',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IonRow
        style={{
          textAlign: 'center',
          fontStyle: 'nexa-bold',
          lineHeight: '22px',
          fontSize: '14px',
          color: 'var(--ion-color-primary)',
          marginBottom: '2em',
          letterSpacing: '.1em',
        }}
      >
        {`FROM ${
          userState.user?.billing.idStripePlan ===
          stripePlanIDByFareDropPlan(FareDropPlan.PRO)
            ? 'YOUR '
            : ''
        }OTHER AIRPORTS`}
      </IonRow>
      <IonRow style={{ width: '100%', justifyContent: 'center' }}>
        {deals.map((deal) => {
          const origin = getAirport(deal.originIATA);
          return (
            <div
              key={`${deal.idAirfareSource}#${deal.originIATA}#${deal.destinationIATA}#${deal.seatClass}`}
              className="deal-details-other-origin"
              style={{
                display: 'flex',
                lineHeight: '27px',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginTop: '5px',
                cursor: 'pointer',
                borderRadius: '5px',
              }}
              onClick={async () => {
                await onSelectDeal(deal);
              }}
            >
              <div
                style={{
                  alignSelf: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {deal.originIATA === homeOriginIATA ? (
                  <Tooltip title="Home Airport">
                    <StarIcon
                      style={{ marginRight: '3px', fontSize: '22px' }}
                      className="gray"
                    />
                  </Tooltip>
                ) : (
                  <IonIcon
                    className="airplane-icon gray"
                    style={{ marginRight: '3px' }}
                    icon={airplane}
                  />
                )}
              </div>
              <div style={{ paddingTop: '3px' }}>
                {origin?.city}
                <span
                  style={{
                    color: 'var(--ion-color-gray-sub-text)',
                    marginLeft: '5px',
                  }}
                >
                  {deal.originIATA}
                </span>
                <span
                  style={{
                    color: 'var(--ion-color-primary)',
                    marginLeft: '5px',
                    fontSize: '9px',
                    verticalAlign: 'super',
                  }}
                >
                  {getCurrencySymbol(deal.currency)}
                </span>
                <span style={{ color: 'var(--ion-color-primary)' }}>
                  {deal.minPrice}
                </span>
              </div>
            </div>
          );
        })}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsOtherDepartureAirportDeals;
