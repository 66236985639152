import { ContentType, ListContentQuery } from '@faredrop/graphql-sdk';
import useFareDropApiClient from './faredropApiClient';
import useSWR from 'swr';
import useAnalytics from './analytics';

export type ListContentResult = ListContentQuery['listContent'];

const useDailyDropContent = () => {
  const { client: authenticatedFareDrop } = useFareDropApiClient();
  const { logAnalyticsError } = useAnalytics();

  const key = ['DAILY_DROP_CONTENT', process.env.REACT_APP_VERSION ?? ''];

  const { data, error, isValidating } = useSWR(key, async () => {
    try {
      const result = await authenticatedFareDrop.listContent({
        type: ContentType.Newsletter,
      });
      return result.data.listContent;
    } catch (error) {
      await logAnalyticsError('listContent', error as Error);
      throw error;
    }
  });

  return {
    dailyDropContent: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
  };
};

export default useDailyDropContent;
