import { IonIcon, IonRow } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import { useState } from 'react';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import DropdownOptionsContainer from './DropdownOptionsContainer';

interface ContainerProps {
  hidden?: boolean;
  hrefOptions?: boolean;
  placeholder: string;
  options: string[];
  onOptionClick?: (option: string) => void;
  sx?: React.CSSProperties;
  width?: string | number;
}

const Dropdown: React.FC<ContainerProps> = ({
  hidden,
  hrefOptions,
  placeholder,
  onOptionClick,
  options,
  sx,
  width,
}) => {
  const { isSmallScreenSizeOrSmaller } = useDevice();
  const [selectedValue, setSelectedValue] = useState(placeholder);
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      style={{
        display: !hidden ? 'block' : 'none',
        height: isSmallScreenSizeOrSmaller ? 'auto' : '4em',
        margin: isSmallScreenSizeOrSmaller ? '2em 0' : 'auto',
        width: width ?? (isSmallScreenSizeOrSmaller ? '100%' : 'auto'),
        borderRadius: '8px',
        position: 'relative',
        zIndex: 10000,
        backgroundColor: 'white',
      }}
      className="dropdown"
    >
      <IonRow
        className="row-vertical-align dropdown-top"
        style={{
          justifyContent: 'left',
          height: 'auto',
          borderRadius: expanded ? '8px 8px 0 0' : '8px',
          borderBottom: expanded ? 'none' : '1px solid #C4C4C4',
          border: '1px solid #C4C4C4',
          zIndex: 1000,
          ...sx,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        <p style={{ margin: isSmallScreenSizeOrSmaller ? '8px' : 'inherit' }}>
          {selectedValue}
        </p>
        <IonIcon
          style={{ position: 'absolute', right: '1em' }}
          icon={expanded ? chevronUp : chevronDown}
        />
      </IonRow>
      <DropdownOptionsContainer
        expanded={expanded}
        hrefOptions={hrefOptions}
        options={options}
        onOptionClick={onOptionClick}
        setExpanded={setExpanded}
        setSelectedValue={(option) => {
          setSelectedValue(option.text);
        }}
      />
    </div>
  );
};

export default Dropdown;
