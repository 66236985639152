import { IonCol, IonRow } from '@ionic/react';

import { AllowedPath } from '@faredrop/types';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import { useDevice } from '../hooks/useDevice';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

const AsSeenIn: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const { isLargeScreenSizeOrSmaller, isSmallScreenSizeOrSmaller } =
    useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const asSeenIn = (
    <IonCol
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      {isSmallScreenSizeOrSmaller && <h4>As seen in</h4>}
      {!isSmallScreenSizeOrSmaller && <h1>As seen in</h1>}
    </IonCol>
  );

  return (
    <IonRow
      style={{
        marginTop: '1em',
        marginBottom: '1em',
        padding: '1em',
        backgroundColor: 'var(--ion-color-primary)',
        height: isSmallScreenSizeOrSmaller ? '160px' : '200px',
        width: '100%',
        display: 'flex',
        flexDirection: isLargeScreenSizeOrSmaller ? 'column' : 'row',
        justifyContent: 'center',
        ...style,
      }}
      onClick={() => {
        goWithStickyParamsPath(AllowedPath.REVIEWS);
      }}
    >
      {isLargeScreenSizeOrSmaller && <IonRow>{asSeenIn}</IonRow>}
      <IonRow
        style={{ height: isLargeScreenSizeOrSmaller ? undefined : '100%' }}
      >
        {!isLargeScreenSizeOrSmaller && <>{asSeenIn}</>}
        <IonCol
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ height: isLargeScreenSizeOrSmaller ? '60%' : '20%' }}>
            <img
              src={`${getAssetsBaseUrl()}/logos/forbes-white.svg`}
              style={{
                height: '100%',
                width: '100%',
                margin: '0px 8px 5px 0px',
                color: 'black',
              }}
            />
          </div>
        </IonCol>
        <IonCol
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ height: isLargeScreenSizeOrSmaller ? '100%' : '60%' }}>
            <img
              src={`${getAssetsBaseUrl()}/logos/tpg-white.svg`}
              style={{
                height: '100%',
                width: '100%',
                margin: '0px 8px 5px 0px',
                color: 'black',
              }}
            />
          </div>
        </IonCol>
        <IonCol
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ height: isLargeScreenSizeOrSmaller ? '60%' : '24%' }}>
            <img
              src={`${getAssetsBaseUrl()}/logos/qvc-white.svg`}
              style={{
                height: '100%',
                width: '100%',
                margin: '0px 8px 5px 0px',
                color: 'black',
              }}
            />
          </div>
        </IonCol>
        <IonCol
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ height: isLargeScreenSizeOrSmaller ? '60%' : '24%' }}>
            <img
              src={`${getAssetsBaseUrl()}/logos/business-insider-white.svg`}
              style={{
                height: '100%',
                width: '100%',
                margin: '0px 8px 5px 0px',
                color: 'black',
              }}
            />
          </div>
        </IonCol>
        {!isSmallScreenSizeOrSmaller && (
          <>
            <IonCol
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{ height: isLargeScreenSizeOrSmaller ? '60%' : '24%' }}
              >
                <img
                  src={`${getAssetsBaseUrl()}/logos/thrillist-white.svg`}
                  style={{
                    height: '100%',
                    width: '100%',
                    margin: '0px 8px 5px 0px',
                    color: 'black',
                  }}
                />
              </div>
            </IonCol>
            <IonCol
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{ height: isLargeScreenSizeOrSmaller ? '60%' : '30%' }}
              >
                <img
                  src={`${getAssetsBaseUrl()}/logos/9to5mac-white.svg`}
                  style={{
                    height: '100%',
                    width: '100%',
                    margin: '0px 8px 5px 0px',
                    color: 'black',
                  }}
                />
              </div>
            </IonCol>
          </>
        )}
      </IonRow>
    </IonRow>
  );
};

export default AsSeenIn;
