import { IonRow } from '@ionic/react';

import { getAssetsBaseUrl } from '@faredrop/utilities';

import './../theme/HeroSection.css';
import './../theme/util.css';
import './../theme/AlternateLanding.css';
import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  title: string;
  subtitle?: string;
  imageSrc?: string;
}

const PerksHero: React.FC<ContainerProps> = ({ title, subtitle, imageSrc }) => {
  const { isLargeScreenSizeOrSmaller, isExtraSmallScreenSize } = useDevice();

  return (
    <>
      <IonRow style={{ position: 'absolute', top: 0, width: '100%' }}>
        <img
          className="alternate-landing-hero"
          src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom-large.png`}
          style={
            isLargeScreenSizeOrSmaller
              ? {
                  WebkitMaskImage:
                    'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                  maskImage:
                    'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                }
              : {}
          }
        />
        <img
          className="alternate-landing-hero"
          src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-black.png`}
          style={
            isLargeScreenSizeOrSmaller
              ? {
                  WebkitMaskImage:
                    'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                  maskImage:
                    'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                  top: -40,
                }
              : { top: -40 }
          }
        />
      </IonRow>
      <IonRow
        style={{
          flex: 1,
          marginTop: isLargeScreenSizeOrSmaller ? '1em' : undefined,
        }}
      >
        <div
          style={{
            fontFamily: 'nexa-bold',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1
            className="main-title title-font"
            style={{ width: 'initial', marginBottom: '0px' }}
          >
            {title}
          </h1>
          {subtitle && (
            <h5
              className="main-subtitle gray"
              style={{
                marginTop: '0px',
                marginLeft: '1em',
                marginRight: '1em',
                textAlign: 'center',
              }}
            >
              {subtitle}
            </h5>
          )}
        </div>
      </IonRow>
      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={imageSrc}
          style={{
            maxWidth: isExtraSmallScreenSize ? undefined : '50%',
            borderRadius: isExtraSmallScreenSize ? undefined : `15px`,
          }}
        />
      </IonRow>
    </>
  );
};

export default PerksHero;
