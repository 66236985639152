import { IonRow } from '@ionic/react';
import _ from 'lodash';

import { SupportedCurrencies } from '@faredrop/graphql-sdk';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import RoutePriceRange from '../RoutePriceRange';
import { useDevice } from '../../hooks/useDevice';

interface ContainerProps {
  price?: number;
  meanMinPrice?: number;
  meanPrice?: number;
  currency?: SupportedCurrencies;
}

const DealDetailsPriceRange: React.FC<ContainerProps> = ({
  price,
  meanMinPrice,
  meanPrice,
  currency,
}) => {
  const { isExtraSmallScreenSize } = useDevice();
  return (
    <IonRow
      style={{
        display: 'flex',
        marginBottom: '2em',
        width: '65%',
        flexDirection: 'column',
      }}
    >
      <IonRow
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          fontSize: isExtraSmallScreenSize ? '22px' : '30px',
          marginBottom: '1em',
          lineHeight: '1.1em',
          textAlign: 'center',
        }}
      >
        How good is this deal?
      </IonRow>
      <RoutePriceRange
        price={price}
        meanMinPrice={meanMinPrice}
        meanPrice={meanPrice}
        currency={currency ?? undefined}
      />
    </IonRow>
  );
};

export default DealDetailsPriceRange;
