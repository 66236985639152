import { FareDropPublicApiClient } from '@faredrop/graphql-apig-unauthenticated-sdk';

export interface FareDropPublicApiClientValue {
  client: ReturnType<typeof FareDropPublicApiClient>;
}

const useFareDropPublicApiClient = (): FareDropPublicApiClientValue => {
  return { client: FareDropPublicApiClient() };
};

export default useFareDropPublicApiClient;
