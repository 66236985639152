const CACHE_NAME = 'faredrop-app-cache';

export const localStorageProvider = () => {
  if (typeof localStorage !== 'undefined') {
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map(JSON.parse(localStorage.getItem(CACHE_NAME) || '[]'));

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
      const appCache = JSON.stringify(Array.from(map.entries()));
      localStorage.setItem(CACHE_NAME, appCache);
    });

    // We still use the map for write & read for performance.
    return map;
  } else {
    return new Map();
  }
};

export const clearLocalStorageCache = () => {
  localStorage.removeItem(CACHE_NAME);
};
