import { AllowedPath, FareDropUser } from '@faredrop/types';
import { IonRow, IonSpinner } from '@ionic/react';
import { FormHelperText, TextField } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Formik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import { default as YupPassword } from 'yup-password';
import StringSchema, { RequiredStringSchema } from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import NewPasswordRequiredError from '../errors/newPasswordRequired';
import useAuth from '../hooks/auth';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import './../theme/LoginPage.css';
import './../theme/util.css';

YupPassword(Yup);

type LoginProps = {
  email?: string;
  hideRegister?: boolean;
  passwordPlaceholder?: string;
  passwordRecoveryText?: string;
  loginQueryParams?: string;
  acceptPromotionCode?: boolean;
  submitButtonText?: string;
  onLogin?: (email: string, promotionCode?: string) => Promise<void>;
  overrideLogin?: (
    user: FareDropUser,
    email: string,
    promotionCode?: string
  ) => Promise<void>;
  onRegisterLinkPress?: () => Promise<void>;
  onNewPasswordRequired?: (user: CognitoUser) => void;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
};

const Login: FC<LoginProps> = (props: LoginProps) => {
  const { isApp } = useDevice();
  const { login } = useAuth();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  const validationSchema: {
    [key: string]:
      | RequiredStringSchema<string | undefined, AnyObject>
      | StringSchema<string | undefined, AnyObject, string | undefined>;
  } = {
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  };

  if (props.acceptPromotionCode) {
    validationSchema.promotionCode = Yup.string().required();
  }

  return (
    <Formik
      initialValues={{
        email: props.email ?? '',
        password: '',
        submit: null,
        promotionCode: '',
      }}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          const [fareDropUser] = await Promise.all([
            login(values.email, values.password),
            props.logAnalyticsEngagement
              ? props.logAnalyticsEngagement(AnalyticsEngagementId.LOGIN)
              : undefined,
          ]);

          props.onLogin &&
            (await props.onLogin(
              values.email,
              values.promotionCode === '' ? undefined : values.promotionCode
            ));

          if (props.overrideLogin) {
            await props.overrideLogin(
              fareDropUser,
              values.email,
              values.promotionCode === '' ? undefined : values.promotionCode
            );
          } else {
            goWithStickyParamsLocation({
              pathname: AllowedPath.DEALS,
              search: props.loginQueryParams,
            });
          }

          setStatus({ success: true });
          setSubmitting(false);

          values.email = '';
          values.password = '';
        } catch (err) {
          if (err instanceof NewPasswordRequiredError) {
            // If we need the user to change their password, direct them to the Password recovery screen
            setStatus({ success: true });
            setSubmitting(false);
            goWithStickyParamsLocation({
              pathname: '/password-recovery',
              search: props.loginQueryParams,
            });
          } else if (
            (err as { code: string }).code === 'UserNotConfirmedException' ||
            (err as Error).name === 'UnauthorizedError'
          ) {
            // If the user has not verified their email, navigate them to the Verify Email screen
            setStatus({ success: true });
            setSubmitting(false);
            setErrors({
              submit: 'User not confirmed. Please contact team@faredrop.com',
            });
          } else if ((err as Error).name === 'NoSubscriptionRoleError') {
            setStatus({ success: true });
            setSubmitting(false);
            setErrors({
              submit: 'Missing roles. Please contact team@faredrop.com',
            });
          } else {
            // Otherwise, throw the error that we caught
            setStatus({ success: false });
            setErrors({ submit: (err as Error).message });
            setSubmitting(false);
          }

          props.onLogin &&
            (await props.onLogin(
              values.email,
              values.promotionCode === '' ? undefined : values.promotionCode
            ));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus={false}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            placeholder="user@gmail.com"
            value={values.email}
            variant="outlined"
          />
          <IonRow>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <a
                onClick={() => {
                  let search: string | undefined = props.loginQueryParams;
                  if (values.email) {
                    const params = new URLSearchParams(props.loginQueryParams);
                    if (!params.has('email')) {
                      // Email is not a sticky param since it is PII
                      params.append('email', values.email);
                    }
                    search = `?${params.toString()}`;
                  }

                  goWithStickyParamsLocation({
                    pathname: '/password-recovery',
                    search,
                  });
                }}
              >
                {props.passwordRecoveryText ?? 'Forgot Password?'}
              </a>
            </div>
          </IonRow>
          <TextField
            autoFocus={false}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={props.passwordPlaceholder ?? 'Password'}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            autoComplete="current-password"
            value={values.password}
            placeholder="************"
            variant="outlined"
          />
          {props.acceptPromotionCode && (
            <IonRow>
              <TextField
                error={Boolean(touched.promotionCode && errors.promotionCode)}
                fullWidth
                helperText={touched.promotionCode && errors.promotionCode}
                label="Promotion Code"
                margin="normal"
                name="promotionCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.promotionCode}
                variant="outlined"
                required
              />
            </IonRow>
          )}
          {errors.submit && (
            <IonRow>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </IonRow>
          )}
          <IonRow>
            <button
              className="button no-caps submit-button"
              type="submit"
              disabled={isSubmitting}
            >
              {(isSubmitting && (
                <IonSpinner color="white" name="crescent" />
              )) || <p>{props.submitButtonText ?? 'Login'}</p>}
            </button>
          </IonRow>
          {!props.hideRegister && (
            <IonRow style={{ marginTop: '2em', justifyContent: 'center' }}>
              <a
                style={{
                  textAlign: 'center',
                }}
                onClick={async () => {
                  if (isApp) {
                    values.email = '';
                    values.password = '';
                    setSubmitting(false);
                    goWithStickyParamsLocation({
                      pathname: '/register',
                    });
                  } else {
                    props.onRegisterLinkPress &&
                      (await props.onRegisterLinkPress());
                  }
                }}
              >
                <span
                  style={{
                    color: '#696969',
                    fontFamily: 'nexa',
                    lineHeight: 2,
                  }}
                >
                  Don&apos;t have a FareDrop account yet?
                </span>{' '}
                {isApp && <br />}
                Register here
              </a>
            </IonRow>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Login;
