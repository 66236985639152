import { IonCol, IonContent, IonIcon, IonPage, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import './../theme/LoginPage.css';
import './../theme/util.css';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import { $enum } from 'ts-enum-util';
import { arrowForwardOutline } from 'ionicons/icons';
import { getPlanName } from '../utilities/plans-utilities';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

const RedeemSuccess: React.FC = () => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const [previousPlan, setPreviousPlan] = useState<FareDropPlan>();
  const [nextPlan, setNextPlan] = useState<FareDropPlan>();
  const [creditAmount, setCreditAmount] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const previousPlan = $enum(FareDropPlan).asValueOrDefault(
      queryParams.get('previousPlan'),
      undefined
    );
    setPreviousPlan(previousPlan);

    const nextPlan = $enum(FareDropPlan).asValueOrDefault(
      queryParams.get('nextPlan'),
      undefined
    );
    setNextPlan(nextPlan);

    setCreditAmount(parseFloat(queryParams.get('creditAmount') ?? '0'));

    if (!nextPlan) {
      goWithStickyParamsPath(AllowedPath.BASE);
    }
  }, []);

  let subtext = '';
  if (nextPlan && (!previousPlan || nextPlan !== previousPlan)) {
    subtext = `Your account has been upgraded to the ${getPlanName(
      nextPlan
    )}plan. `;
  }

  if (creditAmount) {
    subtext += `A credit of $${creditAmount} has been applied to your account. The credit will be applied on renewal${
      nextPlan === FareDropPlan.Pro ? '.' : ' or upgrade.'
    }`;
  }

  return (
    <IonPage>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            transition: 'opacity 800ms',
            textAlign: 'center',
          }}
        >
          <IonCol
            sizeXs="12"
            sizeLg="8"
            style={{ paddingLeft: '1em', paddingRight: '1em' }}
          >
            <IonRow className="row-vertical-align">
              <h1
                className="title-font"
                style={{
                  textAlign: 'center',
                  fontSize: '5em',
                  color: 'var(--ion-color-primary)',
                }}
              >
                Success!
              </h1>
            </IonRow>
            <IonRow className="row-vertical-align">
              <h4 style={{ fontFamily: 'nexa-bold' }}>{subtext}</h4>
            </IonRow>
            <IonRow
              className="row-vertical-align"
              style={{
                marginTop: '4em',
              }}
            >
              <IonCol size="11" style={{ display: 'flex' }}>
                <a
                  onClick={async () => {
                    goWithStickyParamsPath(AllowedPath.DEALS);
                  }}
                  style={{
                    margin: 'auto',
                    display: 'flex',
                    lineHeight: '22px',
                  }}
                >
                  Go to my Deals Dashboard
                  <IonIcon
                    icon={arrowForwardOutline}
                    style={{ margin: '-1px auto auto 4px' }}
                  />
                </a>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default RedeemSuccess;
