import { Deal, Airport } from '@faredrop/graphql-sdk';

export type SocialPlatform = 'instagram' | 'twitter';
export interface SocialProofCardInfo {
  id: number;
  city: string;
  country: string;
  destinationIATA: string;
  dealPrice: number;
  originalPrice: number;
  imgSrc: string;
  rating: number;
  comment: string;
  user: {
    name?: string;
    username?: string;
  };
  source?: SocialPlatform;
}

export enum NotificationSettingsStep {
  LOADING = -1,
  ENABLE_PUSH = 0,
  SET_NOTIFICATION_METHODS = 1,
}

export enum PAGES {
  PROFILE = 'Profile',
  DEPARTURE_AIRPORTS = 'Departure Airports',
  DESTINATION_PREFERENCES = 'Destination Preferences',
  TRAVEL_AVAILABILITY = 'Travel Availability',
  DEAL_PREFERENCES = 'Deal Preferences',
  PLAN_AND_BILLING = 'Plan & Billing',
}

export enum DEVICE_TYPES {
  IPHONE = 'iphone',
  IPAD = 'ipad',
  ANDROID = 'android',
  PHABLET = 'phablet',
  TABLET = 'tablet',
  MOBILE_WEB = 'mobileweb',
  DESKTOP = 'desktop',
}

// TODO: Right now we have no way to query for origin airports
// from the landing page. And in order to show information about a
// deal in the DealNotificationContainer in the hero section, we need an Airport
// object per an origin IATA. So for now, we are going to hard-code that data and use this
// FakeDeal type...
export type FakeDeal = {
  destinationCity: string;
  destinationCountry: string;
  destinationIATA: string;
  historicalMeanPrice: number;
  hugeDrop: boolean;
  minPrice: number;
};

export type LandingDeal = Pick<
  Deal,
  | 'originIATA'
  | 'destinationRegion'
  | 'historicalMeanPrice'
  | 'minPrice'
  | 'seatClass'
  | 'destinationIATA'
  | 'attributes' // NOTE: This is currently not set in the generated json files, adding this to make typescript happy (this field is optional, so it shouldn't break anything)
  | 'currency'
> &
  Pick<Airport, 'city' | 'country' | 'state'>;

export type DefaultLandingDeal = {
  city: string;
  destinationIATA: string;
  seatClass: string;
  historicalMeanPrice: string;
  origins: DefaultLandingDealOrigin[];
  destinationRegion: string;
  country: string;
};

export type DefaultLandingDealOrigin = {
  iata: string;
  price: string;
};

export type GeocoderOption = {
  airport?: Airport;
  subtext?: string;
};

export type DropdownOptionWithSubtext = {
  text: string;
  subtext: string;
};
