import {
  NotificationFilters,
  NotificationKinds,
  NotificationMethods,
} from '@faredrop/graphql-sdk';
import { IonRow, IonSpinner, IonToggle } from '@ionic/react';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

export type NotificationSetting = {
  name: NotificationMethods | NotificationFilters | NotificationKinds | 'PUSH';
  enabled: boolean; // If the user has enabled the filter
  label?: string; // Override the name to be displayed
  tooltip?: string;
  uiDisabled?: boolean; // Disable UI interactivity since a setting may be dependent on another setting
  uiDisabledTooltip?: string;
};

interface ContainerProps {
  setting: NotificationSetting;
  onToggle: (method: NotificationSetting) => Promise<boolean | void>;
  notificationType?: string;
  loadingOverride?: boolean | null;
  onDisabledClick?: (method: NotificationSetting) => void;
}

const formatName = (method: string, notificationType?: string) => {
  const split = method.split('_');
  let title = '';
  split.forEach((val, index) => {
    if (index === 0) {
      title = `${val[0].toUpperCase()}${val.substring(1).toLowerCase()}`;
    } else {
      title += ` ${val.toLowerCase()}`;
    }
  });
  return `${title} ${notificationType ?? 'notifications'}`;
};

const NotificationOptionRow: React.FC<ContainerProps> = ({
  setting,
  onToggle,
  notificationType,
  loadingOverride,
  onDisabledClick,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loadingOverride) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingOverride]);

  const text = (
    <p
      style={{
        marginRight: '5em',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {setting.label ?? formatName(setting.name, notificationType)}
    </p>
  );

  const toggle = (
    <IonToggle
      style={{
        minWidth: '50px',
      }}
      checked={setting.enabled}
      disabled={setting.uiDisabled}
      onIonChange={async () => {
        setLoading(true);
        // Sometimes the toggle method is entirely asynchronous (e.g., push notification registration), so the parent component needs to control loading state of this component
        const skipSetLoading = await onToggle(setting);
        if (!skipSetLoading) {
          setLoading(false);
        }
      }}
    />
  );

  return (
    <IonRow
      style={{
        textAlign: 'center',
        marginTop: '.5em',
        height: 'auto',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        maxWidth: '500px',
      }}
      className="row-vertical-align"
      onClick={() => {
        if (setting.uiDisabled && onDisabledClick) {
          onDisabledClick(setting);
        }
      }}
    >
      {setting.tooltip ? (
        <Tooltip title={setting.tooltip}>{text}</Tooltip>
      ) : (
        text
      )}
      {loading ? (
        <IonSpinner
          name="crescent"
          style={{
            width: '20px',
            height: '20px',
            opacity: 0.3,
            verticalAlign: 'middle',
            marginRight: '.5em',
          }}
          color="primary"
        />
      ) : setting.uiDisabled && setting.uiDisabledTooltip ? (
        <Tooltip title={setting.uiDisabledTooltip}>
          <div>{toggle}</div>
        </Tooltip>
      ) : (
        toggle
      )}
    </IonRow>
  );
};

export default NotificationOptionRow;
