// cspell:ignore grecaptcha

import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';

import { AllowedPath } from '@faredrop/types';

import { Formik, FormikErrors, FormikTouched } from 'formik';
import { FC, useState, useEffect, useRef } from 'react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import * as Yup from 'yup';
import { default as YupPassword } from 'yup-password';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useGoogleReCaptchaV2AndV3 from '../hooks/useGoogleReCaptchaV2AndV3';
import './../theme/LoginPage.css';
import GoogleRecaptchaNotice from './GoogleRecaptchaNotice';
import PasswordRequirements from './PasswordRequirements';
import useLogError from '../hooks/logError';
import SelectHomeAirportComponent from './SelectHomeAirportComponent';
import { Airport, SupportedCurrencies } from '@faredrop/graphql-sdk';
import useUser from '../hooks/user';
import { useHistory } from 'react-router';
import { userConfigOriginFromIata } from '../utilities/airports';
import { AnyObject } from 'yup/lib/types';
import StringSchema, { RequiredStringSchema } from 'yup/lib/string';

YupPassword(Yup);

export enum UserAlreadyExistsBehavior {
  ATTEMPT_LOGIN = 'ATTEMPT_LOGIN',
  SHOW_ERROR = 'SHOW_ERROR',
}

type GetStartedProps = {
  email?: string;
  firstName?: string;
  lastName?: string;
  loginQueryParams?: string;
  hideLoginLink?: boolean;
  submitButtonText?: string;
  onSubmit: (
    email: string,
    firstName: string,
    lastName: string,
    idCognito?: string,
    promotionCode?: string
  ) => Promise<void>;
  onLoginLinkPress?: () => Promise<void>;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  acceptPromotionCode?: boolean;
  userAlreadyExistsBehavior?: UserAlreadyExistsBehavior;
};

export type SignUpValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  promotionCode?: string;
};

const GetStarted: FC<GetStartedProps> = (props: GetStartedProps) => {
  const { login, signUp, refreshSession } = useAuth();
  const [renderRobotCheck, setRenderRobotCheck] = useState(false);
  const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState<string>();
  const clearedV3RecaptchaError = useRef(false);
  const { isSmallScreenSizeOrSmaller, isApp } = useDevice();
  const { v2Token, executeV3, isV2Rendered, renderV2, widgetId } =
    useGoogleReCaptchaV2AndV3();
  const history = useHistory();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { logError } = useLogError();
  const [tryAgainCount, setTryAgainCount] = useState(0);
  const [selectedAirport, setSelectedAirport] = useState<Airport | undefined>(
    undefined
  );
  const [informationCheckbox, setInformationCheckbox] = useState(false);
  const [informationCheckboxTouched, setInformationCheckboxTouched] =
    useState(false);
  const userState = useUser();

  useEffect(() => {
    // @ts-expect-error grecaptcha is sourced
    if (isV2Rendered && grecaptcha.getResponse().length !== 0) {
      setRecaptchaErrorMessage(undefined);
    }
  }, [isV2Rendered]);

  useEffect(() => {
    try {
      if (renderRobotCheck && !v2Token) {
        renderV2('faredrop-recaptcha');
      }
    } catch (err) {
      setRecaptchaErrorMessage(
        'Uh oh! We have detected that you may be a bot. Please try again.'
      );
    }
  }, [renderRobotCheck]);

  const submitDisabled = (
    values: SignUpValues,
    touched: FormikTouched<SignUpValues>,
    errors: FormikErrors<SignUpValues>
  ) => {
    return (
      values.firstName === '' ||
      values.lastName === '' ||
      values.email === '' ||
      values.password === '' ||
      informationCheckbox === false ||
      Boolean(touched.password && errors.password) ||
      Boolean(touched.email && errors.email) ||
      // @ts-expect-error grecaptcha is sourced
      (isV2Rendered && grecaptcha.getResponse().length === 0) ||
      selectedAirport === undefined ||
      (props.acceptPromotionCode && values.promotionCode === '')
    );
  };

  const trySignUpWithTimeoutThreshold = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    token: string
  ) => {
    return await Promise.race([
      signUp(
        firstName,
        lastName,
        email.toLowerCase(),
        password,
        token,
        tryAgainCount,
        !!v2Token
      ) as Promise<string>,
      new Promise<string>((_, reject) => setTimeout(reject, 15000)),
    ]);
  };

  const signUpWithRecaptchaToken = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    token: string
  ) => {
    try {
      return await trySignUpWithTimeoutThreshold(
        firstName,
        lastName,
        email,
        password,
        token
      );
    } catch (error) {
      const updatedTryAgainCount = tryAgainCount + 1;
      setTryAgainCount(updatedTryAgainCount);
      const newToken = await executeV3(
        tryAgainCount === 0 ? 'signUp' : `signUp${updatedTryAgainCount}`
      );
      if (newToken) {
        return await trySignUpWithTimeoutThreshold(
          firstName,
          lastName,
          email,
          password,
          newToken
        );
      }
      throw error;
    }
  };

  const submitButtonText = props.submitButtonText ?? 'Get Started';
  const initialValues = {
    firstName: props.firstName ?? '',
    lastName: props.lastName ?? '',
    email: props.email ?? '',
    password: '',
    submit: null,
    promotionCode: '',
  };

  const validationSchema: {
    [key: string]:
      | RequiredStringSchema<string | undefined, AnyObject>
      | StringSchema<string | undefined, AnyObject, string | undefined>;
  } = {
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8)
      .minNumbers(1)
      .minLowercase(1)
      .minUppercase(1)
      .minSymbols(1)
      .matches(
        /^[\S]+.*[\S]+$/,
        'Password must not start or end with whitespace'
      ),
    firstName: Yup.string().min(1).required('First name is required'),
    lastName: Yup.string().min(1).required('Last name is required'),
  };

  if (props.acceptPromotionCode) {
    validationSchema.promotionCode = Yup.string().required();
  }

  const userAlreadyExistsBehavior =
    props.userAlreadyExistsBehavior ?? UserAlreadyExistsBehavior.ATTEMPT_LOGIN;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(validationSchema)}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        if (!informationCheckbox) {
          setSubmitting(false);
          setErrors({
            submit: 'Please check the box above to continue.',
          });
        } else if (!selectedAirport) {
          // TODO: Once we have integrated Klaviyo frontend forms, we can take in an email address for
          // origin airport suggestions and change the message to "Please select your home airport using
          // the searchbox above. If you are unable to find your home airport, let us know that we should
          // add one by clicking here"
          setSubmitting(false);
          setErrors({
            submit:
              'Please select your home airport using the searchbox above.',
          });
        } else {
          let success = false;
          try {
            let token = v2Token;
            if (!token) {
              token = await executeV3(
                tryAgainCount === 0 ? 'signUp' : `signUp${tryAgainCount}`
              );
            }

            if (token) {
              if (window.location.pathname === AllowedPath.FREE_TRIAL) {
                const url = new URL(window?.location?.href);
                if (!url.searchParams.has('trial')) {
                  url.searchParams.append('trial', '');
                  window.history.pushState({}, '', url.href);
                }
              }

              const { firstName, lastName, email, password } = values;
              const idCognito = await signUpWithRecaptchaToken(
                firstName,
                lastName,
                email,
                password,
                token
              );

              await login(email, password);

              if (selectedAirport?.iata) {
                await Promise.all([
                  (async () => {
                    const originsConfig = [
                      userConfigOriginFromIata(selectedAirport.iata),
                    ];
                    await userState.setOrigins(originsConfig);
                  })(),
                  (async () => {
                    if (
                      selectedAirport.googleCurrencyCode &&
                      selectedAirport.googleCurrencyCode !==
                        SupportedCurrencies.Usd
                    ) {
                      await userState.setCurrency(
                        selectedAirport.googleCurrencyCode as SupportedCurrencies
                      );
                    }
                  })(),
                ]);
              }

              if (idCognito !== undefined) {
                await props.onSubmit(
                  values.email,
                  values.firstName,
                  values.lastName,
                  idCognito,
                  values.promotionCode
                );

                values.firstName = '';
                values.lastName = '';
                values.email = '';
                values.password = '';
                values.submit = null;
              } else {
                throw new Error('Timeout error');
              }
            }
            props.logAnalyticsEngagement &&
              (await props.logAnalyticsEngagement(
                AnalyticsEngagementId.SIGN_UP
              ));

            success = true;
            setStatus({ success });
            setSelectedAirport(undefined);
            setRecaptchaErrorMessage(undefined);
          } catch (err) {
            // Here we prevent users who accidentally close out of the email verification code page from being locked out of their account.
            // If we detect that the username already exists, then first we attempt to login the user. If the user cannot be logged in because
            // they haven't confirmed their email, then we continue directing them to the verify email page. Otherwise, the user has entered
            // an email for an account that already exists and has been confirmed, so we logout the user and throw the error on the initial
            // sign up screen.
            if (err && (err as Error).name === 'UsernameExistsException') {
              if (
                userAlreadyExistsBehavior ===
                UserAlreadyExistsBehavior.ATTEMPT_LOGIN
              ) {
                // TODO: BUG: Weird error on mobile is sometimes erroring in here
                // for new users. However, we can catch and handle this error by
                // just logging in the user which will direct them to the /plans
                // page. Figure out how we can prevent this and always just
                // show the submit error
                try {
                  await login(values.email, values.password);
                  await refreshSession();
                  const searchParams = new URLSearchParams(
                    history.location.search
                  );
                  goWithStickyParamsLocation({
                    pathname: AllowedPath.DEALS,
                    search: `?${searchParams.toString()}`,
                  });
                } catch (error) {
                  setErrors({
                    submit:
                      'An account already exists with that email. Please login instead.',
                  });
                }
              } else if (
                userAlreadyExistsBehavior ===
                UserAlreadyExistsBehavior.SHOW_ERROR
              ) {
                setErrors({
                  submit:
                    'An account already exists with that email. Please login instead.',
                });
              }
              setStatus({ success: true });
            } else {
              // If we get an error on the first try, let's prompt the user to try again. Most errors are
              // resolved on the second attempt (reCaptcha is funky sometimes...)
              // Note: tryAgainCount > 2 will trigger the "contact us" message to appear
              if (tryAgainCount > 2) {
                setErrors({
                  submit:
                    tryAgainCount <= 4
                      ? 'Sorry about that! Something happened on our end.\nPress "Get Started" once more to get started with Faredrop!'
                      : "Hmmm. We're having some trouble processing your request...",
                });
              } else {
                setErrors({
                  submit:
                    err && (err as { message?: string }).message
                      ? (err as { message: string }).message
                      : 'Error: Please try again',
                });
              }
            }
            setStatus({ success: false });
            setSubmitting(false);
            setTryAgainCount(tryAgainCount + 1);

            // Skip email already exists errors
            if (
              (err as Error)?.message !==
              'An account with the given email already exists.'
            ) {
              await logError(
                'Error on sign-up',
                err && (err as Error).message
                  ? (err as Error).message
                  : 'Error or error message is undefined'
              );
            }
          }
          setSubmitting(false);

          if (!success && !isApp) {
            setRenderRobotCheck(true);
            // @ts-expect-error grecaptcha is sourced
            grecaptcha.reset(widgetId);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        resetForm,
        touched,
        values,
      }): JSX.Element => {
        const errorMessage = recaptchaErrorMessage ?? errors.submit;
        const accountAlreadyExists =
          errors.submit === 'An account with the given email already exists.';
        const emailSubject = encodeURIComponent('SignUp Trouble');
        const emailBody = encodeURIComponent(
          `Hi, I am having trouble signing up for FareDrop. Could you assist me? My name is ${values.firstName} ${values.lastName} and the error I received is: "${errorMessage}"`
        );
        const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

        // If ReCaptcha v3 fails, render v2 checkbox
        if (recaptchaErrorMessage && !renderRobotCheck) {
          setRenderRobotCheck(true);
        }

        // Clear ReCaptcha v3 error once v2 checkbox succeeds
        if (
          v2Token &&
          recaptchaErrorMessage &&
          !clearedV3RecaptchaError.current
        ) {
          clearedV3RecaptchaError.current = true;
          setRecaptchaErrorMessage(undefined);
        }

        return (
          <form noValidate onSubmit={handleSubmit}>
            <IonRow>
              <IonCol
                sizeXs="12"
                sizeXl="6"
                style={{
                  paddingLeft: 0,
                  paddingBottom: 0,
                  paddingRight: isSmallScreenSizeOrSmaller ? 0 : 'auto',
                }}
              >
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  fullWidth
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="name"
                  value={values.firstName}
                  variant="outlined"
                  required
                />
              </IonCol>
              <IonCol
                sizeXs="12"
                sizeXl="6"
                style={{
                  paddingRight: 0,
                  paddingBottom: 0,
                  paddingLeft: isSmallScreenSizeOrSmaller ? 0 : 'auto',
                }}
              >
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  fullWidth
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="name"
                  value={values.lastName}
                  variant="outlined"
                  required
                />
              </IonCol>
            </IonRow>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              placeholder="user@gmail.com"
              value={values.email}
              variant="outlined"
              required
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              placeholder="************"
              variant="outlined"
              required
            />
            <PasswordRequirements password={values.password} />
            {props.acceptPromotionCode && (
              <IonRow>
                <TextField
                  error={Boolean(touched.promotionCode && errors.promotionCode)}
                  fullWidth
                  helperText={touched.promotionCode && errors.promotionCode}
                  label="Promotion Code"
                  margin="normal"
                  name="promotionCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.promotionCode}
                  variant="outlined"
                  required
                />
              </IonRow>
            )}
            <div style={{ marginTop: '1em', marginBottom: '1.5em' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={informationCheckbox}
                    onBlur={handleBlur}
                    onChange={() => {
                      setInformationCheckbox(!informationCheckbox);
                      setInformationCheckboxTouched(true);

                      if (informationCheckbox) {
                        errors.submit = undefined;
                      }
                    }}
                    sx={{
                      color: 'var(--ion-color-gray)',
                      '&.Mui-checked': {
                        color: 'var(--ion-color-primary)',
                      },
                    }}
                  />
                }
                label={
                  <small>
                    FareDrop can use my information to get in touch with me. I
                    also agree to receive to receive{' '}
                    <a
                      href={process.env.REACT_APP_DAILY_DROP_REFERRAL_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Daily Drop
                    </a>
                    , FareDrop&apos;s free miles and points newsletter which I
                    can unsubscribe from at any time.
                  </small>
                }
                sx={{ color: 'gray' }}
              />
              {!informationCheckbox && informationCheckboxTouched && (
                <p
                  style={{
                    marginTop: '2em',
                    textAlign: 'center',
                    width: '100%',
                    color: 'var(--ion-color-danger)',
                  }}
                >
                  <small>
                    Please check the checkbox above to complete your sign-up.
                  </small>
                </p>
              )}
            </div>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div id="faredrop-recaptcha" />
            </IonRow>
            <SelectHomeAirportComponent
              selectedAirport={selectedAirport}
              setSelectedAirport={(airport: Airport | undefined) => {
                setSelectedAirport(airport);
                if (airport) {
                  errors.submit = undefined;
                }
              }}
              dropdownSx={{
                maxWidth: '', // Clear the default maxWidth param
              }}
            />
            {errorMessage && (
              <IonRow
                style={{
                  marginBottom: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <FormHelperText error style={{ textAlign: 'center' }}>
                  {errorMessage}
                  {accountAlreadyExists && (
                    <a
                      style={{
                        fontWeight: 'bold',
                        color: 'darkred',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() =>
                        goWithStickyParamsLocation({
                          pathname: '/login',
                          search:
                            values.email != ''
                              ? `?email=${encodeURIComponent(values.email)}`
                              : undefined,
                        })
                      }
                    >
                      {' '}
                      Login here
                    </a>
                  )}
                </FormHelperText>
                {accountAlreadyExists && (
                  <FormHelperText error>
                    Forgot your password?{' '}
                    <a
                      style={{ fontWeight: 'bold', color: 'darkred' }}
                      onClick={() =>
                        goWithStickyParamsLocation({
                          pathname: '/password-recovery',
                          search:
                            values.email != ''
                              ? `?email=${encodeURIComponent(values.email)}`
                              : undefined,
                        })
                      }
                    >
                      Reset here
                    </a>
                  </FormHelperText>
                )}
                {(!recaptchaErrorMessage ||
                  (!!recaptchaErrorMessage && !!v2Token)) &&
                  tryAgainCount > 2 && (
                    <FormHelperText error>
                      Having trouble?{' '}
                      <a
                        style={{ fontWeight: 'bold', color: 'darkred' }}
                        href={errorMailTo}
                      >
                        Click here
                      </a>{' '}
                      for assistance.
                    </FormHelperText>
                  )}
              </IonRow>
            )}
            <IonRow>
              <button
                className="button no-caps submit-button"
                disabled={isSubmitting}
                style={{
                  opacity:
                    submitDisabled(values, touched, errors) ||
                    (!!recaptchaErrorMessage && !isApp)
                      ? 0.5
                      : 1.0,
                }}
                type="submit"
              >
                {(isSubmitting && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IonSpinner
                      style={{ margin: 'auto 0' }}
                      color="white"
                      name="crescent"
                    />
                    <p style={{ margin: 'auto 0 auto 10px' }}>
                      Processing . . .
                    </p>
                  </div>
                )) || <p>{submitButtonText}</p>}
              </button>
            </IonRow>
            {!props.hideLoginLink && (
              <IonRow
                style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                  justifyContent: 'center',
                }}
              >
                <a
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    lineHeight: 2,
                  }}
                  onClick={async () => {
                    resetForm({ values: initialValues });
                    setRecaptchaErrorMessage(undefined);
                    setSubmitting(false);
                    setSelectedAirport(undefined);

                    if (props.onLoginLinkPress) {
                      await props.onLoginLinkPress();
                    } else {
                      // Default behavior should route to login page
                      let search: string | undefined = props.loginQueryParams;
                      if (values.email) {
                        const params = new URLSearchParams(
                          props.loginQueryParams
                        );
                        if (!params.has('email')) {
                          // Email is not a sticky param since it is PII
                          params.append('email', values.email);
                        }

                        search = `?${params.toString()}`;
                      }
                      goWithStickyParamsLocation({
                        pathname: '/login',
                        search,
                      });
                    }
                    props.logAnalyticsEngagement &&
                      (await props.logAnalyticsEngagement(
                        AnalyticsEngagementId.LOGIN_LINK
                      ));
                  }}
                >
                  <span
                    style={{
                      color: '#696969',
                      fontFamily: 'nexa',
                    }}
                  >
                    Already have a FareDrop account?
                  </span>{' '}
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      fontFamily: 'nexa-bold',
                    }}
                  >
                    Login here
                  </span>
                </a>
              </IonRow>
            )}
            <GoogleRecaptchaNotice />
          </form>
        );
      }}
    </Formik>
  );
};

export default GetStarted;
