import { createContext, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import {
  DeliveredNotifications,
  PushNotifications,
  PushNotificationSchema,
  RegistrationError,
} from '@capacitor/push-notifications';
import { PermissionState } from '@capacitor/core';
import { useLocation } from 'react-router-dom';
import { Badge } from '@capawesome/capacitor-badge'; // cspell:disable-line

import {
  AirfareProvider as AirfareProviderGQL,
  Deal,
  DealAttribute,
  DealStatus,
  SeatClass,
  SupportedCurrencies,
} from '@faredrop/graphql-sdk';
import {
  AirportRegion,
  FareDropRole,
  FlightDesignation,
  AirfareProvider,
  AllowedPath,
} from '@faredrop/types';
import {
  getFlightDesignationFromProvider,
  isAuthorized,
} from '@faredrop/utilities';

import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useAppState from '../hooks/useAppState';
import useUserDeals from '../hooks/userDeals';
import useUser from '../hooks/user';
import { isMatch } from '../utilities/deals-utilities';
import useAnalytics from '../hooks/analytics';
import usePresentToast from '../hooks/presentToast';
import { IDestinationDeals } from '../components/DealCard';
import StorageService from '../services/StorageService';
import { usePrevious } from '../hooks/previous';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDealsDashboard } from '../hooks/useDealsDashboard';
import { DealsDashboardList } from './dealsDashboardContext';
import { $enum } from 'ts-enum-util';

export interface PushNotificationContextValue {
  unreadNotifications: number;
  registerAndListen: () => Promise<void>;
  removeNotification: (deals: IDestinationDeals) => Promise<void>;
  disablePushNotifications: () => Promise<void>;
  togglePushNotifications: (
    enabled: boolean
  ) => Promise<PermissionState | undefined>;
}

// Since we set the PushNotificationProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const PushNotificationContext = createContext<PushNotificationContextValue>({
  unreadNotifications: 0,
  registerAndListen: async () => undefined,
  removeNotification: async () => undefined,
  disablePushNotifications: async () => undefined,
  togglePushNotifications: async () => undefined,
});

interface PushNotificationProps {
  children: ReactNode;
}

const parseDeal = (data: { [key: string]: string }) => {
  const deal: Deal = {
    idAirfareSource: data.idAirfareSource,
    provider: data.provider.toUpperCase() as AirfareProviderGQL,
    status: DealStatus.Sent,
    originIATA: data.originIATA,
    destinationIATA: data.destinationIATA,
    destinationRegion: data.destinationRegion.toUpperCase() as AirportRegion,
    seatClass: data.seatClass.toUpperCase() as SeatClass,
    minPrice: parseInt(data.minPrice),
    historicalMeanPrice: parseInt(data.historicalMeanPrice),
    dda: data.dda,
    attributes: data.attributes
      ? (JSON.parse(data.attributes) as DealAttribute[])
      : undefined,
    airfares: JSON.parse(data.airfares),
    daysSincePrice: parseInt(data.daysSincePrice),
    note: data.note,
    currency: data.currency
      ? (data.currency as SupportedCurrencies)
      : undefined,
  };

  return deal;
};

export const PushNotificationProvider: FC<PushNotificationProps> = (props) => {
  const isRegistered = useRef(false);
  const isRegistering = useRef(false);
  const forcedRegisterForUser = useRef<string>();
  const { user } = useAuth();
  const { isApp, isIOS, isAndroid } = useDevice();
  const { presentError } = usePresentToast();
  const location = useLocation();
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const { activeTrigger } = useAppState();
  const {
    putThisDevice,
    removeThisDevice,
    isThisDeviceRegisteredForPushNotifications,
    isInitializing: isUserInitializing,
  } = useUser();
  const economyDealsState = useUserDeals(SeatClass.Economy);
  const businessDealsState = useUserDeals(SeatClass.Business);
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const removeDealsQueue = useRef<Deal[]>([]); // It's possible for our app logic to try and remove notifications before push notifications are registered. So they need to be queued until registration
  const {
    logAnalyticsError,
    logAnalyticsPushNotificationActionPath,
    logAnalyticsPushNotificationActionURL,
    logAnalyticsPushNotificationActionDeal,
  } = useAnalytics();
  const { presentWarning } = usePresentToast();
  const { setLastViewedDealIndex, setLastViewedDealList } = useDealsDashboard();
  const previousUserId = usePrevious(user?.id);

  useEffect(() => {
    const registerIfGranted = async () => {
      // If push notification permissions are granted, register push notification token with FareDrop API
      const permission = (await PushNotifications.checkPermissions()).receive;
      if (permission === 'granted') {
        let force = false;
        if (
          !!user?.id &&
          !!previousUserId &&
          user.id !== previousUserId &&
          user.id !== forcedRegisterForUser.current
        ) {
          forcedRegisterForUser.current = user.id;
          force = true;
        }

        // We don't always want to register and listen when the user changes (app open or sign in from different user)
        // For example, when push notifications are turned off for this device
        if (force || isThisDeviceRegisteredForPushNotifications) {
          await registerAndListen(force);
        }
      }
    };

    if (
      isApp &&
      user &&
      !isUserInitializing &&
      isAuthorized(user, [FareDropRole.Profile])
    ) {
      registerIfGranted().catch(() => showRegistrationFailedToast());
    } else {
      isRegistering.current = false;
      isRegistered.current = false;
    }
  }, [user?.id, isUserInitializing]);

  useEffect(() => {
    if (
      !economyDealsState.isInitializing &&
      !economyDealsState.isLoading &&
      !businessDealsState.isInitializing &&
      !businessDealsState.isLoading
    ) {
      updatePushNotificationState().catch((error) =>
        console.warn('Failed to update push notification state', error)
      );
    }
  }, [
    isRegistered.current,
    location.pathname,
    activeTrigger,
    economyDealsState.isInitializing,
    economyDealsState.isLoading,
    businessDealsState.isInitializing,
    businessDealsState.isLoading,
  ]);

  const updateUnreadNotifications = async (notificationCount?: number) => {
    const count =
      notificationCount ??
      (await PushNotifications.getDeliveredNotifications()).notifications
        .length;
    setUnreadNotifications(count);
    await Badge.set({ count });
  };

  const updatePushNotificationState = async () => {
    try {
      if (isRegistered.current) {
        if (isIOS) {
          await removeQueuedNotifications();
          await removeOldNotifications();
          await updateUnreadNotifications();
        }
        // Android is lame once again...
        // First, push notification data objects are not stored in the notification tray. So, the push body text will have to be used to match the deals
        // Second, Capacitor PushNotifications.removeDeliveredNotifications doesn't work... notifications were retrieved, filtered, and passed to function to no avail. Works on iOS
        // For the short term, we will clear all Android notifications when user is on deals screen
        else if (isAndroid) {
          if (location.pathname === AllowedPath.DEALS) {
            await clearNotifications();
          } else if (unreadNotifications === 0) {
            await updateUnreadNotifications();
          }
        }
      }
    } catch (error) {
      await logAnalyticsError('updatePushNotificationState');
      throw error;
    }
  };

  const showRegistrationFailedToast = () => {
    presentError(
      'Failed to register for push notifications. Please refresh the app. If the issue persists, contact team@faredrop.com'
    );
  };

  const registerAndListen = async (force = false) => {
    try {
      if (!isRegistering.current && (!isRegistered.current || force)) {
        isRegistering.current = true;
        await PushNotifications.removeAllListeners(); // Cleanup listeners before adding new ones
        await Promise.all([
          PushNotifications.addListener('registration', async (data) => {
            try {
              await putThisDevice(data.value);
            } catch (error) {
              console.warn('Failed to add device to set of user devices');
              console.warn(error);
              showRegistrationFailedToast();
              await logAnalyticsError('addUserDevice', error as Error);
            }

            isRegistered.current = true;
            isRegistering.current = false;
          }),
          PushNotifications.addListener(
            'registrationError',
            async (error: RegistrationError) => {
              console.warn('Push notification registration failed', error);
              showRegistrationFailedToast();
              await logAnalyticsError(
                'pushNotificationRegistration',
                new Error(error.error)
              );
            }
          ),
          PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification) => {
              try {
                if (notification?.data?.idAirfareSource) {
                  const deal = parseDeal(notification.data);
                  if (deal.seatClass.toUpperCase() === SeatClass.Economy) {
                    await economyDealsState.addDeal(deal);
                  } else {
                    await businessDealsState.addDeal(deal);
                  }
                  let badgeCount: number | undefined;
                  if (isAndroid) {
                    badgeCount =
                      (await PushNotifications.getDeliveredNotifications())
                        .notifications.length + 1;
                  }
                  await updateUnreadNotifications(badgeCount);
                }
              } catch (error) {
                await logAnalyticsError(
                  'pushNotificationReceived',
                  error as Error
                );
              }
            }
          ),
          PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (action) => {
              try {
                if (action?.notification?.data?.pathname) {
                  goWithStickyParamsPath(action.notification.data);
                  await logAnalyticsPushNotificationActionPath(
                    action.notification.data.pathname
                  );
                } else if (action?.notification?.data?.url) {
                  window.open(
                    action.notification.data.url,
                    '_system',
                    'location=yes'
                  );
                  await logAnalyticsPushNotificationActionURL(
                    action.notification.data.url
                  );
                } else if (action?.notification?.data?.idAirfareSource) {
                  const deal = parseDeal(action.notification.data);

                  let list: DealsDashboardList | undefined = undefined;
                  if (deal.seatClass.toUpperCase() === SeatClass.Economy) {
                    list =
                      getFlightDesignationFromProvider(
                        $enum(AirfareProvider).asValueOrThrow(deal.provider)
                      ) === FlightDesignation.DOMESTIC
                        ? DealsDashboardList.DOMESTIC_ECONOMY
                        : DealsDashboardList.INTERNATIONAL_ECONOMY;
                    await economyDealsState.addDeal(deal);
                  } else {
                    list = DealsDashboardList.INTERNATIONAL_BUSINESS;
                    await businessDealsState.addDeal(deal);
                  }

                  setLastViewedDealIndex(0);
                  setLastViewedDealList(list);

                  goWithStickyParamsLocation({
                    pathname: AllowedPath.DEAL_DETAILS,
                    search: `?dda=${deal.dda}`,
                  });
                  await logAnalyticsPushNotificationActionDeal(deal);
                }
              } catch (error) {
                await logAnalyticsError(
                  'pushNotificationActionPerformed',
                  error as Error
                );
              }
            }
          ),
        ]);
        await PushNotifications.register();
      }
    } catch (error) {
      await logAnalyticsError(
        'registerAndListenForPushNotifications',
        error as Error
      );
      throw error;
    }
  };

  const removeNotification = async (destinationDeals: IDestinationDeals) => {
    try {
      if (isApp && isIOS && isRegistered.current) {
        const deliveredNotifications =
          await PushNotifications.getDeliveredNotifications();

        const toRemove: PushNotificationSchema[] = [];

        // First check if the active deal is in the delivered notifications
        const deliveredNotificationFromActiveDeal =
          deliveredNotifications.notifications.find((notification) => {
            const notificationDeal = parseDeal(notification.data);
            return isMatch(notificationDeal, destinationDeals.activeDeal);
          });
        if (deliveredNotificationFromActiveDeal) {
          toRemove.push(deliveredNotificationFromActiveDeal);
        }

        // Next check if the other deals are included in the delivered notifications
        destinationDeals.deals.forEach((deal) => {
          const deliveredNotification =
            deliveredNotifications.notifications.find((notification) => {
              const notificationDeal = parseDeal(notification.data);
              return isMatch(notificationDeal, deal);
            });
          if (deliveredNotification) {
            toRemove.push(deliveredNotification);
          }
        });

        if (toRemove.length > 0) {
          await PushNotifications.removeDeliveredNotifications({
            notifications: toRemove,
          });
          await updateUnreadNotifications();
        }
      } else {
        removeDealsQueue.current?.push(
          destinationDeals.activeDeal,
          ...destinationDeals.deals
        );
      }
    } catch (error) {
      await logAnalyticsError('removeNotification', error as Error);
      throw error;
    }
  };

  const removeQueuedNotifications = async () => {
    try {
      if (
        isApp &&
        isIOS &&
        removeDealsQueue.current &&
        removeDealsQueue.current.length > 0
      ) {
        const toRemove: DeliveredNotifications = { notifications: [] };
        const deliveredNotifications =
          await PushNotifications.getDeliveredNotifications();
        removeDealsQueue.current.forEach((deal) => {
          const notification = deliveredNotifications.notifications.find(
            (notification) => {
              const notificationDeal = parseDeal(notification.data);
              return isMatch(notificationDeal, deal);
            }
          );
          if (notification) {
            toRemove.notifications.push(notification);
          }
        });
        await PushNotifications.removeDeliveredNotifications(toRemove);
        await updateUnreadNotifications();
        removeDealsQueue.current = [];
      }
    } catch (error) {
      await logAnalyticsError('removeQueuedNotifications', error as Error);
      throw error;
    }
  };

  const removeOldNotifications = async () => {
    try {
      if (isApp && isIOS) {
        const toRemove: DeliveredNotifications = { notifications: [] };
        const deliveredNotifications =
          await PushNotifications.getDeliveredNotifications();
        await Promise.all(
          deliveredNotifications.notifications.map(async (notification) => {
            const deal = parseDeal(notification.data);
            const dealsToSearch =
              deal.seatClass.toUpperCase() === SeatClass.Economy
                ? economyDealsState.deals
                : businessDealsState.deals;
            if (dealsToSearch) {
              const index = dealsToSearch.findIndex((d) => isMatch(d, deal));
              if (index < 0) {
                toRemove.notifications.push(notification);
              }
            }
            await PushNotifications.removeDeliveredNotifications(toRemove);
            await updateUnreadNotifications();
          })
        );
      }
    } catch (error) {
      await logAnalyticsError('removeOldNotifications', error as Error);
      throw error;
    }
  };

  const clearNotifications = async () => {
    try {
      if (isApp) {
        await updateUnreadNotifications(0);
        await PushNotifications.removeAllDeliveredNotifications();
      }
    } catch (error) {
      await logAnalyticsError('clearNotifications', error as Error);
      throw error;
    }
  };

  const disablePushNotifications = async () => {
    try {
      if (isApp) {
        await Promise.all([
          removeThisDevice(),
          PushNotifications.removeAllListeners(),
          clearNotifications(),
        ]);
      }
    } catch (error) {
      await logAnalyticsError('disablePushNotifications', error as Error);
      throw error;
    }
  };

  const showPushNotificationsDeniedWarning = () => {
    presentWarning(
      'You have rejected push notification permission - to enable push notifications for FareDrop, please go into System Preferences'
    );
  };

  const togglePushNotifications = async (enabled: boolean) => {
    if (isApp) {
      if (enabled) {
        let status = await PushNotifications.checkPermissions();
        if (
          status.receive === 'prompt' ||
          status.receive === 'prompt-with-rationale'
        ) {
          status = await PushNotifications.requestPermissions();
        }
        if (status.receive === 'granted') {
          await registerAndListen(true);
        } else if (status.receive !== 'prompt') {
          showPushNotificationsDeniedWarning();
        }

        if (status.receive !== 'prompt') {
          const usersThatHaveSetPermissions =
            (await StorageService.getInstance().getData(
              'usersThatHaveSetPermissions'
            )) ?? [];
          usersThatHaveSetPermissions.push(user?.email);
          await StorageService.getInstance().setData(
            'usersThatHaveSetPermissions',
            usersThatHaveSetPermissions
          );
        }
        return status.receive;
      } else {
        await disablePushNotifications();
      }
    }

    return undefined;
  };

  return (
    <PushNotificationContext.Provider
      value={{
        unreadNotifications,
        registerAndListen,
        removeNotification,
        disablePushNotifications,
        togglePushNotifications,
      }}
    >
      {props.children}
    </PushNotificationContext.Provider>
  );
};

export default PushNotificationContext;
