import { IonRow } from '@ionic/react';
import { useDevice } from '../hooks/useDevice';
import Modal from './MobileModal';

const NoInternetConnectionModal: React.FC = () => {
  const { isSmallScreenSizeOrSmaller } = useDevice();

  return (
    <Modal
      active={true}
      size={isSmallScreenSizeOrSmaller ? '12' : '6'}
      darkBg={true}
      showAsFullPage={isSmallScreenSizeOrSmaller}
      hideCloseIcon={true}
      sx={{ padding: '1em' }}
    >
      <IonRow
        style={{
          width: '100%',
          padding: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IonRow style={{ padding: 15 }}>
          <div>Failed to Connect to FareDrop</div>
        </IonRow>
        <IonRow style={{ padding: 15 }}>
          <button
            style={{
              height: '3em',
              backgroundColor: 'var(--ion-color-primary)',
              color: 'white',
              borderRadius: '7px',
              minWidth: '10em',
            }}
            onClick={() => location.reload()}
          >
            Tap to Refresh
          </button>
        </IonRow>
      </IonRow>
    </Modal>
  );
};

export default NoInternetConnectionModal;
