import { IonIcon, IonRow } from '@ionic/react';
import Wifi from '@material-ui/icons/Wifi';
import Usb from '@material-ui/icons/Usb';
import Power from '@material-ui/icons/Power';
import AirlineSeatLegroomReduced from '@material-ui/icons/AirlineSeatLegroomReduced';
import AirlineSeatLegroomExtra from '@material-ui/icons/AirlineSeatLegroomExtra';
import OnDemandVideo from '@material-ui/icons/OndemandVideo'; // cspell:disable-line
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import BackpackIcon from '@material-ui/icons/Backpack';
import LuggageIcon from '@material-ui/icons/Luggage';
import { alertCircleOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useRef } from 'react';
import { $enum } from 'ts-enum-util';

import {
  FlightSegment,
  SeatClass,
  VerifiedAirfareAttributes,
} from '@faredrop/graphql-sdk';
import { FlightDirection } from '@faredrop/types';
import { isProductionEnvironment } from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import usePresentToast from '../../hooks/presentToast';
import { useDevice } from '../../hooks/useDevice';

interface ContainerProps {
  seatClass?: SeatClass;
  segment?: FlightSegment;
  baggageDetails?: string[];
  flightDirection?: FlightDirection;
}

const BaseIconStyle = {
  fontSize: '16px',
  color: 'gray',
};

enum FlightDirectionBaggageSeparator {
  RETURN = 'returning flight',
  DEPART = 'departing flight',
}

enum BaggageDescriptor {
  CHECKED = 'checked',
  CARRY_ON = 'carry-on',
}

const getAttributeIcon = (name: VerifiedAirfareAttributes) => {
  switch (name) {
    case VerifiedAirfareAttributes.Wifi: {
      return <Wifi style={BaseIconStyle} />;
    }
    case VerifiedAirfareAttributes.AboveAverageLegRoom: {
      return <AirlineSeatLegroomExtra style={BaseIconStyle} />;
    }
    case VerifiedAirfareAttributes.BelowAverageLegRoom: {
      return (
        <AirlineSeatLegroomReduced
          style={{ ...BaseIconStyle, color: '#FFC251' }}
        />
      );
    }
    case VerifiedAirfareAttributes.InSeatPower: {
      return <Power style={BaseIconStyle} />;
    }
    case VerifiedAirfareAttributes.InSeatUsbOutlet: {
      return <Usb style={BaseIconStyle} />;
    }
    case VerifiedAirfareAttributes.OnDemandVideo: {
      return <OnDemandVideo style={BaseIconStyle} />;
    }
    case VerifiedAirfareAttributes.StreamMediaToDevice: {
      return <MobileScreenShareIcon style={BaseIconStyle} />;
    }
  }

  return <></>;
};

const isKnownBaggageDescriptor = (
  baggageDetail: string,
  descriptor?: BaggageDescriptor
) => {
  if (descriptor) {
    return baggageDetail.toLowerCase().includes(descriptor);
  } else {
    return (
      baggageDetail.toLowerCase().includes(BaggageDescriptor.CARRY_ON) ||
      baggageDetail.toLowerCase().includes(BaggageDescriptor.CHECKED)
    );
  }
};

const getBaggageIcon = (baggage: string) => {
  if (isKnownBaggageDescriptor(baggage, BaggageDescriptor.CARRY_ON)) {
    return <BackpackIcon style={BaseIconStyle} />;
  } else if (isKnownBaggageDescriptor(baggage, BaggageDescriptor.CHECKED)) {
    return <LuggageIcon style={BaseIconStyle} />;
  } else {
    return undefined;
  }
};

const detailIsFlightDirectionSeparator = (
  baggageDetail: string,
  separator?: FlightDirectionBaggageSeparator
) => {
  if (separator) {
    return baggageDetail.toLowerCase().includes(separator);
  } else {
    return (
      baggageDetail
        .toLowerCase()
        .includes(FlightDirectionBaggageSeparator.DEPART) ||
      baggageDetail
        .toLowerCase()
        .includes(FlightDirectionBaggageSeparator.RETURN)
    );
  }
};

interface IBaggageDetailType {
  checked?: string;
  carryOn?: string;
}

const DealDetailsAircraftAndBaggageDetails: React.FC<ContainerProps> = ({
  seatClass,
  segment,
  baggageDetails,
  flightDirection,
}) => {
  const { presentError } = usePresentToast();
  const { isExtraSmallScreenSize } = useDevice();
  const missingBaggageDetailToasts = useRef<{
    [baggageDetail: string]: boolean;
  }>({});

  // NOTE:
  // Google Flights sometimes provides multiple strings for the same baggage descriptor
  // From the scenarios I have seen, the second makes the most sense (less wordy), so if we detect a duplicate, overwrite the entry instead of just adding it
  const departingBaggageDetails: IBaggageDetailType = {};
  const returningBaggageDetails: IBaggageDetailType = {};

  if (baggageDetails) {
    let departingRoute = true;
    for (const detail of baggageDetails) {
      if (
        detailIsFlightDirectionSeparator(
          detail,
          FlightDirectionBaggageSeparator.RETURN
        )
      ) {
        departingRoute = false;
        continue;
      }

      if (getBaggageIcon(detail)) {
        if (departingRoute) {
          if (isKnownBaggageDescriptor(detail, BaggageDescriptor.CHECKED)) {
            departingBaggageDetails.checked = detail;
          } else if (
            isKnownBaggageDescriptor(detail, BaggageDescriptor.CARRY_ON)
          ) {
            departingBaggageDetails.carryOn = detail;
          }
        } else {
          if (isKnownBaggageDescriptor(detail, BaggageDescriptor.CHECKED)) {
            returningBaggageDetails.checked = detail;
          } else if (
            isKnownBaggageDescriptor(detail, BaggageDescriptor.CARRY_ON)
          ) {
            returningBaggageDetails.carryOn = detail;
          }
        }
      } else {
        if (
          !isProductionEnvironment() &&
          !detailIsFlightDirectionSeparator(detail) &&
          !missingBaggageDetailToasts.current[detail]
        ) {
          presentError(
            `Found unknown baggage detail (should we show this?): ${detail}`
          );
          missingBaggageDetailToasts.current[detail] = true;
        }
      }
    }
  }

  let baggageDetailsTitle = 'Baggage Details';
  if (baggageDetails?.some((d) => detailIsFlightDirectionSeparator(d))) {
    if (flightDirection === FlightDirection.RETURN) {
      baggageDetailsTitle = 'Returning ' + baggageDetailsTitle;
    } else {
      baggageDetailsTitle = 'Departing ' + baggageDetailsTitle;
    }
  }

  const selectedBaggageDetails: string[] = [];
  Object.values(
    flightDirection === FlightDirection.RETURN &&
      baggageDetails?.some((d) =>
        detailIsFlightDirectionSeparator(
          d,
          FlightDirectionBaggageSeparator.RETURN
        )
      )
      ? returningBaggageDetails
      : departingBaggageDetails
  ).forEach((d) => {
    selectedBaggageDetails.push(d);
  });

  return (
    <IonRow
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: isExtraSmallScreenSize ? undefined : '2em',
        paddingTop: isExtraSmallScreenSize ? '1em' : undefined,
        paddingBottom: isExtraSmallScreenSize ? '1em' : undefined,
      }}
    >
      {!!segment?.attributes.length && (
        <IonRow
          style={{
            fontFamily: 'nexa-bold',
            fontSize: '16px',
            lineHeight: '22px',
          }}
        >
          {segment.aircraftType ?? `Aircraft Details`}
        </IonRow>
      )}
      {segment?.attributes
        .filter(
          (a) =>
            (a.name !== VerifiedAirfareAttributes.None &&
              a.name !== VerifiedAirfareAttributes.BudgetAirline &&
              a.name !== VerifiedAirfareAttributes.OneStop &&
              a.name !== VerifiedAirfareAttributes.TwoStops &&
              a.name !== VerifiedAirfareAttributes.LieFlatSeat) ||
            (a.name === VerifiedAirfareAttributes.LieFlatSeat &&
              a.enabled === false)
        )
        .sort((a, b) => {
          if (a.enabled === b.enabled) return 0;
          if (a.name === VerifiedAirfareAttributes.BelowAverageLegRoom) {
            return b.enabled ? 1 : -1;
          }
          if (a.enabled) {
            return -1;
          } else {
            return 1;
          }
        })
        .map((a) => {
          const formattedName = _.startCase(
            a.name.replaceAll('_', ' ').toLowerCase()
          );
          if (a.enabled) {
            return (
              <IonRow
                key={a.name}
                style={{
                  fontFamily: 'nexa',
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
              >
                {getAttributeIcon(
                  $enum(VerifiedAirfareAttributes).asValueOrThrow(a.name)
                )}
                <div
                  className="deal-details-aircraft-or-baggage"
                  style={{ paddingLeft: '5px' }}
                >
                  {formattedName}
                </div>
              </IonRow>
            );
          } else {
            // Set warning icon only for "important" attributes
            if (
              (a.name === VerifiedAirfareAttributes.InSeatPower &&
                !a.enabled &&
                (segment.duration?.hours ?? Number.MAX_SAFE_INTEGER) > 4) ||
              (a.name === VerifiedAirfareAttributes.LieFlatSeat &&
                !a.enabled &&
                seatClass === SeatClass.Business) ||
              (a.name === VerifiedAirfareAttributes.Wifi && !a.enabled)
            ) {
              return (
                <IonRow
                  key={a.name}
                  style={{
                    fontFamily: 'nexa',
                    fontSize: '14px',
                    lineHeight: '20px',
                    display: 'flex',
                  }}
                >
                  <IonIcon
                    icon={alertCircleOutline}
                    color="orange"
                    style={{ fontSize: '16px', paddingRight: '5px' }}
                  />
                  <div className="deal-details-aircraft-or-baggage">
                    No {formattedName}
                  </div>
                </IonRow>
              );
            } else {
              return <div key={a.name}></div>;
            }
          }
        })}
      {selectedBaggageDetails.length > 0 && (
        <IonRow
          style={{
            fontFamily: 'nexa-bold',
            fontSize: '16px',
            paddingTop: '1em',
            lineHeight: '22px',
          }}
        >
          {baggageDetailsTitle}
        </IonRow>
      )}
      {selectedBaggageDetails?.map((description) => {
        return (
          <IonRow
            key={description}
            style={{ fontFamily: 'nexa', fontSize: '14px', lineHeight: '20px' }}
          >
            {getBaggageIcon(description)}
            <div
              className="deal-details-aircraft-or-baggage"
              style={{ paddingLeft: '5px' }}
            >
              {description}
            </div>
          </IonRow>
        );
      })}
    </IonRow>
  );
};

export default DealDetailsAircraftAndBaggageDetails;
