import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import { useEffect } from 'react';
import moment from 'moment';

import { AllowedPath } from '@faredrop/types';

import useTabs from '../hooks/useTabs';
import { Tab } from '../contexts/tabContext';
import useDailyDropContent from '../hooks/useDailyDropContent';
import useUser from '../hooks/user';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import SettingsBackground from '../components/SettingsBackground';
import { useDevice } from '../hooks/useDevice';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import './../theme/util.css';

const DailyDrop: React.FC = () => {
  const { updateSelectedTab } = useTabs();
  const { dailyDropContent, isInitializing } = useDailyDropContent();
  const userState = useUser();
  const { isApp } = useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const noInternet = userState.timeout;

  useEffect(() => {
    if (!isApp) {
      window.location.href = `${process.env.REACT_APP_DAILY_DROP_REFERRAL_URL}`;
    } else {
      updateSelectedTab(Tab.DAILY_DROP).catch((error) => {
        console.warn('Failed to update selected tab', Tab.SETTINGS, error);
        throw error; // Show uh-oh screen
      });
    }
  }, []);

  return (
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        <IonContent>
          <SettingsBackground hideBlueImage={true} />
          <IonRow
            className="title-font"
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              paddingTop: '2.5em',
              paddingBottom: '.5em',
            }}
          >
            Daily Drop Newsletters
          </IonRow>
          {isInitializing &&
            [1, 2, 3].map((key) => {
              return (
                <IonRow key={key} style={{ display: 'flex', padding: '1em' }}>
                  <IonCol size="4">
                    <IonSkeletonText
                      animated={true}
                      style={{ height: '65px', borderRadius: '10px' }}
                    />
                  </IonCol>
                  <IonCol
                    size="8"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <IonSkeletonText
                      animated={true}
                      style={{ maxWidth: '200px' }}
                    />
                    <IonSkeletonText
                      animated={true}
                      style={{ maxWidth: '125px' }}
                    />
                  </IonCol>
                </IonRow>
              );
            })}
          {!isInitializing &&
            dailyDropContent?.map((content) => {
              return (
                <IonRow
                  key={content.idContent}
                  style={{ display: 'flex', padding: '1em' }}
                  onClick={() => {
                    goWithStickyParamsPath(
                      `${AllowedPath.DAILY_DROP}/${content.idContent}`
                    );
                  }}
                >
                  <IonCol size="4">
                    <div>
                      <img
                        src={content.thumbnailUrl}
                        style={{ borderRadius: '10px' }}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="8">
                    <div style={{ fontSize: 14, paddingBottom: '5px' }}>
                      {content.title}
                    </div>
                    <div style={{ color: 'gray', fontSize: 12 }}>
                      {moment(content.date).format('MMMM Do YYYY')}
                    </div>
                  </IonCol>
                </IonRow>
              );
            })}
        </IonContent>
      </IonPage>
    )
  );
};

export default DailyDrop;
