import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useAnalytics from '../hooks/analytics';
import { Device, DeviceInfo } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

// NOTE: Small screen size does not include iPad
export const enum ScreenSize {
  SMALL_START = 576,
  MEDIUM_START = 768,
  LARGE_START = 992,
  EXTRA_LARGE_START = 1200,
}

export interface DeviceContextValue {
  isExtraSmallScreenSize: boolean;
  isSmallScreenSizeOrSmaller: boolean;
  isSmallScreenSize: boolean;
  isMediumScreenSizeOrSmaller: boolean;
  isMediumScreenSize: boolean;
  isLargeScreenSizeOrSmaller: boolean;
  isLargeScreenSize: boolean;
  isExtraLargeScreenSize: boolean;
  isScreenSmallerThanSize: (size: number) => boolean;
  isScreenLargerThanSize: (size: number) => boolean;
  isScreenBetweenSizes: (low: number, high: number) => boolean;
  isScreenOutsideSizes: (low: number, high: number) => boolean;
  isApp: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  deviceInfo?: DeviceInfo;
}

// Since we set the DeviceProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const DeviceContext = createContext<DeviceContextValue>({
  isExtraSmallScreenSize: false,
  isSmallScreenSizeOrSmaller: false,
  isSmallScreenSize: false,
  isMediumScreenSizeOrSmaller: false,
  isMediumScreenSize: false,
  isLargeScreenSizeOrSmaller: false,
  isLargeScreenSize: false,
  isExtraLargeScreenSize: false,
  isApp: false,
  isIOS: false,
  isAndroid: false,
  deviceInfo: undefined,
  isScreenSmallerThanSize: () => false,
  isScreenLargerThanSize: () => false,
  isScreenBetweenSizes: () => false,
  isScreenOutsideSizes: () => false,
});

interface DevicePropsProps {
  children: ReactNode;
}

export const DeviceProvider: FC<DevicePropsProps> = (props) => {
  const { width } = useWindowDimensions();
  const { logAnalyticsError } = useAnalytics();

  const [isExtraSmallScreenSize, setIsExtraSmallScreenSize] = useState(
    width < ScreenSize.SMALL_START
  );
  const [isSmallScreenSizeOrSmaller, setIsSmallScreenSizeOrSmaller] = useState(
    width < ScreenSize.MEDIUM_START
  );
  const [isSmallScreenSize, setIsSmallScreenSize] = useState(
    width >= ScreenSize.SMALL_START && width < ScreenSize.MEDIUM_START
  );
  const [isMediumScreenSizeOrSmaller, setIsMediumScreenSizeOrSmaller] =
    useState(width < ScreenSize.LARGE_START);
  const [isMediumScreenSize, setIsMediumScreenSize] = useState(
    width >= ScreenSize.MEDIUM_START && width < ScreenSize.LARGE_START
  );
  const [isLargeScreenSizeOrSmaller, setIsLargeScreenSizeOrSmaller] = useState(
    width < ScreenSize.EXTRA_LARGE_START
  );
  const [isLargeScreenSize, setIsLargeScreenSize] = useState(
    width >= ScreenSize.LARGE_START && width < ScreenSize.EXTRA_LARGE_START
  );
  const [isExtraLargeScreenSize, setIsExtraLargeScreenSize] = useState(
    width >= ScreenSize.EXTRA_LARGE_START
  );

  const [isApp] = useState(Capacitor.isNativePlatform());
  const [isIOS] = useState(Capacitor.getPlatform() === 'ios');
  const [isAndroid] = useState(Capacitor.getPlatform() === 'android');
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();

  useEffect(() => {
    const extraSmallUpdate = width < ScreenSize.SMALL_START;
    if (extraSmallUpdate !== isExtraSmallScreenSize)
      setIsExtraSmallScreenSize(extraSmallUpdate);

    const smallOrSmallerUpdate = width < ScreenSize.MEDIUM_START;
    if (smallOrSmallerUpdate !== isSmallScreenSizeOrSmaller)
      setIsSmallScreenSizeOrSmaller(smallOrSmallerUpdate);

    const smallUpdate =
      width >= ScreenSize.SMALL_START && width < ScreenSize.MEDIUM_START;
    if (smallUpdate !== isSmallScreenSize) setIsSmallScreenSize(smallUpdate);

    const mediumOrSmallerUpdate = width < ScreenSize.LARGE_START;
    if (mediumOrSmallerUpdate !== isMediumScreenSizeOrSmaller)
      setIsMediumScreenSizeOrSmaller(mediumOrSmallerUpdate);

    const mediumUpdate =
      width >= ScreenSize.MEDIUM_START && width < ScreenSize.LARGE_START;
    if (mediumUpdate !== isMediumScreenSize) setIsMediumScreenSize(smallUpdate);

    const largeOrSmallerUpdate = width < ScreenSize.EXTRA_LARGE_START;
    if (largeOrSmallerUpdate !== isLargeScreenSizeOrSmaller)
      setIsLargeScreenSizeOrSmaller(largeOrSmallerUpdate);

    const largeUpdate =
      width >= ScreenSize.LARGE_START && width < ScreenSize.EXTRA_LARGE_START;
    if (largeUpdate !== isLargeScreenSize) setIsLargeScreenSize(largeUpdate);

    const extraLargeUpdate = width >= ScreenSize.EXTRA_LARGE_START;
    if (extraLargeUpdate !== isExtraLargeScreenSize)
      setIsExtraLargeScreenSize(extraLargeUpdate);
  }, [width]);

  useEffect(() => {
    if (isApp) {
      Device.getInfo()
        .then((deviceInfo) => {
          setDeviceInfo(deviceInfo);
        })
        .catch((error) => {
          logAnalyticsError('setDeviceInfo', error as Error).catch(() =>
            console.warn('Failed to set deviceInfo', error)
          );
          throw error; // Show uh-oh screen
        });
    }
  }, []);

  const isScreenSmallerThanSize = (size: number) => {
    return width < size;
  };

  const isScreenLargerThanSize = (size: number) => {
    return width > size;
  };

  // Inclusive on the low size and exclusive on the high size
  const isScreenBetweenSizes = (low: number, high: number) => {
    return width >= low && width < high;
  };

  // Exclusive both ends
  const isScreenOutsideSizes = (low: number, high: number) => {
    return width < low || width > high;
  };

  return (
    <DeviceContext.Provider
      value={{
        isExtraSmallScreenSize,
        isSmallScreenSizeOrSmaller,
        isSmallScreenSize,
        isMediumScreenSizeOrSmaller,
        isMediumScreenSize,
        isLargeScreenSizeOrSmaller,
        isLargeScreenSize,
        isExtraLargeScreenSize,
        isApp,
        isIOS,
        isAndroid,
        deviceInfo,
        isScreenSmallerThanSize,
        isScreenLargerThanSize,
        isScreenBetweenSizes,
        isScreenOutsideSizes,
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};

export default DeviceContext;
