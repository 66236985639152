import {
  FareDropPlan,
  NotificationFilters,
  SeatClass,
  UserConfigNotificationFilter,
} from '@faredrop/graphql-sdk';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import DesktopHeader from '../components/DesktopHeader';
import Dropdown from '../components/Dropdown';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import NotificationOptionRow, {
  NotificationSetting,
} from '../components/NotificationOptionRow';
import SettingsBackground from '../components/SettingsBackground';
import SettingsSideMenu, { MENU_OPTIONS } from '../components/SettingsSideMenu';
import { Tab } from '../contexts/tabContext';
import usePresentToast from '../hooks/presentToast';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PAGES } from '../types/types';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import './../theme/util.css';
import { getPlanName, isLimitedPlan } from '../utilities/plans-utilities';
import LimitedUpgradeCTA from '../components/LimitedUpgradeCTA';
import UpgradeModal from '../components/UpgradeModal';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';

const DealPreferences: React.FC = () => {
  const { isMediumScreenSizeOrSmaller, isExtraLargeScreenSize, isApp } =
    useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { width } = useWindowDimensions();
  const { presentWarning } = usePresentToast();

  const userState = useUser();
  const userConfigNotificationFilters =
    userState.user?.configuration.notificationFilters ?? [];

  const noInternet = userState.timeout;

  const isLimited = isLimitedPlan(userState.user?.billing.idStripePlan);

  const [upgradeMessage, setUpgradeMessage] = useState<string>();

  const toggleNotificationFilter = async (method: NotificationSetting) => {
    let economyFilter = false;
    let businessFilter = false;

    const updatedFilters = userConfigNotificationFilters.map(
      (_method: UserConfigNotificationFilter) => {
        if (method.name === _method.name) {
          if (_method.name === NotificationFilters.Economy)
            economyFilter = !_method.enabled;
          else if (_method.name === NotificationFilters.Business)
            businessFilter = !_method.enabled;
          return {
            name: method.name,
            enabled: method.enabled, // Usually we should negate this, but we have already negated the values for display
          } as UserConfigNotificationFilter;
        } else {
          // If a setting with dependencies is disabled, disable the dependent settings as well
          if (
            (method.enabled &&
              _method.name === NotificationFilters.BudgetAirlines &&
              method.name === NotificationFilters.Economy) ||
            (_method.name ===
              NotificationFilters.NonLieFlatBusinessClassSeats &&
              method.name === NotificationFilters.Business)
          ) {
            return {
              name: _method.name,
              enabled: true,
            };
          }

          // Track if both Economy and Business are disabled to display warning message
          if (_method.name === NotificationFilters.Economy)
            economyFilter = _method.enabled;
          else if (_method.name === NotificationFilters.Business)
            businessFilter = _method.enabled;
          return _method;
        }
      }
    );

    // Display toast if both economy and business deals are being filtered
    if (economyFilter && businessFilter) {
      presentWarning(
        'Warning: You will not receive any flight deal notifications with your current settings. We recommend enabling Economy and/or Business class flight deals.'
      );
    }

    await userState.setNotificationFilters(updatedFilters, true);
  };

  useEffect(() => {
    updateSelectedTab(Tab.SETTINGS).catch((error) => {
      console.warn('Failed to update selected tab', Tab.SETTINGS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  const notificationFilters = buildNotificationFilters(
    userConfigNotificationFilters,
    hasSeatClassAccessGQL(userState.user?.profile.roles, SeatClass.Business),
    isLimited
  );

  return (
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize || !userState.hasPaymentInfo ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={
              !userState.hasPaymentInfo && isMediumScreenSizeOrSmaller
            }
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          <IonRow
            style={{
              marginBottom: '10em',
              padding: !isExtraLargeScreenSize
                ? `${isApp ? '1' : '0'}em 2em 2em 2em`
                : '5em 0em 0em 0em',
            }}
          >
            <IonCol
              sizeXs="12"
              sizeXl="10"
              style={{ margin: 'auto', padding: 0 }}
            >
              <IonRow>
                <h1 className="title-font" style={{ marginTop: '2em' }}>
                  {isExtraLargeScreenSize ? 'Deal Settings' : 'Settings'}
                </h1>
              </IonRow>
              <IonRow
                className={!isExtraLargeScreenSize ? 'row-vertical-align' : ''}
              >
                <SettingsSideMenu
                  activeOption={PAGES.DEAL_PREFERENCES}
                  isVisible={isExtraLargeScreenSize}
                />
                <Dropdown
                  options={MENU_OPTIONS[1].options.map(
                    (option) => option.label
                  )}
                  placeholder={PAGES.DEAL_PREFERENCES}
                  onOptionClick={(option: string) => {
                    goWithStickyParamsPath(option);
                  }}
                  hrefOptions={true}
                  hidden={isExtraLargeScreenSize}
                  width={isExtraLargeScreenSize ? width / 2 : '100%'}
                />
                <IonCol
                  size={isExtraLargeScreenSize ? '6' : '12'}
                  style={{
                    marginLeft: !isExtraLargeScreenSize ? 0 : '5em',
                  }}
                >
                  <IonRow style={{ marginBottom: '1em' }}>
                    <h2>Deal Preferences</h2>
                    <p>
                      Select which type of deals you want to receive. We
                      recommend leaving as many checked as possible since we
                      only send great deals.
                    </p>
                  </IonRow>
                  <LimitedUpgradeCTA
                    isLimitedPlan={isLimited}
                    linkText="Upgrade"
                    message="to update deal preferences."
                    style={{ marginBottom: '2em' }}
                  />
                  <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
                    <IonCol style={{ width: 'initial', flexGrow: 'initial' }}>
                      {notificationFilters.map(
                        (filter: NotificationSetting, i: number) => (
                          <NotificationOptionRow
                            key={i}
                            setting={filter}
                            onToggle={toggleNotificationFilter}
                            onDisabledClick={(filter) => {
                              let message = `Updating ${
                                filter.label
                              } deal preference is only available on the ${getPlanName(
                                FareDropPlan.Pro
                              )} Plan. ${
                                HIDE_MEMBERSHIP_LINKS
                                  ? HIDE_UPGRADE_MESSAGE
                                  : 'Please upgrade plans to update deal preferences.'
                              }`;

                              const economyFilter = notificationFilters.find(
                                (f) => f.name === NotificationFilters.Economy
                              );
                              if (
                                !isLimited &&
                                economyFilter?.enabled === false
                              ) {
                                message = `Updating ${filter.label} deal preference is only available for economy class seats. Please enable economy class seats first.`;
                              }

                              setUpgradeMessage(message);
                            }}
                          />
                        )
                      )}
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          message={upgradeMessage}
          onClose={() => {
            setUpgradeMessage(undefined);
          }}
        />
      </IonPage>
    )
  );
};

const buildNotificationFilters = (
  filters: NotificationSetting[],
  hasAccessToBusiness: boolean,
  isLimitedPlan: boolean
) => {
  const notificationFilters: NotificationSetting[] = [];

  let isBusinessFiltered = false;
  let isEconomyFiltered = false;

  if (isLimitedPlan || hasAccessToBusiness) {
    isBusinessFiltered =
      filters?.find((m) => m.name === NotificationFilters.Business)?.enabled ??
      false;
    isEconomyFiltered =
      filters?.find((m) => m.name === NotificationFilters.Economy)?.enabled ??
      false;

    notificationFilters.unshift(
      ...[
        {
          name: NotificationFilters.Business,
          label: 'Business Class Seats',
          enabled: !isBusinessFiltered,
          tooltip: 'Receive deal notifications for Business class seats.',
          uiDisabled: isLimitedPlan,
          uiDisabledTooltip: isLimitedPlan
            ? 'Upgrade to update deal preferences and filter out business class seats'
            : undefined,
        },
        {
          name: NotificationFilters.NonLieFlatBusinessClassSeats,
          label: 'Non Lie-Flat Business Class Seats',
          enabled: !(
            filters.find(
              (m) => m.name === NotificationFilters.NonLieFlatBusinessClassSeats
            )?.enabled ?? false
          ),
          tooltip:
            'Receive deal notifications for business class flights including flights that do not include lie-flat seats.',
          uiDisabled: isLimitedPlan || isBusinessFiltered,
          uiDisabledTooltip: isLimitedPlan
            ? 'Upgrade to update deal preferences and filter out non lie-flat business class seats'
            : 'Business class deals must be enabled to enable this setting.',
        },
        {
          name: NotificationFilters.Economy,
          label: 'Economy Class Seats',
          enabled: !isEconomyFiltered,
          tooltip: 'Receive deal notifications for economy class seats.',
          uiDisabled: isLimitedPlan,
          uiDisabledTooltip: isLimitedPlan
            ? 'Upgrade to update deal preferences and filter out economy class seats'
            : undefined,
        },
      ]
    );
  }

  notificationFilters.push(
    ...[
      {
        name: NotificationFilters.BudgetAirlines,
        label: 'Budget Airlines',
        enabled: !(
          filters.find((m) => m.name === NotificationFilters.BudgetAirlines)
            ?.enabled ?? false
        ),
        tooltip: 'Receive deal notifications for budget airlines.',
        uiDisabled: isLimitedPlan || isEconomyFiltered,
        uiDisabledTooltip: isLimitedPlan
          ? 'Upgrade to update deal preferences and filter out budget airlines.'
          : 'Economy class deals must be enabled to enable this setting.',
      },
      {
        name: NotificationFilters.OneStopOrMore,
        label: 'Flights with Stops',
        enabled: !(
          filters.find((m) => m.name === NotificationFilters.OneStopOrMore)
            ?.enabled ?? false
        ),
        tooltip: 'Receive deal notifications for flights that have stops.',
        uiDisabled: isLimitedPlan,
        uiDisabledTooltip: isLimitedPlan
          ? 'Upgrade to update deal preferences and filter out flights with stops.'
          : undefined,
      },
    ]
  );

  return notificationFilters;
};

export default DealPreferences;
