import { Box, FormHelperText, TextField } from '@mui/material';
import { Formik } from 'formik';
import { CognitoUser } from 'amazon-cognito-identity-js';

import * as Yup from 'yup';
import { default as YupPassword } from 'yup-password';
YupPassword(Yup);

import useAuth from '../hooks/auth';
import { IonRow } from '@ionic/react';

type ChangePasswordProps = {
  onCancel?: () => void;
  onChangePassword?: () => void;
  user?: CognitoUser;
};

const ChangePassword: React.FC<ChangePasswordProps> = (
  props: ChangePasswordProps
) => {
  const { passwordChange } = useAuth();

  return (
    <Formik
      initialValues={{
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('Password is required')
          .min(8)
          .minNumbers(1)
          .minLowercase(1)
          .minUppercase(1)
          .minSymbols(1)
          .matches(
            /^[\S]+.*[\S]+$/,
            'Password must not start or end with whitespace'
          ),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          if (!props.user)
            throw Error('Missing user reference. Please cancel and try again.');

          await passwordChange(props.user, values.password);
          setStatus({ success: true });
          setSubmitting(false);
          // TODO
          // props.onChangePassword();
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: (err as Error).message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        // isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="New Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            placeholder="************"
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <IonRow>
            <button className="button">Change password</button>
          </IonRow>
        </form>
      )}
    </Formik>
  );
};

export default ChangePassword;
