import { IonChip, IonCol, IonIcon, IonSpinner, IonToggle } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { Airport, UserConfigOrigin } from '@faredrop/graphql-sdk';
import './../theme/DepartureAirportsSettings.css';
import './../theme/util.css';
import { formatSubAirportsString } from '../contexts/airportSearchContext';
import { formatAirportCity } from '../utilities/deals-utilities';
import StarIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDevice } from '../hooks/useDevice';
import { FareDropPlan, UserOriginAttributes } from '@faredrop/types';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import usePresentToast from '../hooks/presentToast';
import useUser from '../hooks/user';
import { stripePlanIDByFareDropPlan } from '@faredrop/utilities';
import { isLimitedPlan } from '../utilities/plans-utilities';

interface ContainerProps {
  airport: Airport;
  onRemoveCard: (iata: string) => void;
  origin: UserConfigOrigin;
  isHomeAirport?: boolean;
  highlightOnHover?: boolean;
  hideEditHomeAirportButton?: boolean;
  hideRemoveButton?: boolean;
  onChangeHomeAirport: () => void;
  onSelect?: (iata: string) => Promise<void>;
  onEditOriginConfig?: (attributes: number) => void;
  onInvalidEditOriginConfig?: () => void;
}

const DepartureAirportCard: React.FC<ContainerProps> = ({
  airport,
  onRemoveCard,
  origin,
  isHomeAirport,
  hideEditHomeAirportButton,
  hideRemoveButton,
  onChangeHomeAirport,
  highlightOnHover,
  onSelect,
  onEditOriginConfig,
  onInvalidEditOriginConfig,
}) => {
  const userState = useUser();
  const { isMediumScreenSizeOrSmaller } = useDevice();
  const { presentError } = usePresentToast();

  const [domesticLoading, setDomesticLoading] = useState(false);
  const [internationalLoading, setInternationalLoading] = useState(false);

  const nameFormatted = formatAirportCity(airport, true);
  const dealsFormatted = (dealsLastMonth: number, type?: string) =>
    `${dealsLastMonth} ${type ? type : ''} deal${
      dealsLastMonth == 1 ? '' : 's'
    } last month`;

  let containerClassNames = 'departure-airport-card';
  if (isHomeAirport) {
    containerClassNames += ' departure-home-airport-card';
  } else if (highlightOnHover) {
    containerClassNames += ' departure-airport-card-highlight';
  }

  const isDomesticEnabled =
    (origin.attributes & UserOriginAttributes.DOMESTIC) ===
    UserOriginAttributes.DOMESTIC;
  const isInternationalEnabled =
    (origin.attributes & UserOriginAttributes.INTERNATIONAL) ===
    UserOriginAttributes.INTERNATIONAL;
  const onLimitedPlan =
    !userState.user?.billing.idStripePlan ||
    userState.user.billing.idStripePlan ===
      stripePlanIDByFareDropPlan(FareDropPlan.LIMITED);

  return (
    <IonCol
      className={containerClassNames}
      key={airport.iata}
      sizeXs="12"
      sizeMd="5.5"
      style={{
        background: 'white',
        position: 'relative',
        zIndex: isHomeAirport ? 999 : 1001, // This needs to be greater than the zIndex of the dark fill component
        cursor: isHomeAirport ? 'default' : 'pointer',
        padding: isMediumScreenSizeOrSmaller ? '25px 15px' : '25px 2em',
      }}
      onClick={async () => {
        if (!isHomeAirport && onSelect) {
          await onSelect(airport.iata);
        }
      }}
    >
      {hideRemoveButton ||
        (!highlightOnHover && !isHomeAirport && (
          <div className="close" style={{ marginTop: 5 }}>
            <IonIcon
              icon={closeOutline}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveCard(airport.iata);
              }}
            />
          </div>
        ))}
      <h6>{nameFormatted}</h6>
      <p
        style={{
          fontSize: '12px',
          marginBottom: '0px',
          marginTop: '0px',
        }}
      >
        {airport.subAirports ? formatSubAirportsString(airport) : airport.name}
      </p>
      <div>
        <h6 style={{ fontSize: '14px', marginTop: '1em' }}>Domestic</h6>
      </div>
      <div style={{ display: 'flex' }}>
        <IonChip
          color={isDomesticEnabled ? 'blue' : 'light'}
          style={{
            fontSize: 12,
            flexWrap: 'nowrap',
            maxWidth: '100%',
            color: isDomesticEnabled ? undefined : 'gray',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {dealsFormatted(origin.domesticEconomyDealsLastMonth)}
          </span>
        </IonChip>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {domesticLoading ? (
            <IonSpinner
              name="crescent"
              style={{
                width: '20px',
                height: '20px',
                opacity: 0.3,
                verticalAlign: 'middle',
                marginRight: '.5em',
              }}
              color="primary"
            />
          ) : (
            <Tooltip
              title={`Enable/Disable Domestic flight deals for ${origin.iata}`}
            >
              <div>
                <IonToggle
                  style={{
                    minWidth: '50px',
                  }}
                  checked={isDomesticEnabled}
                  onIonChange={async (event) => {
                    event.stopPropagation();
                    // Domestic users can disabled their domestic deals, but Limited users cannot
                    if (isLimitedPlan(userState.user?.billing.idStripePlan)) {
                      onInvalidEditOriginConfig && onInvalidEditOriginConfig();
                    } else {
                      if (onEditOriginConfig) {
                        try {
                          setDomesticLoading(true);
                          let updated: number | undefined = undefined;
                          if (isDomesticEnabled) {
                            updated =
                              origin.attributes &
                              ~UserOriginAttributes.DOMESTIC;
                          } else {
                            updated =
                              origin.attributes | UserOriginAttributes.DOMESTIC;
                          }
                          await onEditOriginConfig(updated);
                        } catch (error) {
                          presentError(
                            'Failed to update departure airport. Please refresh and try again'
                          );
                        } finally {
                          setDomesticLoading(false);
                        }
                      }
                    }
                  }}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div>
        <h6 style={{ fontSize: '14px', marginTop: '1em' }}>International</h6>
      </div>
      <div style={{ display: 'flex' }}>
        <IonChip
          color={isInternationalEnabled ? 'orange' : 'light'}
          style={{
            fontSize: 12,
            flexWrap: 'nowrap',
            maxWidth: '100%',
            marginBottom: '1em',
            color: isInternationalEnabled ? undefined : 'gray',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {dealsFormatted(
              origin.businessDealsLastMonth + origin.economyDealsLastMonth
            )}
          </span>
        </IonChip>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {internationalLoading ? (
            <IonSpinner
              name="crescent"
              style={{
                width: '20px',
                height: '20px',
                opacity: 0.3,
                verticalAlign: 'middle',
                marginRight: '.5em',
              }}
              color="primary"
            />
          ) : (
            <Tooltip
              title={`Enable/Disable International flight deals for ${origin.iata}`}
            >
              <div>
                <IonToggle
                  style={{
                    minWidth: '50px',
                  }}
                  checked={isInternationalEnabled}
                  onClick={async (event) => {
                    event.stopPropagation();
                    if (onLimitedPlan) {
                      onInvalidEditOriginConfig && onInvalidEditOriginConfig();
                    } else {
                      if (onEditOriginConfig) {
                        try {
                          setInternationalLoading(true);
                          let updated: number | undefined = undefined;
                          if (isInternationalEnabled) {
                            updated =
                              origin.attributes &
                              ~UserOriginAttributes.INTERNATIONAL;
                          } else {
                            updated =
                              origin.attributes |
                              UserOriginAttributes.INTERNATIONAL;
                          }
                          await onEditOriginConfig(updated);
                        } catch (error) {
                          presentError(
                            'Failed to update departure airport. Please refresh and try again'
                          );
                        } finally {
                          setInternationalLoading(false);
                        }
                      }
                    }
                  }}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>

      {isHomeAirport && (
        <>
          {!hideEditHomeAirportButton && (
            <div
              style={{
                position: 'absolute',
                bottom: 7,
                right: 10,
                fontSize: '12px',
                color: 'var(--ion-color-primary)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChangeHomeAirport();
              }}
            >
              <SettingsIcon fontSize="small" />
              <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                Change home airport
              </span>
            </div>
          )}
          <div
            style={{
              backgroundColor: 'var(--ion-color-primary)',
              position: 'absolute',
              top: -15,
              left: -25,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '15px',
              color: 'white',
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '10px',
              paddingRight: '13px',
              fontSize: '12px',
            }}
          >
            <StarIcon fontSize="small" />
            <span style={{ paddingLeft: '5px', paddingTop: '2px' }}>
              Home Airport
            </span>
          </div>
        </>
      )}
    </IonCol>
  );
};

export default DepartureAirportCard;
