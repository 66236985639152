import { IonCol, IonIcon, IonRange, IonRow, IonToggle } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';

import { UserConfigDestinationRegion } from '@faredrop/graphql-sdk';
import {
  CONFIG_NOTIFY_MAX_BUSINESS_PRICE,
  CONFIG_NOTIFY_MAX_ECONOMY_PRICE,
  getCurrencySymbol,
} from '@faredrop/utilities';

import React, { useState } from 'react';
import { useDevice } from '../hooks/useDevice';
import { formatRegion } from '../utilities/utils';
import './../theme/DestinationPreferencesSettings.css';
import './../theme/util.css';
import useUser from '../hooks/user';
import { isLimitedPlan } from '../utilities/plans-utilities';

interface ContainerProps {
  isMaxButtonVisible: boolean;
  region: UserConfigDestinationRegion;
  showBusinessSlider: boolean;
  updateRegionsListener: (region: UserConfigDestinationRegion) => Promise<void>;
  onSliderStart?: (region: UserConfigDestinationRegion) => Promise<void>;
}

const DestinationPreferencesCard: React.FC<ContainerProps> = ({
  isMaxButtonVisible,
  region,
  showBusinessSlider,
  updateRegionsListener,
}) => {
  const userState = useUser();
  const { isMediumScreenSizeOrSmaller, isSmallScreenSizeOrSmaller } =
    useDevice();

  const [maxPriceEconomy, setMaxPriceEconomy] = useState(
    region.maxPriceEconomy
  );
  const [maxPriceBusiness, setMaxPriceBusiness] = useState(
    region.maxPriceBusiness
  );

  const isLimited = isLimitedPlan(userState.user?.billing.idStripePlan);

  return (
    <IonRow
      key={region.region}
      className="region-card"
      style={{
        backgroundColor: `${
          region.enabled ? 'white' : 'rgba(248, 248, 248, 1)'
        }`,
        border: '1px solid #C4C4C4',
        borderRadius: '5px',
        marginBottom: '2em',
        padding: '.5em 0',
      }}
    >
      <IonCol>
        <IonRow
          className="row-vertical-align region-card-top"
          style={{ height: 'auto' }}
          onClick={async () => {
            const updatedRegion = { ...region };
            updatedRegion.enabled = !updatedRegion.enabled;
            await updateRegionsListener(updatedRegion);
          }}
        >
          <IonCol sizeXs="9" sizeMd="11" sizeXl="9">
            <IonRow>
              <h6
                style={{ marginRight: isMediumScreenSizeOrSmaller ? '1em' : 0 }}
              >
                {formatRegion(region.region)}
              </h6>
              <IonToggle
                checked={region.enabled}
                style={{
                  height: '25px',
                  width: '43px',
                  marginLeft: isMediumScreenSizeOrSmaller ? 0 : '15px',
                  marginTop: '5px',
                }}
                disabled={isLimited}
              />
            </IonRow>
          </IonCol>
          <IonCol size="2" style={{ display: 'inherit' }}>
            <IonRow>
              <p
                style={{
                  fontSize: '12px',
                  display: isMaxButtonVisible ? 'block' : 'none',
                }}
              >
                Set max prices
              </p>
              <IonIcon
                icon={!region.enabled ? chevronDown : chevronUp}
                style={{ margin: 'auto 0 auto 15px' }}
              />
            </IonRow>
          </IonCol>
        </IonRow>
        <div
          className="collapsible"
          style={
            region.enabled
              ? { maxHeight: '100%' }
              : { overflow: 'hidden', maxHeight: 0 }
          }
        >
          <IonRow>
            <IonCol>
              <IonRow style={{ padding: '0 1.5em' }}>
                <p className="small-text">Economy Max Price</p>
                <h6
                  className="small-text"
                  style={{
                    margin: 'auto auto auto 10px',
                    color: '#111',
                    fontWeight: 800,
                  }}
                >
                  {getCurrencySymbol(userState.user?.configuration.currency)}
                  {!maxPriceEconomy ||
                  maxPriceEconomy >= CONFIG_NOTIFY_MAX_ECONOMY_PRICE
                    ? `${CONFIG_NOTIFY_MAX_ECONOMY_PRICE}+`
                    : maxPriceEconomy}
                </h6>
              </IonRow>
              <IonRange
                pin={true}
                value={maxPriceEconomy ?? CONFIG_NOTIFY_MAX_ECONOMY_PRICE}
                min={0}
                max={CONFIG_NOTIFY_MAX_ECONOMY_PRICE}
                style={{ padding: '0 2em' }}
                onIonChange={async (e) => {
                  const value = e.detail.value as number;
                  if (maxPriceEconomy !== value) {
                    setMaxPriceEconomy(value);

                    const updatedRegion = { ...region };
                    updatedRegion.maxPriceEconomy = value;
                    await updateRegionsListener(updatedRegion);
                  }
                }}
                disabled={isLimited}
              />
              {isSmallScreenSizeOrSmaller && (
                <div className="row-vertical-align" style={{ height: 'auto' }}>
                  <button
                    onClick={async () => {
                      if (maxPriceEconomy) {
                        setMaxPriceEconomy(undefined);

                        const updatedRegion = { ...region };
                        updatedRegion.maxPriceEconomy = undefined;
                        await updateRegionsListener(updatedRegion);
                      }
                    }}
                    style={{ margin: '2em 1em' }}
                    className="button no-caps"
                  >
                    Set Economy to max price
                  </button>
                </div>
              )}
            </IonCol>
          </IonRow>
          {showBusinessSlider && (
            <IonRow>
              <IonCol>
                <IonRow style={{ padding: '0 1.5em' }}>
                  <p className="small-text">Business Max Price</p>
                  <h6
                    className="small-text"
                    style={{
                      margin: 'auto auto auto 10px',
                      color: '#111',
                      fontWeight: 800,
                    }}
                  >
                    {getCurrencySymbol(userState.user?.configuration.currency)}
                    {!maxPriceBusiness ||
                    maxPriceBusiness >= CONFIG_NOTIFY_MAX_BUSINESS_PRICE
                      ? `${CONFIG_NOTIFY_MAX_BUSINESS_PRICE}+`
                      : maxPriceBusiness}
                  </h6>
                </IonRow>
                <IonRow>
                  <IonRange
                    pin={true}
                    value={maxPriceBusiness ?? CONFIG_NOTIFY_MAX_BUSINESS_PRICE}
                    min={0}
                    max={CONFIG_NOTIFY_MAX_BUSINESS_PRICE}
                    style={{ padding: '0 2em' }}
                    onIonChange={async (e) => {
                      const value = e.detail.value as number;
                      if (maxPriceBusiness !== value) {
                        setMaxPriceBusiness(value);

                        const updatedRegion = { ...region };
                        updatedRegion.maxPriceBusiness = value;
                        await updateRegionsListener(updatedRegion);
                      }
                    }}
                    disabled={isLimited}
                  />
                </IonRow>
                {isSmallScreenSizeOrSmaller && (
                  <div
                    className="row-vertical-align"
                    style={{ height: 'auto' }}
                  >
                    <button
                      onClick={async () => {
                        if (maxPriceBusiness) {
                          setMaxPriceBusiness(undefined);

                          const updatedRegion = { ...region };
                          updatedRegion.maxPriceBusiness = undefined;
                          await updateRegionsListener(updatedRegion);
                        }
                      }}
                      style={{ margin: '2em 1em' }}
                      className="button no-caps"
                    >
                      Set Business to max price
                    </button>
                  </div>
                )}
              </IonCol>
            </IonRow>
          )}
        </div>
      </IonCol>
    </IonRow>
  );
};

export default DestinationPreferencesCard;
