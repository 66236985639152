import { SeatClass, DealSearchQuery } from '@faredrop/graphql-sdk';

import useFareDropApiClient from './faredropApiClient';

const useDealSearch = () => {
  const { client } = useFareDropApiClient();

  const dealSearch = async (
    originIATA: string,
    destinationIATA: string,
    seatClass: SeatClass
  ) => {
    let result: DealSearchQuery['dealSearch'] | undefined = undefined;
    try {
      result = (
        await client.dealSearch({
          originIATA,
          destinationIATA,
          seatClass,
        })
      ).data.dealSearch;
    } catch (error) {
      if (!(error as Error)?.message.includes('Route data not found')) {
        throw error;
      }
    }
    return result;
  };

  return {
    dealSearch,
  };
};

export default useDealSearch;
