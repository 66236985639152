import { ReactComponent as NonStop } from '../icons/non-stop.svg';

import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import Lock from '@material-ui/icons/Lock';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WorkIcon from '@material-ui/icons/Work';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { IFlightBadge } from './FlightBadges';
import { BadgeLabel } from '../utilities/deals-utilities';

interface ContainerProps {
  badge: IFlightBadge;
  style?: React.CSSProperties;
}

const FlightBadgeIcon: React.FC<ContainerProps> = ({ badge, style }) => {
  return (
    <>
      {badge.label === BadgeLabel.Business && (
        <WorkIcon
          sx={{
            fontSize: '19px',
            color: 'white',
            ...style,
          }}
        />
      )}
      {badge.label === BadgeLabel.LieFlat && (
        <AirlineSeatFlatIcon
          sx={{
            fontSize: '24px',
            color: 'white',
            ...style,
          }}
        />
      )}
      {badge.label === BadgeLabel.NonStop && (
        <NonStop
          fill={style?.color ?? 'white'}
          style={{
            width: '20px',
            ...style,
          }}
        />
      )}
      {badge.label === BadgeLabel.Budget && (
        <FontAwesomeIcon
          icon={faWallet}
          style={{
            color: 'white',
            fontSize: '16px',
            ...style,
          }}
        />
      )}
      {badge.label === BadgeLabel.Notified && (
        <NotificationsIcon
          sx={{
            fontSize: '19px',
            color: 'white',
            ...style,
          }}
        />
      )}
      {badge.label === BadgeLabel.Upgrade && (
        <Lock
          sx={{
            fontSize: '17px',
            color: 'white',
            ...style,
          }}
        />
      )}
    </>
  );
};

export default FlightBadgeIcon;
