import { IonButton, IonCol, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import HeroPhoneDealsSection from './HeroPhoneDealsSection';
import './../theme/HeroSection.css';
import './../theme/util.css';
import { useDevice } from '../hooks/useDevice';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  promoPill?: string;
  scrollTarget?: string;
  callToAction?: string;
}

const HeroSection: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
  promoPill,
  scrollTarget,
  callToAction,
}) => {
  const { smoothScroll } = useLandingPageNavigation();
  const { isMediumScreenSizeOrSmaller } = useDevice();

  return (
    <IonRow>
      <img
        className="hero"
        src={`${getAssetsBaseUrl()}/backgrounds/traveler-faded.png`}
        style={
          isMediumScreenSizeOrSmaller
            ? {
                WebkitMaskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                maskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
              }
            : {}
        }
      />
      <IonCol>
        <div className="main-titles-container">
          {promoPill && promoPill !== '' && (
            <div
              style={{
                fontSize: '22px',
                backgroundColor: 'var(--ion-color-yellow)',
                padding: 10,
                borderRadius: '25px',
                textAlign: 'center',
                width: '14em',
              }}
              className="center-on-mobile"
            >
              {promoPill}
            </div>
          )}
          <h1 className="main-title title-font">
            Never overpay for a flight again.
          </h1>
          <h2 className="main-subtitle gray" style={{ width: '90%' }}>
            Save up to 80% on flights with deal notifications. Receive only the
            deals that match your travel preferences.
          </h2>
          <IonRow className="get-started-now-button-row">
            <IonButton
              className="get-started-button get-started-button-hero mb-1-5"
              style={{ position: 'absolute' }}
              onClick={async () => {
                smoothScroll(scrollTarget ? scrollTarget : '#register');
                logAnalyticsEngagement &&
                  (await logAnalyticsEngagement(
                    AnalyticsEngagementId.HERO_GET_STARTED
                  ));
              }}
            >
              {callToAction ?? 'Get started now'}
            </IonButton>
            <img src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`} />
          </IonRow>
          <IonRow className="stats-summary">
            <IonCol
              sizeLg="2.5"
              className="right-border stats-summary-col"
              style={{ paddingRight: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0">
                <span className="primary-color">$450</span> saved per flight on
                average
              </h3>
            </IonCol>
            <IonCol
              sizeLg="2.5"
              className="stats-summary-col right-border"
              style={{ paddingRight: '10px', paddingLeft: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0">
                <span className="primary-color">Hundreds</span> of ⭐⭐⭐⭐⭐
                reviews
              </h3>
            </IonCol>
            <IonCol
              sizeLg="2.5"
              className="stats-summary-col"
              style={{ paddingLeft: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0">
                Created by travel experts{' '}
                <span
                  className="primary-color"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    smoothScroll('#createdBy');
                    logAnalyticsEngagement &&
                      (await logAnalyticsEngagement(
                        AnalyticsEngagementId.HERO_KARA_AND_NATE
                      ));
                  }}
                >
                  Kara and Nate
                </span>
              </h3>
            </IonCol>
          </IonRow>
        </div>
      </IonCol>
      <HeroPhoneDealsSection logAnalyticsEngagement={logAnalyticsEngagement} />
    </IonRow>
  );
};

export default HeroSection;
