import {
  Giftee,
  GiftPurchaser,
} from '@faredrop/graphql-apig-unauthenticated-sdk';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import { IGiftee, IGiftPurchaser } from '@faredrop/types';

import useAnalytics from './analytics';
import useFareDropPublicApiClient from './faredropPublicApiClient';
import useUser from './user';
import { buildSuccessAndCancelCheckoutURLs } from '../utilities/stripe';
import useFirstPromoter from './useFirstPromoter';
import { getPlanName } from '../utilities/plans-utilities';
import useImpact from './useImpact';

export interface GiftsValue {
  buildRedeemFailedMailTo: (code?: string, errorMessage?: string) => string;
  createGiftCheckoutUrl: (
    planType: FareDropPlan,
    purchaser: IGiftPurchaser,
    giftee?: IGiftee,
    couponCode?: string
  ) => Promise<string>;
}

const useGifts = (): GiftsValue => {
  const publicFareDrop = useFareDropPublicApiClient();
  const { logAnalyticsError } = useAnalytics();
  const userState = useUser();
  const { getFirstPromoterTID, getFirstPromoterReferralId } =
    useFirstPromoter();
  const { getImpactClickId } = useImpact();

  const createGiftCheckoutUrl = async (
    planType: FareDropPlan,
    purchaser: IGiftPurchaser,
    giftee?: IGiftee,
    couponCode?: string
  ) => {
    // We need to specify success and cancel URLs to maintain consistent domains (www vs naked)
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      `confirmation`,
      [
        {
          key: 'type',
          value: encodeURIComponent(getPlanName(planType) + ' Plan Gift'),
        },
      ],
      'gift'
    );

    try {
      const [fp_tid, fp_referral_id, impact_click_id] = await Promise.all([
        getFirstPromoterTID(),
        getFirstPromoterReferralId(),
        getImpactClickId(),
      ]);

      const urlResult = await publicFareDrop.client.giftsCheckout({
        planType,
        purchaser: purchaser as GiftPurchaser,
        giftee: giftee as Giftee,
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
        couponCode,
        // Include First Promoter identifier for affiliate tracking
        fp_tid,
        fp_referral_id,
        // Impact affiliate tracking
        impact_click_id,
      });
      return urlResult.data.giftsCheckout.url;
    } catch (error) {
      await logAnalyticsError('getGiftCheckoutUrl', error as Error);
      throw error;
    }
  };

  const buildRedeemFailedMailTo = (code?: string, errorMessage?: string) => {
    const redeemEmailSubject = encodeURIComponent('Redeem Gift Trouble');
    const redeemBody = encodeURIComponent(
      `Hi, My name is ${userState.user?.profile.firstName} ${userState.user?.profile.lastName} and I received an error while trying to redeem my gift with code ${code}. Can you assist me? The error is: "${errorMessage}""`
    );
    return `mailto:team@faredrop.com?subject=${redeemEmailSubject}&body=${redeemBody}`;
  };

  return {
    buildRedeemFailedMailTo,
    createGiftCheckoutUrl,
  };
};

export default useGifts;
