import moment from 'moment';
import { useEffect, useState } from 'react';
import { $enum } from 'ts-enum-util';

import { FareDropPlan } from '@faredrop/graphql-sdk';

import usePresentToast from './presentToast';

export interface IQueryParamDiscounts {
  value: number;
  type: 'percent' | 'amount';
}

export const useDisplayDiscounts = () => {
  const { presentError } = usePresentToast();

  const [discounts, setDiscounts] =
    useState<{ [plan: string]: IQueryParamDiscounts }>();

  // Discount formats
  //  - ?discounts=20 (20% off applied to all paid plans)
  //  - ?discounts=PRO:20 (applies 20% off to Pro plan only)
  //  - ?discounts=PRO:20:amount (applies $20 off to Pro plan only)
  //  - ?discounts=GLOBAL:20:amount,PRO:50:percent (applies $20 off of Deprecated Global plan and 50% off of Pro)
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const discounts = queryParams.get('discounts');
    const discountExpiration = queryParams.get('discountExpiration');

    if (
      discountExpiration &&
      !isNaN(parseInt(discountExpiration)) &&
      moment.utc().isAfter(moment.utc(parseInt(discountExpiration)))
    ) {
      presentError('The given coupon has expired');
    } else {
      if (discounts) {
        const discountMap: { [plan: string]: IQueryParamDiscounts } = {};

        // If only a number is given, assume all paid plans have percent of value off
        if (!isNaN(parseInt(discounts))) {
          discountMap[FareDropPlan.Global] = {
            value: parseInt(discounts),
            type: 'percent',
          };
          discountMap[FareDropPlan.Pro] = {
            value: parseInt(discounts),
            type: 'percent',
          };
        } else {
          try {
            const planSplit = discounts.split(',');
            planSplit.forEach((planString) => {
              const planConfig = planString.split(':');
              if (planConfig.length !== 2 && planConfig.length !== 3)
                throw new Error('Invalid discount');

              if (!$enum(FareDropPlan).isValue(planConfig[0]))
                throw new Error('Invalid plan specified');
              if (isNaN(parseInt(planConfig[1])))
                throw new Error('Discount value must be a number');

              discountMap[planConfig[0]] = {
                value: parseInt(planConfig[1]),
                type: 'percent',
              };

              if (planConfig[2]) {
                if (planConfig[2] !== 'percent' && planConfig[2] !== 'amount')
                  throw new Error('Invalid discount type');
                else {
                  discountMap[planConfig[0]].type = planConfig[2] as
                    | 'percent'
                    | 'amount';
                }
              }
            });
          } catch (error) {
            presentError(
              (error as Error)?.message ?? 'Failed to display discounts'
            );
          }
        }
        setDiscounts(discountMap);
      }
    }
  }, []);

  return {
    discounts,
  } as const;
};
