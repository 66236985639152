// cspell:ignore Meliá

import { IonButton, IonCol, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import './../theme/CreatedBySection.css';
import './../theme/util.css';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface CreatedByProps {
  onSignUp: () => Promise<void>;
  onDetails: () => Promise<void>;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const GiveawayFriendlyDescription: React.FC<CreatedByProps> = ({
  logAnalyticsEngagement,
  onSignUp,
  onDetails,
}) => {
  return (
    <IonRow className="created-by-container">
      <IonCol size="6" className="created-by-bg-container desktop">
        {/* <img
          src={`${getAssetsBaseUrl()}/backgrounds/kara-and-nate-grid.png`}
          style={{ position: 'absolute' }}
        />
        <div style={{ position: 'relative' }}>
          <ScrollYouTube videoId="8kMZcwAuALA" className="overlap-image" />
        </div> */}
        <img
          src={`${getAssetsBaseUrl()}/places/punta-cana-pool-2.jpg`} // cspell:disable-line
          style={{ position: 'absolute' }}
        />
      </IonCol>
      <IonCol size="12" sizeXl="6">
        <h2 className="created-by-text title-font ml-4 mt-3 p-0">
          Punta Cana Giveaway
        </h2>
        <p className="created-by-text gray lh-2 ml-4">
          Hey! We&apos;re Kara and Nate, the creators behind FareDrop. Our
          shared love of travel inspired us to create this service to enable
          more people to travel the world.
          <br />
          <br />
          After traveling to over 100 countries and all 7 continents, one of our
          favorite ways to relax is staying at a beautiful all-inclusive resort
          in the Caribbean. That&apos;s why we are partnering with{' '}
          <a
            href="https://www.melia.com/en/hotels/dominican-republic/punta-cana/melia-punta-cana-beach-resort"
            target="_blank"
            rel="noreferrer"
          >
            Meliá Punta Cana Beach Resort
          </a>{' '}
          to give away:
          <ul>
            <li>
              One, five (5) night all-inclusive stay for two (2) adults at Meliá
              Punta Cana Beach Wellness Inclusive Adults Only Resort (“Resort”)
              in Punta Cana, Dominican Republic
            </li>
            <li>
              The all-inclusive stay includes all meals and specialty snacks,
              drinks and premium cocktails, taxes & gratuities, non-motorized
              water sports, a fitness center and classes, daily activities, live
              performances and entertainment.
            </li>
            <li>Plus, up to $2,000 to cover airfare</li>
          </ul>
          <IonButton
            className="get-started-button get-started-button-hero mb-1-5"
            style={{
              width: '100%',
              maxWidth: '24em',
              textAlign: 'center',
            }}
            onClick={async () => {
              const promises = [onSignUp()];
              logAnalyticsEngagement &&
                promises.push(
                  logAnalyticsEngagement(
                    AnalyticsEngagementId.DESCRIPTION_SIGN_UP_CLICK
                  )
                );
              await Promise.all(promises);
            }}
          >
            Join FareDrop
            <br />
            for your chance to win!
          </IonButton>
          <br />
          The winner will be chosen and notified on August 12th for this
          incredible adventure!
          <br />
          <br />
          <a
            onClick={async () => {
              const promises = [onDetails()];
              logAnalyticsEngagement &&
                promises.push(
                  logAnalyticsEngagement(
                    AnalyticsEngagementId.DESCRIPTION_DETAILS_CLICK
                  )
                );
              await Promise.all(promises);
            }}
            style={{
              cursor: 'pointer',
              color: 'inherit',
            }}
          >
            NO PURCHASE NECESSARY TO ENTER OR WIN. See official sweepstakes
            rules below for more details.
          </a>{' '}
          <br />
          <br />
          <span style={{ float: 'right' }}>Cheers from somewhere,</span>
        </p>
        <br />
        <svg
          className="ml-4"
          width="224"
          height="93"
          viewBox="0 0 224 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            float: 'right',
            marginRight: '2em',
            opacity: 0.8,
          }}
        >
          <path
            d="M20.2972 18.6293L7.4687 84.6215L44.6935 16.5589C21.7502 50.8305 -12.7836 111.996 32.6274 82.4864C78.0384 52.9766 84.4244 35.3307 81.941 30.1964L117.435 68.417L136.18 8.79486C134.408 36.1792 133.022 87.4052 141.648 73.2342C150.274 59.0633 176.551 32.0937 159.723 41.921C161.757 41.7485 167.652 41.2481 174.971 40.627C184.12 39.8506 183.476 32.2695 189.897 35.5425C195.034 38.1608 210.279 22.3592 217.259 14.1311"
            stroke="black"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IonCol>
      <IonCol size="12" className="created-by-bg-container mobile mt-7">
        {/* <IonImg
          src={`${getAssetsBaseUrl()}/backgrounds/kara-and-nate-grid.png`}
          style={{ position: 'absolute',  }}
        />
        <div style={{ position: 'relative' }}>
          <ScrollYouTube videoId="8kMZcwAuALA" className="overlap-image" />
        </div> */}
      </IonCol>
    </IonRow>
  );
};

export default GiveawayFriendlyDescription;
