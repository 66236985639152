// cspell:ignore illum
import {
  FareDropRole as FareDropRoleGQL,
  SeatClass as SeatClassGQL,
  SubscriptionStatus,
  FareDropPlan as FareDropGQL,
} from '@faredrop/graphql-sdk';
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { airplane } from 'ionicons/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DesktopHeader from '../components/DesktopHeader';
import Dropdown from '../components/Dropdown';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import Loading from '../components/Loading';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import SettingsBackground from '../components/SettingsBackground';
import SettingsSideMenu, { MENU_OPTIONS } from '../components/SettingsSideMenu';
import useAnalytics from '../hooks/analytics';
import useLandingPageDefaultDeals from '../hooks/landingPageDefaultDeals';
import usePresentToast from '../hooks/presentToast';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useStripe from '../hooks/useStripe';
import { LandingDeal, PAGES } from '../types/types';
import './../theme/PlanAndBillingSettings.css';
import './../theme/util.css';
import { formatAirportLocation } from '../utilities/deals-utilities';
import { getPlanName, stripePlanIDToFareDropPlans } from '@faredrop/utilities';
import { AllowedPath, FareDropPlan } from '@faredrop/types';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useChurnKey from '../hooks/useChurnKey';
import { IChangeSubscriptionPlanPreflight } from '../components/ChoosePlan';
import { $enum } from 'ts-enum-util';
import { Tab } from '../contexts/tabContext';
import useLogError from '../hooks/logError';
import { getAssetsBaseUrl } from '@faredrop/utilities';
import useRefreshAccount from '../hooks/useRefreshAccount';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';
import { isLimitedPlan } from '../utilities/plans-utilities';

enum LoadingState {
  NONE = 'NONE',
  CHANGE_CARD = 'CHANGE_CARD',
  CHANGE_PLAN = 'CHANGE_PLAN',
  UPGRADE_TO_PRO = 'UPGRADE_TO_PRO',
  RESUBSCRIBE = 'RESUBSCRIBE',
}

const PlanAndBillingSettings: React.FC = () => {
  const { logAnalyticsUpgradeSubscription } = useAnalytics();
  const { isExtraLargeScreenSize, isApp } = useDevice();
  const { createCustomerPortalSession, changeSubscriptionPreFlight } =
    useStripe();

  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.NONE
  );
  const { presentError } = usePresentToast();
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { width } = useWindowDimensions();
  const { showChurnKeyModal, initializeChurnKey } = useChurnKey();
  const { logError } = useLogError();

  const userState = useUser();
  const noInternet = userState.timeout;

  const userBilling = userState.user?.billing;
  const userProfile = userState.user?.profile;

  const [advertisedDeals, setAdvertisedDeals] = useState<LandingDeal[]>([]);
  const [preflightPlan, setPreflightPlan] =
    useState<IChangeSubscriptionPlanPreflight | null>();

  const defaultDealsState = useLandingPageDefaultDeals();

  const { isAccountReady } = useRefreshAccount();

  const onLimitedPlan = isLimitedPlan(userBilling?.idStripePlan);

  let nextPlan: FareDropPlan | undefined = FareDropPlan.PRO;
  if (userProfile?.roles.includes(FareDropRoleGQL.Pro)) {
    nextPlan = undefined;
  }
  const nextPlanDescription =
    'Get unlimited deals for both economy and business class seats to incredible destinations!';

  useEffect(() => {
    updateSelectedTab(Tab.ACCOUNT).catch((error) => {
      console.warn('Failed to update selected tab', Tab.ACCOUNT, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  useEffect(() => {
    if (!defaultDealsState.isInitializing) {
      setAdvertisedDeals(
        defaultDealsState.randomDeals(4, SeatClassGQL.Economy)
      );
    }
  }, [defaultDealsState.isInitializing]);

  useEffect(() => {
    const fetchPreflight = async () => {
      let preflight: IChangeSubscriptionPlanPreflight | undefined = undefined;
      try {
        const currentPlan = userState.user?.billing.idStripePlan;
        if (currentPlan) {
          const accessibleFareDropPlans =
            stripePlanIDToFareDropPlans(currentPlan);
          if (accessibleFareDropPlans.length > 0) {
            const currentFareDropPlan =
              accessibleFareDropPlans[accessibleFareDropPlans.length - 1];
            const preflightPlans = await changeSubscriptionPreFlight();
            preflight = preflightPlans.find(
              (preflightPlan) =>
                preflightPlan.plan ===
                $enum(FareDropGQL).asValueOrDefault(
                  currentFareDropPlan,
                  undefined
                )
            );
          }
        }
      } catch (error) {
        presentError(
          'Failed to fetch current plan information - please refresh the page and try again'
        );
      } finally {
        setPreflightPlan(preflight ?? null); // undefined = not loaded, null = loaded and no value
      }
    };

    fetchPreflight().catch(() =>
      presentError(
        'Failed to fetch current plan information - please refresh the page and try again'
      )
    ); // Async - Fire and forget
  }, [userState.user?.billing.idStripePlan]);

  // Initialize Churn Key
  useEffect(() => {
    if (userBilling?.idStripeCustomer) {
      initializeChurnKey().catch((err) => {
        logError(
          'Failed to initialize churn key on plan and billing page',
          err && (err as Error).message
            ? (err as Error).message
            : 'Error or error message is undefined'
        ).catch((error) =>
          console.warn(
            'Failed to send error log to endpoint for initializing churnkey on plan and billing page',
            error
          )
        );
        throw err; // Show uh-oh screen
      }); // Async - Fire and forget
    }
  }, [userBilling?.idStripeCustomer]);

  const getPlanTitleText = () => {
    // We will redirect from this page is idStripePlan is not set,
    // so this is just to make TS happy
    if (!userState.user?.billing.idStripePlan) {
      return '';
    }

    const planTypes = stripePlanIDToFareDropPlans(
      userState.user?.billing.idStripePlan
    );
    const planType = planTypes.includes(FareDropPlan.PRO)
      ? FareDropPlan.PRO
      : planTypes.includes(FareDropPlan.GLOBAL)
      ? FareDropPlan.GLOBAL
      : FareDropPlan.LIMITED;

    return `${getPlanName(planType)} Plan`;
  };

  const getPlanSubtitleText = () => {
    if (!userState.user?.billing.idStripePlan) {
      return '';
    }

    return `You're getting ${
      onLimitedPlan ? 'limited ' : ''
    }Economy class flight deals to domestic and international destinations${
      onLimitedPlan
        ? '.'
        : ' as well as Business class flight deals to international destinations.'
    }`;
  };

  const setLoadingAndCatchErrors = async (
    call: Promise<void>,
    loadingState: LoadingState
  ) => {
    try {
      setLoadingState(loadingState);
      await call;

      // Anything that calls this method and succeeds should redirect us away from the page, so no need to do this
      // In fact, this creates an awkward visual effect where the loading spinner disappears before react can be bothered to do the actual redirect...
      // setLoadingState(LoadingState.NONE);
    } catch {
      presentError('Error: Please try again');
      setLoadingState(LoadingState.NONE);
    }
  };

  const nextPaymentText = () => {
    if (preflightPlan === undefined || !userBilling?.autoRenew) return '';

    if (preflightPlan?.nextPlan) {
      return (
        <>
          Downgrading to the
          <span style={{ fontFamily: 'nexa-bold', color: '#222' }}>
            &nbsp;&nbsp;
            {preflightPlan.nextPlan}
            &nbsp;&nbsp;
          </span>
          plan on
          <span style={{ fontFamily: 'nexa-bold', color: '#222' }}>
            &nbsp;&nbsp;
            {moment(userBilling.stripePlanEnd).format('LL')}
            &nbsp;
          </span>
          .
        </>
      );
    } else {
      return (
        <>
          Next payment
          {preflightPlan?.nextInvoiceAmount != null
            ? ` $${preflightPlan.nextInvoiceAmount}`
            : ''}{' '}
          on
          <span style={{ fontFamily: 'nexa-bold', color: '#222' }}>
            &nbsp;&nbsp;
            {moment(userBilling.stripePlanEnd).format('LL')}
          </span>
        </>
      );
    }
  };

  return (
    (!isAccountReady && <Loading />) ||
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize ||
        (!userState.hasPaymentInfo &&
          userState.subscriptionStatus === SubscriptionStatus.FreeTrial) ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo &&
              userState.subscriptionStatus === SubscriptionStatus.FreeTrial
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={
              !userState.hasPaymentInfo &&
              userState.subscriptionStatus === SubscriptionStatus.FreeTrial &&
              !isExtraLargeScreenSize
            }
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          {!userState.hasPaymentInfo &&
          userState.subscriptionStatus === SubscriptionStatus.FreeTrial ? (
            <IonRow className="row-vertical-align">
              <IonCol
                sizeXs="10"
                sizeMd="8"
                sizeXl="10"
                style={{ textAlign: 'center' }}
              >
                <h2 style={{ margin: '1em 0' }}>
                  {!userState.hasPaymentInfo &&
                  userState.subscriptionStatus === SubscriptionStatus.FreeTrial
                    ? 'This is a free trial of FareDrop'
                    : "You're not currently subscribed to FareDrop"}
                </h2>
                <h4
                  style={{
                    color: 'var(--ion-color-lighter-gray)',
                    margin: '1em 0',
                  }}
                >
                  Subscribe to get even more amazing flight deals to incredible
                  destinations!
                </h4>
                <div style={{ width: '100%' }}>
                  <IonButton
                    style={{ margin: '1em 0', height: '4em', width: '12em' }}
                    onClick={async () => {
                      goWithStickyParamsPath(AllowedPath.PLANS);
                    }}
                  >
                    Subscribe
                  </IonButton>
                </div>
                <div style={{ marginTop: '3em' }}>
                  <a
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      showChurnKeyModal();
                    }}
                  >
                    I&apos;d like to cancel my free trial
                  </a>
                </div>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow
              style={{
                marginBottom: '10em',
                paddingTop: isExtraLargeScreenSize
                  ? '5em'
                  : isApp
                  ? '1.5em'
                  : undefined,
              }}
            >
              <IonCol size="10" style={{ margin: 'auto', padding: 0 }}>
                <IonRow>
                  <h1
                    className="title-font"
                    style={{
                      marginTop: '2em',
                    }}
                  >
                    {isExtraLargeScreenSize ? 'Account Settings' : 'Settings'}
                  </h1>
                </IonRow>
                <IonRow
                  className={
                    !isExtraLargeScreenSize ? 'row-vertical-align' : ''
                  }
                >
                  <SettingsSideMenu
                    activeOption={PAGES.PLAN_AND_BILLING}
                    isVisible={isExtraLargeScreenSize}
                  />
                  <Dropdown
                    options={MENU_OPTIONS[0].options.map(
                      (option) => option.label
                    )}
                    placeholder={PAGES.PLAN_AND_BILLING}
                    onOptionClick={(option: string) => {
                      goWithStickyParamsPath(option);
                    }}
                    hrefOptions={true}
                    hidden={isExtraLargeScreenSize}
                    width={isExtraLargeScreenSize ? width / 2 : '100%'}
                  />
                  {/* {isApp ? (
                    <IonCol
                      size={isExtraLargeScreenSize ? '8' : '12'}
                      style={{
                        marginLeft: !isExtraLargeScreenSize ? 0 : '5em',
                      }}
                      className="plan-and-billing-section-container"
                    >
                      <IonRow
                        style={{
                          marginBottom: '2em',
                          marginTop: !isExtraLargeScreenSize
                            ? '2em'
                            : 'inherit',
                        }}
                      >
                        <IonCol>
                          <h2
                            style={{
                              textAlign: 'center',
                              fontSize: 24,
                              lineHeight: '1.5em',
                            }}
                          >
                            Looking for your
                            <br />
                            Plan & Billings settings?
                          </h2>
                          <p style={{ textAlign: 'center', marginTop: '2em' }}>
                            Please go to FareDrop on the web to manage your plan
                            and billing settings.
                          </p>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  ) : ( */}
                  <IonCol
                    sizeXs="12"
                    sizeXl="8"
                    style={{
                      marginLeft: !isExtraLargeScreenSize ? 0 : '5em',
                    }}
                    className="plan-and-billing-section-container"
                  >
                    <IonRow
                      style={{
                        marginBottom: '2em',
                      }}
                    >
                      <IonCol>
                        <h2>Plan & Billing</h2>
                        <p>Update your FareDrop plan and billing information</p>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      style={{
                        border: '1px solid #C4C4C4',
                        borderRadius: '8px',
                        marginBottom: '2em',
                        backgroundColor: 'white',
                      }}
                    >
                      <div
                        style={{
                          padding: '1em 2em',
                        }}
                      >
                        <IonCol>
                          <IonRow>
                            <IonCol size={isExtraLargeScreenSize ? '9' : '12'}>
                              <IonRow>
                                <IonCol sizeXs="12" sizeXl="8">
                                  <IonRow>
                                    <h5>Current Plan</h5>
                                  </IonRow>
                                  <IonRow>
                                    <h3>{getPlanTitleText()}</h3>
                                  </IonRow>
                                  <IonRow>
                                    <p>{getPlanSubtitleText()}</p>
                                  </IonRow>
                                  <IonRow style={{ marginTop: '1em' }}>
                                    <p style={{ fontSize: 14 }}>
                                      {nextPaymentText()}
                                    </p>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeXl="3">
                              <hr
                                style={{
                                  borderTop: !isExtraLargeScreenSize
                                    ? '1px solid #ddd'
                                    : 'none',
                                  marginBottom: !isExtraLargeScreenSize
                                    ? '3em'
                                    : 0,
                                }}
                              />
                              {!HIDE_MEMBERSHIP_LINKS && (
                                <>
                                  <div>
                                    <a
                                      style={{
                                        float: !isExtraLargeScreenSize
                                          ? 'left'
                                          : 'right',
                                        textAlign: !isExtraLargeScreenSize
                                          ? 'left'
                                          : 'right',
                                        width: !isExtraLargeScreenSize
                                          ? '100%'
                                          : 'auto',
                                      }}
                                      onClick={() => {
                                        goWithStickyParamsPath('/plans');
                                      }}
                                    >
                                      Manage Membership
                                    </a>
                                  </div>
                                  <div>
                                    {(loadingState ===
                                      LoadingState.CHANGE_CARD && (
                                      <div
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                        }}
                                      >
                                        <IonSpinner
                                          name="crescent"
                                          style={{
                                            width: 40,
                                            marginTop: '2em',
                                            marginBottom: '2em',
                                            marginRight: !isExtraLargeScreenSize
                                              ? 'auto'
                                              : 0,
                                            marginLeft: isExtraLargeScreenSize
                                              ? 'auto'
                                              : 0,
                                          }}
                                        />
                                      </div>
                                    )) || (
                                      <a
                                        style={{
                                          float: !isExtraLargeScreenSize
                                            ? 'left'
                                            : 'right',
                                          textAlign: !isExtraLargeScreenSize
                                            ? 'left'
                                            : 'right',
                                          width: !isExtraLargeScreenSize
                                            ? '100%'
                                            : 'auto',
                                          marginTop: '2em',
                                          marginBottom: '2em',
                                        }}
                                        onClick={async () => {
                                          await setLoadingAndCatchErrors(
                                            createCustomerPortalSession(
                                              'account/plan-and-billing'
                                            ),
                                            LoadingState.CHANGE_CARD
                                          );
                                        }}
                                      >
                                        Edit Payment
                                      </a>
                                    )}
                                  </div>
                                </>
                              )}
                              {HIDE_MEMBERSHIP_LINKS && (
                                <div>
                                  <p
                                    style={{
                                      float: !isExtraLargeScreenSize
                                        ? 'left'
                                        : 'right',
                                      width: !isExtraLargeScreenSize
                                        ? '100%'
                                        : 'auto',
                                      marginTop: '2em',
                                      marginBottom: '2em',
                                    }}
                                  >
                                    {HIDE_UPGRADE_MESSAGE}
                                  </p>
                                </div>
                              )}
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </div>
                    </IonRow>
                    {!HIDE_MEMBERSHIP_LINKS && nextPlan && (
                      <IonRow
                        style={{
                          border: '1px solid #6EB3C1',
                          borderRadius: '8px',
                          backgroundColor: '#E6F2F4',
                        }}
                      >
                        <IonCol
                          sizeXs="12"
                          sizeXl="7.5"
                          style={{ padding: '1em 2em' }}
                        >
                          <IonRow
                            className="row-vertical-align"
                            style={{ justifyContent: 'left' }}
                          >
                            <IonCol size="10">
                              <IonRow>
                                <h3>{getPlanName(nextPlan)} Plan</h3>
                              </IonRow>
                              <IonRow>
                                <h6>{nextPlanDescription}</h6>
                              </IonRow>
                              <IonRow>
                                <button
                                  style={{
                                    margin: !isExtraLargeScreenSize
                                      ? '2em 0 0 0'
                                      : '2em 0',
                                    display: 'flex',
                                    backgroundColor: 'var(--ion-color-primary)',
                                    padding: '1em 2em',
                                    borderRadius: '8px',
                                    width: '16em',
                                  }}
                                  className="dim-on-hover"
                                  onClick={async () => {
                                    await logAnalyticsUpgradeSubscription(
                                      'plan_and_billing'
                                    );
                                    goWithStickyParamsPath('/plans');
                                  }}
                                >
                                  {(loadingState ===
                                    LoadingState.UPGRADE_TO_PRO && (
                                    <IonSpinner
                                      name="crescent"
                                      style={{ margin: 'auto' }}
                                      color="secondary"
                                    />
                                  )) || (
                                    <p
                                      style={{
                                        fontFamily: 'nexa-bold',
                                        margin: 'auto',
                                        color: 'white',
                                      }}
                                      className="hover-illum" // cspell:disable-line
                                    >
                                      Upgrade to {getPlanName(nextPlan)}
                                    </p>
                                  )}
                                </button>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                        <IonCol
                          sizeXs="12"
                          sizeXl="4.5"
                          className="business-section-right"
                          style={{
                            padding: !isExtraLargeScreenSize
                              ? '3em 0 2em'
                              : '0 2em',
                            margin: !isExtraLargeScreenSize
                              ? '2em auto 0 auto'
                              : '2em 0',
                            backgroundImage: !isExtraLargeScreenSize
                              ? 'linear-gradient(to right, #E6F2F4 33%, rgba(110, 179, 193, 0.5) 0%)'
                              : 'linear-gradient(#E6F2F4 33%, rgba(110, 179, 193, 0.5) 0%)',
                            backgroundPosition: !isExtraLargeScreenSize
                              ? 'top'
                              : 'left',
                            backgroundSize: !isExtraLargeScreenSize
                              ? '25px 2px'
                              : '2px 25px',
                            backgroundRepeat: !isExtraLargeScreenSize
                              ? 'repeat-x'
                              : 'repeat-y',
                          }}
                        >
                          <div
                            style={{
                              margin: !isExtraLargeScreenSize ? '1em 2em' : 0,
                            }}
                          >
                            <img
                              style={{
                                position: 'absolute',
                                top: !isExtraLargeScreenSize ? '5%' : '-10%',
                                right: '4%',
                              }}
                              src={`${getAssetsBaseUrl()}/graphics/recent-deals-text.png`}
                            />
                            {advertisedDeals.map((deal, i) => {
                              if (!deal) {
                                return <> </>;
                              }
                              const priceFormatted = (price: number) =>
                                `$${price}`;
                              return (
                                <IonRow
                                  key={i}
                                  className="row-vertical-align"
                                  style={{
                                    height: 'auto',
                                    justifyContent: 'left',
                                  }}
                                >
                                  <IonIcon
                                    icon={airplane}
                                    className="airplane-icon"
                                  />
                                  <p
                                    style={{
                                      color: 'var(--ion-color-primary)',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {formatAirportLocation(deal)}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'nexa-bold',
                                    }}
                                  >
                                    &nbsp;&nbsp;
                                    {priceFormatted(deal.minPrice)}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    &nbsp;&nbsp;
                                    <span
                                      style={{
                                        textDecoration: 'line-through',
                                      }}
                                    >
                                      {priceFormatted(deal.historicalMeanPrice)}
                                    </span>
                                  </p>
                                </IonRow>
                              );
                            })}
                          </div>
                        </IonCol>
                      </IonRow>
                    )}
                  </IonCol>
                  {/* )} */}
                </IonRow>
              </IonCol>
            </IonRow>
          )}
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
      </IonPage>
    )
  );
};

export default PlanAndBillingSettings;
