// cspell:ignore Gvtg
import { IonButton, IonCol, IonContent, IonPage, IonRow, useIonViewDidEnter } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import './../theme/Home.css';
import './../theme/Offer.css';
import { useState } from 'react';
import useAnalytics from '../hooks/analytics';
import { AnalyticsEngagementId, OfferType } from '../contexts/analyticsContext';
import HomePageDealsSection from '../components/HomePageDealsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import SocialProofSection from '../components/SocialProofSection';
import TrackVisibility from '../components/TrackVisibility';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import PromotionHero from '../components/PromotionHero';
import SubscriptionPlansSection from '../components/SubscriptionPlansSection';
import GiveawayFriendlyDescription from '../components/GiveawayFriendlyDescription';
import GiveawayLegalDisclaimer from '../components/GiveawayLegalDisclaimer';
import DynamicBanner from '../components/DynamicBanner';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import useAuth from '../hooks/auth';
import moment from 'moment';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  getAssetsBaseUrl,
} from '@faredrop/utilities';
import usePromotionRedirect from '../hooks/usePromotionRedirect';
import { AllowedPath } from '@faredrop/types';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import useHandleNewSubscription from '../hooks/handleNewSubscription';
import PromotionFreebieModal from '../components/PromotionFreebieModal';
import SubscriptionPlansTable from '../components/SubscriptionPlansTable';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';
import AsSeenIn from '../components/AsSeenIn';

interface ContainerProps {
  plansParams?: [{ key: string; value: string }];
  onMenuClickHandler: () => void;
}

const GiveawayOffer: React.FC<ContainerProps> = ({
  onMenuClickHandler,
  plansParams,
}) => {
  usePromotionRedirect(); // Redirect to home page if the promotion is over
  const {
    isSmallScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
    isLargeScreenSizeOrSmaller,
    isExtraLargeScreenSize,
  } = useDevice();
  const [showRegisterSection, setShowRegisterSection] = useState(false);
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { logAnalyticsOfferEngagement, logAnalyticsOfferPageSectionView } =
    useAnalytics();
  const dynamicBannerContent = useDynamicBanner();
  const { isAuthenticated, isInitialized } = useAuth();
  const { handleNewSubscription, createPlanFromPlanParam, selectedPlanParam } =
    useHandleNewSubscription();

  const [promotionFreebieModalPlan, setPromotionFreebieModalPlan] =
    useState<FareDropPlan>();

  useIonViewDidEnter(() => {
    const queryParams = new URLSearchParams(location.search);
    const target = queryParams.get('target');
    if (target != null) {
      scrollIntoView(`#${target}`);
    }
  }, [location.search]);

  const scrollIntoView = (target: string) => {
    document.querySelector(target)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const goToRegistration = async () => {
    if (isInitialized && isAuthenticated) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    } else {
      setShowRegisterSection(true);
      scrollIntoView('#register-giveaway');
    }
  };

  return (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <DynamicBanner bannerClickListener={goToRegistration} />
      <IonRow
        style={{
          position: 'absolute',
          top: dynamicBannerContent ? 50 : 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: isMediumScreenSizeOrSmaller ? undefined : '0.5em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            ctaHidden={true}
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.SUMMER24,
                engagementId
              );
            }}
          />
        )}
      </IonRow>
      <IonContent fullscreen={true} scrollEvents={true}>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(OfferType.SUMMER24, 'hero')
          }
          style={{ height: '100%' }}
        >
          <PromotionHero
            title="Win a FREE trip to Punta Cana!"
            subTitle="Join FareDrop for a chance to win"
            heroImageSrc={`${getAssetsBaseUrl()}/places/punta-cana-palms.webp`}
            onCreatedByTravelExperts={async () => {
              scrollIntoView('#giveaway-friendly-description');
            }}
            onRulesClick={async () => {
              scrollIntoView('#giveaway-legal-disclaimer');
            }}
            onGetStarted={goToRegistration}
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.SUMMER24,
                engagementId
              );
            }}
            expiration={moment('08-08-2024', 'MM-DD-YYYY')}
          />
        </TrackVisibility>
        <div id="register-giveaway" style={{ paddingTop: '2em' }}>
          {showRegisterSection && (
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                marginBottom: '3em',
                marginTop: '3em',
                marginLeft: '1em',
                marginRight: '1em',
              }}
            >
              <div style={{ maxWidth: '500px' }}>
                <div>
                  <h3 className="title-font" style={{ textAlign: 'center' }}>
                    Almost there! We just need a few things first
                  </h3>
                </div>
                <GetStarted
                  onSubmit={async () => {
                    if (selectedPlanParam !== undefined) {
                      const subscriptionPlan =
                        createPlanFromPlanParam(selectedPlanParam);

                      if (
                        isFrontendSubscriptionPromotion() &&
                        PROMOTION_IS_SHOPIFY_INTEGRATION &&
                        selectedPlanParam !== FareDropPlan.Pro
                      ) {
                        setPromotionFreebieModalPlan(selectedPlanParam);
                      } else {
                        await handleNewSubscription(
                          subscriptionPlan,
                          undefined,
                          false,
                          plansParams
                        );
                      }
                    } else {
                      const params = new URLSearchParams(
                        window.location.search
                      );

                      plansParams?.forEach((p) => {
                        params.set(p.key, p.value);
                      });

                      goWithStickyParamsLocation({
                        pathname: AllowedPath.PLANS,
                        search: `?${params.toString()}`,
                      });
                    }
                  }}
                  logAnalyticsEngagement={async (engagementId) => {
                    await logAnalyticsOfferEngagement(
                      OfferType.SUMMER24,
                      engagementId
                    );
                  }}
                />
              </div>
            </IonRow>
          )}
        </div>
        <div id="giveaway-friendly-description">
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsOfferPageSectionView(
                OfferType.SUMMER24,
                'friendly_description'
              )
            }
          >
            <GiveawayFriendlyDescription
              onSignUp={goToRegistration}
              onDetails={async () => {
                scrollIntoView('#giveaway-legal-disclaimer');
              }}
              logAnalyticsEngagement={async (engagementId) => {
                await logAnalyticsOfferEngagement(
                  OfferType.SUMMER24,
                  engagementId
                );
              }}
            />
          </TrackVisibility>
        </div>
        <IonRow style={{marginBottom: '8em'}}>
          <IonCol
            size="12"
            sizeXl="6"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <h2 className="title-font p-0" style={{textAlign: 'center'}}>
              AND Get a FREE Travel Day Bundle
            </h2>
            <IonRow style={{display: 'flex', justifyContent: isExtraLargeScreenSize ? 'flex-start' : 'center', paddingLeft: isExtraLargeScreenSize ? '5em' : undefined}}>
              <ul className="created-by-text gray lh-2">
                <li>Daily Drop luggage tag with key ring</li>
                <li>FareDrop luggage tag with key ring</li>
                <li>FareDrop sticker pack with 3 stickers</li>
                <li>Black packable “This is my personal item” bag and pouch</li>
                <li>5 travel packs of AG1</li>
              </ul>
              <IonButton
                className="get-started-button get-started-button-hero mb-1-5"
                style={{
                  width: '84%',
                  maxWidth: '24em',
                  textAlign: 'center',
                }}
                onClick={async () => {
                  const promises = [goToRegistration()];
                  logAnalyticsOfferEngagement &&
                    promises.push(
                      logAnalyticsOfferEngagement(
                        OfferType.SUMMER24,
                        AnalyticsEngagementId.DESCRIPTION_SIGN_UP_CLICK
                      )
                    );
                  await Promise.all(promises);
                }}
              >
                Join FareDrop
                <br />
                to claim your bundle!
              </IonButton>
            </IonRow>
          </IonCol>
          <IonCol
            size="12"
            sizeXl="6"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                maxWidth: '600px',
                paddingLeft: '15px',
                paddingRight: '15px',
              }}
            >
              <img
                src={`${getAssetsBaseUrl()}/kara-and-nate/summer24/grass2.jpg`}
                style={{ borderRadius: '5px' }}
              ></img>
            </div>
          </IonCol>
        </IonRow>
        <AsSeenIn />
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.SUMMER24,
              'how_it_works'
            )
          }
        >
          <HowItWorksSection sx={{ paddingTop: 0 }} />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(OfferType.SUMMER24, 'deals')
          }
        >
          <HomePageDealsSection
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.SUMMER24,
                engagementId
              );
            }}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.SUMMER24,
              'subscription_plans'
            )
          }
        >
          {!isMediumScreenSizeOrSmaller && (
            <SubscriptionPlansTable onSelect={goToRegistration} />
          )}
          {isMediumScreenSizeOrSmaller && (
            <SubscriptionPlansSection
              onSelect={goToRegistration}
              logAnalyticsEngagement={async (engagementId) => {
                await logAnalyticsOfferEngagement(
                  OfferType.SUMMER24,
                  engagementId
                );
              }}
              reverse={isSmallScreenSizeOrSmaller}
              hideBillingTextSection={(plan) =>
                isSmallScreenSizeOrSmaller && plan === FareDropPlan.Limited
              }
            />
          )}
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.SUMMER24,
              'social_proof'
            )
          }
        >
          <SocialProofSection
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.SUMMER24,
                engagementId
              );
            }}
          />
        </TrackVisibility>
        {/* TODO: UPdate */}
        <div id="giveaway-legal-disclaimer" style={{ paddingTop: '2em' }}>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsOfferPageSectionView(
                OfferType.SUMMER24,
                'legal_disclaimer'
              )
            }
          >
            <GiveawayLegalDisclaimer />
          </TrackVisibility>
        </div>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(OfferType.SUMMER24, 'footer')
          }
        >
          <Footer
            bgColor="secondary"
            isVisible={true}
            onLinkPress={async (linkId) => {
              await logAnalyticsOfferEngagement(OfferType.SUMMER24, linkId);
            }}
          />
        </TrackVisibility>
      </IonContent>
      <PromotionFreebieModal
        selectedPlan={promotionFreebieModalPlan}
        setIsModalActive={() => {
          setPromotionFreebieModalPlan(undefined);
        }}
        onSubmit={handleNewSubscription}
      />
    </IonPage>
  );
};

export default GiveawayOffer;
