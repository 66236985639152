import { IonButton, IonCol, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import './../theme/HeroSection.css';
import './../theme/util.css';
import './../theme/PromotionHero.css';
import { Moment } from 'moment';
import moment from 'moment';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  title: string;
  subTitle: string;
  heroImageSrc: string;
  onGetStarted: () => Promise<void>;
  onCreatedByTravelExperts: () => Promise<void>;
  onRulesClick: () => Promise<void>;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  expiration?: Moment;
}

const PromotionHero: React.FC<ContainerProps> = ({
  title,
  subTitle,
  heroImageSrc,
  onGetStarted,
  onCreatedByTravelExperts,
  onRulesClick,
  logAnalyticsEngagement,
  expiration,
}) => {
  return (
    <IonRow
      style={{ height: '100%', display: 'flex' }}
      className="promotion-hero-container-responsive"
    >
      <img
        style={{
          position: 'absolute',
          top: '0px',
          objectFit: 'cover',
          zIndex: -1,
          width: '100%',
          height: '100%',
        }}
        src={heroImageSrc}
      />
      {expiration && moment().isAfter(expiration) && (
        <div
          style={{
            position: 'absolute',
            marginTop: '1em',
            right: '1em',
            background: 'var(--ion-color-primary)',
            padding: '10px 15px',
            borderRadius: 15,
            color: 'white',
          }}
        >
          Offer expired: {expiration.format('MMMM DD, YYYY')}
        </div>
      )}
      <IonCol style={{ paddingLeft: '1em', paddingRight: '1em' }}>
        <div
          className="main-titles-container promotion-hero-title-container-responsive promotion-hero-title-container"
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            maxWidth: '575px',
            height: 'inherit',
          }}
        >
          <h1
            className="main-title title-font promotion-header-title-responsive"
            style={{ lineHeight: '1.25em' }}
          >
            {title}
          </h1>
          <h2 className="main-subtitle gray promotion-header-subtitle-responsive">
            {subTitle}
          </h2>
          <IonRow className="get-started-now-button-row">
            <IonButton
              className="get-started-button get-started-button-hero mb-1-5"
              style={{ position: 'absolute' }}
              onClick={async () => {
                const promises = [onGetStarted()];
                logAnalyticsEngagement &&
                  promises.push(
                    logAnalyticsEngagement(
                      AnalyticsEngagementId.HERO_GET_STARTED
                    )
                  );
                await Promise.all(promises);
              }}
            >
              Get started now
            </IonButton>
            <img src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`} />
          </IonRow>
          <IonRow
            className="stats-summary"
            style={{ marginTop: '1em', width: '100%' }}
          >
            <IonCol
              sizeLg="3"
              className="right-border stats-summary-col"
              style={{ paddingRight: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0 promotion-hero-status-summary-text-responsive">
                <span className="primary-color">$450</span> saved per flight on
                average
              </h3>
            </IonCol>
            <IonCol
              sizeLg="3"
              className="stats-summary-col right-border"
              style={{ paddingRight: '10px', paddingLeft: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0">
                <span className="primary-color">Hundreds</span> of ⭐⭐⭐⭐⭐
                reviews
              </h3>
            </IonCol>
            <IonCol
              sizeLg="3"
              className="stats-summary-col"
              style={{ paddingLeft: '10px' }}
            >
              <h3 className="light-gray medium-small-text m-0 promotion-hero-status-summary-text-responsive">
                Created by travel experts{' '}
                <span
                  className="primary-color"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await onCreatedByTravelExperts();
                    logAnalyticsEngagement &&
                      (await logAnalyticsEngagement(
                        AnalyticsEngagementId.HERO_KARA_AND_NATE
                      ));
                  }}
                >
                  Kara and Nate
                </span>
              </h3>
            </IonCol>
          </IonRow>
          <IonRow
            style={{ marginTop: '2em' }}
            className="promotion-header-rules-link-responsive"
          >
            <div
              className="sweepstakes-no-purchase-text sweepstakes-no-purchase-text-responsive"
              style={{
                fontFamily: 'nexa',
                cursor: 'pointer',
              }}
              onClick={onRulesClick}
            >
              NO PURCHASE NECESSARY TO ENTER OR WIN.
              <br />
              See official sweepstakes rules below for details.
            </div>
          </IonRow>
        </div>
      </IonCol>
      <IonCol size="6" className="phone-col-container"></IonCol>
    </IonRow>
  );
};

export default PromotionHero;
