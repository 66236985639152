import { FC } from 'react';
import { useState } from 'react';

import PasswordRecovery from '../components/PasswordRecovery';
import PasswordReset from '../components/PasswordReset';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';

import './../theme/LoginPage.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useAuth from '../hooks/auth';
import usePresentToast from '../hooks/presentToast';

enum PasswordRecoveryStep {
  PasswordRecovery = 'Password Recovery',
  PasswordReset = 'Password Reset',
}

const PasswordRecoveryPage: FC = () => {
  const queryParams = new URLSearchParams(location.search);
  const queryParamEmail = queryParams.get('email');
  const queryParamStep = queryParams.get('step');
  const { isLargeScreenSizeOrSmaller } = useDevice();

  // The URL can be used by Support to direct a customer directly to the
  // "enter verification code" screen
  // https://www.faredrop.com/password-recovery?step=reset
  const [step, setStep] = useState<PasswordRecoveryStep>(
    queryParamStep != null && queryParamStep === 'reset'
      ? PasswordRecoveryStep.PasswordReset
      : PasswordRecoveryStep.PasswordRecovery
  );

  const [resetPasswordEmail, setResetPasswordEmail] = useState<
    string | undefined
  >(undefined);
  const [resentCode, setResentCode] = useState(false);
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { sendVerificationCode } = useAuth();
  const { presentError } = usePresentToast();
  const [emailSent, setEmailSent] = useState(false);

  const onRecoverPassword = (email: string) => {
    setResetPasswordEmail(email);
    setStep(PasswordRecoveryStep.PasswordReset);
  };

  const onVerifyAndResetPasswordEmailSent = (email: string) => {
    setEmailSent(true);
    setResetPasswordEmail(email);
  };

  const emailSubject = encodeURIComponent('Verification Code Trouble');
  const emailBody = encodeURIComponent(
    `Hi, I am not receiving any verification codes for FareDrop. Could you assist me? My email is ${
      queryParamEmail ?? resetPasswordEmail
    }`
  );
  const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

  const subheader = emailSent
    ? `If we find an account associated with ${resetPasswordEmail}, we will send an email for you to finish resetting your password.`
    : resetPasswordEmail
    ? `We sent a code to ${resetPasswordEmail}. Please enter the six digit code in that email.`
    : `We'll send a code to your email address`;

  const stepComponent: JSX.Element =
    step === PasswordRecoveryStep.PasswordRecovery ? (
      <PasswordRecovery
        initEmail={
          queryParamEmail != null
            ? decodeURIComponent(queryParamEmail)
            : undefined
        }
        onRecoverPassword={onRecoverPassword}
        onVerifyAndResetPasswordEmailSent={onVerifyAndResetPasswordEmailSent}
      />
    ) : (
      <PasswordReset
        onPasswordReset={() => {
          goWithStickyParamsLocation({
            pathname: '/login',
            search: resetPasswordEmail
              ? `?email=${encodeURIComponent(resetPasswordEmail)}`
              : undefined,
          });
        }}
        email={resetPasswordEmail}
      />
    );

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          padding: '15px 40px',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader menuHidden={true} showBackToHome={true} />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            showBackToHome={true}
            ctaHidden={true}
          />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow className="row-vertical-align" style={{ padding: '2em' }}>
          <IonCol sizeXs="12" sizeLg="4">
            <h1
              className="title-font"
              style={{ textAlign: 'center', fontSize: 24 }}
            >
              {step}
            </h1>
            <h2 style={{ fontSize: 16 }}>{subheader}</h2>
            {step === PasswordRecoveryStep.PasswordReset && (
              <p
                className={!resentCode ? 'pointer-on-hover' : ''}
                style={{
                  textAlign: 'center',
                  margin: '1em 0 2em',
                  color: resentCode ? 'inherit' : 'var(--ion-color-primary)',
                }}
                onClick={async () => {
                  if (!resentCode) {
                    try {
                      if (!resetPasswordEmail) {
                        throw new Error('Missing email');
                      }
                      await sendVerificationCode(
                        'password',
                        resetPasswordEmail
                      );
                      setResentCode(true);
                    } catch (err) {
                      setResentCode(false);
                      presentError('Error: Please try again');
                    }
                  }
                }}
              >
                {resentCode ? 'Code sent' : 'Resend code'}
              </p>
            )}
            {emailSent || <hr />}
            {stepComponent}
            {resentCode && (
              <IonRow>
                <p
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    fontFamily: 'nexa',
                  }}
                >
                  Not receiving our verification codes? Click{' '}
                  <a href={errorMailTo} style={{ fontFamily: 'nexa-bold' }}>
                    here
                  </a>{' '}
                  to contact support
                </p>
              </IonRow>
            )}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default PasswordRecoveryPage;
