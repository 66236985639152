import { IonSpinner } from '@ionic/react';

interface ContainerProps {
  size?: string;
  sx?: React.CSSProperties;
  color?: string;
}

const Loading: React.FC<ContainerProps> = ({ size, sx, color }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        ...sx,
      }}
    >
      <IonSpinner
        name="crescent"
        style={{
          margin: 'auto',
          width: size ?? '60px',
          height: size ?? '60px',
          opacity: 0.3,
        }}
        color={color ?? 'primary'}
      />
    </div>
  );
};

export default Loading;
