// cspell:ignore modestbranding
import { useRef, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useDevice } from '../hooks/useDevice';
import TrackVisibility from './TrackVisibility';

const ScrollYouTube: React.FC<YouTubeProps> = (props) => {
  const youtube = useRef<YouTube | null>(null);
  const [unmuted, setUnmuted] = useState(false);
  const { isMediumScreenSizeOrSmaller } = useDevice();

  return (
    <TrackVisibility
      onVisible={async () => {
        youtube.current?.getInternalPlayer().playVideo();
      }}
    >
      <div style={{ position: 'relative' }}>
        {/* https://developers.google.com/youtube/player_parameters */}
        <YouTube
          {...props}
          ref={youtube}
          opts={{
            ...props.opts,
            playerVars: {
              mute: 1,
              cc_load_policy: 1,
              rel: 0,
              modestbranding: 1,
              controls: 1,
              // iv_load_policy: 3,
              // showinfo: 0, //cspell:disable-line
              ...props.opts?.playerVars,
            },
          }}
        />
        {isMediumScreenSizeOrSmaller && !unmuted && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
            }}
            onClick={async () => {
              // Controls to unmute aren't displayed on mobile devices. Tapping the video on mobile will unmute and following taps will pause/start
              const player = youtube.current?.getInternalPlayer();
              if ((await player.isMuted()) && !unmuted) {
                await player.unMute();
                setUnmuted(true);
              }
            }}
          />
        )}
      </div>
    </TrackVisibility>
  );
};

export default ScrollYouTube;
