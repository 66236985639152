import { FareDropApiClient } from '@faredrop/graphql-sdk';
import { AllowedPath, CognitoValidationData } from '@faredrop/types';
import { FareDropRole, FareDropUser } from '@faredrop/types';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import { Hub, HubCapsule } from '@aws-amplify/core';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { isAuthorized } from '@faredrop/utilities';
import config from '../aws-exports';
import NewPasswordRequiredError from '../errors/newPasswordRequired';
import { useDevice } from '../hooks/useDevice';
import CognitoUserAccessor from '../wrappers/cognitoUserAccessor';
import useAnalyticsService from '../hooks/analytics';
import { AuthMethod } from './analyticsContext';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useFareDropPublicApiClient from '../hooks/faredropPublicApiClient';
import useKlaviyo from '../hooks/useKlaviyo';
import usePresentToast from '../hooks/presentToast';
import useLogError from '../hooks/logError';
import useGleam from '../hooks/useGleam';
import useStripeHook from '../hooks/useStripe';
import { stripTrailingSlash } from '../utilities/utils';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: FareDropUser;
  email?: string;
  password?: string;
  showNotificationModal?: boolean;
}

export interface AuthContextValue extends State {
  getIdToken: () => Promise<string>;
  initialize: (skipRedirect?: boolean) => Promise<void>;
  login: (
    email: string,
    password: string,
    newPassword?: string
  ) => Promise<FareDropUser>;
  signUp: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    token: string,
    retries: number,
    isV2Token?: boolean
  ) => Promise<string>;
  logout: (emitAnalyticsEvent?: boolean) => Promise<void>;
  passwordRecovery: (email: string) => Promise<void>;
  publicApiSendVerifyEmailAndResetPasswordEmail: (
    email: string
  ) => Promise<boolean>;
  sendDeleteAccountEmail: () => Promise<boolean>;
  verifyUserAndSetPassword: (
    email: string,
    asset: string,
    signature: string,
    ts: string,
    newPassword: string
  ) => Promise<void>;
  passwordChange: (user: CognitoUser, password: string) => Promise<void>;
  verifyAttribute: (
    email: string,
    code: string,
    password?: string
  ) => Promise<void>;
  sendVerificationCode: (attribute: string, email?: string) => Promise<void>;
  isEmailVerified: () => Promise<boolean>;
  refreshSession: () => Promise<FareDropUser | undefined>;
  currentUserHasSubscription: () => Promise<boolean>;
  currentUserHasPaidSubscription: () => Promise<boolean>;
  verifyPasswordAndDeleteAccount: (password: string) => Promise<boolean>;
  clearUser: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializePayload = {
  isAuthenticated: boolean;
  user?: FareDropUser;
};

type LoginPayload = {
  user?: FareDropUser;
  email?: string;
  password?: string;
};

enum AuthActionType {
  Initialize = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  SignUp = 'SIGNUP',
  Refresh = 'REFRESH',
}

type AuthAction = {
  type: AuthActionType;
  payload?: InitializePayload | LoginPayload;
};

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined,
};

const reducer = (state: State, action: AuthAction): State => {
  switch (action.type) {
    case AuthActionType.Initialize: {
      const payload: InitializePayload = action.payload as InitializePayload;
      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
    case AuthActionType.Login: {
      const payload: LoginPayload = action.payload as LoginPayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
        isAuthenticated: true,
      };
    }
    case AuthActionType.SignUp: {
      const payload: LoginPayload = action.payload as LoginPayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
        isAuthenticated: false,
      };
    }
    case AuthActionType.Logout: {
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: undefined,
        email: undefined,
      };
    }
    case AuthActionType.Refresh: {
      return state;
    }
  }
};

// Since we set the AuthProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  getIdToken: () => Promise.resolve(''),
  initialize: () => Promise.resolve(),
  login: () =>
    Promise.resolve({
      id: '',
      email: '',
      firstName: '',
      roles: [],
      isEmailVerified: false,
    }),
  logout: () => Promise.resolve(),
  signUp: () => Promise.resolve(''),
  passwordRecovery: () => Promise.resolve(),
  publicApiSendVerifyEmailAndResetPasswordEmail: () => Promise.resolve(true),
  sendDeleteAccountEmail: () => Promise.resolve(true),
  verifyUserAndSetPassword: () => Promise.resolve(),
  passwordChange: () => Promise.resolve(),
  verifyAttribute: () => Promise.resolve(),
  sendVerificationCode: () => Promise.resolve(),
  isEmailVerified: () => Promise.resolve(false),
  refreshSession: () => Promise.resolve(undefined),
  currentUserHasSubscription: () => Promise.resolve(false),
  currentUserHasPaidSubscription: () => Promise.resolve(false),
  verifyPasswordAndDeleteAccount: () => Promise.resolve(true),
  clearUser: () => undefined,
});

export const allowedUninitializedUrls = [
  AllowedPath.NAKED, // ''  // Necessary or you can't get to the landing page with our strip trailing backslash logic
  AllowedPath.BASE, // '/',
  AllowedPath.FAQ, // '/faq',
  AllowedPath.PASSWORD_RECOVERY, // '/password-recovery',
  AllowedPath.PASSWORD_RESET, // '/password-reset',
  AllowedPath.REFUND_POLICY, // '/refund-policy',
  AllowedPath.ERROR, // '/error',
  AllowedPath.OOPS, // '/oops',
  AllowedPath.REDIRECTING, // '/redirecting',
  AllowedPath.TERMS_OF_SERVICE, // '/terms-of-service',
  AllowedPath.PRIVACY_POLICY, // '/privacy-policy',
  AllowedPath.LOGOUT, // '/logout',
  AllowedPath.GET_STARTED_COMPLETE, // '/get-started/complete',
  AllowedPath.CONFIRMATION, // '/confirmation',
  AllowedPath.GIFT, // '/gift',
  AllowedPath.REDEEM, // '/redeem',
  AllowedPath.REDEEM_OFFER, // '/offer/redeem'
  AllowedPath.REDEEM_SUCCESS, // '/redeem/success',
  AllowedPath.MILES_AND_POINTS, // '/miles-and-points',
  AllowedPath.ABOUT, // '/about',
  AllowedPath.START_HERE, // '/start-here',
  AllowedPath.NEW_PLAN, // '/new-plan',
  AllowedPath.FREE_TRIAL, // '/offer/free-trial',
  AllowedPath.CONTEST_FEEDBACK_RULES, // '/feedback-contest',
  AllowedPath.CONTEST_MEET_KARA_AND_NATE, // '/meet-kara-and-nate-contest',
  AllowedPath.REVIEWS, // '/reviews',
  AllowedPath.UPGRADE, // '/upgrade'
  AllowedPath.WELCOME_BACK, // '/welcome-back'
  AllowedPath.REGISTER, // '/register'
  AllowedPath.DEAL_DETAILS, // '/deal'
];

export const allowedUninitializedBasePaths = ['/offer/'];

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const {
    logAnalyticsSignUp,
    logAnalyticsLogin,
    logAnalyticsLogout,
    logAnalyticsError,
    initializeAnalyticsUserId,
    isGoogleAnalyticsReady,
  } = useAnalyticsService();
  const { children } = props;
  const { isApp, isIOS } = useDevice();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { client } = useFareDropPublicApiClient();
  const { presentWarning, presentError } = usePresentToast();
  const { trackLoginKlaviyoEvent, trackLogoutKlaviyoEvent } = useKlaviyo();
  const { logError } = useLogError();
  const { trackGleamIdCognito } = useGleam();
  const { getFirstPromoterReferralId } = useStripeHook();

  useEffect(() => {
    initialize().catch(() =>
      presentError(
        'Failed to initialize account details - please refresh the page'
      )
    );
  }, []);

  // Set analytics user here since Analytics hook won't have access to user (AnalyticsProvider is a parent provider)
  useEffect(() => {
    if (state.isInitialized) {
      initializeAnalyticsUserId(state.user);
    }
  }, [state.user?.id, state.isInitialized]);

  // Configure auth events here since Analytics hook won't have access to user (AnalyticsProvider is a parent provider)
  useEffect(() => {
    const onAuthEvent = async (data: HubCapsule) => {
      switch (data.payload.event) {
        case 'signIn': {
          await logAnalyticsLogin(
            AuthMethod.COGNITO,
            data.payload.data?.attributes?.email
          );
          break;
        }
        case 'signUp': {
          await logAnalyticsSignUp(AuthMethod.COGNITO);
          break;
        }
        case 'signIn_failure': {
          await logAnalyticsError(
            'login',
            data.payload.message ? new Error(data.payload.message) : undefined
          );
          break;
        }
      }
    };
    Hub.listen('auth', onAuthEvent);
    return () => Hub.remove('auth', onAuthEvent);
  }, [isGoogleAnalyticsReady]);

  useEffect(() => {
    const updateLastMobileLogin = async () => {
      // Can't use FareDropApiClient hook in authContext since getIdToken is not ready yet
      const client = FareDropApiClient(getIdToken);
      await client.updateUserTrackingLastMobileLogin({
        deviceOS: isIOS ? 'ios' : 'android',
      });
    };

    if (state.isInitialized && state.user?.id) {
      trackGleamIdCognito(state.user.id);
      if (isApp) {
        updateLastMobileLogin().catch((err) => {
          logError(
            'Failed to update last mobile login',
            err && (err as Error).message
              ? (err as Error).message
              : 'Error or error message is undefined'
          ).catch((error) =>
            console.warn(
              'Failed to send error log to endpoint for updating last mobile login',
              error
            )
          );
        });
      }
    }
  }, [state.isInitialized, state.user?.id]);

  const initialize = async (skipRedirect?: boolean) => {
    try {
      Amplify.configure(config);
      const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
        await Auth.currentAuthenticatedUser()
      );
      const user = await cognitoUserAccessor.getFareDropUser();
      dispatch({
        type: AuthActionType.Login,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      dispatch({
        type: AuthActionType.Initialize,
        payload: {
          isAuthenticated: false,
          user: undefined,
        },
      });

      // Don't allow trailing "/"'s to prevent a user from accessing an unguarded page
      const normalizedPathName = stripTrailingSlash(window.location.pathname);

      if (
        !skipRedirect && // Ideally, routes should be added to the allowedUninitializedUrls, but the Get Started page has several "routes"
        window.location.pathname != AllowedPath.LOGIN &&
        !allowedUninitializedUrls.includes(normalizedPathName as AllowedPath) &&
        // We have a tricky scenario with deals dashboard (/deals) which is private and deal details (/deals/...) which is public
        // Since we are normalizing the path by removing trailing slashes, this should be safe to check for base paths
        !allowedUninitializedBasePaths.some((p) =>
          normalizedPathName.startsWith(p)
        )
      ) {
        const url = new URL(window.location.href);
        if (url.pathname !== AllowedPath.DEALS) {
          url.searchParams.set('redirect', url.pathname);
        }
        goWithStickyParamsLocation({
          pathname: '/login',
          search: url.searchParams.toString(),
        });
      }

      await logAnalyticsError('initializeAuth');
    }
  };

  const getIdToken = async () => {
    // Auth.currentSession automatically refreshes the accessToken and idToken if the tokens are expired and valid refreshToken presented.
    let session: CognitoUserSession;
    try {
      session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (err) {
      await logAnalyticsError('getIdToken');
    }

    return '';
  };

  const signUp = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    token: string,
    retries: number,
    isV2Token?: boolean
  ) => {
    try {
      const tokenData: { [key: string]: string | number } = {};
      tokenData[isV2Token ? 'recaptchaV2Token' : 'recaptchaToken'] = token;
      tokenData['retries'] = retries.toString();
      tokenData[CognitoValidationData.AutoConfirmUser] = 'true';

      const clientMetadata: { [key: string]: string } = {
        href: window.location.href,
        referrer: document.referrer,
      };

      const fp_referral_id = await getFirstPromoterReferralId();
      if (fp_referral_id) {
        clientMetadata['fp_referral_id'] = fp_referral_id;
      }

      const response = await Auth.signUp({
        username: email,
        password,
        validationData: tokenData,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
        clientMetadata,
      });

      dispatch({
        type: AuthActionType.SignUp,
        payload: {
          user: {
            email,
            firstName,
            lastName,
            id: response?.userSub, // We don't use this and we just signed up so we would have to await to get this back from Cognito if we cared
            roles: [],
            isEmailVerified: false,
          },
          password,
        },
      });

      return response?.userSub;
    } catch (error) {
      await logAnalyticsError('signup');
      throw error;
    }
  };

  const login = async (
    email: string,
    password: string,
    newPassword?: string
  ): Promise<FareDropUser> => {
    try {
      const signInResponse = await Auth.signIn(email, password);

      // Respond to challenges
      if (signInResponse.challengeName) {
        // signInResponse is type CognitoUser, but CognitoUser doesn't have defined properties for challenges...

        switch (signInResponse.challengeName) {
          case 'NEW_PASSWORD_REQUIRED': {
            if (!newPassword) {
              throw new NewPasswordRequiredError(signInResponse);
            }

            await Auth.completeNewPassword(signInResponse, newPassword);

            break;
          }
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
          case 'SELECT_MFA_TYPE':
          case 'MFA_SETUP':
          case 'PASSWORD_VERIFIER':
          case 'CUSTOM_CHALLENGE':
          case 'DEVICE_SRP_AUTH':
          case 'DEVICE_PASSWORD_VERIFIER':
          case 'ADMIN_NO_SRP_AUTH':
          default: {
            throw new Error(
              `Failed to login due to ${signInResponse.challengeName} challenge`
            );
          }
        }
      }

      const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
        signInResponse as CognitoUser
      );
      const user = await cognitoUserAccessor.getFareDropUser();

      if (isAuthorized(user, [FareDropRole.Profile])) {
        trackLoginKlaviyoEvent(email);

        dispatch({
          type: AuthActionType.Login,
          payload: {
            user,
          },
        });
      } else {
        await logout();
        throw new Error('Unauthorized');
      }

      return user;
    } catch (err) {
      const error = err as Error;
      if (error.message !== 'Incorrect username or password.') {
        await logAnalyticsError('login');
      }
      throw error;
    }
  };

  const clearUser = () => {
    dispatch({
      type: AuthActionType.Logout,
    });
  };

  const logout = async (emitAnalyticsEvent?: boolean): Promise<void> => {
    try {
      await Auth.signOut();

      trackLogoutKlaviyoEvent();

      if (emitAnalyticsEvent) {
        await logAnalyticsLogout();
      }
    } catch (error) {
      console.warn('Failed to logout server side');
      await logAnalyticsError('logout');
    } finally {
      dispatch({
        type: AuthActionType.Logout,
      });
    }
  };

  const passwordRecovery = async (email: string): Promise<void> => {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      await logAnalyticsError('passwordRecovery');
      throw error;
    }
  };

  const verifyUserAndSetPassword = async (
    email: string,
    asset: string,
    signature: string,
    ts: string,
    newPassword: string
  ) => {
    try {
      await client.verifyUserAndSetPassword({
        email,
        asset,
        signature,
        ts,
        newPassword,
      });
    } catch (error) {
      await logAnalyticsError('verifyUserAndSetPassword');
      presentWarning(
        'Password reset failed. Please contact support at team@faredrop.com'
      );
      goWithStickyParamsLocation({ pathname: AllowedPath.PASSWORD_RECOVERY });
    }
  };

  const publicApiSendVerifyEmailAndResetPasswordEmail = async (
    email: string
  ) => {
    try {
      return (await client.verifyEmailAndResetPassword({ email })).data
        .verifyEmailAndResetPassword.success;
    } catch (error) {
      await logAnalyticsError('publicApiSendVerifyEmailAndResetPasswordEmail');
      throw error;
    }
  };

  const sendDeleteAccountEmail = async () => {
    try {
      const client = FareDropApiClient(getIdToken);
      return (await client.sendDeleteAccountEmail()).data.sendDeleteAccountEmail
        .success;
    } catch (error) {
      await logAnalyticsError('sendDeleteAccountEmail');
      throw error;
    }
  };

  const verifyPasswordAndDeleteAccount = async (password: string) => {
    try {
      const client = FareDropApiClient(getIdToken);
      return (
        await client.verifyPasswordAndDeleteAccount({
          password,
        })
      ).data.verifyPasswordAndDeleteAccount.success;
    } catch (error) {
      await logAnalyticsError('verifyPasswordAndDeleteAccount');
      throw error;
    }
  };

  const passwordChange = async (
    user: CognitoUser,
    password: string
  ): Promise<void> => {
    try {
      const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
        await Auth.completeNewPassword(user, password)
      );
      dispatch({
        type: AuthActionType.Login,
        payload: {
          user: await cognitoUserAccessor.getFareDropUser(),
        },
      });
    } catch (error) {
      await logAnalyticsError('passwordChange');
      throw error;
    }
  };

  const verifyAttribute = async (
    attribute: string,
    code: string
  ): Promise<void> => {
    try {
      if (!state.isAuthenticated) {
        throw new Error('User is not authenticated');
      }
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(cognitoUser, attribute, code);

      // Update the JWT
      await refreshSession();

      // Roles are set after confirmation, so we need to update the auth user state
      const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
        cognitoUser as CognitoUser
      );
      const fareDropUser = await cognitoUserAccessor.getFareDropUser();
      if (isAuthorized(fareDropUser, [FareDropRole.Profile])) {
        dispatch({
          type: AuthActionType.Login,
          payload: {
            user: fareDropUser,
          },
        });
      }
    } catch (err) {
      // We want to ignore this error which pretty much gets thrown by Cognito when we verify an
      // existing user's email. Since they are already CONFIRMED in the system, Cognito barfs when
      // it goes to update that. But this should not cause our code to crash. We can ignore it as
      // the user already being CONFIRMED is expected behavior ...
      if (
        (err as Error).message !==
        'User cannot be confirmed. Current status is CONFIRMED'
      ) {
        await logAnalyticsError('verifyAttribute');
        throw err;
      }
    }

    dispatch({
      type: AuthActionType.Login,
      payload: {
        isAuthenticated: true,
      },
    });
  };

  const isEmailVerified = async () => {
    try {
      const userInfo = await Auth.currentUserInfo();

      if (!userInfo || userInfo == null) {
        throw new Error('No user info found');
      }
      return userInfo.attributes?.email_verified;
    } catch (error) {
      await logAnalyticsError('checkIsEmailVerified');
      throw error;
    }
  };

  const sendVerificationCode = async (
    attribute: string,
    email?: string
  ): Promise<void> => {
    try {
      if (attribute === 'password') {
        if (!email) {
          throw new Error('Must enter email to reset password');
        }
        return await Auth.forgotPassword(email);
      } else {
        return await Auth.verifyCurrentUserAttribute(attribute);
      }
    } catch (err) {
      await logAnalyticsError(
        `sendVerificationCode - ${attribute}`,
        err as Error
      );
      return Promise.reject();
    }
  };

  // currentUserHasSubscription and currentUserHasPaidSubscription
  const currentUserHasSubscription = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
      cognitoUser as CognitoUser
    );
    const user = await cognitoUserAccessor.getFareDropUser();

    return isAuthorized(user, [
      FareDropRole.Limited,
      FareDropRole.Global,
      FareDropRole.Pro,
    ]);
  };

  const currentUserHasPaidSubscription = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoUserAccessor: CognitoUserAccessor = new CognitoUserAccessor(
      cognitoUser as CognitoUser
    );
    const user = await cognitoUserAccessor.getFareDropUser();

    return isAuthorized(user, [FareDropRole.Global, FareDropRole.Pro]);
  };

  // https://github.com/aws-amplify/amplify-js/issues/2560
  const refreshSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      return await new Promise<FareDropUser | undefined>((resolve, reject) => {
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          async (err: Error) => {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const cognitoUserAccessor: CognitoUserAccessor =
              new CognitoUserAccessor(cognitoUser as CognitoUser);
            const user = await cognitoUserAccessor.getFareDropUser();
            dispatch({
              type: AuthActionType.Initialize,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
            if (err) {
              await logAnalyticsError('refreshSession');
              reject();
            } else {
              resolve(user);
            }
          }
        );
      });
    } catch (err) {
      if (err !== 'The user is not authenticated') {
        await Promise.all([logAnalyticsError('refreshSession'), logout(true)]);
      } else {
        // User is not authenticated during logout so don't log analytics event again
        await logout();
      }
    }
    return undefined;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        getIdToken,
        initialize,
        login,
        signUp,
        logout,
        passwordRecovery,
        publicApiSendVerifyEmailAndResetPasswordEmail,
        sendDeleteAccountEmail,
        verifyUserAndSetPassword,
        passwordChange,
        verifyAttribute,
        sendVerificationCode,
        isEmailVerified,
        refreshSession,
        currentUserHasSubscription,
        currentUserHasPaidSubscription,
        verifyPasswordAndDeleteAccount,
        clearUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
