import { IonImg, IonIcon, IonRow, IonCol } from '@ionic/react';
import { star } from 'ionicons/icons';
import { SocialProofCardInfo } from '../types/types';
import { formatAirportLocation } from '../utilities/deals-utilities';
import './../theme/SocialProofCard.css';
import './../theme/util.css';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  card: SocialProofCardInfo;
}

export const SocialProofCard: React.FC<ContainerProps> = ({ card }) => {
  const nameFormatted = (card: SocialProofCardInfo) =>
    (card.user.name ?? `@${card.user.username}`)?.trim();
  const commentFormatted = (card: SocialProofCardInfo) => `"${card.comment}"`;
  const priceFormatted = (price: number) => `$${price}`;
  return (
    <IonCol className="social-proof-card-col">
      <div
        className="bg-white image-and-title-section"
        style={{
          boxShadow: '0px 5px 15px rgba(0, 0, 0, .2)',
        }}
      >
        <IonRow
          className="pt-1 row-vertical-align"
          style={{
            padding: 15,
          }}
        >
          <IonRow style={{ padding: '5px 0px' }}>
            <p className="m-0 ml--5" style={{ fontSize: 24 }}>
              <small style={{ fontSize: 14, verticalAlign: 'top' }}>$</small>
              {card.dealPrice}
            </p>
            <p
              className="m-0 ml--5 line-through"
              style={{
                opacity: 0.15,
                lineHeight: '1.5em',
                marginLeft: 12,
                fontSize: 14,
              }}
            >
              {priceFormatted(card.originalPrice)}
            </p>
          </IonRow>
          <IonRow style={{ maxWidth: '100%', padding: '5px 0px' }}>
            <p
              className="medium-text gray m-0 ml--5"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: 14,
              }}
            >
              {formatAirportLocation(card)}
            </p>
          </IonRow>
        </IonRow>
        <IonImg
          src={`${getAssetsBaseUrl()}/iatas/${card.destinationIATA}.jpg`}
          className="social-proof-image"
          style={{ height: 250 }}
        />
      </div>
      <IonRow className="mt-1">
        {
          // Generates a array [0, ..., props.card.rating] which is used to create
          // X number of star icons where X = props.card.rating
          Array.from({ length: card.rating }, (_, i) => i).map((index) => {
            return <IonIcon key={index} icon={star} />;
          })
        }
      </IonRow>
      <p
        style={{
          fontSize: 14,
          lineHeight: '1.5em',
          textAlign: 'left',
          fontFamily: 'nexa',
          marginBottom: 0,
        }}
      >
        {commentFormatted(card)}
      </p>
      <IonRow className="row-vertical-align user-section">
        <p style={{ fontSize: 14, opacity: 0.5, marginTop: 20 }}>
          - {nameFormatted(card)}
        </p>
      </IonRow>
    </IonCol>
  );
};
