import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { AllowedPath } from '@faredrop/types';

import useAuth from './auth';
import { stripTrailingSlash } from '../utilities/utils';
import {
  allowedUninitializedBasePaths,
  allowedUninitializedUrls,
} from '../contexts/authContext';

export const useDynamicBanner = () => {
  const location = useLocation();
  const [dynamicBannerText, setDynamicBanner] = useState<string>();
  const { isAuthenticated, isInitialized } = useAuth();

  useEffect(() => {
    if (isInitialized) {
      const normalizedPathName = stripTrailingSlash(location.pathname);

      const queryParams = new URLSearchParams(location.search);
      const banner = queryParams.get('banner');
      if (
        banner &&
        (!isAuthenticated ||
          allowedUninitializedUrls.includes(
            normalizedPathName as AllowedPath
          ) ||
          allowedUninitializedBasePaths.some((p) =>
            normalizedPathName.startsWith(p)
          ))
      ) {
        setDynamicBanner(banner);
      } else {
        setDynamicBanner(undefined);
      }
    }
  }, [location.search, isInitialized, isAuthenticated]);

  return dynamicBannerText;
};
