import { Color } from '@ionic/core';
import { IonSearchbar } from '@ionic/react';
import { debounce } from 'lodash';
import { Ref, forwardRef, useRef, useState } from 'react';

import '../theme/SearchBarWithResults.css';

import { DropdownOptionWithSubtext } from '../types/types';
import DropdownOptionsContainer from './DropdownOptionsContainer';
import { useDevice } from '../hooks/useDevice';
import { useContainerDimensions } from '../hooks/useContainerDimensions';

interface ContainerProps {
  ref?: Ref<HTMLIonSearchbarElement>;
  disabled?: boolean;
  placeholder: string;
  badge?: React.ReactElement;
  style?: React.CSSProperties;
  expandedStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  className?: string;
  color?: Color & string;
  searchIcon?: string;
  cancelButtonIcon?: string;
  hideNoResultOriginMessage?: boolean;
  clearSearchTextOnSubmit?: boolean;
  fullScreenForExtraSmallScreens?: boolean;
  onChange: (
    searchText?: string
  ) => Promise<DropdownOptionWithSubtext[] | undefined>; // Return list of results to display in dropdown
  onSelect: (
    selectedOption: DropdownOptionWithSubtext
  ) => Promise<{ displayText?: string; value?: string } | undefined>; // Returns text to display in searchbox
  onSubmit: (searchText?: string) => Promise<void>;
  withBadge?: (option: string) => boolean;
}

const SearchBarWithResults: React.FC<ContainerProps> = forwardRef(
  (
    {
      disabled,
      placeholder,
      badge,
      style,
      className,
      expandedStyle,
      dropdownStyle,
      color,
      searchIcon,
      cancelButtonIcon,
      hideNoResultOriginMessage,
      clearSearchTextOnSubmit,
      fullScreenForExtraSmallScreens,
      onChange,
      onSelect,
      onSubmit,
      withBadge,
    },
    forwardedSearchbarRef
  ) => {
    const { isExtraSmallScreenSize } = useDevice();

    const lastSelectedValueRef = useRef<string>();
    const searchTextRef = useRef<string>(); // Sometimes we need to know immediately what the text is instead of having to wait for state to update
    const optionSelectedRef = useRef(false); // Flag to help with state when dealing with searchbar onBlur and dropdown option onClick timing (onBlur is triggered first, but it's useful to know if an option was clicked)

    const [searchText, setSearchText] = useState<string>();
    const [resultsExpanded, setResultsExpanded] = useState(false);
    const [results, setResults] = useState<DropdownOptionWithSubtext[]>();
    const [searchBarSelected, setSearchBarSelected] = useState(false);

    if (typeof forwardedSearchbarRef === 'function') {
      throw new Error('SearchBarWithResults does no support ref functions!');
    }

    const internalSearchbarRef = useRef<HTMLIonSearchbarElement>(null);
    const searchBarRef = forwardedSearchbarRef ?? internalSearchbarRef;
    const { width: searchBarWidth } = useContainerDimensions(searchBarRef);

    const handleChange = async (text?: string) => {
      searchTextRef.current = text;
      setSearchText(text ?? undefined);
      await debounceChange(text);
    };

    const debounceChange = debounce(async (text?: string) => {
      const result = await onChange(text);
      if (text === searchTextRef.current) {
        // Only show results if it's the most recent query
        setResultsExpanded(text != null && text !== '');
        setResults(result);
      }
    }, 200);

    // Fill screen for extra small devices
    let calculatedWidth: string | undefined = undefined;
    if (isExtraSmallScreenSize && fullScreenForExtraSmallScreens) {
      calculatedWidth = '100vw';
    } else if (searchBarWidth) {
      calculatedWidth = `${searchBarWidth}px`;
    }

    return (
      <>
        <IonSearchbar
          ref={searchBarRef}
          className={
            className
              ? className
              : searchBarSelected
              ? `${
                  searchIcon === 'undefined'
                    ? 'searchbar-with-results-no-search-icon'
                    : 'searchbar-with-results'
                } searchbar-with-results-selected`
              : `${
                  searchIcon === 'undefined'
                    ? 'searchbar-with-results-no-search-icon'
                    : 'searchbar-with-results'
                }`
          }
          style={{
            borderBottomLeftRadius: resultsExpanded ? '0px' : undefined,
            borderBottomRightRadius: resultsExpanded ? '0px' : undefined,
            ...style,
            ...(resultsExpanded ? expandedStyle ?? {} : {}),
          }}
          value={searchText}
          placeholder={placeholder}
          showClearButton="focus"
          disabled={disabled}
          // NOTE: There is a bug with Ionic searchIcon === 'undefined'
          // 'undefined' is supposed to hide the search icon, which it does if 'undefined' is the initial value.
          // However, Ionic throws when it is initially a different value, and then is changed to 'undefined'
          // So instead, we are using css classes to mimic the same behavior
          searchIcon={searchIcon === 'undefined' ? undefined : searchIcon}
          cancelButtonIcon={cancelButtonIcon}
          color={color}
          onFocus={async () => {
            setSearchBarSelected(true);
            const result = await onChange(searchText);
            if (result) {
              setResults(result);
              setResultsExpanded(true);
            }
          }}
          onIonBlur={() => {
            setSearchBarSelected(false);

            // HACK ALERT - if we set expanded to false immediately, the onClick listener in the options container won't fire because we are removing the element before the event can propagate!
            // But the options onClick listener will fire if we add a little delay..
            // We also only need to clear results if an option in the drop down wasn't selected
            setTimeout(async () => {
              if (
                !optionSelectedRef.current &&
                (!searchText || searchText === '')
              ) {
                await onSubmit();
              } else if (
                lastSelectedValueRef.current &&
                lastSelectedValueRef.current !== searchText
              ) {
                // If changes were made to the search text, but nothing was selected, reset the value to the last selected value
                setSearchText(lastSelectedValueRef.current);
              }

              // Due to timing issues with onBlur function on the search element and onClick on the dropdown options element, we need to reset a flag here
              if (optionSelectedRef.current) {
                optionSelectedRef.current = false;
              }

              if (clearSearchTextOnSubmit) {
                lastSelectedValueRef.current = undefined;
                setSearchText(undefined);
              }

              setResultsExpanded(false);
            }, 150);
          }}
          onIonInput={async (e) => {
            await handleChange(e.detail.value ?? undefined);
          }}
          onIonClear={async () => {
            await handleChange();
          }}
        />
        <DropdownOptionsContainer
          sx={{
            position: 'absolute',
            maxWidth:
              (isExtraSmallScreenSize && fullScreenForExtraSmallScreens) ||
              calculatedWidth
                ? undefined
                : '500px',
            width: calculatedWidth,
            zIndex: 1000000000000,
            ...dropdownStyle,
          }}
          hideNoResultOriginMessage={hideNoResultOriginMessage}
          expanded={resultsExpanded}
          setExpanded={(expanded) => {
            setResultsExpanded(expanded);
          }}
          options={results}
          badge={badge}
          withBadge={withBadge}
          setSelectedValue={async (selectedValue) => {
            // Due to timing issues with onBlur function on the search element and onClick on the result options element, we need to set a flag here
            optionSelectedRef.current = true;

            const result = await onSelect(selectedValue);
            const displayText = result?.displayText ?? result?.value;
            const value = result?.value ?? result?.displayText;

            lastSelectedValueRef.current = clearSearchTextOnSubmit
              ? undefined
              : displayText;
            setSearchText(clearSearchTextOnSubmit ? undefined : displayText);
            await onSubmit(value);
          }}
        />
      </>
    );
  }
);

export default SearchBarWithResults;
