import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

// We have created a provider to maintain the state of the selected tab
// Given that we have multiple pages mapped to a single tab, maintaining
// the state of the selected tab with a provider/context was the only
// way I could think of to do this.

// In order to make sure that the right tab is selected, any component
// that should be shown under a selected tab must call updateTab on
// ionViewDidEnter

export enum Tab {
  DEALS = 'deals',
  SETTINGS = 'settings',
  ACCOUNT = 'account',
  DAILY_DROP = 'daily-drop',
  PERKS = 'perks',
}

interface State {
  isInitialized: boolean;
  selectedTab: Tab;
}

export interface TabContextValue extends State {
  updateSelectedTab: (tab?: Tab) => Promise<void>;
}

interface TabProviderProps {
  children: ReactNode;
}

type InitializePayload = {
  isInitialized: boolean;
  selectedTab: Tab;
};

type UpdatePayload = {
  tab: Tab;
};

enum TabActionType {
  Initialize = 'INITIALIZE',
  Update = 'UPDATE',
}

type TabAction = {
  type: TabActionType;
  payload?: InitializePayload | UpdatePayload;
};

const initialState: State = {
  isInitialized: false,
  selectedTab: Tab.DEALS,
};

const reducer = (state: State, action: TabAction): State => {
  switch (action.type) {
    case TabActionType.Initialize: {
      const payload: InitializePayload = action.payload as InitializePayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
    case TabActionType.Update: {
      const payload: UpdatePayload = action.payload as UpdatePayload;

      return {
        ...state,
        ...payload,
        isInitialized: true,
      };
    }
  }
};

const TabContext = createContext<TabContextValue>({
  ...initialState,
  updateSelectedTab: () => Promise.resolve(),
});

export const TabProvider: FC<TabProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    const initSelectedTab = (): void => {
      dispatch({
        type: TabActionType.Initialize,
        payload: {
          isInitialized: true,
          selectedTab: history.location.pathname.slice(1).split('/')[0] as Tab,
        },
      });
    };

    initSelectedTab();
  }, []);

  const updateSelectedTab = async (tab?: Tab) => {
    dispatch({
      type: TabActionType.Update,
      payload: {
        isInitialized: true,
        selectedTab:
          tab ?? (history.location.pathname.slice(1).split('/')[0] as Tab),
      },
    });
  };

  return (
    <TabContext.Provider
      value={{
        ...state,
        updateSelectedTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export default TabContext;
