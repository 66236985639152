import { useEffect, useState } from 'react';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';

import {
  isLimitedPlan,
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  stripePlanIDToFareDropPlans,
} from '@faredrop/utilities';

import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/util.css';
import useStripeHook from '../hooks/useStripe';
import { SetupIntentReason } from '../components/PaymentForm';
import usePresentToast from '../hooks/presentToast';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { FareDropPlan, SubscriptionPlan } from '@faredrop/graphql-sdk';
import {
  buildFrontendSubscriptionPlans,
  isFrontendSubscriptionPromotion,
} from '../utilities/plans-utilities';
import SubscriptionPlansSection from '../components/SubscriptionPlansSection';
import HidingHeader from '../components/HidingHeader';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useHidingHeader } from '../hooks/useHidingHeader';
import SocialProofSection from '../components/SocialProofSection';
import Footer from '../components/Footer';
import useAuth from '../hooks/auth';
import { useCodeQueryParam } from '../hooks/useCodeQueryParam';
import PromotionFreebieModal from '../components/PromotionFreebieModal';

interface ContainerProps {
  onMenuClickHandler: () => void;
  title: string;
  subtitle: string;
  notYouRedirectPath: string;
  setupIntentReason: SetupIntentReason;
  hidePlansOverride?: FareDropPlan[]; // This page automatically hides plans lower than the currentPlan (user's plan or query param)
}

const UnauthenticatedPurchasePage: React.FC<ContainerProps> = ({
  onMenuClickHandler,
  title,
  subtitle,
  notYouRedirectPath,
  setupIntentReason,
  hidePlansOverride,
}) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const userState = useUser();
  const noInternet = userState.timeout;
  const { createUpgradeCheckoutSessionPublic, createCheckoutSessionPublic } =
    useStripeHook();
  const { presentError } = usePresentToast();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const plans = buildFrontendSubscriptionPlans();
  const dynamicBannerContent = useDynamicBanner();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { user } = useUser();
  const { isInitialized, isAuthenticated } = useAuth();
  const { couponQueryParam } = useCodeQueryParam();

  const [email, setEmail] = useState<string>();
  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlan>();
  const [hidePlans, setHidePlans] = useState<FareDropPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();

  const [promotionFreebieModalPlan, setPromotionFreebieModalPlan] =
    useState<FareDropPlan>();

  const handleUpgrade = async (plan: FareDropPlan) => {
    if (!email) {
      presentError('No email provided - please refresh and try again');
    } else {
      await createUpgradeCheckoutSessionPublic(
        email,
        plan,
        'deals',
        undefined,
        notYouRedirectPath.slice(1),
        [{ key: 'email', value: email }],
        couponQueryParam
      );
    }
  };

  const handleCheckout = async (plan: FareDropPlan) => {
    if (!email) {
      presentError('No email provided - please refresh and try again');
    } else {
      await createCheckoutSessionPublic(
        email,
        plan,
        'deals',
        undefined,
        notYouRedirectPath.slice(1),
        [{ key: 'email', value: email }],
        couponQueryParam
      );
    }
  };

  // Redirect to home page if there isn't an email query param and the user is not logged in
  useEffect(() => {
    if (isInitialized) {
      const queryParams = new URLSearchParams(location.search);

      let email: string | undefined = undefined;
      let currentPlanString: string | undefined = undefined;
      if (isAuthenticated) {
        if (user?.profile.email) {
          email = user.profile.email;
        }
        if (user?.billing.idStripePlan) {
          const fareDropPlans = stripePlanIDToFareDropPlans(
            user.billing.idStripePlan
          );
          if (fareDropPlans.length)
            currentPlanString = fareDropPlans[fareDropPlans.length - 1];
        }
      }

      if (!email) {
        email = queryParams.get('email') ?? undefined;
      }

      if (!currentPlanString) {
        currentPlanString = queryParams.get('currentPlan') ?? undefined;
      }

      const currentPlan = plans.find((p) => p.planType === currentPlanString);
      setCurrentPlan(currentPlan);

      if (email) {
        setEmail(email);
      } else {
        goWithStickyParamsPath('/');
      }
    }
  }, [isInitialized]);

  // Hide all plans below the current plan (this page is not going to support downgrades)
  useEffect(() => {
    if (hidePlansOverride) {
      setHidePlans(hidePlansOverride);
    } else if (currentPlan) {
      const excludePlans: FareDropPlan[] = [];
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].planType !== currentPlan?.planType) {
          excludePlans.push(plans[i].planType);
        } else {
          break;
        }
      }

      if (excludePlans.length) {
        setHidePlans(excludePlans);
      }
    }
  }, [currentPlan]);

  return noInternet ? (
    <NoInternetConnectionModal />
  ) : (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <IonRow>
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <h2
              className="title-font mt-3"
              style={{ marginLeft: '1em', marginRight: '1em' }}
            >
              {title}
            </h2>
            <h2
              className="title-font"
              style={{ marginLeft: '1em', marginRight: '1em' }}
            >
              {subtitle}
            </h2>
          </IonCol>
        </IonRow>
        <SubscriptionPlansSection
          title={null}
          selectedPlan={selectedPlan ?? currentPlan}
          selectedPlanButtonText={
            selectedPlan ? 'Selected Plan' : 'Current Plan'
          }
          notSelectedPlanButtonText={selectedPlan ? 'Select' : 'Upgrade'}
          hidePlans={hidePlans}
          onSelect={async (plan) => {
            if (!email)
              presentError('No email provided - please refresh and try again');
            else {
              if (plan === currentPlan?.planType) {
                setSelectedPlan(undefined);
              } else {
                const selected = plans.find((p) => p.planType === plan);
                setSelectedPlan(selected);

                if (!selected) presentError('Failed to select plan');
                else {
                  if (
                    isFrontendSubscriptionPromotion(
                      isLimitedPlan(userState.user?.billing.idStripePlan)
                    ) &&
                    PROMOTION_IS_SHOPIFY_INTEGRATION &&
                    plan !== FareDropPlan.Pro
                  ) {
                    setPromotionFreebieModalPlan(plan as FareDropPlan);
                  } else {
                    if (setupIntentReason === SetupIntentReason.UPGRADE) {
                      await handleUpgrade(selected.planType);
                    } else if (SetupIntentReason.SUBSCRIBE) {
                      await handleCheckout(selected.planType);
                    }
                  }
                }
              }
            }
          }}
        />
        <SocialProofSection />
        <Footer bgColor="secondary" isVisible={true} />
      </IonContent>
      <PromotionFreebieModal
        selectedPlan={promotionFreebieModalPlan}
        setIsModalActive={() => {
          setPromotionFreebieModalPlan(undefined);
        }}
        onSubmit={async (subscriptionPlan) => {
          if (setupIntentReason === SetupIntentReason.UPGRADE) {
            await handleUpgrade(subscriptionPlan.planType);
          } else if (SetupIntentReason.SUBSCRIBE) {
            await handleCheckout(subscriptionPlan.planType);
          }
        }}
      />
    </IonPage>
  );
};

export default UnauthenticatedPurchasePage;
