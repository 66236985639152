// cspell:ignore Gvtg
import {
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonSpinner,
  IonToolbar,
} from '@ionic/react';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { $enum } from 'ts-enum-util';

import { FareDropPlan as FareDropPlanGQL } from '@faredrop/graphql-sdk';
import { FareDropRole } from '@faredrop/types';
import { capitalize, isAuthorized } from '@faredrop/utilities';

import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import './../theme/Home.css';
import GetStarted, { UserAlreadyExistsBehavior } from './GetStarted';
import TrackVisibility from './TrackVisibility';
import useAnalytics from '../hooks/analytics';
import HomePageDealsSection from './HomePageDealsSection';
import HowItWorksSection from './HowItWorksSection';
import SocialProofSection from './SocialProofSection';
import Footer from './Footer';
import usePresentToast from '../hooks/presentToast';
import useAuth from '../hooks/auth';
import useStripe from '../hooks/useStripe';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import Login from './Login';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { getRedeemSubscriptionNextLocation } from '../utilities/plans-utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

enum RedeemState {
  SIGNUP,
  LOGIN,
  REDEEM_ONLY,
}

const RedeemSubscription: React.FC<ContainerProps> = ({
  onMenuClickHandler,
}) => {
  const { isMediumScreenSizeOrSmaller, isLargeScreenSizeOrSmaller } =
    useDevice();
  const { presentWarning } = usePresentToast();
  const [purchaser, setPurchaser] = useState<string>();
  const [code, setCode] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [seatClass, setSeatClass] = useState<string>();
  const [plan, setPlan] = useState<string>();
  const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean>();
  const [redemptionType, setRedemptionType] = useState<string>();
  const [
    isStateFromQueryParamsInitialized,
    setIsStateFromQueryParamsInitialized,
  ] = useState(false);
  const [isStepInitialized, setIsStepInitialized] = useState(false);
  const { user, isAuthenticated, isInitialized } = useAuth();
  const { redeemSubscription } = useStripe();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  const [redeemState, setRedeemState] = useState(RedeemState.SIGNUP);
  const [isLoading, setIsLoading] = useState(false);

  const {
    logAnalyticsRedeemSubscriptionEngagement,
    logAnalyticsRedeemSubscriptionPageSectionView,
  } = useAnalytics();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setPurchaser(queryParams.get('purchaser') ?? '');
    setCode(queryParams.get('code') ?? queryParams.get('giftCode') ?? '');
    setEmail(queryParams.get('email') ?? '');
    setFirstName(queryParams.get('fn') ?? queryParams.get('firstName') ?? '');
    setLastName(queryParams.get('ln') ?? queryParams.get('lastName') ?? '');
    setSeatClass(queryParams.get('seatClass') ?? undefined);
    setPlan(queryParams.get('plan') ?? undefined);
    setHasActiveSubscription(
      queryParams.get('hasActiveSubscription') === 'true'
    );
    setRedemptionType(queryParams.get('type') ?? undefined);

    setIsStateFromQueryParamsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized && !isStepInitialized && hasActiveSubscription != null) {
      if (
        user &&
        isAuthenticated &&
        isAuthorized(user, [FareDropRole.Profile]) &&
        hasActiveSubscription
      ) {
        setRedeemState(RedeemState.REDEEM_ONLY);
      } else if (hasActiveSubscription) {
        setRedeemState(RedeemState.LOGIN);
      } else {
        setRedeemState(RedeemState.SIGNUP);
      }
      setIsStepInitialized(true);
    }
  }, [user, isAuthenticated, hasActiveSubscription, isInitialized]);

  let greeting = '';
  if (!hasActiveSubscription) {
    greeting = firstName
      ? `Welcome to FareDrop, ${capitalize(firstName)}!`
      : 'Welcome to FareDrop!';
  }
  if (redemptionType === 'reward') {
    greeting = firstName
      ? `Congratulations, ${capitalize(firstName)}!`
      : 'Congratulations!';
  }

  const title =
    purchaser && seatClass
      ? `Your ${capitalize(plan ?? seatClass)} Plan gift from ${capitalize(
          purchaser
        )} is waiting`
      : `Redeem your ${redemptionType ?? 'gift'}`;

  const submitRedeem = async () => {
    setIsLoading(true);
    if (code) {
      try {
        const gqlPlan = $enum(FareDropPlanGQL).asValueOrDefault(
          plan,
          undefined
        );
        const result = await redeemSubscription(code, gqlPlan);

        setCode(undefined);
        logAnalyticsRedeemSubscriptionEngagement(
          redemptionType === 'reward'
            ? AnalyticsEngagementId.REDEEM_REWARD
            : AnalyticsEngagementId.REDEEM_GIFT
        ).catch((error) =>
          console.warn(
            'Failed to log analytics redeem subscription engagement',
            error
          )
        );

        goWithStickyParamsLocation(
          getRedeemSubscriptionNextLocation(result, hasActiveSubscription)
        );
      } catch (error) {
        presentWarning(
          'Invalid Code. Please contact team@faredrop.com with your code.'
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      presentWarning('Please enter code');
    }
  };

  return (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: isLargeScreenSizeOrSmaller ? undefined : '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <IonHeader
            className="ion-no-border"
            style={{
              marginTop: 0,
              marginBottom: 0,
              backgroundColor: '#ffffff',
              marginLeft: 0,
              marginRight: 0,
              padding: 5,
              boxShadow: '0px 6px 10px rgba(0, 0, 0, 15%)',
              borderBottom: '1px solid #ccc',
            }}
          >
            <IonToolbar>
              <MobileHeader onMenuClickHandler={onMenuClickHandler} />
            </IonToolbar>
          </IonHeader>
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      {isStateFromQueryParamsInitialized && (
        <IonContent>
          <IonRow>
            <IonCol
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <h2 className="title-font mt-3">{greeting}</h2>
              <h2 className="title-font">{title}</h2>
            </IonCol>
          </IonRow>
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginBottom: '3em',
              marginTop: '3em',
              marginLeft: '1em',
              marginRight: '1em',
            }}
          >
            <div
              style={{
                maxWidth: '500px',
                minWidth: isMediumScreenSizeOrSmaller ? '200px' : '300px',
              }}
            >
              <TextField
                fullWidth
                label="Code"
                name="code"
                onChange={(e) => {
                  setCode(e.target.value as string);
                }}
                type="name"
                value={code}
                variant="outlined"
                style={{ width: '100%' }}
              />
              {redeemState === RedeemState.REDEEM_ONLY && (
                <IonRow>
                  <button
                    className="button no-caps submit-button"
                    disabled={isLoading || !code || code.length === 0}
                    style={{
                      opacity: !code || code.length === 0 ? 0.5 : 1.0,
                      marginTop: '5em',
                    }}
                    onClick={async () => await submitRedeem()}
                  >
                    {(isLoading && (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <IonSpinner
                          style={{ margin: 'auto 0' }}
                          color="white"
                          name="crescent"
                        />
                        <p style={{ margin: 'auto 0 auto 10px' }}>
                          Processing . . .
                        </p>
                      </div>
                    )) || (
                      <p>
                        Redeem{' '}
                        {redemptionType ? capitalize(redemptionType) : 'Gift'}
                      </p>
                    )}
                  </button>
                </IonRow>
              )}
              {redeemState === RedeemState.SIGNUP && (
                <GetStarted
                  email={email}
                  firstName={firstName}
                  lastName={lastName}
                  submitButtonText={`Redeem ${
                    redemptionType ? capitalize(redemptionType) : 'Gift'
                  }`}
                  onSubmit={async (_email, _firstName, _lastName, _idCognito) =>
                    await submitRedeem()
                  }
                  logAnalyticsEngagement={
                    logAnalyticsRedeemSubscriptionEngagement
                  }
                  onLoginLinkPress={async () =>
                    setRedeemState(RedeemState.LOGIN)
                  }
                  userAlreadyExistsBehavior={
                    UserAlreadyExistsBehavior.SHOW_ERROR
                  }
                />
              )}
              {redeemState === RedeemState.LOGIN && (
                <Login
                  onRegisterLinkPress={async () =>
                    setRedeemState(RedeemState.SIGNUP)
                  }
                  email={email}
                  overrideLogin={async () => {
                    await submitRedeem();
                  }}
                />
              )}
            </div>
          </IonRow>

          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsRedeemSubscriptionPageSectionView(
                'how_it_works'
              )
            }
          >
            <HowItWorksSection sx={{ paddingTop: 0 }} />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsRedeemSubscriptionPageSectionView('deals')
            }
          >
            <HomePageDealsSection
              logAnalyticsEngagement={logAnalyticsRedeemSubscriptionEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsRedeemSubscriptionPageSectionView(
                'social_proof'
              )
            }
          >
            <SocialProofSection
              logAnalyticsEngagement={logAnalyticsRedeemSubscriptionEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsRedeemSubscriptionPageSectionView('footer')
            }
          >
            <Footer
              bgColor="secondary"
              isVisible={true}
              onLinkPress={logAnalyticsRedeemSubscriptionEngagement}
            />
          </TrackVisibility>
        </IonContent>
      )}
    </IonPage>
  );
};

export default RedeemSubscription;
