import { IonCol, IonRow } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SocialProofCard } from './SocialProofCard';
import './../theme/SocialProofSection.css';
import './../theme/util.css';
import { useEffect, useState } from 'react';
import useSocialProof from '../hooks/socialProof';
import { SocialProofCardInfo } from '../types/types';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  hideBackground?: boolean;
}

const SocialProofSection: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
  hideBackground,
}) => {
  const [viewLoaded, setViewLoaded] = useState(false);
  const [shuffledSocialProof, setShuffledSocialProof] = useState<
    SocialProofCardInfo[]
  >([]);

  const socialProofState = useSocialProof();

  let slidesPerView = Math.floor(window.innerWidth / 400);
  if (slidesPerView === 0) {
    slidesPerView = 1;
  }

  useEffect(() => {
    if (!socialProofState.isInitializing) {
      setShuffledSocialProof(socialProofState.shuffle());
      setViewLoaded(true);
    }
  }, [socialProofState.isInitializing]);

  // This is the fix for the insertBefore error:
  // https://github.com/ionic-team/ionic-framework/issues/18782
  const sliderKey = !socialProofState.isInitializing
    ? shuffledSocialProof
        .map((card) =>
          !card ? 'empty' : `${card.user}#${card.city}#${card.country}`
        )
        .join('_')
    : 'empty';

  let wrapperClasses = 'social-proof-wrapper no-flex-wrap';
  if (!hideBackground) {
    wrapperClasses += ' social-proof-wrapper-image';
  }

  return (
    <IonRow className={wrapperClasses}>
      <IonCol className="mt-5">
        <div className="titles-container" style={{ marginBottom: '2em' }}>
          <p className="title-font center-align">
            FareDrop users have been to some
            <br />
            amazing places – <span className="orange">at</span>
          </p>
          <p className="title-font underline--drawn orange"> amazing prices!</p>
        </div>
        <IonRow>
          {viewLoaded && (
            // We have opted to not make this slides instance horizontally mousewheel scrollable because
            // scrolling the entire slides object also causes the pager dots section to scroll.
            // However, hiding the pager dots prevents users from realizing that this section
            // is scrollable. So as a happy medium, this section will just not be horizontally
            // mousewheel scrollable
            <Swiper
              key={sliderKey}
              className="social-proof-carousel"
              slidesPerView={slidesPerView}
              // pager // overflow-x: scroll causes pager to be scrolled as well
              onSlideChange={async () => {
                if (logAnalyticsEngagement) {
                  await logAnalyticsEngagement(
                    AnalyticsEngagementId.SOCIAL_PROOF_SCROLL
                  );
                }
              }}
              style={{ overflowX: 'scroll' }}
            >
              {shuffledSocialProof.map((card) => {
                return (
                  <SwiperSlide
                    key={card.id}
                    className="social-proof-card-wrapper"
                  >
                    <SocialProofCard card={card} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default SocialProofSection;
