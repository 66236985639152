// cspell:ignore trustpilot

import { AllowedPath } from '@faredrop/types';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import CreatedBySection from '../components/CreatedBySection';
import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import { GetStartedBlock } from '../components/GetStartedBlock';
import HidingHeader from '../components/HidingHeader';
import AlternateLandingHeroSection from '../components/AlternateLandingHeroSection';
import MobileHeader from '../components/MobileHeader';
import SocialProofSection from '../components/SocialProofSection';
import useAnalytics from '../hooks/analytics';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useHidingHeader } from '../hooks/useHidingHeader';
import './../theme/util.css';
import './../theme/Reviews.css';
import ReviewCard from '../components/ReviewCard';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const Reviews: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { logAnalyticsReviewsPageEngagement } = useAnalytics();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const dynamicBannerContent = useDynamicBanner();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: AllowedPath.BASE,
            search: '?target=#register',
          });
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader
            onMenuClickHandler={onMenuClickHandler}
            backToHomeListener={() => {
              window.location.reload();
            }}
          />
        ) : (
          <DesktopHeader
            loggedIn={false}
            logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
          />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <AlternateLandingHeroSection
          logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
          title="Learn why FareDrop is loved by our members"
          imageSrc={`${getAssetsBaseUrl()}/places/cook-islands.jpg`}
          subtitle="Over 250,000 travelers have used FareDrop to get the best flight deals"
          hideImageOnMobile={true}
        />
        <IonRow className="review-cards-container review-cards-container-responsive">
          <ReviewCard
            imageSrc={`${getAssetsBaseUrl()}/logos/9to5mac.svg`}
            title="The 3 Best Modern Travel Apps on iOS for 2023"
            ctaText="Read the story"
            targetHref="https://9to5mac.com/2023/02/18/the-3-best-modern-travel-apps-on-ios-for-2023/"
          />
          <ReviewCard
            imageSrc={`${getAssetsBaseUrl()}/logos/trustpilot.svg`}
            title="4.8 out of 5 stars"
            ctaText="Read reviews"
            targetHref="https://www.trustpilot.com/review/faredrop.com"
          />
          <ReviewCard
            imageSrc={`${getAssetsBaseUrl()}/logos/available-on-the-app-store.svg`}
            title="4.9 out of 5 stars"
            ctaText="Read reviews"
            targetHref="https://apps.apple.com/us/app/faredrop/id1583315019?see-all=reviews"
            style={{
              paddingTop: '1.25em',
              paddingLeft: '1em',
              paddingRight: '2em',
              paddingBottom: '2em',
            }}
            imageStyle={{
              margin: '0px 8px -5px 0px',
            }}
            textStyle={{
              paddingLeft: '1em',
            }}
          />
        </IonRow>
        <SocialProofSection
          logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
          hideBackground={true}
        />
        <CreatedBySection
          logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
        />
        <GetStartedBlock
          logAnalyticsEngagement={logAnalyticsReviewsPageEngagement}
        />
        <Footer bgColor="secondary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default Reviews;
