const SettingsBackground: React.FC<{
  hideBlueImage?: boolean;
}> = ({ hideBlueImage }) => {
  return (
    <div>
      <div style={{ position: 'absolute', top: '5%', right: 0 }}>
        <svg
          width="514"
          height="893"
          viewBox="0 0 514 893"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.4">
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="842"
              height="893"
            >
              <ellipse
                cx="421"
                cy="446.655"
                rx="421"
                ry="445.673"
                fill="url(#paint0_radial)"
              />
            </mask>
            <g mask="url(#mask0)">
              <ellipse
                cx="421"
                cy="446.655"
                rx="421"
                ry="445.673"
                fill="url(#paint1_linear)"
              />
            </g>
          </g>
          <defs>
            <radialGradient
              id="paint0_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(421 446.655) rotate(90) scale(445.673 421)"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <linearGradient
              id="paint1_linear"
              x1="421"
              y1="0.981445"
              x2="421"
              y2="892.328"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F2B486" />
              <stop offset="1" stopColor="#F5D091" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {!hideBlueImage && (
        <div style={{ position: 'absolute', top: '250px', left: 0 }}>
          <svg
            width="473"
            height="943"
            viewBox="0 0 473 943"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <mask
                id="mask1"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="-417"
                y="0"
                width="890"
                height="943"
              >
                <ellipse
                  cx="28"
                  cy="471.741"
                  rx="445"
                  ry="471.08"
                  fill="url(#paint1_radial)"
                />
              </mask>
              <g mask="url(#mask1)">
                <ellipse
                  cx="28"
                  cy="471.741"
                  rx="445"
                  ry="471.08"
                  fill="url(#paint2_linear)"
                />
              </g>
            </g>
            <defs>
              <radialGradient
                id="paint1_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28 471.741) rotate(90) scale(471.08 445)"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </radialGradient>
              <linearGradient
                id="paint2_linear"
                x1="28"
                y1="0.661133"
                x2="28"
                y2="942.821"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B5D8DF" />
                <stop offset="1" stopColor="#56B3A9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SettingsBackground;
