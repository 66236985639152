// cspell:ignore Gvtg
import {
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import { SubscriptionPlanCard } from '../components/SubscriptionPlanCard';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import './../theme/Home.css';
import './../theme/Offer.css';
import { useEffect, useState } from 'react';
import useAnalytics from '../hooks/analytics';
import { AnalyticsEngagementId, OfferType } from '../contexts/analyticsContext';
import HomePageDealsSection from '../components/HomePageDealsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import SocialProofSection from '../components/SocialProofSection';
import TrackVisibility from '../components/TrackVisibility';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { isProductionEnvironment } from '@faredrop/utilities';
import { AllowedPath, STRIPE_COUPON_ID } from '@faredrop/types';
import Login from '../components/Login';
import { useHistory, useLocation } from 'react-router';
import { FareDropPlan, SubscriptionPlan } from '@faredrop/graphql-sdk';
import { buildFrontendSubscriptionPlans } from '../utilities/plans-utilities';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import DynamicBanner from '../components/DynamicBanner';
import useAuth from '../hooks/auth';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const couponId = isProductionEnvironment()
  ? STRIPE_COUPON_ID.PRODUCTION_20_PERCENT_DISCOUNT
  : STRIPE_COUPON_ID.DEVELOPMENT_20_PERCENT_DISCOUNT;

const YouTubePercent20Discount: React.FC<ContainerProps> = ({
  onMenuClickHandler,
}) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [email, setEmail] = useState<string>();
  const [isLogin, setIsLogin] = useState(false); // Display login form instead of register
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { logAnalyticsOfferEngagement, logAnalyticsOfferPageSectionView } =
    useAnalytics();
  const location = useLocation();
  const history = useHistory();
  const dynamicBannerContent = useDynamicBanner();
  const { isAuthenticated, isInitialized } = useAuth();
  const plans = buildFrontendSubscriptionPlans().map((plan) => {
    return {
      ...plan,
      discount: 0.2,
    };
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setEmail(queryParams.get('email') ?? undefined);

    // Show a login form instead of register form for remarketing users
    const utmId = queryParams.get('utm_id');
    if (utmId?.includes('remarketing')) {
      setIsLogin(true);
    }

    // if the user is logged in, bring them back to the deals page
    if (isAuthenticated && isInitialized) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    }

    // Add coupon code to query params
    if (!queryParams.has('coupon')) {
      queryParams.append('coupon', couponId);
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
  }, []);

  return (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <DynamicBanner
        bannerClickListener={() => {
          if (plans[1]) {
            setSelectedPlan(plans[1]);
            document
              .querySelector(`#youtube-percent-20-plans`)
              ?.scrollIntoView({
                behavior: 'smooth',
              });
          }
        }}
      />
      <IonRow
        style={{
          position: 'absolute',
          top: dynamicBannerContent ? 50 : 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: isLargeScreenSizeOrSmaller ? undefined : '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <IonHeader
            className="ion-no-border"
            style={{
              marginTop: 0,
              marginBottom: 0,
              backgroundColor: '#ffffff',
              marginLeft: 0,
              marginRight: 0,
              padding: 5,
              boxShadow: '0px 6px 10px rgba(0, 0, 0, 15%)',
              borderBottom: '1px solid #ccc',
            }}
          >
            <IonToolbar>
              <MobileHeader onMenuClickHandler={onMenuClickHandler} />
            </IonToolbar>
          </IonHeader>
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      <IonContent>
        <IonRow>
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <h2
              className="title-font mt-3"
              style={{ marginLeft: '1em', marginRight: '1em' }}
            >
              Thanks for being a loyal viewer of the channel!
            </h2>
            <h2
              className="title-font"
              style={{ marginLeft: '1em', marginRight: '1em' }}
            >
              {`Enjoy 20% off your first year of Faredrop membership`}
            </h2>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 800ms',
            textAlign: 'center',
            marginBottom: '2em',
          }}
        >
          {plans?.map((plan) => {
            const isPriceNumber = !isNaN(parseInt(plan.price));
            return (
              <SubscriptionPlanCard
                key={plan.id}
                plan={plan}
                discountPrice={
                  plan.discount && isPriceNumber
                    ? Math.floor(
                        parseInt(plan.price) * (1 - plan.discount)
                      ).toFixed(0)
                    : undefined
                }
                billingText={isPriceNumber ? 'For the first year' : ''}
                billingSubText={isPriceNumber ? `Renews at full-price` : ''}
                border={
                  selectedPlan?.planType === FareDropPlan.Limited
                    ? '2px solid var(--ion-color-primary)'
                    : undefined
                }
                buttonText={
                  selectedPlan?.planType === FareDropPlan.Limited
                    ? 'Selected'
                    : 'Select'
                }
                buttonTextColor={
                  selectedPlan?.planType === FareDropPlan.Limited
                    ? 'white'
                    : undefined
                }
                buttonColor={
                  selectedPlan?.planType === FareDropPlan.Limited
                    ? 'var(--ion-color-primary)'
                    : undefined
                }
                buttonOnClickListener={async () => {
                  setSelectedPlan(plan);
                  document
                    .querySelector(`#youtube-percent-20-plans`)
                    ?.scrollIntoView({
                      behavior: 'smooth',
                    });
                }}
              />
            );
          })}
        </IonRow>
        {/* <h4
          style={{
            fontFamily: 'nexa',
            textAlign: 'center',
            marginBottom: '3em',
            marginLeft: '2em',
            marginRight: '2em',
          }}
        >
          All plans include our{' '}
          <a
            id="percent-20-refund-policy"
            style={{ fontWeight: 'bold', cursor: 'auto' }}
            // TODO: Link to FAQ instead
            // onClick={() => {
            //   goWithStickyParamsPath('/refund-policy');
            //   logAnalyticsOfferEngagement(
            //     OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
            //     AnalyticsEngagementId.OFFER_REFUND_POLICY
            //   );
            // }}
          >
            no questions asked, 90 day refund policy
          </a>
        </h4> */}
        <div id="youtube-percent-20-plans"></div>
        {selectedPlan && (
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginBottom: '3em',
              marginTop: '3em',
              marginLeft: '1em',
              marginRight: '1em',
            }}
          >
            <div style={{ maxWidth: '500px' }}>
              <div>
                <h3 className="title-font" style={{ textAlign: 'center' }}>
                  {isLogin
                    ? 'Almost there! Login to complete registration'
                    : 'Almost there! We just need a few things first'}
                </h3>
              </div>
              {!isLogin && (
                <GetStarted
                  email={email}
                  onSubmit={async () => {
                    goWithStickyParamsLocation({
                      pathname: '/plans',
                      search: `?coupon=${couponId}&planId=${selectedPlan.id}`,
                    });
                    await logAnalyticsOfferEngagement(
                      OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                      `choose_${selectedPlan.planType}_plan` as AnalyticsEngagementId
                    );
                  }}
                  logAnalyticsEngagement={async (engagementId) => {
                    await logAnalyticsOfferEngagement(
                      OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                      engagementId
                    );
                  }}
                  onLoginLinkPress={async () => {
                    setIsLogin(true);
                  }}
                />
              )}
              {isLogin && (
                <Login
                  email={email}
                  loginQueryParams={`?coupon=${couponId}&planId=${selectedPlan.id}`}
                  onLogin={async () => {
                    await logAnalyticsOfferEngagement(
                      OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                      `choose_${selectedPlan.planType}_plan` as AnalyticsEngagementId
                    );
                  }}
                  onRegisterLinkPress={async () => {
                    setIsLogin(false);
                  }}
                  logAnalyticsEngagement={async (engagementId) => {
                    await logAnalyticsOfferEngagement(
                      OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                      engagementId
                    );
                  }}
                />
              )}
            </div>
          </IonRow>
        )}

        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
              'how_it_works'
            )
          }
        >
          <HowItWorksSection sx={{ paddingTop: 0 }} />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
              'deals'
            )
          }
        >
          <HomePageDealsSection
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                engagementId
              );
            }}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
              'social_proof'
            )
          }
        >
          <SocialProofSection
            logAnalyticsEngagement={async (engagementId) => {
              await logAnalyticsOfferEngagement(
                OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                engagementId
              );
            }}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsOfferPageSectionView(
              OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
              'footer'
            )
          }
        >
          <Footer
            bgColor="secondary"
            isVisible={true}
            onLinkPress={async (linkId) => {
              await logAnalyticsOfferEngagement(
                OfferType.YOUTUBE_PERCENT_20_DISCOUNT,
                linkId
              );
            }}
          />
        </TrackVisibility>
      </IonContent>
    </IonPage>
  );
};

export default YouTubePercent20Discount;
