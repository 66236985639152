// cspell:ignore BVTPBA

import { IonCol, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import './../theme/util.css';
import './../theme/GiftPaperAirplane.css';
import ScrollYouTube from './ScrollYouTube';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const GiftPaperAirplane: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
}) => {
  return (
    <IonRow className="gift-paper-airplane-container-responsive">
      <IonCol
        sizeLg="6"
        sizeXs="12"
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '2em',
          paddingRight: '2em',
        }}
      >
        <h2 className="title-font center-align">
          Prefer to give a physical gift?
        </h2>
        <img
          src={`${getAssetsBaseUrl()}/gifts/paper-airplane-progression.png`}
          style={{ maxWidth: '500px', width: '100%' }}
        />
        <div
          className="center-align"
          style={{
            color: 'var(--ion-color-gray)',
            lineHeight: '2em',
          }}
        >
          We have a designed a paper airplane that contains all the information
          your recipient needs to start their new FareDrop subscription. Just
          download and print the paper airplane PDF, add your purchased gift
          code, and follow the provided instructions to fold!
        </div>
        <button
          className="button no-caps submit-button"
          style={{ maxWidth: '25em' }}
          type="submit"
          onClick={async () => {
            window.open(`${getAssetsBaseUrl()}/gifts/paper-airplane.pdf`);
            logAnalyticsEngagement &&
              (await logAnalyticsEngagement(
                AnalyticsEngagementId.DOWNLOAD_PAPER_AIRPLANE
              ));
          }}
        >
          <p>Download Paper Airplane PDF</p>
        </button>
      </IonCol>
      <IonCol
        sizeLg="6"
        sizeXs="12"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '4em',
          marginBottom: '4em',
          width: '100%',
        }}
      >
        <ScrollYouTube videoId="uL1nBVTPBA0" className="overlap-image" />
      </IonCol>
    </IonRow>
  );
};

export default GiftPaperAirplane;
