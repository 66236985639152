import useSWR from 'swr';

import { getAssetsBaseUrl, shuffleArray } from '@faredrop/utilities';

import useSearchAirports from './useSearchAirports';
import { SocialProofCardInfo } from '../types/types';
import useAnalytics from './analytics';

export interface SocialProofValue {
  socialProof?: SocialProofCardInfo[];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  socialProofKey: () => string[];
  shuffle: () => SocialProofCardInfo[];
}

const useSocialProof = (): SocialProofValue => {
  const { origins, destinations } = useSearchAirports();
  const { logAnalyticsError } = useAnalytics();

  const socialProofKey = () => [
    'SOCIAL_PROOF',
    process.env.REACT_APP_VERSION ?? '',
  ];

  const key = origins && destinations ? socialProofKey() : null;

  const { data, error, isValidating } = useSWR(key, async () => {
    try {
      const raw = await fetch(`${getAssetsBaseUrl()}/data/social-proof.json`);
      const json = await raw.json();

      if (Array.isArray(json)) {
        return json as SocialProofCardInfo[];
      } else {
        throw new Error(
          `Invalid return type for social proof card info. ${json}`
        );
      }
    } catch (error) {
      await logAnalyticsError('getSocialProof', error as Error);
      throw error;
    }
  });

  const shuffle = () => {
    if (data) {
      // If any of the first 8 social proofs have duplicate destinations, move the duplicates to the back of the list
      const frontDictionary: { [key: string]: boolean } = {};
      const shuffled = shuffleArray([...data]);
      const updated: SocialProofCardInfo[] = [];
      const moveToBack: SocialProofCardInfo[] = [];
      let dynamicIndex = 8;
      shuffled.forEach((socialProof, index) => {
        if (index < dynamicIndex) {
          if (!frontDictionary[socialProof.destinationIATA]) {
            frontDictionary[socialProof.destinationIATA] = true;
            updated.push(socialProof);
          } else {
            dynamicIndex++;
            moveToBack.push(socialProof);
          }
        } else {
          updated.push(socialProof);
        }
      });

      updated.push(...moveToBack);
      return updated;
    }

    return [];
  };

  return {
    socialProof: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
    socialProofKey,
    shuffle,
  };
};

export default useSocialProof;
