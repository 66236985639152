import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { App } from '@capacitor/app';
import { useDevice } from '../hooks/useDevice';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import useLogError from '../hooks/logError';

export interface AppStateContextValue {
  activeTrigger: number;
}

// Since we set the AppStateProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const AppStateContext = createContext<AppStateContextValue>({
  activeTrigger: Date.now(),
});

interface AppStateProps {
  children: ReactNode;
}

// Android is dumb and has issues where App.addListener is called multiple times, so we're doing it once
// NOTE: This will stop working for Android after hotloading (restart app in Android Studio) // cspell:disable-line
export const AppStateProvider: FC<AppStateProps> = (props) => {
  const { isApp } = useDevice();
  const [activeTrigger, setActiveTrigger] = useState(Date.now());
  const { logError } = useLogError();

  useEffect(() => {
    if (isApp) {
      ScreenOrientation.lock('portrait').catch((err) => {
        logError(
          'Failed to lock screen to portrait',
          err && (err as Error).message
            ? (err as Error).message
            : 'Error or error message is undefined'
        ).catch((error) =>
          console.warn(
            'Failed to send error log to endpoint for locking to portrait mode',
            error
          )
        );
      });
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          setActiveTrigger(Date.now());
        }
      }).catch((err) => {
        logError(
          'Failed to add app state listener',
          err && (err as Error).message
            ? (err as Error).message
            : 'Error or error message is undefined'
        ).catch((error) =>
          console.warn(
            'Failed to send error log to endpoint for adding app state listener',
            error
          )
        );
      });
    }

    return () => {
      if (isApp) {
        App.removeAllListeners().catch((err) => {
          logError(
            'Failed to remove all app state listeners',
            err && (err as Error).message
              ? (err as Error).message
              : 'Error or error message is undefined'
          ).catch((error) =>
            console.warn(
              'Failed to send error log to endpoint for removing app state listeners',
              error
            )
          );
        });
      }
    };
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        activeTrigger,
      }}
    >
      {props.children}
    </AppStateContext.Provider>
  );
};

export default AppStateContext;
