import { IonButton, IonContent } from '@ionic/react';
import { Component, ErrorInfo, ReactNode } from 'react';

import { FirebasePushID } from '@faredrop/firebase-push-id';
import { UNSUPPORTED_VERSION_ERROR } from '@faredrop/utilities';
import UnsupportedVersion from '../pages/UnsupportedVersion';
import { AllowedPath } from '@faredrop/types';

// Source: https://reactjs.org/docs/error-boundaries.html
// NOTE: Only class components can be error boundaries

interface Props {
  children: ReactNode;
  fallbackComponent?: ReactNode;
  logger?: (
    error: Error,
    errorInfo: ErrorInfo,
    clientRequestId: string
  ) => Promise<string>;
}

interface State {
  hasError: boolean;
  clientRequestId: string | undefined;
  requestId: string | undefined;
  errorMessage: string | undefined;
}

// NOTE: With these boundaries in place, Ionic will still throw up an Error screen for the errors that are caught.
// However, these error screens are only shown when Ionic code is being run locally and are not shown in prod. These
// error screens have an X button at the top right which, if pressed, will reveal the JSX component rendered
// by the ErrorBoundary on getDerivedStateFromError (i.e. 'Uh oh, something went wrong. Refreshing...')
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    clientRequestId: undefined,
    requestId: undefined,
    errorMessage: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      clientRequestId: FirebasePushID.generate(),
      requestId: undefined,
      errorMessage: error.message,
    };
  }

  public async logAndUpdateRequestId(error: Error, errorInfo: ErrorInfo) {
    if (this.props.logger) {
      const requestId = await this.props.logger(
        error,
        errorInfo,
        this.state.clientRequestId ?? FirebasePushID.generate()
      );
      this.setState((st) => {
        return { ...st, requestId };
      });
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error Boundary:', error, errorInfo);
    this.logAndUpdateRequestId(error, errorInfo).catch((error) =>
      console.warn('logAndUpdateRequestId failed', error)
    );
  }

  public render() {
    const emailSubject = encodeURIComponent('Uh-Oh Screen Trouble');
    const emailBody = encodeURIComponent(
      'Hi Faredrop Team,\n\nI am being taken to the Uh-Oh screen and I need help getting back to the app. Can you please help me? My name is _________________ and the email associated with my account is _________________.\n\nClient Request ID: ' +
        this.state.clientRequestId +
        '\n\nRequest ID: ' +
        this.state.requestId +
        '\n\nApp version: ' +
        process.env.REACT_APP_VERSION ?? 'Not found'
    );
    const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

    if (this.state.hasError) {
      if (this.state.errorMessage === UNSUPPORTED_VERSION_ERROR) {
        return <UnsupportedVersion />;
      } else if (this.props.fallbackComponent) {
        return this.props.fallbackComponent;
      } else {
        return (
          <IonContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                maxWidth: '90%',
                height: '400px',
              }}
            >
              <h2 className="title-font">Uh oh, something went wrong...</h2>
              {this.state.clientRequestId && (
                <small style={{ fontFamily: 'nexa' }}>
                  Request ID: {this.state.clientRequestId}
                </small>
              )}
              <br />
              <small style={{ fontFamily: 'nexa' }}>
                App Version: {process.env.REACT_APP_VERSION ?? 'Not found'}
              </small>
              <hr
                style={{
                  borderBottom: '1px solid #ddd',
                  maxWidth: 400,
                  marginBottom: '2em',
                }}
              />
              <h4 style={{ marginBottom: '2em' }}>
                <a
                  onClick={() => {
                    window.location.reload();
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  Click here to refresh
                </a>
              </h4>
              <IonButton
                className="get-started-button get-started-button-hero mb-1-5"
                style={{
                  maxWidth: '10em',
                  maxHeight: '4em',
                  letterSpacing: 1,
                  marginBottom: '4em',
                }}
                onClick={() => {
                  window.location.href = AllowedPath.LOGOUT;
                }}
              >
                Go to Login
              </IonButton>
              <h5>
                Need help? Click&nbsp;
                <a href={errorMailTo} style={{ textDecoration: 'underline' }}>
                  here
                </a>
                &nbsp;to contact support
              </h5>
            </div>
          </IonContent>
        );
      }
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
