import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';

import { FareDropRole as FareDropRoleGQL } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';

import useAuth from '../hooks/auth';
import useUser from '../hooks/user';
import Loading from './Loading';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useRefreshAccount from '../hooks/useRefreshAccount';

interface ContainerProps {
  children: React.ReactNode;
}

const pathsThatCannotBeRedirectedToPlans: string[] = [
  AllowedPath.PLANS,
  AllowedPath.PASSWORD_RECOVERY,
  AllowedPath.PASSWORD_RESET,
  AllowedPath.GET_STARTED_WELCOME_FREE,
  AllowedPath.GET_STARTED_WELCOME_PAID,
];

const GuardedRoute: React.FC<ContainerProps> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const { buildStickyParamsLocationDescriptor } = useHistoryWithStickyParams();
  const userState = useUser();
  const reactLocation = useLocation();
  const { isAccountReady } = useRefreshAccount();

  const configInitialized = !userState.isInitializing;
  const noInternet = userState.timeout;
  const userProfile = userState.user?.profile;

  let isLoading = true;
  let routeComponent: React.ReactNode = <Loading />;
  if (noInternet) {
    isLoading = false;
    routeComponent = children; // Children will show no internet modal if there is no internet
  } else if (isInitialized && configInitialized) {
    if (!isAuthenticated) {
      const url = new URL(window.location.href);
      if (url.pathname !== AllowedPath.DEALS) {
        url.searchParams.set('redirect', url.pathname);
      }
      routeComponent = (
        <Redirect
          to={buildStickyParamsLocationDescriptor({
            pathname: AllowedPath.LOGIN,
            search: url.searchParams.toString(),
          })}
        />
      );
    } else if (
      !pathsThatCannotBeRedirectedToPlans.includes(reactLocation.pathname) &&
      !userProfile?.roles.includes(FareDropRoleGQL.Limited)
    ) {
      routeComponent = (
        <Redirect
          to={buildStickyParamsLocationDescriptor({
            pathname: AllowedPath.PLANS,
          })}
        />
      );
    } else if (isAccountReady) {
      isLoading = false;
      routeComponent = children;
    }
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading />
        </div>
      )}
      {/* We are using display: none instead of component conditionals because of component mounting issues
      This component was causing several re-mounts which had undesired behavior, especially in the refreshAccount context */}
      <div
        style={{
          height: '100%',
          width: '100%',
          display: isLoading ? 'none' : undefined,
        }}
      >
        {routeComponent}
      </div>
    </>
  );
};

export default GuardedRoute;
