import { IonCol, IonRow } from '@ionic/react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import '../theme/PasswordRequirements.css';
import { ReactNode, useEffect, useState } from 'react';

interface ContainerProps {
  password: string;
}

interface PasswordRequirement {
  id: number;
  text: string;
  isValid: () => boolean;
}

const PasswordRequirements: React.FC<ContainerProps> = ({ password }) => {
  const [showRequirements, setShowRequirements] = useState(false);

  useEffect(() => {
    if (password && password.length > 0) {
      setShowRequirements(
        [...requirements]
          .flatMap((r) => r)
          .some((requirement) => !requirement.isValid())
      );
    }
  }, [password]);

  const requirements: PasswordRequirement[][] = [
    [
      {
        id: 0,
        text: '1 number',
        isValid: () => {
          return /\d/.test(password);
        },
      },
      {
        id: 1,
        text: '1 lowercase letter',
        isValid: () => {
          return /[a-z]/.test(password);
        },
      },
    ],
    [
      {
        id: 2,
        text: '1 uppercase letter',
        isValid: () => {
          return /[A-Z]/.test(password);
        },
      },
      {
        id: 3,
        text: '1 special character',
        isValid: () => {
          return /[^A-Z a-z0-9]/.test(password);
        },
      },
    ],
    [
      {
        id: 4,
        text: '8 characters long',
        isValid: () => {
          return !!password && password.length >= 8;
        },
      },
    ],
  ];

  return showRequirements ? (
    <>
      {requirements.map((requirementsRow: PasswordRequirement[], index) => {
        const columns: ReactNode[] = [];
        columns.push(
          ...requirementsRow.map((requirement: PasswordRequirement) => {
            const isValid = requirement.isValid();
            return (
              <IonCol
                className="password-requirement-container"
                key={requirement.id}
              >
                {isValid ? (
                  <CheckCircleIcon
                    color="success"
                    className="password-requirement-icon"
                  />
                ) : (
                  <CancelIcon className="password-requirement-icon password-invalid" />
                )}
                <span className="password-requirement-text">
                  {requirement.text}
                </span>
              </IonCol>
            );
          })
        );
        return <IonRow key={index}>{columns}</IonRow>;
      })}
    </>
  ) : (
    <></>
  );
};

export default PasswordRequirements;
