import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { Box, FormHelperText, TextField } from '@mui/material';
import { Formik } from 'formik';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import * as Yup from 'yup';
import { default as YupPassword } from 'yup-password';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import { AllowedPath } from '@faredrop/types';

YupPassword(Yup);

type PasswordResetProps = {
  onPasswordReset?: () => void;
  email?: string;
  hideCodeSection?: boolean;
  useLoginFunction?: boolean;
};

const PasswordReset: FC<PasswordResetProps> = (props: PasswordResetProps) => {
  const itemsRef = useRef<Array<HTMLElement>>([]);
  const newPasswordRef = useRef<HTMLElement>();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { isSmallScreenSizeOrSmaller } = useDevice();

  const queryParams = new URLSearchParams(location.search);
  // URLSearchParams handles the decoding of these params for us. Calling decodeURI or
  // decodeURIComponent on these params, given that they have already been decoded, actually
  // throws a URI encoding error...
  const queryParamEmail = queryParams.get('email') ?? '';
  const queryParamAsset = queryParams.get('asset') ?? '';
  const queryParamSignature = queryParams.get('auth') ?? '';
  const queryParamTS = queryParams.get('ts') ?? '';
  const { login, verifyUserAndSetPassword } = useAuth();

  useEffect(() => {
    if (!props.hideCodeSection) {
      itemsRef.current = itemsRef.current.slice(0, 6);
    }
  }, []);

  const codeRequirements = isSmallScreenSizeOrSmaller
    ? Yup.string().required('Code is required')
    : Yup.array().of(Yup.string().required('Code is required'));

  const passwordRequirements = Yup.string()
    .required('Password is required')
    .min(8)
    .minNumbers(1)
    .minLowercase(1)
    .minUppercase(1)
    .minSymbols(1)
    .matches(
      /^[\S]+.*[\S]+$/,
      'Password must not start or end with whitespace'
    );
  const passwordConfirmRequirements = Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password is required');

  return (
    <Formik
      initialValues={{
        code: isSmallScreenSizeOrSmaller ? '' : ['', '', '', '', '', ''],
        password: '',
        passwordConfirm: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape(
        props.hideCodeSection
          ? {
              password: passwordRequirements,
              passwordConfirm: passwordConfirmRequirements,
            }
          : {
              code: codeRequirements,
              password: passwordRequirements,
              passwordConfirm: passwordConfirmRequirements,
            }
      )}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          if (!props.email && queryParamEmail === '') {
            throw new Error('No email found');
          }

          await verifyUserAndSetPassword(
            props.email ?? queryParamEmail,
            queryParamAsset,
            queryParamSignature,
            queryParamTS,
            values.password
          );

          await login(props.email ?? queryParamEmail, values.password);

          setSubmitting(false);
          goWithStickyParamsPath(AllowedPath.DEALS);
          window.location.reload();
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: (err as Error).message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          {props.hideCodeSection || <h4>Verification code</h4>}
          <IonRow style={{ marginBottom: props.hideCodeSection ? '' : '2em' }}>
            {isSmallScreenSizeOrSmaller && !props.hideCodeSection && (
              <TextField
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                label="Verification Code"
                margin="normal"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="outlined"
              />
            )}
            {!isSmallScreenSizeOrSmaller &&
              !props.hideCodeSection &&
              [1, 2, 3, 4, 5, 6].map((_ref, i: number) => (
                <IonCol size="2" key={i}>
                  <TextField
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'center',
                        fontSize: '24px',
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                    error={Boolean(
                      Array.isArray(touched.code) &&
                        touched.code.length === 6 &&
                        errors.code
                    )}
                    fullWidth
                    inputRef={(el: HTMLElement) => (itemsRef.current[i] = el)}
                    key={`codeNumber-${i}`}
                    name={`code[${i}]`}
                    onBlur={handleBlur}
                    onKeyDown={(event) => {
                      if (!props.hideCodeSection) {
                        if (event.key === 'ENTER') {
                          handleSubmit();
                        }

                        if (event.key === 'ArrowLeft') {
                          if (i > 0) {
                            itemsRef.current[i - 1].focus();
                          }
                        }

                        if (event.key == 'ArrowRight') {
                          if (i < 5) {
                            itemsRef.current[i + 1].focus();
                          }
                        }

                        if (event.key == 'Backspace' || event.key == 'Delete') {
                          setFieldValue(`code[${i}]`, '');

                          if (i > 0) {
                            itemsRef.current[i - 1].focus();
                          }
                        }

                        if (Number.isInteger(parseInt(event.key, 10))) {
                          setFieldValue(`code[${i}]`, event.key);

                          if (i < 5) {
                            itemsRef.current[i + 1].focus();
                          } else {
                            // Have to add in a little delay - otherwise, the focus action occurs before
                            // the input action is finished, causing the text to be also inputted
                            // into the newly focused input
                            setTimeout(() => {
                              newPasswordRef.current?.focus();
                            }, 100);
                          }
                        }
                      }
                    }}
                    onPaste={(event) => {
                      const paste = event.clipboardData.getData('text');
                      const pasteArray = paste.split('');

                      if (pasteArray.length > 6) {
                        return;
                      }

                      let valid = true;

                      pasteArray.map((x) => {
                        if (!Number.isInteger(parseInt(x, 10))) {
                          valid = false;
                        }
                      });

                      if (!props.hideCodeSection && valid) {
                        setFieldValue('code', pasteArray);
                        // Have to add in a little delay - otherwise, the focus action occurs before
                        // the paste action is finished, causing the text to be also inputted
                        // into the newly focused input
                        if (pasteArray.length == 6) {
                          setTimeout(() => {
                            newPasswordRef.current?.focus();
                          }, 100);
                        } else {
                          setTimeout(() => {
                            itemsRef.current[pasteArray.length].focus();
                          });
                        }
                      }
                    }}
                    type="tel"
                    value={values.code[i]}
                    variant="outlined"
                  />
                </IonCol>
              ))}
          </IonRow>
          {!isSmallScreenSizeOrSmaller &&
            !props.hideCodeSection &&
            Boolean(
              Array.isArray(touched.code) &&
                touched.code.length === 6 &&
                errors.code
            ) && (
              <FormHelperText error>
                {Array.isArray(errors.code) &&
                  errors.code.find((x) => x !== undefined)}
              </FormHelperText>
            )}
          <TextField
            inputRef={(el: HTMLElement) => (newPasswordRef.current = el)}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="New password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            placeholder="************"
            variant="outlined"
            onKeyDown={(event) => {
              if (
                (event.key == 'Backspace' || event.key == 'Delete') &&
                values.password === '' &&
                !props.hideCodeSection
              ) {
                itemsRef.current[5].focus();
              }
            }}
          />
          <TextField
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            fullWidth
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            label="Password Confirmation"
            margin="normal"
            name="passwordConfirm"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.passwordConfirm}
            placeholder="************"
            variant="outlined"
            onKeyDown={(event) => {
              if (
                (event.key == 'Backspace' || event.key == 'Delete') &&
                values.passwordConfirm === ''
              ) {
                newPasswordRef.current?.focus();
              }
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <IonRow>
            <button
              disabled={isSubmitting}
              className="button no-caps"
              type="submit"
            >
              {(isSubmitting && (
                <IonSpinner color="white" name="crescent" />
              )) || <p>Reset password</p>}
            </button>
          </IonRow>
        </form>
      )}
    </Formik>
  );
};

export default PasswordReset;
