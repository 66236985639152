import useFareDropPublicApiClient from './faredropPublicApiClient';
import { useDevice } from './useDevice';

interface LogErrorValue {
  logError: (
    title?: string,
    message?: string,
    data?: { [key: string]: unknown }
  ) => Promise<void>;
}

const useLogError = (): LogErrorValue => {
  const { client } = useFareDropPublicApiClient();
  const { isApp, isAndroid, isIOS } = useDevice();

  const logError = async (
    title?: string,
    message?: string,
    data?: { [key: string]: unknown }
  ) => {
    console.warn('Sending error to log endpoint:', title, message, data);
    await client.log({
      obj: JSON.stringify({
        title: title ?? 'Error on sign-up',
        err: message ?? 'Error or error message is undefined',
        appVersion: process.env.REACT_APP_VERSION ?? 'Not found',
        isAndroid,
        isIOS,
        isApp,
        ts: new Date().valueOf(),
        ...(data ?? {}),
        userAgent: window.navigator.userAgent,
        // Filtering out some SWR entries that we know probably won't be very helpful...
        localStorage: Object.entries(window.localStorage).filter(
          (entry) =>
            !entry[0].startsWith('swr-USER_DEALS') &&
            !entry[0].startsWith('swr-USER_PAST_DEALS') &&
            !entry[0].startsWith('swr-LANDING_PAGE') &&
            !entry[0].startsWith('swr-HERO_PHONE_DEALS') &&
            !entry[0].startsWith('swr-SOCIAL_PROOF') &&
            !entry[0].startsWith('swr-SWR_TIMEOUT')
        ),
      }).slice(0, 100000),
    });
  };

  return {
    logError,
  };
};

export default useLogError;
