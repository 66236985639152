import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AllowedPath } from '@faredrop/types';

import useTabs from '../hooks/useTabs';
import { Tab } from '../contexts/tabContext';
import { useDevice } from '../hooks/useDevice';
import useDailyDropContent from '../hooks/useDailyDropContent';
import './../theme/util.css';
import './../theme/DealsDashboard.css';
import BackButton from '../components/BackButton';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DailyDropPostPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const DailyDropPost: React.FC<DailyDropPostPageProps> = ({ match }) => {
  const { updateSelectedTab } = useTabs();
  const { isApp } = useDevice();
  const { dailyDropContent, isInitializing } = useDailyDropContent();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const [content, setContent] = useState<string>();

  useEffect(() => {
    if (!isApp) {
      window.location.href = `${process.env.REACT_APP_DAILY_DROP_REFERRAL_URL}`;
    } else {
      updateSelectedTab(Tab.DAILY_DROP).catch((error) => {
        console.warn('Failed to update selected tab', Tab.SETTINGS, error);
        throw error; // Show uh-oh screen
      });
    }
  }, []);

  useEffect(() => {
    if (dailyDropContent && !isInitializing) {
      for (const c of dailyDropContent) {
        if (c.idContent === match.params.id) {
          setContent(c.data);
        }
      }
    }
  }, [dailyDropContent, isInitializing]);

  return (
    <IonPage>
      <IonContent>
        {content && (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            style={{
              position: 'relative',
              margin: '1em',
              marginBottom: '3em',
              top: '2em',
            }}
          ></div>
        )}
        <BackButton
          onClick={async () => goWithStickyParamsPath(AllowedPath.DAILY_DROP)}
          style={{
            top: '3em',
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default DailyDropPost;
