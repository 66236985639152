import { IonIcon } from '@ionic/react';
import { closeOutline, notificationsOutline } from 'ionicons/icons';
import { Dispatch, SetStateAction } from 'react';
import useNotifications from '../hooks/notifications';
import FabButton from './FabButton';

interface ContainerProps {
  hideDecimal: number;
  hideOnClick: boolean;
  notificationsModalActive?: boolean;
  setScrollYCurrent: (scroll: number) => void;
  setNotificationsModalActive: Dispatch<SetStateAction<boolean>>;
  top?: string | undefined;
}

const NotificationsFabButton: React.FC<ContainerProps> = ({
  hideDecimal,
  hideOnClick,
  notificationsModalActive,
  setNotificationsModalActive,
  setScrollYCurrent,
  top,
}) => {
  const { clearNotifications, notifications } = useNotifications();

  return (
    <FabButton
      hideDecimal={parseInt(hideDecimal.toString())}
      onClick={async () => {
        if (notificationsModalActive) {
          await clearNotifications();
        }

        if (hideOnClick) {
          setScrollYCurrent(200);
          setNotificationsModalActive(true);
        } else {
          setNotificationsModalActive(!notificationsModalActive);
        }
      }}
      right="11em"
      top={top}
      color={notificationsModalActive ? 'primary' : 'fab'}
    >
      <IonIcon
        icon={notificationsModalActive ? closeOutline : notificationsOutline}
        style={{ margin: 'auto' }}
        color={notificationsModalActive ? 'fab' : 'standard'}
      />
      {notifications.length != 0 && !notificationsModalActive ? (
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '4px',
            backgroundColor: 'var(--ion-color-primary)',
            position: 'absolute',
            top: '36%',
            right: '34%',
          }}
        />
      ) : (
        <></>
      )}
    </FabButton>
  );
};

export default NotificationsFabButton;
