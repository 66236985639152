import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import _ from 'lodash';

import { FareDropPlan } from '@faredrop/graphql-sdk';
import { AllowedPath, PerkName } from '@faredrop/types';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  getAssetsBaseUrl,
  hasActivePlan,
} from '@faredrop/utilities';

import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useHidingHeader } from '../hooks/useHidingHeader';
import './../theme/util.css';
import './../theme/Reviews.css';
import PerksHero from '../components/PerksHero';
import ReviewCard from '../components/ReviewCard';
import usePerks from '../hooks/usePerks';
import {
  getPlanName,
  isFrontendSubscriptionPromotion,
  isLimitedPlan,
} from '../utilities/plans-utilities';
import useUser from '../hooks/user';
import UpgradeModal from '../components/UpgradeModal';
import { useEffect, useState } from 'react';
import { Tab } from '../contexts/tabContext';
import useTabs from '../hooks/useTabs';
import PerkTermsAndConditionsModal from '../components/PerkTermsAndConditionsModal';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';

const Perks: React.FC = () => {
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { isExtraLargeScreenSize, isMediumScreenSizeOrSmaller } = useDevice();
  const dynamicBannerContent = useDynamicBanner();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { redeemLonelyPlanetEbook } = usePerks();
  const { user } = useUser();
  const { perks } = usePerks();
  const { isSmallScreenSizeOrSmaller, isApp } = useDevice();
  const { updateSelectedTab } = useTabs();

  const [upgradeMessage, setUpgradeMessage] = useState<string>();
  const [perkTermsTitle, setPerkTermsTitle] = useState<string>();
  const [perkTermsMessage, setPerkTermsMessage] = useState<string>();

  useEffect(() => {
    updateSelectedTab(Tab.PERKS).catch((error) => {
      console.warn('Failed to update selected tab', Tab.PERKS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  const isLimited = isLimitedPlan(user?.billing.idStripePlan);
  const UpgradeMessage = `Perks are only available on the ${getPlanName(
    FareDropPlan.Pro
  )} Plan. ${
    HIDE_MEMBERSHIP_LINKS
      ? HIDE_UPGRADE_MESSAGE
      : 'Upgrade to unlock exclusive FareDrop Perks!'
  }`;

  return (
    <IonPage>
      {isExtraLargeScreenSize ? (
        <HidingHeader
          hideDecimal={parseInt(hideDecimal.toString())}
          promotionText={dynamicBannerContent}
          bannerClickListener={() => {
            goWithStickyParamsLocation({
              pathname: AllowedPath.BASE,
              search: '?target=#register',
            });
          }}
        >
          <DesktopHeader loggedIn={true} />
        </HidingHeader>
      ) : (
        <div
          style={{
            height: '2em',
            backdropFilter: 'blur(1.5px)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100000,
          }}
        />
      )}
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <IonRow
          style={{
            paddingTop: isExtraLargeScreenSize
              ? '6em'
              : isApp
              ? '3.5em'
              : '2em',
          }}
        >
          <PerksHero
            title="Pro Perks"
            imageSrc={`${getAssetsBaseUrl()}/kara-and-nate/summer24/grass-bag-horizontal.jpg`}
            subtitle="Get exclusive perks for being a FareDrop Pro member"
          />
          <IonRow
            className="review-cards-container-responsive"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: '5em',
              marginBottom: '5em',
            }}
          >
            {!perks &&
              // NOTE: This is only valid for non t-shirt promotions
              Array(
                isFrontendSubscriptionPromotion(
                  hasActivePlan(
                    user?.billing.idStripePlan,
                    user?.billing.subscriptionStatus
                  )
                ) && PROMOTION_IS_SHOPIFY_INTEGRATION
                  ? 3
                  : 2
              )
                .fill(0)
                .map((_value, index) => (
                  <IonCol key={index} sizeXs="8" sizeMd="3">
                    <IonSkeletonText
                      animated={true}
                      style={{ height: '218px', borderRadius: '10px' }}
                    />
                  </IonCol>
                ))}
            {perks?.map((perk) => {
              let style: React.CSSProperties = {
                paddingTop: isSmallScreenSizeOrSmaller ? '1.25em' : '2em',
              };
              let imageStyle: React.CSSProperties = {};
              const textStyle: React.CSSProperties = {};
              let onLinkClick: () => Promise<string | undefined> = async () => {
                if (!isLimited) {
                  window.open(perk?.target ?? undefined, '_blank');
                } else {
                  setUpgradeMessage(UpgradeMessage);
                }
                return undefined;
              };

              // Customizations
              switch (perk.name) {
                case PerkName.AirportTShirt: {
                  imageStyle = {
                    margin: '-10px 8px 5px 0px',
                  };
                  style = {
                    ...style,
                    marginLeft: isMediumScreenSizeOrSmaller
                      ? undefined
                      : perks.length > 2
                      ? '4em'
                      : undefined,
                  };
                  break;
                }
                case PerkName.LonelyPlanetEBook: {
                  style = {
                    marginLeft: isMediumScreenSizeOrSmaller ? undefined : '4em',
                  };
                  imageStyle = {
                    width: '90%',
                  };
                  onLinkClick = async () => {
                    if (isLimited) {
                      setUpgradeMessage(UpgradeMessage);
                    } else if (perk.target) {
                      const targetURL = new URL(perk.target);

                      // If discount code doesn't exist, try to get it
                      // Previous customers or new customers with a delayed Klaviyo profile update won't have the assigned code yet
                      let emailSent = false;
                      if (!perk.value) {
                        const code = await redeemLonelyPlanetEbook();
                        if (code) {
                          targetURL.searchParams.set('discount', code);
                        } else {
                          emailSent = true;
                        }
                      }

                      if (emailSent) {
                        return 'Email on the way 🎉';
                      } else {
                        if (!isLimited) {
                          window.open(targetURL.toString(), '_blank');
                        }
                      }
                    }
                    return undefined;
                  };
                  break;
                }
              }

              return (
                <ReviewCard
                  key={perk.name}
                  title={perk.title}
                  description={
                    <p
                      style={{
                        color: 'var(--ion-color-gray)',
                        marginTop: '10px',
                        fontSize: '14px',
                        lineHeight: '1.15em',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: perk.description ?? '',
                      }}
                    ></p>
                  }
                  textStyle={textStyle}
                  imageSrc={perk.imageSrc}
                  imageStyle={imageStyle}
                  ctaText={isLimited ? 'Unlock Perk' : 'Redeem Perk'}
                  targetHref={perk?.target ?? undefined}
                  onCTAClick={onLinkClick}
                  ctaButton={true}
                  onTermsClick={() => {
                    setPerkTermsTitle(
                      `${_.startCase(perk.name)} Terms and Conditions`
                    );
                    setPerkTermsMessage(perk.terms ?? undefined);
                  }}
                  style={{
                    ...style,
                    height: 'initial',
                  }}
                />
              );
            })}
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'var(--ion-color-gray)',
                marginTop: '2em',
                marginLeft: '2em',
                marginRight: '2em',
                textAlign: 'center',
              }}
            >
              Please note, once a perk has been redeemed, your FareDrop
              subscription is no longer refundable.
            </IonRow>
          </IonRow>
        </IonRow>
        {!isApp && <Footer bgColor="secondary" isVisible={true} />}
      </IonContent>
      <UpgradeModal
        message={upgradeMessage}
        onClose={() => {
          setUpgradeMessage(undefined);
        }}
      />
      <PerkTermsAndConditionsModal
        title={perkTermsTitle}
        message={perkTermsMessage}
        onClose={() => {
          setPerkTermsTitle(undefined);
          setPerkTermsMessage(undefined);
        }}
      />
    </IonPage>
  );
};

export default Perks;
