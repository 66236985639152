const getUrlOrigin = (isApp?: boolean) => {
  // The app's window.origin is always localhost, so we need to update it according to TARGET_ENV
  return isApp ? process.env.REACT_APP_URL_ORIGIN : window.origin;
};

export const buildSuccessAndCancelCheckoutURLs = (
  successPathname?: string,
  successQueryParams?: { key: string; value: string }[],
  cancelPathname?: string,
  cancelQueryParams?: { key: string; value: string }[],
  isApp?: boolean
) => {
  // https://nodejs.org/dist/latest-v8.x/docs/api/url.html#url_urlsearchparams_set_name_value
  // Sets the value in the URLSearchParams object associated with name to value.
  // If there are any pre-existing name-value pairs whose names are name, set the first such pair's value to value and remove all others. If not, append the name-value pair to the query string.
  const successUrlObj = new URL(`${getUrlOrigin(isApp)}/${successPathname}`);
  const cancelUrlObj = new URL(`${getUrlOrigin(isApp)}/${cancelPathname}`);

  // Copy any existing query parameters (coupon, UTM codes, etc., onto the success and cancel URLs)
  const windowUrlObj = new URL(window.location.toString());
  windowUrlObj.searchParams.forEach((value, key) => {
    successUrlObj.searchParams.set(key, value);
    cancelUrlObj.searchParams.set(key, value);
  });

  successQueryParams?.forEach((p) => {
    successUrlObj.searchParams.set(p.key, p.value);
  });

  cancelQueryParams?.forEach((p) => {
    cancelUrlObj.searchParams.set(p.key, p.value);
  });

  // /get-started/complete route for apps requires a success query param
  if (isApp) {
    successUrlObj.searchParams.set('success', 'true');
  }

  return {
    successUrlObj,
    cancelUrlObj,
  };
};
