import './../theme/util.css';
import './../theme/LoginPage.css';

import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useDevice } from '../hooks/useDevice';
import MobileHeader from '../components/MobileHeader';
import DesktopHeader from '../components/DesktopHeader';
import { useEffect, useState } from 'react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

const Error: React.FC = () => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [mailTo, setMailTo] = useState<string>();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryMessage = queryParams.get('message');
    queryMessage && setErrorMessage(queryMessage);

    const queryMailTo = queryParams.get('mailto');
    queryMailTo && setMailTo(queryMailTo);
  }, []);

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader menuHidden={true} />
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            transition: 'opacity 800ms',
            textAlign: 'center',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8">
            <IonRow className="row-vertical-align">
              <h1
                className="title-font"
                style={{
                  textAlign: 'center',
                  fontSize: '5em',
                  color: 'var(--ion-color-primary)',
                }}
              >
                Uh-oh
              </h1>
            </IonRow>
            {(!errorMessage ||
              (!!errorMessage && errorMessage.indexOf('expired') === -1)) && (
              <IonRow className="row-vertical-align">
                <h4 style={{ fontFamily: 'nexa-bold' }}>
                  Something went wrong. Please try again
                </h4>
              </IonRow>
            )}
            {errorMessage && (
              <IonRow className="row-vertical-align">
                <p style={{ color: 'rgba(124, 124, 124, 1)' }}>
                  {errorMessage}
                </p>
              </IonRow>
            )}
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1em',
              }}
            >
              <a
                href={mailTo ?? 'mailto:team@faredrop.com'}
                style={{ lineHeight: '1em' }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    color: 'rgba(124, 124, 124, 1)',
                  }}
                >
                  Contact Support
                </div>
              </a>
            </IonRow>
            <IonButton
              style={{
                height: '3em',
                width: '15em',
                marginTop: '2em',
                borderRadius: 8,
                textTransform: 'none',
              }}
              onClick={() => {
                goWithStickyParamsPath('/');
              }}
            >
              Back to Home
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Error;
