import { IonCol, IonIcon, IonSpinner } from '@ionic/react';
import { checkmarkOutline, closeCircleOutline } from 'ionicons/icons';

import { FareDropPlan, SubscriptionPlan } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';

import { useState } from 'react';
import './../theme/SubscriptionPlanCard.css';
import './../theme/util.css';
import { useDevice } from '../hooks/useDevice';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

interface ContainerProps {
  plan: SubscriptionPlan;
  discountPrice?: string; // If this is provided, the original price will be crossed out
  overridePrice?: string; // For upgrades and downgrades we want to override the price with the prorated amount (without the price being crossed out)
  border?: string;
  billingText?: string;
  billingSubText?: string;
  buttonText?: string;
  buttonTextColor?: string;
  buttonColor?: string;
  buttonStyle?: React.CSSProperties;
  buttonOnClickListener?: () => Promise<void>;
  sx?: React.CSSProperties;
  outline?: boolean;
  discountImage?: string;
  big?: boolean;
  hideButton?: boolean;
  hideBillingTextSection?: (plan: FareDropPlan) => boolean;
  titleSize?: string;
  titleMarginTop?: string;
}

const SubscriptionPrice = (props: {
  price: string;
  planType: FareDropPlan | string;
  isCrossedOut?: boolean;
  hide?: boolean;
  big?: boolean;
}) => {
  const { isExtraSmallScreenSize } = useDevice();

  const containerStyle: React.CSSProperties = {};
  const dollarSignStyle: React.CSSProperties = {};
  const priceStyle: React.CSSProperties = {
    lineHeight: 'inherit',
    borderColor: 'var(--ion-color-orange)',
    marginTop: '0px',
  };

  if (!isExtraSmallScreenSize) {
    priceStyle.paddingTop = '10px';
  }

  if (props.hide) {
    containerStyle.opacity = 0;
  }

  if (props.isCrossedOut) {
    priceStyle.color = 'grey';
    dollarSignStyle.color = 'grey';
    containerStyle.opacity = 0.3;
    containerStyle.paddingLeft = '2px';
  }

  let classNames = '';
  if (props.isCrossedOut) classNames += 'strike-through';

  const isNumber = !isNaN(parseInt(props.price));
  return (
    <div
      className={props.big ? 'big-subscription-price' : 'subscription-price'}
      style={{ ...containerStyle, fontSize: props.big ? '64px' : '48px' }}
    >
      <h2 style={priceStyle} className={classNames}>
        {isNumber && (
          <span className="dollar-sign" style={dollarSignStyle}>
            $
          </span>
        )}
        {isNumber ? parseInt(props.price) : props.price}
      </h2>
    </div>
  );
};

export const SubscriptionPlanCard: React.FC<ContainerProps> = ({
  buttonOnClickListener,
  border,
  billingText,
  billingSubText,
  buttonText,
  buttonTextColor,
  buttonColor,
  buttonStyle,
  discountPrice,
  discountImage,
  overridePrice,
  plan,
  sx,
  big,
  hideButton,
  hideBillingTextSection,
  titleSize,
  titleMarginTop,
}) => {
  const [loading, setLoading] = useState(false);
  const { isExtraSmallScreenSize } = useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const subTextClassName = `light-gray ${
    big
      ? isExtraSmallScreenSize
        ? 'small-big-text'
        : 'big-text'
      : 'small-text'
  }`;
  let featureContainerStyle: React.CSSProperties = {
    marginTop: '.5em',
    marginBottom: isExtraSmallScreenSize ? undefined : '.5em',
  };
  if (big) {
    featureContainerStyle = {
      ...featureContainerStyle,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    };
  }

  return (
    <IonCol
      className={big ? 'big-subscription-plan' : 'subscription-plan'}
      style={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        border: border ?? sx?.border ?? '2px solid #dadada',
        alignItems: 'center',
      }}
    >
      {/* TODO: Finish this - can be used to show discount media */}
      {discountImage && (
        <div
          style={{
            backgroundColor: 'var(--ion-color-primary)',
            position: 'absolute',
            top: '-30px',
            right: '-30px',
            height: '100px',
            width: '100px',
            borderRadius: '50px',
          }}
        ></div>
      )}
      <h4
        className={big ? 'big-plan-name' : 'plan-name'}
        style={{ fontSize: titleSize, marginTop: titleMarginTop }}
      >
        {plan.name}
      </h4>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {discountPrice && (
          <>
            <SubscriptionPrice
              price={plan.price}
              planType={plan.planType}
              hide={true}
              big={big}
            />
            <SubscriptionPrice
              price={discountPrice}
              planType={plan.planType}
              big={big}
            />
          </>
        )}
        <SubscriptionPrice
          price={overridePrice || plan.price}
          planType={plan.planType}
          isCrossedOut={!!discountPrice}
          big={big}
        />
      </div>
      {(!hideBillingTextSection || !hideBillingTextSection(plan.planType)) && (
        <>
          <p
            className={subTextClassName}
            style={{
              marginBottom: '5px',
              marginTop: '5px',
              visibility: !billingText ? 'hidden' : undefined,
            }}
          >
            {billingText ?? 'invisible'}
          </p>
          <p
            className={subTextClassName}
            style={{
              marginTop: '0px',
              visibility: !billingSubText ? 'hidden' : undefined,
            }}
          >
            {billingSubText ?? 'invisible'}
          </p>
        </>
      )}
      <div style={featureContainerStyle}>
        {plan?.features &&
          plan.features?.map((feature) => {
            return (
              <div
                key={feature}
                style={{
                  display: 'flex',
                  marginLeft: '2em',
                  marginRight: '2em',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {/* PROMOTION_IS_SHOPIFY_INTEGRATION */}
                {(feature.includes('FREE') ||
                  feature.includes('Credit') ||
                  feature.includes('Discount') ||
                  feature.includes('Punta Cana')) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '6px',
                      width: '22px',
                    }}
                  >
                    🎉
                  </div>
                )}
                {!feature.includes('FREE') &&
                  !feature.includes('Credit') &&
                  !feature.includes('Discount') &&
                  !feature.includes('Punta Cana') && (
                    <IonIcon
                      icon={checkmarkOutline}
                      className="checkmark-icon"
                    />
                  )}
                <p
                  style={{
                    fontSize: big
                      ? isExtraSmallScreenSize
                        ? '14px'
                        : '18px'
                      : '14px',
                    textAlign: 'left',
                    marginTop: '7px',
                    marginBottom: '7px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* PROMOTION_IS_SHOPIFY_INTEGRATION */}
                  {feature.includes('Travel Day Bundle') ? (
                    <a
                      href={`${process.env.REACT_APP_DAILY_DROP_SHOP_DOMAIN}/products/travel-day-bundle`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      {feature}
                    </a>
                  ) : feature.includes('Lonely Planet') ? (
                    <a
                      href="https://shop.lonelyplanet.com/collections/ebooks"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      {feature}
                    </a>
                  ) : feature.includes('Airport T-Shirt') ? (
                    <a
                      href={`${process.env.REACT_APP_DAILY_DROP_SHOP_DOMAIN}/collections/airport-diagram-t-shirts`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      {feature}
                    </a>
                  ) : feature.includes('Punta Cana') ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        goWithStickyParamsPath(AllowedPath.SUMMER24);
                      }}
                    >
                      {feature}
                    </a>
                  ) : (
                    feature
                  )}
                </p>
              </div>
            );
          })}
        {plan?.limitedFeatures &&
          plan.limitedFeatures?.map((feature) => {
            return (
              <div
                key={feature}
                style={{
                  display: 'flex',
                  marginLeft: '2em',
                  marginRight: '2em',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <IonIcon icon={checkmarkOutline} className="checkmark-icon" />
                <p
                  style={{
                    fontSize: big
                      ? isExtraSmallScreenSize
                        ? '14px'
                        : '18px'
                      : '14px',
                    textAlign: 'left',
                    marginTop: '7px',
                    marginBottom: '7px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {feature}
                </p>
              </div>
            );
          })}
        {!isExtraSmallScreenSize &&
          plan?.missingFeatures &&
          plan.missingFeatures.map((missingFeature) => {
            return (
              <div
                key={missingFeature}
                style={{
                  display: 'flex',
                  marginLeft: '2em',
                  marginRight: '2em',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <IonIcon
                  icon={closeCircleOutline}
                  className="alert-circle-icon"
                />
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'left',
                    color: 'var(--ion-color-gray)',
                    textDecoration: 'line-through',
                    marginTop: '7px',
                    marginBottom: '7px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {missingFeature}
                </p>
              </div>
            );
          })}
      </div>
      {!hideButton && (
        <div
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              if (buttonOnClickListener) {
                await buttonOnClickListener();
              }
              setLoading(false);
            }
          }}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <div
            className={
              loading
                ? 'subscription-plan-get-started'
                : 'subscription-plan-get-started pointer-on-hover'
            }
            style={{
              height: isExtraSmallScreenSize ? '45px' : '55px',
              padding: 0,
              display: 'flex',
              backgroundColor: buttonColor ?? undefined,
              opacity: loading ? 0.8 : '',
              margin: `${isExtraSmallScreenSize ? '10' : '25'}px 0 0 0`,
              ...buttonStyle,
            }}
          >
            {loading && (
              <IonSpinner name="crescent" style={{ margin: 'auto' }} />
            )}
            {loading || (
              <p
                className="small-text"
                style={{ margin: 'auto', color: buttonTextColor ?? undefined }}
              >
                {buttonText ?? 'Get started now!'}
              </p>
            )}
          </div>
        </div>
      )}
    </IonCol>
  );
};
