import { IonRow, IonCol } from '@ionic/react';
import { Dispatch, SetStateAction } from 'react';
import { hrefFromOption } from './SettingsSideMenu';
import './../theme/util.css';
import { DropdownOptionWithSubtext } from '../types/types';
import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  badge?: JSX.Element;
  expanded: boolean;
  hideNoResultsMessage?: boolean;
  hideNoResultOriginMessage?: boolean;
  hrefOptions?: boolean;
  options?: (string | DropdownOptionWithSubtext)[];
  optionSx?: React.CSSProperties;
  onOptionClick?: (args: string) => void;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  setSelectedValue?: (option: DropdownOptionWithSubtext) => void;
  sx?: React.CSSProperties;
  // Determines if a dropdown option should have a badge on it
  // Note: If the option has a badge, this option will not be clickable
  withBadge?: (option: string) => boolean;
}

const DropdownOptionsContainer: React.FC<ContainerProps> = ({
  badge,
  hideNoResultsMessage,
  hideNoResultOriginMessage,
  hrefOptions,
  expanded,
  options,
  optionSx,
  onOptionClick,
  setExpanded,
  setSelectedValue,
  sx,
  withBadge,
}) => {
  const { isExtraSmallScreenSize } = useDevice();
  return (
    <div
      className="dropdown-bottom"
      style={{
        backgroundColor: 'white',
        display: expanded ? 'flex' : 'none',
        borderRadius: '0px 0px 8px 8px',
        padding: '1em',
        position: 'absolute',
        ...sx,
      }}
    >
      <IonCol style={{ padding: 0 }}>
        {(options ?? []).map((option, i) => {
          let text: string;
          let subtext: string | undefined;

          if (typeof option === 'object') {
            const dropdownOption = option as DropdownOptionWithSubtext;
            text = dropdownOption.text;
            subtext = dropdownOption.subtext;
          } else {
            const dropdownOption = option as string;
            text = dropdownOption;
          }

          return (
            <IonRow
              className={`${
                withBadge && withBadge(text)
                  ? 'no-pointer-on-hover'
                  : 'pointer-on-hover dropdown-option'
              }`}
              key={i}
              style={{ padding: '.75em', height: 'auto' }}
              onClick={() => {
                setExpanded(false);
                if (setSelectedValue) {
                  setSelectedValue({
                    text,
                    subtext: subtext ?? '',
                  });
                }

                if (onOptionClick && hrefOptions) {
                  onOptionClick(hrefFromOption(text));
                } else if (onOptionClick) {
                  onOptionClick(text);
                }
              }}
            >
              <a
                onClick={(e) => {
                  if (withBadge && withBadge(text)) {
                    e.stopPropagation();
                  } else {
                    if (onOptionClick && hrefOptions) {
                      onOptionClick(hrefFromOption(text));
                    } else if (onOptionClick) {
                      onOptionClick(text);
                    }
                  }
                }}
                className={
                  withBadge && withBadge(text) ? 'no-pointer-on-hover' : ''
                }
                style={{
                  color: '#111',
                  lineHeight: isExtraSmallScreenSize ? '1.25em' : '1.5em',
                  opacity: withBadge && withBadge(text) ? 0.3 : 1,
                  maxWidth: withBadge && withBadge(text) ? '90%' : '100%',
                }}
              >
                {subtext && text ? (
                  <>
                    <span
                      style={{
                        float: 'left',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        ...optionSx,
                      }}
                    >
                      {text}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: 12,
                        float: 'left',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {subtext}
                    </span>
                  </>
                ) : (
                  <span style={{ ...optionSx }}>{text ?? subtext}</span>
                )}
              </a>
              {withBadge && withBadge(text) && badge}
            </IonRow>
          );
        })}
        {options && options.length === 0 && !hideNoResultsMessage && (
          <IonRow style={{ textAlign: 'initial', lineHeight: '1.25em' }}>
            No results found. Please try a different city or airport.
            {!hideNoResultOriginMessage && (
              <>
                <br />
                <br />
                <small>
                  FareDrop currently only searches for deals departing from
                  major international airports in the United States and Canada.
                </small>
              </>
            )}
          </IonRow>
        )}
      </IonCol>
    </div>
  );
};

export default DropdownOptionsContainer;
