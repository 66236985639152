import { IonCol } from '@ionic/react';
import { useState, useEffect, FC, Fragment } from 'react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useLogError from '../hooks/logError';
import { useDevice } from '../hooks/useDevice';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import './../theme/HeroSection.css';
import './../theme/util.css';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

enum TextLocation {
  TOP = 0,
  MIDDLE = 1,
  BOTTOM = 2,
}

type Deal = {
  src: string;
  location: string;
  textLocation: TextLocation;
  percent?: number;
  priceOff?: number;
  price?: number;
  brightnessPercent?: number;
};

const HeroPhoneDealsSection: FC<ContainerProps> = ({
  logAnalyticsEngagement,
}) => {
  const [numDealsLoaded, setNumDealsLoaded] = useState(0);
  const [deals, setDeals] = useState<Deal[]>([]);
  const { smoothScroll } = useLandingPageNavigation();
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { logError } = useLogError();

  useEffect(() => {
    const init = async () => {
      const raw = await fetch(
        `${getAssetsBaseUrl()}/data/landing-page-deals/hero-phone-deals.json`
      );
      const data = (await raw.json()) as Deal[];
      setDeals(data);
    };

    init().catch(async (err) => {
      logError(
        'Error loading hero-phone-deals.json',
        err && (err as Error).message
          ? (err as Error).message
          : 'Error or error message is undefined'
      ).catch((error) =>
        console.warn(
          'Landing page failed to render phone deals and then send error log',
          error
        )
      );
    });
  }, []);

  const onImageLoaded = () => {
    setNumDealsLoaded(numDealsLoaded + 1);
  };

  return (
    <>
      {!isLargeScreenSizeOrSmaller && (
        <img
          src={`${getAssetsBaseUrl()}/graphics/hero.png`}
          className="mobile-hide"
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
            opacity: 0.85,
            marginTop: '5.5em',
            width: '45%',
            right: '3.5%',
            position: 'absolute',
          }}
        />
      )}
      <IonCol
        size="6"
        className="phone-col-container"
        style={{
          opacity:
            deals && deals?.length > 0 && numDealsLoaded == deals?.length
              ? 1
              : 0,
          transition: 'opacity 1s',
        }}
      >
        <img
          className="landing-phone-outline"
          src={`${getAssetsBaseUrl()}/graphics/phone-outline.png`}
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
            marginTop: '2em',
            marginRight: '2em',
            position: 'absolute',
            height: '70%',
          }}
        />
        {(deals ?? []).map((deal, i) => {
          return (
            <Fragment key={`${deal.src}${i}`}>
              <img
                className="landing-phone-image pointer-on-hover"
                src={`${getAssetsBaseUrl()}/phone-deals/${deal.src}.png`}
                onLoad={onImageLoaded}
                onClick={async () => {
                  smoothScroll('#register');
                  logAnalyticsEngagement &&
                    (await logAnalyticsEngagement(
                      AnalyticsEngagementId.HERO_GET_STARTED
                    ));
                }}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'top',
                  marginTop: '2em',
                  marginRight: '2em',
                  position: 'absolute',
                  height: '70%',
                  filter: `sepia(10%) brightness(${
                    deal.brightnessPercent ?? '100'
                  }%)`,
                }}
              />
              <div
                className="phone-text-div pointer-on-hover"
                style={{
                  color: 'white',
                  marginTop:
                    deal.textLocation === TextLocation.BOTTOM
                      ? '30em'
                      : deal.textLocation === TextLocation.MIDDLE
                      ? '17em'
                      : '5em',
                  marginRight: '2.5em',
                  height: '100%',
                  position: 'absolute',
                }}
                onClick={async () => {
                  smoothScroll('#register');
                  logAnalyticsEngagement &&
                    (await logAnalyticsEngagement(
                      AnalyticsEngagementId.HERO_GET_STARTED
                    ));
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: 800,
                  }}
                >
                  {(deal.price || deal.priceOff) && (
                    <div
                      style={{
                        fontSize: deal.priceOff ? 30 : 35,
                        marginTop: deal.priceOff ? 35 : 40,
                        marginRight: -4,
                      }}
                    >
                      <p style={{ margin: 0, lineHeight: '.6em' }}>$</p>
                    </div>
                  )}
                  <p
                    style={{
                      fontSize: deal.priceOff ? 70 : 90,
                      margin: 10,
                    }}
                  >
                    {deal.percent ?? deal.priceOff ?? deal.price}
                  </p>
                  {(deal.priceOff || deal.percent) && (
                    <div
                      style={{ fontSize: 35, marginTop: 40, marginLeft: -2 }}
                    >
                      {deal.percent && (
                        <p style={{ margin: 0, lineHeight: '.6em' }}>%</p>
                      )}
                      <p style={{ margin: 0 }}>OFF</p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    backgroundColor: 'var(--ion-color-primary)',
                    fontWeight: 800,
                    fontSize: 10,
                    padding: '6px 12px',
                    width: 'fit-content',
                    letterSpacing: 4,
                    marginLeft: 10,
                    marginTop: '-1.5em',
                  }}
                >
                  <p style={{ margin: 0, textAlign: 'center' }}>
                    FLIGHTS TO {deal.location.toUpperCase()}
                  </p>
                </div>
              </div>
            </Fragment>
          );
        })}
      </IonCol>
    </>
  );
};

export default HeroPhoneDealsSection;
