import { Tooltip } from '@mui/material';

import { Deal, VerifiedAirfare } from '@faredrop/graphql-sdk';

import useUser from '../hooks/user';
import { LandingDeal } from '../types/types';
import {
  BadgeLabel,
  buildDealBadges,
  buildVerifiedAirfareBadges,
} from '../utilities/deals-utilities';
import FlightBadgeIcon from './FlightBadgeIcon';
import { useDevice } from '../hooks/useDevice';

export type BadgeSize = 'small' | 'large';
interface ContainerProps {
  deal?: Pick<
    Deal | LandingDeal,
    'destinationIATA' | 'seatClass' | 'attributes'
  >;
  verifiedAirfare?: Pick<VerifiedAirfare, 'attributes'>;
  size?: 'small' | 'large';
  fontSize?: string;
  includeLabels?: boolean;
  color?: string;
  backgroundColor?: string;
  hideBackground?: boolean;
  exclude?: string[];
  createOrUpgrade?: BadgeLabel.Create | BadgeLabel.Upgrade;
  containerStyle?: React.CSSProperties;
  badgeStyle?: React.CSSProperties;
  width?: number;
  height?: number;
  onClick?: (badge: IFlightBadge) => Promise<void>;
}

export type IFlightBadge = {
  label: string;
  iconStyle?: React.CSSProperties;
  backgroundColor?: string;
  textColor?: string;
};

const FlightBadges: React.FC<ContainerProps> = ({
  deal,
  verifiedAirfare,
  size,
  fontSize,
  includeLabels,
  color,
  backgroundColor,
  hideBackground,
  exclude,
  createOrUpgrade,
  containerStyle,
  badgeStyle,
  width,
  height,
  onClick,
}) => {
  const userState = useUser();
  const { isSmallScreenSizeOrSmaller } = useDevice();

  size = size ?? 'large';
  exclude = exclude ?? [];

  const badges: IFlightBadge[] = deal
    ? buildDealBadges(
        deal,
        userState.user,
        color,
        size,
        exclude,
        createOrUpgrade
      )
    : buildVerifiedAirfareBadges(
        verifiedAirfare,
        color,
        size,
        exclude,
        createOrUpgrade
      );

  return (
    <div
      style={{
        position: 'absolute',
        top: '.5em',
        left: '.5em',
        display: 'flex',
        ...containerStyle,
      }}
    >
      {badges.map((badge: IFlightBadge) => {
        return (
          <Tooltip
            title={badge.label}
            key={badge.label}
            disableHoverListener={includeLabels}
          >
            <div
              className="deal-card-badge-wrapper"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: includeLabels ? '10px' : '2px',
                marginRight: '.5em',
                borderRadius: '50px',
                height: height ? `${height}px` : '30px',
                width: includeLabels
                  ? undefined
                  : width
                  ? `${width}px`
                  : '30px',
                backgroundColor: hideBackground
                  ? undefined
                  : backgroundColor ??
                    badge.backgroundColor ??
                    'rgba(63, 140, 132)',
                boxShadow: hideBackground
                  ? undefined
                  : 'box-shadow: 0px 6px 10px rgba(0, 0, 0, 15%)',
                ...badgeStyle,
              }}
              onClick={async () => {
                onClick && (await onClick(badge));
              }}
            >
              <FlightBadgeIcon badge={badge} style={badge.iconStyle} />
              {includeLabels && (
                <p
                  style={{
                    color: color ?? 'white',
                    paddingLeft: '5px',
                    paddingTop: '3px',
                    fontSize:
                      fontSize ??
                      (isSmallScreenSizeOrSmaller ? '14px' : '16px'),
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {badge.label}
                </p>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default FlightBadges;
