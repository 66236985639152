// Source: https://developers.google.com/recaptcha/docs/faq#can-i-customize-the-recaptcha-widget-or-badge

const GoogleRecaptchaNotice: React.FC = () => {
  return (
    <div
      style={{
        color: '#696969',
        fontFamily: 'nexa',
        paddingTop: '10px',
        lineHeight: '1.5em',
        textAlign: 'center',
      }}
    >
      <small>
        This site is protected by reCAPTCHA. The Google&nbsp;
        <br />
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 800 }}
        >
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 800 }}
        >
          Terms of Service
        </a>
        &nbsp; apply.
      </small>
    </div>
  );
};

export default GoogleRecaptchaNotice;
