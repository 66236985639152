import { IonCol, IonRow } from '@ionic/react';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import './../theme/DealsSection.css';
import './../theme/util.css';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import { useDevice } from '../hooks/useDevice';
import { useEffect, useRef, useState } from 'react';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  sx?: React.CSSProperties;
}

const HowItWorksSection: React.FC<ContainerProps> = ({ sx }) => {
  const { isMediumScreenSizeOrSmaller } = useDevice();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slidesRef = useRef<SwiperRef>(null);

  useEffect(() => {
    slidesRef.current?.swiper.slideTo(currentSlideIndex);
  }, [currentSlideIndex]);

  const steps = [
    {
      src: 'step1',
      text: 'Let us know what airports you are interested in flying out of.',
    },
    {
      src: 'step2',
      text: 'Make sure you only get notified about deals in regions that you are interested in traveling to.',
    },
    {
      src: 'step3',
      text: 'Get notified about amazing deals that you can book using your favorite booking site or application!',
    },
  ];

  return (
    <>
      <h2
        id="how-it-works"
        className="title-font check-out-deals-title p-0"
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: isMediumScreenSizeOrSmaller ? 0 : '2em',
        }}
      >
        How it works
      </h2>
      <hr
        style={{
          borderTop: '1.5px solid #222',
          marginBottom: '1.5em',
          width: 200,
        }}
      />
      <IonRow
        className="no-flex-wrap"
        id="find-deals"
        style={{
          ...sx,
          paddingTop: isMediumScreenSizeOrSmaller ? '1em' : '2em',
          paddingBottom: isMediumScreenSizeOrSmaller ? '0em' : '3em',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IonCol sizeXl="10" sizeLg="11">
          <IonRow>
            {isMediumScreenSizeOrSmaller ? (
              <>
                <Swiper
                  ref={slidesRef}
                  style={{ overflowX: 'scroll' }}
                  slidesPerView={1}
                  onSlideNextTransitionEnd={async () => {
                    if (
                      slidesRef.current?.swiper.activeIndex != currentSlideIndex
                    ) {
                      setCurrentSlideIndex(currentSlideIndex + 1);
                    }
                  }}
                  onSlidePrevTransitionEnd={async () => {
                    if (
                      slidesRef.current?.swiper.activeIndex != currentSlideIndex
                    ) {
                      setCurrentSlideIndex(currentSlideIndex - 1);
                    }
                  }}
                >
                  {steps.map((step) => {
                    return (
                      <SwiperSlide key={step.src} style={{ display: 'block' }}>
                        <img
                          src={`${getAssetsBaseUrl()}/graphics/how-it-works/${
                            step.src
                          }.png`}
                          style={{
                            padding: '0em 1em',
                            WebkitMaskImage:
                              'linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0))',
                            maskImage:
                              'linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0))',
                          }}
                        />
                        <p
                          style={{
                            textAlign: 'center',
                            marginTop: '2em',
                            width: '100%',
                            lineHeight: '1.5em',
                            padding: '0 5em',
                            color: 'var(--ion-color-gray)',
                            fontSize: 16,
                          }}
                        >
                          {step.text}
                        </p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '2em',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  {[...Array(steps.length).keys()].map((page, i) => {
                    return (
                      <div
                        key={page}
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          background: '#999',
                          margin: 5,
                          opacity: currentSlideIndex == i ? 1 : 0.2,
                        }}
                        onClick={() => {
                          setCurrentSlideIndex(i);
                        }}
                      ></div>
                    );
                  })}
                </div>
              </>
            ) : (
              steps.map((step) => {
                return (
                  <IonCol key={step.src}>
                    <img
                      src={`${getAssetsBaseUrl()}/graphics/how-it-works/${
                        step.src
                      }.png`}
                      style={{
                        padding: '0em 3em',
                        WebkitMaskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0))',
                        maskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 80%, rgba(0,0,0,0))',
                      }}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: '4em',
                        width: '100%',
                        padding: '0 5em',
                        lineHeight: '1.5em',
                        color: 'var(--ion-color-gray)',
                        fontSize: 16,
                      }}
                    >
                      {step.text}
                    </p>
                  </IonCol>
                );
              })
            )}
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default HowItWorksSection;
