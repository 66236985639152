import {
  GetActiveFareDropPlansQuery,
  SubscriptionPlan,
} from '@faredrop/graphql-sdk';
import useFareDropApiClientWithTimeout from './faredropApiClientWithTimeout';

import useSWRTimeout from './useSWRTimeout';
import useAnalytics from './analytics';
import useAuth from './auth';
import { buildFrontendSubscriptionPlans } from '../utilities/plans-utilities';

export interface PlansValue {
  plans?: SubscriptionPlan[];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  timeout: boolean;
  mutate: (
    data?: GetActiveFareDropPlansQuery['getActiveFareDropPlans']['plans'],
    shouldRevalidate?: boolean
  ) => Promise<
    GetActiveFareDropPlansQuery['getActiveFareDropPlans']['plans'] | undefined
  >;
}

const usePlans = (coupon?: string): PlansValue => {
  const { client } = useFareDropApiClientWithTimeout();
  const { logAnalyticsError } = useAnalytics();
  const { isInitialized, isAuthenticated } = useAuth();

  const userPlansKey = (coupon?: string) => [
    'PLANS',
    process.env.REACT_APP_VERSION ?? '',
    coupon ?? 'NO_COUPON',
    isInitialized && isAuthenticated ? 'AUTH' : 'NO_AUTH',
  ];

  const requestKey = userPlansKey(coupon);

  const { data, error, mutate, timeout, isValidating } = useSWRTimeout(
    requestKey,
    async () => {
      try {
        if (!isInitialized || !isAuthenticated) {
          return buildFrontendSubscriptionPlans();
        }

        return (
          await client.getActiveFareDropPlans({
            coupon,
          })
        ).data.getActiveFareDropPlans.plans;
      } catch (error) {
        await logAnalyticsError('getActiveFareDropPlans', error as Error);
        throw error;
      }
    },
    undefined,
    'Failed to get subscription plans. Please refresh the page'
  );

  return {
    plans: data,
    isInitializing: !error && !data,
    isLoading: isValidating,
    error,
    timeout,
    mutate,
  };
};

export default usePlans;
