import { useState } from 'react';
import { IonButton, IonCol, IonRow, IonSpinner } from '@ionic/react';

import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  imageSrc: string;
  title: string;
  ctaText: string;
  targetHref?: string;
  imageStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  description?: string | React.ReactNode;
  ctaButton?: boolean; // Use a button instead of a link
  onTermsClick?: () => void;
  onCTAClick?: () => Promise<string | undefined>;
  onTarget?: () => void;
}

const ReviewCard: React.FC<ContainerProps> = ({
  imageSrc,
  title,
  ctaText,
  targetHref,
  imageStyle,
  textStyle,
  style,
  description,
  ctaButton,
  onTermsClick,
  onCTAClick,
  onTarget,
}) => {
  const { isLargeScreenSizeOrSmaller, isExtraLargeScreenSize } = useDevice();

  const [loading, setLoading] = useState(false);
  const [linkResult, setLinkResult] = useState<string | undefined>();

  const cta = async () => {
    if (onCTAClick) {
      setLoading(true);
      try {
        setLinkResult(await onCTAClick());
      } finally {
        setLoading(false);
      }
    } else {
      window.open(targetHref, '_blank');
    }
  };

  return (
    <IonCol
      sizeXs="8"
      sizeLg="3"
      style={{
        backgroundColor: 'white',
        height: '250px',
        width: '250px',
        boxShadow: '0px 5px 15px rgba(0, 0, 0, .2)',
        marginBottom: '3em',
        padding: isLargeScreenSizeOrSmaller ? '1em' : '2em',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <a
        rel="noreferrer"
        onClick={() => {
          if (onTarget) {
            onTarget();
          } else {
            window.open(targetHref, '_blank');
          }
        }}
      >
        <img
          src={imageSrc}
          style={{
            width: isExtraLargeScreenSize ? '70%' : '100%',
            margin: '0px 8px 5px 0px',
            color: 'black',
            ...imageStyle,
          }}
        />
      </a>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          ...textStyle,
        }}
        onClick={() => {
          if (onTarget) {
            onTarget();
          }
        }}
      >
        <h1
          style={{
            marginTop: '5px',
            fontSize: isLargeScreenSizeOrSmaller ? '18px' : undefined,
            marginBottom: '0px',
          }}
        >
          {title}
        </h1>
        {description && typeof description === 'string' && (
          <p
            style={{
              color: 'var(--ion-color-gray)',
              marginTop: '10px',
              fontSize: '14px',
            }}
          >
            {description}
          </p>
        )}
        {description && typeof description !== 'string' && <>{description}</>}
        <div
          style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}
        >
          {!loading && linkResult && <div>{linkResult}</div>}
          {!linkResult &&
            (ctaButton ? (
              <IonRow
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonButton onClick={cta}>
                  {(loading && (
                    <IonSpinner color="white" name="crescent" />
                  )) || <p style={{ color: 'white' }}>{ctaText}</p>}
                </IonButton>
              </IonRow>
            ) : (
              <>
                {loading && (
                  <IonSpinner style={{ marginLeft: '2em' }} name="crescent" />
                )}
                {!loading && (
                  <a style={{ textDecoration: 'underline' }} onClick={cta}>
                    {ctaText}
                  </a>
                )}
              </>
            ))}
        </div>
        {onTermsClick && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onTermsClick();
            }}
            style={{
              paddingTop: '25px',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              color: 'var(--ion-color-gray)',
              cursor: 'pointer',
            }}
          >
            Terms and Conditions
          </div>
        )}
      </div>
    </IonCol>
  );
};

export default ReviewCard;
