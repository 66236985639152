import { IonButton, IonRow } from '@ionic/react';
import _ from 'lodash';

import { AllowedPath } from '@faredrop/types';
import { isLimitedPlan } from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import { useDevice } from '../../hooks/useDevice';
import useHistoryWithStickyParams from '../../hooks/historyWithStickyParams';
import {
  HIDE_MEMBERSHIP_LINKS,
  SubscriptionPromotionRegisterCTA,
  SubscriptionPromotionUpgradeCTA,
} from '../../utilities/constants';
import { isFrontendSubscriptionPromotion } from '../../utilities/plans-utilities';
import useUser from '../../hooks/user';

interface ContainerProps {
  hasAccess: boolean;
  noUser?: boolean;
  search?: string;
}

const DealDetailsRegisterOrUpgrade: React.FC<ContainerProps> = ({
  hasAccess,
  noUser,
  search,
}) => {
  const {
    isExtraSmallScreenSize,
    isMediumScreenSizeOrSmaller,
    isSmallScreenSizeOrSmaller,
  } = useDevice();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const userState = useUser();

  return (
    <IonRow
      style={{
        display: 'flex',
        marginBottom: isMediumScreenSizeOrSmaller
          ? isSmallScreenSizeOrSmaller
            ? '1em'
            : '2em'
          : '3em',
        marginTop: isMediumScreenSizeOrSmaller
          ? isSmallScreenSizeOrSmaller
            ? '1em'
            : '2em'
          : '1em',
        width: '65%',
        flexDirection: 'column',
        fontSize: isExtraSmallScreenSize ? '22px' : '30px',
      }}
    >
      <IonRow
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          fontSize: isExtraSmallScreenSize ? '22px' : '30px',
          marginBottom: '1em',
          lineHeight: '1.25em',
          textAlign: 'center',
        }}
      >
        {noUser ? 'Create an account ' : 'Upgrade to FareDrop Pro '}
        {` ${
          hasAccess
            ? 'and unlock deals to over 250 other destinations'
            : 'to unlock this deal'
        }`}
        {noUser ? ` - it's FREE!` : ''}
      </IonRow>
      {!HIDE_MEMBERSHIP_LINKS &&
        isFrontendSubscriptionPromotion(
          isLimitedPlan(userState.user?.billing.idStripePlan)
        ) && (
          <div
            style={{
              marginBottom: '2em',
              color: 'var(--ion-color-gray)',
              textAlign: 'center',
            }}
          >
            {noUser
              ? SubscriptionPromotionRegisterCTA
              : SubscriptionPromotionUpgradeCTA}
          </div>
        )}
      <IonRow
        style={{
          flex: 1,
          justifyContent: isExtraSmallScreenSize ? 'space-evenly' : 'center',
          alignItems: 'center',
        }}
      >
        <IonButton
          style={{
            marginRight: isExtraSmallScreenSize ? undefined : '1em',
            width: noUser ? '125px' : undefined,
          }}
          onClick={async () => {
            if (noUser) {
              goWithStickyParamsLocation({
                pathname: AllowedPath.LOGIN,
                search,
              });
            } else {
              goWithStickyParamsLocation({
                pathname: AllowedPath.PLANS,
                search,
              });
            }
          }}
        >
          <p style={{ color: 'white', fontSize: '20px' }}>
            {noUser
              ? 'Login'
              : `Upgrade ${
                  isSmallScreenSizeOrSmaller
                    ? 'now!'
                    : 'for more incredible deals!'
                }`}
          </p>
        </IonButton>
        {noUser && (
          <IonButton
            style={{
              marginLeft: isExtraSmallScreenSize ? undefined : '1em',
              width: '125px',
            }}
            onClick={async () => {
              goWithStickyParamsLocation({
                pathname: AllowedPath.REGISTER,
                search,
              });
            }}
          >
            <p style={{ color: 'white', fontSize: '20px' }}>Sign Up</p>
          </IonButton>
        )}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsRegisterOrUpgrade;
