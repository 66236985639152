import { IonCol, IonRow } from '@ionic/react';
import { Dispatch, SetStateAction, useState } from 'react';

import { FareDropPlan, SubscriptionPlan } from '@faredrop/graphql-sdk';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import { useDevice } from '../hooks/useDevice';
import Modal from './MobileModal';
import usePlans from '../hooks/usePlans';
import SubscriptionPlansSection from './SubscriptionPlansSection';
import Loading from './Loading';
import { getPlanName } from '../utilities/plans-utilities';

interface ContainerProps {
  selectedPlan?: FareDropPlan;
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  onSubmit: (plan: SubscriptionPlan) => Promise<void>;
}

const PromotionFreebieModal: React.FC<ContainerProps> = ({
  selectedPlan,
  setIsModalActive,
  onSubmit,
}) => {
  const {
    isSmallScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
    isLargeScreenSizeOrSmaller,
  } = useDevice();
  const { plans } = usePlans();
  const [loading, setLoading] = useState(false);

  const proPlan = plans?.find((p) => p.planType === FareDropPlan.Pro);

  const title = `For a limited time, receive a FREE Travel Day Bundle and be entered for a chance to win a free trip to Punta Cana when you purchase a ${getPlanName(
    FareDropPlan.Pro
  )} Subscription!`;
  const titleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: isMediumScreenSizeOrSmaller ? '2.5em' : '1.5em',
  };

  const continueWithOriginalPlan = selectedPlan ? (
    <div
      style={{
        textDecoration: 'underline',
        color: 'var(--ion-color-light-gray)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: '1em',
        textAlign: 'center',
        marginTop: '1em',
      }}
      onClick={async () => {
        const selectedSubscriptionPlan = plans?.find(
          (p) => p.planType === selectedPlan
        );
        if (selectedSubscriptionPlan) {
          setLoading(true);
          await onSubmit(selectedSubscriptionPlan);
        }
      }}
    >
      No thanks, continue with {getPlanName(selectedPlan)} Plan
    </div>
  ) : (
    <></>
  );

  return (
    <Modal
      active={!!selectedPlan}
      modalListener={setIsModalActive}
      size={isMediumScreenSizeOrSmaller ? '12' : '9'}
      darkBg={true}
      showAsFullPage={isMediumScreenSizeOrSmaller}
      height="100%"
      sx={{
        position: isMediumScreenSizeOrSmaller ? 'absolute' : 'absolute',
      }}
      closeButtonMargin={isMediumScreenSizeOrSmaller ? '10px' : undefined}
    >
      {proPlan && selectedPlan && (
        <IonRow
          style={{
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
          }}
        >
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                marginBottom: '3em',
                marginTop: '3em',
              }}
            >
              <Loading />
            </div>
          )}
          {!loading && (
            <>
              {isSmallScreenSizeOrSmaller && (
                <h6 style={titleStyle}>{title}</h6>
              )}
              {!isSmallScreenSizeOrSmaller && (
                <h1 style={titleStyle}>{title}</h1>
              )}
              {continueWithOriginalPlan}
              <IonRow style={{ maxWidth: '90%' }}>
                <IonCol
                  sizeXl="6"
                  size="12"
                  style={{
                    paddingLeft: isLargeScreenSizeOrSmaller
                      ? undefined
                      : '2.5em',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={`${getAssetsBaseUrl()}/kara-and-nate/summer24/shop2.jpg`}
                      style={{ borderRadius: '5px', maxHeight: '500px' }}
                    ></img>
                  </div>
                </IonCol>
                <IonCol
                  sizeXl="6"
                  size="12"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <SubscriptionPlansSection
                    title={null}
                    hidePlans={[FareDropPlan.Limited, FareDropPlan.Global]}
                    removeBottomMargin={true}
                    selectedPlan={proPlan}
                    selectedPlanButtonText={`Choose ${getPlanName(
                      FareDropPlan.Pro
                    )}`}
                    onSelect={async () => {
                      setLoading(true);
                      await onSubmit(proPlan);
                    }}
                  />
                </IonCol>
              </IonRow>
            </>
          )}
        </IonRow>
      )}
    </Modal>
  );
};

export default PromotionFreebieModal;
