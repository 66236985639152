import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';
import usePresentToast, { PresentOption } from '../hooks/presentToast';
import useUser from '../hooks/user';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import { FareDropRole, SeatClass } from '@faredrop/graphql-sdk';
import useAuth from '../hooks/auth';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

// Since we set the `BadUserSettingsToastProvider` to wrap our entire application in App.tsx, this is basically boilerplate code that keeps Typescript happy
const BadUserSettingsToastContext = createContext({});

interface BadUserSettingsToastProps {
  children: ReactNode;
}

export const BadUserSettingsToastProvider: FC<BadUserSettingsToastProps> = (
  props
) => {
  const location = useLocation();
  const { presentOptions, dismiss } = usePresentToast();
  const [
    hasBadUserSettingsToastDisplayed,
    setHasBadUserSettingsToastDisplayed,
  ] = useState(false);
  const { isAuthenticated } = useAuth();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const userState = useUser();
  const userConfigOrigins = userState.user?.configuration?.origins ?? [];
  const userConfigTravelAvailability =
    userState.user?.configuration.travelMonths;
  const userConfigDestinationRegions =
    userState.user?.configuration.destinationRegions;
  const userConfigHomeAirport = userState.user?.configuration.homeOriginIATA;

  const getToastAttributes = () => {
    const baselineChecks =
      !userState.isInitializing &&
      userState.user?.profile.roles.includes(FareDropRole.Limited) &&
      !hasBadUserSettingsToastDisplayed;
    if (baselineChecks && userConfigOrigins.length === 0) {
      // If no origin IATAs
      return {
        infoText:
          'No home airports set. Please add a home airport to start seeing deals!',
        mainAction: {
          title: 'Add Home Airport',
          action: async (key: number) => {
            goWithStickyParamsPath(AllowedPath.SETTINGS_DEPARTURE_AIRPORTS);
            dismiss(key);
            setHasBadUserSettingsToastDisplayed(true);
          },
          style: {
            fontWeight: 'bold',
          },
        } as PresentOption,
      };
    } else if (
      baselineChecks &&
      !userConfigTravelAvailability?.some((month) => month.enabled === true)
    ) {
      // If no travel availability months
      return {
        infoText:
          'No travel months set. Please add travel months to start seeing deals!',
        mainAction: {
          title: 'Add Travel Months',
          action: async (key: number) => {
            goWithStickyParamsPath(AllowedPath.SETTINGS_TRAVEL_AVAILABILITY);
            dismiss(key);
            setHasBadUserSettingsToastDisplayed(true);
          },
          style: {
            fontWeight: 'bold',
          },
        } as PresentOption,
      };
    } else if (
      baselineChecks &&
      !userConfigDestinationRegions?.some((region) => region.enabled === true)
    ) {
      // If no regions enabled
      return {
        infoText:
          'No destination regions enabled. Please enable a destination region to start seeing deals!',
        mainAction: {
          title: 'Enable Regions',
          action: async (key: number) => {
            goWithStickyParamsPath(
              AllowedPath.SETTINGS_DESTINATION_PREFERENCES
            );
            dismiss(key);
            setHasBadUserSettingsToastDisplayed(true);
          },
          style: {
            fontWeight: 'bold',
          },
        } as PresentOption,
      };
    } else if (
      baselineChecks &&
      (userConfigDestinationRegions?.filter(
        (region) => region.maxPriceEconomy && region.maxPriceEconomy < 250
      ).length === userConfigDestinationRegions?.length ||
        (hasSeatClassAccessGQL(
          userState.user?.profile.roles,
          SeatClass.Business
        ) &&
          userConfigDestinationRegions?.filter(
            (region) => region.maxPriceBusiness && region.maxPriceBusiness < 500
          ).length === userConfigDestinationRegions?.length))
    ) {
      // If max prices are set too low for all regions
      return {
        infoText:
          'Max prices set too low. Please raise your max prices to start seeing deals!',
        mainAction: {
          title: 'Raise Prices',
          action: async (key: number) => {
            goWithStickyParamsPath(
              AllowedPath.SETTINGS_DESTINATION_PREFERENCES
            );
            dismiss(key);
            setHasBadUserSettingsToastDisplayed(true);
          },
          style: {
            fontWeight: 'bold',
          },
        } as PresentOption,
      };
    } else if (baselineChecks && !userConfigHomeAirport) {
      // If a legacy user does not yet have a home airport selected
      return {
        infoText:
          'Please select a home airport from your list of departure airports.',
        mainAction: {
          title: 'Select Home Airport',
          action: async (key: number) => {
            goWithStickyParamsPath(AllowedPath.SETTINGS_DEPARTURE_AIRPORTS);
            dismiss(key);
            setHasBadUserSettingsToastDisplayed(true);
          },
          style: {
            fontWeight: 'bold',
          },
        } as PresentOption,
      };
    } else {
      return {
        infoText: undefined,
        mainAction: undefined,
      };
    }
  };

  useEffect(() => {
    const { infoText, mainAction } = getToastAttributes();

    if (
      isAuthenticated &&
      infoText !== undefined &&
      mainAction !== undefined &&
      location.pathname.indexOf('/get-started') === -1
    ) {
      presentOptions(
        infoText,
        [
          mainAction,
          {
            title: 'Dismiss',
            action: async (key) => {
              setHasBadUserSettingsToastDisplayed(true);
              dismiss(key);
            },
          },
        ],
        {
          persist: true,
        }
      );
    }
  }, [userConfigOrigins, location.pathname, userState.isInitializing]);

  return (
    <BadUserSettingsToastContext.Provider value={{}}>
      {props.children}
    </BadUserSettingsToastContext.Provider>
  );
};

export default BadUserSettingsToastContext;
