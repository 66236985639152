import { useContext } from 'react';

import InactiveAirportsContext, {
  InactiveAirportsContextValue,
} from '../contexts/inactiveAirportsContext';

const useInactiveAirports = (): InactiveAirportsContextValue =>
  useContext(InactiveAirportsContext);

export default useInactiveAirports;
