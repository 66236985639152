import { IonCol, IonRow } from '@ionic/react';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FlightSegment, VerifiedAirfare } from '@faredrop/graphql-sdk';
import { FlightDirection } from '@faredrop/types';
import { formatDuration, getAssetsBaseUrl } from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import MapboxMap from '../MapboxMap';
import useAirports from '../../hooks/airports';
import FlightBadges from '../FlightBadges';
import { BadgeLabel } from '../../utilities/deals-utilities';
import DealDetailsRouteDiagram from './DealDetailsRouteDiagram';
import DealDetailsSegmentDiagram from './DealDetailsSegmentDiagram';
import DealDetailsAircraftAndBaggageDetails from './DealDetailsAircraftAndBaggageDetails';
import { useDevice } from '../../hooks/useDevice';

interface ContainerProps {
  hasAccess: boolean;
  airfare?: VerifiedAirfare;
  isLoading?: boolean;
  onHiddenDetailClick?: () => void;
}

const DealDetailsAirfareDetails: React.FC<ContainerProps> = ({
  airfare,
  isLoading,
  hasAccess,
  onHiddenDetailClick,
}) => {
  const { airports } = useAirports();
  const {
    isMediumScreenSizeOrSmaller,
    isScreenSmallerThanSize,
    isExtraSmallScreenSize,
    isLargeScreenSize,
  } = useDevice();

  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>();
  const [selectedSegmentFlightDirection, setSelectedSegmentFlightDirection] =
    useState<FlightDirection>();

  let selectedRoute: FlightSegment[] | undefined = undefined;
  let selectedSegment: FlightSegment | undefined = undefined;
  if (selectedSegmentFlightDirection && selectedSegmentIndex != null) {
    selectedRoute = isLoading
      ? []
      : (selectedSegmentFlightDirection === FlightDirection.RETURN
          ? airfare?.returningRoute
          : airfare?.departingRoute) ?? undefined;
    selectedSegment =
      selectedRoute && selectedSegmentIndex != null
        ? selectedRoute[selectedSegmentIndex]
        : undefined;
  }

  // IonContent's scrollEvents=true is causing MapBoxMap useLayoutEffect hook to be called onScroll - so we need to memoize
  const memoizedMap = useMemo(() => {
    if (
      !airports ||
      selectedSegmentIndex == null ||
      selectedSegmentFlightDirection == null ||
      airfare?.departureDate == null
    ) {
      return <></>;
    }

    return (
      <>
        <div className="mapboxMap" />
        <MapboxMap
          lineBetweenOrigins={true}
          segments={selectedRoute}
          selectedSegment={selectedSegment}
          sx={{
            minHeight: '300px',
            height: isMediumScreenSizeOrSmaller ? '300px' : undefined,
          }}
          viewLoaded={
            !!airfare?.departureDate &&
            selectedSegment != null &&
            selectedRoute != null
          }
          onLineSelect={(segment) => {
            const index = selectedRoute?.findIndex(
              (s) =>
                s.originIATA === segment.originIATA &&
                s.destinationIATA === segment.destinationIATA
            );
            setSelectedSegmentIndex(index);
          }}
        />
      </>
    );
  }, [
    isLoading,
    airports,
    selectedSegmentIndex,
    selectedSegmentFlightDirection,
    airfare?.departureDate,
  ]);

  // Set the selected segment
  useEffect(() => {
    // Ensure airports are loaded since we need them for passing to map component
    if (airfare && airfare.departingRoute && airports) {
      setSelectedSegmentIndex(0);
      setSelectedSegmentFlightDirection(FlightDirection.DEPART);
    }
  }, [airfare, airports]);

  const durationProperty =
    selectedSegmentFlightDirection === FlightDirection.DEPART
      ? 'departingDuration'
      : 'returningDuration';
  const airfareDuration = airfare
    ? formatDuration(airfare[durationProperty] ?? undefined)
    : undefined;

  const isBadgeOnSeparateRow = isExtraSmallScreenSize || isLargeScreenSize;
  const badgeRow = (
    <IonRow
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        justifyContent: isBadgeOnSeparateRow ? 'flex-start' : 'flex-end',
        marginRight: isBadgeOnSeparateRow ? undefined : '2em',
        marginBottom: isBadgeOnSeparateRow ? '5px' : undefined,
      }}
    >
      <FlightBadges
        verifiedAirfare={selectedSegment}
        includeLabels={true}
        containerStyle={{
          position: 'initial',
        }}
        backgroundColor="#F8F6F3"
        color="var(--ion-color-primary)"
        exclude={[BadgeLabel.Notified, BadgeLabel.NonStop]}
      />
    </IonRow>
  );

  return (
    <IonRow style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <IonRow style={{ paddingTop: '1em' }}>
        <IonCol
          size="12"
          sizeLg="6"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: isMediumScreenSizeOrSmaller ? '85%' : '100%' }}>
            <IonRow style={{ height: '120px', flexWrap: 'nowrap' }}>
              <IonCol
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                size="auto"
              >
                <img
                  src={`${getAssetsBaseUrl()}/airline-logos/${
                    selectedSegment?.airlineIATA
                  }.svg`}
                  style={{
                    maxHeight: isScreenSmallerThanSize(400) ? '60px' : '80px',
                    width: isScreenSmallerThanSize(400) ? '60px' : '80px',
                  }}
                />
              </IonCol>
              <IonCol
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <IonRow
                  style={{
                    lineHeight: '20px',
                    fontFamily: 'nexa',
                    fontSize: '16px',
                  }}
                >
                  {selectedSegment?.airline}
                </IonRow>
                {(!hasAccess ||
                  (hasAccess && !!selectedSegment?.flightNumber)) && (
                  <IonRow
                    style={{
                      lineHeight: '32px',
                      fontFamily: 'nexa-bold',
                      fontSize: '20px',
                    }}
                  >
                    Flight&nbsp;
                    <span
                      style={{
                        ...(!hasAccess
                          ? {
                              color: 'gray',
                              filter: 'blur(5px)',
                              cursor: 'pointer',
                            }
                          : {}),
                      }}
                      onClick={() => {
                        if (!hasAccess && onHiddenDetailClick) {
                          onHiddenDetailClick();
                        }
                      }}
                    >
                      {/* If flight number doesn't exist, the text will be blurred, so the value doesn't matter */}
                      {selectedSegment?.flightNumber?.replace(/\s/g, '') ??
                        '0000'}
                    </span>
                  </IonRow>
                )}
                {!!airfareDuration && (
                  <IonRow
                    style={{
                      fontFamily: 'nexa',
                      color: 'gray',
                      fontSize: '14px',
                      lineHeight: '16px',
                    }}
                  >
                    Total Travel Time: {airfareDuration}
                  </IonRow>
                )}
              </IonCol>
              {!isBadgeOnSeparateRow && (
                <IonCol style={{ display: 'flex', paddingLeft: '2em' }}>
                  {badgeRow}
                </IonCol>
              )}
            </IonRow>
            {isBadgeOnSeparateRow && <>{badgeRow}</>}
            <IonRow
              style={{
                paddingTop: '1em',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="deal-details-segment-container"
                style={{ display: 'flex', width: '85%' }}
              >
                <DealDetailsRouteDiagram
                  airfare={airfare}
                  selectedSegment={selectedSegment}
                  onSelect={(segmentIndex, flightDirection) => {
                    setSelectedSegmentIndex(segmentIndex);
                    setSelectedSegmentFlightDirection(flightDirection);
                  }}
                />
              </div>
            </IonRow>
            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '1.5em',
              }}
            >
              <IonCol size="12" sizeSm="6">
                <DealDetailsSegmentDiagram
                  hasAccess={hasAccess}
                  segment={selectedSegment}
                  departureDate={
                    (selectedSegmentFlightDirection === FlightDirection.RETURN
                      ? airfare?.returnDate
                      : airfare?.departureDate) ?? undefined
                  }
                  onHiddenDetailClick={() => {
                    if (onHiddenDetailClick) {
                      onHiddenDetailClick();
                    }
                  }}
                />
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <DealDetailsAircraftAndBaggageDetails
                  segment={selectedSegment}
                  baggageDetails={airfare?.baggage ?? undefined}
                  seatClass={airfare?.seatClass}
                  flightDirection={selectedSegmentFlightDirection}
                />
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
        <IonCol
          size="12"
          sizeLg="6"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: isMediumScreenSizeOrSmaller ? '85%' : '100%' }}>
            {memoizedMap}
          </div>
        </IonCol>
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsAirfareDetails;
