import { IonCol, IonIcon, IonSpinner } from '@ionic/react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';

import { Airport, DealSearchQuery, SeatClass } from '@faredrop/graphql-sdk';

import useSearchAirports from '../hooks/useSearchAirports';
import { DropdownOptionWithSubtext } from '../types/types';
import { formatGeoCoderOption } from '../contexts/airportSearchContext';
import useUser from '../hooks/user';
import useAirports from '../hooks/airports';
import { formatAirportName } from '../utilities/deals-utilities';
import SearchBarWithResults from './SearchBarWithResults';
import useDealSearch from '../hooks/useDealSearch';
import { createAirportSearchText } from '../utilities/airports';
import { useDevice } from '../hooks/useDevice';
import { useContainerDimensions } from '../hooks/useContainerDimensions';
import { ScreenSize } from '../contexts/deviceContext';
import useAnalytics from '../hooks/analytics';

interface DealSearchInput {
  from?: string;
  to?: string;
  seatClass?: SeatClass;
}

interface ContainerProps {
  onResult: (
    input?: DealSearchInput,
    result?: DealSearchQuery['dealSearch'],
    errorMessage?: string
  ) => void;
}

const parseSearchTextIATA = (searchText: string) => {
  let iata: string | undefined = undefined;
  const split = searchText.split('·');
  if (split.length > 1) {
    iata = split[1].trim();
  }
  return iata;
};

const DealSearchbar: React.FC<ContainerProps> = ({ onResult }) => {
  const { searchAirports } = useSearchAirports();
  const { getAirport } = useAirports();
  const { user } = useUser();
  const { dealSearch } = useDealSearch();
  const { isExtraSmallScreenSize, isScreenBetweenSizes } = useDevice();
  const { logAnalyticsDealSearch } = useAnalytics();

  const originSearchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const destinationSearchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const { width: originSearchBarWidth } =
    useContainerDimensions(originSearchBarRef);
  const { width: destinationSearchBarWidth } = useContainerDimensions(
    destinationSearchBarRef
  );

  const [seatClass, setSeatClass] = useState(SeatClass.Economy);
  const [originIATA, setOriginIATA] = useState<string>();
  const [destinationIATA, setDestinationIATA] = useState<string>();
  const [isSearching, setIsSearching] = useState(false);

  const parseDropDownSelection = (
    option: DropdownOptionWithSubtext,
    includeParentIata = false
  ) => {
    const parsed: { searchText?: string; iata?: string } = {};
    const text = option.text;
    const match = text.match(/\(([^)]+)\)/);
    if (match && match.length > 1) {
      parsed.iata = match[1];
      const airport = getAirport(parsed.iata);
      if (airport) {
        parsed.searchText = createAirportSearchText(airport, includeParentIata);
      }
    }
    return parsed;
  };

  const setUserAirportsResults = () => {
    let result: DropdownOptionWithSubtext[] | undefined = undefined;
    if (user?.configuration.origins?.length) {
      // If there is no text, use user's airports
      let homeAirport: Airport | undefined;
      if (user.configuration.homeOriginIATA) {
        homeAirport = getAirport(user.configuration.homeOriginIATA);
      }
      result = [
        {
          text: formatAirportName(homeAirport),
          subtext: '',
        },
        ...user.configuration.origins
          .filter((o) => o.iata !== user.configuration.homeOriginIATA)
          .map((o) => {
            const airport = getAirport(o.iata);
            return {
              text: formatAirportName(airport),
              subtext: '',
            };
          }),
      ];
    }
    return result;
  };

  const searchDeals = async (
    from?: string,
    to?: string,
    seatClass?: SeatClass
  ) => {
    if (from && to && seatClass) {
      setIsSearching(true);
      let result: DealSearchQuery['dealSearch'] | undefined = undefined;
      let errorMessage: string | undefined = undefined;
      try {
        // Fire and forget analytics
        logAnalyticsDealSearch(from, to, seatClass).catch(() => {
          console.warn('Failed to log deal search analytics');
        });
        result = await dealSearch(from, to, seatClass);
      } catch (error) {
        errorMessage =
          'Uh oh - something went wrong. Please refresh the page and try again.';
      } finally {
        setIsSearching(false);
        onResult(
          {
            from,
            to,
            seatClass,
          },
          result,
          errorMessage
        );
      }
    }
  };

  const buildSeatClassSelect = () => {
    let seatClassSelect: JSX.Element | undefined = undefined;
    if (isExtraSmallScreenSize) {
      seatClassSelect = (
        <FormControl>
          <Select
            labelId="seatclass-select-label"
            value={seatClass}
            label="Seat Class"
            disabled={isSearching}
            onChange={async (e) => {
              const updated = e.target.value as SeatClass;
              setSeatClass(updated);
              if (originIATA && destinationIATA) {
                await searchDeals(originIATA, destinationIATA, updated);
              }
            }}
            size="small"
            variant="standard"
            disableUnderline={true}
            sx={{
              '&.MuiInput-root.Mui-focused div': {
                backgroundColor: 'initial',
              },
              '&.MuiInput-root': {
                fontFamily: 'nexa-bold',
                fontSize: '14px',
                borderBottom: 'initial',
              },
            }}
          >
            <MenuItem value={SeatClass.Economy}>Economy</MenuItem>
            <MenuItem value={SeatClass.Business}>Business</MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      seatClassSelect = (
        <FormControl>
          <InputLabel
            id="seatclass-select-label"
            sx={{
              '&.MuiInputLabel-root': {
                fontSize: '14px',
                fontFamily: 'nexa-bold',
                lineHeight: '26px',
              },
              '&.MuiInputLabel-root.Mui-focused': {
                color: 'var(--ion-color-primary)',
              },
            }}
          >
            Seat Class
          </InputLabel>
          <Select
            labelId="seatclass-select-label"
            value={seatClass}
            label="Seat Class"
            disabled={isSearching}
            onChange={async (e) => {
              const updated = e.target.value as SeatClass;
              setSeatClass(updated);
              if (originIATA && destinationIATA) {
                await searchDeals(originIATA, destinationIATA, updated);
              }
            }}
            size="small"
            variant="outlined"
            sx={{
              '&.MuiOutlinedInput-root.Mui-focused fieldset': {
                borderColor: 'var(--ion-color-primary)',
              },
              '&.MuiOutlinedInput-root': {
                fontFamily: 'nexa-bold',
                fontSize: '14px',
              },
              '&:hover': {
                '&&.MuiOutlinedInput-root fieldset': {
                  borderColor: 'var(--ion-color-primary)',
                },
              },
            }}
          >
            <MenuItem value={SeatClass.Economy}>Economy</MenuItem>
            <MenuItem value={SeatClass.Business}>Business</MenuItem>
          </Select>
        </FormControl>
      );
    }
    return seatClassSelect;
  };

  let searchBarsWidth: number | undefined = undefined;
  let destinationDropDownLeft: string | undefined = undefined;
  if (isExtraSmallScreenSize) {
    destinationDropDownLeft = '-105%';
  } else if (
    isScreenBetweenSizes(ScreenSize.SMALL_START, ScreenSize.LARGE_START) ||
    isScreenBetweenSizes(ScreenSize.EXTRA_LARGE_START, 1600)
  ) {
    searchBarsWidth =
      originSearchBarWidth && destinationSearchBarWidth
        ? originSearchBarWidth + destinationSearchBarWidth
        : undefined;
    if (originSearchBarWidth) {
      destinationDropDownLeft = `-${originSearchBarWidth}px`;
    }
  }

  // Hide search icons when an airport has been selected on mobile devices
  const toSearchIcon =
    isExtraSmallScreenSize && originIATA ? 'undefined' : undefined;
  const fromSearchIcon =
    isExtraSmallScreenSize && destinationIATA ? 'undefined' : undefined;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginBottom: isExtraSmallScreenSize ? '1em' : undefined,
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <IonCol>
          <SearchBarWithResults
            ref={originSearchBarRef}
            disabled={isSearching}
            fullScreenForExtraSmallScreens={true}
            placeholder="Where from?"
            searchIcon={toSearchIcon}
            onChange={async (searchText) => {
              let result: DropdownOptionWithSubtext[] | undefined = undefined;
              if (searchText) {
                const origins = await searchAirports(searchText, {
                  originsOnly: true,
                });
                result = origins.map((o) =>
                  formatGeoCoderOption(o, { useParentAirports: true })
                );
              } else {
                result = setUserAirportsResults();
              }
              return result;
            }}
            onSelect={async (option: DropdownOptionWithSubtext) => {
              const noParentIataResult = parseDropDownSelection(option, false);
              setOriginIATA(noParentIataResult.iata);
              return {
                displayText: noParentIataResult.searchText,
                value: parseDropDownSelection(option, true).searchText,
              };
            }}
            onSubmit={async (searchText) => {
              if (searchText) {
                if (destinationIATA) {
                  const iata = parseSearchTextIATA(searchText);
                  setOriginIATA(iata);
                  if (iata) {
                    await searchDeals(iata, destinationIATA, seatClass);
                  }
                }
              } else {
                setOriginIATA(undefined);
                await onResult({
                  to: destinationIATA,
                  seatClass,
                });
              }
            }}
            dropdownStyle={{
              top: '55px',
              ...(searchBarsWidth
                ? {
                    width: searchBarsWidth,
                  }
                : {}),
            }}
          />
        </IonCol>
        <IonCol
          size="auto"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '-.35em',
            marginRight: '-.35em',
          }}
        >
          {isSearching && (
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
              <IonSpinner
                style={{ margin: 'auto 0', width: '22px' }}
                name="crescent"
              />
            </div>
          )}
          {!isSearching && (
            <IonIcon
              icon={arrowForwardCircleOutline}
              style={{ cursor: 'initial' }}
            />
          )}
        </IonCol>
        <IonCol>
          <SearchBarWithResults
            ref={destinationSearchBarRef}
            disabled={isSearching}
            searchIcon={fromSearchIcon}
            placeholder="Where to?"
            hideNoResultOriginMessage={true}
            fullScreenForExtraSmallScreens={true}
            onChange={async (searchText) => {
              let result: DropdownOptionWithSubtext[] | undefined = undefined;
              if (searchText) {
                const destinations = await searchAirports(searchText, {
                  destinationsOnly: true,
                });
                result = destinations.map((d) =>
                  formatGeoCoderOption(d, { useParentAirports: true })
                );
              }
              return result;
            }}
            onSelect={async (option: DropdownOptionWithSubtext) => {
              const noParentIataResult = parseDropDownSelection(option, false);
              setDestinationIATA(noParentIataResult.iata);
              return {
                displayText: noParentIataResult.searchText,
                value: parseDropDownSelection(option, true).searchText,
              };
            }}
            onSubmit={async (searchText) => {
              if (searchText) {
                if (originIATA) {
                  const iata = parseSearchTextIATA(searchText);
                  setDestinationIATA(iata);
                  if (iata) {
                    await searchDeals(originIATA, iata, seatClass);
                  }
                }
              } else {
                setDestinationIATA(undefined);
                await onResult({
                  from: originIATA,
                  seatClass,
                });
              }
            }}
            dropdownStyle={{
              top: '55px',
              ...(searchBarsWidth
                ? {
                    width: searchBarsWidth,
                  }
                : {}),
              ...(destinationDropDownLeft
                ? {
                    left: destinationDropDownLeft,
                  }
                : {}),
            }}
          />
        </IonCol>
        {!isExtraSmallScreenSize && (
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '.5em',
              marginRight: '1em',
            }}
            size="auto"
          >
            {buildSeatClassSelect()}
          </IonCol>
        )}
      </div>
      {isExtraSmallScreenSize && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-.35em',
            marginLeft: '24px', // Amount of padding on the select carrot
          }}
        >
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            size="auto"
          >
            {buildSeatClassSelect()}
          </IonCol>
        </div>
      )}
    </div>
  );
};

export default DealSearchbar;
