import sample from 'lodash/sample';
import sampleSize from 'lodash/sampleSize';
import useSWR from 'swr';

import { SeatClass } from '@faredrop/graphql-sdk';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import { DefaultLandingDeal, LandingDeal } from '../types/types';
import useAnalytics from './analytics';
import { sortBySeatClass } from '../utilities/deals-utilities';

export interface LandingPageDealsValue {
  destinationDealsList?: DefaultLandingDeal[];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  landingPageDefaultDealsKey: () => string[];
  randomDeals: (count?: number, seatClass?: SeatClass) => LandingDeal[];
}

const useLandingPageDefaultDeals = (): LandingPageDealsValue => {
  const { logAnalyticsError } = useAnalytics();

  const landingPageDefaultDealsKey = () => [
    'LANDING_PAGE_DEALS',
    process.env.REACT_APP_VERSION ?? '',
    'default',
  ];

  const key = landingPageDefaultDealsKey();

  const { data, error, isValidating } = useSWR(key, async () => {
    try {
      const raw = await fetch(
        `${getAssetsBaseUrl()}/data/landing-page-deals/default.json`
      );
      const json = await raw.json();

      if (Array.isArray(json)) {
        return json as DefaultLandingDeal[];
      } else {
        throw new Error(
          `Invalid return type for default landing page deals. ${json}`
        );
      }
    } catch (error) {
      await logAnalyticsError('getLandingPageDefaultDeals', error as Error);
      throw error;
    }
  });

  const getRandomDeals = (count?: number, seatClass?: SeatClass) => {
    if (!data) return [];

    const destinationLibrary = seatClass
      ? data.filter(
          (destinationDeals) =>
            destinationDeals.seatClass.trim().toUpperCase() === seatClass
        )
      : [...data];
    count = count ?? 40;
    if (count > destinationLibrary.length) count = destinationLibrary.length;

    const randomDeals: LandingDeal[] = [];
    sampleSize(destinationLibrary, count).forEach((destinationDeals) => {
      const origin = sample(destinationDeals.origins);
      if (origin) {
        randomDeals.push({
          originIATA: origin.iata,
          destinationRegion: destinationDeals.destinationRegion,
          historicalMeanPrice: parseInt(
            destinationDeals.historicalMeanPrice.replace(',', '')
          ),
          minPrice: parseInt(origin.price.replace(',', '')),
          seatClass: destinationDeals.seatClass as SeatClass,
          destinationIATA: destinationDeals.destinationIATA,
          city: destinationDeals.city,
          country: destinationDeals.country,
        });
      }
    });
    return randomDeals.sort(sortBySeatClass);
  };

  return {
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
    landingPageDefaultDealsKey,
    randomDeals: getRandomDeals,
  };
};

export default useLandingPageDefaultDeals;
