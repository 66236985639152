import { FormEvent, useState } from 'react';
import { IonRow, IonSpinner } from '@ionic/react';
import { TextField } from '@mui/material';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {
  SetupIntent,
  Stripe,
  StripeElements,
  SetupIntentResult,
} from '@stripe/stripe-js';

import { SubscriptionPlan } from '@faredrop/graphql-sdk';

import { useDevice } from '../hooks/useDevice';
import Loading from './Loading';
import usePresentToast from '../hooks/presentToast';
import useFareDropPublicApiClient from '../hooks/faredropPublicApiClient';
import useUser from '../hooks/user';
import './../theme/util.css';

import { useCodeQueryParam } from '../hooks/useCodeQueryParam';
import CodeInput from './CodeInput';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

export enum SetupIntentReason {
  FREE_TRIAL = 'free_trial',
  UPGRADE = 'upgrade',
  REDEMPTION = 'redemption',
  SUBSCRIBE = 'subscribe',
}

interface PaymentFormProps {
  plan?: SubscriptionPlan;
  setupIntentReason?: SetupIntentReason;
  hideCouponCode?: boolean;
  discountDescription?: string;
  discountError?: string;
  returnUrl?: string;
  disabled?: boolean;
  subtitle?: string;
  email?: string;
  notYouRedirect?: string;
  onSubmit: (
    elements: StripeElements | null,
    stripe: Stripe | null,
    setupIntent?: SetupIntent
  ) => Promise<void>;
  onApplyDiscount?: (code?: string) => Promise<void>;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  plan,
  setupIntentReason,
  hideCouponCode,
  discountDescription,
  discountError,
  returnUrl,
  disabled,
  onSubmit,
  onApplyDiscount,
  subtitle,
  email,
  notYouRedirect,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { presentWarning } = usePresentToast();
  const { isMediumScreenSizeOrSmaller } = useDevice();
  const [submitting, setSubmitting] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const { client } = useFareDropPublicApiClient();
  const userState = useUser();
  const { couponOrGiftCodeQueryParam } = useCodeQueryParam();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        presentWarning('Error: Please refresh the page and try again');
      } else if (plan && setupIntentReason) {
        const url = new URL(
          returnUrl ??
            `${window.location.origin}/payment?id=${plan.id}&setup_intent_reason=${setupIntentReason}`
        );
        if (couponOrGiftCodeQueryParam)
          url.searchParams.append('coupon', couponOrGiftCodeQueryParam);

        // Our webhook stripe-event.onStripeSetupIntentSucceeded (setup_intent.succeeded) will set the customer.invoice.default_payment_method
        const response = await stripe?.confirmSetup({
          elements,
          confirmParams: {
            return_url: url.href, // Only redirect if the payment option requires further authentication
          },
          redirect: 'if_required', // NOTE: Comment this out to test return_url logic
        });

        const setupIntent = (response as SetupIntentResult)?.setupIntent;
        if (
          response?.error &&
          (response.error.type === 'card_error' ||
            response.error.type === 'validation_error')
        ) {
          setErrorMessage(response.error.message);
        } else if (response?.error) {
          setErrorMessage('An unexpected error occurred.');
        } else if (setupIntent) {
          // Success
          await onSubmit(elements, stripe, setupIntent);
        }
      }
    } catch (error) {
      presentWarning('Error processing payment...');
      await client.log({
        obj: JSON.stringify({
          error: {
            name: (error as Error).name,
            message: (error as Error).message,
            stack: (error as Error).stack,
          },
          errorStringified: JSON.stringify(
            error,
            Object.getOwnPropertyNames(error)
          ),
          userState,
        }),
      });
    }

    setSubmitting(false);
  };

  const form = (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await handleSubmit(e);
      }}
    >
      <div>
        <h2
          style={{
            textAlign: 'center',
            marginBottom: subtitle ? undefined : '1em',
          }}
        >
          Payment Details
        </h2>
        {subtitle && (
          <h6 style={{ textAlign: 'center', marginBottom: '1em' }}>
            {subtitle}
          </h6>
        )}
        <div style={{ flex: 1 }}>
          {loadingForm && (
            <Loading sx={{ marginTop: '4em', marginBottom: '4em' }} />
          )}
          {email && (
            <>
              <TextField
                autoFocus={false}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                type="email"
                value={email}
                variant="outlined"
                disabled={true}
              />
              {notYouRedirect && (
                <IonRow>
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    <a
                      onClick={() => {
                        let search: string | undefined = undefined;
                        const params = new URLSearchParams();
                        params.append('redirect', notYouRedirect);
                        search = `?${params.toString()}`;

                        goWithStickyParamsLocation({
                          pathname: '/login',
                          search,
                        });
                      }}
                    >
                      Not you?
                    </a>
                  </div>
                </IonRow>
              )}
            </>
          )}
          <PaymentElement
            onReady={() => {
              setLoadingForm(false);
            }}
          />
          {!loadingForm && !hideCouponCode && (
            <CodeInput
              discountDescription={discountDescription}
              discountError={discountError}
              onApply={async (code) => {
                if (onApplyDiscount) await onApplyDiscount(code);
              }}
            />
          )}
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: '2em' }}
        >
          {errorMessage && (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                color: 'var(--ion-color-danger)',
              }}
            >
              {errorMessage}
            </p>
          )}
          <button
            className="button no-caps submit-button"
            type="submit"
            disabled={loadingForm || submitting || disabled}
            style={{
              maxWidth: '15em',
              margin: 'auto',
              transition: 'opacity 500ms',
              opacity: loadingForm ? 0 : submitting ? 0.5 : 1.0,
            }}
          >
            {(submitting && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IonSpinner
                  style={{ margin: 'auto 0' }}
                  color="white"
                  name="crescent"
                />
                <p style={{ margin: 'auto 0 auto 10px' }}>Processing . . .</p>
              </div>
            )) || <p>Submit</p>}
          </button>
        </div>
      </div>
    </form>
  );

  return isMediumScreenSizeOrSmaller ? (
    form
  ) : (
    <IonRow style={{ display: 'inline-block', width: '100%' }}>{form}</IonRow>
  );
};

export default PaymentForm;
