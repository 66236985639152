import { Airport, Deal } from '@faredrop/graphql-sdk';
import { getCurrencySymbol } from '@faredrop/utilities';

import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { airplane, calendarOutline } from 'ionicons/icons';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import { LandingDeal } from '../types/types';
import {
  BadgeLabel,
  formatAirportCity,
  formatAirportLocation,
} from '../utilities/deals-utilities';
import './../theme/DealCard.css';
import './../theme/util.css';
import FlightBadges from './FlightBadges';
import StarIcon from '@material-ui/icons/Star';
import { getAssetsBaseUrl } from '@faredrop/utilities';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { HIDE_MEMBERSHIP_LINKS } from '../utilities/constants';
import { AllowedPath } from '@faredrop/types';

export interface IDestinationDeals {
  deals: Deal[];
  activeDeal: Deal;
  keyDeal: Deal; // Deals and activeDeal may change, but key deal needs to be consistent to prevent deal sections from remounting
}

export interface IDestinationLandingDeals {
  deals: LandingDeal[];
  activeDeal: LandingDeal;
}
interface ContainerProps {
  city?: string;
  country?: string;
  destinationDeals: IDestinationDeals | IDestinationLandingDeals;
  locked?: boolean;
  openModalListener?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isDealLocked: boolean
  ) => void;
  sx?: React.CSSProperties;
  showGetDealsButton?: boolean | undefined;
  originAirport?: Airport;
  destinationAirport?: Airport;
}

export const DealCard: React.FC<ContainerProps> = ({
  city,
  country,
  destinationDeals,
  locked,
  openModalListener,
  sx,
  showGetDealsButton,
  originAirport,
  destinationAirport,
}) => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  const activeDeal = destinationDeals.activeDeal;

  const nonActiveDeals = (
    destinationDeals.deals as Pick<Deal, 'originIATA'>[]
  ).filter(({ originIATA }) => originIATA !== activeDeal.originIATA);

  const { isApp, isSmallScreenSizeOrSmaller, isExtraSmallScreenSize } =
    useDevice();
  const priceFormatted = (price: number) =>
    `${getCurrencySymbol(activeDeal.currency)}${price}`;

  const userState = useUser();
  const homeOriginIATA = userState.user?.configuration.homeOriginIATA;

  const isDealLocked =
    locked === true ||
    // Landing deals don't have airfares and that's the only way for us to check if the deal is locked or not..
    // So let's make sure to set locked=true for landing deals
    (locked !== false && (activeDeal as Deal).airfares == null);

  return (
    <div
      className={
        isApp || isSmallScreenSizeOrSmaller
          ? 'deal-slide-wrapper deal-slide-wrapper-responsive'
          : 'deal-slide-wrapper deal-slide-wrapper-responsive flinch-on-hover'
      }
      style={{
        margin: isExtraSmallScreenSize ? 0 : 12,
        maxWidth: isSmallScreenSizeOrSmaller ? '90%' : '',
        ...sx,
      }}
      onClick={(e) => {
        e.stopPropagation();
        openModalListener && openModalListener(e, isDealLocked);
      }}
    >
      <div className="br-10 bg-white top-section">
        <div style={{ position: 'relative' }}>
          <FlightBadges
            deal={activeDeal}
            includeLabels={isDealLocked && !HIDE_MEMBERSHIP_LINKS}
            size={'large'}
            createOrUpgrade={
              isDealLocked
                ? userState.user
                  ? BadgeLabel.Upgrade
                  : BadgeLabel.Create
                : undefined
            }
            onClick={async (badge) => {
              if (!HIDE_MEMBERSHIP_LINKS) {
                if (badge.label === BadgeLabel.Upgrade) {
                  goWithStickyParamsPath(AllowedPath.PLANS);
                } else if (badge.label === BadgeLabel.Create) {
                  goWithStickyParamsPath(AllowedPath.REGISTER);
                }
              }
            }}
          />
          <img
            src={`${getAssetsBaseUrl()}/iatas/800/480/${
              activeDeal.destinationIATA
            }.jpg`}
            style={{
              borderRadius: '10px 10px 0 0',
              height: isExtraSmallScreenSize ? 150 : 220,
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <div className="location-wrapper" style={{ textAlign: 'left' }}>
          <h5
            className="m-0"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {city && country
              ? `${city}, ${country}`
              : formatAirportLocation(destinationAirport, isDealLocked)}
          </h5>
          <IonRow
            className="m-0 small-text gray row-vertical-align"
            style={{ justifyContent: 'left' }}
          >
            <div className="origin-location">
              {originAirport ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    color:
                      originAirport.iata === homeOriginIATA
                        ? 'var(--ion-color-primary)'
                        : 'var(--ion-color-gray)',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  <span
                    style={{
                      alignSelf: 'center',
                      alignItems: 'center',
                      marginTop: '7px',
                    }}
                  >
                    {originAirport.iata === homeOriginIATA ? (
                      <StarIcon
                        style={{ marginRight: '5px', fontSize: '24px' }}
                      />
                    ) : (
                      <IonIcon className="airplane-icon gray" icon={airplane} />
                    )}
                  </span>
                  {formatAirportCity(originAirport)}
                  {!!nonActiveDeals.length && (
                    <div
                      style={{
                        marginTop: '-5px',
                        marginLeft: '5px',
                        fontStyle: 'italic',
                        lineHeight: '10px',
                        alignSelf: 'center',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '10px',
                          color: 'var(--ion-color-gray)',
                        }}
                      >
                        + {nonActiveDeals.length} Airports
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <span
                  style={{
                    display: 'block',
                    height: '1em',
                    width: '100%',
                    backgroundColor: '#eee',
                    borderRadius: 5,
                  }}
                />
              )}
            </div>
          </IonRow>
        </div>
      </div>
      <hr className="border-top-dashed" />
      <div className="br-10 bg-white price-wrapper">
        <IonRow className="row-vertical-align">
          <IonCol size="7">
            <div style={{ textAlign: 'left', display: 'flex' }}>
              <h4 className="m-0" style={{ fontSize: 20 }}>
                {priceFormatted(activeDeal.minPrice)}
              </h4>
              <h5 className="m-0 small-text gray" style={{ fontSize: 12 }}>
                {priceFormatted(activeDeal.historicalMeanPrice)}
              </h5>
            </div>
          </IonCol>
          <IonCol size="5">
            {!showGetDealsButton ? (
              <IonIcon
                icon={calendarOutline}
                style={{
                  float: 'right',
                  margin: '.5em',
                  color: 'var(--ion-color-gray)',
                }}
                className="icon-gray"
              />
            ) : (
              <IonButton color="secondary">Get deals</IonButton>
            )}
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};
