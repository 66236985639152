import produce from 'immer';
import { $enum } from 'ts-enum-util';
import { Device } from '@capacitor/device';
import moment from 'moment';
import { useSWRConfig } from 'swr';

import {
  ChangeSubscriptionType,
  NotificationKinds,
  NotificationMethods,
  SubscriptionStatus,
  SupportedCurrencies,
  UserConfigDestinationRegion,
  UserConfigDestinationRegionsQuery,
  UserConfigNotificationFilter,
  UserConfigNotificationKind,
  UserConfigNotificationMethod,
  UserConfigOrigin,
  UserConfigTravelMonthsQuery,
  UserQuery,
} from '@faredrop/graphql-sdk';
import {
  FareDropPlan,
  FareDropRole,
  SupportedCountryCodes,
} from '@faredrop/types';
import { isAuthorized, stripePlanIDToFareDropPlans } from '@faredrop/utilities';

import useFareDropApiClientWithTimeout from './faredropApiClientWithTimeout';
import useAuth from './auth';
import useSWRTimeout from './useSWRTimeout';
import usePresentToast from './presentToast';
import useAnalytics from './analytics';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'debounce';
import { useDevice } from './useDevice';
import useAirports from './airports';

export type IFrontendUser = UserQuery['user'];
export type IUserConfigTravelMonths =
  UserConfigTravelMonthsQuery['userConfig']['travelMonths'];
export type IDestinationRegions =
  UserConfigDestinationRegionsQuery['userConfig']['destinationRegions'];

export const sortRegions = (
  a: UserConfigDestinationRegion,
  b: UserConfigDestinationRegion
) => {
  if (a.region < b.region) return -1;
  else return 1;
};

export interface UserConfigValue {
  user?: IFrontendUser;
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  timeout: boolean;
  hasPaymentInfo: boolean;
  isLimited: boolean;
  subscriptionStatus: SubscriptionStatus;
  planEndInDays: number | undefined;
  hasDealNotificationsEnabled: boolean | undefined;
  isThisDeviceRegisteredForPushNotifications: boolean | undefined;
  homeCountryCode?: SupportedCountryCodes;

  userKey: (id: string) => string[];
  cancelSubscription: (
    changeSubscriptionType: ChangeSubscriptionType
  ) => Promise<IFrontendUser>;
  mutate: (
    data?: IFrontendUser,
    shouldRevalidate?: boolean
  ) => Promise<IFrontendUser | undefined>;
  putThisDevice: (pushNotificationToken: string) => Promise<void>;
  removeThisDevice: () => Promise<void>;
  setAutoRenew: (autoRenew: boolean, cacheOnly?: boolean) => Promise<void>;
  setDestinationRegions: (regions: IDestinationRegions) => Promise<void>;
  setEmail: (email: string) => Promise<void>;
  setNotificationMethods: (
    notificationMethods: UserConfigNotificationMethod[],
    showSuccess?: boolean
  ) => Promise<void>;
  setNotificationFilters: (
    notificationFilters: UserConfigNotificationFilter[],
    showSuccess?: boolean
  ) => Promise<void>;
  setNotificationKinds: (
    notificationKinds: UserConfigNotificationKind[],
    showSuccess?: boolean
  ) => Promise<void>;
  setOrigins: (origins: UserConfigOrigin[]) => Promise<void>;
  setHomeOrigin: (iata: string) => Promise<void>;
  resetOrigins: (
    homeOriginIata: string,
    origins: UserConfigOrigin[]
  ) => Promise<void>;
  removeOrigin: (
    originToRemove: string,
    remainingOrigins: UserConfigOrigin[]
  ) => Promise<void>;
  updateOrigin: (originIATA: string, attributes: number) => Promise<void>;
  setProfile: (
    phoneNumber?: string,
    firstName?: string,
    lastName?: string
  ) => Promise<void>;
  setTravelMonths: (months: IUserConfigTravelMonths) => Promise<void>;
  setCurrency: (currency: SupportedCurrencies) => Promise<void>;
  refreshUser: (
    updatedUser?: IFrontendUser
  ) => Promise<IFrontendUser | undefined>;
}

const useUser = (): UserConfigValue => {
  const { user, refreshSession } = useAuth();
  const { client } = useFareDropApiClientWithTimeout();
  const { presentError, presentSuccess, presentWarning } = usePresentToast();
  const { logAnalyticsError } = useAnalytics();
  const { isApp } = useDevice();
  const { mutate } = useSWRConfig();
  const { airports } = useAirports();

  const [deviceId, setDeviceId] = useState<string>();
  const [homeCountryCode, setHomeCountryCode] =
    useState<SupportedCountryCodes>();

  useEffect(() => {
    if (isApp) {
      Device.getId()
        .then((deviceId) => {
          setDeviceId(deviceId.identifier);
        })
        .catch((error) => {
          logAnalyticsError('setDeviceId', error as Error).catch(() =>
            console.warn(
              'Failed to track analytics error for setDeviceId',
              error
            )
          );
          throw error; // Show uh-oh screen
        });
    }
  }, []);

  const userKey = (id: string) => [
    'USER',
    process.env.REACT_APP_VERSION ?? '',
    id,
  ];

  let requestKey: string[] | null = null;
  if (user && user.id && isAuthorized(user, [FareDropRole.Profile])) {
    requestKey = userKey(user.id);
  }

  const { data, error, timeout, isValidating } = useSWRTimeout(
    requestKey,
    async () => {
      try {
        const user = (await client.user()).data.user;
        user.configuration.destinationRegions.sort(sortRegions);
        return user;
      } catch (error) {
        await logAnalyticsError('getUser', error as Error);
        throw error;
      }
    },
    `Failed to get profile settings` // Friendly error message to be "presented" to user upon failure
  );

  useEffect(() => {
    if (airports && data?.configuration.homeOriginIATA) {
      const homeOriginIATA = data.configuration.homeOriginIATA;
      const homeOrigin = airports.find((o) => o.iata === homeOriginIATA);
      setHomeCountryCode(
        $enum(SupportedCountryCodes).asValueOrDefault(
          homeOrigin?.countryCode,
          undefined
        )
      );
    }
  }, [airports, data?.configuration.homeOriginIATA]);

  const debounceSetDestinationRegions = useRef(
    debounce(async (regions: UserConfigDestinationRegion[]) => {
      await client.setUserConfigDestinationRegions({
        destinationRegions: regions,
      });
    }, 500)
  );

  const cancelSubscription = async (
    changeSubscriptionType: ChangeSubscriptionType
  ) => {
    const updatedUser = (
      await client.cancelSubscription({
        changeSubscriptionType,
      })
    ).data.cancelSubscription;
    return updatedUser;
  };

  const putThisDevice = async (pushNotificationToken: string) => {
    const deviceId = await Device.getId();
    const device = {
      idDevice: deviceId.identifier,
      pushNotificationToken,
    };

    if (data) {
      const updated = produce(data, (draft) => {
        draft.configuration.devices.push(device);
      });
      await mutate(requestKey, updated, false);
    }

    await client.putUserConfigDevice({ device });

    // Since this method is called right when the app launches, it is very likely data won't be initialized yet
    if (!data) {
      await mutate(requestKey); // Refresh user config
    }
  };

  const removeThisDevice = async () => {
    try {
      if (isApp) {
        if (!data)
          throw new Error('Profile has not loaded yet, please try again');

        const idDevice = (await Device.getId()).identifier;
        const devices = data?.configuration.devices ?? [];
        if (devices.some((d) => d.idDevice === idDevice)) {
          const updatedDevices = [...devices].filter(
            (d) => d.idDevice !== idDevice
          );

          // update the local data immediately, and disable the revalidation
          const updated = produce(data, (draft) => {
            draft.configuration.devices = updatedDevices;
          });

          await Promise.all([
            mutate(requestKey, updated, false),
            client.removeUserConfigDevice({ idDevice }),
          ]);
        }
      }
    } catch (error) {
      presentError(
        'Failed to unregister device for push notifications. Please delete the app to stop receiving push notifications.'
      );

      console.error(error);
      await logAnalyticsError('removeUserDevice');
    }
  };

  const setAutoRenew = async (autoRenew: boolean, cacheOnly?: boolean) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');
      const updated = produce(data, (draft) => {
        draft.billing.autoRenew = autoRenew;
      });
      await Promise.all([
        mutate(requestKey, updated, false),
        cacheOnly ? undefined : client.setUserBillingAutoRenew({ autoRenew }),
      ]);
    } catch (error) {
      presentError(
        `Error updating auto renew setting. Please refresh and try again`
      );

      console.error(error);
      await logAnalyticsError('setAutoRenew', error as Error);
    }
  };

  const setDestinationRegions = async (
    destinationRegions: IDestinationRegions
  ) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      if (!destinationRegions.some((region) => region.enabled)) {
        presentWarning(
          'Warning: You have disabled all destination regions which will result in you no longer receiving any FareDrop deals'
        );
      } else if (
        destinationRegions.some(
          (region) =>
            region.maxPriceEconomy == 0 || region.maxPriceBusiness == 0
        )
      ) {
        presentWarning(
          'Warning: Setting your max price to $0 will result in you no longer receiving deals for that region'
        );
      }

      // update the local data immediately, and disable the revalidation
      const updated = produce(data, (draft) => {
        draft.configuration.destinationRegions = destinationRegions;
      });

      await Promise.all([
        mutate(requestKey, updated, false),
        debounceSetDestinationRegions.current(destinationRegions),
      ]);
    } catch (error) {
      presentWarning(
        `Error updating destination regions. Please refresh and try again`
      );
      console.error(error);
      await logAnalyticsError('setDestinationRegions', error as Error);
    }
  };

  const setEmail = async (email: string) => {
    if (!data) throw new Error('Profile has not loaded yet, please try again');

    const updated = produce(data, (draft) => {
      draft.profile.email = email;
    });
    await Promise.all([
      mutate(requestKey, updated, false),
      client.setUserProfileEmailAddress({ email }),
    ]);
    await refreshSession();
  };

  const setNotificationMethods = async (
    notificationMethods: UserConfigNotificationMethod[],
    showSuccess?: boolean
  ) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      if (
        (!notificationMethods.find(
          (nm) => nm.name === NotificationMethods.Email
        )?.enabled ||
          !data.configuration.notificationKinds.find(
            (nk) => nk.name === NotificationKinds.Deals
          )?.enabled) &&
        (!data?.configuration.devices ||
          data.configuration.devices.length === 0)
      ) {
        presentWarning(
          'Warning: You have disabled all notification methods which result in you no longer receiving any notifications about new deals'
        );
      }

      const updated = produce(data, (draft) => {
        draft.configuration.notificationMethods = notificationMethods;
      });

      await Promise.all([
        mutate(requestKey, updated, false),
        client.setUserConfigNotificationMethods({
          notificationMethods,
        }),
      ]);

      if (showSuccess) {
        presentSuccess('Settings Updated');
      }
    } catch (err) {
      presentError(
        `Error updating notification method preferences. Please refresh and try again`
      );

      console.error(err);
      await logAnalyticsError('setNotificationMethods', err as Error);
    }
  };

  const setNotificationFilters = async (
    notificationFilters: UserConfigNotificationFilter[],
    showSuccess?: boolean
  ) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      const updated = produce(data, (draft) => {
        draft.configuration.notificationFilters = notificationFilters;
      });
      await Promise.all([
        mutate(requestKey, updated, false),
        client.setUserConfigNotificationFilters({
          notificationFilters,
        }),
      ]);

      if (showSuccess) {
        presentSuccess('Settings Updated');
      }
    } catch (err) {
      presentError(
        `Error updating notification filter preferences. Please refresh and try again`
      );

      console.error(err);
      await logAnalyticsError('setNotificationFilters', err as Error);
    }
  };

  const setNotificationKinds = async (
    notificationKinds: UserConfigNotificationKind[],
    showSuccess?: boolean
  ) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      if (
        (!data.configuration.notificationMethods.find(
          (nm) => nm.name === NotificationMethods.Email
        )?.enabled ||
          !notificationKinds.find((nk) => nk.name === NotificationKinds.Deals)
            ?.enabled) &&
        (!data?.configuration.devices ||
          data.configuration.devices.length === 0)
      ) {
        presentWarning(
          'Warning: You have disabled all notification methods which result in you no longer receiving any notifications about new deals'
        );
      }

      const updated = produce(data, (draft) => {
        draft.configuration.notificationKinds = notificationKinds;
      });
      await Promise.all([
        mutate(requestKey, updated, false),
        client.setUserConfigNotificationKinds({
          notificationKinds,
        }),
      ]);

      if (showSuccess) {
        presentSuccess('Settings Updated');
      }
    } catch (err) {
      presentError(
        `Error updating notification kind preferences. Please refresh and try again`
      );

      console.error(err);
      await logAnalyticsError('setNotificationKinds', err as Error);
    }
  };

  const setOrigins = async (origins: UserConfigOrigin[]) => {
    try {
      if (origins.length === 0) {
        presentWarning(
          'Warning: You have 0 departure airports which will result in you no longer receiving any FareDrop deals'
        );
      }

      const result = await client.setUserConfigOrigins({
        originIATAs: origins.map((origin) => origin.iata),
      });
      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.origins = result.data.setUserConfigOrigins;
        });
        await mutate(requestKey, updated, false);
      }
    } catch (error) {
      presentError(
        `Error updating departure airports. Please refresh and try again`
      );

      console.error(error);
      await logAnalyticsError('setOrigins', error as Error);
    }
  };

  const updateOrigin = async (originIATA: string, attributes: number) => {
    try {
      const result = await client.updateUserConfigOrigin({
        originIATA,
        attributes,
      });
      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.origins = result.data.updateUserConfigOrigin;
        });
        await mutate(requestKey, updated, false);
      }
    } catch (error) {
      presentError(
        `Error updating departure airport. Please refresh and try again`
      );

      console.error(error);
      await logAnalyticsError('updateOrigin', error as Error);
    }
  };

  const setHomeOrigin = async (iata: string) => {
    try {
      // Set the local cache immediately for immediate feedback
      if (data) {
        const origin = data.configuration.origins.find((o) => o.iata === iata);
        if (origin) {
          const updated = produce(data, (draft) => {
            draft.configuration.homeOriginIATA = iata;
          });
          await mutate(requestKey, updated, false);
        }
      }

      const result = (
        await client.setUserConfigHomeOrigin({
          homeOriginIATA: iata,
        })
      ).data;

      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.homeOriginIATA =
            result.setUserConfigHomeOrigin.homeOriginIATA;
          draft.configuration.origins = result.setUserConfigHomeOrigin.origins;
        });

        await mutate(requestKey, updated, false);
      }
    } catch (error) {
      presentError(`Error updating home airport. Please refresh and try again`);

      console.error(error);
      await logAnalyticsError('setHomeOrigin', error as Error);
    }
  };

  const resetOrigins = async (
    homeOriginIATA: string,
    origins: UserConfigOrigin[]
  ) => {
    try {
      const homeOriginResult = await client.setUserConfigHomeOrigin({
        homeOriginIATA,
      });

      const originsResult = await client.setUserConfigOrigins({
        originIATAs: origins.map((origin) => origin.iata),
      });

      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.homeOriginIATA =
            homeOriginResult.data.setUserConfigHomeOrigin.homeOriginIATA;
          draft.configuration.origins = originsResult.data.setUserConfigOrigins;
        });
        await mutate(requestKey, updated, false);
      }
    } catch (error) {
      presentError(
        `Error resetting departure airports. Please refresh and try again`
      );
      await logAnalyticsError('resetOrigins', error as Error);
    }
  };

  const setCurrency = async (currency: SupportedCurrencies) => {
    try {
      // Set the local cache immediately for immediate feedback
      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.currency = currency;
        });
        await mutate(requestKey, updated, false);
      }

      await client.setUserConfigCurrency({
        currency,
      });
    } catch (error) {
      presentError(`Error updating currency. Please refresh and try again`);

      console.error(error);
      await logAnalyticsError('setCurrency', error as Error);
    }
  };

  const removeOrigin = async (
    originToRemoveIATA: string,
    remainingOrigins: UserConfigOrigin[]
  ) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      if (remainingOrigins.length === 0) {
        presentWarning(
          'Warning: You have 0 departure airports which will result in you no longer receiving any FareDrop deals'
        );
      }

      // set the local cache for immediate feedback
      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.origins = [
            ...draft.configuration.origins.filter(
              (o) => o.iata !== originToRemoveIATA
            ),
          ];
        });
        await mutate(requestKey, updated, false);
      }

      const result = await client.removeUserConfigOrigin({
        originIATA: originToRemoveIATA,
      });
      if (data) {
        const updated = produce(data, (draft) => {
          draft.configuration.origins = result.data.removeUserConfigOrigin;
        });
        await mutate(requestKey, updated, false);
      }
    } catch (error) {
      presentError(
        `Error updating departure airports. Please refresh and try again`
      );

      console.error(error);
      await logAnalyticsError('removeOrigin', error as Error);
    }
  };

  const setProfile = async (
    phoneNumber?: string,
    firstName?: string,
    lastName?: string
  ) => {
    if (!data) throw new Error('Profile has not loaded yet, please try again');
    if (!phoneNumber && !firstName && !lastName)
      throw new Error('At least 1 argument required');

    let updatePhoneNumber = false;
    let updateFirstName = false;
    let updateLastName = false;

    const formattedNumber = phoneNumber
      ? formatPhoneNumber(phoneNumber)
      : undefined;
    const updated = produce(data, (draft) => {
      if (firstName && firstName !== draft.profile.firstName) {
        draft.profile.firstName = firstName;
        updateFirstName = true;
      }

      if (lastName && lastName !== draft.profile.lastName) {
        draft.profile.lastName = lastName;
        updateLastName = true;
      }

      if (
        formattedNumber &&
        formattedNumber !== formatPhoneNumber(draft.profile.phoneNumber ?? '')
      ) {
        draft.profile.phoneNumber = formattedNumber;
        updatePhoneNumber = true;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<any>[] = [];
    if (firstName && updateFirstName) {
      promises.push(client.setUserProfileFirstName({ firstName }));
    }

    if (lastName && updateLastName) {
      promises.push(client.setUserProfileLastName({ lastName }));
    }

    if (formattedNumber && updatePhoneNumber) {
      promises.push(
        client.setUserProfilePhoneNumber({ phoneNumber: formattedNumber })
      );
    }

    await Promise.all([mutate(requestKey, updated, false), ...promises]);

    await refreshSession();
  };

  const setTravelMonths = async (months: IUserConfigTravelMonths) => {
    try {
      if (!data)
        throw new Error('Profile has not loaded yet, please try again');

      if (!months.some((month) => month.enabled)) {
        presentWarning(
          'Warning: You have disabled all travel availability which will result in you no longer receiving any FareDrop deals'
        );
      }

      const updated = produce(data, (draft) => {
        draft.configuration.travelMonths = months;
      });

      await Promise.all([
        mutate(requestKey, updated, false),
        client.setUserConfigTravelMonths({
          travelMonths: months,
        }),
      ]);
    } catch (error) {
      presentError(
        `Error updating travel availability. Please refresh and try again`
      );

      console.error(error);
      await logAnalyticsError('setTravelMonths', error as Error);
    }
  };

  const refreshUser = async (updatedUser?: IFrontendUser) => {
    if (requestKey) {
      return await mutate(requestKey, updatedUser, {
        revalidate: !updatedUser,
      });
    }

    return;
  };

  const hasDealNotificationsEnabled =
    (data?.configuration.notificationMethods.find(
      (nm) => nm.name === NotificationMethods.Email
    )?.enabled &&
      data?.configuration.notificationKinds.find(
        (nk) => nk.name === NotificationKinds.Deals
      )?.enabled) ||
    (data?.configuration.devices && data.configuration.devices.length > 0);

  const isThisDeviceRegisteredForPushNotifications =
    !!deviceId &&
    !!data?.configuration.devices &&
    data.configuration.devices.some((d) => d.idDevice === deviceId);

  const idStripePlan = data?.billing?.idStripePlan;
  const planTypes = idStripePlan
    ? stripePlanIDToFareDropPlans(idStripePlan)
    : [];
  const isLimited =
    planTypes.length === 1 &&
    planTypes.some(
      (type) => type.toUpperCase() === FareDropPlan.LIMITED.toUpperCase()
    );

  return {
    user: data,
    isInitializing: (!error && !data) || (isApp && deviceId == null), // Some user state properties are dependent on the deviceId, so we need to wait until it's set for the user state to be initialized
    isLoading: isValidating,
    isLimited,
    hasPaymentInfo: data?.billing.hasPaymentInfo ?? false,
    subscriptionStatus:
      data?.billing.subscriptionStatus ?? SubscriptionStatus.Active,
    planEndInDays: data
      ? moment(data.billing.stripePlanEnd).diff(moment(), 'days')
      : undefined,
    error,
    timeout,
    hasDealNotificationsEnabled,
    isThisDeviceRegisteredForPushNotifications,
    homeCountryCode,
    userKey,
    cancelSubscription,
    mutate,
    putThisDevice,
    removeThisDevice,
    setAutoRenew,
    setDestinationRegions,
    setEmail,
    setNotificationMethods,
    setNotificationFilters,
    setNotificationKinds,
    setOrigins,
    setHomeOrigin,
    resetOrigins,
    removeOrigin,
    updateOrigin,
    setProfile,
    setTravelMonths,
    setCurrency,
    refreshUser,
  };
};

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }

  const number = phoneNumber.trim().replace(/[-()+]/g, '');
  return number.length == 10 ? `+1${number}` : `+${number}`;
};

export default useUser;
