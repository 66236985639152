import { IonRow } from '@ionic/react';
import { Dispatch, SetStateAction, useState } from 'react';

import { FareDropPlan } from '@faredrop/graphql-sdk';
import { isLimitedPlan } from '@faredrop/utilities';

import { useDevice } from '../../hooks/useDevice';
import Modal from './../MobileModal';
import usePlans from '../../hooks/usePlans';
import SubscriptionPlansSection from './../SubscriptionPlansSection';
import Loading from './../Loading';
import { isFrontendSubscriptionPromotion } from '../../utilities/plans-utilities';
import useUser from '../../hooks/user';

interface ContainerProps {
  show: boolean;
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
  onPlan: (plan: string) => Promise<void>;
  onGoToBooking: () => Promise<void>;
}

const DealDetailsPlansModal: React.FC<ContainerProps> = ({
  show,
  setIsModalActive,
  onGoToBooking,
  onPlan,
}) => {
  const { isSmallScreenSizeOrSmaller, isMediumScreenSizeOrSmaller } =
    useDevice();
  const { plans } = usePlans();
  const userState = useUser();
  const [loading, setLoading] = useState(false);

  const title = `Receive deals to over 250 destinations when you sign up for a FareDrop subscription`;
  const titleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: isMediumScreenSizeOrSmaller ? '2.5em' : '1.5em',
  };

  return (
    <Modal
      active={show}
      modalListener={setIsModalActive}
      size={isMediumScreenSizeOrSmaller ? '12' : '9'}
      darkBg={true}
      showAsFullPage={isMediumScreenSizeOrSmaller}
      height="100%"
      sx={{
        position: isMediumScreenSizeOrSmaller ? 'absolute' : 'absolute',
      }}
      closeButtonMargin={isMediumScreenSizeOrSmaller ? '10px' : undefined}
    >
      {plans && (
        <IonRow
          style={{
            padding: '1em',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
          }}
        >
          {loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                marginBottom: '3em',
                marginTop: '3em',
              }}
            >
              <Loading />
            </div>
          )}
          {!loading && (
            <>
              {isSmallScreenSizeOrSmaller && (
                <h6 style={titleStyle}>{title}</h6>
              )}
              {!isSmallScreenSizeOrSmaller && (
                <h1 style={titleStyle}>{title}</h1>
              )}
              <div
                style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                  color: 'var(--ion-color-gray-sub-text)',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  await onGoToBooking();
                }}
              >
                No thanks, take me to the booking site
              </div>
              <IonRow style={{ maxWidth: '90%' }}>
                <SubscriptionPlansSection
                  reverse={isFrontendSubscriptionPromotion(
                    isLimitedPlan(userState.user?.billing.idStripePlan)
                  )}
                  title={null}
                  removeBottomMargin={true}
                  hideBillingTextSection={(plan) =>
                    isSmallScreenSizeOrSmaller && plan === FareDropPlan.Limited
                  }
                  onSelect={async (plan) => {
                    setLoading(true);
                    await onPlan(plan);
                  }}
                />
              </IonRow>
            </>
          )}
        </IonRow>
      )}
    </Modal>
  );
};

export default DealDetailsPlansModal;
