import {
  createContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type { FC, ReactNode } from 'react';
import Stripe from 'stripe';

import useAnalytics from '../hooks/analytics';
import {
  FareDropPlan,
  NotificationFilters,
  NotificationKinds,
  NotificationMethods,
} from '@faredrop/graphql-sdk';
import useFareDropApiClient from '../hooks/faredropApiClient';
import useUser from '../hooks/user';
import { isProductionEnvironment } from '@faredrop/utilities';
import useStripe from '../hooks/useStripe';
import useLogError from '../hooks/logError';

export interface ChurnKeyContextValue {
  userHash?: string;
  initializeChurnKey: () => Promise<void>;
  showChurnKeyModal: (
    downgradePlan?: FareDropPlan,
    callback?: () => Promise<{ message: string }>
  ) => void;
  hideChurnKeyModal: () => void;
}

// Since we set the ChurnKeyProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const ChurnKeyContext = createContext<ChurnKeyContextValue>({
  userHash: undefined,
  initializeChurnKey: () => Promise.resolve(),
  showChurnKeyModal: () => undefined,
  hideChurnKeyModal: () => undefined,
});

interface ChurnKeyProps {
  children: ReactNode;
}

export const ChurnKeyProvider: FC<ChurnKeyProps> = (props) => {
  const [userHash, setUserHash] = useState<string>();
  const [idStripeSubscription, setIdStripeSubscription] = useState<string>();
  const [idCoupon, setIdCoupon] = useState<string>();
  const [initializedIdStripeCustomer, setInitializedIdStripeCustomer] =
    useState<string>();
  const [bfcm23GlobalMigration, setBfcm23GlobalMigration] = useState<boolean>();
  const sourceCheckCount = useRef(0);
  const { client } = useFareDropApiClient();
  const { logAnalyticsError } = useAnalytics();
  const userState = useUser();
  const { reconcileCoupon } = useStripe();
  const hasCompletedFlow = useRef(false);
  const { logError } = useLogError();

  useLayoutEffect(() => {
    if (!window.churnkey || !window.churnkey.created) {
      window.churnkey = { created: true };
      const a = document.createElement('script');
      a.src = 'https://assets.churnkey.co/js/app.js?appId=cl8qsfqf9';
      a.async = true;
      const b = document.getElementsByTagName('script')[0];
      if (b?.parentNode) {
        b.parentNode.insertBefore(a, b);
      }
    }
  }, []);

  // Initialize Churn Key
  useEffect(() => {
    if (userState?.user?.billing?.idStripeCustomer) {
      initializeChurnKey().catch((err) => {
        logError(
          'Failed to initialize churnkey',
          err && (err as Error).message
            ? (err as Error).message
            : 'Error or error message is undefined'
        ).catch((error) =>
          console.warn(
            'Failed to send error log to endpoint for initializing churnkey',
            error
          )
        );
        throw err; // Show uh-oh screen
      }); // Async - Fire and forget
    }
  }, [userState?.user?.billing?.idStripeCustomer]);

  const initializeUserHash = async () => {
    const idStripeCustomer = userState.user?.billing.idStripeCustomer;
    if (!idStripeCustomer) throw new Error('Missing customer information');
    if (idStripeCustomer !== initializedIdStripeCustomer) {
      try {
        const {
          userHash,
          idStripeSubscription,
          idCoupon,
          stripeSubscriptionMetadata,
        } = (await client.churnKeyUserHash({ idStripeCustomer })).data
          .churnKeyUserHash;
        setUserHash(userHash);
        setIdStripeSubscription(idStripeSubscription ?? undefined);
        setIdCoupon(idCoupon ?? undefined);
        setInitializedIdStripeCustomer(idStripeCustomer);
        setBfcm23GlobalMigration(
          stripeSubscriptionMetadata?.bfcm23GlobalMigration === 'true'
        );
      } catch (error) {
        console.warn('Failed to retrieve churnkey user hash');
      }
    }
  };

  const initializeChurnKey = (): Promise<void> => {
    return new Promise((resolve) => {
      // 8 seconds
      if (sourceCheckCount.current < 16) {
        sourceCheckCount.current += 1;

        if (window.churnkey && window.churnkey.created) {
          initializeUserHash()
            .then(resolve)
            .catch((error) => {
              console.warn(
                'Failed to initialize user hash for churnkey',
                error
              );
              throw error;
            });
        } else {
          setTimeout(() => {
            initializeChurnKey()
              .then(resolve)
              .catch((error) => {
                console.warn('Failed to initialize churnkey', error);
                throw error;
              });
          }, 500);
        }
      } else {
        logAnalyticsError(
          'churnKeyInitialization',
          new Error('churn key exceeded 8 second initialization limit')
        )
          .then(resolve)
          .catch((error) =>
            console.warn('Failed to log analytics error', error)
          );
      }
    });
  };

  const hideChurnKeyModal = () => {
    if (window.churnkey) window.churnkey.hide();
  };

  const showChurnKeyModal = (
    downgradePlan?: FareDropPlan,
    callback?: () => Promise<{ message: string }>
  ) => {
    // Churnkey caches stripe data, so if the churn key flow is completes and a user tries to start another session, weird state happens
    // To mitigate, let's refresh...
    if (hasCompletedFlow.current) location.reload();
    else if (
      userHash &&
      userState?.user &&
      userState.user.billing?.idStripeCustomer &&
      window.churnkey &&
      window.churnkey.created
    ) {
      const customerAttributes = {
        autoRenew: userState.user.billing.autoRenew,
        hasPaymentInfo: userState.user.billing.hasPaymentInfo,
        idCoupon: idCoupon,
        idStripePlan: userState.user.billing.idStripePlan,
        stripePlanEnd: userState.user.billing.stripePlanEnd,
        subscriptionStatus: userState.user.billing.subscriptionStatus,
        homeOriginIATA: userState.user.configuration.homeOriginIATA,
        hasDestinationRegionsEnabled:
          userState.user.configuration.destinationRegions.some(
            (dr) => dr.enabled
          ),
        hasDevices: userState.user.configuration.devices.length > 0,
        hasEconomyFiltered:
          userState.user.configuration.notificationFilters.some(
            (nf) => nf.name === NotificationFilters.Economy && nf.enabled
          ),
        hasPremiumEconomyFiltered:
          userState.user.configuration.notificationFilters.some(
            (nf) => nf.name === NotificationFilters.PremiumEconomy && nf.enabled
          ),
        hasBusinessFiltered:
          userState.user.configuration.notificationFilters.some(
            (nf) => nf.name === NotificationFilters.Business && nf.enabled
          ),
        hasStopsFiltered: userState.user.configuration.notificationFilters.some(
          (nf) => nf.name === NotificationFilters.OneStopOrMore && nf.enabled
        ),
        hasBudgetFiltered:
          userState.user.configuration.notificationFilters.some(
            (nf) => nf.name === NotificationFilters.BudgetAirlines && nf.enabled
          ),
        hasNonLieFlatFiltered:
          userState.user.configuration.notificationFilters.some(
            (nf) =>
              nf.name === NotificationFilters.NonLieFlatBusinessClassSeats &&
              nf.enabled
          ),
        hasDealsNotificationKind:
          userState.user.configuration.notificationKinds.some(
            (nk) => nk.name === NotificationKinds.Deals && nk.enabled
          ),
        hasNewsletterNotificationKind:
          userState.user.configuration.notificationKinds.some(
            (nk) => nk.name === NotificationKinds.Newsletter && nk.enabled
          ),
        hasEmailNotificationMethod:
          userState.user.configuration.notificationMethods.some(
            (nm) => nm.name === NotificationMethods.Email && nm.enabled
          ),
        hasTravelMonths: userState.user.configuration.travelMonths.some(
          (tm) => tm.enabled
        ),
        isEmailVerified: userState.user.profile.isEmailVerified,
        lastMobileLogin: userState.user.tracking.lastMobileLogin,
        downgradePlan,
        idFlow: downgradePlan ? undefined : 'cancel_091522', // Introducing an id so we can deprecate previous cancel flow
        bfcm23GlobalMigration,
      };

      // Source: https://docs.churnkey.co/installing-churnkey
      window.churnkey.init('show', {
        customerId: userState.user.billing.idStripeCustomer,
        subscriptionId: idStripeSubscription,
        authHash: userHash,
        appId: 'cl8qsfqf9', // cspell:disable-line
        mode: isProductionEnvironment() ? 'live' : 'test',
        provider: 'stripe',
        customerAttributes,
        i18n: {
          lang: 'en',
          messages: {
            en: {
              cancelSubscription: 'Next', // We show our own modal after churnkey cancel flow for downgrades
              cancelApplied: downgradePlan
                ? 'Subscription Downgraded'
                : undefined,
              cancelAppliedMessage: downgradePlan
                ? 'You subscription has successfully been downgraded'
                : undefined,
            },
          },
        },
        handleSupportRequest: () => {
          const request = downgradePlan ? 'downgrading' : 'canceling';
          window.location.href = `mailto:team@faredrop.com?subject=${encodeURIComponent(
            'Technical Issues'
          )}&body=${encodeURIComponent(
            `Hi FareDrop Team, my name is ${userState.user?.profile.firstName} ${userState.user?.profile.lastName}, and I am considering ${request} my subscription due to the following technical issues. Could you help me out?`
          )}`;
          window.churnkey.hide();
        },
        handleCancel: callback ?? undefined,
        onCancel: async () => {
          hasCompletedFlow.current = true;
        },
        onDiscount: async (
          customer: Stripe.Customer,
          coupon: Stripe.Coupon
        ) => {
          hasCompletedFlow.current = true;

          // Churnkey handles the discount well except for the scenario when there is a downgrade scheduled
          // In this case, churnkey applies the coupon to the current phase, not the downgrade phase
          // We need to reconcile the coupon to be applied to the correct schedule phase
          if (customer.subscriptions?.data[0].schedule) {
            await reconcileCoupon(coupon.id);
          }
        },
      });
    }
  };

  return (
    <ChurnKeyContext.Provider
      value={{
        userHash,
        initializeChurnKey,
        showChurnKeyModal,
        hideChurnKeyModal,
      }}
    >
      {props.children}
    </ChurnKeyContext.Provider>
  );
};

export default ChurnKeyContext;
