import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { MouseEventHandler } from 'react';
import { useHistory } from 'react-router';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import useUser from '../hooks/user';
import { FaredropLogo } from './FaredropLogo';
import { getAssetsBaseUrl } from '@faredrop/utilities';
import { AllowedPath } from '@faredrop/types';

export interface IMenuOption {
  name: string;
  target?: string;
  href: string;
  loggedIn: boolean;
  analyticsEngagementId?: AnalyticsEngagementId;
  hideOnSmallScreen?: boolean;
}

const MENU_OPTIONS: IMenuOption[] = [
  {
    name: 'Pricing',
    target: '#pricing',
    href: '?target=pricing',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.HEADER_PRICING,
  },
  {
    name: 'How it works',
    target: '#how-it-works',
    href: '?target=how-it-works',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.HEADER_HOW_IT_WORKS,
  },
  {
    name: 'FAQ',
    href: '/faq',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.HEADER_FAQ,
  },
  {
    name: 'Why FareDrop',
    href: '/reviews',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.HEADER_WHY_FAREDROP,
  },
  {
    name: 'Gift FareDrop',
    href: '/gift',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.HEADER_GIFT,
    hideOnSmallScreen: true,
  },
  {
    name: 'Flight Deals',
    href: AllowedPath.DEALS,
    loggedIn: true,
  },
  {
    name: 'Departure Airports',
    href: '/settings/departure-airports',
    loggedIn: true,
    hideOnSmallScreen: true,
  },
  {
    name: 'Deal Settings',
    href: '/settings/destination-preferences',
    loggedIn: true,
  },
  {
    name: 'Perks',
    href: '/perks',
    loggedIn: true,
    analyticsEngagementId: AnalyticsEngagementId.PERKS,
    hideOnSmallScreen: true,
  },
  {
    name: 'My Account',
    href: '/account/profile',
    loggedIn: true,
  },
  {
    name: 'Gift FareDrop',
    href: '/gift',
    loggedIn: true,
  },
  {
    name: 'Daily Drop',
    href: process.env.REACT_APP_DAILY_DROP_REFERRAL_URL ?? '',
    loggedIn: true,
    analyticsEngagementId: AnalyticsEngagementId.DAILY_DROP,
  },
  {
    name: 'Daily Drop',
    href: process.env.REACT_APP_DAILY_DROP_REFERRAL_URL ?? '',
    loggedIn: false,
    analyticsEngagementId: AnalyticsEngagementId.DAILY_DROP,
  },
];
interface ContainerProps {
  showLoadingDiv?: boolean;
  loggedIn?: boolean;
  optionsHidden?: boolean;
  ctaHidden?: boolean;
  search?: string;
  sx?: React.CSSProperties;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  showBackToHome?: boolean;
  backToHomeListener?: MouseEventHandler<HTMLAnchorElement>;
  backToHomeText?: string;
  getStartedTarget?: string;
  getStartedPath?: string;
  menuOptionsOverride?: (Partial<IMenuOption> & Pick<IMenuOption, 'name'>)[];
  optionsExcludeQueryParams?: string[];
}

const DesktopHeader: React.FC<ContainerProps | undefined> = (props) => {
  const { isMediumScreenSizeOrSmaller, isScreenLargerThanSize } = useDevice();
  const history = useHistory();
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const { smoothScroll } = useLandingPageNavigation();

  const userState = useUser();
  const configInitializing = userState.isInitializing;

  return (
    <>
      <IonRow
        className="row-vertical-align desktop-menu"
        style={{
          justifyContent: 'left',
          display: `${isMediumScreenSizeOrSmaller ? 'none' : 'flex'}`,
          marginTop: props?.loggedIn ? '10px' : '5px',
          ...props?.sx,
        }}
      >
        <IonCol size={props?.loggedIn ? '10' : '9'}>
          <IonRow
            className="row-vertical-align"
            style={{ justifyContent: 'left', marginLeft: '40px' }}
          >
            <FaredropLogo
              bw={false}
              excludeParams={props.optionsExcludeQueryParams}
            />
            {props?.optionsHidden || (
              <div
                style={{
                  paddingTop: '10px',
                  marginLeft: '2.5em',
                  display: 'flex',
                }}
              >
                {(props?.loggedIn
                  ? MENU_OPTIONS.filter((option) => option.loggedIn).filter(
                      (option) =>
                        isScreenLargerThanSize(1460) ||
                        !option.hideOnSmallScreen
                    )
                  : MENU_OPTIONS.filter((option) => !option.loggedIn).filter(
                      (options) =>
                        isScreenLargerThanSize(1300) ||
                        !options.hideOnSmallScreen
                    )
                ).map((option) => {
                  const override = props.menuOptionsOverride?.find(
                    (override) => override.name === option.name
                  );
                  if (override) {
                    option = {
                      ...option,
                      ...override,
                    };
                  }

                  if (option.name === 'Daily Drop') {
                    return (
                      <a
                        key={option.name}
                        className="header-menu-option"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        href={option.href}
                        onClick={async () => {
                          props?.logAnalyticsEngagement &&
                            option.analyticsEngagementId &&
                            (await props.logAnalyticsEngagement(
                              option.analyticsEngagementId
                            ));
                        }}
                      >
                        <img
                          src={`${getAssetsBaseUrl()}/icons/daily-drop.png`}
                          style={{
                            top: 0,
                            right: 0,
                            paddingBottom: '3px',
                            width: '30px',
                            paddingRight: '3px',
                          }}
                        />
                        {option.name}
                      </a>
                    );
                  } else if (option.name === 'Perks') {
                    return (
                      <a
                        key={option.name}
                        className="header-menu-option"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color:
                            history.location.pathname == option.href
                              ? 'var(--ion-color-green)'
                              : 'inherit',
                        }}
                        href={option.href}
                        onClick={async () => {
                          props?.logAnalyticsEngagement &&
                            option.analyticsEngagementId &&
                            (await props.logAnalyticsEngagement(
                              option.analyticsEngagementId
                            ));
                        }}
                      >
                        <div
                          style={{
                            height: '15px',
                            width: '35px',
                            backgroundColor: 'var(--ion-color-primary)',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '5px',
                            marginBottom: '2px',
                            paddingTop: '2px',
                            fontSize: '11px',
                            borderRadius: '15px',
                          }}
                        >
                          New
                        </div>
                        {option.name}
                      </a>
                    );
                  }

                  return (
                    <a
                      key={option.name}
                      className="header-menu-option"
                      onClick={async () => {
                        // In the case that this is too hokey, we can just opt for an href attribute
                        // on this "a" tag with an href to option.target. The only reason we are opting
                        // for this onClick instead of the href attribute is so that we can have smooth
                        // scrolling to the element on desktop (this smooth scrolling does not work on
                        // mobile, however, but items are still put into view)
                        if (option.target) {
                          smoothScroll(option.target);
                          return;
                        }

                        if (option.href) {
                          goWithStickyParamsPath(
                            option.href,
                            undefined,
                            props.optionsExcludeQueryParams
                          );
                          return;
                        }

                        if (
                          option.analyticsEngagementId &&
                          props?.logAnalyticsEngagement
                        ) {
                          await props?.logAnalyticsEngagement(
                            option.analyticsEngagementId
                          );
                        }
                      }}
                      style={{
                        color:
                          history.location.pathname == option.href
                            ? 'var(--ion-color-green)'
                            : 'inherit',
                      }}
                    >
                      {option.name}
                    </a>
                  );
                })}
              </div>
            )}
          </IonRow>
        </IonCol>
        {props?.ctaHidden || (
          <IonCol
            size={props?.loggedIn ? '2' : '3'}
            style={{
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '2em',
            }}
          >
            {props?.loggedIn ? (
              <IonRow
                className="row-vertical-align"
                style={{ justifyContent: 'right' }}
              >
                <a
                  onClick={async () => {
                    history.push(AllowedPath.LOGOUT);
                  }}
                >
                  <button
                    className="transparent-button md-button green-button"
                    style={{
                      fontSize: '16px',
                      color: 'var(--ion-color-primary)',
                    }}
                  >
                    Sign out
                  </button>
                </a>
              </IonRow>
            ) : (
              <IonRow
                className="row-vertical-align"
                style={{ justifyContent: 'right' }}
              >
                <a
                  className="header-menu-option"
                  onClick={async () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.LOGIN,
                      search: props.search,
                    });
                    props?.logAnalyticsEngagement &&
                      (await props?.logAnalyticsEngagement(
                        AnalyticsEngagementId.HEADER_LOGIN
                      ));
                  }}
                >
                  Login
                </a>
                <a
                  onClick={async (e) => {
                    e.preventDefault();
                    if (props.getStartedPath) {
                      goWithStickyParamsLocation({
                        pathname: props.getStartedPath,
                        search: props.search,
                      });
                    } else {
                      smoothScroll(props.getStartedTarget ?? '#register');
                    }
                    props?.logAnalyticsEngagement &&
                      (await props?.logAnalyticsEngagement(
                        AnalyticsEngagementId.HEADER_GET_STARTED
                      ));
                  }}
                >
                  <button
                    className="transparent-button md-button green-button"
                    style={{
                      fontSize: '16px',
                      color: 'var(--ion-color-primary)',
                    }}
                  >
                    Get started
                  </button>
                </a>
              </IonRow>
            )}
          </IonCol>
        )}
      </IonRow>
      {configInitializing && props?.showLoadingDiv && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            position: 'absolute',
            zIndex: -1,
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: 'white',
              margin: 'auto',
            }}
          >
            <p style={{ lineHeight: '2em', color: '#11111155' }}>
              <span>
                <IonSpinner
                  name="crescent"
                  style={{
                    width: '20px',
                    height: '20px',
                    opacity: 0.3,
                    verticalAlign: 'middle',
                    marginRight: '.5em',
                  }}
                  color="primary"
                />
              </span>
              Loading ...
            </p>
          </div>
        </div>
      )}
      {props?.showBackToHome && (
        <div style={{ position: 'fixed', right: '2em' }}>
          <a
            onClick={(e) => {
              if (props?.backToHomeListener) {
                props?.backToHomeListener(e);
              } else {
                history.push(AllowedPath.LOGOUT);
              }
            }}
          >
            <button
              className="transparent-button md-button green-button"
              style={{
                fontSize: '16px',
                color: 'var(--ion-color-primary)',
                whiteSpace: 'nowrap',
              }}
            >
              {props.backToHomeText ?? 'Back to Home'}
            </button>
          </a>
        </div>
      )}
    </>
  );
};

export default DesktopHeader;
