import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import Dropdown from '../components/Dropdown';
import Loading from '../components/Loading';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import SearchAirportsComponent from '../components/SearchAirportsComponent';
import SettingsBackground from '../components/SettingsBackground';
import { Tab } from '../contexts/tabContext';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useMapBox from '../hooks/useMapBox';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PAGES } from '../types/types';
import DesktopHeader from './../components/DesktopHeader';
import Footer from './../components/Footer';
import HidingHeader from './../components/HidingHeader';
import SettingsSideMenu, {
  MENU_OPTIONS,
} from './../components/SettingsSideMenu';
import './../theme/DepartureAirportsSettings.css';
import './../theme/util.css';
import UpgradeModal from '../components/UpgradeModal';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';
import { getPlanName } from '../utilities/plans-utilities';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';

const DepartureAirportsSettings: React.FC = () => {
  const { updateSelectedTab } = useTabs();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { width } = useWindowDimensions();
  const { isExtraLargeScreenSize, isApp } = useDevice();
  const [selectHomeAirportMode, setSelectHomeAirportMode] = useState(false);

  const [originConfigIATA, setOriginConfigIATA] = useState<string>();

  const userState = useUser();
  const isInitializing = userState.isInitializing;
  const noInternet = userState.timeout;

  // Source mapbox from CDN if in App
  useMapBox();

  useEffect(() => {
    updateSelectedTab(Tab.SETTINGS).catch((error) => {
      console.warn('Failed to select tab', Tab.SETTINGS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  return (
    (isInitializing && <Loading />) ||
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize || !userState.hasPaymentInfo ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={!userState.hasPaymentInfo && !isExtraLargeScreenSize}
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          <IonRow
            style={{
              paddingTop: isExtraLargeScreenSize
                ? '5em'
                : isApp
                ? '1em'
                : undefined,
              paddingBottom: '10em',
              position: 'relative',
            }}
          >
            {selectHomeAirportMode && (
              <div
                style={{
                  backgroundColor: 'rgba(20,20,20, .5)',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  zIndex: 1000,
                }}
                onClick={() => setSelectHomeAirportMode(false)}
              />
            )}
            <IonCol
              size="10"
              style={{
                margin: 'auto',
                padding: 0,
              }}
            >
              <IonRow>
                <h1 className="title-font" style={{ marginTop: '2em' }}>
                  {isExtraLargeScreenSize ? 'Deal Settings' : 'Settings'}
                </h1>
              </IonRow>
              <IonRow
                className={!isExtraLargeScreenSize ? 'row-vertical-align' : ''}
              >
                <SettingsSideMenu
                  activeOption={PAGES.DEPARTURE_AIRPORTS}
                  isVisible={isExtraLargeScreenSize}
                />
                <Dropdown
                  options={MENU_OPTIONS[1].options.map(
                    (option) => option.label
                  )}
                  placeholder={PAGES.DEPARTURE_AIRPORTS}
                  onOptionClick={(option: string) => {
                    goWithStickyParamsPath(option);
                  }}
                  hrefOptions={true}
                  hidden={isExtraLargeScreenSize}
                  width={isExtraLargeScreenSize ? width / 2 : '100%'}
                />
                <IonCol
                  size={isExtraLargeScreenSize ? '8' : '12'}
                  style={{ marginLeft: !isExtraLargeScreenSize ? 0 : '5em' }}
                  className="departure-airports-section-container"
                >
                  <IonRow>
                    <h2>Departure Airports</h2>
                    <p>
                      Choose which airports you want to receive deals for.
                      Consider adding airports that may be a bit farther away
                      from you but offer better international airfare deals.
                    </p>
                  </IonRow>
                  <SearchAirportsComponent
                    showMap={isExtraLargeScreenSize}
                    onChangeHomeAirport={() => {
                      setSelectHomeAirportMode(true);
                    }}
                    onSelectHomeAirport={() => setSelectHomeAirportMode(false)}
                    highlightOnHover={selectHomeAirportMode}
                    onInvalidEditOriginConfig={(iata) => {
                      setOriginConfigIATA(iata);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          message={
            originConfigIATA
              ? `Updating departure airport settings is only available on the ${getPlanName(
                  FareDropPlan.Pro
                )} Plan. ${
                  HIDE_MEMBERSHIP_LINKS
                    ? HIDE_UPGRADE_MESSAGE
                    : 'Please upgrade plans to update departure airport settings.'
                }`
              : undefined
          }
          onClose={() => {
            setOriginConfigIATA(undefined);
          }}
        />
      </IonPage>
    )
  );
};

export default DepartureAirportsSettings;
