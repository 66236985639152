import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { chevronExpand, close } from 'ionicons/icons';
import _, { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import {
  Deal,
  DealSearchQuery,
  DealStatus,
  SeatClass,
  VerifiedAirfare,
  VerifiedAirfareStatus,
} from '@faredrop/graphql-sdk';
import {
  getAssetsBaseUrl,
  getCurrencySymbol,
  isDomestic,
} from '@faredrop/utilities';

import './../theme/DealSearch.css';

import { PriceRangeColors } from './RoutePriceRange';
import DealSearchbar from './DealSearchbar';
import FlightBadges from './FlightBadges';
import useAirports from '../hooks/airports';
import DealPriceHeader from './DealPriceHeader';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import { ScreenSize } from '../contexts/deviceContext';
import DealSearchPriceRange from './DealSearchPriceRange';
import { BadgeLabel } from '../utilities/deals-utilities';

interface ContainerProps {
  onAirfareClick: (verifiedAirfare: VerifiedAirfare) => void;
}

const DealSearch: React.FC<ContainerProps> = ({ onAirfareClick }) => {
  const { getAirport } = useAirports();
  const {
    isExtraSmallScreenSize,
    isSmallScreenSizeOrSmaller,
    isSmallScreenSize,
    isMediumScreenSizeOrSmaller,
    isLargeScreenSizeOrSmaller,
    isLargeScreenSize,
    isExtraLargeScreenSize,
    isScreenLargerThanSize,
    isScreenSmallerThanSize,
    isScreenBetweenSizes,
  } = useDevice();
  const userState = useUser();

  const [searchResult, setSearchResult] =
    useState<DealSearchQuery['dealSearch']>();
  const [resultsExpanded, setResultsExpanded] = useState(false);

  const [noResultsMessage, setNoResultsMessage] = useState<string>();
  const [noResultSubMessage, setNoResultSubMessage] = useState<string>();

  const searchResultsRef = useRef<HTMLDivElement>(null);
  const [searchResultsHeight, setSearchResultsHeight] = useState(0);

  const clearSearchResultsAfterAnimation = useRef(false);

  const origin = searchResult?.routeAggregation.originIATA
    ? getAirport(searchResult.routeAggregation.originIATA)
    : undefined;
  const destination = searchResult?.routeAggregation.destinationIATA
    ? getAirport(searchResult.routeAggregation.destinationIATA)
    : undefined;

  // Since results are not rendered all at the same time, the height can be different per render
  // Debounce renders for smoothest experience
  const calculateSearchResultsHeight = debounce(async () => {
    if (searchResultsRef.current) {
      if (
        resultsExpanded &&
        ((origin && destination && searchResult) || noResultsMessage)
      ) {
        setSearchResultsHeight(searchResultsRef.current?.scrollHeight ?? 0);
      } else {
        setSearchResultsHeight(0);
      }
    }
  }, 200);

  const onSearchResultTransitionEnd = (event: TransitionEvent) => {
    if (
      event.propertyName === 'max-height' &&
      clearSearchResultsAfterAnimation.current
    ) {
      clearSearchResultsAfterAnimation.current = false;
      if (!resultsExpanded) {
        setSearchResult(undefined);
      }
    }
  };

  const expandOrCollapse = () => {
    if (!resultsExpanded) {
      setResultsExpanded(true);
    } else {
      setResultsExpanded(false);
    }
  };

  useEffect(() => {
    if (searchResultsRef.current) {
      searchResultsRef.current.addEventListener(
        'transitionend',
        onSearchResultTransitionEnd
      );
    }
    return () => {
      if (searchResultsRef.current) {
        searchResultsRef.current.removeEventListener(
          'transitionend',
          onSearchResultTransitionEnd
        );
      }
    };
  }, [searchResultsRef.current]);

  useEffect(() => {
    calculateSearchResultsHeight()?.catch(() => {
      throw new Error('Failed to set search result height');
    });
  }, [
    searchResultsRef.current?.scrollHeight,
    origin,
    destination,
    searchResult,
    resultsExpanded,
  ]);

  let currencyNotice: string | undefined = undefined;
  if (
    searchResult?.routeAggregation &&
    origin &&
    searchResult.routeAggregation.currency !== origin.googleCurrencyCode
  ) {
    currencyNotice = `* All prices shown are in ${searchResult.routeAggregation.currency?.toUpperCase()}`;
  }

  const showExpandCollapseButtons = !!searchResult || !!noResultsMessage;

  const meanMinPrice = searchResult?.routeAggregation?.meanMinPrice90Days
    ? Math.floor(searchResult.routeAggregation.meanMinPrice90Days)
    : undefined;
  const meanPrice = searchResult?.routeAggregation.meanPrice90Days
    ? Math.floor(searchResult.routeAggregation.meanPrice90Days)
    : undefined;

  let todayDeal: Deal | undefined = undefined;
  if (
    searchResult?.deals.length &&
    moment.utc().isSame(moment.utc(searchResult.deals[0].created), 'day')
  ) {
    todayDeal = searchResult.deals[0];
  }

  const airfareAndDealColumns =
    isExtraSmallScreenSize || isLargeScreenSize ? 1 : 2;

  let expandCollapseIcon: string | undefined = undefined;
  if (showExpandCollapseButtons) {
    if (!resultsExpanded) {
      expandCollapseIcon = chevronExpand;
    } else {
      expandCollapseIcon = close;
    }
  }

  return (
    <>
      <IonRow
        style={{
          marginTop: isExtraSmallScreenSize ? '1em' : '2em',
          marginLeft: isExtraSmallScreenSize ? '4%' : '8%',
        }}
      >
        <h2
          className="title-font"
          style={{
            marginBottom: isExtraSmallScreenSize ? '1em' : '1.5em',
            fontSize: isMediumScreenSizeOrSmaller ? 20 : 24,
            fontFamily: 'nexa-bold',
          }}
        >
          Search Deals
        </h2>
        {isExtraSmallScreenSize && showExpandCollapseButtons && (
          <IonCol
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: '12px',
            }}
          >
            <IonIcon
              icon={expandCollapseIcon}
              color="gray"
              onClick={expandOrCollapse}
            />
          </IonCol>
        )}
      </IonRow>
      <IonRow
        style={{
          zIndex: 100,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-1.25em',
          marginBottom: !isExtraSmallScreenSize ? '2em' : undefined,
        }}
      >
        <IonRow
          style={{
            marginLeft: !isExtraSmallScreenSize ? '8%' : undefined,
          }}
        >
          <IonCol
            sizeXl="8"
            sizeMd="10"
            sizeSm="11"
            sizeXs="12"
            style={{
              display: 'flex',
            }}
          >
            <DealSearchbar
              onResult={(input, result, errorMessage) => {
                const resultsExpandedBefore = resultsExpanded;
                setResultsExpanded(true);
                if (errorMessage) {
                  setNoResultsMessage(errorMessage);
                } else {
                  if (
                    result == null &&
                    input?.from &&
                    input.to &&
                    input.seatClass
                  ) {
                    const isDomesticRoute = isDomestic(
                      getAirport(input.from),
                      getAirport(input.to)
                    );
                    setNoResultsMessage(
                      'No results found. Please try a different route.'
                    );
                    setNoResultSubMessage(
                      input.seatClass === SeatClass.Business && isDomesticRoute
                        ? 'FareDrop only searches business class seats for international routes'
                        : 'FareDrop searches a maximum of 2 stops for each route'
                    );
                    setSearchResult(result);
                  } else if (
                    result == null &&
                    (!input?.from || !input?.to || !input.seatClass)
                  ) {
                    setResultsExpanded(false);

                    // Since the animation is dependent on content height, we need to wait to clear the results until after the animation is complete
                    if (resultsExpandedBefore) {
                      clearSearchResultsAfterAnimation.current = true;
                    } else {
                      setSearchResult(undefined);
                    }
                  } else if (noResultsMessage) {
                    setNoResultsMessage(undefined);
                    setSearchResult(result);
                  } else {
                    setSearchResult(result);
                  }
                }
              }}
            />
          </IonCol>
          {!isExtraSmallScreenSize && showExpandCollapseButtons && (
            <IonCol
              size="auto"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '20px',
                marginLeft: '-1px',
              }}
            >
              <IonIcon
                icon={expandCollapseIcon}
                onClick={expandOrCollapse}
                size={isSmallScreenSize ? 'small' : undefined}
              />
            </IonCol>
          )}
        </IonRow>
        <div
          ref={searchResultsRef}
          style={{
            opacity: searchResultsHeight > 0 ? 1 : 0,
            maxHeight: `${searchResultsHeight}px`,
            overflow: 'hidden',
            transition: 'max-height .5s ease, opacity .5s ease',
            marginLeft: isExtraSmallScreenSize ? '4%' : '8%',
            marginRight: isExtraSmallScreenSize ? '4%' : '8%',
          }}
        >
          {origin && destination && searchResult && (
            <IonRow
              style={{
                fontFamily: 'nexa-bold',
                marginTop: !isExtraSmallScreenSize ? '2em' : undefined,
              }}
            >
              <IonCol
                size="12"
                sizeLg="6"
                style={{
                  paddingLeft: isMediumScreenSizeOrSmaller ? undefined : '2em',
                  paddingRight: isMediumScreenSizeOrSmaller ? undefined : '2em',
                }}
              >
                <IonRow
                  style={{
                    textAlign: 'center',
                    lineHeight: '1.25em',
                    display: 'flex',
                    justifyContent: isExtraSmallScreenSize
                      ? 'center'
                      : 'flex-start',
                  }}
                >
                  {`Today's lowest roundtrip price to ${destination.city}`}
                </IonRow>
                <IonRow
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1.25em',
                    marginBottom: '-1.75em',
                  }}
                >
                  <DealPriceHeader
                    price={searchResult.routeAggregation?.minPrice ?? 0}
                    usually={
                      ((meanPrice ?? 0) - (meanMinPrice ?? 0)) / 2 +
                      (meanMinPrice ?? 0)
                    }
                    currency={
                      searchResult?.routeAggregation?.currency ?? undefined
                    }
                    style={{
                      backgroundColor: '#F8F6F3',
                      border: '4px solid var(--ion-color-primary)',
                    }}
                    textColor="black"
                    usePercent={true}
                  />
                </IonRow>
                <IonRow>
                  <img
                    style={{
                      height: '300px',
                      width: '100%',
                      borderRadius: '15px',
                      objectFit: 'cover',
                    }}
                    src={`${getAssetsBaseUrl()}/iatas/${
                      searchResult.routeAggregation?.destinationIATA ?? 'empty'
                    }.jpg`}
                  />
                </IonRow>
                <IonRow
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: `2em ${isExtraSmallScreenSize ? '1em' : '2em'}`,
                  }}
                >
                  <DealSearchPriceRange
                    price={Math.floor(searchResult.routeAggregation.minPrice)}
                    meanMinPrice={meanMinPrice ?? 0}
                    meanPrice={meanPrice ?? 0}
                    seatClass={searchResult.routeAggregation.seatClass}
                    destinationIATA={destination.iata}
                    currency={
                      searchResult.routeAggregation?.currency ?? undefined
                    }
                    deal={todayDeal}
                  />
                </IonRow>
                {currencyNotice && (
                  <IonRow>
                    <p
                      style={{
                        fontSize: 16,
                        fontFamily: 'nexa',
                        color: 'rgba(159, 155, 147, 1)',
                        lineHeight: '17px',
                        marginBottom: '4em',
                      }}
                    >
                      {currencyNotice}
                    </p>
                  </IonRow>
                )}
              </IonCol>
              <IonCol
                size="12"
                sizeLg="6"
                style={{
                  paddingLeft: isMediumScreenSizeOrSmaller ? undefined : '2em',
                  paddingRight: isMediumScreenSizeOrSmaller ? undefined : '2em',
                }}
              >
                {!!searchResult.verifiedAirfares?.length && (
                  <>
                    <IonRow style={{ marginBottom: '.5em' }}>
                      Best Airfares by Month
                    </IonRow>
                    {_.chunk(
                      searchResult.verifiedAirfares,
                      airfareAndDealColumns
                    ).map((chunk) => {
                      if (
                        airfareAndDealColumns > 1 &&
                        chunk.length < airfareAndDealColumns
                      ) {
                        const emptyAirfare: VerifiedAirfare = {
                          departureDate: 'empty',
                          destinationIATA: chunk[0].destinationIATA,
                          idAirfareSource: chunk[0].idAirfareSource,
                          originIATA: chunk[0].originIATA,
                          seatClass: SeatClass.Economy,
                          status: VerifiedAirfareStatus.Unknown,
                        };
                        chunk.push(emptyAirfare);
                      }
                      return (
                        <IonRow
                          key={`${chunk[0].destinationIATA}-${chunk[0].departureDate}`}
                        >
                          {chunk.map((a) => {
                            let underlineColor: string | undefined = undefined;
                            if (a.totalPrice && meanPrice && meanMinPrice) {
                              if (a.totalPrice >= meanPrice) {
                                underlineColor = PriceRangeColors.HIGH;
                              } else if (a.totalPrice < meanMinPrice) {
                                underlineColor = PriceRangeColors.LOW;
                              } else {
                                underlineColor = PriceRangeColors.TYPICAL;
                              }
                            }

                            const dateHasRow =
                              isMediumScreenSizeOrSmaller ||
                              isExtraLargeScreenSize;
                            const badgeHasColumn =
                              isScreenBetweenSizes(
                                400,
                                ScreenSize.SMALL_START
                              ) ||
                              isScreenBetweenSizes(
                                ScreenSize.MEDIUM_START,
                                ScreenSize.LARGE_START
                              ) ||
                              isScreenLargerThanSize(1599);
                            const smallBadge =
                              isScreenSmallerThanSize(401) ||
                              isSmallScreenSize ||
                              isScreenBetweenSizes(
                                ScreenSize.LARGE_START,
                                1600
                              );
                            const badgeComponent = (
                              <FlightBadges
                                verifiedAirfare={a}
                                includeLabels={false}
                                createOrUpgrade={
                                  userState.isLimited
                                    ? BadgeLabel.Upgrade
                                    : undefined
                                }
                                hideBackground={userState.isLimited}
                                containerStyle={{ position: 'initial' }}
                                size={smallBadge ? 'small' : 'large'}
                                width={smallBadge ? 18 : undefined}
                                height={smallBadge ? 18 : undefined}
                                color={
                                  userState.isLimited
                                    ? 'var(--ion-color-primary)'
                                    : undefined
                                }
                              />
                            );

                            const url = a.bookingUrl ?? a.departureUrl;
                            const formattedDate = `${moment(
                              a.departureDate,
                              'YYYY-MM-DD'
                            ).format('MMMM Do, YYYY')}`;
                            const price = `${getCurrencySymbol(a.currency)}${
                              a.totalPrice
                            }`;
                            return (
                              <IonCol
                                key={a.departureDate}
                                className="deal-search-airfare"
                                style={{
                                  border: `2px solid var(--ion-color-primary)`,
                                  color: 'black',
                                  borderRadius: '5px',
                                  margin: isLargeScreenSize ? '5px' : '7px',
                                  paddingLeft: '15px',
                                  paddingRight: '15px',
                                  paddingTop: isLargeScreenSize
                                    ? '8px'
                                    : '10px',
                                  paddingBottom: isLargeScreenSize
                                    ? '6px'
                                    : '8px',
                                  cursor: url ? 'pointer' : undefined,
                                  display: 'flex',
                                  visibility:
                                    a.totalPrice == null ? 'hidden' : undefined,
                                }}
                                onClick={() => {
                                  if (a.totalPrice) {
                                    onAirfareClick(a);
                                  }
                                }}
                              >
                                <IonCol
                                  style={{
                                    fontSize: isLargeScreenSize
                                      ? '.9em'
                                      : '1em',
                                    display: 'flex',
                                    flex: 1,
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                  }}
                                >
                                  {dateHasRow && (
                                    <IonRow style={{ marginBottom: '5px' }}>
                                      {formattedDate}
                                    </IonRow>
                                  )}
                                  <IonRow style={{ width: '100%' }}>
                                    <div
                                      style={{
                                        height: '10px',
                                        width: '10px',
                                        backgroundColor: underlineColor,
                                        borderRadius: '5px',
                                        marginTop: '2px',
                                        marginRight: '5px',
                                      }}
                                    ></div>
                                    <IonCol
                                      size="auto"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {price}
                                    </IonCol>
                                    {!dateHasRow && (
                                      <IonCol
                                        size="auto"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '.75em',
                                        }}
                                      >
                                        {formattedDate}
                                      </IonCol>
                                    )}
                                    {!badgeHasColumn && (
                                      <IonCol
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '.5em',
                                          justifyContent: isLargeScreenSize
                                            ? 'flex-end'
                                            : undefined,
                                          marginTop: isScreenLargerThanSize(
                                            1599
                                          )
                                            ? '-4px'
                                            : 0,
                                        }}
                                      >
                                        {badgeComponent}
                                      </IonCol>
                                    )}
                                  </IonRow>
                                </IonCol>
                                {badgeHasColumn && (
                                  <IonCol
                                    size="auto"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginRight: '-.25em',
                                    }}
                                  >
                                    {badgeComponent}
                                  </IonCol>
                                )}
                              </IonCol>
                            );
                          })}
                        </IonRow>
                      );
                    })}
                  </>
                )}
                {!!searchResult.deals?.length && (
                  <div
                    style={{
                      marginBottom: isMediumScreenSizeOrSmaller
                        ? '2em'
                        : undefined,
                    }}
                  >
                    <IonRow
                      style={{
                        marginTop: isLargeScreenSizeOrSmaller ? '1.5em' : '3em',
                        marginBottom: '.5em',
                      }}
                    >
                      Past Deal Notifications
                    </IonRow>
                    {_.chunk(
                      searchResult.deals.filter((d) => {
                        return !moment
                          .utc()
                          .isSame(moment.utc(d.created), 'day');
                      }),
                      airfareAndDealColumns
                    ).map((chunk) => {
                      if (
                        airfareAndDealColumns > 1 &&
                        chunk.length < airfareAndDealColumns
                      ) {
                        const emptyDeal: Deal = {
                          destinationRegion: '',
                          historicalMeanPrice: 0,
                          idAirfareSource: chunk[0].idAirfareSource,
                          minPrice: 0,
                          originIATA: chunk[0].originIATA,
                          provider: chunk[0].provider,
                          seatClass: chunk[0].seatClass,
                          status: DealStatus.Sent,
                          dda: '',
                        };
                        chunk.push(emptyDeal);
                      }
                      return (
                        <IonRow key={`${chunk[0].created}`}>
                          {chunk.map((d) => {
                            const dateTitle = `${moment(d.created).fromNow()}`;
                            return (
                              <IonCol
                                key={`${d.created}-${d.idAirfareSource}`}
                                style={{
                                  border: '2px solid var(--ion-color-primary)',
                                  color: 'black',
                                  backgroundColor: '#F8F6F3',
                                  borderRadius: '5px',
                                  margin: isLargeScreenSize ? '5px' : '7px',
                                  paddingLeft: '15px',
                                  paddingRight: '15px',
                                  paddingTop: isLargeScreenSize
                                    ? '8px'
                                    : '10px',
                                  paddingBottom: isLargeScreenSize
                                    ? '6px'
                                    : '8px',
                                  display: 'flex',
                                  visibility:
                                    d.minPrice === 0 ? 'hidden' : undefined,
                                }}
                              >
                                {d.minPrice > 0 && (
                                  <IonCol
                                    style={{
                                      fontSize: isLargeScreenSize
                                        ? '.9em'
                                        : '1em',
                                      display: 'flex',
                                      flex: 1,
                                      alignItems: 'flex-start',
                                      flexDirection: 'column',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <IonRow>
                                      {`${getCurrencySymbol(d.currency)}${
                                        d.minPrice
                                      } - ${dateTitle}`}
                                    </IonRow>
                                  </IonCol>
                                )}
                              </IonCol>
                            );
                          })}
                        </IonRow>
                      );
                    })}
                  </div>
                )}
              </IonCol>
            </IonRow>
          )}
          {noResultsMessage && (
            <IonRow
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '200px',
              }}
            >
              <h3
                style={{
                  color: isSmallScreenSizeOrSmaller ? '#ccc' : '#222',
                  margin: '0',
                  fontSize: isSmallScreenSizeOrSmaller ? 16 : 22,
                }}
              >
                {noResultsMessage}
              </h3>
              {!isSmallScreenSizeOrSmaller && noResultSubMessage && (
                <h6 style={{ color: '#222', fontFamily: 'nexa' }}>
                  {noResultSubMessage}
                </h6>
              )}
            </IonRow>
          )}
        </div>
      </IonRow>
    </>
  );
};

export default DealSearch;
