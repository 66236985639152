import { IonCol, IonRow } from '@ionic/react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { Airport, FlightSegment } from '@faredrop/graphql-sdk';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import useAirports from '../../hooks/airports';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { useDevice } from '../../hooks/useDevice';
import usePresentToast from '../../hooks/presentToast';

interface ContainerProps {
  hasAccess: boolean;
  segment?: FlightSegment;
  departureDate?: string;
  onHiddenDetailClick?: () => void;
}

const DealDetailsSegmentDiagram: React.FC<ContainerProps> = ({
  hasAccess,
  segment,
  departureDate,
  onHiddenDetailClick,
}) => {
  const { getAirportOrSubAirportWithBackendFallback, airports } = useAirports();
  const { isLargeScreenSize } = useDevice();
  const { presentError } = usePresentToast();

  const [departureAirport, setDepartureAirport] = useState<Airport>();
  const [arrivalAirport, setArrivalAirport] = useState<Airport>();

  const segmentDetailsRef = useRef<HTMLIonColElement>(null);
  const { height: segmentDetailsHeight } =
    useContainerDimensions(segmentDetailsRef);

  useEffect(() => {
    const buildAirports = async () => {
      if (segment?.originIATA) {
        // It's possible the origin for this segment is not an origin or a destination, so we may need to retrieve it from our backend
        const origin = await getAirportOrSubAirportWithBackendFallback(
          segment.originIATA
        );
        if (origin) {
          setDepartureAirport(origin);
        }
      }

      if (segment?.destinationIATA) {
        // It's possible the destination for this segment is not an origin or a destination, so we may need to retrieve it from our backend
        const destination = await getAirportOrSubAirportWithBackendFallback(
          segment.destinationIATA
        );
        if (destination) {
          setArrivalAirport(destination);
        }
      }
    };

    // Wait until the airports cache has loaded
    if (airports) {
      buildAirports().catch(() => {
        presentError(
          'Uh oh - failed to load airports. Please refresh the page.'
        );
      });
    }
  }, [segment?.originIATA, segment?.destinationIATA, airports]);

  const departMoment = segment?.departureTime
    ? moment.utc(segment.departureTime)
    : undefined;
  const arrivalMoment = segment?.arrivalTime
    ? moment.utc(segment.arrivalTime)
    : undefined;

  const departureDateMoment = departureDate ? moment(departureDate) : undefined;
  const departureDateString = departureDateMoment?.format('MMMM Do, YYYY');
  const departureDateSplit = departureDateString?.split(' ');

  const isOvernightLayover = !!segment?.alerts?.find(
    (a) => a === 'Overnight layover'
  );

  return (
    <IonRow
      style={{
        minHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgb(44, 98, 92, .08)',
        borderRadius: '10px',
        fontSize: '14px',
        padding: '8px',
      }}
    >
      {!!(departMoment || departureDateMoment) && (
        <IonRow
          style={{
            paddingTop: '5px',
            fontFamily: 'nexa',
            color: 'var(--ion-color-primary)',
            fontSize: isLargeScreenSize ? '14px' : '16px',
            paddingBottom: '10px',
            textAlign: 'center',
            lineHeight: '1.1em',
          }}
        >
          {departMoment ? (
            departMoment.format('dddd, MMMM Do, YYYY')
          ) : departureDateSplit ? (
            <>
              <span>{departureDateSplit[0]}&nbsp;</span>
              <span
                style={{
                  ...(!hasAccess
                    ? { filter: 'blur(5px)', cursor: 'pointer' }
                    : {}),
                }}
                onClick={() => {
                  if (!hasAccess && onHiddenDetailClick) {
                    onHiddenDetailClick();
                  }
                }}
              >
                {departureDateSplit[1]}&nbsp;
              </span>
              <span>{departureDateSplit[2]}</span>
            </>
          ) : (
            ''
          )}
        </IonRow>
      )}
      <div style={{ width: '100%' }}>
        <IonRow
          style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}
        >
          <IonCol
            size="3"
            sizeLg="2"
            style={{
              height: `${segmentDetailsHeight}px`,
              display: 'flex',
              paddingRight: '3px',
              paddingLeft: 0,
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  border: '4px solid var(--ion-color-primary)',
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                }}
              ></div>
              <div
                style={{
                  backgroundColor: 'var(--ion-color-primary)',
                  width: '5px',
                  flexGrow: 1,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: 'white',
                  border: '4px solid var(--ion-color-primary)',
                  width: '20px',
                  height: '20px',
                  borderRadius: '10px',
                }}
              ></div>
            </div>
          </IonCol>
          <IonCol
            ref={segmentDetailsRef}
            size="9"
            sizeLg="10"
            style={{ height: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                flexDirection: 'column',
                paddingTop: '3px',
                whiteSpace: 'nowrap',
              }}
            >
              <div style={{ display: 'flex', paddingBottom: '1em' }}>
                {(!hasAccess || (hasAccess && !!departMoment)) && (
                  <div
                    style={{
                      color: 'var(--ion-color-primary)',
                      ...(!hasAccess
                        ? {
                            filter: 'blur(5px)',
                            cursor: 'pointer',
                          }
                        : {}),
                    }}
                    onClick={() => {
                      if (!hasAccess && onHiddenDetailClick) {
                        onHiddenDetailClick();
                      }
                    }}
                  >
                    {departMoment
                      ? departMoment.format('h:mm a')
                      : departureDateMoment?.format('h:mm a')}
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {departureAirport?.city}
                </div>
                <div
                  style={{ paddingLeft: '5px', color: 'var(--ion-color-gray)' }}
                >
                  {departureAirport?.iata}
                </div>
              </div>
              <div
                style={{
                  fontFamily: 'nexa',
                  paddingLeft: '20px',
                  color: 'var(--ion-color-primary)',
                  paddingBottom: '1.15em',
                }}
              >{`${
                (segment?.duration?.hours ?? 0) > 0
                  ? `${segment?.duration?.hours} hr${
                      (segment?.duration?.hours ?? 0) > 1 ? 's' : ''
                    }`
                  : ''
              }${
                segment?.duration?.minutes
                  ? ` ${segment?.duration?.minutes} min`
                  : ''
              } in the air`}</div>
              <div style={{ display: 'flex' }}>
                {(!hasAccess || (hasAccess && !!arrivalMoment)) && (
                  <div
                    style={{
                      color: 'var(--ion-color-primary)',
                      ...(!hasAccess
                        ? {
                            filter: 'blur(5px)',
                            cursor: 'pointer',
                          }
                        : {}),
                    }}
                    onClick={() => {
                      if (!hasAccess && onHiddenDetailClick) {
                        onHiddenDetailClick();
                      }
                    }}
                  >
                    {/* If there is no arrivalMoment, this text will be blurred, so it doesn't matter what date we show */}
                    {arrivalMoment
                      ? arrivalMoment.format('h:mm a')
                      : departureDateMoment?.format('h:mm a')}
                  </div>
                )}
                <div style={{ paddingLeft: '5px' }}>{arrivalAirport?.city}</div>
                <div
                  style={{ paddingLeft: '5px', color: 'var(--ion-color-gray)' }}
                >
                  {arrivalAirport?.iata}
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow style={{ width: '100%', height: '40px' }}>
          {!!segment?.layoverDuration && (
            <>
              <IonCol size="3" sizeLg="2" style={{ paddingLeft: 0 }} />
              <IonCol size="9" sizeLg="10">
                <div
                  style={{
                    fontFamily: 'nexa',
                    paddingLeft: '20px',
                    color: 'var(--ion-color-primary)',
                    paddingBottom: '1.15em',
                  }}
                >{`${
                  (segment?.layoverDuration?.hours ?? 0) > 0
                    ? `${segment.layoverDuration.hours} hr${
                        (segment?.layoverDuration?.hours ?? 0) > 1 ? 's' : ''
                      }`
                    : ''
                }${
                  segment?.layoverDuration?.minutes
                    ? ` ${segment?.layoverDuration?.minutes} min`
                    : ''
                } ${isOvernightLayover ? 'overnight ' : ''}layover`}</div>
              </IonCol>
            </>
          )}
        </IonRow>
      </div>
    </IonRow>
  );
};

export default DealDetailsSegmentDiagram;
