import { IonCol, IonRow } from '@ionic/react';
import './../theme/util.css';

const StoreBadges = () => {
  return (
    <>
      <IonRow className="row-vertical-align" style={{ textAlign: 'center' }}>
        <IonCol sizeXs="10" sizeMd="6">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <a
              href={process.env.REACT_APP_IOS_URL}
              target="_blank"
              rel="noreferrer"
              style={{
                width: '80%',
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1636156800&h=7e3303962dcf0c50153cab8d824e53e1"
                alt="Download on the App Store"
                style={{ width: '100%' }}
              />
            </a>
          </div>
        </IonCol>
        <IonCol sizeXs="10" sizeMd="6">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <a
              href={process.env.REACT_APP_ANDROID_URL}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={{ width: '100%' }}
              />
            </a>
          </div>
        </IonCol>
      </IonRow>
      <IonRow
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <p className="attribution-text">
          Google Play and the Google Play logo are trademarks of Google LLC.
        </p>
        <p className="attribution-text">
          App Store® is a registered trademark of Apple Inc.
        </p>
      </IonRow>
    </>
  );
};

export default StoreBadges;
