import { createContext, useLayoutEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { ScriptLoader } from '@faredrop/utilities';

import usePresentToast from '../hooks/presentToast';

export interface MapBoxContextValue {
  isMapBoxReady: boolean;
}

// Since we set the MapBoxProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const MapBoxContext = createContext<MapBoxContextValue>({
  isMapBoxReady: false,
});

interface MapboxPropsProps {
  children: ReactNode;
}

export const MapBoxProvider: FC<MapboxPropsProps> = (props) => {
  const [mapInitialized, setMapInitialized] = useState(false);
  const { presentError } = usePresentToast();

  // https://docs.mapbox.com/mapbox-gl-js/guides/migrate-to-v2/#excluding-mapbox-gl-js-explicitly-from-transpilation
  // https://github.com/mapbox/mapbox-gl-js/issues/10240
  // https://github.com/alex3165/react-mapbox-gl/issues/931
  // Everyone seems to be able to get it to work in Webpack 4 and Ionic-less React, but so far we've had no luck here...
  //
  // You can use these commands for testing the minified code locally:
  /*
  cd packages/frontend
  rm -rf node_modules/.cache # Get rid of babel cache
  yarn package:development # build a development build pointed at our hosted endpoints (assuming you haven't updated .env.development)
  cd build
  npx http-serve -p 3000 -f /
  Open a browser at http://localhost:3000.  DON'T GO 127.0.0.1 OR YOU WILL HAVE CORS ERRORS!
  */

  // Here we are sourcing mapbox-gl-js
  // We are unable to pull this in as a module because we get an error
  // on runtime as a result of minifying and deploying the code whereas
  // pulling in this module via CDN on runtime circumvents this problem
  useLayoutEffect(() => {
    const mapBoxLoader = new ScriptLoader({
      src: 'api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.js', // cspell:disable-line
      protocol: 'https:',
      global: 'mapboxgl',
    });

    mapBoxLoader
      .load()
      .catch(() => {
        presentError('Failed to load map. Please refresh the page.');
      })
      .finally(() => {
        setMapInitialized(true);
      });
  }, []);

  return (
    <MapBoxContext.Provider
      value={{
        isMapBoxReady: mapInitialized,
      }}
    >
      {props.children}
    </MapBoxContext.Provider>
  );
};

export default MapBoxContext;
