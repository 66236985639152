import _ from 'lodash';
import moment from 'moment';
import { $enum } from 'ts-enum-util';

import { DealDetailsProtoBuf } from '@faredrop/deal-details-url-protobuf';
import { FirebasePushID } from '@faredrop/firebase-push-id';
import {
  Airport,
  Deal,
  DealStatus,
  NotificationFilters,
  SeatClass,
  Regions,
  VerifiedAirfare,
} from '@faredrop/graphql-sdk';
import {
  AirfareProvider,
  AirportRegion,
  Country,
  FlightDesignation,
  UserOriginAttributes,
  VerifiedAirfareAttributes,
} from '@faredrop/types';
import {
  clientDealIsBudgetAirline,
  clientDealIsLieFlat,
  clientDealIsNonStop,
  getFlightDesignationFromProvider,
  isClientVerifiedAirfareAttributeEnabled,
  isClientVerifiedAirfareNonStop,
} from '@faredrop/utilities';

import { LandingDeal } from '../types/types';
import { BadgeSize, IFlightBadge } from '../components/FlightBadges';
import { IFrontendUser } from '../hooks/user';
import { IDestinationDeals } from '../components/DealCard';

export const isMatch = (deal0: Deal, deal1: Deal) => {
  return (
    deal0.idAirfareSource === deal1.idAirfareSource &&
    deal0.originIATA === deal1.originIATA &&
    deal0.destinationIATA === deal1.destinationIATA
  );
};

export const formatAirportCity = (
  airport?: Airport,
  includeCountry?: boolean
) => {
  let formatted = '';
  if (airport?.city) {
    formatted = airport.city;
    if (includeCountry) {
      formatted += `, ${airport.country}`;
    }
    if (!airport.subAirports) {
      formatted += ` (${airport?.iata.toUpperCase()})`;
    }
  }
  return formatted;
};

export const formatAirportLocation = (
  airport?: Pick<Airport, 'city' | 'country' | 'state'> &
    Partial<Pick<Airport, 'region'>>,
  locked?: boolean
) => {
  if (locked) {
    if (airport?.country) {
      switch (airport.country) {
        case 'United States of America':
        case Country.us: {
          return Country.us;
        }
        case Country.ca: {
          return Country.ca;
        }
        case Country.mx: {
          return Country.mx;
        }
      }
    }

    if (airport?.region) {
      return _.startCase(
        $enum(AirportRegion).getKeyOrDefault(airport.region, '')
      );
    }
  }

  return airport && airport.state
    ? `${airport.city}, ${airport.state}`
    : airport && airport.country
    ? `${airport.city}, ${airport.country}`
    : airport?.city;
};

export const formatAirportName = (airport?: Airport) => {
  let formatted = formatAirportCity(airport);
  if (airport?.name && !airport?.subAirports) {
    formatted = `${airport.name} (${airport.iata})`;
  }
  return formatted;
};

export const sortBySeatClass = (a: LandingDeal, _: LandingDeal) => {
  if (a.seatClass.toUpperCase() === SeatClass.Economy) {
    return -1;
  }
  return 1;
};

export enum BadgeLabel {
  Create = 'Create Account to Unlock',
  Upgrade = 'Upgrade to Unlock',
  Business = 'Business',
  Notified = 'Notified',
  LieFlat = 'Lie-Flat',
  NonStop = 'Non-Stop',
  Budget = 'Budget Airline',
}

export const buildDealBadges = (
  deal?: Pick<
    Deal | LandingDeal,
    'destinationIATA' | 'seatClass' | 'attributes'
  >,
  user?: IFrontendUser,
  color = 'white',
  size: BadgeSize = 'large',
  exclude: string[] = [],
  badgeLabel?: BadgeLabel
) => {
  const badges: IFlightBadge[] = [];

  if (badgeLabel) {
    badges.push({
      label: badgeLabel,
      iconStyle: {
        color,
        fontSize: size === 'small' ? '18px' : '22px',
      },
      backgroundColor: 'var(--ion-color-primary)',
    });
  } else {
    if (deal) {
      if (
        !exclude.includes(BadgeLabel.Notified) &&
        deal.destinationIATA && // This is missing if deal is business and user is not subscribed to business plan
        (deal as Deal).status && // LandingDeals shouldn't have notified icon
        dealMatchesPreferences(deal as Deal, user) &&
        (deal as Deal).status === DealStatus.Sent
      ) {
        badges.push({
          label: BadgeLabel.Notified,
          iconStyle: {
            color,
            fontSize: size === 'small' ? '18px' : '22px',
          },
          backgroundColor: '#348ceb',
        });
      }

      if (
        !exclude.includes(BadgeLabel.Business) &&
        deal.seatClass.toUpperCase() === SeatClass.Business
      ) {
        badges.push({
          label: BadgeLabel.Business,
          iconStyle: {
            color: color,
            fontSize: size === 'small' ? '18px' : '19px',
          },
          textColor: color,
        });
      }

      if (
        !exclude.includes(BadgeLabel.LieFlat) &&
        clientDealIsLieFlat(deal?.attributes ?? [])
      ) {
        badges.push({
          label: BadgeLabel.LieFlat,
          iconStyle: {
            color: color,
            fontSize: size === 'small' ? '20px' : '24px',
          },
          backgroundColor: '#EFB656',
        });
      }

      if (
        !exclude.includes(BadgeLabel.NonStop) &&
        clientDealIsNonStop(deal?.attributes ?? [])
      ) {
        badges.push({
          label: BadgeLabel.NonStop,
          iconStyle: {
            color: color,
            width: size === 'small' ? '16px' : '20px',
          },
          backgroundColor: '#6EB3C1',
        });
      }
      if (
        !exclude.includes(BadgeLabel.Budget) &&
        clientDealIsBudgetAirline(deal?.attributes ?? [])
      ) {
        badges.push({
          label: BadgeLabel.Budget,
          iconStyle: {
            color: color,
            fontSize: size === 'small' ? '15px' : '16px',
          },
          backgroundColor: '#E77722',
        });
      }
    }
  }
  return badges;
};

export const buildVerifiedAirfareBadges = (
  verifiedAirfare?: Pick<VerifiedAirfare, 'attributes'>,
  color = 'white',
  size: BadgeSize = 'large',
  exclude: string[] = [],
  badgeLabel?: BadgeLabel
) => {
  const badges: IFlightBadge[] = [];
  if (badgeLabel) {
    badges.push({
      label: badgeLabel,
      iconStyle: {
        color,
        fontSize: size === 'small' ? '18px' : '22px',
      },
      backgroundColor: 'var(--ion-color-primary)',
    });
  } else if (verifiedAirfare) {
    if (
      !exclude.includes(BadgeLabel.LieFlat) &&
      isClientVerifiedAirfareAttributeEnabled(
        VerifiedAirfareAttributes.LIE_FLAT_SEAT,
        verifiedAirfare?.attributes ?? []
      )
    ) {
      badges.push({
        label: BadgeLabel.LieFlat,
        iconStyle: {
          color: color,
          fontSize: size === 'small' ? '20px' : '24px',
        },
        backgroundColor: '#EFB656',
      });
    }

    if (
      !exclude.includes(BadgeLabel.NonStop) &&
      isClientVerifiedAirfareNonStop(verifiedAirfare?.attributes ?? [])
    ) {
      badges.push({
        label: BadgeLabel.NonStop,
        iconStyle: {
          color: color,
          width: size === 'small' ? '16px' : '20px',
        },
        backgroundColor: '#6EB3C1',
      });
    }
    if (
      !exclude.includes(BadgeLabel.Budget) &&
      isClientVerifiedAirfareAttributeEnabled(
        VerifiedAirfareAttributes.BUDGET_AIRLINE,
        verifiedAirfare?.attributes ?? []
      )
    ) {
      badges.push({
        label: BadgeLabel.Budget,
        iconStyle: {
          color: color,
          fontSize: size === 'small' ? '12px' : '16px',
        },
        backgroundColor: '#E77722',
      });
    }
  }

  return badges;
};

export const dealMatchesPreferences = (deal: Deal, user?: IFrontendUser) => {
  let filterDeal = false;

  const notificationFilters = user?.configuration.notificationFilters;
  const travelMonths = user?.configuration.travelMonths;
  const destinationRegions = user?.configuration.destinationRegions;

  if (notificationFilters?.length) {
    notificationFilters.every((filter) => {
      if (filter.enabled) {
        const dealAttributes = deal.attributes ?? [];
        switch (filter.name) {
          case NotificationFilters.Economy: {
            if (deal.seatClass.toUpperCase() === SeatClass.Economy)
              filterDeal = true;
            break;
          }
          case NotificationFilters.Business: {
            if (deal.seatClass.toUpperCase() === SeatClass.Business)
              filterDeal = true;
            break;
          }
          case NotificationFilters.OneStopOrMore: {
            if (!clientDealIsNonStop(dealAttributes)) filterDeal = true;
            break;
          }
          case NotificationFilters.BudgetAirlines: {
            if (clientDealIsBudgetAirline(dealAttributes)) filterDeal = true;
            break;
          }
          case NotificationFilters.NonLieFlatBusinessClassSeats: {
            if (
              deal.seatClass.toUpperCase() === SeatClass.Business &&
              !clientDealIsLieFlat(dealAttributes)
            )
              filterDeal = true;
            break;
          }
        }

        // No need to continue checking if deal should be filtered
        if (filterDeal) {
          return false;
        }
      }

      return true;
    });

    if (filterDeal) {
      return false;
    }
  }

  // Filter out deals that do not match user travel months
  if (
    !travelMonths?.length ||
    (deal.airfares &&
      !deal?.airfares?.some(
        (airfare) =>
          !!travelMonths.find(
            (m) =>
              m.enabled &&
              m.month === moment(airfare.departureDate).format('MMMM')
          )
      ))
  ) {
    return false;
  }

  const flightDesignation = getFlightDesignationFromProvider(
    $enum(AirfareProvider).asValueOrThrow(deal.provider)
  );

  // Filter out deals that do match destination destination settings or are too expensive
  const destinationRegionConfig = destinationRegions?.find((config) => {
    return (
      config.enabled &&
      ((flightDesignation === FlightDesignation.DOMESTIC &&
        config.region === Regions.Domestic) ||
        deal.destinationRegion ===
          $enum(AirportRegion).getValueOrThrow(config.region))
    );
  });

  if (!destinationRegionConfig) {
    return false;
  } else {
    let maxPrice: number | undefined | null = undefined;
    if (deal.seatClass.toUpperCase() === SeatClass.Economy) {
      maxPrice = destinationRegionConfig?.maxPriceEconomy;
    } else {
      maxPrice = destinationRegionConfig?.maxPriceBusiness;
    }

    if (maxPrice && deal.minPrice > maxPrice) {
      return false;
    }

    // Filter out deals based on origin config
    const originConfig = user?.configuration.origins.find(
      (o) => o.iata === deal.originIATA
    );
    if (originConfig) {
      if (flightDesignation === FlightDesignation.DOMESTIC) {
        if (
          (originConfig.attributes & UserOriginAttributes.DOMESTIC) !==
          UserOriginAttributes.DOMESTIC
        ) {
          return false;
        }
      } else {
        if (
          (originConfig.attributes & UserOriginAttributes.INTERNATIONAL) !==
          UserOriginAttributes.INTERNATIONAL
        ) {
          return false;
        }
      }
    }
  }

  return true;
};

// Server sends deals in the appropriate sort order, but it's possible that the frontend wants to change the order of origins for a specific destination,
// so we need to track the changes and apply it to our SWR data
export const ensureDestinationsStackOrder = (
  deals: IDestinationDeals[],
  activeOrigins?: { [key: string]: string }, // Each destination may have a different active origin
  activeOrigin?: string // Make sure each destination has the same origin
) => {
  if (!activeOrigins && !activeOrigin) {
    throw new Error(
      'activeOrigin(s) is required to ensureDestinationStackOrder!'
    );
  }

  return [...deals].map((destinationDeals: IDestinationDeals) => {
    let activeIndex = -1;
    let activeDeal = destinationDeals.deals[0];
    for (let i = 0; i < destinationDeals.deals.length; i++) {
      const deal = destinationDeals.deals[i];
      const destinationIATA = deal.destinationIATA;

      if (destinationIATA) {
        if (activeOrigin) {
          if (deal.originIATA === activeOrigin) {
            activeIndex = i;
            activeDeal = deal;
            break;
          }
        } else if (activeOrigins || i === destinationDeals.deals.length - 1) {
          if (!activeOrigins) activeOrigins = {};

          if (!activeOrigins[destinationIATA]) {
            activeOrigins[destinationIATA] = deal.originIATA;
            activeIndex = i;
            activeDeal = deal;
            break;
          } else if (activeOrigins[destinationIATA] === deal.originIATA) {
            activeIndex = i;
            activeDeal = deal;
            break;
          }
        }
      }
    }

    // Make sure active index is at front
    if (activeIndex > 0) {
      const active = destinationDeals.deals.splice(activeIndex, 1);
      destinationDeals.deals.unshift(active[0]);
    }

    return {
      activeDeal: activeDeal,
      deals: destinationDeals.deals,
      keyDeal: destinationDeals.keyDeal,
    };
  });
};

export const convertProtoBufSeatClassToSeatClass = (
  seatClass: DealDetailsProtoBuf.SeatClass
) => {
  switch (seatClass) {
    case DealDetailsProtoBuf.SeatClass.Economy: {
      return SeatClass.Economy;
    }
    case DealDetailsProtoBuf.SeatClass.Business: {
      return SeatClass.Business;
    }
    case DealDetailsProtoBuf.SeatClass.First: {
      return SeatClass.First;
    }
    case DealDetailsProtoBuf.SeatClass.Premium_Economy: {
      return SeatClass.PremiumEconomy;
    }
    default: {
      throw new Error('Invalid seat class!');
    }
  }
};

export const convertProtoBufDealStatusToDealStatus = (
  dealStatus: DealDetailsProtoBuf.DealStatus
) => {
  switch (dealStatus) {
    case DealDetailsProtoBuf.DealStatus.Auto: {
      return DealStatus.Auto;
    }
    case DealDetailsProtoBuf.DealStatus.Sent: {
      return DealStatus.Sent;
    }
    case DealDetailsProtoBuf.DealStatus.Sending: {
      return DealStatus.Sending;
    }
    case DealDetailsProtoBuf.DealStatus.Failed: {
      return DealStatus.Failed;
    }
    case DealDetailsProtoBuf.DealStatus.Bucket: {
      return DealStatus.Bucket;
    }
    default: {
      throw new Error('Invalid deal status!');
    }
  }
};

// A function that decodes a base64 url encoded string to a Uint8Array and then decodes the bytes to a DealDetailsQueryParam object
export const decodeDealQueryParam = (dealQueryParam: string) => {
  let base64 = dealQueryParam.replace(/-/g, '+').replace(/_/g, '/');
  const padding = base64.length % 4;
  if (padding) {
    base64 += '='.repeat(4 - padding);
  }
  const bytes = new Uint8Array(
    Array.from(window.atob(base64), (c) => c.charCodeAt(0))
  );

  return DealDetailsProtoBuf.DealDetailsQueryParam.decode(bytes);
};

export const isDealExpired = (deal?: Deal) => {
  if (!deal) return false;

  const dealMoment = deal
    ? FirebasePushID.getMoment(deal?.idAirfareSource)
    : undefined;
  const expireDays = deal?.seatClass === SeatClass.Business ? 7 : 2;
  return dealMoment
    ? dealMoment.isBefore(moment().subtract(expireDays, 'days').startOf('day'))
    : false;
};
