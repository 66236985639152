import { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';

import { applyPromotion, getAssetsBaseUrl } from '@faredrop/utilities';

import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/util.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import {
  buildFrontendSubscriptionPlans,
  calculateDiscountPrice,
  convertSubscriptionPlanTypeToGQL,
} from '../utilities/plans-utilities';
import HidingHeader from '../components/HidingHeader';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useHidingHeader } from '../hooks/useHidingHeader';
import SocialProofSection from '../components/SocialProofSection';
import Footer from '../components/Footer';
import useAuth from '../hooks/auth';
import { SubscriptionPlanCard } from '../components/SubscriptionPlanCard';
import usePresentToast from '../hooks/presentToast';
import useStripeHook from '../hooks/useStripe';
import AsSeenIn from '../components/AsSeenIn';
import { SubscriptionPlan } from '@faredrop/types';

interface ContainerProps {
  onMenuClickHandler: () => void;
  title: string;
}

const UnauthenticatedPromotionUpgrade: React.FC<ContainerProps> = ({
  onMenuClickHandler,
  title,
}) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const userState = useUser();
  const noInternet = userState.timeout;
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const plans = buildFrontendSubscriptionPlans();
  const dynamicBannerContent = useDynamicBanner();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { user } = useUser();
  const { isInitialized, isAuthenticated } = useAuth();
  const { presentError } = usePresentToast();
  const { createUpgradeCheckoutSessionPublic } = useStripeHook();

  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState(false);

  // Redirect to home page if there isn't an email query param and the user is not logged in
  useEffect(() => {
    if (isInitialized) {
      setLoading(false);

      const queryParams = new URLSearchParams(location.search);

      let email: string | undefined = undefined;
      if (isAuthenticated) {
        if (user?.profile.email) {
          email = user.profile.email;
        }
      }

      if (!email) {
        email = queryParams.get('email') ?? undefined;
      }

      if (email) {
        setEmail(email);
      } else {
        goWithStickyParamsPath('/');
      }
    }
  }, [isInitialized]);

  const plan = plans.filter((p) => p.planType === FareDropPlan.Pro)[0];

  return noInternet ? (
    <NoInternetConnectionModal />
  ) : (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <IonRow style={{ marginBottom: '2em', paddingTop: '5em' }}>
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <h1
              className="title-font mt-1"
              style={{
                marginLeft: '1em',
                marginRight: '1em',
              }}
            >
              {title}
            </h1>
            <h2
              className="title-font"
              style={{
                marginLeft: '1em',
                marginRight: '1em',
                fontSize: isLargeScreenSizeOrSmaller ? '24px' : undefined,
              }}
            >
              Save 50% on Pro + get a{' '}
              <a
                href={`${process.env.REACT_APP_DAILY_DROP_SHOP_DOMAIN}/products/travel-day-bundle`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                FREE Travel Day Bundle
              </a>
            </h2>
          </IonCol>
        </IonRow>
        <IonRow
          id="register"
          className="redeem-with-card-section redeem-with-card-section-responsive"
          style={{
            paddingTop: isLargeScreenSizeOrSmaller ? 0 : '2em',
            marginBottom: '2em',
          }}
        >
          <IonCol
            size="12"
            sizeXl="6"
            className="offer-container"
            style={{ flexDirection: 'column', marginBottom: '2em' }}
          >
            <IonRow className="row-vertical-align" style={{ width: '100%' }}>
              <SubscriptionPlanCard
                key={plan.id}
                plan={convertSubscriptionPlanTypeToGQL(
                  applyPromotion(plan as SubscriptionPlan)
                )}
                billingText="For the first year"
                billingSubText="Renews at full-price"
                discountPrice={calculateDiscountPrice(plan, 0.5)}
                big={true}
                border="none"
                hideButton={true}
                titleSize={isLargeScreenSizeOrSmaller ? '34px' : undefined}
                titleMarginTop={isLargeScreenSizeOrSmaller ? '5px' : undefined}
              />
            </IonRow>
            <IonButton
              className="get-started-button mb-1-5"
              onClick={async () => {
                if (!email) {
                  presentError(
                    'No email provided - please refresh and try again'
                  );
                } else {
                  setLoading(true);
                  await createUpgradeCheckoutSessionPublic(
                    email,
                    plan.planType,
                    'deals',
                    undefined,
                    'upgrade',
                    [{ key: 'email', value: email }]
                  );
                }
              }}
            >
              {(loading && <IonSpinner color="white" name="crescent" />) || (
                <p style={{ color: 'white' }}>Upgrade</p>
              )}
            </IonButton>
          </IonCol>
          <IonCol
            size="12"
            sizeXl="6"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <IonRow style={{ width: '100%', marginBottom: '1em' }}>
              <div
                style={{
                  width: '100%',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <img
                  src={`${getAssetsBaseUrl()}/kara-and-nate/summer24/grass2.jpg`}
                  style={{
                    borderRadius: '5px',
                    maxHeight: '1000px',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                ></img>
              </div>
            </IonRow>
          </IonCol>
        </IonRow>
        <SocialProofSection />
        <AsSeenIn style={{ marginBottom: '0' }} />
        <Footer bgColor="secondary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default UnauthenticatedPromotionUpgrade;
