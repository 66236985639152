import { IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

import './../theme/util.css';

interface ContainerProps {
  label?: string;
  style?: React.CSSProperties;
  onClick: () => Promise<void>;
}

const BackButton: React.FC<ContainerProps | undefined> = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '1em',
        top: '2em',
        ...props.style,
      }}
    >
      <a onClick={props.onClick}>
        <button
          className="transparent-button md-button green-button"
          style={{
            display: 'flex',
            fontSize: '16px',
            color: 'var(--ion-color-primary)',
            border: 0,
          }}
        >
          <IonIcon icon={arrowBackOutline} style={{ margin: '20px 10px' }} />
          <p
            style={{
              margin: '20px 5px',
              paddingTop: '4px',
              whiteSpace: 'nowrap',
            }}
          >
            {props.label ?? 'Back'}
          </p>
        </button>
      </a>
    </div>
  );
};

export default BackButton;
