import { IonRow, IonSpinner } from '@ionic/react';
import { Box, FormHelperText, TextField } from '@mui/material';
import { Formik } from 'formik';
import { FC } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import useAuth from '../hooks/auth';
import Loading from './Loading';

type PasswordRecoveryProps = {
  initEmail?: string;
  onRecoverPassword: (email: string) => void;
  onVerifyAndResetPasswordEmailSent: (email: string) => void;
};

const PasswordRecovery: FC<PasswordRecoveryProps> = (
  props: PasswordRecoveryProps
) => {
  const { publicApiSendVerifyEmailAndResetPasswordEmail } = useAuth();
  const [hideForm, setHideForm] = useState(false);
  const [successfulSend, setSuccessfulSend] = useState(false);

  return hideForm && !successfulSend ? (
    <Loading />
  ) : hideForm && successfulSend ? (
    <></>
  ) : (
    <Formik
      initialValues={{
        email: props.initEmail ? decodeURIComponent(props.initEmail) : '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        const email: string = values.email;

        await publicApiSendVerifyEmailAndResetPasswordEmail(email);

        setHideForm(true);

        props.onVerifyAndResetPasswordEmailSent(email);
        setSuccessfulSend(true);
        setStatus({ success: true });
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            placeholder="user@gmail.com"
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <IonRow>
            <button className="button no-caps" type="submit">
              {(isSubmitting && (
                <IonSpinner color="white" name="crescent" />
              )) || <p>Send password reset email</p>}
            </button>
          </IonRow>
        </form>
      )}
    </Formik>
  );
};

export default PasswordRecovery;
