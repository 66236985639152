// cSpell:ignore Iatas geodesically
import { IonRow, IonSpinner } from '@ionic/react';
import { lineChunk, lineString } from '@turf/turf';
import { CircleLayer, LineLayer, SymbolLayer } from 'mapbox-gl';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  Airport,
  FlightSegment,
  UserConfigOrigin,
} from '@faredrop/graphql-sdk';

import AirportNotFoundError from '../errors/airportNotFound';
import { useDevice } from '../hooks/useDevice';
import useMapBox from '../hooks/useMapBox';
import useUser from '../hooks/user';
import useAirports from '../hooks/airports';
import usePresentToast from '../hooks/presentToast';

interface ContainerProps {
  lineBetweenOrigins?: boolean;
  segments?: FlightSegment[];
  selectedSegment?: FlightSegment;
  padding?: number;
  searchbarRef?: React.RefObject<HTMLIonSearchbarElement>;
  sx?: React.CSSProperties;
  viewLoaded: boolean;
  onLineSelect?: (segment: FlightSegment) => void;
}

const MapboxMap: React.FC<ContainerProps> = (props) => {
  const { lineBetweenOrigins, searchbarRef, sx, viewLoaded } = props;
  const { isApp } = useDevice();
  const {
    origins,
    getAirportOrSubAirport,
    getAirportOrSubAirportWithBackendFallback,
    airports,
  } = useAirports();
  const { isMapBoxReady } = useMapBox();
  const userState = useUser();
  const { presentError } = usePresentToast();

  const mapboxMap = useRef<mapboxgl.Map | undefined>();
  const [mapboxMapIsInitializing, setMapboxMapIsInitializing] = useState(false);
  const [mapBoxFailed, setMapboxMapFailed] = useState(false);
  const [allAirports, setAllAirports] = useState<Airport[]>();

  const userConfigOrigins = userState.user?.configuration.origins ?? [];

  useEffect(() => {
    const buildAirports = async () => {
      if (props.segments) {
        const airports: Airport[] = [];
        for (let i = 0; i < props.segments.length; i++) {
          const segment = props.segments[i];
          if (i === 0) {
            const origin = await getAirportOrSubAirportWithBackendFallback(
              segment.originIATA
            );
            if (origin) {
              airports.push(origin);
            }
          }

          const destination = await getAirportOrSubAirportWithBackendFallback(
            segment.destinationIATA
          );
          if (destination) {
            airports.push(destination);
          }
        }

        setAllAirports(airports);
      }
    };

    // Wait for airports cache to load
    if (airports) {
      buildAirports().catch(() => {
        presentError(
          'Uh oh - failed to load airports. Please refresh the page.'
        );
      });
    }
  }, [props.segments, airports]);

  const getSegmentAirport = (airportIATA: string) => {
    return allAirports?.find((a) => a.iata === airportIATA);
  };

  // If there are no user origins, set bounding airports to California, Washington, Maine and Florida
  const defaultBoundingAirports =
    origins?.filter(
      (airport) =>
        airport.iata === 'SAN' ||
        airport.iata === 'SEA' ||
        airport.iata === 'PWM' ||
        airport.iata === 'MIA'
    ) ?? [];

  const pointStyleOptionsFromOrigin = (iata: string) => {
    const airport = getAirportOrSubAirport(iata);

    if (!airport) {
      throw new AirportNotFoundError();
    }

    return pointStyleOptions(airport);
  };

  const pointStyleOptions = (
    airport: Airport,
    settings?: { color?: string; strokeColor?: string; width?: number }
  ) => {
    return {
      id: `symbol-${airport.iata}`,
      type: `circle`,
      source: {
        type: `geojson`,
        data: {
          type: `Feature`,
          geometry: {
            type: `Point`,
            coordinates: [airport.lng, airport.lat] ?? [],
          },
          properties: {},
        },
      },
      paint: {
        'circle-color': settings?.color ?? '#fe6767',
        'circle-stroke-color': settings?.strokeColor ?? '#ffffff',
        'circle-stroke-width': settings?.width ?? 2,
      },
    } as CircleLayer;
  };

  const pointShadowStyleOptionsFromOrigin = (iata: string) => {
    const airport = getAirportOrSubAirport(iata);

    if (!airport) {
      throw new AirportNotFoundError();
    }

    return pointShadowStyleOptions(airport);
  };

  const pointShadowStyleOptions = (airport: Airport) => {
    return {
      id: `symbol-${airport.iata}-shadow`,
      type: `circle`,
      source: {
        type: `geojson`,
        data: {
          type: `Feature`,
          geometry: {
            type: `Point`,
            coordinates: [airport.lng, airport.lat] ?? [],
          },
          properties: {},
        },
      },
      paint: {
        'circle-color': '#111111',
        'circle-stroke-color': '#111111',
        'circle-stroke-width': 2,
        'circle-blur': 1,
        'circle-radius': 8,
      },
    } as unknown as SymbolLayer;
  };

  const buildGeodesicCoordinates = (coordinates: number[][]) => {
    const from = [...coordinates[0]],
      chunked =
        /* Here, we use Turf.js to chunk the line between the start and end points into 15-mile segments,
          and then pass that list of points 15 miles apart to MapboxGL to draw as the final line. Using
          Turf.js lets us solve two problems at once - the geodesic problem, and the 180th meridian
          problem. (15 mile-segments were picked arbitrarily to balance having reasonably good geodesic
          approximations, while not overwhelming MapboxGL with an unnecessary amount of points for the
          detail needed for our purpose.)

          Geodesic problem: When you ask MapboxGL to draw a line, it draws it non-geodesically, meaning
          that it appears straight on the 2D map representation because MapboxGL does not make the line
          follow the curvature of the Earth. However, the Turf.js library DOES work geodesically by
          default, meaning that when you ask Turf to take the path between the start and end point and
          "chunk" it into sections like we are doing here, the points dividing the "chunks" are placed
          geodesically between the start and end point. Thus, we can give that "chunked" line to MapboxGL,
          and it will then appear geodesically on the map.

          There is also a MapboxGL geodesic plugin that could do the same thing I'm doing here with
          Turf.js, but using Turf.js allows us to also solve the 180th meridian problem at the same time:

          180th meridian problem: The 180th meridian is the vertical line approximately following the
          International Date Line, and it is where longitude "wraps around" from -180° to 180°. MapboxGL
          does not draw lines across the 180th meridian unless you explicitly add or subtract 360° from
          one of your longitude values
          (https://docs.mapbox.com/mapbox-gl-js/example/line-across-180th-meridian).
          In order to solve this problem, we would need to calculate, for each flight route, whether the
          default route drawn by MapboxGL (avoiding the 180th meridian) is the shortest route, or if it
          would be shorter to go "the other way around the world", crossing the 180th meridian. If it
          would be shorter to go "the other way around the world", we would then have to adjust one of
          our longitudes up or down by 360°.
          However, luckily, Turf.js' lineChunk method also handles this for us as well, and the points
          dividing the "chunks" already have their longitude adjusted if necessary so that MapboxGL will
          always draw the line with the shortest distance, whether it crosses the 180th meridian or
          not. */
        lineChunk(lineString(coordinates), 15, { units: `miles` }).features.map(
          ({ geometry }) => geometry.coordinates[1]
        ),
      [startLng] = from,
      [[endLng]] = chunked;
    if (endLng - startLng >= 180) from[0] += 360;
    if (endLng - startLng < -180) from[0] -= 360;
    const path = [from, ...chunked];
    return path;
  };

  const lineStyleOptions = (
    airports: Airport[],
    settings: { id: string; color?: string; width?: number }
  ) => {
    const lineLayer: LineLayer = {
      id: settings.id,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: buildGeodesicCoordinates([
              ...airports.map((airport) => [
                airport.lng ?? 0,
                airport.lat ?? 0,
              ]),
            ]),
          },
        },
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': settings?.color ?? '#fff',
        'line-width': settings?.width ?? 4,
      },
    };
    return lineLayer;
  };

  const calculateMapBoundsFromPointsFromOrigins = (
    selectedOrigins: UserConfigOrigin[]
  ) => {
    const selectedAirports = selectedOrigins.map((_origin) => {
      const airport: Airport | undefined = getAirportOrSubAirport(_origin.iata);

      if (!airport) {
        throw new Error('Origin not found!');
      }

      return airport;
    });

    return calculateMapBoundsFromPoints(selectedAirports);
  };

  const calculateMapBoundsFromPoints = (selectedAirports: Airport[]) => {
    //@ts-expect-error mapboxgl is sourced
    const bounds = new mapboxgl.LngLatBounds();
    if (selectedAirports.length > 1) {
      const crossesMeridian = selectedAirports.find(
        (airport, index) =>
          index < selectedAirports.length - 1 &&
          Math.abs(airport.lng - selectedAirports[index + 1].lng) > 180
      );
      for (let i = 0; i < selectedAirports.length; i++) {
        bounds.extend([
          selectedAirports[i].lng +
            (crossesMeridian && selectedAirports[i].lng < 0 ? 360 : 0),
          selectedAirports[i].lat,
        ]);
      }
    } else if (selectedAirports.length === 1) {
      bounds.extend([selectedAirports[0].lng, selectedAirports[0].lat]);
    }

    return bounds;
  };

  useLayoutEffect(() => {
    if (!process.env.REACT_APP_MAPBOX_ACCESS_TOKEN) {
      throw Error('Missing mapbox access token!');
    }

    // Clear searchbar value when the map changes if searchbar is shown
    if (
      viewLoaded &&
      ((props.segments && props.segments?.length > 0) ||
        userConfigOrigins.length < 10) &&
      searchbarRef?.current
    ) {
      searchbarRef.current.value = '';
    }

    if (isMapBoxReady && viewLoaded && !mapboxMapIsInitializing) {
      // NOTE: Unfortunately, MapBox URL config doesn't like Capacitor's origins, so we need to use the public key
      //@ts-expect-error mapboxgl is sourced
      mapboxgl.accessToken = isApp
        ? process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN
        : process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

      // On screen init
      if (!mapboxMap.current) {
        setMapboxMapIsInitializing(true);
        try {
          // Init map
          //@ts-expect-error mapboxgl is sourced
          const _map = new mapboxgl.Map({
            container: 'mapbox-map',
            style: process.env.REACT_APP_MAPBOX_STYLE,
            center: [-74.5, 40],
            zoom: 3,
            attributionControl: false,
            // i.e. The map cannot be zoomed in more than zoom level 6
            maxZoom: 6,
            minZoom: 0.5, // Prevent map duplication
          }).on('load', () => {
            // Resize map to fit screen
            _map.resize();

            let bounds;
            if (allAirports) {
              bounds = calculateMapBoundsFromPoints(allAirports ?? []);
            } else {
              bounds =
                userConfigOrigins.length > 0
                  ? calculateMapBoundsFromPointsFromOrigins(userConfigOrigins)
                  : calculateMapBoundsFromPoints(defaultBoundingAirports);
            }

            // Fit map to calculated bounds
            _map.fitBounds(bounds, { padding: props.padding ?? 50 });

            // Add line between origins
            if (lineBetweenOrigins && props.segments) {
              for (const segment of props.segments) {
                const segmentLineId = `segment-${segment.originIATA}-${segment.destinationIATA}`;

                const settings: { id: string; color?: string; width?: number } =
                  {
                    id: segmentLineId,
                  };
                if (
                  segment.originIATA === props.selectedSegment?.originIATA &&
                  segment.destinationIATA ===
                    props.selectedSegment.destinationIATA
                ) {
                  settings.color = '#2c625c';
                  settings.width = 6;
                }

                const origin = getSegmentAirport(segment.originIATA);
                const destination = getSegmentAirport(segment.destinationIATA);

                if (origin && destination) {
                  _map.addLayer(
                    lineStyleOptions([origin, destination], settings)
                  );
                  _map.on('click', segmentLineId, () => {
                    if (props.onLineSelect) {
                      props.onLineSelect(segment);
                    }
                  });
                  _map.on('mouseenter', segmentLineId, () => {
                    _map.getCanvas().style.cursor = 'pointer';
                  });
                  _map.on('mouseleave', segmentLineId, () => {
                    _map.getCanvas().style.cursor = '';
                  });
                }
              }
            }

            const addPoint = (origin: Airport, isSelected?: boolean) => {
              try {
                _map.addLayer(pointShadowStyleOptions(origin));
                _map.addLayer(
                  pointStyleOptions(origin, {
                    strokeColor: isSelected ? '#2c625c' : undefined,
                    color: isSelected != null ? '#fff' : undefined,
                    width: isSelected ? 4 : undefined,
                  })
                );
              } catch {
                /* no-op */
              }

              // Clicking a marker centers the map on the marker
              _map.on('click', `symbol-${origin.iata}`, (e) => {
                _map.flyTo({
                  center: e.lngLat,
                });
              });
              _map.on('mouseenter', `symbol-${origin.iata}`, () => {
                _map.getCanvas().style.cursor = 'pointer';
              });
              _map.on('mouseleave', `symbol-${origin.iata}`, () => {
                _map.getCanvas().style.cursor = '';
              });
            };

            // Add points to map
            if (props.segments) {
              for (let i = 0; i < props.segments.length; i++) {
                const segment = props.segments[i];
                if (i === 0) {
                  const origin = getSegmentAirport(segment.originIATA);
                  if (origin) {
                    if (
                      segment.originIATA ===
                        props.selectedSegment?.originIATA ||
                      segment.originIATA ===
                        props.selectedSegment?.destinationIATA
                    ) {
                      addPoint(origin, true);
                    } else {
                      addPoint(
                        origin,
                        props.selectedSegment ? false : undefined
                      );
                    }
                  }
                }

                const destination = getSegmentAirport(segment.destinationIATA);
                if (destination) {
                  if (
                    segment.destinationIATA ===
                      props.selectedSegment?.originIATA ||
                    segment.destinationIATA ===
                      props.selectedSegment?.destinationIATA
                  ) {
                    addPoint(destination, true);
                  } else {
                    addPoint(
                      destination,
                      props.selectedSegment ? false : undefined
                    );
                  }
                }
              }
            } else {
              userConfigOrigins.map((origin) => {
                try {
                  _map.addLayer(pointShadowStyleOptionsFromOrigin(origin.iata));
                  _map.addLayer(pointStyleOptionsFromOrigin(origin.iata));
                } catch {
                  /* no-op */
                }

                // Clicking a marker centers the map on the marker
                _map.on('click', `symbol-${origin.iata}`, (e) => {
                  _map.flyTo({
                    center: e.lngLat,
                  });
                });
                _map.on('mouseenter', `symbol-${origin.iata}`, () => {
                  _map.getCanvas().style.cursor = 'pointer';
                });
                _map.on('mouseleave', `symbol-${origin.iata}`, () => {
                  _map.getCanvas().style.cursor = '';
                });
              });
            }
            mapboxMap.current = _map;
            setMapboxMapIsInitializing(false);
          });
        } catch (error) {
          // If webgl fails to load, don't try to render the map
          setMapboxMapFailed(true);
        }
      } else {
        let bounds;
        if (allAirports) {
          bounds = allAirports.length
            ? calculateMapBoundsFromPoints(allAirports ?? [])
            : calculateMapBoundsFromPoints(defaultBoundingAirports);
        } else {
          bounds =
            userConfigOrigins.length > 0
              ? calculateMapBoundsFromPointsFromOrigins(userConfigOrigins)
              : calculateMapBoundsFromPoints(defaultBoundingAirports);
        }

        // Fit map to calculated bounds
        mapboxMap.current.fitBounds(bounds, { padding: 50 });

        // Add points to map if not already on map
        if (props.segments) {
          // Add line between origins
          // TODO: We can be smart about this - we need to remove and add line only if origins change, not on every render...
          if (lineBetweenOrigins) {
            const segmentsToRemove = mapboxMap.current
              .getStyle()
              .layers?.filter((layer) => layer.id.includes('segment-'));

            for (const segment of segmentsToRemove ?? []) {
              mapboxMap.current.removeLayer(segment.id);
              mapboxMap.current.removeSource(segment.id);
            }

            for (const segment of props.segments) {
              const segmentId = `segment-${segment.originIATA}-${segment.destinationIATA}`;
              if (mapboxMap.current.getLayer(segmentId)) {
                mapboxMap.current.removeLayer(segmentId);
                mapboxMap.current.removeSource(segmentId);
              }

              const settings: { id: string; color?: string; width?: number } = {
                id: segmentId,
              };
              if (
                segment.originIATA === props.selectedSegment?.originIATA &&
                segment.destinationIATA ===
                  props.selectedSegment.destinationIATA
              ) {
                settings.color = '#2c625c';
                settings.width = 6;
              }

              const origin = getSegmentAirport(segment.originIATA);
              const destination = getSegmentAirport(segment.destinationIATA);
              if (origin && destination) {
                mapboxMap.current.addLayer(
                  lineStyleOptions([origin, destination], settings)
                );
                mapboxMap.current.on('click', segmentId, () => {
                  if (props.onLineSelect) {
                    props.onLineSelect(segment);
                  }
                });
                mapboxMap.current.on('mouseenter', segmentId, () => {
                  if (mapboxMap.current) {
                    mapboxMap.current.getCanvas().style.cursor = 'pointer';
                  }
                });
                mapboxMap.current.on('mouseleave', segmentId, () => {
                  if (mapboxMap.current) {
                    mapboxMap.current.getCanvas().style.cursor = '';
                  }
                });
              }
            }
          }

          const addPoint = (origin: Airport, isSelected?: boolean) => {
            const shadowId = `symbol-${origin.iata}-shadow`;
            const pointId = `symbol-${origin.iata}`;
            try {
              if (mapboxMap.current?.getLayer(shadowId)) {
                mapboxMap.current.removeLayer(shadowId);
                mapboxMap.current.removeSource(shadowId);
              }
              mapboxMap.current?.addLayer(pointShadowStyleOptions(origin));
              mapboxMap.current?.moveLayer(shadowId);

              if (mapboxMap.current?.getLayer(pointId)) {
                mapboxMap.current.removeLayer(pointId);
                mapboxMap.current.removeSource(pointId);
              }
              mapboxMap.current?.addLayer(
                pointStyleOptions(origin, {
                  strokeColor: isSelected ? '#2c625c' : undefined,
                  color: isSelected != null ? '#fff' : undefined,
                  width: isSelected ? 4 : undefined,
                })
              );
              mapboxMap.current?.moveLayer(pointId);
            } catch {
              /* no-op */
            }
          };

          for (let i = 0; i < props.segments.length; i++) {
            const segment = props.segments[i];

            if (i === 0) {
              const origin = getSegmentAirport(segment.originIATA);
              if (origin) {
                if (
                  segment.originIATA === props.selectedSegment?.originIATA ||
                  segment.originIATA === props.selectedSegment?.destinationIATA
                ) {
                  addPoint(origin, true);
                } else {
                  addPoint(origin, props.selectedSegment ? false : undefined);
                }
              }
            }

            const destination = getSegmentAirport(segment.destinationIATA);
            if (destination) {
              if (
                segment.destinationIATA === props.selectedSegment?.originIATA ||
                segment.destinationIATA ===
                  props.selectedSegment?.destinationIATA
              ) {
                addPoint(destination, true);
              } else {
                addPoint(
                  destination,
                  props.selectedSegment ? false : undefined
                );
              }
            }
          }
        } else {
          userConfigOrigins.map((origin) => {
            if (!mapboxMap.current?.getLayer(`symbol-${origin.iata}-shadow`)) {
              try {
                mapboxMap.current?.addLayer(
                  pointShadowStyleOptionsFromOrigin(origin.iata)
                );
              } catch {
                /* TODO: no-op */
              }
            }
            if (!mapboxMap.current?.getLayer(`symbol-${origin.iata}`)) {
              try {
                mapboxMap.current?.addLayer(
                  pointStyleOptionsFromOrigin(origin.iata)
                );
              } catch {
                /* TODO: no-op */
              }
            }
          });
        }

        const originIatas = (allAirports ? allAirports : userConfigOrigins).map(
          (origin) => origin.iata
        );
        const iatasToRemove = mapboxMap.current
          .getStyle()
          .layers?.filter((layer) => layer.id.includes('symbol-'))
          .map((layer) => layer.id.split('-')[1])
          .filter((iata) => !originIatas?.includes(iata));

        iatasToRemove?.map((iata) => {
          if (mapboxMap.current?.getLayer(`symbol-${iata}`)) {
            mapboxMap.current.removeLayer(`symbol-${iata}`);
            mapboxMap.current.removeSource(`symbol-${iata}`);
          }
          if (mapboxMap.current?.getLayer(`symbol-${iata}-shadow`)) {
            mapboxMap.current.removeLayer(`symbol-${iata}-shadow`);
            mapboxMap.current.removeSource(`symbol-${iata}-shadow`);
          }
        });
      }
    }
  }, [
    origins,
    userConfigOrigins,
    viewLoaded, // Parent data has loaded
    isMapBoxReady, // Javascript source has been loaded
    mapboxMapIsInitializing, // Map object instantiated and "load" event fired and completed
    props.segments,
    props.selectedSegment,
  ]);

  return (
    <>
      <IonRow
        style={{
          position: 'relative',
          width: '100%',
          display: viewLoaded ? 'block' : 'none',
        }}
      >
        {!mapboxMap && !mapBoxFailed && (
          <IonSpinner
            name="crescent"
            style={{
              margin: 'auto',
              width: '60px',
              height: '60px',
              opacity: 0.3,
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
            }}
            color="primary"
          />
        )}
        <div
          id="mapbox-map"
          style={{ visibility: !mapboxMap ? 'hidden' : 'visible', ...sx }}
        />
      </IonRow>
    </>
  );
};

export default MapboxMap;
