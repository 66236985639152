const AddedDropdownBadge: React.FC = () => {
  return (
    <div
      style={{
        margin: '0 1em 0 0',
        lineHeight: '2em',
        position: 'absolute',
        right: 0,
        backgroundColor: '#fe6767',
        padding: '0 1em',
        borderRadius: 8,
      }}
    >
      <p
        style={{
          color: 'white',
          fontWeight: 800,
          fontSize: 10,
          letterSpacing: 1,
          fontFamily: 'nexa-bold',
          margin: 0,
          lineHeight: '2.5em',
        }}
      >
        ADDED
      </p>
    </div>
  );
};

export default AddedDropdownBadge;
