import { IonRow, IonSpinner } from '@ionic/react';
import { FC, useState } from 'react';
import usePresentToast from '../hooks/presentToast';

import useUser from '../hooks/user';

interface CancelSubscriptionProps {
  onCancel: () => Promise<void>;
}

const CancelSubscription: FC<CancelSubscriptionProps> = ({ onCancel }) => {
  const userState = useUser();
  const { presentError } = usePresentToast();

  const [isLoading, setIsLoading] = useState(false);

  const autoRenew = userState?.user?.billing.autoRenew;
  const title = autoRenew
    ? 'Cancel Subscription'
    : 'Enable Automatic Subscription Renewals';

  return (
    <IonRow>
      <button
        style={{
          width: '15em',
          margin: 'auto',
          marginBottom: '6em',
          height: '5em',
          padding: autoRenew ? '1em' : undefined,
          lineHeight: autoRenew ? undefined : '19px',
          backgroundColor: 'transparent',
          borderRadius: '7px',
          border: autoRenew
            ? '2px solid var(--ion-color-gray)'
            : '2px solid var(--ion-color-primary)',
          color: autoRenew
            ? 'var(--ion-color-gray)'
            : 'var(--ion-color-primary)',
        }}
        onClick={async (e) => {
          e.preventDefault();

          if (autoRenew) {
            await onCancel();
          } else {
            setIsLoading(true);
            try {
              await userState.setAutoRenew(!autoRenew);
            } catch (error) {
              presentError(`Failed to ${title}`);
            } finally {
              setIsLoading(false);
            }
          }
        }}
      >
        {(isLoading && <IonSpinner color="gray" name="crescent" />) || (
          <p>{title}</p>
        )}
      </button>
    </IonRow>
  );
};

export default CancelSubscription;
