import { IonRow, IonCol, IonCheckbox } from '@ionic/react';
import { UserConfigTravelMonth } from '@faredrop/graphql-sdk';
import Loading from './Loading';
import useUser from '../hooks/user';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import { useDevice } from '../hooks/useDevice';

// The weird ordering allows our list to look like this (more readable than
// January and February being on the same row, March and April being on the same
// row, etc.)
//
// January: 1      July: 2
// February: 3     August: 4
// March: 5        September: 6
// April: 7        October: 8
// May: 9          November: 10
// June: 11        December: 12

const MONTHS: { [month: string]: number } = {
  January: 1,
  February: 3,
  March: 5,
  April: 7,
  May: 9,
  June: 11,
  July: 2,
  August: 4,
  September: 6,
  October: 8,
  November: 10,
  December: 12,
};

const sortMonths = (a: UserConfigTravelMonth, b: UserConfigTravelMonth) => {
  return MONTHS[a.month] - MONTHS[b.month];
};

interface MonthsSelectorProps {
  disabled?: boolean;
  onDisabledClick?: () => void;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const MonthsSelector: React.FC<MonthsSelectorProps> = ({
  disabled,
  onDisabledClick,
  logAnalyticsEngagement,
}) => {
  const userState = useUser();
  const isInitializing = userState.isInitializing;
  const { isExtraSmallScreenSize } = useDevice();

  // State cannot directly be manipulated (e.g., w/ sort), so we need to clone the array
  // TypeError: Cannot assign to readonly property
  const travelMonths = userState.user
    ? [...userState.user.configuration.travelMonths]
    : [];

  const updateMonthsListener = async (month: UserConfigTravelMonth) => {
    const months = [...travelMonths];
    months[months?.indexOf(month) ?? -1] = {
      ...month,
      enabled: !month.enabled,
    };
    const promises: Promise<void>[] = [userState.setTravelMonths(months)];
    logAnalyticsEngagement &&
      promises.push(
        logAnalyticsEngagement(AnalyticsEngagementId.TOGGLE_TRAVEL_MONTH)
      );
    await Promise.all(promises);
  };

  return (
    (isInitializing && <Loading />) || (
      <IonRow className="row-vertical-align" style={{ height: 'auto' }}>
        <IonCol sizeXs="12" sizeMd="8">
          <IonRow>
            {travelMonths.sort(sortMonths).map((m: UserConfigTravelMonth) => {
              return (
                <IonCol key={m.month} size="6" style={{ padding: '.5em 0' }}>
                  <IonRow
                    className="row-vertical-align"
                    style={{ justifyContent: 'left', flexFlow: 'nowrap' }}
                    onClick={() => {
                      if (disabled && onDisabledClick) {
                        onDisabledClick();
                      }
                    }}
                  >
                    <IonCheckbox
                      checked={m.enabled}
                      onIonChange={async () => {
                        await updateMonthsListener(m);
                      }}
                      style={{ minWidth: 20 }}
                      disabled={disabled}
                    />
                    <p
                      style={{
                        margin: isExtraSmallScreenSize ? '0 0 0 .8em' : '0 2em',
                        fontSize: '14px',
                        fontFamily: 'nexa-bold',
                      }}
                      onClick={async () => {
                        if (!disabled) {
                          await updateMonthsListener(m);
                        }
                      }}
                    >
                      {m.month}
                    </p>
                  </IonRow>
                </IonCol>
              );
            })}
          </IonRow>
        </IonCol>
      </IonRow>
    )
  );
};

export default MonthsSelector;
