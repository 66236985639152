import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import semver from 'semver';
import useFareDropPublicApiClient from '../hooks/faredropPublicApiClient';
import useAnalytics from '../hooks/analytics';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import usePresentToast from '../hooks/presentToast';
import { UNSUPPORTED_VERSION_ERROR } from '@faredrop/utilities';

// Since we set the VersionSupportProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const VersionSupportContext = createContext({});

interface VersionSupportProps {
  children: ReactNode;
}

export const VersionSupportProvider: FC<VersionSupportProps> = (props) => {
  const { client } = useFareDropPublicApiClient();
  const { logAnalyticsError } = useAnalytics();
  const { presentOptions, dismiss } = usePresentToast();
  const [unsupportedVersion, setUnsupportedVersion] = useState(false);

  if (unsupportedVersion) {
    throw new Error(UNSUPPORTED_VERSION_ERROR);
  }

  useEffect(() => {
    const checkVersionSupport = async () => {
      const promises = await Promise.all([App.getInfo(), getVersionSupport()]);
      const appVersion = promises[0].version;
      const versionSupport = promises[1];

      const updateUrl =
        Capacitor.getPlatform() === 'ios'
          ? process.env.REACT_APP_IOS_URL
          : process.env.REACT_APP_ANDROID_URL;
      if (semver.lt(appVersion, versionSupport.minimumSupportedVersion)) {
        // We aren't able to simply throw an error in the useEffect callback
        // because errors inside of callbacks don't trigger top level
        // component errors which are caught by error boundaries. So instead,
        // setting a state here will cause the component to be re-rendered
        // with the new state which will then trigger the top level component
        // error throw new Error(UNSUPPORTED_VERSION_ERROR) above
        setUnsupportedVersion(true);
      } else if (
        updateUrl &&
        semver.lt(appVersion, versionSupport.currentVersion)
      ) {
        presentOptions('New version of FareDrop available', [
          {
            title: 'Update',
            action: async () => {
              window.location.href = updateUrl;
            },
            style: {
              fontWeight: 'bold',
            },
          },
          {
            title: 'Not Now',
            action: async (key) => {
              dismiss(key);
            },
          },
        ]);
      }
    };
    if (Capacitor.isNativePlatform()) {
      checkVersionSupport().catch((error) =>
        logAnalyticsError('checkVersionSupport', error as Error).catch((err) =>
          console.warn(
            'Failed to track analytics error for checkVersionSupport',
            err
          )
        )
      );
    }
  }, []);

  const getVersionSupport = async () => {
    try {
      const versionSupportResult = await client.versionSupport();
      return versionSupportResult.data.versionSupport;
    } catch (error) {
      await logAnalyticsError('getVersionSupport', error as Error);
      throw error;
    }
  };

  return (
    <VersionSupportContext.Provider value={{}}>
      {props.children}
    </VersionSupportContext.Provider>
  );
};

export default VersionSupportContext;
