import { PermissionState } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import {
  NotificationKinds,
  NotificationMethods,
  SupportedCurrencies,
  UserConfigNotificationKind,
  UserConfigNotificationMethod,
} from '@faredrop/graphql-sdk';
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { lockClosedOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';
import { default as YupPassword } from 'yup-password';
import DeleteAccount from '../components/DeleteAccount';
import DesktopHeader from '../components/DesktopHeader';
import Dropdown from '../components/Dropdown';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import Loading from '../components/Loading';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import NotificationOptionRow, {
  NotificationSetting,
} from '../components/NotificationOptionRow';
import SettingsBackground from '../components/SettingsBackground';
import SettingsSideMenu, { MENU_OPTIONS } from '../components/SettingsSideMenu';
import { Tab } from '../contexts/tabContext';
import useAuth from '../hooks/auth';
import usePresentToast from '../hooks/presentToast';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useInterval from '../hooks/useInterval';
import usePushNotifications from '../hooks/usePushNotifications';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PAGES } from '../types/types';
import './../theme/ProfileSettings.css';
import './../theme/util.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

YupPassword(Yup);
interface SignUpValues {
  firstName: string;
  lastName: string;
}

enum LOADING_STATE {
  USER_ATTRIBUTES = 0,
  PASSWORD = 1,
  PHONE_NUMBER = 2,
  VERIFY_EMAIL = 3,
  DELETE_ACCOUNT = 4,
}

const getReadableCurrency = (currency: SupportedCurrencies) => {
  switch (currency) {
    case SupportedCurrencies.Cad: {
      return 'Canadian Dollars';
    }
    default: {
      return 'US Dollars';
    }
  }
};

const ProfileSettings: React.FC = () => {
  const { isApp, isExtraLargeScreenSize } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const [_isLoading, setIsLoading] = useState(-1);
  const {
    initialize,
    publicApiSendVerifyEmailAndResetPasswordEmail,
    user,
    sendVerificationCode,
  } = useAuth();
  const { presentWarning, presentSuccess, presentError } = usePresentToast();
  const history = useHistory();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { width } = useWindowDimensions();
  const { togglePushNotifications } = usePushNotifications();
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [pushNotificationState, setPushNotificationState] =
    useState<PermissionState>();
  const userState = useUser();
  const [hashDelay, setHashDelay] = useState<number | null>(10000);
  const [
    pushNotificationEnableLoadingOverride,
    setPushNotificationsEnableLoadingOverride,
  ] = useState<boolean | null>(null);

  const configInitializing = userState.isInitializing;
  const noInternet = userState.timeout;

  const userProfile = userState.user?.profile;
  const userConfigNotificationMethods =
    userState.user?.configuration.notificationMethods ?? [];
  const notificationKinds: NotificationSetting[] =
    userState.user?.configuration.notificationKinds ?? [];
  const emailVerified = user?.isEmailVerified;
  const currency = userState.user?.configuration.currency;

  const notificationMethods = buildNotificationMethods(
    userConfigNotificationMethods
  );

  // Scroll to any hash element in the URL
  // This is behind a delay so that we can actually see the smooth scroll. Without
  // the delay, this looks choppy
  useInterval(() => {
    if (window.location.hash !== '') {
      const hash = document.querySelector(window.location.hash);
      if (hash != null) {
        hash.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
    setHashDelay(null);
  }, hashDelay);

  useEffect(() => {
    updateSelectedTab(Tab.ACCOUNT).catch((error) => {
      console.warn('Failed to update selected tab', Tab.ACCOUNT, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  useEffect(() => {
    if (isApp) {
      PushNotifications.checkPermissions()
        .then(async (status) => {
          setPushNotificationState(status.receive);
        })
        .catch(() =>
          presentError(
            'Failed to verify push notification permissions - please refresh the app'
          )
        );
    }
  }, []);

  // Since push notification registration is async via event listeners, we need to control push notification toggle loading indicator at this level
  useEffect(() => {
    if (
      pushNotificationEnableLoadingOverride &&
      userState.isThisDeviceRegisteredForPushNotifications
    ) {
      setPushNotificationsEnableLoadingOverride(null);
    }
  }, [
    pushNotificationEnableLoadingOverride,
    userState.isThisDeviceRegisteredForPushNotifications,
  ]);

  const emailSubject = encodeURIComponent('Email Change Request');
  const emailBody = encodeURIComponent(
    `Hi, I'd like help changing the email associated with my account. Could you assist me? My name is ${userProfile?.firstName} ${userProfile?.lastName} and my current email is ${userProfile?.email}. I would like to change my email to ______________________`
  );
  const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

  const attributesSubmitDisabled = (
    values: SignUpValues
    // touched: FormikTouched<SignUpValues>,
    // errors: FormikErrors<SignUpValues>
  ) => {
    return (
      (values.firstName === userProfile?.firstName &&
        values.lastName === userProfile?.lastName) ||
      // && values.email === userProfile?.email
      values.firstName === '' ||
      values.lastName === '' ||
      emailVerified === false
      // || values.email === ''
      // || Boolean(touched.email && errors.email)
    );
  };

  const toggleNotificationMethod = async (method: NotificationSetting) => {
    const updatedMethods = userConfigNotificationMethods.map(
      (_method: UserConfigNotificationMethod) => {
        if (method.name === _method.name) {
          return {
            name: method.name,
            enabled: !method.enabled,
          } as UserConfigNotificationMethod;
        } else {
          return _method;
        }
      }
    );

    await userState.setNotificationMethods(updatedMethods, true);
  };

  const toggleNotificationKind = async (kind: NotificationSetting) => {
    const updatedKinds = notificationKinds.map((_kind: NotificationSetting) => {
      if (kind.name === _kind.name) {
        return {
          name: kind.name,
          enabled: !kind.enabled,
        } as UserConfigNotificationKind;
      } else {
        return _kind as UserConfigNotificationKind;
      }
    });

    await userState.setNotificationKinds(updatedKinds, true);
  };

  const handleCurrencyChange = async (
    event: SelectChangeEvent<SupportedCurrencies>
  ) => {
    try {
      const newCurrency = $enum(SupportedCurrencies).asValueOrThrow(
        event.target.value
      );
      await userState.setCurrency(newCurrency);
    } catch (error) {
      presentError('Invalid currency');
    }
  };

  const allEmailsDisabled = !notificationMethods.find(
    (method) => method.name === NotificationMethods.Email
  )?.enabled;

  return (
    ((configInitializing || emailVerified === undefined) && <Loading />) ||
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize || !userState.hasPaymentInfo ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={!userState.hasPaymentInfo && !isExtraLargeScreenSize}
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          <IonRow
            style={{
              marginBottom: '4em',
              padding: !isExtraLargeScreenSize
                ? isApp
                  ? '1.5em 2em 0'
                  : '0 2em'
                : '5em 0em 0em 0em',
            }}
            className="settings-bg-wrapper"
          >
            <IonCol
              sizeXs="12"
              sizeXl="10"
              style={{ margin: 'auto', padding: 0 }}
            >
              <IonRow>
                <h1
                  className="title-font"
                  style={{
                    marginTop: '2em',
                  }}
                >
                  {isExtraLargeScreenSize ? 'Account Settings' : 'Settings'}
                </h1>
              </IonRow>
              <IonRow
                className={!isExtraLargeScreenSize ? 'row-vertical-align' : ''}
              >
                <SettingsSideMenu
                  activeOption={PAGES.PROFILE}
                  isVisible={isExtraLargeScreenSize}
                />
                <Dropdown
                  options={MENU_OPTIONS[0].options.map(
                    (option) => option.label
                  )}
                  placeholder={PAGES.PROFILE}
                  onOptionClick={(option: string) => {
                    goWithStickyParamsPath(option);
                  }}
                  hrefOptions={true}
                  hidden={isExtraLargeScreenSize}
                  width={isExtraLargeScreenSize ? width / 2 : '100%'}
                />
                <IonCol
                  size={isExtraLargeScreenSize ? '5' : '12'}
                  style={{ marginLeft: !isExtraLargeScreenSize ? 0 : '5em' }}
                >
                  <IonRow>
                    <IonCol>
                      <h2>Profile</h2>
                      <p
                        style={{
                          marginBottom: !emailVerified ? '1em' : 0,
                        }}
                      >
                        {!emailVerified
                          ? "Before you change anything, let's first verify your email!"
                          : 'View & edit your account information'}
                      </p>
                    </IonCol>
                  </IonRow>
                  {!emailVerified && (
                    <IonRow>
                      <IonButton
                        className="button no-caps"
                        disabled={_isLoading === LOADING_STATE.VERIFY_EMAIL}
                        style={{
                          opacity:
                            _isLoading === LOADING_STATE.VERIFY_EMAIL
                              ? 0.5
                              : 1.0,
                          marginTop: 0,
                          width: '11.5em',
                          height: '3.5em',
                        }}
                        onClick={async () => {
                          if (userProfile?.email) {
                            goWithStickyParamsLocation({
                              pathname: AllowedPath.VERIFY,
                            });
                            await sendVerificationCode('email');
                          }
                        }}
                      >
                        {(_isLoading === LOADING_STATE.VERIFY_EMAIL && (
                          <IonSpinner color="white" name="crescent" />
                        )) || <p style={{ color: 'white' }}>Verify Email</p>}
                      </IonButton>
                    </IonRow>
                  )}
                  <IonRow style={{ opacity: !emailVerified ? 0.5 : 1 }}>
                    <IonCol>
                      <Formik
                        initialValues={{
                          firstName: userProfile?.firstName ?? '',
                          lastName: userProfile?.lastName ?? '',
                          email: userProfile?.email ?? '',
                          submit: null,
                        }}
                        // validationSchema={Yup.object().shape({
                        //   email: Yup.string()
                        //     .email('Must be a valid email')
                        //     .max(255)
                        //     .required('Email is required'),
                        // })}
                        onSubmit={async (
                          values,
                          { setErrors, setStatus, setSubmitting }
                        ): Promise<void> => {
                          try {
                            setIsLoading(LOADING_STATE.USER_ATTRIBUTES);
                            const {
                              firstName,
                              lastName,
                              // email
                            } = values;

                            await userState.setProfile(
                              undefined,
                              firstName,
                              lastName
                            );

                            // Re-init the auth with the new state from Cognito
                            await initialize();
                            setStatus({ success: true });
                            setSubmitting(false);
                            presentSuccess(
                              'Your information was successfully saved'
                            );
                            setIsLoading(-1);
                            // }
                          } catch (err) {
                            setIsLoading(-1);
                            presentWarning(
                              'Uh-oh. Something went wrong. Please contact team@faredrop.com'
                            );
                            setStatus({ success: false });
                            setErrors({ submit: (err as Error).message });
                            setSubmitting(false);
                          }
                        }}
                      >
                        {({
                          // errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          // touched,
                          values,
                        }): JSX.Element => (
                          <form noValidate onSubmit={handleSubmit}>
                            <IonRow>
                              <IonCol
                                size="12"
                                style={{
                                  paddingLeft: 0,
                                  paddingBottom: 0,
                                  paddingRight: 0,
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: 0,
                                    opacity: !emailVerified ? 0.5 : 1,
                                  }}
                                >
                                  First name
                                </h6>
                                <TextField
                                  autoFocus={false}
                                  fullWidth
                                  margin="normal"
                                  name="firstName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="name"
                                  value={values.firstName ?? ''}
                                  variant="outlined"
                                  disabled={!emailVerified}
                                />
                              </IonCol>
                              <IonCol
                                size="12"
                                style={{
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: 0,
                                    opacity: !emailVerified ? 0.5 : 1,
                                  }}
                                >
                                  Last name
                                </h6>
                                <TextField
                                  autoFocus={false}
                                  fullWidth
                                  margin="normal"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="name"
                                  value={values.lastName ?? ''}
                                  variant="outlined"
                                  disabled={!emailVerified}
                                />
                              </IonCol>
                              <IonCol
                                size="12"
                                style={{
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: 0,
                                    opacity: !emailVerified ? 0.5 : 1,
                                  }}
                                >
                                  Email
                                </h6>
                                <TextField
                                  InputProps={{
                                    endAdornment: (
                                      <IonIcon
                                        style={{
                                          marginTop: -4,
                                          paddingLeft: 12,
                                        }}
                                        icon={lockClosedOutline}
                                      />
                                    ),
                                  }}
                                  autoFocus={false}
                                  fullWidth
                                  margin="normal"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="name"
                                  disabled={true}
                                  value={values.email ?? ''}
                                  variant="outlined"
                                />
                                <FormHelperText
                                  className="pointer-on-hover"
                                  style={{
                                    fontSize: 12,
                                    color: 'var(--ion-color-primary)',
                                    textAlign: 'right',
                                    fontFamily: 'nexa-bold',
                                  }}
                                >
                                  <a href={errorMailTo}>
                                    Need to change your email? Contact our
                                    support team!
                                  </a>
                                </FormHelperText>
                              </IonCol>
                            </IonRow>
                            {/* <h6 style={{ marginBottom: 0 }}>Email</h6>
                            <TextField
                              autoFocus={false}
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email}
                              margin="normal"
                              name="emailAddress"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              value={values.email}
                              variant="outlined"
                            /> */}
                            {/* {errors.submit && (
                              <IonRow style={{ backgroundColor: 'white' }}>
                                <FormHelperText error>
                                  {errors.submit}
                                </FormHelperText>
                              </IonRow>
                            )} */}
                            <IonRow>
                              <IonButton
                                className="button no-caps"
                                disabled={attributesSubmitDisabled(
                                  values
                                  // touched,
                                  // errors
                                )}
                                style={{
                                  opacity: attributesSubmitDisabled(
                                    values
                                    // touched,
                                    // errors
                                  )
                                    ? 0.5
                                    : 1.0,
                                  marginTop: '2em',
                                  width: '11.5em',
                                  height: '3.5em',
                                }}
                                type="submit"
                              >
                                {(_isLoading ==
                                  LOADING_STATE.USER_ATTRIBUTES && (
                                  <IonSpinner color="white" name="crescent" />
                                )) || (
                                  <p style={{ color: 'white' }}>Save changes</p>
                                )}
                              </IonButton>
                            </IonRow>
                          </form>
                        )}
                      </Formik>
                    </IonCol>
                  </IonRow>
                  <hr
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .1)',
                      margin: '2em 0 3em',
                    }}
                  />
                  <IonRow
                    style={{
                      opacity: !emailVerified ? 0.5 : 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <IonCol size="12">
                      <h3>Notification Preferences</h3>
                    </IonCol>
                    <IonCol className="toggle-container-responsive">
                      {notificationMethods.map(
                        (method: NotificationSetting, i: number) => (
                          <NotificationOptionRow
                            key={i}
                            setting={{
                              ...method,
                              uiDisabled: method.uiDisabled || !emailVerified,
                            }}
                            onToggle={toggleNotificationMethod}
                          />
                        )
                      )}
                      {isApp && (
                        <NotificationOptionRow
                          setting={{
                            name: 'PUSH',
                            enabled:
                              pushNotificationState === 'granted' &&
                              userState.isThisDeviceRegisteredForPushNotifications ===
                                true,
                          }}
                          onToggle={async (setting) => {
                            const enabled = !setting.enabled;

                            const updatedState = await togglePushNotifications(
                              enabled
                            );
                            if (
                              updatedState &&
                              pushNotificationState !== updatedState
                            ) {
                              setPushNotificationState(updatedState);
                            }

                            if (enabled) {
                              setPushNotificationsEnableLoadingOverride(true);
                              return true; // Skip set loading to false after this method is complete - let loading override do it's thing since we're waiting for user state to update
                            }

                            return;
                          }}
                          loadingOverride={
                            pushNotificationEnableLoadingOverride
                          }
                        />
                      )}
                    </IonCol>
                    {!isApp && (
                      <IonRow
                        style={{
                          width: '100%',
                          display: 'flex',
                          marginTop: '4em',
                        }}
                      >
                        <p style={{ margin: 'auto' }}>
                          <small>
                            Log onto our mobile app to change Push notifications
                            permissions
                          </small>
                        </p>
                      </IonRow>
                    )}
                  </IonRow>
                  <hr
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .1)',
                      margin: '2em 0 3em',
                    }}
                  />
                  <IonRow
                    style={{
                      opacity: !emailVerified ? 0.5 : 1,
                      paddingBottom: '1em',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <IonCol size="12">
                      <h3>Email Preferences</h3>
                    </IonCol>
                    <IonCol className="toggle-container-responsive">
                      {notificationKinds
                        .filter(
                          (nk) =>
                            nk.name !== NotificationKinds.MilesAndPoints &&
                            nk.name !== NotificationKinds.CreditCards &&
                            nk.name !== NotificationKinds.Newsletter
                        )
                        .map((kind: NotificationSetting, i: number) => (
                          <NotificationOptionRow
                            key={i}
                            setting={{
                              ...kind,
                              enabled: allEmailsDisabled ? false : kind.enabled,
                              uiDisabled:
                                kind.uiDisabled ||
                                !emailVerified ||
                                allEmailsDisabled,
                              uiDisabledTooltip:
                                'Enable Email Notifications in Notification Preferences to enable this setting',
                            }}
                            onToggle={toggleNotificationKind}
                            notificationType={'emails'}
                          />
                        ))}
                    </IonCol>
                  </IonRow>
                  <hr
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .1)',
                      margin: '2em 0 3em',
                    }}
                  />
                  <IonRow
                    style={{
                      opacity: !emailVerified ? 0.5 : 1,
                      paddingBottom: '1em',
                    }}
                  >
                    <IonCol size="12">
                      <h3>Currency Preferences</h3>
                    </IonCol>
                    <IonCol>
                      <FormControl fullWidth style={{ marginTop: '1em' }}>
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                          labelId="currency-label"
                          id="currency"
                          value={currency ?? SupportedCurrencies.Usd}
                          label="Currency"
                          disabled={!emailVerified}
                          onChange={handleCurrencyChange}
                        >
                          {$enum(SupportedCurrencies)
                            .getValues()
                            .map((value) => (
                              <MenuItem key={value} value={value}>
                                {getReadableCurrency(value)}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </IonCol>
                  </IonRow>
                  <IonRow
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <p style={{ margin: 'auto', lineHeight: 'inherit' }}>
                      <small>
                        {`FareDrop verifies prices in the departing airport's currency. If your selected currency does not match the departing airport's currency, the price is converted. In this scenario, it's possible that the displayed price and the actual price may differ.`}
                      </small>
                    </p>
                  </IonRow>
                  <hr
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .1)',
                      margin: '2em 0 3em',
                    }}
                  />
                  <IonRow
                    id="change-password"
                    style={{ opacity: !emailVerified ? 0.5 : 1 }}
                  >
                    <IonCol style={{ display: 'flex' }}>
                      {resetEmailSent ? (
                        <p style={{ textAlign: 'center' }}>
                          We sent an email to {user?.email} with a link to reset
                          your password!
                        </p>
                      ) : (
                        <IonButton
                          style={{ margin: 'auto', marginBottom: '1.5em' }}
                          disabled={!emailVerified}
                          onClick={async (e) => {
                            e.preventDefault();

                            try {
                              setIsLoading(LOADING_STATE.PASSWORD);

                              if (user?.email) {
                                await publicApiSendVerifyEmailAndResetPasswordEmail(
                                  user.email
                                );
                              }

                              setIsLoading(-1);
                              setResetEmailSent(true);
                            } catch (err) {
                              setIsLoading(-1);
                              presentWarning(
                                'Uh-oh. Something went wrong. Please contact team@faredrop.com'
                              );
                            }
                          }}
                        >
                          Reset password
                        </IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow
                    id="change-password"
                    style={{ opacity: !emailVerified ? 0.5 : 1 }}
                  >
                    <IonCol style={{ display: 'flex' }}>
                      <DeleteAccount />
                    </IonCol>
                  </IonRow>
                  <hr
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .1)',
                      margin: '2em 0 1em',
                    }}
                  />
                  <IonRow
                    style={{
                      padding: '2em 0',
                      marginTop: '3em',
                      display: 'flex',
                    }}
                  >
                    <button
                      style={{
                        width: '15em',
                        margin: 'auto',
                        height: '5em',
                        padding: '1em',
                        backgroundColor: 'transparent',
                        borderRadius: '7px',
                        border: '2px solid var(--ion-color-gray)',
                        color: 'var(--ion-color-gray)',
                      }}
                      onClick={async () => {
                        history.push({ pathname: AllowedPath.LOGOUT });
                      }}
                    >
                      Sign out
                    </button>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
      </IonPage>
    )
  );
};

const buildNotificationMethods = (methods: NotificationSetting[]) => {
  const notificationMethods: NotificationSetting[] = [
    {
      name: NotificationMethods.Email,
      enabled:
        methods.find((m) => m.name === NotificationMethods.Email)?.enabled ??
        false,
      tooltip: 'Receive deal notifications via email.',
    },
  ];

  return notificationMethods;
};

export default ProfileSettings;
