import { IonCol, IonContent, IonIcon, IonPage, IonRow } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { useEffect, useRef } from 'react';

import { AllowedPath } from '@faredrop/types';

import './../theme/LoginPage.css';
import './../theme/util.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import GetStarted from '../components/GetStarted';
import useAnalytics from '../hooks/analytics';
import { useDevice } from '../hooks/useDevice';
import MobileHeader from '../components/MobileHeader';
import DesktopHeader from '../components/DesktopHeader';
import useAuth from '../hooks/auth';
import useHandleNewSubscription from '../hooks/handleNewSubscription';

const LoginPage: React.FC = () => {
  const { isAuthenticated, isInitialized } = useAuth();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { logAnalyticsOnboardingEngagement } = useAnalytics();
  const { isApp, isLargeScreenSizeOrSmaller } = useDevice();

  const { handleNewSubscription, createPlanFromPlanParam, selectedPlanParam } =
    useHandleNewSubscription();

  const initializedRef = useRef(false);

  useEffect(() => {
    if (isInitialized) {
      // Without initializedRef, this is getting called after sign up, which is not what we want
      if (isAuthenticated && !initializedRef.current) {
        goWithStickyParamsPath(AllowedPath.DEALS);
      }
      initializedRef.current = true;
    }
  }, [isAuthenticated, isInitialized]);

  return (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          padding: '15px 40px',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader
            menuHidden={true}
            backToHomeListener={() => {
              window.location.reload();
            }}
          />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            ctaHidden={true}
            showBackToHome={true}
          />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow className="row-vertical-align" style={{ padding: '2em' }}>
          <IonCol sizeXs="12" sizeLg="4">
            {isApp && (
              <div>
                <a
                  onClick={async () => {
                    goWithStickyParamsPath(AllowedPath.LOGIN);
                  }}
                >
                  <button
                    className="transparent-button md-button green-button"
                    style={{
                      display: 'flex',
                      fontSize: '16px',
                      color: 'var(--ion-color-primary)',
                      border: 0,
                    }}
                  >
                    <IonIcon
                      icon={arrowBackOutline}
                      style={{ margin: 'auto' }}
                    />
                    <p
                      style={{
                        margin: 'auto',
                        paddingTop: 2,
                        paddingLeft: 5,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Back to Home
                    </p>
                  </button>
                </a>
              </div>
            )}
            <h1 className="title-font" style={{ textAlign: 'center' }}>
              Create an account to get started
            </h1>
            <GetStarted
              onSubmit={async () => {
                if (selectedPlanParam !== undefined) {
                  const subscriptionPlan =
                    createPlanFromPlanParam(selectedPlanParam);
                  await handleNewSubscription(subscriptionPlan);
                } else {
                  goWithStickyParamsLocation({
                    pathname: AllowedPath.PLANS,
                  });
                }
              }}
              logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
            />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
