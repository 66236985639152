import {
  Deal,
  DealStatus,
  SeatClass,
  UserConfigOrigin,
  UserDealsQuery,
} from '@faredrop/graphql-sdk';
import useFareDropApiClientWithTimeout from './faredropApiClientWithTimeout';
import useAuth from './auth';
import { FareDropRole as FareDropRoleGQL } from '@faredrop/graphql-sdk';

import useSWRTimeout from './useSWRTimeout';
import useUser from './user';
import useAnalytics from './analytics';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import useAppState from './useAppState';
import moment from 'moment';
import { buildOriginsKey } from '../utilities/swr-util';
import { FareDropRole } from '@faredrop/types';

export interface UserDealsValue {
  deals?: UserDealsQuery['userDeals'];
  isInitializing: boolean;
  isLoading: boolean;
  error?: Error;
  timeout: boolean;
  addDeal: (deal: Deal) => void;
  mutate: (
    data?: UserDealsQuery['userDeals'],
    shouldRevalidate?: boolean
  ) => Promise<UserDealsQuery['userDeals'] | undefined>;
}

const useUserDeals = (seatClass?: SeatClass): UserDealsValue => {
  const { user } = useAuth();
  const userState = useUser();
  const { activeTrigger } = useAppState();
  const userProfile = userState.user?.profile;
  const userConfigOrigins = userState.user?.configuration?.origins ?? [];
  const { client } = useFareDropApiClientWithTimeout();
  const { logAnalyticsError } = useAnalytics();

  const userDealsKey = (
    userId: string,
    seatClass: SeatClass,
    origins: UserConfigOrigin[],
    userRoles: FareDropRole[]
  ) => [
    'USER_DEALS',
    process.env.REACT_APP_VERSION ?? '',
    userId,
    seatClass.toString(),
    ...buildOriginsKey(origins),
    userRoles
      .map((role) => role.toString())
      .sort()
      .join(','),
  ];

  let requestKey: string[] | null = null;
  if (
    seatClass &&
    user?.id &&
    (userProfile?.roles.includes(FareDropRoleGQL.Global) ||
      userProfile?.roles.includes(FareDropRoleGQL.Limited) ||
      userProfile?.roles.includes(FareDropRoleGQL.Pro))
  ) {
    requestKey = userDealsKey(
      user.id,
      seatClass,
      userConfigOrigins,
      user.roles
    );
  }

  const { data, error, mutate, timeout, isValidating } = useSWRTimeout(
    requestKey,
    async (
      _scopedPrefix,
      _scopedVersion,
      _scopedUserId,
      scopedSeatClass,
      ...scopedOrigins
    ) => {
      try {
        if (scopedOrigins.length === 0) return [];
        const castSeatClass = scopedSeatClass as SeatClass;
        return (
          await client.userDeals({
            seatClasses: [castSeatClass],
            status: [DealStatus.Auto, DealStatus.Sent],
            startDate:
              castSeatClass.toUpperCase() === SeatClass.Business
                ? // Ideally, we shouldn't use any variables outside of the fetcher function, but we don't want to include ActiveTrigger in the key
                  moment(activeTrigger).subtract(7, 'days').valueOf()
                : undefined, // We grab 7 days of business vs the default of 2 days for economy
            filter: false,
            limit: 50,
          })
        ).data.userDeals;
      } catch (error) {
        await logAnalyticsError(
          `getUser${scopedSeatClass}Deals`,
          error as Error
        );
        throw error;
      }
    },
    seatClass && hasSeatClassAccessGQL(userProfile?.roles, seatClass)
      ? // Friendly error message to be "presented" to user upon failure only if deals are not returned
        // to user and user has correct roles
        `Failed to get ${seatClass} deals`
      : undefined
  );

  const addDeal = async (_deal: Deal) => {
    await mutate(); // This will also revalidate the data
  };

  return {
    deals: data,
    isInitializing: !error && !data,
    isLoading: isValidating,
    error,
    timeout,
    mutate,
    addDeal,
  };
};

export default useUserDeals;
