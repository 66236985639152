import { IonButton, IonIcon, IonCol, IonGrid, IonRow } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';

import { AllowedPath, SubscriptionPlan } from '@faredrop/types';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  applyPromotion,
  isLimitedPlan,
} from '@faredrop/utilities';

import './../theme/util.css';
import './../theme/SubscriptionPlansTable.css';
import {
  SubscriptionFeatureDescriptions,
  calculateDiscountPrice,
  convertSubscriptionPlanTypeToGQL,
  isFrontendSubscriptionPromotion,
} from '../utilities/plans-utilities';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { useDevice } from '../hooks/useDevice';
import usePlans from '../hooks/usePlans';
import { useDisplayDiscounts } from '../hooks/useDisplayDiscounts';
import useUser from '../hooks/user';

interface ContainerProps {
  onSelect?: () => void;
  planDiscount?: number;
}

const SubscriptionPlansTable: React.FC<ContainerProps> = ({
  onSelect,
  planDiscount,
}) => {
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { smoothScroll } = useLandingPageNavigation();
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { plans } = usePlans();
  const { discounts } = useDisplayDiscounts();
  const userState = useUser();

  const isLimited = isLimitedPlan(userState.user?.billing.idStripePlan);

  // NOTE: Revert for T-Shirt promotions
  const dynamicPerks: SubscriptionFeatureDescriptions[] = [
    // isFrontendSubscriptionPromotion(isLimited) && PROMOTION_IS_SHOPIFY_INTEGRATION
    //   ? SubscriptionFeatureDescriptions.FreeTShirt
    //   : SubscriptionFeatureDescriptions.DiscountedTShirt,
  ];

  // NOTE: Remove for T-Shirt promotions
  if (
    isFrontendSubscriptionPromotion(isLimited) &&
    PROMOTION_IS_SHOPIFY_INTEGRATION
  ) {
    dynamicPerks.push(SubscriptionFeatureDescriptions.FreeTravelDayBundle);
  }

  const features: SubscriptionFeatureDescriptions[] = [
    SubscriptionFeatureDescriptions.DepartureAirports,
    SubscriptionFeatureDescriptions.DealNotificationsPerWeek,
    SubscriptionFeatureDescriptions.DealSearch,
    SubscriptionFeatureDescriptions.DomesticEconomyDeals,
    SubscriptionFeatureDescriptions.InternationalEconomyDeals,
    SubscriptionFeatureDescriptions.InternationalBusinessDeals,
    SubscriptionFeatureDescriptions.DestinationPreferences,
    SubscriptionFeatureDescriptions.DealPreferences,
    ...dynamicPerks,
    SubscriptionFeatureDescriptions.FreeDestinationGuideEBook,
    // NOTE: This should be dynamic perk if we have a t-shirt promotion
    SubscriptionFeatureDescriptions.DiscountedTShirt,
  ];

  if (
    isFrontendSubscriptionPromotion(isLimited) &&
    PROMOTION_IS_SHOPIFY_INTEGRATION
  ) {
    features.push(SubscriptionFeatureDescriptions.Giveaway);
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        paddingTop: '6em',
        marginBottom: '2em',
      }}
      id="pricing"
    >
      <IonGrid fixed={true} style={{ display: 'flex' }}>
        <IonCol size="6" className="pricing-table-col">
          <IonRow
            className="title-font pricing-table-header pricing-table-description-row"
            style={{ color: 'white' }}
          >
            hidden
          </IonRow>
          <IonRow
            className="pricing-table-row pricing-table-description-row pricing-table-price"
            style={{ color: 'white' }}
          >
            <div style={{ paddingTop: '10px' }}>hidden</div>
          </IonRow>
          <IonRow
            className="pricing-table-row pricing-table-description-row pricing-table-sub-price"
            style={{ color: 'white', paddingBottom: '23px' }}
          >
            hidden
          </IonRow>
          <IonRow
            className="title-font pricing-table-header pricing-table-description-row pricing-table-plan pricing-table-border"
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              alignItems: 'center',
              fontSize: isLargeScreenSizeOrSmaller ? '42px' : undefined,
            }}
          >
            Compare Memberships
          </IonRow>
          {features.map((feature, index) => {
            const className = `pricing-table-row pricing-table-description-row ${
              index === features.length - 1 ? 'pricing-table-row-end' : ''
            }`;
            return (
              <IonRow key={feature} className={className}>
                {feature.includes('Travel Day Bundle') ? (
                  <a
                    href={`${process.env.REACT_APP_DAILY_DROP_SHOP_DOMAIN}/products/travel-day-bundle`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {feature}
                  </a>
                ) : feature.includes('Lonely Planet') ? (
                  <a
                    href="https://shop.lonelyplanet.com/collections/ebooks"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {feature}
                  </a>
                ) : feature.includes('Airport T-Shirt') ? (
                  <a
                    href={`${process.env.REACT_APP_DAILY_DROP_SHOP_DOMAIN}/collections/airport-diagram-t-shirts`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {feature}
                  </a>
                ) : feature.includes('Punta Cana') ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      goWithStickyParamsPath(AllowedPath.SUMMER24);
                    }}
                  >
                    {feature}
                  </a>
                ) : (
                  feature
                )}
              </IonRow>
            );
          })}
        </IonCol>
        {plans?.map((plan, index) => {
          plan = isFrontendSubscriptionPromotion(isLimited)
            ? convertSubscriptionPlanTypeToGQL(
                applyPromotion(plan as SubscriptionPlan)
              )
            : plan;

          const isPriceNumber = !isNaN(parseInt(plan.price));
          const title = plan.name.replace(' Plan', '');
          const isHighlighted = index === plans.length - 1;
          const billingText = plan.billingText;
          const billingSubText = plan.billingSubtext;
          const discountPrice = calculateDiscountPrice(
            plan,
            plan.discount ?? planDiscount ?? undefined,
            discounts
          );
          return (
            <IonCol
              size="3"
              className="pricing-table-col"
              key={plan.id}
              style={{
                backgroundColor: isHighlighted
                  ? 'var(--ion-color-secondary)'
                  : undefined,
              }}
            >
              <IonRow className="title-font pricing-table-header">
                {title}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-price">
                <IonRow style={{ display: 'flex' }}>
                  <div style={{ position: 'relative' }}>
                    {isPriceNumber && (
                      <div className="pricing-table-dollar-sign">$</div>
                    )}
                    <div
                      style={{
                        paddingTop: '10px',
                        color: isHighlighted
                          ? 'var(--ion-color-primary)'
                          : undefined,
                        marginLeft: '2px',
                      }}
                    >
                      {isPriceNumber
                        ? Math.floor(
                            parseInt(discountPrice ?? plan.price)
                          ).toFixed(0)
                        : plan.price}
                    </div>
                  </div>
                  {discountPrice && (
                    <div
                      style={{
                        position: 'relative',
                        color: 'gray',
                        opacity: 0.75,
                      }}
                    >
                      <div
                        className="pricing-table-dollar-sign"
                        style={{ left: '5px' }}
                      >
                        $
                      </div>
                      <div
                        className="strike-through"
                        style={{
                          position: 'absolute',
                          left: '17px',
                          paddingTop: '10px',
                          borderColor: 'var(--ion-color-orange)',
                        }}
                      >
                        {Math.floor(parseInt(plan.price)).toFixed(0)}
                      </div>
                    </div>
                  )}
                </IonRow>
              </IonRow>
              <IonRow
                className="pricing-table-row pricing-table-details-row pricing-table-sub-price"
                style={{
                  paddingBottom: billingText ? '5px' : '23px',
                  visibility: !billingText ? 'hidden' : undefined,
                }}
              >
                {billingText && (
                  <div>
                    <div style={{ textAlign: 'center' }}>{billingText}</div>
                    <div
                      style={{
                        textAlign: 'center',
                        paddingTop: '4px',
                        visibility: billingSubText ? undefined : 'hidden',
                      }}
                    >
                      {billingSubText ?? 'hidden'}
                    </div>
                  </div>
                )}
                {!billingText && 'hidden'}
              </IonRow>
              <IonRow
                className="pricing-table-row pricing-table-details-row pricing-table-plan pricing-table-border"
                style={{ paddingTop: 0 }}
              >
                <IonRow
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonButton
                    onClick={() => {
                      const url = new URL(window?.location?.href);
                      url.searchParams.set('selected-plan', plan.planType);
                      goWithStickyParamsLocation({
                        search: url.searchParams.toString(),
                      });

                      if (onSelect) {
                        onSelect();
                      } else {
                        smoothScroll('#register');
                      }
                    }}
                    fill={isHighlighted ? undefined : 'clear'}
                  >
                    <p
                      style={{
                        color: isHighlighted
                          ? 'white'
                          : 'var(--ion-color-primary)',
                      }}
                    >
                      Choose {title}
                    </p>
                  </IonButton>
                </IonRow>
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row">
                {plan.departureAirports}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row">
                {plan.dealNotifications}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check pricing-table-row-end">
                {plan.dealSearch}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row">
                {plan.economyDeals ?? '-'}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row">
                {plan.economyDeals ?? '-'}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check">
                {plan.businessDeals ?? '-'}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check">
                {plan.dealPreferences && <IonIcon icon={checkmarkOutline} />}
                {!plan.dealPreferences && '-'}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check pricing-table-row-end">
                {plan.dealPreferences && <IonIcon icon={checkmarkOutline} />}
                {!plan.dealPreferences && '-'}
              </IonRow>
              {dynamicPerks.map((perk) => {
                return (
                  <IonRow
                    key={perk}
                    className="pricing-table-row pricing-table-details-row pricing-table-no-check"
                  >
                    {plan.perks && <IonIcon icon={checkmarkOutline} />}
                    {!plan.perks && '-'}
                  </IonRow>
                );
              })}
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check">
                {plan.perks && <IonIcon icon={checkmarkOutline} />}
                {!plan.perks && '-'}
              </IonRow>
              <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check">
                {plan.perks && <IonIcon icon={checkmarkOutline} />}
                {!plan.perks && '-'}
              </IonRow>
              {isFrontendSubscriptionPromotion(isLimited) &&
                PROMOTION_IS_SHOPIFY_INTEGRATION && (
                  <IonRow className="pricing-table-row pricing-table-details-row pricing-table-no-check">
                    {plan.perks && <IonIcon icon={checkmarkOutline} />}
                    {!plan.perks && '-'}
                  </IonRow>
                )}
            </IonCol>
          );
        })}
      </IonGrid>
    </div>
  );
};

export default SubscriptionPlansTable;
