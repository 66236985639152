import { IonButton, IonCol, IonRow } from '@ionic/react';
import moment from 'moment';

import { SHOPIFY_DISCOUNT_CODES } from '@faredrop/types';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  SHOPIFY_REDEEM_END,
  SUBSCRIPTION_PROMOTION_END,
  SUBSCRIPTION_PROMOTION_START,
} from '@faredrop/utilities';

import QuestionCard from './QuestionCard';
import './../theme/QuestionsSection.css';
import './../theme/util.css';
import { useDevice } from '../hooks/useDevice';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';

interface QuestionCardData {
  id: AnalyticsEngagementId;
  question: string;
  answer: string;
}

let refundPolicy = `For all full-priced subscriptions, we offer a no-questions-asked, risk-free, 30-day refund policy. Simply send an email to team@faredrop.com and request a refund from your subscribed email address. Full details are available in our Refund Policy linked at the bottom of the page.

FareDrop stands behind our product and the value that it provides. If you are not receiving value from our amazing flight deals, we would love to hear your feedback and better understand how we can improve our services. You can reach us at feedback@faredrop.com with your feedback.

All FareDrop employees have been on at least one amazing FareDrop trip and we have each personally seen how the service more than pays for itself if you are able to book one international trip per year.`; // cspell:disable-line

if (
  isFrontendSubscriptionPromotion(undefined, { postPromotionDays: 60 }) &&
  PROMOTION_IS_SHOPIFY_INTEGRATION
) {
  refundPolicy = `If purchasing a 50% off Pro Plan during our Summer sale, you are not eligible for a refund on your FareDrop membership once you’ve redeemed any of your perks, including your free Travel Day Bundle or Lonely Planet eBook. ${refundPolicy}`;
}

const questionCardData: QuestionCardData[] = [
  {
    id: AnalyticsEngagementId.QUESTION_WHY_FAREDROP,
    question: 'Why FareDrop?',
    answer: `FareDrop is your ticket to see the world - on any budget! We search millions of flights each day and alert our users to the best airfares available. Our FareDrop deals are up to 80 percent lower than the usual prices, which means that our average user saves hundreds of dollars on each flight they book.

We differentiate ourselves from other services by providing more granular controls of your travel availability throughout the year, allowing you to select regions you’d like to travel to and allowing you to set maximum prices for the airfares you’d like to receive. Our goal is to send you the best deals we can find each day - deals that align with your travel goals, rather than to flood you with notifications of every cheap airfare our system finds.`, // cspell:disable-line
  },
  {
    id: AnalyticsEngagementId.QUESTION_COUNTRY_AVAILABILITY,
    question: 'Which countries does FareDrop service?',
    answer:
      'FareDrop is currently searching for deals departing from the United States and Canada.', // cspell:disable-line
  },
  {
    id: AnalyticsEngagementId.QUESTION_DEALS_FROM_HOME_AIRPORT,
    question: 'Will there be any deals from my home airport?',
    answer:
      'We send deals from all major international airports in the United States and Canada, so there is a good chance that your city and other near-by airports are available. You can search above on the page to find which airports are currently supported and to see some of our recent deals departing from each origin.', // cspell:disable-line
  },
  {
    id: AnalyticsEngagementId.QUESTION_DEAL_CADENCE,
    question: 'How often will I receive flight deal alerts?',
    answer: `Great FareDrop deals can happen anytime, but are also completely outside of our control! As such, we cannot guarantee the frequency you’ll be alerted to new deals. There may be a week when you will not receive any new notifications, and then there will be others when we'll be alerting you to dozens of amazing fares. Rest assured, if there’s a great deal that matches your preferences, you’ll hear about it!

We strive to send at least one amazing deal per origin each day, but will not waste your time with mediocre deals. FareDrops only includes truly great deals, so that you can travel more affordably.`, // cspell:disable-line
  },
  {
    id: AnalyticsEngagementId.QUESTION_DEAL_TIME_BEFORE_DEPARTURE,
    question: 'How far in advance do you send out deals?',
    answer:
      'We search for a wide-range of deals departing up to 11 months in the future. Some deals will have sample dates which depart immediately, but a majority of deals will have a range of dates departing from one to eight months in advance. Great flight prices can be found any time, so departure dates of our deals will vary.', // cspell:disable-line
  },
  {
    id: AnalyticsEngagementId.QUESTION_REFUND_POLICY,
    question: 'What is your refund policy?',
    answer: refundPolicy,
  },
];

if (
  isFrontendSubscriptionPromotion(undefined, { postPromotionDays: 60 }) &&
  PROMOTION_IS_SHOPIFY_INTEGRATION
) {
  questionCardData.push(
    ...[
      {
        id: AnalyticsEngagementId.QUESTION_FREE_TRAVEL_DAY_BUNDLE,
        question: 'Am I eligible for the free Travel Day Bundle?',
        answer: `All first time FareDrop Pro customers, including those who have upgraded from the Limited plan, are eligible to receive the free Travel Day Bundle during the sale (${moment(
          SUBSCRIPTION_PROMOTION_START
        )
          .add(1, 'day')
          .format('MMMM Do YYYY')} through ${moment(SUBSCRIPTION_PROMOTION_END)
          .subtract(1, 'day')
          .format(
            'MMMM Do YYYY'
          )}). As soon as a Pro subscription is purchased during the sale, you should receive an email with a link to redeem your free Travel Day Bundle. Make sure to use your FareDrop account email address and apply the ${
          SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_FREE
        } and ${
          SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_SHIPPING
        } discount codes at checkout to redeem your free Travel Day Bundle.

        If you are a current Pro FareDrop subscriber, you are not eligible for a free Travel Day Bundle, however, you can purchase the Travel Day Bundle at a discounted price of $20. To purchase a discounted Travel Day Bundle, please use your FareDrop account email address and ${
          SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_DISCOUNTED
        } discount code at checkout. Free and discounted Travel Day Bundles must be redeemed by
        ${moment(SHOPIFY_REDEEM_END)
          .subtract(1, 'day')
          .format('MMMM Do YYYY')}. Limit 1 per customer.`,
      },
      {
        id: AnalyticsEngagementId.QUESTION_FREE_TRAVEL_DAY_BUNDLE_SHIPPING,
        question: 'When will I receive my free Travel Day Bundle?',
        answer: `After subscribing to the Pro plan, you should receive an email with instructions on how to redeem your free Travel Day Bundle. Once you have placed an order for your Travel Day Bundle, you should receive the Travel Day Bundle within 15 business days. If you do not receive your Travel Day Bundle within 15 business days, please email team@faredrop.com. Free and discounted Travel Day Bundles must be redeemed by
        ${moment(SHOPIFY_REDEEM_END)
          .subtract(1, 'day')
          .format('MMMM Do YYYY')}. Limit 1 per customer.`,
        // answer: `After subscribing to the Pro plan, you should receive an email with instructions on how to redeem your free t-shirt. Once you have placed an order for your t-shirt, you should receive an email with a tracking link. A delivery estimate will be provided based on your address. If you do not receive an email with instructions on how to redeem your free t-shirt, please email team@faredrop.com. You will have until December 11th 2023 to redeem your free t-shirt.`,
      },
    ]
  );
}

interface QuestionsCardProps {
  onCardOpen?: (questionId: AnalyticsEngagementId) => Promise<void>;
}

const QuestionsSection: React.FC<QuestionsCardProps> = ({ onCardOpen }) => {
  const { isSmallScreenSizeOrSmaller, isMediumScreenSizeOrSmaller } =
    useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  return (
    <IonRow className="questions-container mt-0">
      <IonCol>
        <IonRow>
          <h2 className="title-font mt-0 p-0 mb-1-5 center-align">
            Have any questions?
          </h2>
        </IonRow>
        <IonRow
          className="row-vertical-align"
          style={{ height: 'auto', padding: '0 2em' }}
        >
          {questionCardData.map((card, i) => {
            return (
              <QuestionCard
                key={i}
                border={!isSmallScreenSizeOrSmaller}
                data={card}
                sx={{
                  marginBottom: isMediumScreenSizeOrSmaller ? '2em' : '1em',
                }}
                onOpen={onCardOpen}
              />
            );
          })}
        </IonRow>
        <IonRow style={{ display: 'flex', marginTop: '2em' }}>
          <IonButton
            className="get-started-button get-started-button-hero mb-1-5"
            style={{ margin: 'auto', width: '15em' }}
            onClick={async () => {
              goWithStickyParamsPath('/faq');
              onCardOpen &&
                (await onCardOpen(AnalyticsEngagementId.NAV_TO_FAQ));
            }}
          >
            Check out our full FAQ here
          </IonButton>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default QuestionsSection;
