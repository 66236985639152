import { $enum } from 'ts-enum-util';
import _ from 'lodash';

import {
  FareDropPlan as FareDropPlanGQL,
  SubscriptionPlan as SubscriptionPlanGQL,
} from '@faredrop/graphql-sdk';
import {
  STRIPE_PLAN_ID,
  SubscriptionPlan,
  FareDropPlan,
  AllowedPath,
} from '@faredrop/types';
import {
  stripePlanIDByFareDropPlan,
  buildSubscriptionPlans,
  isPaidPlan,
  stripePlanIDToFareDropPlan,
  IIsSubscriptionPromotion,
  isSubscriptionPromotion,
  PRE_PROMOTION_DAYS_LIMITED_USERS,
} from '@faredrop/utilities';

import { IQueryParamDiscounts } from '../hooks/useDisplayDiscounts';

export enum SubscriptionFeatureDescriptions {
  DepartureAirports = 'Departure Airports',
  DealNotificationsPerWeek = 'Deal Notifications Per Week',
  DomesticEconomyDeals = 'Domestic Economy Deals',
  InternationalEconomyDeals = 'International Economy Deals',
  InternationalBusinessDeals = 'International Business Deals',
  DestinationPreferences = 'Destination Preferences',
  DealPreferences = 'Deal Preferences',
  DealSearch = 'Deal Search',
  FreeTShirt = '🎉 FREE Airport T-Shirt',
  DiscountedTShirt = '🎉 $10 Discount on FareDrop Airport T-Shirt',
  FreeTravelDayBundle = '🎉 FREE Travel Day Bundle',
  FreeDestinationGuideEBook = '🎉 FREE Lonely Planet Destination Guide eBook',
  Giveaway = '🎉 Automatic entry to win a FREE trip to Punta Cana',
}

export const convertSubscriptionPlanToGql = (
  subscriptionPlan: SubscriptionPlan
) => {
  return {
    ...subscriptionPlan,
    planType: subscriptionPlan.planType as FareDropPlanGQL,
  };
};

// We are opting to hard-code plans to provide to our unauthenticated pages
// NOTE: Since we are hard-coding these plans, prices here must be updated
// anytime prices or discounts are updated in Stripe
export const buildFrontendSubscriptionPlans = (): SubscriptionPlanGQL[] => {
  return buildSubscriptionPlans().map((plan) =>
    convertSubscriptionPlanToGql(plan)
  );
};

export const isSubscriptionUpgrade = (
  currentPlan: STRIPE_PLAN_ID,
  newPlan: FareDropPlanGQL
) => {
  switch (currentPlan) {
    case STRIPE_PLAN_ID.PRODUCTION_LIMITED_YEARLY:
    case STRIPE_PLAN_ID.DEVELOPMENT_LIMITED_YEARLY: {
      if (newPlan === FareDropPlanGQL.Limited) return false;
      else return true;
    }
    case STRIPE_PLAN_ID.DEPRECATED_PRODUCTION_GLOBAL_YEARLY:
    case STRIPE_PLAN_ID.DEPRECATED_DEVELOPMENT_GLOBAL_YEARLY: {
      if (newPlan === FareDropPlanGQL.Pro) return true;
      else return false;
      break;
    }
    case STRIPE_PLAN_ID.PRODUCTION_PRO_YEARLY:
    case STRIPE_PLAN_ID.DEVELOPMENT_PRO_YEARLY: {
      return false;
    }
    default: {
      throw new Error('Unknown plan');
    }
  }
};

export const isSubscriptionDowngrade = (
  currentPlan: STRIPE_PLAN_ID,
  newPlan: FareDropPlanGQL
) => {
  switch (currentPlan) {
    case STRIPE_PLAN_ID.PRODUCTION_LIMITED_YEARLY:
    case STRIPE_PLAN_ID.DEVELOPMENT_LIMITED_YEARLY: {
      return false;
    }
    case STRIPE_PLAN_ID.DEPRECATED_PRODUCTION_GLOBAL_YEARLY:
    case STRIPE_PLAN_ID.DEPRECATED_DEVELOPMENT_GLOBAL_YEARLY: {
      return newPlan === FareDropPlanGQL.Limited;
    }
    case STRIPE_PLAN_ID.PRODUCTION_PRO_YEARLY:
    case STRIPE_PLAN_ID.DEVELOPMENT_PRO_YEARLY: {
      return newPlan !== FareDropPlanGQL.Pro;
    }
    default: {
      throw new Error('Unknown plan');
    }
  }
};

export const isFrontendSubscriptionPromotion = (
  useLimitedUserPrePromotionDays?: boolean,
  options?: Omit<IIsSubscriptionPromotion, 'queryString' | 'prePromotionDays'>
) => {
  let input: IIsSubscriptionPromotion | undefined = undefined;
  if (useLimitedUserPrePromotionDays && PRE_PROMOTION_DAYS_LIMITED_USERS) {
    input = {
      ...options,
      prePromotionDays: PRE_PROMOTION_DAYS_LIMITED_USERS,
    };
  } else {
    input = {
      ...options,
      queryString: location.search,
    };
  }
  return isSubscriptionPromotion(input);
};

export const getRedeemSubscriptionNextLocation = (
  redeemResult: {
    idStripePlan: string;
    previousIdStripePlan?: string | null;
    creditAmount?: number | null;
  },
  hasActiveSubscription?: boolean
) => {
  const url = new URL(window.location.href);

  // Refresh the user
  url.searchParams.set('refreshAccount', 'true');
  if (
    hasActiveSubscription ||
    redeemResult.creditAmount ||
    redeemResult.previousIdStripePlan
  ) {
    url.searchParams.set(
      'nextPlan',
      stripePlanIDToFareDropPlan(redeemResult.idStripePlan)
    );

    if (redeemResult.previousIdStripePlan) {
      url.searchParams.set(
        'previousPlan',
        stripePlanIDToFareDropPlan(redeemResult.previousIdStripePlan)
      );
    }

    if (redeemResult.creditAmount) {
      url.searchParams.set(
        'creditAmount',
        (redeemResult.creditAmount / 100).toString()
      );
    }

    return {
      pathname: AllowedPath.REDEEM_SUCCESS,
      search: `${url.searchParams.toString()}`,
    };
  } else {
    const plan = stripePlanIDToFareDropPlan(redeemResult.idStripePlan);
    return {
      pathname:
        plan && isPaidPlan(plan)
          ? AllowedPath.GET_STARTED_WELCOME_PAID
          : AllowedPath.GET_STARTED_WELCOME_FREE,
      search: `${url.searchParams.toString()}`,
    };
  }
};

export const calculateDiscountPrice = (
  plan: SubscriptionPlanGQL,
  planDiscount?: number,
  discounts?: {
    [plan: string]: IQueryParamDiscounts;
  }
) => {
  const isPriceNumber = !isNaN(parseInt(plan.price));
  let price: string | undefined = undefined;

  // Don't apply discounts to "FREE" prices
  if (isPriceNumber) {
    if (discounts) {
      const discount = discounts[plan.planType];
      if (discount) {
        if (discount.type === 'percent') {
          price = Math.floor(
            parseInt(plan.price) * (1 - discount.value / 100)
          ).toFixed(0);
        } else if (discount.type === 'amount') {
          price = Math.floor(parseInt(plan.price) - discount.value).toFixed(0);
        }
      }
    } else if (planDiscount) {
      price = Math.floor(parseInt(plan.price) * (1 - planDiscount)).toFixed(0);
    } else if (plan.discount) {
      price = Math.floor(parseInt(plan.price) * (1 - plan.discount)).toFixed(0);
    }
  }

  return price;
};

export const isLimitedPlan = (plan?: STRIPE_PLAN_ID | string | null) => {
  if (plan && stripePlanIDByFareDropPlan(FareDropPlan.LIMITED) === plan) {
    return true;
  }
  return false;
};

export const getPlanName = (plan?: FareDropPlanGQL) => {
  if (!plan) return '';
  return _.startCase(
    $enum(FareDropPlanGQL).getKeyOrDefault(plan, '').toLowerCase()
  );
};

export const convertSubscriptionPlanTypeToGQL = (plan: SubscriptionPlan) => {
  const planGQL: SubscriptionPlanGQL = {
    ...plan,
    planType: $enum(FareDropPlanGQL).asValueOrThrow(plan.planType),
  };

  return planGQL;
};
