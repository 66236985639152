import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';

import { FareDropPlan } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  isGiftPromotion,
} from '@faredrop/utilities';

import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import CreatedBySection from '../components/CreatedBySection';
import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import { GetStartedBlock } from '../components/GetStartedBlock';
import HeroSection from '../components/HeroSection';
import HidingHeader from '../components/HidingHeader';
import HomePageDealsSection from '../components/HomePageDealsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import Loading from '../components/Loading';
import MobileHeader from '../components/MobileHeader';
import QuestionsSection from '../components/QuestionsSection';
import SocialProofSection from '../components/SocialProofSection';
import SubscriptionPlansSection from '../components/SubscriptionPlansSection';
import TrackVisibility from '../components/TrackVisibility';
import useAnalytics from '../hooks/analytics';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import useHandleNewSubscription from '../hooks/handleNewSubscription';
import './../theme/Home.css';
import './../theme/util.css';
import PromotionFreebieModal from '../components/PromotionFreebieModal';
import { IBannerOptions } from '../App';
import AsSeenIn from '../components/AsSeenIn';
import SubscriptionPlansTable from '../components/SubscriptionPlansTable';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
  hidePlans?: FareDropPlan[];
  plansParams?: [{ key: string; value: string }];
  bannerOptions?: IBannerOptions;
}

const StartHere: React.FC<ContainerProps> = ({
  onMenuClickHandler,
  hidePlans,
  plansParams,
  bannerOptions,
}) => {
  const {
    logAnalyticsLandingPageSectionView,
    logAnalyticsLandingPageEngagement,
    logAnalyticsOnboardingEngagement,
  } = useAnalytics();
  const {
    isLargeScreenSizeOrSmaller,
    isApp,
    isMediumScreenSizeOrSmaller,
    isSmallScreenSizeOrSmaller,
    isExtraLargeScreenSize,
  } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { isAuthenticated, isInitialized } = useAuth();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const [loading, setLoading] = useState(true);
  const { smoothScroll } = useLandingPageNavigation();
  const dynamicBannerContent = useDynamicBanner();
  const { handleNewSubscription, createPlanFromPlanParam, selectedPlanParam } =
    useHandleNewSubscription();
  const [promotionFreebieModalPlan, setPromotionFreebieModalPlan] =
    useState<FareDropPlan>();

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    } else if (isInitialized) {
      setLoading(false);
    }
  }, [isInitialized]);

  // If landing page has target query param, scroll to target section on load
  useIonViewDidEnter(() => {
    const queryParams = new URLSearchParams(location.search);
    const target = queryParams.get('target');
    if (target != null) {
      smoothScroll(`#${target}`);
    }
    // TODO: Eventually, we should move all of our sticky query params to live in session storage (if possible)
    // so that we can clean all of our URLs from query params that aren't useful for the user to see. Leaving
    // this commented for now...
    // Cleans the URL of any analytics query params
    // history.pushState(undefined, '', `${location.protocol}//${location.host}`);
  }, [location.search]);

  const bannerContent = bannerOptions?.text?.length ? (
    <p style={isApp ? { marginTop: 50 } : {}}>
      {!isFrontendSubscriptionPromotion() && (
        <span style={{ marginRight: '.5em' }}>🎉</span>
      )}
      <span>{bannerOptions.text}</span>
      {!isFrontendSubscriptionPromotion() && (
        <span style={{ marginLeft: '.5em' }}>🎉</span>
      )}
    </p>
  ) : undefined;

  return loading ? (
    <Loading />
  ) : (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
        promotionText={
          dynamicBannerContent ? dynamicBannerContent : bannerContent
        }
        bannerClickListener={() => {
          if (bannerOptions?.path) {
            goWithStickyParamsLocation({
              pathname: bannerOptions.path,
              search: bannerOptions.search,
            });
          } else {
            if (isGiftPromotion()) {
              goWithStickyParamsPath('gift');
            } else {
              smoothScroll('#register');
            }
          }
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader
            onMenuClickHandler={onMenuClickHandler}
            backToHomeListener={() => {
              window.location.reload();
            }}
          />
        ) : (
          <DesktopHeader
            loggedIn={false}
            logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
          />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => {
          setScrollYCurrent(e.detail.scrollTop);
        }}
      >
        <div style={{ paddingTop: '5em' }}>
          <TrackVisibility
            onVisible={async () => logAnalyticsLandingPageSectionView('hero')}
          >
            <HeroSection
              logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
              scrollTarget={'#register'}
            />
          </TrackVisibility>
          {!isExtraLargeScreenSize && <AsSeenIn />}
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('deals')
            }
          >
            <HomePageDealsSection
              logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('how_it_works')
            }
          >
            <HowItWorksSection />
          </TrackVisibility>
          {isExtraLargeScreenSize && <AsSeenIn />}
          <div id="register">
            <TrackVisibility
              onVisible={async () =>
                await logAnalyticsLandingPageSectionView('register')
              }
            >
              <div className="login-page register-home register-home-mobile">
                <h3 className="title-font" style={{ textAlign: 'center' }}>
                  Create an account to get started
                </h3>
                <div style={{ maxWidth: '500px' }}>
                  <GetStarted
                    onSubmit={async () => {
                      if (selectedPlanParam !== undefined) {
                        const subscriptionPlan =
                          createPlanFromPlanParam(selectedPlanParam);

                        if (
                          isFrontendSubscriptionPromotion() &&
                          PROMOTION_IS_SHOPIFY_INTEGRATION &&
                          selectedPlanParam !== FareDropPlan.Pro
                        ) {
                          setPromotionFreebieModalPlan(selectedPlanParam);
                        } else {
                          await handleNewSubscription(
                            subscriptionPlan,
                            undefined,
                            false,
                            plansParams
                          );
                        }
                      } else {
                        const params = new URLSearchParams(
                          window.location.search
                        );

                        plansParams?.forEach((p) => {
                          params.set(p.key, p.value);
                        });

                        goWithStickyParamsLocation({
                          pathname: '/plans',
                          search: `?${params.toString()}`,
                        });
                      }
                    }}
                    logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
                  />
                </div>
              </div>
            </TrackVisibility>
          </div>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('social_proof')
            }
          >
            <SocialProofSection
              logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('subscription_plans')
            }
          >
            {!isMediumScreenSizeOrSmaller && <SubscriptionPlansTable />}
            {isMediumScreenSizeOrSmaller && (
              <SubscriptionPlansSection
                logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
                hidePlans={hidePlans}
                reverse={isSmallScreenSizeOrSmaller}
              />
            )}
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('created_by')
            }
          >
            <CreatedBySection
              logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('questions')
            }
          >
            <QuestionsSection
              onCardOpen={async (questionId) =>
                await logAnalyticsLandingPageEngagement(questionId)
              }
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsLandingPageSectionView('get_started')
            }
          >
            <GetStartedBlock
              logAnalyticsEngagement={logAnalyticsLandingPageEngagement}
            />
          </TrackVisibility>
        </div>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsLandingPageSectionView('footer')
          }
        >
          <Footer
            bgColor="secondary"
            isVisible={true}
            onLinkPress={async (linkId) =>
              await logAnalyticsLandingPageEngagement(linkId)
            }
          />
        </TrackVisibility>
      </IonContent>
      <PromotionFreebieModal
        selectedPlan={promotionFreebieModalPlan}
        setIsModalActive={() => {
          setPromotionFreebieModalPlan(undefined);
        }}
        onSubmit={handleNewSubscription}
      />
    </IonPage>
  );
};

export default StartHere;
