import { IonButton, IonCol, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import './../theme/GetStartedBlock.css';
import './../theme/util.css';
import { GetStartedBlockText } from './GetStartedBlockText';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface GetStartedBlockProps {
  scrollTarget?: string;
  content?: React.ReactNode;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  callToAction?: string;
}

export const GetStartedBlock: React.FC<GetStartedBlockProps> = ({
  scrollTarget,
  content,
  logAnalyticsEngagement,
  callToAction,
}) => {
  const { smoothScroll } = useLandingPageNavigation();
  return (
    <IonRow
      className="get-started-block-container row-vertical-align"
      style={{ padding: '0', height: 'auto', marginTop: '3em' }}
    >
      <IonCol sizeXs="12" sizeLg="11" style={{ padding: 0 }}>
        <img
          className="get-started-block-bg"
          src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-white.png`}
        />
        <img
          className="get-started-block-bg-mobile"
          src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-white-east.png`}
        />
        <div className="wrapper">
          {content ?? <GetStartedBlockText />}
          <IonButton
            onClick={async () => {
              smoothScroll(scrollTarget ?? '#register');
              logAnalyticsEngagement &&
                (await logAnalyticsEngagement(
                  AnalyticsEngagementId.GET_STARTED_BLOCK_GET_STARTED
                ));
            }}
            className="get-started-button"
          >
            {callToAction ?? 'Get started now'}
          </IonButton>
        </div>
      </IonCol>
    </IonRow>
  );
};
