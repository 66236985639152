import { IonButton } from '@ionic/react';

import { useDevice } from '../hooks/useDevice';
import ResponsiveModal from './ResponsiveModal';

interface PerkTermsAndConditionsModalProps {
  title?: string;
  message?: string;
  onClose: () => void;
}

const UpgradeModal: React.FC<PerkTermsAndConditionsModalProps> = ({
  title,
  message,
  onClose,
}) => {
  const { isMediumScreenSizeOrSmaller } = useDevice();

  return (
    <ResponsiveModal active={message != null} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ textAlign: 'center' }}>{title}</h3>
        <p
          style={{ textAlign: 'center', lineHeight: '1.25em' }}
          dangerouslySetInnerHTML={{ __html: message ?? '' }}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: isMediumScreenSizeOrSmaller ? 'column' : 'row',
              marginTop: '1em',
            }}
          >
            <IonButton
              className="button no-caps"
              style={{
                marginLeft: isMediumScreenSizeOrSmaller ? undefined : '1em',
                opacity: 1.0,
                marginTop: isMediumScreenSizeOrSmaller ? '1em' : 0,
                width: '15em',
                height: '3em',
              }}
              onClick={async () => {
                onClose();
              }}
            >
              <p style={{ color: 'white' }}>Close</p>
            </IonButton>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default UpgradeModal;
