import {
  FareDropRole as FareDropRoleGQL,
  SeatClass as SeatClassGQL,
} from '@faredrop/graphql-sdk';

export const hasSeatClassAccessGQL = (
  userRoles: FareDropRoleGQL[] = [],
  seatClass: SeatClassGQL
): boolean => {
  if (userRoles.includes(FareDropRoleGQL.Admin)) {
    return true;
  } else if (
    seatClass === SeatClassGQL.Economy &&
    userRoles.some((role) => role === FareDropRoleGQL.Limited)
  ) {
    return true;
  } else if (
    seatClass === SeatClassGQL.Business &&
    userRoles.some((role) => role === FareDropRoleGQL.Pro)
  ) {
    return true;
  }

  return false;
};
