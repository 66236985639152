import { RefObject, useCallback, useEffect, useState } from 'react';

export function useContainerDimensions<T extends HTMLElement>(
  elementRef: RefObject<T>
) {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const handleResize = useCallback(() => {
    if (elementRef.current) {
      setWidth(elementRef.current?.offsetWidth);
      setHeight(elementRef.current?.offsetHeight);
    }
  }, [elementRef.current?.offsetWidth, elementRef.current?.offsetHeight]);

  useEffect(() => {
    if (elementRef.current) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (elementRef.current) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [elementRef.current, handleResize]);

  return { width, height };
}
