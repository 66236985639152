import { IonContent, IonPage } from '@ionic/react';
import moment from 'moment';

import { SHOPIFY_DISCOUNT_CODES } from '@faredrop/types';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  SHOPIFY_REDEEM_END,
  SUBSCRIPTION_PROMOTION_END,
  SUBSCRIPTION_PROMOTION_START,
} from '@faredrop/utilities';

import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import MobileHeader from '../components/MobileHeader';
import StoreBadges from '../components/StoreBadges';
import useAnalytics from '../hooks/analytics';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import './../theme/FAQ.css';
import './../theme/util.css';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const FAQ: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { logAnalyticsFAQ } = useAnalytics();
  const { isMediumScreenSizeOrSmaller, isLargeScreenSizeOrSmaller } =
    useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { smoothScroll } = useLandingPageNavigation();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const dynamicBannerContent = useDynamicBanner();

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <div
          id="faq-container"
          style={{
            padding: isMediumScreenSizeOrSmaller
              ? '5em 2em 2em 2em'
              : '5em 6em 6em 6em',
          }}
        >
          <h1 style={{ fontSize: 32, marginBottom: '1em' }}>FAQ</h1>
          <details>
            <summary onClick={async () => await logAnalyticsFAQ('sign_up')}>
              &emsp;How do I sign up to receive flight deals?
            </summary>
            <div className="item-content">
              <p>
                You can start receiving the best flight deals on the internet{' '}
                <a
                  onClick={() => {
                    smoothScroll('#register');
                  }}
                >
                  here!
                </a>
              </p>
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('economy_vs_business')}
            >
              &emsp;What subscription plans are offered?
            </summary>
            <div className="item-content">
              <p>
                FareDrop offers two subscription plan options. Our The Limited
                Plan will give you limited access to notifications to Economy
                class domestic and international flight deals that we find. All
                destination and deal preferences will also be locked on the
                Limited plan. Our Pro plan will alert you to all the best flight
                deals we find in Economy and Business class seats for both
                domestic and international flights. Pro subscribers can also
                fine-tune the notifications they receive with our destination
                and deal preferences.
              </p>
            </div>
          </details>
          <details>
            <summary onClick={async () => await logAnalyticsFAQ('pricing')}>
              &emsp;How much does FareDrop cost?
            </summary>
            <div className="item-content">
              <p>
                We offer our Limited Plan for free! Our Pro Plan is $99, billed
                annually.
              </p>
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('country_availability')
              }
            >
              &emsp;Which countries does FareDrop Service?
            </summary>
            <div className="item-content">
              FareDrop currently searches for deals departing from the United
              States and Canada to airports around the globe, including domestic
              airports in the U.S. and Canada. Although we are currently only
              sending flight deals for U.S. and Canada departure airports, we
              hope to send deals from international departure airports in the
              future.
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('deals_from_home_airport')
              }
            >
              &emsp;Will there be deals from my home airport?
            </summary>
            <div className="item-content">
              We send deals from most major international airports, so there is
              a good chance that your city and other near-by airports are
              available. Click&nbsp;
              <a
                style={{ fontFamily: 'nexa-bold' }}
                onClick={() => {
                  goWithStickyParamsLocation({
                    pathname: '/',
                    search: '?target=find-deals',
                  });
                }}
              >
                here
              </a>{' '}
              to be directed to our homepage where you can find which airports
              are currently supported and to see some of our recent deals
              departing from each origin.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('number_of_devices')}
            >
              &emsp;How many devices are supported for my account?
            </summary>
            <div className="item-content">
              You can log in to the FareDrop app on up to five devices. We
              believe that users should be able to share the same FareDrop
              account, as many of our deals are helping couples and families
              travel domestically and internationally together!
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('notifications')}
            >
              &emsp;How will I be notified?
            </summary>
            <div className="item-content">
              Users receive alerts in the FareDrop app, via email, or both! Our
              app also displays recent deals we have sent from your selected
              origin airports.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('type_of_deals')}
            >
              &emsp;What type of deals can I expect to receive?
            </summary>
            <div className="item-content">
              We are currently searching for the best round-trip, domestic and
              international flight deals in both Economy and Business class. Our
              deals are to destinations all around the globe, so users on our
              Pro Plan can adjust their settings to be notified of deals to only
              their selected regions and travel times.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('deal_cadence')}
            >
              &emsp;How often will I receive flight deal alerts?
            </summary>
            <div className="item-content">
              <p>
                Great FareDrop deals can happen anytime, but are also completely
                outside of our control! As such, we cannot guarantee the
                frequency you&apos;ll be alerted to new deals. There may be a
                week when you will not receive any new notifications, and then
                there will be others when we&apos;ll be alerting you to dozens
                of amazing fares. Rest assured, if there&apos;s a great deal
                that matches your preferences, you&apos;ll hear about it!
              </p>
              <p>
                We strive to send at least one amazing deal per origin each day,
                but will not waste your time with mediocre deals. FareDrops only
                includes truly great deals, so that you can travel more
                affordably.
              </p>
            </div>
          </details>
          <details>
            <summary onClick={async () => await logAnalyticsFAQ('book_deals')}>
              &emsp;How do I book FareDrop deals?
            </summary>
            <div className="item-content">
              We will send links to book the deals on Google Flights or
              elsewhere. We send sample dates for each month in which a deal is
              valid, allowing you to easily find the optimal dates for your
              travel plans. We recommend using Google Flights to search the
              calendar for availability, and then booking your tickets with the
              airline directly.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('deal_expiration')}
            >
              &emsp;How long will a deal last once I receive an alert?
            </summary>
            <div className="item-content">
              <p>
                We cannot control how long each FareDrop deal will remain
                bookable at our fantastically low prices, and our best deals are
                only available for a very short window of time. Some deals may
                be valid for several days, but we recommend users to book deals
                as soon as possible.
              </p>
              <p>
                All flights departing from the United States have a 24-hour free
                cancellation policy, which allows users in the US to lock in
                great deals immediately, risk-free.
              </p>
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('download_app')}
            >
              &emsp;Where can I download the app?
            </summary>
            <div className="item-content">
              <div style={{ maxWidth: '500px' }}>
                <StoreBadges />
              </div>
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('deal_time_before_departure')
              }
            >
              &emsp;How far in advance do you send out deals?
            </summary>
            <div className="item-content">
              We search for a wide-range of deals departing up to 11 months in
              the future. Some deals will have sample dates which depart
              immediately, but a majority of deals will have a range of dates
              departing from one to eight months in advance. Great flight prices
              can be found any time, so departure dates of our deals will vary.
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('change_deal_preferences')
              }
            >
              &emsp;How do I change my flight deal preferences?
            </summary>
            <div className="item-content">
              For Pro subscribers, you can change your preferences in the
              FareDrop app or by logging into your account dashboard on our
              website. Limited subscribers cannot change their flight deal
              preferences.
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('automatic_membership_renewal')
              }
            >
              &emsp;Does my membership automatically renew?
            </summary>
            <div className="item-content">
              Your membership automatically renews yearly, so that you never
              miss a deal.
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('domestic_us_canada_deals')
              }
            >
              &emsp;Does the Limited plan give me access to both U.S. and
              Canadian domestic deals?
            </summary>
            <div className="item-content">
              Our free Limited plan allows notifications for 1 home departure
              airport, either in the U.S. or Canada. The Limited plan will
              provide you with limited domestic and international deals from
              your home airport. To receive all deals from your departure
              airports, you can upgrade to our Pro Plan.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('local_currencies')}
            >
              &emsp;Do you support local currencies?
            </summary>
            <div className="item-content">
              Yes, all prices are by default in the departing airport&apos;s
              local currency. At the moment, FareDrop only supports U.S. and
              Canada departing airports. You can adjust your currency settings
              anytime in your account settings. If the departing airport&apos;s
              local currency and your account currency settings do not match,
              the prices will be converted based off of your account settings
              and the current currency exchange rate.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('cancel_subscription')}
            >
              &emsp;How do I cancel my account?
            </summary>
            <div className="item-content">
              You can easily cancel your account on our website. You can locate
              this option on the Plan and Billing settings screen on our
              website.
            </div>
          </details>
          <details>
            <summary
              onClick={async () =>
                await logAnalyticsFAQ('faredrop_vs_competition')
              }
            >
              &emsp;What makes FareDrop different from other flight deal
              services?
            </summary>
            <div className="item-content">
              We differentiate ourselves from other services by providing more
              granular controls of your travel availability throughout the year,
              allowing you to select regions you&apos;d like to travel to and
              allowing you to set maximum prices for the airfares you&apos;d
              like to receive. Our goal is to send you the best deals we can
              find each day - deals that align with your travel goals, rather
              than to flood you with notifications of every cheap airfare our
              system finds.
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('refund_policy')}
            >
              &emsp;What is your refund policy?
            </summary>
            <div className="item-content">
              {isFrontendSubscriptionPromotion(undefined, {
                postPromotionDays: 60,
              }) &&
                PROMOTION_IS_SHOPIFY_INTEGRATION && (
                  <p>
                    If purchasing a 50% off Pro Plan during our Summer sale, you
                    are not eligible for a refund on your FareDrop membership
                    once you’ve redeemed any of your perks, including your free
                    Travel Day Bundle or Lonely Planet eBook.
                  </p>
                )}
              <p>
                For all full-priced subscriptions, we offer a
                no-questions-asked, risk-free, 30-day refund policy. Simply send
                an email to{' '}
                <a
                  href="mailto:team@faredrop.com"
                  style={{ fontFamily: 'nexa-bold' }}
                >
                  team@faredrop.com
                </a>{' '}
                and request a refund from your subscribed email address. Full
                details are available in our{' '}
                <a href="/refund-policy">Refund Policy</a>.
              </p>
              <p>
                FareDrop stands behind our product and the value that it
                provides. If you are not receiving value from our amazing flight
                deals, we would love to hear your feedback and better understand
                how we can improve our services. You can reach us at{' '}
                <a
                  href="mailto:feedback@faredrop.com"
                  style={{ fontFamily: 'nexa-bold' }}
                >
                  feedback@faredrop.com
                </a>{' '}
                with your feedback.
              </p>
              <p>
                All FareDrop employees have traveled on amazing FareDrop trips,
                and we have personally seen how the service more than pays for
                itself if you are able to book one international trip per year.
              </p>
            </div>
          </details>
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('t-shirt-eligibility')}
            >
              &emsp;Am I eligible for the{' '}
              {/* NOTE: Current promotion is not for a t-shirt, but for a Travel Day Bundle */}
              {/* {isFrontendSubscriptionPromotion() && PROMOTION_IS_SHOPIFY_INTEGRATION
                ? 'free'
                : 'discounted'}{' '} */}
              discounted airport t-shirt?
            </summary>
            <div className="item-content">
              {/* NOTE: Current promotion is not for a t-shirt, but for a Travel Day Bundle */}
              {/* {isFrontendSubscriptionPromotion() && PROMOTION_IS_SHOPIFY_INTEGRATION && (
                <p>
                  All first time FareDrop Pro customers, including those who have
                  upgraded from the Limited plan, are eligible to receive a free
                  FareDrop t-shirt during the sale (
                  {moment(SUBSCRIPTION_PROMOTION_START)
                    .add(1, 'day')
                    .format('MMMM Do YYYY')}{' '}
                  through{' '}
                  {moment(SUBSCRIPTION_PROMOTION_END)
                    .subtract(1, 'day')
                    .format('MMMM Do YYYY')}
                  ). As soon as a Pro subscription is purchased during the sale,
                  you should receive an email with a link to redeem your
                  free t-shirt. Make sure to use your FareDrop account email
                  address and apply the{' '}
                  {SHOPIFY_DISCOUNT_CODES.BFCM23_SHIRT_FREE} and{' '}
                  {SHOPIFY_DISCOUNT_CODES.BFCM23_SHIRT_SHIPPING} discount codes
                  at checkout to redeem your free t-shirt.
                </p>
              )} */}
              <p>
                If you are a current Pro FareDrop subscriber, you can purchase
                the t-shirt at a discounted price of $20. To purchase a
                discounted t-shirt, please use your FareDrop account email
                address and {SHOPIFY_DISCOUNT_CODES.SHIRT_DISCOUNTED} discount
                code at checkout. Limit 1 per customer.
              </p>
              {/* NOTE: Current promotion is not for a t-shirt, but for a Travel Day Bundle */}
              {/* {isFrontendSubscriptionPromotion() &&
                PROMOTION_IS_SHOPIFY_INTEGRATION && (
                  <p>
                    Free and discounted T-Shirts must be redeemed by December
                    11th, 2023.
                  </p>
                )} */}
            </div>
          </details>
          {isFrontendSubscriptionPromotion(undefined, {
            postPromotionDays: 60,
          }) &&
            PROMOTION_IS_SHOPIFY_INTEGRATION && (
              <>
                <details>
                  <summary
                    onClick={async () =>
                      await logAnalyticsFAQ('travel-day-bundle-eligibility')
                    }
                  >
                    &emsp;Am I eligible for the free Travel Day Bundle?
                  </summary>
                  <div className="item-content">
                    <p>
                      All first time FareDrop Pro customers, including those who
                      have upgraded from the Limited plan, are eligible to
                      receive a free Travel Day Bundle during the sale (
                      {moment(SUBSCRIPTION_PROMOTION_START)
                        .add(1, 'day')
                        .format('MMMM Do YYYY')}{' '}
                      through{' '}
                      {moment(SUBSCRIPTION_PROMOTION_END)
                        .subtract(1, 'day')
                        .format('MMMM Do YYYY')}
                      ). As soon as a Pro subscription is purchased during the
                      sale, you should receive an email with a link to redeem
                      your free Travel Day Bundle. Make sure to use your
                      FareDrop account email address and apply the{' '}
                      {SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_FREE} and{' '}
                      {SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_SHIPPING} discount
                      codes at checkout to redeem your free Travel Day Bundle.
                    </p>
                    <p>
                      If you are a current Pro FareDrop subscriber, you can
                      purchase the Travel Day Bundle at a discounted price of
                      $20. To purchase a discounted Travel Day Bundle, please
                      use your FareDrop account email address and{' '}
                      {SHOPIFY_DISCOUNT_CODES.SUMMER24_BUNDLE_DISCOUNTED}{' '}
                      discount code at checkout.
                    </p>
                    <p>
                      Free and discounted Travel Day Bundles must be redeemed by
                      {moment(SHOPIFY_REDEEM_END)
                        .subtract(1, 'day')
                        .format('MMMM Do YYYY')}
                      . Limit 1 per customer.
                    </p>
                  </div>
                </details>
                <details>
                  <summary
                    onClick={async () =>
                      await logAnalyticsFAQ('free-travel-day-bundle-delivery')
                    }
                  >
                    &emsp;When will I receive my free Travel Day Bundle?
                  </summary>
                  <div className="item-content">
                    <p>
                      {/* After subscribing to the Pro plan, you should receive an email
                    with instructions on how to redeem your free airport t-shirt. Once you
                    have placed an order for your t-shirt, you should receive an
                    email with a tracking link. A delivery estimate will be
                    provided based on your address. If you do not receive an email
                    with instructions on how to redeem your free t-shirt, please
                    email{' '} */}
                      After subscribing to the Pro plan, you should receive an
                      email with instructions on how to redeem your free Travel
                      Day Bundle. Once you have placed an order for your Travel
                      Day Bundle, you should receive your Travel Day Bundle
                      within 15 business days. If you do not receive your Travel
                      Day Bundle within 15 business days, please email{' '}
                      <a
                        href="mailto:team@faredrop.com"
                        style={{ fontFamily: 'nexa-bold' }}
                      >
                        team@faredrop.com
                      </a>{' '}
                      . Free and discounted Travel Day Bundles must be redeemed
                      by{' '}
                      {moment(SHOPIFY_REDEEM_END)
                        .subtract(1, 'day')
                        .format('MMMM Do YYYY')}
                      . Limit 1 per customer.
                    </p>
                  </div>
                </details>
              </>
            )}
          <details>
            <summary
              onClick={async () => await logAnalyticsFAQ('perk-eligibility')}
            >
              &emsp;Am I eligible for the free Lonely Planet Destination Guide
              eBook?
            </summary>
            <div className="item-content">
              <p>
                All new and existing FareDrop Pro subscribers are eligible to
                receive a free Lonely Planet destination guide eBook.
                Instructions on how to redeem your eBook can be found on our{' '}
                <a href="/perks" style={{ fontFamily: 'nexa-bold' }}>
                  Perks
                </a>{' '}
                page. Limit 1 per customer.
              </p>
            </div>
          </details>
          <div
            style={{
              width: '100%',
              marginTop: '6em',
              fontFamily: 'nexa',
              fontSize: 16,
              display: 'flex',
            }}
          >
            <p
              style={{ margin: 'auto', lineHeight: '2em', textAlign: 'center' }}
            >
              Still have questions?
              <br />
              Contact our team via email at{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                href="mailto:team@faredrop.com"
              >
                team@faredrop.com
              </a>
              &nbsp;or write us at:
              <br />
              <small>
                2348 W Andrew Johnson Highway, Box #214, Morristown, TN 37814
              </small>
            </p>
          </div>
        </div>
        <Footer bgColor="primary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default FAQ;
