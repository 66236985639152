import {
  SubscriptionPlan,
  FareDropPlan as GQLFareDropPlan,
} from '@faredrop/graphql-sdk';
import { AllowedPath, IUserBilling } from '@faredrop/types';
import useHistoryWithStickyParams from './historyWithStickyParams';
import useStripeHook from '../hooks/useStripe';
import useAnalytics from './analytics';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useAuth from './auth';
import usePresentToast from './presentToast';
import usePlans from './usePlans';
import { useCodeQueryParam } from './useCodeQueryParam';
import { useEffect, useState } from 'react';
import { useDisplayDiscounts } from './useDisplayDiscounts';
import { $enum } from 'ts-enum-util';

const useHandleNewSubscription = () => {
  type FareDropPlanType = GQLFareDropPlan.Global | GQLFareDropPlan.Pro;
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const {
    createCheckoutSession,
    registerForLimitedSubscription,
    isCouponValid,
  } = useStripeHook();
  const { logAnalyticsOnboardingEngagement, logAnalyticsCheckout } =
    useAnalytics();
  const {
    couponOrGiftCodeQueryParam,
    couponQueryParam,
    couponsAreInitialized,
    removeCouponQueryParam,
  } = useCodeQueryParam();
  const { isAuthenticated, refreshSession } = useAuth();
  const { presentError, presentWarning } = usePresentToast();
  const { plans } = usePlans(couponOrGiftCodeQueryParam);
  const [selectedPlanParam, setSelectedPlanParam] =
    useState<FareDropPlanType>();
  const [hidePlansParam, setHidePlansParam] = useState<GQLFareDropPlan[]>();
  const { discounts } = useDisplayDiscounts();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const selectedPlanQueryParam = params.get('selected-plan');
    const rawHidePlansQueryParam = params.get('hide-plans');
    const hidePlansQueryParam = rawHidePlansQueryParam
      ? rawHidePlansQueryParam.split(',')
      : [];

    if (
      selectedPlanQueryParam !== null &&
      (selectedPlanQueryParam === GQLFareDropPlan.Global ||
        selectedPlanQueryParam === GQLFareDropPlan.Pro) &&
      !hidePlansQueryParam?.includes(selectedPlanQueryParam)
    ) {
      const typedParam: FareDropPlanType =
        selectedPlanQueryParam as FareDropPlanType;

      setSelectedPlanParam(typedParam);
    }

    const plansToHide: GQLFareDropPlan[] = [];
    $enum(GQLFareDropPlan).forEach((plan) => {
      if (hidePlansQueryParam?.includes(plan)) plansToHide.push(plan);
    });
    if (plansToHide.length) {
      setHidePlansParam(plansToHide);
    }
  }, [window.location.search]);

  const createPlanFromPlanParam = (
    planType: FareDropPlanType
  ): SubscriptionPlan | undefined => {
    if (plans) {
      for (const plan of plans) {
        if (plan.planType === planType) {
          return plan;
        }
      }
    }
    return undefined;
  };

  const checkCouponValidity = async () => {
    let isValid = false;
    if (couponsAreInitialized) {
      if (couponQueryParam) {
        try {
          const response = await isCouponValid(couponQueryParam);
          if (response && response.isValid) {
            isValid = true;
          } else {
            presentWarning('Invalid coupon code.');
            removeCouponQueryParam();
          }
        } catch (error) {
          presentWarning('Failed to fetch coupon details');
        }
      }
    }
    return isValid;
  };

  const handleNewSubscription = async (
    plan?: SubscriptionPlan,
    checkedCoupon?: string,
    skipCheckValid = true,
    cancelQueryParams?: [{ key: string; value: string }]
  ) => {
    let coupon: string | undefined = undefined;
    let result: boolean | undefined = undefined;
    const nonPaidPlan = plan?.planType === GQLFareDropPlan.Limited;
    try {
      // When we come from the plans page (where coupon is already checked), we don't need to check again
      // if skipCheckValid is true, that means we already should have a pre validated coupon
      // use that checkedCoupon instead
      if (skipCheckValid === false) {
        result = await checkCouponValidity();
        coupon = result ? couponQueryParam : undefined;
      } else {
        coupon = checkedCoupon;
      }
      const promises: Promise<void | IUserBilling>[] = [];
      if (nonPaidPlan) {
        promises.push(
          (async () => {
            const queryParams = new URL(window.location.toString()).search;
            goWithStickyParamsLocation({
              pathname: AllowedPath.GET_STARTED_WELCOME_FREE,
              search: '?refreshAccount',
            });
            await registerForLimitedSubscription(queryParams);
          })()
        );
      } else {
        if (plan) {
          let appliedCoupon = coupon;
          if (discounts) {
            appliedCoupon = discounts[plan.planType] ? coupon : undefined;
          }
          promises.push(
            ...[
              logAnalyticsCheckout(plan),
              createCheckoutSession(
                plan.planType,
                AllowedPath.GET_STARTED_WELCOME_PAID.slice(1),
                [
                  { key: 'refreshAccount', value: 'true' },
                  { key: 'newPlan', value: plan.id },
                ],
                'plans',
                cancelQueryParams,
                appliedCoupon
              ),
            ]
          );
        }
      }

      logAnalyticsOnboardingEngagement &&
        promises.push(
          logAnalyticsOnboardingEngagement(
            `choose_${
              plan ? plan.planType : undefined
            }_plan` as AnalyticsEngagementId
          )
        );
      await Promise.all(promises);
    } catch (error) {
      if (
        nonPaidPlan &&
        (error as { message?: string }).message?.includes(
          'Stripe customer already exists'
        )
      ) {
        await refreshSession();
        if (isAuthenticated) {
          goWithStickyParamsPath(AllowedPath.DEALS);
        } else {
          goWithStickyParamsPath(AllowedPath.LOGOUT);
        }
      }
      presentError(`${(error as Error).message.substring(0, 30)}...`);
    }
  };

  return {
    handleNewSubscription,
    createPlanFromPlanParam,
    selectedPlanParam,
    hidePlansParam,
  };
};
export default useHandleNewSubscription;
