import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  useIonViewDidEnter,
} from '@ionic/react';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AirfareProvider,
  Airport,
  Deal,
  NotificationFilters,
  SeatClass,
  SubscriptionStatus,
} from '@faredrop/graphql-sdk';

import DealsSection, { DealsSectionHandle } from '../components/DealsSection';
import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import Loading from '../components/Loading';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import NotificationSettingsModal from '../components/NotificationSettingsModal';
import NotificationsFabButton from '../components/NotificationsFabButton';
import useAuth from '../hooks/auth';
import useDeals, { IActiveOrigins } from '../hooks/deals';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useUserDeals from '../hooks/userDeals';
import useMapBox from '../hooks/useMapBox';
import useTabs from '../hooks/useTabs';
import StorageService from '../services/StorageService';
import { NotificationSettingsStep } from '../types/types';
import './../theme/DealsDashboard.css';
import './../theme/ProfileSettings.css';
import './../theme/util.css';
import useUser from '../hooks/user';
import useUserPastDeals from '../hooks/userPastDeals';
import usePushNotifications from '../hooks/usePushNotifications';
import useAnalytics from '../hooks/analytics';
import NoDealsText, { NoDeals } from '../components/NoDealsText';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import { ensureDestinationsStackOrder } from '../utilities/deals-utilities';
import StoreBadges from '../components/StoreBadges';
import MobileHeader from '../components/MobileHeader';
import { IDestinationDeals } from '../components/DealCard';
import useSearchAirports from '../hooks/useSearchAirports';
import { getPlanName, isNumberAndLessThanOrEqualTo } from '@faredrop/utilities';
import { AllowedPath, FareDropPlan, FareDropRole } from '@faredrop/types';
import { Tab } from '../contexts/tabContext';
import useLogError from '../hooks/logError';
import usePresentToast from '../hooks/presentToast';
import { getAssetsBaseUrl } from '@faredrop/utilities';
import { IPromotion } from '../components/PromotionCard';
import UpgradeModal from '../components/UpgradeModal';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
  LOCKED_DEAL_MESSAGE,
} from '../utilities/constants';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import DealSearch from '../components/DealSearch';
import useMounted from '../hooks/useMounted';
import { isLimitedPlan } from '../utilities/plans-utilities';
import { useDealsDashboard } from '../hooks/useDealsDashboard';
import { DealsDashboardList } from '../contexts/dealsDashboardContext';
import useKlaviyo from '../hooks/useKlaviyo';

const DealsDashboard: React.FC = () => {
  const { logAnalyticsViewDeal, appTrackingStatus } = useAnalytics();
  const { user } = useAuth();
  const { removeNotification, registerAndListen } = usePushNotifications();
  const {
    isApp,
    isLargeScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
    isExtraLargeScreenSize,
  } = useDevice();
  const { logError } = useLogError();
  const { presentError } = usePresentToast();
  const { trackDealCardClickKlaviyoEvent } = useKlaviyo();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [notificationsModalActive, setNotificationsModalActive] =
    useState(false);
  const history = useHistory();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { getSharedDeal, filterAndGroupDeals } = useDeals();
  const [
    notificationsSettingsModalActive,
    setNotificationsSettingsModalActive,
  ] = useState(false);
  const [notificationsSettingsModalStep, setNotificationsSettingsModalStep] =
    useState(NotificationSettingsStep.LOADING);
  const [originAutoAddedAirport, setOriginAutoAddedAirport] = useState<
    Airport | undefined
  >(undefined);
  const [arePageAnimationsComplete, setArePageAnimationsComplete] =
    useState(false);
  const [upgradeMessage, setUpgradeMessage] = useState<string>();
  const isMounted = useMounted();
  const {
    lastViewedDealIndex,
    lastViewedDealList,
    setLastViewedDealIndex,
    setLastViewedDealList,
  } = useDealsDashboard();

  const { getLocalOrigins } = useSearchAirports();

  const domesticSliderRef = useRef<DealsSectionHandle>(null);
  const economySliderRef = useRef<DealsSectionHandle>(null);
  const businessSliderRef = useRef<DealsSectionHandle>(null);
  const filteredSliderRef = useRef<DealsSectionHandle>(null);
  const pastSliderRef = useRef<DealsSectionHandle>(null);

  const userState = useUser();
  const userProfile = userState.user?.profile;
  const lastMobileLogin = userState.user?.tracking.lastMobileLogin;
  const userConfigOrigins = userState.user?.configuration?.origins ?? [];

  const lockedAirfareRequirements = `The search feature is only available on the ${getPlanName(
    FareDropPlan.PRO
  )} Plan.`;
  const lockedAirfareMessage = `${lockedAirfareRequirements} ${
    HIDE_MEMBERSHIP_LINKS
      ? HIDE_UPGRADE_MESSAGE
      : `Please upgrade to view this airfare's details.`
  }`;

  const economyDealsState = useUserDeals(SeatClass.Economy);
  const businessDealsState = useUserDeals(SeatClass.Business);
  const pastDealsState = useUserPastDeals();

  const noInternet =
    economyDealsState.timeout &&
    businessDealsState.timeout &&
    pastDealsState.timeout;

  const filteredEconomyDeals = filterAndGroupDeals(
    economyDealsState.deals ?? []
  );
  const filteredBusinessDeals = filterAndGroupDeals(
    businessDealsState.deals ?? []
  );

  // We need to persist origins that are on "top" so data fetches don't overwrite them
  const activeEconomyOrigins = useRef<IActiveOrigins>({});
  const economyDealsInOrder = ensureDestinationsStackOrder(
    filteredEconomyDeals.updated,
    activeEconomyOrigins.current
  );

  const economyDeals: {
    deals: Deal[];
    activeDeal: Deal;
    keyDeal: Deal;
  }[] = [];

  // TODO: Build list of domestic deals from unfiltered economy
  // deals list instead of filtered economy deals list
  const domesticDeals: {
    deals: Deal[];
    activeDeal: Deal;
    keyDeal: Deal;
  }[] = [];
  economyDealsInOrder.map((dealObj) => {
    if (
      dealObj.activeDeal.provider === AirfareProvider.GoogleFlightsDomestic ||
      dealObj.keyDeal.provider === AirfareProvider.GoogleFlightsDomestic
    ) {
      domesticDeals.push(dealObj);
    } else {
      economyDeals.push(dealObj);
    }
  });

  const adsTypes = [
    DealsDashboardList.INTERNATIONAL_ECONOMY,
    DealsDashboardList.INTERNATIONAL_BUSINESS,
    DealsDashboardList.DOMESTIC_ECONOMY,
    DealsDashboardList.FILTERED,
    DealsDashboardList.PAST,
  ];
  let adsPromotions: { [key: string]: IPromotion[] | undefined } = {};
  adsTypes.forEach((type) => {
    adsPromotions = {
      ...adsPromotions,
      [type]: process.env[`REACT_APP_KLAVIYO_FORMS_${type}_IDS`]
        ?.split(',')
        .map((ref) => ({
          type: 'klaviyo-form',
          ref,
        })),
    };
  });

  // We need to persist origins that are on "top" so data fetches don't overwrite them
  const activeBusinessOrigins = useRef<IActiveOrigins>({});
  const businessDeals = ensureDestinationsStackOrder(
    filteredBusinessDeals.updated,
    activeBusinessOrigins.current
  ).filter(
    (dealObj) =>
      dealObj.activeDeal.provider !== AirfareProvider.GoogleFlightsDomestic &&
      dealObj.keyDeal.provider !== AirfareProvider.GoogleFlightsDomestic
  );

  // We need to persist origins that are on "top" so data fetches don't overwrite them
  const activeFilteredOrigins = useRef<IActiveOrigins>({});
  const filteredDeals = ensureDestinationsStackOrder(
    filteredEconomyDeals.filtered
      .concat(filteredBusinessDeals.filtered)
      .sort((a, b) => {
        if (!a.activeDeal.created && !b.activeDeal.created) return 0;
        else if (!a.activeDeal.created) return -1;
        else if (!b.activeDeal.created) return 1;
        return b.activeDeal.created.localeCompare(a.activeDeal.created);
      }),
    activeFilteredOrigins.current
  );

  const pastDeals = (pastDealsState.deals ?? []).map((pastDeal) => {
    const destinationDeals: IDestinationDeals = {
      deals: [pastDeal],
      activeDeal: pastDeal,
      keyDeal: pastDeal,
    };
    return destinationDeals;
  });

  const dealsInitialized =
    !economyDealsState.isInitializing &&
    !businessDealsState.isInitializing &&
    !pastDealsState.isInitializing;

  // Source mapbox from CDN if in App
  useMapBox();

  // NOTE: There is a potential conflict between the page load animation and the deal modal open animation
  // When this occurs, the page load animation wins and the deal modal thinks it's open, but really it's not since the animation failed
  // This causes deal cards to be shown but not be clickable
  // To ensure there is no animation conflict, we wait for the page animations to be finished before we show the deal modal
  useIonViewDidEnter(() => {
    if (isMounted) {
      setArePageAnimationsComplete(true);
    }
  });

  useEffect(() => {
    updateSelectedTab(Tab.DEALS).catch((error) => {
      console.warn('Failed to update selected tab', Tab.DEALS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  useEffect(() => {
    // This will only be set when directed to the deals dash from the Welcome screen
    // If we get to the deals dash with more than one origin set on the user, we've
    // added a tier 1 or tier 2 airport to the user's origins by default on sign up
    // and should notify them about that. This will only be shown on the first
    // load of the Deals Dash after the Welcome screen
    if (
      !isApp &&
      location.state &&
      (location.state as Record<string, string | number | boolean>).newUser ===
        true &&
      userState.user?.configuration.origins &&
      userState.user?.configuration.origins.length > 1
    ) {
      userState.user?.configuration.origins.map((o) => {
        getLocalOrigins()
          .then((origins) => {
            const origin = origins.find((origin) => origin.iata === o.iata);
            if (origin?.tier && origin.tier < 3) {
              setNotificationsModalActive(true);
              setOriginAutoAddedAirport(origin);
            }
          })
          .catch((err) => {
            logError(
              'Failed to get local origins for initial deals dashboard view ',
              err && (err as Error).message
                ? (err as Error).message
                : 'Error or error message is undefined'
            ).catch((error) =>
              console.warn(
                'Failed to send to log error endpoint for getting local origins',
                error
              )
            );
          });
      });
    }
  }, [location.state, userState.user?.configuration.origins]);

  // If the user is on mobile or tablet...
  // ... and if the user has not yet already granted or denied Push Notifications permissions OR not yet seen the modal,
  // show them the mobile notifications settings slide-up modal in the "Enable push" state
  // ... else if the user has already granted or denied Push Notifications permissions
  // show them the mobile notifications settings slide-up modal in the "Set Notification Methods" state
  useEffect(() => {
    const checkNotificationsSettings = async () => {
      const storageServiceInstance = StorageService.getInstance();
      const usersThatHaveSetPermissions =
        ((await storageServiceInstance.getData(
          'usersThatHaveSetPermissions'
        )) ?? []) as string[];

      if (
        userState.user?.profile.email &&
        !usersThatHaveSetPermissions.includes(userState.user.profile.email)
      ) {
        const permissionStatus = (await PushNotifications.checkPermissions())
          .receive;
        if (
          permissionStatus === 'granted' &&
          !userState.isThisDeviceRegisteredForPushNotifications
        ) {
          usersThatHaveSetPermissions.push(userState.user.profile.email);
          await storageServiceInstance.setData(
            'usersThatHaveSetPermissions',
            usersThatHaveSetPermissions
          );
          await registerAndListen();
        } else if (permissionStatus === 'prompt') {
          setNotificationsSettingsModalStep(
            NotificationSettingsStep.ENABLE_PUSH
          );
          setNotificationsSettingsModalActive(true);
        }
      }
    };

    if (isApp && userState.user?.profile.email && !userState.isInitializing) {
      checkNotificationsSettings().catch(() =>
        presentError(
          'Failed to check push notification permission - please refresh and try again'
        )
      );
    }
  }, [userState.user?.profile.email, userState.isInitializing]);

  // Determines if we should pop out the notifications modal on desktop if the user
  // has not already used the mobile app and they have not yet seen the notifications modal
  useEffect(() => {
    StorageService.getInstance()
      .getData('hasSeenNotificationSettingsModal')
      .then((hasSeenNotificationSettingsModal) => {
        const showModal = !hasSeenNotificationSettingsModal && !lastMobileLogin;
        const criteriaMetAndJustLoggedIn = showModal === true;

        if (criteriaMetAndJustLoggedIn) {
          StorageService.getInstance()
            .setData('hasSeenNotificationSettingsModal', true)
            .then((criteriaMetAndJustLoggedIn) => {
              setNotificationsModalActive(criteriaMetAndJustLoggedIn);
            })
            .catch((err) =>
              logError(
                'Failed to set hasSeenNotificationSettingsModal in storage',
                err && (err as Error).message
                  ? (err as Error).message
                  : 'Error or error message is undefined'
              ).catch((error) =>
                console.warn(
                  'Failed to send to log error endpoint for setting hasSeenNotificationSettingsModal in storage',
                  error
                )
              )
            );
        }
      })
      .catch((err) =>
        logError(
          'Failed to get hasSeenNotificationSettingsModal in storage',
          err && (err as Error).message
            ? (err as Error).message
            : 'Error or error message is undefined'
        ).catch((error) =>
          console.warn(
            'Failed to send to log error endpoint for getting hasSeenNotificationSettingsModal from storage',
            error
          )
        )
      );
  }, []);

  useLayoutEffect(() => {
    if (lastViewedDealList) {
      setTimeout(() => {
        document
          .getElementById(`deals-section-${lastViewedDealList}`)
          ?.scrollIntoView({
            block: 'center',
          });
      }, 1); // Ugly.. but works

      if (lastViewedDealIndex != null) {
        let sectionRef: DealsSectionHandle | null = null;
        switch (lastViewedDealList) {
          case DealsDashboardList.INTERNATIONAL_ECONOMY: {
            sectionRef = economySliderRef.current;
            break;
          }
          case DealsDashboardList.INTERNATIONAL_BUSINESS: {
            sectionRef = businessSliderRef.current;
            break;
          }
          case DealsDashboardList.DOMESTIC_ECONOMY: {
            sectionRef = domesticSliderRef.current;
            break;
          }
          case DealsDashboardList.FILTERED: {
            sectionRef = filteredSliderRef.current;
            break;
          }
          case DealsDashboardList.PAST: {
            sectionRef = pastSliderRef.current;
            break;
          }
        }

        sectionRef?.setIndex(lastViewedDealIndex);
      }
    }
  }, [dealsInitialized]);

  type LocationState = {
    deal?: Deal;
  };

  const locationStateDeal: Deal | undefined = (location.state as LocationState)
    ?.deal;
  useEffect(() => {
    if (locationStateDeal?.idAirfareSource && arePageAnimationsComplete) {
      const dealsToSearch =
        locationStateDeal.seatClass === SeatClass.Economy
          ? economyDeals
          : businessDeals;
      const destinationDeals = dealsToSearch.find(
        (deals) =>
          deals.activeDeal.destinationIATA === locationStateDeal.destinationIATA
      );

      const selectedDeal = {
        deals: destinationDeals?.deals ?? [locationStateDeal],
        activeDeal: locationStateDeal,
        keyDeal: destinationDeals?.keyDeal ?? locationStateDeal,
      };

      // Also need to set active deal in activeDealsDictionary
      const activeDeals =
        locationStateDeal.seatClass === SeatClass.Economy
          ? activeEconomyOrigins.current
          : activeBusinessOrigins.current;
      if (locationStateDeal.destinationIATA) {
        activeDeals[locationStateDeal.destinationIATA] =
          locationStateDeal.originIATA;
      }

      selectDeal(selectedDeal).catch((error) => {
        console.warn('Failed to select deal', error);
        throw error; // Show uh-oh screen
      }); // Async - Fire and forget
    }
  }, [locationStateDeal, arePageAnimationsComplete]);

  useEffect(() => {
    const setOpenDeal = async () => {
      // After refreshing deals, check query params to see if we should open up specific deal
      const idAirfareSource = queryParams.get('idAirfareSource');
      const originIATA = queryParams.get('originIATA');
      const destinationIATA = queryParams.get('destinationIATA');
      const seatClass = queryParams.get('seatClass');
      const sharedBy = queryParams.get('sharedBy');
      const minPrice = queryParams.get('price');
      const signature = queryParams.get('signature');
      if (
        idAirfareSource != null &&
        originIATA != null &&
        destinationIATA != null &&
        seatClass != null &&
        sharedBy != null &&
        minPrice != null &&
        signature != null
      ) {
        const sharedDeal = await getSharedDeal(
          idAirfareSource,
          originIATA,
          destinationIATA,
          seatClass.toUpperCase() as SeatClass,
          sharedBy,
          parseInt(minPrice),
          signature
        );
        await selectDeal({
          deals: [sharedDeal as Deal],
          activeDeal: sharedDeal as Deal,
          keyDeal: sharedDeal as Deal,
        });
      }
    };

    if (dealsInitialized) {
      setOpenDeal().catch((error) => {
        console.warn('Failed to open deal', error);
        throw error; // Show uh-oh screen
      });
    }
  }, [dealsInitialized]);

  const onSlideTransition = async (index: number) => {
    if (index > pastDeals.length - 5 && !pastDealsState.isLoading) {
      await pastDealsState.loadNextPage();
    }
  };

  const selectDeal = async (destinationDeals: IDestinationDeals) => {
    const activeDeal = destinationDeals.activeDeal;
    // Async - fire and forget
    logAnalyticsViewDeal(destinationDeals.activeDeal).catch(() => {
      console.warn('Failed to track deal click');
    });
    // NOTE: Unfortunately, we can't make klaviyo hook calls in analytics context due to dependency order
    Capacitor.getPlatform() !== 'ios' || appTrackingStatus === 'authorized'
      ? trackDealCardClickKlaviyoEvent(destinationDeals.activeDeal)
      : undefined;

    goWithStickyParamsLocation({
      pathname: AllowedPath.DEAL_DETAILS,
      search: `?dda=${activeDeal.dda}`,
    });
  };

  const isEconomyFiltered =
    userState.user?.configuration?.notificationFilters?.find(
      (filter) => filter.enabled && filter.name === NotificationFilters.Economy
    );
  const isBusinessFiltered =
    userState.user?.configuration?.notificationFilters?.find(
      (filter) => filter.enabled && filter.name === NotificationFilters.Business
    );

  let domesticEmptyComponent: JSX.Element | undefined;
  if (userConfigOrigins.length === 0) {
    domesticEmptyComponent = <NoDealsText type={NoDeals.NO_ORIGINS} />;
  } else if (isEconomyFiltered) {
    domesticEmptyComponent = <NoDealsText type={NoDeals.FILTERED} />;
  }

  let economyEmptyComponent: JSX.Element | undefined;
  if (userConfigOrigins.length === 0) {
    economyEmptyComponent = <NoDealsText type={NoDeals.NO_ORIGINS} />;
  } else if (isEconomyFiltered) {
    economyEmptyComponent = <NoDealsText type={NoDeals.FILTERED} />;
  }

  let businessEmptyComponent: JSX.Element = (
    <NoDealsText text="Business class deals happen less frequently than deals in economy. There are no active deals at this time, but check back later!" />
  );
  if (userConfigOrigins.length === 0) {
    businessEmptyComponent = <NoDealsText type={NoDeals.NO_ORIGINS} />;
  } else if (isBusinessFiltered) {
    businessEmptyComponent = <NoDealsText type={NoDeals.FILTERED} />;
  }

  const userRoles = user?.roles ?? [];

  const domesticSection = (
    <DealsSection
      ref={domesticSliderRef}
      onRefresh={async () => {
        await economyDealsState.mutate();
      }}
      deals={domesticDeals}
      onSelect={async (destinationDeals, index, _activeIndex, isDealLocked) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.DOMESTIC_ECONOMY);

        if (isDealLocked) {
          setUpgradeMessage(LOCKED_DEAL_MESSAGE);
        } else {
          await selectDeal(destinationDeals);
        }
      }}
      onVisible={async (destinationDeals) => {
        await removeNotification(destinationDeals);
      }}
      onChange={async (destinationDeals, index) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.DOMESTIC_ECONOMY);

        await removeNotification(destinationDeals);
      }}
      title="Domestic FareDrops"
      viewLoaded={dealsInitialized}
      emptyComponent={domesticEmptyComponent}
      // NOTE: Not only do promotions not work in production apps, they are causing weird behavior on the app even in development
      // Weird behavior = open dashboard, scroll international economy a few to the right, click a deal. The section is rerendered, resetting the active index, and then the click is applied to new active index (first card)
      // NOTE: Promotions are also causing unwanted behavior in web. After promotions load, they are causing a "reset", which resets any setIndex calls
      // TODO: Promotions need to be refactored, commenting out for now...
      // promotions={isApp ? undefined : adsPromotions[DealsDashboardList.DOMESTIC_ECONOMY]}
      type={DealsDashboardList.DOMESTIC_ECONOMY}
      sx={{
        marginTop: userState.isLimited ? 0 : undefined,
      }}
      subtitle={() => (
        <>
          {isBusinessFiltered
            ? ''
            : isLimitedPlan(userState.user?.billing.idStripePlan)
            ? 'Check back every 24 hours for new domestic deals'
            : 'Our latest flight deals in domestic economy class seats.'}
        </>
      )}
    />
  );

  const economySection = (
    <DealsSection
      ref={economySliderRef}
      onRefresh={async () => {
        await economyDealsState.mutate();
      }}
      deals={economyDeals}
      onSelect={async (destinationDeals, index, _activeIndex, isDealLocked) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.INTERNATIONAL_ECONOMY);

        if (isDealLocked) {
          setUpgradeMessage(LOCKED_DEAL_MESSAGE);
        } else {
          await selectDeal(destinationDeals);
        }
      }}
      onVisible={async (destinationDeals) => {
        await removeNotification(destinationDeals);
      }}
      onChange={async (destinationDeals, index) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.INTERNATIONAL_ECONOMY);

        await removeNotification(destinationDeals);
      }}
      title="Economy International FareDrops"
      viewLoaded={dealsInitialized}
      emptyComponent={economyEmptyComponent}
      subtitle={() => (
        <>
          {isBusinessFiltered
            ? ''
            : isLimitedPlan(userState.user?.billing.idStripePlan)
            ? 'Check back every 24 hours for new international economy class seat deals'
            : 'Our latest flight deals in international economy class seats.'}
        </>
      )}
      // NOTE: Not only do promotions not work in production apps, they are causing weird behavior on the app even in development
      // Weird behavior = open dashboard, scroll international economy a few to the right, click a deal. The section is rerendered, resetting the active index, and then the click is applied to new active index (first card)
      // NOTE: Promotions are also causing unwanted behavior in web. After promotions load, they are causing a "reset", which resets any setIndex calls
      // TODO: Promotions need to be refactored, commenting out for now...
      // promotions={isApp ? undefined : adsPromotions[DealsDashboardList.INTERNATIONAL_ECONOMY]}
      type={DealsDashboardList.INTERNATIONAL_ECONOMY}
      sx={{
        marginTop: 0,
      }}
    />
  );

  const businessSection = (
    <DealsSection
      ref={businessSliderRef}
      deals={businessDeals}
      hidePlaceholderWhenEmpty={
        !hasSeatClassAccessGQL(userProfile?.roles, SeatClass.Business) &&
        isLargeScreenSizeOrSmaller
      }
      lockDeals={!userRoles.includes(FareDropRole.Pro)}
      onRefresh={async () => await businessDealsState.mutate()}
      onSelect={async (destinationDeals, index, _activeIndex, isDealLocked) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.INTERNATIONAL_BUSINESS);

        if (isDealLocked) {
          setUpgradeMessage(LOCKED_DEAL_MESSAGE);
        } else {
          await selectDeal(destinationDeals);
        }
      }}
      onVisible={async (destinationDeals) => {
        await removeNotification(destinationDeals);
      }}
      onChange={async (destinationDeals, index) => {
        setLastViewedDealIndex(index);
        setLastViewedDealList(DealsDashboardList.INTERNATIONAL_BUSINESS);

        await removeNotification(destinationDeals);
      }}
      showButton={!userRoles.includes(FareDropRole.Pro)}
      subtitle={() => (
        <>
          {isBusinessFiltered
            ? ''
            : 'Our latest flight deals in international business class seats.'}
        </>
      )}
      title="Business International FareDrops"
      viewLoaded={dealsInitialized}
      sx={{ marginTop: 0 }}
      emptyComponent={businessEmptyComponent}
      // NOTE: Not only do promotions not work in production apps, they are causing weird behavior on the app even in development
      // Weird behavior = open dashboard, scroll international economy a few to the right, click a deal. The section is rerendered, resetting the active index, and then the click is applied to new active index (first card)
      // NOTE: Promotions are also causing unwanted behavior in web. After promotions load, they are causing a "reset", which resets any setIndex calls
      // TODO: Promotions need to be refactored, commenting out for now...
      // promotions={isApp ? undefined : adsPromotions[DealsDashboardList.INTERNATIONAL_BUSINESS]}
      type={DealsDashboardList.INTERNATIONAL_BUSINESS}
    />
  );

  const dealSections: JSX.Element[] = [];
  if (
    userRoles.includes(FareDropRole.Limited) &&
    !userRoles.includes(FareDropRole.Global)
  ) {
    dealSections.push(...[domesticSection, economySection, businessSection]);
  } else if (userRoles.includes(FareDropRole.Global)) {
    if (isEconomyFiltered && userRoles.includes(FareDropRole.Pro)) {
      dealSections.push(...[businessSection, economySection, domesticSection]);
    } else {
      dealSections.push(...[economySection, businessSection, domesticSection]);
    }
  }

  if (filteredDeals?.length > 0) {
    dealSections.push(
      <DealsSection
        ref={filteredSliderRef}
        deals={filteredDeals}
        onSelect={async (
          destinationDeals,
          index,
          _activeIndex,
          isDealLocked
        ) => {
          setLastViewedDealIndex(index);
          setLastViewedDealList(DealsDashboardList.FILTERED);

          if (isDealLocked) {
            setUpgradeMessage(LOCKED_DEAL_MESSAGE);
          } else {
            await selectDeal(destinationDeals);
          }
        }}
        onChange={async (_destinationDeals, index) => {
          setLastViewedDealIndex(index);
          setLastViewedDealList(DealsDashboardList.FILTERED);
        }}
        subtitle={() => (
          <div>
            Deals from your selected origin airports that do not meet your{' '}
            <a
              onClick={() =>
                goWithStickyParamsLocation({
                  pathname: AllowedPath.SETTINGS_DESTINATION_PREFERENCES,
                })
              }
            >
              deal preferences
            </a>
          </div>
        )}
        title="Filtered FareDrops"
        viewLoaded={dealsInitialized}
        // NOTE: Not only do promotions not work in production apps, they are causing weird behavior on the app even in development
        // Weird behavior = open dashboard, scroll international economy a few to the right, click a deal. The section is rerendered, resetting the active index, and then the click is applied to new active index (first card)
        // NOTE: Promotions are also causing unwanted behavior in web. After promotions load, they are causing a "reset", which resets any setIndex calls
        // TODO: Promotions need to be refactored, commenting out for now...
        // promotions={isApp ? undefined : adsPromotions[DealsDashboardList.FILTERED]}
        type={DealsDashboardList.FILTERED}
      />
    );
  }

  const pastDealsSection: JSX.Element[] = [];
  if (pastDeals?.length > 0) {
    pastDealsSection.push(
      <DealsSection
        ref={pastSliderRef}
        cardOpacity={0.5}
        deals={pastDeals}
        onSlideTransition={onSlideTransition}
        onSelect={async (
          destinationDeals,
          index,
          _activeIndex,
          isDealLocked
        ) => {
          setLastViewedDealIndex(index);
          setLastViewedDealList(DealsDashboardList.PAST);

          if (isDealLocked) {
            setUpgradeMessage(LOCKED_DEAL_MESSAGE);
          } else {
            await selectDeal(destinationDeals);
          }
        }}
        onChange={async (_destinationDeals, index) => {
          setLastViewedDealIndex(index);
          setLastViewedDealList(DealsDashboardList.PAST);
        }}
        subtitle={() => (
          <>
            A look back at some amazing deals that match your{' '}
            <a
              onClick={() =>
                goWithStickyParamsLocation({
                  pathname: AllowedPath.SETTINGS_DESTINATION_PREFERENCES,
                })
              }
            >
              preferences
            </a>
          </>
        )}
        title="Past FareDrops"
        viewLoaded={dealsInitialized}
        // NOTE: Not only do promotions not work in production apps, they are causing weird behavior on the app even in development
        // Weird behavior = open dashboard, scroll international economy a few to the right, click a deal. The section is rerendered, resetting the active index, and then the click is applied to new active index (first card)
        // NOTE: Promotions are also causing unwanted behavior in web. After promotions load, they are causing a "reset", which resets any setIndex calls
        // TODO: Promotions need to be refactored, commenting out for now...
        // promotions={isApp ? undefined : adsPromotions[DealsDashboardList.PAST]}
        type={DealsDashboardList.PAST}
      />
    );
  }

  return (
    (!dealsInitialized && <Loading />) ||
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage className="deals-dashboard">
        {
          // Desktop notifications fab
          !isApp &&
            !isMediumScreenSizeOrSmaller &&
            history.location.pathname === AllowedPath.DEALS && (
              <div
                className="desktop-notifications-modal-wrapper"
                style={{
                  zIndex: 1000,
                }}
              >
                {
                  // When this state changes, we need to render a fully new component,
                  // hence this ternary. Simply putting the ternary inside the "top"
                  // property and just re-rendering the component does not seem to work
                  (!userState.hasPaymentInfo && !userState.isLimited) ||
                  (userState.user?.billing?.idStripeCustomer &&
                    isNumberAndLessThanOrEqualTo(
                      userState.planEndInDays !== undefined,
                      0
                    )) ||
                  userState.hasDealNotificationsEnabled === false ||
                  userState.subscriptionStatus ===
                    SubscriptionStatus.Incomplete ? (
                    <NotificationsFabButton
                      hideDecimal={hideDecimal}
                      hideOnClick={false}
                      notificationsModalActive={notificationsModalActive}
                      setNotificationsModalActive={setNotificationsModalActive}
                      setScrollYCurrent={setScrollYCurrent}
                      top="75px"
                    />
                  ) : (
                    <NotificationsFabButton
                      hideDecimal={hideDecimal}
                      hideOnClick={false}
                      notificationsModalActive={notificationsModalActive}
                      setNotificationsModalActive={setNotificationsModalActive}
                      setScrollYCurrent={setScrollYCurrent}
                      top="1.5em"
                    />
                  )
                }

                {/*
                  Note: Not to be confused with NotificationSettingsModal!
                  This section below is just a small modal that pops up on login
                  if the user has never logged into the mobile app (i.e. lastMobileLogin
                  is null) and let's them know how they can download it
                */}
                {notificationsModalActive ? (
                  <IonRow
                    className="desktop-notifications-container row-vertical-align"
                    style={{ height: originAutoAddedAirport ? '38em' : '22em' }}
                  >
                    <IonCol>
                      {originAutoAddedAirport && (
                        <>
                          <IonRow
                            className="row-vertical-align"
                            style={{ height: 'auto', position: 'relative' }}
                          >
                            <IonCol size="11">
                              <p
                                className="download-app-text"
                                style={{ margin: 0 }}
                              >
                                {`We want to make sure you
                                  receive as many great deals as possible, so
                                  we've gone ahead and also added ${originAutoAddedAirport?.name}
                                  to your list of departure airports.`}
                              </p>
                              <br />
                              <p
                                className="download-app-text"
                                style={{ margin: 0 }}
                              >
                                Click{' '}
                                <a
                                  href="/settings/departure-airports"
                                  style={{
                                    fontWeight: 800,
                                    fontFamily: 'nexa-bold',
                                  }}
                                >
                                  here
                                </a>{' '}
                                to update your departure airports!
                              </p>
                            </IonCol>
                          </IonRow>
                          <hr
                            style={{
                              borderTop: '1px solid #ddd',
                              margin: '2em 0',
                            }}
                          />
                        </>
                      )}
                      <IonRow
                        className="row-vertical-align"
                        style={{ height: 'auto', position: 'relative' }}
                      >
                        <IonCol size="11">
                          <p className="download-app-text">
                            <b>Did you know we have a mobile app?</b> If you
                            haven&apos;t already, download the app to browse
                            flight deals on the go and receive notifications
                            right to your phone!
                          </p>
                        </IonCol>
                        <StoreBadges />
                      </IonRow>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )}
              </div>
            )
        }
        {
          // Headers
          !isApp || (isApp && !userState.hasPaymentInfo) ? (
            <HidingHeader
              hideDecimal={parseInt(hideDecimal.toString())}
              bannerClickListener={
                !userState.hasPaymentInfo ||
                (userState.user?.billing?.idStripeCustomer &&
                  isNumberAndLessThanOrEqualTo(
                    userState.planEndInDays !== undefined,
                    0
                  ))
                  ? () => {
                      goWithStickyParamsLocation({
                        pathname: AllowedPath.PLANS,
                      });
                    }
                  : undefined
              }
              hideToolbar={!userState.hasPaymentInfo && isApp}
            >
              {isLargeScreenSizeOrSmaller ? (
                <MobileHeader />
              ) : (
                <DesktopHeader showLoadingDiv={true} loggedIn={true} />
              )}
            </HidingHeader>
          ) : (
            <div
              style={{
                height: '2em',
                backdropFilter: 'blur(1.5px)',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 100000,
              }}
            />
          )
        }
        {isApp && (
          <NotificationSettingsModal
            isOpen={
              isLargeScreenSizeOrSmaller && notificationsSettingsModalActive
            }
            onClose={() => {
              setScrollYCurrent(0);
              setNotificationsSettingsModalActive(false);
            }}
            step={notificationsSettingsModalStep}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="deals-dashboard-content"
        >
          <img
            src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom.png`}
            style={{
              position: 'absolute',
              top: 0,
              minWidth: '1170px',
              height: '380px',
              WebkitMaskImage:
                'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)',
              maskImage:
                'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)',
            }}
            className="deals-dashboard-background-image-responsive"
          />
          <IonRow>
            <IonCol
              style={{ margin: 'auto', paddingTop: isApp ? '3em' : '5em' }}
              className="deals-dashboard-section-container-responsive"
            >
              <DealSearch
                onAirfareClick={(verifiedAirfare) => {
                  if (userState.isLimited) {
                    setUpgradeMessage(lockedAirfareMessage);
                  } else {
                    const url =
                      verifiedAirfare.bookingUrl ??
                      verifiedAirfare.departureUrl;
                    if (url) {
                      window.open(url, '_blank');
                    }
                  }
                }}
              />
              <>
                {dealSections.map((section, i) => (
                  <React.Fragment key={i}>{section}</React.Fragment>
                ))}
              </>
            </IonCol>
          </IonRow>
          <IonRow className="deals-dashboard-past-section-container-responsive">
            <IonCol style={{ margin: 'auto' }}>
              {pastDealsSection.map((section, i) => (
                <React.Fragment key={i}>{section}</React.Fragment>
              ))}
              <img
                src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom.png`}
                style={{
                  position: 'absolute',
                  zIndex: -1,
                  minWidth: '1170px',
                  transform: 'rotate(180deg)',
                  WebkitMaskImage: isApp
                    ? 'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)'
                    : '',
                  maskImage: isApp
                    ? 'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)'
                    : '',
                }}
                className="deals-dashboard-past-background-image-responsive"
              />
              <IonRow className="row-vertical-align deals-dashboard-edit-settings-container-responsive">
                <IonCol sizeXs="12" sizeXl="8" style={{ textAlign: 'center' }}>
                  <h1 className="title-font">Not seeing the deals you want?</h1>
                  <a
                    onClick={() =>
                      goWithStickyParamsPath(
                        userConfigOrigins.length > 0
                          ? AllowedPath.SETTINGS_DESTINATION_PREFERENCES
                          : AllowedPath.SETTINGS_DEPARTURE_AIRPORTS
                      )
                    }
                  >
                    <button
                      className="transparent-button"
                      style={{
                        width: '15em',
                        textAlign: 'center',
                        marginTop: '2em',
                        color: 'var(--ion-color-primary)',
                        borderColor: 'var(--ion-color-primary)',
                      }}
                    >
                      Edit deal settings
                    </button>
                  </a>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          message={upgradeMessage}
          onClose={() => {
            setUpgradeMessage(undefined);
          }}
        />
      </IonPage>
    )
  );
};

export default DealsDashboard;
