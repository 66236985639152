import { Airport, Deal } from '@faredrop/graphql-sdk';
import useAirports from './airports';

export type DealWithAirports = {
  deal: Deal;
  destinationAirport: Airport;
  originAirport: Airport;
};

export const useAirportsFromDeal = (deal?: Deal) => {
  const { destinations, origins } = useAirports();

  if (!deal || !origins || !destinations) {
    return [] as const;
  }

  const originAirport = origins.find(
    (origin) => origin.iata === (deal as Deal).originIATA
  );
  const destinationAirport = destinations.find(
    (destination) => destination.iata === (deal as Deal).destinationIATA
  );

  return [destinationAirport, originAirport] as const;
};
