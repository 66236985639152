import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import DesktopHeader from '../components/DesktopHeader';
import Dropdown from '../components/Dropdown';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import MonthsSelector from '../components/MonthsSelector';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import SettingsBackground from '../components/SettingsBackground';
import SettingsSideMenu, { MENU_OPTIONS } from '../components/SettingsSideMenu';
import { Tab } from '../contexts/tabContext';
import { useDevice } from '../hooks/useDevice';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PAGES } from '../types/types';
import './../theme/TravelAvailabilitySettings.css';
import './../theme/util.css';
import LimitedUpgradeCTA from '../components/LimitedUpgradeCTA';
import { getPlanName, isLimitedPlan } from '../utilities/plans-utilities';
import UpgradeModal from '../components/UpgradeModal';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';
import { FareDropPlan } from '@faredrop/graphql-sdk';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';

const Settings: React.FC = () => {
  const { isExtraLargeScreenSize, isApp } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { width } = useWindowDimensions();

  const [upgradeMessage, setUpgradeMessage] = useState<string>();

  const userState = useUser();

  const noInternet = userState.timeout;

  useEffect(() => {
    updateSelectedTab(Tab.SETTINGS).catch((error) => {
      console.warn('Failed to update selected tab', Tab.SETTINGS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  const isLimited = isLimitedPlan(userState.user?.billing?.idStripePlan);

  return (
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize || !userState.hasPaymentInfo ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={!userState.hasPaymentInfo && !isExtraLargeScreenSize}
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          <IonRow
            style={{
              marginBottom: '10em',
              padding: !isExtraLargeScreenSize
                ? `${isApp ? '1' : '0'}em 2em 2em 2em`
                : `5em 0em 0em 0em`,
            }}
          >
            <IonCol
              sizeXs="12"
              sizeXl="10"
              style={{ margin: 'auto', padding: 0 }}
            >
              <IonRow>
                <h1 className="title-font" style={{ marginTop: '2em' }}>
                  {isExtraLargeScreenSize ? 'Deal Settings' : 'Settings'}
                </h1>
              </IonRow>
              <IonRow
                className={!isExtraLargeScreenSize ? 'row-vertical-align' : ''}
              >
                <SettingsSideMenu
                  activeOption={PAGES.TRAVEL_AVAILABILITY}
                  isVisible={isExtraLargeScreenSize}
                />
                <Dropdown
                  options={MENU_OPTIONS[1].options.map(
                    (option) => option.label
                  )}
                  placeholder={PAGES.TRAVEL_AVAILABILITY}
                  onOptionClick={(option: string) => {
                    goWithStickyParamsPath(option);
                  }}
                  hrefOptions={true}
                  hidden={isExtraLargeScreenSize}
                  width={isExtraLargeScreenSize ? width / 2 : '100%'}
                />
                <IonCol
                  size={isExtraLargeScreenSize ? '6' : '12'}
                  style={{
                    marginLeft: !isExtraLargeScreenSize ? 0 : '5em',
                  }}
                >
                  <IonRow>
                    <h2>Travel Availability</h2>
                    <p>
                      We recommend leaving as many months checked as possible.
                      We can&apos;t anticipate when the next amazing deal will
                      be.
                    </p>
                  </IonRow>
                  <LimitedUpgradeCTA
                    isLimitedPlan={isLimited}
                    linkText="Upgrade"
                    message="to update travel availability."
                    style={{ marginBottom: '2em' }}
                  />
                  <MonthsSelector
                    disabled={isLimited}
                    onDisabledClick={() => {
                      setUpgradeMessage(
                        `Updating travel availability is only available on the ${getPlanName(
                          FareDropPlan.Pro
                        )} Plan. ${
                          HIDE_MEMBERSHIP_LINKS
                            ? HIDE_UPGRADE_MESSAGE
                            : 'Please upgrade plans to update travel availability settings.'
                        }`
                      );
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          message={upgradeMessage}
          onClose={() => {
            setUpgradeMessage(undefined);
          }}
        />
      </IonPage>
    )
  );
};

export default Settings;
