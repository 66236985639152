import { Browser } from '@capacitor/browser';
import {
  ChangeSubscriptionType,
  FareDropPlan as FareDropPlanGQL,
  ChangeSubscriptionPreFlightQuery,
} from '@faredrop/graphql-sdk';
import { AllowedPath, IUserBilling } from '@faredrop/types';
import { isDefaultFpTid } from '@faredrop/utilities';
import useAnalytics from './analytics';
import useAnalyticsService from './analytics';
import useFareDropApiClient from './faredropApiClient';
import useFareDropPublicApiClient from './faredropPublicApiClient';
import { useDevice } from './useDevice';
import useFirstPromoter from './useFirstPromoter';
import { buildSuccessAndCancelCheckoutURLs } from '../utilities/stripe';
import useImpact from './useImpact';
import useHistoryWithStickyParams from './historyWithStickyParams';

export type ChangeSubscriptionPreFlightResult =
  ChangeSubscriptionPreFlightQuery['changeSubscriptionPreFlight'];

const useStripeHook = () => {
  const { client: authenticatedFareDrop } = useFareDropApiClient();
  const { client: publicFareDrop } = useFareDropPublicApiClient();
  const { logAnalyticsError } = useAnalytics();
  const { getDeviceId } = useAnalyticsService();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { isApp } = useDevice();
  const { getFirstPromoterTID, getFirstPromoterReferralId } =
    useFirstPromoter();
  const { getImpactClickId } = useImpact();

  const createCheckoutSession = async (
    plan: string,
    successPathname?: string,
    successQueryParams?: { key: string; value: string }[],
    cancelPathname?: string,
    cancelQueryParams?: { key: string; value: string }[],
    coupon?: string
  ) => {
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      // For apps, since we don't have deep links configured, users are kicked back to the web where they aren't logged in, so we use an interstitial page that prompts the user to close the page which will then redirect them back to the app
      isApp ? AllowedPath.GET_STARTED_COMPLETE.slice(1) : successPathname,
      successQueryParams,
      cancelPathname,
      cancelQueryParams,
      isApp
    );

    const [fp_tid, fp_referral_id, impact_click_id, deviceId] =
      await Promise.all([
        getFirstPromoterTID(),
        getFirstPromoterReferralId(),
        getImpactClickId(),
        getDeviceId(),
      ]);

    // We need to specify success and cancel URLs to maintain consistent domains (www vs naked)
    const result = (
      await authenticatedFareDrop.checkoutURL({
        planType: plan as FareDropPlanGQL,
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
        couponCode: coupon,
        // Include First Promoter identifier for affiliate tracking
        fp_tid,
        fp_referral_id,
        // Impact affiliate tracking
        impact_click_id,
        // Include GA instance information for Measurement Protocol reporting when the purchase is complete
        ...deviceId,
      })
    ).data;

    if (isApp) {
      // TODO: Eventually we might want to pass in some UTM params for tracking
      await Browser.addListener('browserFinished', () => {
        const { successUrlObj: mobileSuccessUrlObj } =
          buildSuccessAndCancelCheckoutURLs(
            successPathname,
            successQueryParams,
            cancelPathname,
            cancelQueryParams,
            isApp
          );
        goWithStickyParamsLocation({
          pathname: mobileSuccessUrlObj.pathname,
          search: mobileSuccessUrlObj.search,
        });
      });
      Browser.open({ url: result.checkoutURL.url }).catch((error) =>
        console.warn('Failed to open browser', error)
      );
    } else {
      window.location.assign(result.checkoutURL.url);
    }
  };

  const createEditPaymentSession = async (
    successPathname?: string,
    successQueryParams?: { key: string; value: string }[],
    cancelPathname?: string,
    cancelQueryParams?: { key: string; value: string }[]
  ) => {
    // TODO: If someone signups in the app for a paid plan, Stripe checkout will redirect them to the web. Ideally, we'd use deep links instead to go back to the app
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      successPathname,
      successQueryParams,
      cancelPathname,
      cancelQueryParams,
      isApp
    );

    // We need to specify success and cancel URLs to maintain consistent domains (www vs naked)
    const result = (
      await authenticatedFareDrop.editPaymentURL({
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
      })
    ).data;

    if (isApp) {
      await Browser.addListener('browserFinished', () => {
        goWithStickyParamsPath(AllowedPath.PLANS);
      });
      Browser.open({ url: result.editPaymentURL.url }).catch((error) =>
        console.warn('Failed to open browser', error)
      );
    } else {
      window.location.assign(result.editPaymentURL.url);
    }
  };

  const createUpgradeCheckoutSession = async (
    plan: string,
    successPathname?: string,
    successQueryParams?: { key: string; value: string }[],
    cancelPathname?: string,
    cancelQueryParams?: { key: string; value: string }[],
    coupon?: string
  ) => {
    // TODO: If someone upgrades in the app for a paid plan, Stripe checkout will redirect them to the web. Ideally, we'd use deep links instead to go back to the app
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      // For apps, since we don't have deep links configured, users are kicked back to the web where they aren't logged in, so we use an interstitial page that prompts the user to close the page which will then redirect them back to the app
      isApp ? AllowedPath.GET_STARTED_COMPLETE.slice(1) : successPathname,
      successQueryParams,
      cancelPathname,
      cancelQueryParams,
      isApp
    );

    const [fp_tid, fp_referral_id, impact_click_id, deviceId] =
      await Promise.all([
        getFirstPromoterTID(),
        getFirstPromoterReferralId(),
        getImpactClickId(),
        getDeviceId(),
      ]);

    // We need to specify success and cancel URLs to maintain consistent domains (www vs naked)
    const result = (
      await authenticatedFareDrop.upgradeCheckoutURL({
        planType: plan as FareDropPlanGQL,
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
        couponCode: coupon,
        // Include First Promoter identifier for affiliate tracking
        fp_tid,
        fp_referral_id,
        // Impact affiliate tracking
        impact_click_id,
        // Include GA instance information for Measurement Protocol reporting when the purchase is complete
        ...deviceId,
      })
    ).data;

    if (isApp) {
      await Browser.addListener('browserFinished', () => {
        const { successUrlObj: mobileSuccessUrlObj } =
          buildSuccessAndCancelCheckoutURLs(
            successPathname,
            successQueryParams,
            cancelPathname,
            cancelQueryParams,
            isApp
          );
        goWithStickyParamsLocation({
          pathname: mobileSuccessUrlObj.pathname,
          search: mobileSuccessUrlObj.search,
        });
      });
      Browser.open({ url: result.upgradeCheckoutURL.url }).catch((error) =>
        console.warn(
          'Failed to open browser for confirm upgrade customer portal page',
          error
        )
      );
    } else {
      window.location.assign(result.upgradeCheckoutURL.url);
    }
  };

  const createCustomerPortalSession = async (successPathname: string) => {
    const { successUrlObj } = buildSuccessAndCancelCheckoutURLs(
      successPathname,
      [{ key: 'refreshAccount', value: 'true' }],
      undefined,
      undefined,
      isApp
    );

    // returnUrl is ignored in production but is very useful for development
    const result = (
      await authenticatedFareDrop.customerPortalURL({
        returnUrl: successUrlObj.toString(),
        // Make sure we include GA instance information for Measurement Protocol reporting when the purchase is complete
        ...(await getDeviceId()),
      })
    ).data;

    window.location.assign(result.customerPortalURL.url);
  };

  const getReceipt = async (idCheckoutSession: string) => {
    try {
      const result = (
        await publicFareDrop.receipt({
          idCheckoutSession,
        })
      ).data;
      return result.receipt;
    } catch (error) {
      await logAnalyticsError('getReceipt', error as Error);
      throw error;
    }
  };

  const convertFreeTrialSubscription = async (
    newPlanId: string,
    setupIntentId: string,
    coupon?: string
  ) => {
    try {
      const result = await authenticatedFareDrop.convertFreeTrialSubscription({
        newPlanId,
        setupIntentId,
        coupon,
      });
      return result.data.convertFreeTrialSubscription.idStripePlan;
    } catch (error) {
      await logAnalyticsError('getReceipt', error as Error);
      throw error;
    }
  };

  const registerForLimitedSubscription = async (queryParams?: string) => {
    try {
      const [fp_tid, fp_referral_id, impact_click_id, deviceId] =
        await Promise.all([
          getFirstPromoterTID(),
          getFirstPromoterReferralId(),
          getImpactClickId(),
          getDeviceId(),
        ]);

      const result = await authenticatedFareDrop.registerForLimitedSubscription(
        {
          ...deviceId,
          queryParams:
            queryParams ?? new URL(window.location.toString()).search,
          fp_tid,
          fp_referral_id,
          // Impact affiliate tracking
          impact_click_id,
        }
      );
      return result.data.registerForLimitedSubscription as IUserBilling;
    } catch (error) {
      await logAnalyticsError('registerForLimitedSubscription', error as Error);
      throw error;
    }
  };

  const fetchSetupIntentClientSecret = async () => {
    return (await authenticatedFareDrop.createSetupIntent()).data
      .createSetupIntent.clientSecret;
  };

  const fetchPublicSetupIntentClientSecret = async (email: string) => {
    return (await publicFareDrop.createSetupIntent({ email })).data
      .createSetupIntent.clientSecret;
  };

  const isCouponValid = async (coupon: string) => {
    return (
      await authenticatedFareDrop.isCouponValid({
        coupon,
      })
    ).data.isCouponValid;
  };

  const redeemSubscription = async (
    code: string,
    plan?: FareDropPlanGQL,
    idSetupIntent?: string
  ) => {
    try {
      const result = await authenticatedFareDrop.redeemSubscription({
        code,
        plan,
        idSetupIntent,
        ...(await getDeviceId()),
      });
      return result.data.redeemSubscription;
    } catch (error) {
      await logAnalyticsError('redeemSubscription', error as Error);
      throw error;
    }
  };

  const pollForOpenInvoices = async () => {
    return (await authenticatedFareDrop.pollForOpenInvoices()).data
      .pollForOpenInvoices.invoicesPaid;
  };

  const changeSubscription = async (
    newPlan: FareDropPlanGQL,
    changeSubscriptionType: ChangeSubscriptionType,
    idSetupIntent?: string,
    code?: string
  ) => {
    const [fp_tid, fp_referral_id, impact_click_id] = await Promise.all([
      getFirstPromoterTID(),
      getFirstPromoterReferralId(),
      getImpactClickId(),
    ]);
    return (
      await authenticatedFareDrop.changeSubscription({
        newPlan,
        changeSubscriptionType,
        idSetupIntent,
        code,
        // Only include the fp_tid for subscription updates if the fp_tid is not the default
        // i.e., only include the fp_tid if there is a referral query param (?fpr=)
        fp_tid: !isDefaultFpTid(fp_tid) ? fp_tid : undefined,
        fp_referral_id: !isDefaultFpTid(fp_tid) ? fp_referral_id : undefined,
        // Impact affiliate tracking
        impact_click_id: impact_click_id,
        queryParams: new URL(window.location.toString()).search,
      })
    ).data.changeSubscription;
  };

  const upgradeSubscriptionPublic = async (
    email: string,
    newPlan: FareDropPlanGQL,
    idSetupIntent: string,
    code?: string
  ) => {
    return (
      await publicFareDrop.upgradeSubscription({
        email,
        newPlan,
        idSetupIntent,
        code,
        queryParams: new URL(window.location.toString()).search,
      })
    ).data.upgradeSubscription;
  };

  const createUpgradeCheckoutSessionPublic = async (
    email: string,
    newPlan: FareDropPlanGQL,
    successPathname?: string,
    successQueryParams?: { key: string; value: string }[],
    cancelPathname?: string,
    cancelQueryParams?: { key: string; value: string }[],
    code?: string
  ) => {
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      successPathname,
      successQueryParams,
      cancelPathname,
      cancelQueryParams,
      isApp
    );

    const [fp_tid, fp_referral_id, impact_click_id, deviceId] =
      await Promise.all([
        getFirstPromoterTID(),
        getFirstPromoterReferralId(),
        getImpactClickId(),
        getDeviceId(),
      ]);
    const result = (
      await publicFareDrop.upgradeSubscriptionUrl({
        email,
        newPlan,
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
        code,
        queryParams: new URL(window.location.toString()).search,
        // Only include the fp_tid for subscription updates if the fp_tid is not the default
        // i.e., only include the fp_tid if there is a referral query param (?fpr=)
        fp_tid: !isDefaultFpTid(fp_tid) ? fp_tid : undefined,
        fp_referral_id: !isDefaultFpTid(fp_tid) ? fp_referral_id : undefined,
        // Impact affiliate tracking
        impact_click_id,
        // Include GA instance information for Measurement Protocol reporting when the purchase is complete
        ...deviceId,
      })
    ).data.upgradeSubscriptionUrl;

    if (isApp) {
      await Browser.addListener('browserFinished', () => {
        goWithStickyParamsLocation({
          pathname: successUrlObj.pathname,
          search: successUrlObj.search,
        });
      });
      Browser.open({ url: result.url }).catch((error) =>
        console.warn(
          'Failed to open browser for unauthenticated confirm upgrade customer portal page',
          error
        )
      );
    } else {
      window.location.assign(result.url);
    }
  };

  const createCheckoutSessionPublic = async (
    email: string,
    newPlan: FareDropPlanGQL,
    successPathname?: string,
    successQueryParams?: { key: string; value: string }[],
    cancelPathname?: string,
    cancelQueryParams?: { key: string; value: string }[],
    code?: string
  ) => {
    const { successUrlObj, cancelUrlObj } = buildSuccessAndCancelCheckoutURLs(
      successPathname,
      successQueryParams,
      cancelPathname,
      cancelQueryParams,
      isApp
    );

    const [fp_tid, fp_referral_id, impact_click_id, deviceId] =
      await Promise.all([
        getFirstPromoterTID(),
        getFirstPromoterReferralId(),
        getImpactClickId(),
        getDeviceId(),
      ]);

    const result = (
      await publicFareDrop.createSubscriptionUrl({
        email,
        newPlan,
        successUrl: successUrlObj.toString(),
        cancelUrl: cancelUrlObj.toString(),
        code,
        queryParams: new URL(window.location.toString()).search,
        // Only include the fp_tid for subscription updates if the fp_tid is not the default
        // i.e., only include the fp_tid if there is a referral query param (?fpr=)
        fp_tid: !isDefaultFpTid(fp_tid) ? fp_tid : undefined,
        fp_referral_id: !isDefaultFpTid(fp_tid) ? fp_referral_id : undefined,
        // Impact affiliate tracking
        impact_click_id,
        // Include GA instance information for Measurement Protocol reporting when the purchase is complete
        ...deviceId,
      })
    ).data.createSubscriptionUrl;

    if (isApp) {
      await Browser.addListener('browserFinished', () => {
        goWithStickyParamsLocation({
          pathname: successUrlObj.pathname,
          search: successUrlObj.search,
        });
      });
      Browser.open({ url: result.url }).catch((error) =>
        console.warn(
          'Failed to open browser for unauthenticated create subscription page',
          error
        )
      );
    } else {
      window.location.assign(result.url);
    }
  };

  const changeSubscriptionPreFlight = async (
    plan?: FareDropPlanGQL,
    code?: string
  ) => {
    return (
      await authenticatedFareDrop.changeSubscriptionPreFlight({
        plan,
        code,
      })
    ).data.changeSubscriptionPreFlight;
  };

  const reconcileCoupon = async (idCoupon: string) => {
    return (
      await authenticatedFareDrop.reconcileCoupon({
        idCoupon,
      })
    ).data.reconcileCoupon;
  };

  const releaseSchedule = async () => {
    return (await authenticatedFareDrop.releaseSchedule()).data.releaseSchedule;
  };

  const createSubscriptionPublic = async (
    email: string,
    newPlan: FareDropPlanGQL,
    idSetupIntent: string,
    code?: string
  ) => {
    return (
      await publicFareDrop.createSubscription({
        email,
        newPlan,
        idSetupIntent,
        code,
        queryParams: new URL(window.location.toString()).search,
      })
    ).data.createSubscription;
  };

  return {
    createCheckoutSession,
    createUpgradeCheckoutSession,
    createCustomerPortalSession,
    getReceipt,
    registerForLimitedSubscription,
    fetchSetupIntentClientSecret,
    fetchPublicSetupIntentClientSecret,
    convertFreeTrialSubscription,
    isCouponValid,
    pollForOpenInvoices,
    changeSubscription,
    changeSubscriptionPreFlight,
    reconcileCoupon,
    releaseSchedule,
    redeemSubscription,
    upgradeSubscriptionPublic,
    createUpgradeCheckoutSessionPublic,
    getFirstPromoterTID,
    getFirstPromoterReferralId,
    createSubscriptionPublic,
    createCheckoutSessionPublic,
    createEditPaymentSession,
  };
};

export default useStripeHook;
