import {
  IonButton,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { $enum } from 'ts-enum-util';
import { Elements } from '@stripe/react-stripe-js';
import { FormHelperText } from '@mui/material';

import {
  ChangeSubscriptionType,
  FareDropPlan,
  SubscriptionPlan,
} from '@faredrop/graphql-sdk';
import { STRIPE_PLAN_ID } from '@faredrop/types';

import ResponsiveModal from './ResponsiveModal';
import useStripe, {
  ChangeSubscriptionPreFlightResult,
} from '../hooks/useStripe';
import useUser from '../hooks/user';
import moment from 'moment';
import {
  getPlanName,
  isSubscriptionDowngrade,
  isSubscriptionUpgrade,
} from '../utilities/plans-utilities';
import PaymentForm, { SetupIntentReason } from './PaymentForm';
import useStripeReact from '../hooks/useStripeReact';
import { useCodeQueryParam } from '../hooks/useCodeQueryParam';
import CodeInput from './CodeInput';
import usePresentToast from '../hooks/presentToast';

interface ChangeSubscriptionModalProps {
  active: boolean;
  newPlan?: SubscriptionPlan;
  onConfirm: (
    nextPlan: FareDropPlan,
    changeSubscriptionType: ChangeSubscriptionType,
    setupIntent?: string,
    coupon?: string
  ) => Promise<boolean>;
  onDone: () => void;
  onClose: () => void;
}

enum ChangeSubscriptionStep {
  CONFIRM = 'CONFIRM',
  CONFIRMATION = 'CONFIRMATION',
}

enum InternalChangeSubscriptionType {
  DOWNGRADE_INSTEAD_OF_CANCEL = 'DOWNGRADE_INSTEAD_OF_CANCEL',
}

type ChangeSubscriptionOption =
  | ChangeSubscriptionType
  | InternalChangeSubscriptionType;

const ChangeSubscriptionModal: React.FC<ChangeSubscriptionModalProps> = ({
  active,
  newPlan,
  onClose,
  onConfirm,
  onDone,
}) => {
  const [step, setStep] = useState(ChangeSubscriptionStep.CONFIRM);
  const [loading, setLoading] = useState(false);
  const [nextInvoiceAmount, setNextInvoiceAmount] = useState<number>();
  const [refundAmount, setRefundAmount] = useState<number>();
  const [renewalAmount, setRenewalAmount] = useState<number>();
  const [setupIntentClientSecret, setSetupIntentClientSecret] =
    useState<string>();
  const [creditAmount, setCreditAmount] = useState<number>();
  const [confirmationMessage, setConfirmationMessage] = useState<string>();
  const [showError, setShowError] = useState(false);
  const [discountDescription, setDiscountDescription] = useState<string>();
  const [discountError, setDiscountError] = useState<string>();
  const [code, setCode] = useState<string>();

  const userState = useUser();
  const { changeSubscriptionPreFlight } = useStripe();
  const { stripePromise } = useStripeReact();
  const { couponOrGiftCodeQueryParam, couponsAreInitialized } =
    useCodeQueryParam();
  const { presentError } = usePresentToast();
  const [isUpgrade, setIsUpgrade] = useState<boolean>();
  const [isDowngrade, setIsDowngrade] = useState<boolean>();
  const [immediatelyDescription, setImmediatelyDescription] =
    useState<string>();
  const [atPlanEndDescription, setAtPlanEndDescription] = useState<string>();
  const [newPlanDescription, setNewPlanDescription] = useState<string>();

  const [changeSubscriptionOption, setChangeSubscriptionOption] =
    useState<ChangeSubscriptionOption>();

  const updatePreflightData = (result: ChangeSubscriptionPreFlightResult) => {
    const preflight = result[0];
    setNextInvoiceAmount(preflight?.nextInvoiceAmount);
    setRefundAmount(preflight?.refundAmount ?? 0);
    setRenewalAmount(preflight?.renewalAmount ?? 0);
    setSetupIntentClientSecret(preflight?.setupIntentClientSecret ?? undefined);
    setCreditAmount(preflight?.creditAmount ?? 0);

    if (preflight.couponErrorMessage) {
      setDiscountError(preflight.couponErrorMessage);
    } else {
      if (discountError) setDiscountError(undefined);
      if (preflight?.dollarDiscount) {
        setDiscountDescription(
          `$${preflight.dollarDiscount} discount has been applied`
        );
      } else if (preflight?.percentDiscount) {
        setDiscountDescription(
          `${preflight?.percentDiscount}% discount has been applied`
        );
      } else {
        setDiscountDescription(undefined);
      }
    }
  };

  useEffect(() => {
    // Only set isUpgrade and isDowngrade once - onConfirm changes user idStripePlan which causes a flash of different content
    if (active && userState.user && newPlan && isUpgrade == null) {
      const idStripePlan = userState.user?.billing.idStripePlan;

      if (idStripePlan) {
        const isUpgrade = isSubscriptionUpgrade(
          $enum(STRIPE_PLAN_ID).asValueOrThrow(idStripePlan),
          newPlan.planType
        );
        setIsUpgrade(isUpgrade);

        const isDowngrade = isSubscriptionDowngrade(
          $enum(STRIPE_PLAN_ID).asValueOrThrow(idStripePlan),
          newPlan.planType
        );
        setIsDowngrade(isDowngrade);
      }
    }
  }, [userState.user, newPlan, active]);

  // Make sure confirm is the first step when the modal is opened
  useEffect(() => {
    if (active && isUpgrade != null && couponsAreInitialized && newPlan) {
      setStep(ChangeSubscriptionStep.CONFIRM);
      changeSubscriptionPreFlight(newPlan.planType, couponOrGiftCodeQueryParam)
        .then(updatePreflightData)
        .catch(() =>
          presentError(
            'Failed to fetch plan data - please refresh the page and try again'
          )
        );

      // Set the default change subscription option
      if (isUpgrade === true) {
        setChangeSubscriptionOption(ChangeSubscriptionType.Immediately);
      } else if (isDowngrade === true) {
        setChangeSubscriptionOption(ChangeSubscriptionType.AtPlanEnd);
      } else {
        // Default option for cancel should be to downgrade
        setChangeSubscriptionOption(
          InternalChangeSubscriptionType.DOWNGRADE_INSTEAD_OF_CANCEL
        );
      }
    }
  }, [active, couponsAreInitialized, isUpgrade, newPlan]);

  const resetModal = () => {
    setIsUpgrade(undefined);
    setIsDowngrade(undefined);
    setNextInvoiceAmount(undefined);
    setRefundAmount(undefined);
    setCreditAmount(undefined);
    setRenewalAmount(undefined);
    setImmediatelyDescription(undefined);
    setAtPlanEndDescription(undefined);
    setNewPlanDescription(undefined);
    setDiscountDescription(undefined);
    setDiscountError(undefined);
  };

  // Reset when not active
  useEffect(() => {
    if (!active) {
      resetModal();
    }
  }, [active]);

  useEffect(() => {
    if (couponOrGiftCodeQueryParam) {
      setCode(couponOrGiftCodeQueryParam);
    }
  }, [couponOrGiftCodeQueryParam]);

  useEffect(() => {
    if (
      active &&
      !loading &&
      isUpgrade != null &&
      newPlan &&
      nextInvoiceAmount != null &&
      renewalAmount != null &&
      creditAmount != null &&
      refundAmount != null
    ) {
      const idStripePlan = userState.user?.billing.idStripePlan;

      let atPlanEndDesc: string | undefined = undefined;
      let immediatelyDesc: string | undefined = undefined;
      let newPlanDesc: string | undefined = undefined;

      const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      const formattedNextInvoiceAmount =
        numberFormatter.format(nextInvoiceAmount);
      const formattedRenewalAmount = numberFormatter.format(renewalAmount);
      const formattedRefundAmount = numberFormatter.format(refundAmount);
      const formattedCreditAmount = numberFormatter.format(creditAmount);

      if (isUpgrade) {
        atPlanEndDesc = undefined;
        immediatelyDesc = `Upgrade to the ${getPlanName(
          newPlan?.planType
        )} plan.`;
        if (
          idStripePlan === STRIPE_PLAN_ID.PRODUCTION_LIMITED_YEARLY ||
          idStripePlan === STRIPE_PLAN_ID.DEVELOPMENT_LIMITED_YEARLY
        ) {
          if (nextInvoiceAmount !== renewalAmount) {
            // Coupon applied
            immediatelyDesc += ` You will be charged a discounted amount of ${formattedNextInvoiceAmount} plus tax today, and you will be charged ${formattedRenewalAmount} plus tax annually starting ${moment()
              .add(1, 'year')
              .format('MMMM Do, YYYY')}.`;
          } else {
            immediatelyDesc += ` You will be charged ${formattedRenewalAmount} plus tax annually starting today.`;
          }
        } else {
          immediatelyDesc += ` You will be charged a prorated${
            discountDescription ? ' and discounted' : ''
          } amount of ${formattedNextInvoiceAmount} plus tax today, and you will be charged ${formattedRenewalAmount} plus tax annually starting ${periodEnd}.`;
        }
        if (creditAmount) {
          immediatelyDesc += ` After applying the coupon, your account will have a credit of ${formattedCreditAmount}.`;
        }
      } else if (isDowngrade) {
        atPlanEndDesc = `Downgrade to the ${getPlanName(
          newPlan?.planType
        )} plan at the end of your billing cycle on ${periodEnd}`;
        immediatelyDesc = `Downgrade to the ${getPlanName(
          newPlan?.planType
        )} plan immediately and receive a ${formattedRefundAmount} refund`;
      } else {
        newPlanDesc = `Downgrade to our FREE ${getPlanName(
          FareDropPlan.Limited
        )} plan at the end of your billing cycle on ${periodEnd}`;
        atPlanEndDesc = `Cancel at the end of your billing cycle on ${periodEnd}`;
        immediatelyDesc = `Cancel immediately and receive a ${formattedRefundAmount} refund`;
      }

      setImmediatelyDescription(immediatelyDesc);
      setAtPlanEndDescription(atPlanEndDesc);
      setNewPlanDescription(newPlanDesc);
    }
  }, [
    active,
    userState.user?.billing.idStripePlan,
    newPlan,
    isUpgrade,
    nextInvoiceAmount,
    renewalAmount,
    creditAmount,
    refundAmount,
    loading,
  ]);

  const periodEnd = userState.user?.billing.stripePlanEnd
    ? moment(userState.user?.billing.stripePlanEnd).format('MMMM Do, YYYY')
    : undefined;
  const title =
    step === ChangeSubscriptionStep.CONFIRM
      ? 'Confirm Subscription Change'
      : 'Subscription Change Success';

  const handleClose = () => {
    resetModal();
    onClose();
  };

  const handleConfirm = async (idSetupIntent?: string) => {
    if (changeSubscriptionOption) {
      setLoading(true);
      try {
        if (!newPlan?.planType) throw new Error('New plan is required');
        const showConfirmation = await onConfirm(
          // For cancel scenarios, we want to offer a downgrade option instead of just cancel immediately or cancel at plan end
          changeSubscriptionOption ===
            InternalChangeSubscriptionType.DOWNGRADE_INSTEAD_OF_CANCEL
            ? FareDropPlan.Limited
            : newPlan.planType,
          changeSubscriptionOption === ChangeSubscriptionType.Immediately
            ? ChangeSubscriptionType.Immediately
            : ChangeSubscriptionType.AtPlanEnd,
          idSetupIntent,
          code
        );

        if (showConfirmation) {
          setStep(ChangeSubscriptionStep.CONFIRMATION);

          const numberFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          const formattedNextInvoiceAmount = numberFormatter.format(
            nextInvoiceAmount != null ? nextInvoiceAmount : 0
          );
          const formattedRefundAmount = numberFormatter.format(
            refundAmount != null ? refundAmount : 0
          );

          let formattedCreditAmount: string | undefined = undefined;
          if (creditAmount) {
            formattedCreditAmount = numberFormatter.format(creditAmount);
          }

          let confirmationMessage: string | undefined = undefined;
          if (isUpgrade) {
            confirmationMessage = `Your plan has successfully been upgraded to the ${getPlanName(
              newPlan?.planType
            )} plan. You have been charged ${formattedNextInvoiceAmount}`;
            if (formattedCreditAmount) {
              confirmationMessage += ` and your account has been credited ${formattedCreditAmount}`;
            }
          } else if (isDowngrade) {
            if (changeSubscriptionOption === ChangeSubscriptionType.AtPlanEnd) {
              confirmationMessage = `Your plan will be downgraded to the ${getPlanName(
                newPlan?.planType
              )} plan at the end of your billing cycle on ${periodEnd}.`;
              if (nextInvoiceAmount) {
                confirmationMessage += ` Your next invoice amount will be ${formattedNextInvoiceAmount}.`;
              }
            } else if (
              changeSubscriptionOption === ChangeSubscriptionType.Immediately
            ) {
              confirmationMessage = `Your plan has successfully been downgraded to the ${getPlanName(
                newPlan?.planType
              )} plan. You have been refunded ${formattedRefundAmount}.`;
            }
          } else {
            if (changeSubscriptionOption === ChangeSubscriptionType.AtPlanEnd) {
              confirmationMessage = `Your subscription has successfully been updated and will cancel at the end of your billing cycle on ${periodEnd}`;
            } else if (
              changeSubscriptionOption === ChangeSubscriptionType.Immediately
            ) {
              confirmationMessage = `Your subscription has successfully been canceled and you have been refunded ${formattedRefundAmount}`;
            } else if (
              changeSubscriptionOption ===
              InternalChangeSubscriptionType.DOWNGRADE_INSTEAD_OF_CANCEL
            ) {
              confirmationMessage = `Your plan will be downgraded to the ${getPlanName(
                FareDropPlan.Limited
              )} plan at the end of your billing cycle on ${periodEnd}`;
            }
          }
          setConfirmationMessage(confirmationMessage);
          setLoading(false);
        }
      } catch (error) {
        setShowError(true);
        setLoading(false);
        handleClose();
      }
    }
  };

  const handleApplyDiscount = async (code?: string) => {
    setCode(code);
    if (newPlan) {
      const result = await changeSubscriptionPreFlight(newPlan?.planType, code);
      updatePreflightData(result);
    }
  };

  const emailSubject = encodeURIComponent('Update Plan Trouble');
  const emailBody = encodeURIComponent(
    `Hi, I am having trouble updating my FareDrop Plan. Could you assist me? I would like to ______`
  );
  const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

  return (
    <ResponsiveModal title={title} active={active} onClose={handleClose}>
      {nextInvoiceAmount == null && <IonSpinner name="crescent" />}
      {nextInvoiceAmount != null && step === ChangeSubscriptionStep.CONFIRM && (
        <>
          <IonRadioGroup
            value={changeSubscriptionOption}
            onIonChange={(e) => setChangeSubscriptionOption(e.detail.value)}
          >
            {isUpgrade === false && isDowngrade === false && (
              <IonItem text-wrap>
                <IonLabel
                  class="ion-text-wrap"
                  style={{
                    color:
                      changeSubscriptionOption !==
                      InternalChangeSubscriptionType.DOWNGRADE_INSTEAD_OF_CANCEL
                        ? 'grey'
                        : undefined,
                  }}
                >
                  {newPlanDescription}
                </IonLabel>
                <IonRadio
                  slot="start"
                  value={
                    InternalChangeSubscriptionType.DOWNGRADE_INSTEAD_OF_CANCEL
                  }
                />
              </IonItem>
            )}
            {isUpgrade === false && (
              <IonItem>
                <IonLabel
                  class="ion-text-wrap"
                  style={{
                    color:
                      changeSubscriptionOption !==
                      ChangeSubscriptionType.AtPlanEnd
                        ? 'grey'
                        : undefined,
                  }}
                >
                  {atPlanEndDescription}
                </IonLabel>
                <IonRadio
                  slot="start"
                  value={ChangeSubscriptionType.AtPlanEnd}
                />
              </IonItem>
            )}
            {(isUpgrade === true || !!refundAmount) && (
              <IonItem text-wrap>
                <IonLabel
                  class="ion-text-wrap"
                  style={{
                    color:
                      changeSubscriptionOption !==
                      ChangeSubscriptionType.Immediately
                        ? 'grey'
                        : undefined,
                  }}
                >
                  {immediatelyDescription}
                </IonLabel>
                <IonRadio
                  slot="start"
                  value={ChangeSubscriptionType.Immediately}
                />
              </IonItem>
            )}
          </IonRadioGroup>
          {setupIntentClientSecret && nextInvoiceAmount > 0 && (
            <div style={{ marginTop: '1em' }}>
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: setupIntentClientSecret }}
              >
                <PaymentForm
                  plan={newPlan}
                  setupIntentReason={SetupIntentReason.UPGRADE}
                  onSubmit={async (_elements, _stripe, setupIntent) => {
                    await handleConfirm(setupIntent?.id);
                  }}
                  onApplyDiscount={handleApplyDiscount}
                  discountDescription={discountDescription}
                  discountError={discountError}
                  disabled={showError}
                />
              </Elements>
            </div>
          )}
          {(!setupIntentClientSecret || nextInvoiceAmount === 0) && (
            <IonRow
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isUpgrade && (
                <CodeInput
                  discountDescription={discountDescription}
                  discountError={discountError}
                  onApply={handleApplyDiscount}
                />
              )}
              <IonButton
                className="button no-caps"
                disabled={loading || showError}
                style={{
                  opacity: loading ? 0.5 : 1.0,
                  marginTop: '2em',
                  width: '11.5em',
                  height: '3.5em',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  await handleConfirm();
                }}
              >
                {(loading && <IonSpinner color="white" name="crescent" />) || (
                  <p style={{ color: 'white' }}>Confirm</p>
                )}
              </IonButton>
            </IonRow>
          )}
        </>
      )}
      {nextInvoiceAmount != null &&
        step === ChangeSubscriptionStep.CONFIRMATION && (
          <>
            <IonRow style={{ maxWidth: '500px' }}>
              <p style={{ lineHeight: '1.35em' }}>{confirmationMessage}</p>
            </IonRow>
            <IonRow>
              <IonButton
                className="button no-caps"
                disabled={loading || showError}
                style={{
                  opacity: loading ? 0.5 : 1.0,
                  marginTop: '2em',
                  width: '11.5em',
                  height: '3.5em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleClose();
                  onDone();
                }}
              >
                {(loading && <IonSpinner color="white" name="crescent" />) || (
                  <p style={{ color: 'white' }}>Done</p>
                )}
              </IonButton>
            </IonRow>
          </>
        )}
      {showError && (
        <FormHelperText error style={{ marginTop: '1em' }}>
          Failed to update plan.{' '}
          <a
            style={{ fontWeight: 'bold', color: 'darkred' }}
            href={errorMailTo}
          >
            Click here
          </a>{' '}
          for assistance.
        </FormHelperText>
      )}
    </ResponsiveModal>
  );
};

export default ChangeSubscriptionModal;
