import { IonCol, IonContent, IonIcon, IonPage, IonRow } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import useAnalyticsScreenName from '../hooks/analyticsScreenName';
import './../theme/util.css';
import './../theme/Welcome.css';
import { useDevice } from '../hooks/useDevice';

const CloseInAppBrowser: React.FC = () => {
  useAnalyticsScreenName('/get-started/complete');
  const { isAndroid } = useDevice();

  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success') !== null;

  const supportEmailBody = encodeURI(
    `Hi there,\n\nI was told that Faredrop was unable to process my payment for a subscription. Would you mind assisting me? The email associated with my account is: _____________________.\n\nThank you!`
  );

  return (
    <IonPage>
      <IonContent className="welcome-page">
        <IonRow
          className="row-vertical-align"
          style={{
            padding: '3em 2em 2em 2em',
          }}
        >
          <IonCol
            sizeXs="12"
            sizeLg="7"
            sizeXl="5"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ maxWidth: '650px' }}>
              <h1
                className="title-font"
                style={{
                  textAlign: 'center',
                  color: success
                    ? 'var(--ion-color-primary)'
                    : 'var(--ion-color-danger)',
                }}
              >
                {success
                  ? 'Successfully subscribed to Faredrop!'
                  : "We're sorry"}
              </h1>
              <div style={{ width: '100%', display: 'flex' }}>
                <IonIcon
                  icon={success ? checkmarkCircleOutline : closeCircleOutline}
                  style={{
                    margin: 'auto',
                    marginTop: '.5em',
                    marginBottom: '.5em',
                    fontSize: '4em',
                  }}
                  color={success ? 'primary' : 'danger'}
                />
              </div>
              <IonRow
                className="row-vertical-align"
                style={{
                  marginTop: '1em',
                  marginBottom: '2em',
                }}
              >
                <IonCol size="8" style={{ display: 'flex' }}>
                  <p
                    style={{
                      margin: 'auto',
                      lineHeight: '30px',
                      transition: 'opacity 200ms',
                      fontFamily: 'nexa',
                      textAlign: 'center',
                    }}
                  >
                    {success ? (
                      <>
                        Exit the browser by pressing{' '}
                        <span style={{ fontFamily: 'nexa-bold' }}>
                          <b>{isAndroid ? 'X' : 'Done'}</b>
                        </span>{' '}
                        to begin using your new account!
                      </>
                    ) : (
                      <>
                        We were unable to successfully process your payment.
                        <br />
                        Please press{' '}
                        <span style={{ fontFamily: 'nexa-bold' }}>
                          <b>Done</b>
                        </span>{' '}
                        and try again.
                        <br />
                        <br />
                        <a
                          style={{ fontFamily: 'nexa-bold' }}
                          href={`mailto:team@faredrop.com?subject=Refund request&body=${supportEmailBody}`}
                        >
                          Contact support
                        </a>
                      </>
                    )}
                  </p>
                </IonCol>
              </IonRow>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default CloseInAppBrowser;
