import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHidingHeader = (threshold?: number) => {
  const [scrollYCurrent, setScrollYCurrent] = useState(0);
  // number between 0 and 1
  const [hideDecimal, setHideDecimal] = useState(0);

  useEffect(() => {
    // At the top of the page || Scrolling upwards
    if (scrollYCurrent <= (threshold ?? 150)) {
      // Set margins to fully show the header
      setHideDecimal(0);
    } else {
      // If scrolled past 150...
      if (scrollYCurrent > (threshold ?? 150)) {
        // Set margins to fully hide the header
        setHideDecimal(1);
      }
    }
  }, [scrollYCurrent]);

  return {
    hideDecimal,
    setScrollYCurrent,
  };
};
