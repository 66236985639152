import { IonCol, IonItem, IonLabel, IonList } from '@ionic/react';

import './../theme/SettingsSideMenu.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

interface ContainerProps {
  activeOption: string;
  isVisible: boolean;
}

export const MENU_OPTIONS = [
  {
    sectionLabel: 'Account',
    options: [
      {
        label: 'Profile',
        href: '/account/profile',
      },
      {
        label: 'Plan & Billing',
        href: '/account/plan-and-billing',
      },
      {
        label: 'Perks',
        href: '/perks',
      },
    ],
  },
  {
    sectionLabel: 'Deal Settings',
    options: [
      {
        label: 'Departure Airports',
        href: '/settings/departure-airports',
      },
      {
        label: 'Destination Preferences',
        href: '/settings/destination-preferences',
      },
      {
        label: 'Deal Preferences',
        href: '/settings/deal-preferences',
      },
      {
        label: 'Travel Availability',
        href: '/settings/travel-availability',
      },
    ],
  },
];

export const hrefFromOption = (_option: string) => {
  let href = '/';
  MENU_OPTIONS.map((section) => {
    section.options.map((option) => {
      if (option.label === _option) href = option.href;
    });
  });

  return href;
};

const sectionOptionFormatted = (option: string) =>
  option.toUpperCase().replace('&', 'AND').split(' ').join('_');

const SettingsSideMenu: React.FC<ContainerProps> = ({
  activeOption,
  isVisible,
}) => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  return (
    <IonCol
      size="2.5"
      className="side-nav-menu"
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      {MENU_OPTIONS.map((section) => {
        const label = (
          <IonItem>
            <IonLabel style={{ background: 'transparent', margin: 0 }}>
              {section.sectionLabel}
            </IonLabel>
          </IonItem>
        );
        const options: JSX.Element[] = [];
        section.options.map((option) => {
          options.push(
            <IonItem
              style={{ margin: '.5em' }}
              key={option.label}
              className={
                sectionOptionFormatted(activeOption) ===
                sectionOptionFormatted(option.label)
                  ? 'item-active'
                  : 'item-inactive'
              }
              color={
                sectionOptionFormatted(activeOption) ===
                sectionOptionFormatted(option.label)
                  ? 'light'
                  : 'inactive'
              }
              onClick={() => goWithStickyParamsPath(option.href)}
            >
              <a style={{ textDecoration: 'none' }}>{option.label}</a>
            </IonItem>
          );
        });
        return (
          <IonList
            key={section.sectionLabel}
            lines="none"
            style={{ background: 'transparent' }}
          >
            {label}
            {options}
          </IonList>
        );
      })}
    </IonCol>
  );
};

export default SettingsSideMenu;
