import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSpinner,
  useIonViewDidLeave,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/LoginPage.css';
import './../theme/util.css';
import useAnalytics from '../hooks/analytics';
import usePresentToast from '../hooks/presentToast';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import Loading from '../components/Loading';
import { TextField, FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useAnalyticsScreenName from '../hooks/analyticsScreenName';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useLogError from '../hooks/logError';
import { FareDropRole } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';

const VerifyEmailPage: React.FC = () => {
  const [couponCode, _] = useState(
    new URLSearchParams(window.location.search).get('coupon')
  );
  useAnalyticsScreenName('/verify');
  const itemsRef = useRef<Array<HTMLElement>>([]);
  const {
    isSmallScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
    isLargeScreenSizeOrSmaller,
  } = useDevice();
  const [mounted, setMounted] = useState(false);
  const [fade, setFade] = useState(0);
  const [resentCode, setResentCode] = useState(false);
  const [description, setDescription] = useState('');
  const [_isLoading, setIsLoading] = useState<boolean>(true);
  const {
    isAuthenticated,
    sendVerificationCode,
    verifyAttribute,
    refreshSession,
    isEmailVerified,
  } = useAuth();
  const { presentError } = usePresentToast();
  const { logAnalyticsOnboardingEngagement } = useAnalytics();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const history = useHistory();
  const userState = useUser();
  const noInternet = userState.timeout;
  const { logError } = useLogError();

  if (couponCode !== null) {
    const url = new URL(window.location.href);
    url.searchParams.set('coupon', couponCode ?? '');
    window.history.pushState({}, '', url.href);
  }

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
    setDescription(getDescription(userState.user?.profile.email));
    setIsLoading(false);
    setMounted(true);
    setFade(1.0);
  }, [userState.user?.profile.email]);

  useIonViewDidLeave(() => {
    setIsLoading(false);
  }, []);

  const emailSubject = encodeURIComponent('Verification Code Trouble');
  const emailBody = encodeURIComponent(
    `Hi, I am not receiving any verification codes for FareDrop. Could you assist me? My name is ${userState.user?.profile.firstName} ${userState.user?.profile.lastName} and my email is ${userState.user?.profile.email}`
  );
  const errorMailTo = `mailto:team@faredrop.com?subject=${emailSubject}&body=${emailBody}`;

  const getDescription = (email: string | undefined) => {
    return !email
      ? ''
      : `We sent a code to ${email}. Please enter the six digit code in that email.`;
  };

  const codeRequirements = isSmallScreenSizeOrSmaller
    ? Yup.string().required('Code is required')
    : Yup.array().of(Yup.string().required('Code is required'));

  return noInternet ? (
    <NoInternetConnectionModal />
  ) : !mounted ? (
    <Loading />
  ) : (
    <IonPage>
      <IonRow
        style={{
          position: 'absolute',
          top: 0,
          zIndex: 100,
          backgroundColor: 'transparent',
          width: '100%',
          padding: '15px 40px',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader
            menuHidden={true}
            showLoadingDiv={false}
            showBackToHome={true}
            backToHomeListener={() => {
              if (isAuthenticated) {
                goWithStickyParamsLocation({
                  pathname: AllowedPath.DEALS,
                  search: `?${new URLSearchParams(
                    window.location.search
                  ).toString()}`,
                });
              } else {
                history.push({ pathname: AllowedPath.LOGOUT });
              }
            }}
            backToHomeText={'Back to Deals'}
          />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            ctaHidden={true}
            showLoadingDiv={false}
            logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
            showBackToHome={true}
            backToHomeListener={() => {
              if (isAuthenticated) {
                goWithStickyParamsLocation({
                  pathname: AllowedPath.DEALS,
                  search: `?${new URLSearchParams(
                    window.location.search
                  ).toString()}`,
                });
              } else {
                history.push({ pathname: AllowedPath.LOGOUT });
              }
            }}
            backToHomeText={'Back to Deals'}
          />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            padding: '5em 2em 2em 2em',
            opacity: fade,
            transition: 'opacity 800ms',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8" sizeXl="4">
            <h1 className="title-font" style={{ textAlign: 'center' }}>
              Verify email address
            </h1>
            <h2>{description}</h2>
            <p
              className={!resentCode ? 'pointer-on-hover' : ''}
              style={{
                textAlign: 'center',
                margin: '1em 0 2em',
                color: resentCode ? 'inherit' : 'var(--ion-color-primary)',
              }}
              onClick={async () => {
                if (!resentCode) {
                  const _email = userState.user?.profile.email;
                  if (!_email) {
                    throw new Error(
                      'No User email found to Resend Verification Code'
                    );
                  } else {
                    try {
                      await sendVerificationCode('email');
                      setResentCode(true);
                    } catch (err) {
                      setResentCode(false);
                      presentError('Error: Please try again');
                    }
                    await logAnalyticsOnboardingEngagement(
                      AnalyticsEngagementId.RESEND_EMAIL_VERIFICATION_CODE
                    );
                  }
                }
              }}
            >
              {resentCode ? 'Code sent' : 'Resend code'}
            </p>
            <hr />
            <Formik
              initialValues={{
                code: isSmallScreenSizeOrSmaller
                  ? ''
                  : ['', '', '', '', '', ''],
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                code: codeRequirements,
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                setErrors({
                  submit: undefined,
                });

                try {
                  // If we do not have any value for email (i.e. username, email, or the email entered on
                  // the getting started screen), we cannot verify the email
                  if (!userState.user?.profile.email) {
                    throw Error(
                      'Missing email reference. Please cancel and try again.'
                    );
                  }

                  setIsLoading(true);

                  if (!userState.user?.profile.email) {
                    throw new Error('Missing email!');
                  }

                  // If state.accountExists, verifyEmail will call Auth.verifyCurrentUserAttributeSubmit
                  // Else, verifyEmail will call Auth.confirmSignUp
                  await Promise.race([
                    verifyAttribute(
                      'email',
                      Array.isArray(values.code)
                        ? values.code.join('')
                        : values.code
                    ),
                    new Promise<string>((_, reject) =>
                      setTimeout(reject, 8000)
                    ),
                  ]);

                  await refreshSession();

                  if (!isEmailVerified()) {
                    throw new Error('Please refresh and try again');
                  }

                  // If user already has an account, navigate back to Profile Settings
                  if (
                    isAuthenticated &&
                    userState.user &&
                    !(
                      userState.user?.profile.roles.length === 1 &&
                      userState.user?.profile.roles.includes(
                        FareDropRole.Profile
                      )
                    )
                  ) {
                    // Fade out page and nav to Deals Dash
                    setFade(0);
                    setTimeout(() => {
                      goWithStickyParamsLocation({
                        pathname: AllowedPath.ACCOUNT_PROFILE,
                      });
                    }, 800);
                  } else {
                    // Fade out page and nav to Get Started
                    setFade(0);
                    setTimeout(() => {
                      goWithStickyParamsLocation({
                        pathname: AllowedPath.DEALS,
                      });
                    }, 800);
                  }

                  await logAnalyticsOnboardingEngagement(
                    AnalyticsEngagementId.VERIFY_EMAIL_CODE
                  );
                } catch (err) {
                  // Throw error
                  setStatus({ success: false });
                  setErrors({
                    submit:
                      err && (err as Error).message
                        ? (err as Error).message
                        : 'Error: please try again',
                  });
                  setSubmitting(false);
                  setIsLoading(false);

                  await logError(
                    'Error on verify email page',
                    err && (err as Error).message
                      ? (err as Error).message
                      : 'Error or error message is undefined',
                    userState.user
                  );
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                values,
              }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                  <h4>Verification code</h4>
                  <IonRow style={{ marginBottom: '2em' }}>
                    {isSmallScreenSizeOrSmaller && (
                      <TextField
                        error={Boolean(touched.code && errors.code)}
                        fullWidth
                        helperText={touched.code && errors.code}
                        label="Verification Code"
                        margin="normal"
                        name="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.code}
                        variant="outlined"
                      />
                    )}
                    {!isSmallScreenSizeOrSmaller &&
                      [1, 2, 3, 4, 5, 6].map((_ref, i: number) => (
                        <IonCol size="2" key={i}>
                          <TextField
                            inputProps={{
                              min: 0,
                              style: {
                                textAlign: 'center',
                                fontSize: '24px',
                                paddingLeft: 0,
                                paddingRight: 0,
                              },
                            }}
                            error={Boolean(
                              Array.isArray(touched.code) &&
                                touched.code.length === 6 &&
                                errors.code
                            )}
                            fullWidth
                            autoComplete="off"
                            inputRef={(el: HTMLElement) =>
                              (itemsRef.current[i] = el)
                            }
                            key={`codeNumber-${i}`}
                            name={`code[${i}]`}
                            type={
                              isMediumScreenSizeOrSmaller ? 'number' : 'text'
                            }
                            inputMode="numeric"
                            onBlur={handleBlur}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                handleSubmit();
                              }

                              if (event.key === 'ArrowLeft') {
                                if (i > 0) {
                                  itemsRef.current[i - 1].focus();
                                }
                              }

                              if (event.key == 'ArrowRight') {
                                if (i < 5) {
                                  itemsRef.current[i + 1].focus();
                                }
                              }

                              if (
                                event.key == 'Backspace' ||
                                event.key == 'Delete'
                              ) {
                                setFieldValue(`code[${i}]`, '');

                                if (i > 0) {
                                  itemsRef.current[i - 1].focus();
                                }
                              }

                              if (Number.isInteger(parseInt(event.key, 10))) {
                                setFieldValue(`code[${i}]`, event.key);

                                if (i < 5) {
                                  itemsRef.current[i + 1].focus();
                                }
                              }
                            }}
                            onPaste={(event) => {
                              const paste = event.clipboardData.getData('text');
                              const pasteArray = paste.split('');

                              if (pasteArray.length !== 6) {
                                return;
                              }

                              let valid = true;

                              pasteArray.forEach((x) => {
                                if (!Number.isInteger(parseInt(x, 10))) {
                                  valid = false;
                                }
                              });

                              if (valid) {
                                setFieldValue('code', pasteArray);
                                itemsRef.current[5].focus();
                              }
                            }}
                            value={values.code[i]}
                            variant="outlined"
                          />
                        </IonCol>
                      ))}
                    {!isSmallScreenSizeOrSmaller &&
                      Boolean(
                        Array.isArray(touched.code) &&
                          touched.code.length === 6 &&
                          errors.code
                      ) && (
                        <FormHelperText error>
                          {Array.isArray(errors.code) &&
                            errors.code.find((x) => x !== undefined)}
                        </FormHelperText>
                      )}
                  </IonRow>
                  {errors.submit && (
                    <IonRow>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </IonRow>
                  )}
                  <IonRow>
                    <button
                      className="button no-caps"
                      disabled={_isLoading}
                      type="submit"
                    >
                      {(_isLoading && (
                        <IonSpinner color="white" name="crescent" />
                      )) || <p>Continue</p>}
                    </button>
                  </IonRow>
                </form>
              )}
            </Formik>
            {resentCode && (
              <IonRow>
                <p
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    fontFamily: 'nexa',
                  }}
                >
                  Not receiving our verification codes? Click{' '}
                  <a href={errorMailTo} style={{ fontFamily: 'nexa-bold' }}>
                    here
                  </a>{' '}
                  to contact support
                </p>
              </IonRow>
            )}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmailPage;
