import { IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';

import { isLimitedPlan } from '@faredrop/utilities';

import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDevice } from '../hooks/useDevice';
import ResponsiveModal from './ResponsiveModal';
import {
  HIDE_MEMBERSHIP_LINKS,
  SubscriptionPromotionUpgradeCTA,
} from '../utilities/constants';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';
import useUser from '../hooks/user';

interface UpgradeModalProps {
  title?: string;
  message?: string;
  search?: string;
  onClose: () => void;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({
  title,
  message,
  search,
  onClose,
}) => {
  const { isMediumScreenSizeOrSmaller } = useDevice();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const userState = useUser();

  const [nav, setNav] = useState<string>();

  useEffect(() => {
    if (nav) {
      goWithStickyParamsLocation({
        pathname: nav,
        search,
      });
    }
  }, [nav]);

  return (
    <ResponsiveModal
      active={message != null || title != null}
      onClose={onClose}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h3 style={{ textAlign: 'center' }}>
          {title ??
            (HIDE_MEMBERSHIP_LINKS ? 'Pro Plan Required' : 'Upgrade Required')}
        </h3>
        {!!message && (
          <p style={{ textAlign: 'center', lineHeight: '1.25em' }}>{message}</p>
        )}
        {!HIDE_MEMBERSHIP_LINKS &&
          isFrontendSubscriptionPromotion(
            isLimitedPlan(userState.user?.billing.idStripePlan)
          ) && (
            <div
              style={{
                marginBottom: '1em',
                color: 'var(--ion-color-gray)',
                textAlign: 'center',
              }}
            >
              {SubscriptionPromotionUpgradeCTA}
            </div>
          )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: isMediumScreenSizeOrSmaller ? 'column' : 'row',
              marginTop: '1em',
            }}
          >
            <IonButton
              className="button no-caps"
              style={{
                marginLeft: isMediumScreenSizeOrSmaller ? undefined : '1em',
                opacity: 1.0,
                marginTop: isMediumScreenSizeOrSmaller ? '1em' : 0,
                width: '15em',
                height: '3em',
              }}
              onClick={async () => {
                if (!HIDE_MEMBERSHIP_LINKS) {
                  setNav('/plans');
                }
                onClose();
              }}
            >
              <p style={{ color: 'white' }}>
                {HIDE_MEMBERSHIP_LINKS ? 'Close' : 'Upgrade'}
              </p>
            </IonButton>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default UpgradeModal;
