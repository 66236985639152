import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { useEffect } from 'react';
import './../theme/LoginPage.css';
import './../theme/util.css';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

const Redirecting: React.FC = () => {
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  useEffect(() => {
    if (!location.search.includes('?')) {
      goWithStickyParamsPath(AllowedPath.BASE);
    }
  }, []);

  return (
    <IonPage>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            transition: 'opacity 800ms',
            textAlign: 'center',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8">
            <IonRow className="row-vertical-align">
              <h1
                className="title-font"
                style={{
                  textAlign: 'center',
                  fontSize: '5em',
                  color: 'var(--ion-color-primary)',
                }}
              >
                Redirecting...
              </h1>
            </IonRow>
            <IonRow className="row-vertical-align">
              <h4 style={{ fontFamily: 'nexa-bold' }}>
                To something awesome, one moment please...
              </h4>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Redirecting;
