import { PushNotifications } from '@capacitor/push-notifications';
import {
  IonCol,
  IonContent,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  useIonViewDidEnter,
} from '@ionic/react';
import { PermissionState } from '@capacitor/core';
import { closeOutline, warningOutline } from 'ionicons/icons';
import {
  NotificationKinds,
  NotificationMethods,
  UserConfigNotificationKind,
} from '@faredrop/graphql-sdk';
import { useEffect, useState } from 'react';
import useNotifications from '../hooks/notifications';
import usePushNotifications from '../hooks/usePushNotifications';
import StorageService from '../services/StorageService';
import { NotificationSettingsStep } from '../types/types';
import './../theme/NotificationSettingsModal.css';
import FabButton from './FabButton';
import Loading from './Loading';
import NotificationOptionRow, {
  NotificationSetting,
} from './NotificationOptionRow';
import useUser from '../hooks/user';
import useAnalytics from '../hooks/analytics';
import { useDevice } from '../hooks/useDevice';
import usePresentToast from '../hooks/presentToast';
import useLogError from '../hooks/logError';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  isOpen: boolean;
  onClose: () => void;
  step?: NotificationSettingsStep;
}

// Note: Not to be confused with NotificationModal!
// This modal is the modal that slides up from the bottom on first user login
// on the native app and gives the user to set their push notification permissions
const NotificationSettingsModal: React.FC<ContainerProps> = ({
  isOpen,
  onClose,
  step,
}) => {
  const { isApp } = useDevice();
  const { clearNotifications } = useNotifications();
  const [_step, setStep] = useState(step);
  const { registerAndListen, togglePushNotifications } = usePushNotifications();
  const [isSaving, setIsSaving] = useState(false);
  const { requestAppTracking } = useAnalytics();
  const { presentError } = usePresentToast();
  const [pushNotificationState, setPushNotificationState] =
    useState<PermissionState>();
  const [pushNotificationsEnabled, setPushNotificationsEnabled] =
    useState(true); // This modal is only shown when Push Notifications haven't been prompted yet, so default to true
  const { logError } = useLogError();

  const userState = useUser();
  const userProfile = userState.user?.profile;
  const userConfigNotificationMethods =
    userState.user?.configuration.notificationMethods ?? [];
  const userConfigNotificationKinds =
    userState.user?.configuration.notificationKinds ?? [];

  const [notificationMethods] = useState(userConfigNotificationMethods);
  const [notificationKinds, setNotificationKinds] = useState(
    userConfigNotificationKinds
  );

  useIonViewDidEnter(() => {
    const setFlagInSessionStorage = async () => {
      await StorageService.getInstance().setData(
        'hasSeenNotificationSettingsModal',
        true
      );
    };

    setFlagInSessionStorage().catch((err) => {
      logError(
        'Failed to set hasSeenNotificationSettingsModal',
        err && (err as Error).message
          ? (err as Error).message
          : 'Error or error message is undefined'
      ).catch((error) =>
        console.warn(
          'Failed to send hasSeenNotificationSettingsModal error to log endpoint',
          error
        )
      );
    });
  }, []);

  useEffect(() => {
    setStep(step);
  }, [step]);

  useEffect(() => {
    if (_step === NotificationSettingsStep.SET_NOTIFICATION_METHODS) {
      // Push Notification permissions check needs to come after push notification prompt
      PushNotifications.checkPermissions()
        .then(async (status) => {
          setPushNotificationState(status.receive);
          if (status.receive !== 'granted') {
            setPushNotificationsEnabled(false);
          }
        })
        .catch(() =>
          presentError(
            'Failed to check for push notification permissions - please refresh the app and try again'
          )
        );
    }
  }, [_step]);

  const toggleNotificationKinds = async (method: NotificationSetting) => {
    const updatedKinds = userConfigNotificationKinds.map(
      (_method: UserConfigNotificationKind) => {
        if (method.name === _method.name) {
          return {
            name: method.name,
            enabled: !method.enabled,
          } as UserConfigNotificationKind;
        } else {
          return _method;
        }
      }
    );

    setNotificationKinds(updatedKinds);
  };

  return (
    <IonModal
      isOpen={isOpen}
      css-class="notifications-modal"
      initialBreakpoint={0.75}
      onDidDismiss={async () => {
        await requestAppTracking();
      }}
    >
      <IonContent>
        <IonRow>
          <FabButton
            onClick={async () => {
              await clearNotifications();
              onClose();
            }}
            color="primary"
          >
            <IonIcon icon={closeOutline} color="white" size="large" />
          </FabButton>
          <IonRow>
            <IonCol>
              {_step == NotificationSettingsStep.LOADING ? (
                <Loading />
              ) : _step == NotificationSettingsStep.ENABLE_PUSH ? (
                <IonRow style={{ padding: '2em 2em' }}>
                  <IonCol>
                    <IonRow style={{ marginBottom: '2em' }}>
                      <h3>Push notifications</h3>
                    </IonRow>
                    <IonRow
                      className="row-vertical-align"
                      style={{ textAlign: 'center' }}
                    >
                      <img
                        src={`${getAssetsBaseUrl()}/icons/faredrop.svg`}
                        style={{
                          width: '60px',
                          margin: '0px 8px 5px 5px',
                          color: 'green',
                        }}
                      />
                      <p
                        style={{
                          fontFamily: 'nexa',
                          fontSize: 14,
                          lineHeight: '2em',
                        }}
                      >
                        For the best FareDrop experience, we recommend enabling
                        push notifications so that you can have quick access to
                        the best deals!
                      </p>
                      <button
                        className="button"
                        style={{ maxWidth: '20em', marginBottom: '10em' }}
                        onClick={async () => {
                          let status =
                            await PushNotifications.checkPermissions();
                          if (
                            status.receive === 'prompt' ||
                            status.receive === 'prompt-with-rationale'
                          ) {
                            status =
                              await PushNotifications.requestPermissions();
                          }

                          if (status.receive === 'granted') {
                            await registerAndListen();
                          }

                          if (status.receive !== 'prompt') {
                            const usersThatHaveSetPermissions =
                              (await StorageService.getInstance().getData(
                                'usersThatHaveSetPermissions'
                              )) ?? [];
                            usersThatHaveSetPermissions.push(
                              userProfile?.email
                            );
                            await StorageService.getInstance().setData(
                              'usersThatHaveSetPermissions',
                              usersThatHaveSetPermissions
                            );
                          }

                          setStep(
                            NotificationSettingsStep.SET_NOTIFICATION_METHODS
                          );
                        }}
                      >
                        Enable push notifications
                      </button>
                    </IonRow>
                  </IonCol>
                </IonRow>
              ) : (
                <IonRow
                  style={{
                    paddingRight: '2em',
                    paddingLeft: '2em',
                    paddingTop: '5em',
                    paddingBottom: '20em',
                  }}
                  className="row-vertical-align"
                >
                  <IonCol>
                    {!userState.hasDealNotificationsEnabled &&
                      pushNotificationsEnabled !== true && (
                        <IonRow className="row-vertical-align">
                          <p
                            style={{
                              fontSize: '12',
                              textAlign: 'center',
                              color: 'var(--ion-color-danger)',
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: 'middle',
                                marginRight: '.5em',
                              }}
                            >
                              <IonIcon icon={warningOutline} color="danger" />
                            </span>
                            No notifications enabled!
                          </p>
                        </IonRow>
                      )}
                    <IonRow>
                      <h6
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          color: 'var(--ion-color-primary)',
                          fontSize: 16,
                        }}
                      >
                        How can we notify you about the great deals that we
                        find?
                      </h6>
                      <p
                        style={{
                          fontFamily: 'nexa',
                          margin: '0 1em 1em',
                          textAlign: 'center',
                          width: '100%',
                          color: '#555',
                          fontSize: 14,
                        }}
                      >
                        You can always change this later in your account
                        settings if you change your mind!
                      </p>
                    </IonRow>
                    <hr
                      style={{
                        borderTop: '1px solid rgba(202, 202, 202, 1)',
                        width: '100%',
                        margin: '1em 0 1.5em',
                      }}
                    />
                    <NotificationOptionRow
                      setting={{
                        name: NotificationKinds.Deals,
                        label: 'Email',
                        enabled:
                          (notificationMethods.find(
                            (nm) => nm.name === NotificationMethods.Email
                          )?.enabled &&
                            notificationKinds.find(
                              (nk) => nk.name === NotificationKinds.Deals
                            )?.enabled) ??
                          false,
                      }}
                      onToggle={toggleNotificationKinds}
                    />
                    {isApp && (
                      <NotificationOptionRow
                        setting={{
                          name: 'PUSH',
                          enabled: pushNotificationsEnabled,
                        }}
                        onToggle={async (setting) => {
                          if (pushNotificationState !== 'denied') {
                            setPushNotificationsEnabled(!setting.enabled);
                          }
                          const updatedState = await togglePushNotifications(
                            !setting.enabled
                          );
                          if (
                            updatedState &&
                            pushNotificationState !== updatedState
                          ) {
                            setPushNotificationState(updatedState);
                          }
                        }}
                      />
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        marginTop: '3em',
                      }}
                    >
                      <button
                        className="button"
                        style={{
                          margin: 'auto',
                          textAlign: 'center',
                          width: '15em',
                        }}
                        disabled={isSaving}
                        onClick={async () => {
                          setIsSaving(true);
                          await userState.setNotificationKinds(
                            notificationKinds
                          );
                          onClose();
                          setIsSaving(false);
                        }}
                      >
                        {(isSaving && (
                          <IonSpinner color="white" name="crescent" />
                        )) || <p>Save</p>}
                      </button>
                    </div>
                  </IonCol>
                </IonRow>
              )}
            </IonCol>
          </IonRow>
        </IonRow>
      </IonContent>
    </IonModal>
  );
};

export default NotificationSettingsModal;
