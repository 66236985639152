import {
  CognitoIdToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { FareDropRole, FareDropUser } from '@faredrop/types';

export default class CognitoUserAccessor {
  user: CognitoUser;

  constructor(user: CognitoUser) {
    this.user = user;
  }

  async getEmail(): Promise<string> {
    return await this._getAttribute('email');
  }

  async getFirstName(): Promise<string> {
    return await this._getAttribute('given_name');
  }

  async getId(): Promise<string> {
    return await this._getAttribute('sub');
  }

  async getPhoneNumber(): Promise<string> {
    return await this._getAttribute('phone_number');
  }

  async getIdToken(): Promise<string> {
    const encodedToken = await this._getEncodedIdToken();
    return encodedToken.getJwtToken();
  }

  async getLastName(): Promise<string> {
    return await this._getAttribute('family_name');
  }

  async getFareDropUser(): Promise<FareDropUser> {
    return new Promise<FareDropUser>((resolve, reject) => {
      this.user.getUserAttributes(
        async (
          error: Error | undefined,
          attributes: CognitoUserAttribute[] | undefined
        ) => {
          if (error) reject(error);

          return resolve({
            id:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'sub')
                ?.getValue() ?? '',
            email:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'email')
                ?.getValue() ?? '',
            firstName:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'given_name')
                ?.getValue() ?? '',
            lastName:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'family_name')
                ?.getValue() ?? '',
            roles: await this.getGroups(),
            phoneNumber:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'phone_number')
                ?.getValue() ?? '',
            isEmailVerified:
              attributes
                ?.find((a: CognitoUserAttribute) => a.Name === 'email_verified')
                ?.getValue() === 'true',
          });
        }
      );
    });
  }

  async getGroups(): Promise<FareDropRole[]> {
    const encodedToken = await this._getEncodedIdToken();
    const decoded = encodedToken.decodePayload();
    const groups = decoded['cognito:groups'] ?? [];
    return groups.map((group: string) => group as FareDropRole);
  }

  private async _getAttribute(attribute: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.user.getUserAttributes(
        (
          error: Error | undefined,
          attributes: CognitoUserAttribute[] | undefined
        ) => {
          if (error) reject(error);
          const desiredAttributes: CognitoUserAttribute[] =
            attributes?.filter(
              (a: CognitoUserAttribute) => a.Name === attribute
            ) ?? [];

          resolve(desiredAttributes[0]?.Value);
        }
      );
    });
  }

  private _getEncodedIdToken(): Promise<CognitoIdToken> {
    return new Promise((resolve, reject) => {
      this.user.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error) reject(error);
          else if (session) {
            resolve(session.getIdToken());
          }
          reject(new Error('Session is null'));
        }
      );
    });
  }
}
