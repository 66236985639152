// cspell:ignore Gvtg
import {
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import './../theme/Home.css';
import './../theme/Offer.css';
import { useState } from 'react';
import useAnalytics from '../hooks/analytics';
import HomePageDealsSection from '../components/HomePageDealsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import SocialProofSection from '../components/SocialProofSection';
import TrackVisibility from '../components/TrackVisibility';
import Footer from '../components/Footer';
import { FareDropPlan, FareDropRole } from '@faredrop/graphql-sdk';
import { applyPromotion, isGiftPromotion } from '@faredrop/utilities';
import { SubscriptionPlanCard } from '../components/SubscriptionPlanCard';
import useGifts from '../hooks/useGifts';
import PurchaseGift from '../components/PurchaseGift';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import GiftPaperAirplane from '../components/GiftPaperAirplane';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { SubscriptionPlan as SubscriptionPlanGQL } from '@faredrop/graphql-sdk';
import Loading from '../components/Loading';
import useUser from '../hooks/user';
import DynamicBanner from '../components/DynamicBanner';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useCodeQueryParam } from '../hooks/useCodeQueryParam';
import {
  buildFrontendSubscriptionPlans,
  calculateDiscountPrice,
  convertSubscriptionPlanTypeToGQL,
  getPlanName,
} from '../utilities/plans-utilities';
import { useDisplayDiscounts } from '../hooks/useDisplayDiscounts';
import { SubscriptionPlan } from '@faredrop/types';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const GiftFareDrop: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const [selectedPlanType, setSelectedPlanType] = useState<FareDropPlan>();
  const {
    logAnalyticsCheckout,
    logAnalyticsGiftEngagement,
    logAnalyticsGiftPageSectionView,
  } = useAnalytics();
  const { createGiftCheckoutUrl } = useGifts();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { user } = useUser();
  const dynamicBannerContent = useDynamicBanner();
  const { couponQueryParam } = useCodeQueryParam();
  const { discounts } = useDisplayDiscounts();

  const plans = buildFrontendSubscriptionPlans();

  return (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <DynamicBanner
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
      />
      <IonRow
        style={{
          position: 'absolute',
          top: dynamicBannerContent ? 50 : 0,
          zIndex: 100,
          backgroundColor: 'white',
          width: '100%',
          padding: isLargeScreenSizeOrSmaller ? undefined : '1em',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <IonHeader
            className="ion-no-border"
            style={{
              marginTop: 0,
              marginBottom: 0,
              backgroundColor: '#ffffff',
              marginLeft: 0,
              marginRight: 0,
              padding: 5,
              boxShadow: '0px 6px 10px rgba(0, 0, 0, 15%)',
              borderBottom: '1px solid #ccc',
            }}
          >
            <IonToolbar>
              <MobileHeader onMenuClickHandler={onMenuClickHandler} />
            </IonToolbar>
          </IonHeader>
        ) : (
          <DesktopHeader optionsHidden={true} ctaHidden={true} />
        )}
      </IonRow>
      <IonContent>
        <IonRow>
          <IonCol
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <h2 className="title-font mt-3">
              Gift an opportunity to travel the world
            </h2>
            <h2 className="title-font">with a FareDrop subscription</h2>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 800ms',
            textAlign: 'center',
            marginBottom: '2em',
          }}
        >
          <IonCol
            size="12"
            className="offer-container"
            style={{ justifyContent: 'center' }}
          >
            <IonRow className="row-vertical-align">
              {(!plans || plans.length === 0) && <Loading />}
              {(plans ?? [])
                .filter((plan) => plan.planType !== FareDropPlan.Limited)
                .map((cardPlan) => {
                  const plan = isGiftPromotion()
                    ? convertSubscriptionPlanTypeToGQL(
                        applyPromotion(cardPlan as SubscriptionPlan)
                      )
                    : cardPlan;

                  return (
                    <SubscriptionPlanCard
                      key={plan.id}
                      plan={plan}
                      discountPrice={calculateDiscountPrice(
                        plan,
                        undefined,
                        discounts
                      )}
                      border={
                        selectedPlanType?.toUpperCase() === plan.planType
                          ? '2px solid var(--ion-color-primary)'
                          : undefined
                      }
                      billingText=""
                      buttonText={
                        selectedPlanType?.toUpperCase() === plan.planType
                          ? 'Selected'
                          : 'Select'
                      }
                      buttonTextColor={
                        selectedPlanType?.toUpperCase() === plan.planType
                          ? 'white'
                          : undefined
                      }
                      buttonColor={
                        selectedPlanType?.toUpperCase() === plan.planType
                          ? 'var(--ion-color-primary)'
                          : undefined
                      }
                      buttonOnClickListener={async () => {
                        setSelectedPlanType(plan.planType as FareDropPlan);
                        document
                          .querySelector(`#gifts-redeem-title`)
                          ?.scrollIntoView({
                            behavior: 'smooth',
                          });
                      }}
                    />
                  );
                })}
            </IonRow>
          </IonCol>
        </IonRow>
        {/* <h4
          style={{
            fontFamily: 'nexa',
            textAlign: 'center',
            marginBottom: '3em',
            marginLeft: '2em',
            marginRight: '2em',
          }}
        >
          All plans include our{' '}
          <a
            id="gifts-refund-policy"
            style={{ fontWeight: 'bold', cursor: 'auto' }}
            // TODO: Link to FAQ instead
            // onClick={() => {
            //   goWithStickyParamsPath('/refund-policy');
            //   logAnalyticsGiftEngagement(
            //     AnalyticsEngagementId.TODO
            //   );
            // }}
          >
            no questions asked, 90 day refund policy
          </a>
        </h4> */}
        <h4
          id="gifts-redeem-title"
          style={{
            fontFamily: 'nexa',
            textAlign: 'center',
            marginBottom: '3em',
            marginLeft: '2em',
            marginRight: '2em',
          }}
        >
          Already have a gift code? Redeem your gift{' '}
          <a
            onClick={() => {
              let params = '?';
              if (user?.profile) {
                params += `firstName=${encodeURIComponent(
                  user.profile.firstName
                )}&email=${encodeURIComponent(user.profile.email)}`;
                if (user.profile.lastName) {
                  params += `&lastName=${encodeURIComponent(
                    user.profile.lastName
                  )}`;
                }
                if (user?.profile.roles.includes(FareDropRole.Limited)) {
                  params += `&hasActiveSubscription=true`;
                }
              }

              goWithStickyParamsLocation({
                pathname: '/redeem',
                search: params,
              });
            }}
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
          >
            here.
          </a>
        </h4>
        {selectedPlanType && (
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginBottom: '8em',
              marginTop: '8em',
              marginLeft: '1em',
              marginRight: '1em',
            }}
            id="gift-checkout"
          >
            <div style={{ maxWidth: '500px' }}>
              <div>
                <h3 className="title-font" style={{ textAlign: 'center' }}>
                  Gift the {getPlanName(selectedPlanType)} Plan
                </h3>
              </div>
              <PurchaseGift
                onSubmit={async (purchaser, giftee) => {
                  const plan: SubscriptionPlanGQL | undefined = plans?.find(
                    (plan) => plan.planType === selectedPlanType
                  );
                  const analyticsPromises: [
                    Promise<string>,
                    Promise<void>,
                    Promise<void>
                  ] = [
                    createGiftCheckoutUrl(
                      selectedPlanType,
                      purchaser,
                      giftee,
                      couponQueryParam
                    ),
                    plan
                      ? logAnalyticsCheckout(plan)
                      : new Promise((resolve) => resolve()),
                    logAnalyticsGiftEngagement(
                      `purchase_${selectedPlanType}_gift` as AnalyticsEngagementId
                    ),
                  ];
                  const results = await Promise.all(analyticsPromises);
                  window.location.assign(results[0]);
                }}
              />
            </div>
          </IonRow>
        )}

        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsGiftPageSectionView('paper_airplane')
          }
        >
          <GiftPaperAirplane
            logAnalyticsEngagement={logAnalyticsGiftEngagement}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsGiftPageSectionView('how_it_works')
          }
        >
          <HowItWorksSection sx={{ paddingTop: 0 }} />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () => await logAnalyticsGiftPageSectionView('deals')}
        >
          <HomePageDealsSection
            logAnalyticsEngagement={logAnalyticsGiftEngagement}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsGiftPageSectionView('social_proof')
          }
        >
          <SocialProofSection
            logAnalyticsEngagement={logAnalyticsGiftEngagement}
          />
        </TrackVisibility>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsGiftPageSectionView('footer')
          }
        >
          <Footer
            bgColor="secondary"
            isVisible={true}
            onLinkPress={logAnalyticsGiftEngagement}
          />
        </TrackVisibility>
      </IonContent>
    </IonPage>
  );
};

export default GiftFareDrop;
