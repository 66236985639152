import { IonCol, IonImg, IonRow } from '@ionic/react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import './../theme/CreatedBySection.css';
import './../theme/util.css';
import ScrollYouTube from './ScrollYouTube';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface CreatedByProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
}

const CreatedBySection: React.FC<CreatedByProps> = ({
  logAnalyticsEngagement,
}) => {
  return (
    <IonRow id="createdBy" className="created-by-container">
      <IonCol size="6" className="created-by-bg-container desktop">
        <img
          src={`${getAssetsBaseUrl()}/backgrounds/kara-and-nate-grid.png`}
          style={{ position: 'absolute', height: '100%' }}
        />
        <div style={{ position: 'relative' }}>
          <ScrollYouTube videoId="C1mMeUPQtDE" className="overlap-image" />
          <img
            className="watch-the-highlight-reel"
            src={`${getAssetsBaseUrl()}/graphics/watch-the-highlight-reel-text.png`}
          />
        </div>
      </IonCol>
      <IonCol size="12" sizeXl="6">
        <h2 className="created-by-text title-font ml-4 mt-3 p-0">
          Created by expert travelers
        </h2>
        <p className="created-by-text gray lh-2 ml-4">
          Hi! We&apos;re Kara and Nate. High school sweet hearts from Nashville,
          TN whose shared love of travel has led us on the adventure of a
          lifetime. In 2016 we quit our jobs to spend one year traveling around
          the world. Six years later, we still haven&apos;t stopped! We&apos;ve
          visited over 100 countries, and been fortunate enough to build an
          awesome community of subscribers on our{' '}
          <span
            className="primary-color"
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              window.open(
                'https://www.youtube.com/channel/UC4ijq8Cg-8zQKx8OH12dUSw',
                '_blank'
              );
              logAnalyticsEngagement &&
                (await logAnalyticsEngagement(
                  AnalyticsEngagementId.CREATED_BY_YOUTUBE_CHANNEL
                ));
            }}
          >
            YouTube channel
          </span>
          .
          <br />
          <br />
          After traveling to over 100 countries we&apos;ve learned a thing or
          two about finding great flight deals - we&apos;re so happy to share
          our secret weapon with you!
          <br />
          <br />
          <span style={{ float: 'right' }}>Cheers from somewhere,</span>
        </p>
        <br />
        <svg
          className="ml-4"
          width="224"
          height="93"
          viewBox="0 0 224 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            float: 'right',
            marginRight: '2em',
            opacity: 0.8,
          }}
        >
          <path
            d="M20.2972 18.6293L7.4687 84.6215L44.6935 16.5589C21.7502 50.8305 -12.7836 111.996 32.6274 82.4864C78.0384 52.9766 84.4244 35.3307 81.941 30.1964L117.435 68.417L136.18 8.79486C134.408 36.1792 133.022 87.4052 141.648 73.2342C150.274 59.0633 176.551 32.0937 159.723 41.921C161.757 41.7485 167.652 41.2481 174.971 40.627C184.12 39.8506 183.476 32.2695 189.897 35.5425C195.034 38.1608 210.279 22.3592 217.259 14.1311"
            stroke="black"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IonCol>
      <IonCol size="12" className="created-by-bg-container mobile mt-7">
        <IonImg
          src={`${getAssetsBaseUrl()}/backgrounds/kara-and-nate-grid.png`}
          style={{ position: 'absolute', height: '100%' }}
        />
        <div style={{ position: 'relative' }}>
          <ScrollYouTube videoId="C1mMeUPQtDE" className="overlap-image" />
          <img
            className="watch-the-highlight-reel"
            src={`${getAssetsBaseUrl()}/graphics/watch-the-highlight-reel-text.png`}
          />
        </div>
      </IonCol>
    </IonRow>
  );
};

export default CreatedBySection;
