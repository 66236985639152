import { IonRow, IonCol } from '@ionic/react';
import { useDevice } from '../hooks/useDevice';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

export enum NoDeals {
  NO_ORIGINS,
  FILTERED,
}

interface ContainerProps {
  sx?: React.CSSProperties;
  text?: string;
  type?: NoDeals;
}

const NoDealsText: React.FC<ContainerProps> = ({ sx, text, type }) => {
  const { isSmallScreenSizeOrSmaller } = useDevice();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();

  let defaultText = 'No active deals. Check back later!';
  if (type === NoDeals.NO_ORIGINS)
    defaultText = 'No departure airports selected';
  if (type === NoDeals.FILTERED)
    defaultText = 'All deals have been filtered out';

  return (
    <IonRow
      className="row-vertical-align"
      style={{
        height: isSmallScreenSizeOrSmaller ? '16em' : '20em',
        textAlign: 'center',
        ...sx,
      }}
    >
      <IonCol size={isSmallScreenSizeOrSmaller ? '10' : '6'}>
        <IonRow className="row-vertical-align">
          <h3
            style={{
              color: isSmallScreenSizeOrSmaller ? '#ccc' : '#222',
              margin: '0',
              fontSize: isSmallScreenSizeOrSmaller ? 16 : 22,
            }}
          >
            {text ?? defaultText}
          </h3>
        </IonRow>
        {!isSmallScreenSizeOrSmaller && (
          <IonRow className="row-vertical-align">
            <h6 style={{ color: '#222', fontFamily: 'nexa' }}>
              {!type ? 'Not seeing the deals you want?' : ''} Click&nbsp;
              <a
                onClick={() =>
                  goWithStickyParamsPath(
                    type === NoDeals.NO_ORIGINS
                      ? AllowedPath.SETTINGS_DEPARTURE_AIRPORTS
                      : type === NoDeals.FILTERED
                      ? AllowedPath.SETTINGS_DEAL_PREFERENCES
                      : AllowedPath.SETTINGS_DESTINATION_PREFERENCES
                  )
                }
                style={{ fontFamily: 'nexa-bold' }}
              >
                here
              </a>
              &nbsp;to edit your Deal Settings.
            </h6>
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
};

export default NoDealsText;
