import useSWR from 'swr';

import useAnalytics from './analytics';
import useAuth from './auth';
import useFareDropApiClient from './faredropApiClient';

const usePerks = () => {
  const { client } = useFareDropApiClient();
  const { logAnalyticsError } = useAnalytics();
  const { user } = useAuth();

  const userPerksKey = (userId: string) => [
    'PERKS',
    process.env.REACT_APP_VERSION ?? '',
    userId,
  ];

  let requestKey: string[] | null = null;
  if (user?.id) {
    requestKey = userPerksKey(user.id);
  }

  const { data, error, isValidating } = useSWR(requestKey, async () => {
    try {
      const result = await client.perks();
      return result.data.perks;
    } catch (error) {
      await logAnalyticsError('get perks', error as Error);
      throw error;
    }
  });

  const redeemFareDropTShirt = async () => {
    const response = await client.redeemFareDropTShirt();
    return response.data.redeemFareDropTShirt.coupons ?? [];
  };

  const redeemLonelyPlanetEbook = async () => {
    const response = await client.redeemLonelyPlanetEbook();
    return response.data.redeemLonelyPlanetEbook.coupon ?? undefined;
  };

  return {
    perks: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
    redeemFareDropTShirt,
    redeemLonelyPlanetEbook,
  };
};

export default usePerks;
