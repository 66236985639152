import { createContext, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { sha1, sleep, ScriptLoader } from '@faredrop/utilities';

import useAnalytics from '../hooks/analytics';

export interface ImpactContextValue {
  impactIdentify: (idCognito?: string, email?: string) => Promise<void>;
  getImpactClickId: () => Promise<string | undefined>;
}

// Since we set the ImpactProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const ImpactContext = createContext<ImpactContextValue>({
  impactIdentify: () => Promise.resolve(),
  getImpactClickId: () => Promise.resolve(undefined),
});

interface ImpactProps {
  children: ReactNode;
}

interface IImpactPayload {
  method: 'identify' | 'generateClickId';
  payload:
    | { customerId: string; customerEmail: string }
    | ((clickId: string) => void);
}

type IImpactIre =
  | ((
      method: 'identify' | 'generateClickId',
      data:
        | { customerId: string; customerEmail: string }
        | ((clickId: string) => void)
    ) => void | undefined)
  | undefined;

export const ImpactProvider: FC<ImpactProps> = (props) => {
  const { logAnalyticsError } = useAnalytics();

  const [impactInitialized, setImpactInitialized] = useState(false);
  const impactInitializedRef = useRef(false);

  const cachedClickId = useRef<string>();

  const impact = useRef<IImpactIre>();
  const impactQueue = useRef<IImpactPayload[]>([]);

  useEffect(() => {
    const impactLoader = new ScriptLoader({
      src: 'utt.impactcdn.com/A4951555-5d25-44b7-8ea2-140c481cd5ea1.js', // cspell:disable-line
      protocol: 'https:',
      global: 'ire',
    });

    impactLoader
      .load()
      .then((ire) => {
        impact.current = ire as IImpactIre | undefined;
      })
      .catch((e) => {
        console.warn(`Impact initialization error: ${JSON.stringify(e)}`);
      })
      .finally(() => {
        setImpactInitialized(true);
        impactInitializedRef.current = true;
      });
  }, []);

  useEffect(() => {
    if (impactInitialized) {
      if (impact.current) {
        for (const item of impactQueue.current) {
          impact.current(item.method, item.payload);
        }
      }

      // Cache the click id if we aren't already queued to do so
      if (!impactQueue.current.some((i) => i.method === 'generateClickId')) {
        // Fire and forget - Set the cachedClickId
        getImpactClickId().catch((e) => {
          console.warn("Failed to get Impact's click id");
          console.warn(e);
        });
      }
    }
  }, [impactInitialized]);

  const impactIdentify = async (idCognito = '', email?: string) => {
    const method = 'identify';
    const payload = {
      customerId: idCognito,
      customerEmail: email?.length ? await sha1(email) : '',
    };

    if (impactInitialized) {
      if (impact.current) {
        impact.current(method, payload);
      }
    } else {
      impactQueue.current.push({
        method,
        payload,
      });
    }
  };

  const getImpactClickId = async (): Promise<string | undefined> => {
    if (cachedClickId.current) {
      return cachedClickId.current;
    } else {
      let generatedClickId: string | undefined = undefined;
      await Promise.race([
        new Promise<void>((resolve) => {
          checkReady()
            .then(() => {
              if (!impact.current) {
                resolve();
              } else {
                try {
                  impact.current('generateClickId', (clickId: string) => {
                    cachedClickId.current = clickId;
                    generatedClickId = clickId;
                    resolve();
                  });
                } catch (error) {
                  resolve();
                }
              }
            })
            .catch(resolve);
        }),
        new Promise((resolve) => setTimeout(resolve, 5000)),
      ]);
      return generatedClickId;
    }
  };

  const checkReady = async () => {
    const poller = async () => {
      let count = 0;
      let ready = false;
      do {
        count++;
        if (impactInitializedRef.current === true) {
          ready = true;
          return;
        }
        await sleep(500);
      } while (!ready && count < 10);
      throw new Error('Impact exceeded 5 second initialization limit');
    };

    return new Promise<void>((resolve, reject) => {
      poller()
        .then(resolve)
        .catch((error) => {
          logAnalyticsError('impactInitialization', error).catch(() => {
            console.warn('Failed to log Impact initialization error');
          });
          reject(error);
        });
    });
  };

  return (
    <ImpactContext.Provider
      value={{
        impactIdentify,
        getImpactClickId,
      }}
    >
      {props.children}
    </ImpactContext.Provider>
  );
};

export default ImpactContext;
