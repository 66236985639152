import { IonCol, IonContent, IonIcon, IonPage, IonRow } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { AsyncReturnType } from 'type-fest';

import { FareDropRole } from '@faredrop/graphql-sdk';
import { AllowedPath, FareDropPlan } from '@faredrop/types';
import { stripePlanIDByFareDropPlan } from '@faredrop/utilities';

import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import DesktopHeader from '../components/DesktopHeader';
import Loading from '../components/Loading';
import MobileHeader from '../components/MobileHeader';
import StoreBadges from '../components/StoreBadges';
import useAnalytics from '../hooks/analytics';
import useAnalyticsScreenName from '../hooks/analyticsScreenName';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useRefreshAccount from '../hooks/useRefreshAccount';
import useStripe from '../hooks/useStripe';
import './../theme/util.css';
import './../theme/Welcome.css';
import usePresentToast from '../hooks/presentToast';
import useUser from '../hooks/user';
import useAirports from '../hooks/airports';
import useSearchAirports from '../hooks/useSearchAirports';
import { userConfigOriginFromIata } from '../utilities/airports';
import { getFullHostUrl, stripTrailingSlash } from '../utilities/utils';

const Welcome: React.FC = () => {
  useAnalyticsScreenName(AllowedPath.GET_STARTED_WELCOME_FREE);
  const { isInitialized } = useAuth();
  const { logAnalyticsPurchase } = useAnalytics();
  const { getReceipt } = useStripe();
  const [fade, setFade] = useState(1.0);
  const { isMediumScreenSizeOrSmaller, isLargeScreenSizeOrSmaller, isApp } =
    useDevice();
  const [transitionToDeals, setTransitionToDeals] = useState(false);
  const [receipt, setReceipt] = useState<AsyncReturnType<typeof getReceipt>>();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const { presentError } = usePresentToast();
  const { isAccountReady } = useRefreshAccount(); // Hook to verify when all account creation webhooks are complete
  const userState = useUser();
  const { origins } = useAirports();
  const { findClosestHighTierAirport } = useSearchAirports();
  const [hasSetClosestHighTierAirport, setHasSetClosestHighTierAirport] =
    useState(false);
  const [redirectToDeal, setRedirectToDeal] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      const queryParams = new URLSearchParams(location.search);
      const queryCheckoutSessionId = queryParams.get('session_id');
      if (queryCheckoutSessionId) {
        getReceipt(queryCheckoutSessionId)
          .then(setReceipt)
          .catch(() => presentError('Failed to retrieve receipt information'));
      }

      // If we are redirecting to a deal, we don't want the CTA to say "Go to my Deals Dashboard"
      const redirect = queryParams.get('redirect');
      if (redirect) {
        const url = new URL(redirect, getFullHostUrl());
        const normalizedPath = stripTrailingSlash(url.pathname);
        if (normalizedPath === AllowedPath.DEAL_DETAILS) {
          setRedirectToDeal(true);
        }
      }
    }
  }, [isInitialized]);

  useEffect(() => {
    if (receipt) {
      logAnalyticsPurchase(receipt).catch((error) =>
        console.warn('Failed to log analytics purchase', error)
      );
    }
  }, [receipt]);

  useEffect(() => {
    const setClosestHighTierAirport = async () => {
      setHasSetClosestHighTierAirport(true);

      const homeAirportIATA = userState.user?.configuration.homeOriginIATA;
      const clientHomeAirport = origins?.find(
        (o) => o.iata === homeAirportIATA
      );
      if (clientHomeAirport) {
        const originalOriginsLength =
          userState.user?.configuration.origins.length;
        const originsConfig =
          userState.user?.configuration.origins.map((origin) => {
            const clientOrigin = origins?.find((o) => o.iata === origin.iata);
            if (!clientOrigin)
              throw new Error('User origin not found in list of origins');
            return userConfigOriginFromIata(clientOrigin.iata);
          }) ?? [];
        if (clientHomeAirport.tier && clientHomeAirport.tier > 2) {
          const closestHighTierAirportIata = await findClosestHighTierAirport(
            clientHomeAirport?.iata
          );
          if (closestHighTierAirportIata) {
            originsConfig.push(
              userConfigOriginFromIata(closestHighTierAirportIata)
            );
          }
        }

        if (originsConfig.length !== originalOriginsLength) {
          await userState.setOrigins(originsConfig);
        }
      }
    };

    const homeAirportIATA = userState.user?.configuration.homeOriginIATA;
    const idStripePlan = userState.user?.billing.idStripePlan;
    const roles = userState.user?.profile.roles;
    if (
      isInitialized &&
      homeAirportIATA &&
      idStripePlan &&
      idStripePlan !== stripePlanIDByFareDropPlan(FareDropPlan.LIMITED) &&
      roles?.includes(FareDropRole.Pro) &&
      !hasSetClosestHighTierAirport
    ) {
      void setClosestHighTierAirport(); // Async - fire and forget
    }
  }, [
    isInitialized,
    userState.user?.configuration.homeOriginIATA,
    userState.user?.billing.idStripePlan,
    hasSetClosestHighTierAirport,
    userState.user?.profile,
  ]);

  return (
    (!isAccountReady && <Loading />) || (
      <IonPage>
        <IonRow
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 100,
            backgroundColor: 'white',
            width: '100%',
            padding: '1em',
          }}
        >
          {isLargeScreenSizeOrSmaller ? (
            <MobileHeader menuHidden={true} showLoadingDiv={true} />
          ) : (
            <DesktopHeader
              optionsHidden={true}
              ctaHidden={true}
              showLoadingDiv={true}
            />
          )}
        </IonRow>
        <IonContent
          className="welcome-page"
          style={{ opacity: fade, transition: 'opacity 800ms' }}
        >
          <IonRow
            className="row-vertical-align"
            style={{
              padding: '5em 2em 2em 2em',
            }}
          >
            <IonCol
              sizeXs="12"
              sizeLg="7"
              sizeXl="5"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ maxWidth: '650px' }}>
                <h1 className="title-font" style={{ textAlign: 'center' }}>
                  Welcome to FareDrop!
                </h1>
                {!isApp && (
                  <>
                    <IonRow className="row-vertical-align">
                      <IonCol size="10">
                        <p
                          style={{
                            lineHeight: '2em',
                            fontFamily: 'nexa',
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          <b style={{ fontFamily: 'nexa-bold' }}>
                            Did you know we have a mobile app?
                          </b>{' '}
                          If you haven&apos;t already, download the app to
                          browse flight deals on the go and receive
                          notifications right to your phone!
                        </p>
                      </IonCol>
                    </IonRow>
                    <StoreBadges />
                  </>
                )}
                <IonRow
                  className="row-vertical-align"
                  style={{
                    marginTop: '4em',
                    marginBottom: isMediumScreenSizeOrSmaller ? '2em' : 0,
                  }}
                >
                  <IonCol size="11" style={{ display: 'flex' }}>
                    <a
                      onClick={async () => {
                        setTransitionToDeals(true);
                        setFade(0);
                        goWithStickyParamsLocation(
                          {
                            pathname: AllowedPath.DEALS,
                            state: { newUser: true },
                          },
                          ['selected-plan']
                        );
                      }}
                      style={{
                        margin: 'auto',
                        display: 'flex',
                        lineHeight: '22px',
                        opacity: transitionToDeals ? 0 : 1.0,
                        transition: 'opacity 200ms',
                      }}
                    >
                      {redirectToDeal
                        ? 'Go to Deal'
                        : 'Go to my Deals Dashboard'}
                      <IonIcon
                        icon={arrowForwardOutline}
                        style={{ margin: '-1px auto auto 4px' }}
                      />
                    </a>
                  </IonCol>
                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    )
  );
};

export default Welcome;
