/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.DealDetailsProtoBuf = (function() {

    /**
     * Namespace DealDetailsProtoBuf.
     * @exports DealDetailsProtoBuf
     * @namespace
     */
    var DealDetailsProtoBuf = {};

    DealDetailsProtoBuf.DealDetailsQueryParam = (function() {

        /**
         * Properties of a DealDetailsQueryParam.
         * @memberof DealDetailsProtoBuf
         * @interface IDealDetailsQueryParam
         * @property {string} idAirfareSource DealDetailsQueryParam idAirfareSource
         * @property {string} originIATA DealDetailsQueryParam originIATA
         * @property {string} destinationIATA DealDetailsQueryParam destinationIATA
         * @property {DealDetailsProtoBuf.SeatClass} seatClass DealDetailsQueryParam seatClass
         * @property {DealDetailsProtoBuf.DealStatus} status DealDetailsQueryParam status
         */

        /**
         * Constructs a new DealDetailsQueryParam.
         * @memberof DealDetailsProtoBuf
         * @classdesc Represents a DealDetailsQueryParam.
         * @implements IDealDetailsQueryParam
         * @constructor
         * @param {DealDetailsProtoBuf.IDealDetailsQueryParam=} [properties] Properties to set
         */
        function DealDetailsQueryParam(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DealDetailsQueryParam idAirfareSource.
         * @member {string} idAirfareSource
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         */
        DealDetailsQueryParam.prototype.idAirfareSource = "";

        /**
         * DealDetailsQueryParam originIATA.
         * @member {string} originIATA
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         */
        DealDetailsQueryParam.prototype.originIATA = "";

        /**
         * DealDetailsQueryParam destinationIATA.
         * @member {string} destinationIATA
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         */
        DealDetailsQueryParam.prototype.destinationIATA = "";

        /**
         * DealDetailsQueryParam seatClass.
         * @member {DealDetailsProtoBuf.SeatClass} seatClass
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         */
        DealDetailsQueryParam.prototype.seatClass = 1;

        /**
         * DealDetailsQueryParam status.
         * @member {DealDetailsProtoBuf.DealStatus} status
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         */
        DealDetailsQueryParam.prototype.status = 1;

        /**
         * Creates a new DealDetailsQueryParam instance using the specified properties.
         * @function create
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {DealDetailsProtoBuf.IDealDetailsQueryParam=} [properties] Properties to set
         * @returns {DealDetailsProtoBuf.DealDetailsQueryParam} DealDetailsQueryParam instance
         */
        DealDetailsQueryParam.create = function create(properties) {
            return new DealDetailsQueryParam(properties);
        };

        /**
         * Encodes the specified DealDetailsQueryParam message. Does not implicitly {@link DealDetailsProtoBuf.DealDetailsQueryParam.verify|verify} messages.
         * @function encode
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {DealDetailsProtoBuf.IDealDetailsQueryParam} message DealDetailsQueryParam message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DealDetailsQueryParam.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.idAirfareSource);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.originIATA);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.destinationIATA);
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.seatClass);
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified DealDetailsQueryParam message, length delimited. Does not implicitly {@link DealDetailsProtoBuf.DealDetailsQueryParam.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {DealDetailsProtoBuf.IDealDetailsQueryParam} message DealDetailsQueryParam message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DealDetailsQueryParam.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DealDetailsQueryParam message from the specified reader or buffer.
         * @function decode
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DealDetailsProtoBuf.DealDetailsQueryParam} DealDetailsQueryParam
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DealDetailsQueryParam.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DealDetailsProtoBuf.DealDetailsQueryParam();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.idAirfareSource = reader.string();
                    break;
                case 2:
                    message.originIATA = reader.string();
                    break;
                case 3:
                    message.destinationIATA = reader.string();
                    break;
                case 4:
                    message.seatClass = reader.int32();
                    break;
                case 5:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("idAirfareSource"))
                throw $util.ProtocolError("missing required 'idAirfareSource'", { instance: message });
            if (!message.hasOwnProperty("originIATA"))
                throw $util.ProtocolError("missing required 'originIATA'", { instance: message });
            if (!message.hasOwnProperty("destinationIATA"))
                throw $util.ProtocolError("missing required 'destinationIATA'", { instance: message });
            if (!message.hasOwnProperty("seatClass"))
                throw $util.ProtocolError("missing required 'seatClass'", { instance: message });
            if (!message.hasOwnProperty("status"))
                throw $util.ProtocolError("missing required 'status'", { instance: message });
            return message;
        };

        /**
         * Decodes a DealDetailsQueryParam message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DealDetailsProtoBuf.DealDetailsQueryParam} DealDetailsQueryParam
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DealDetailsQueryParam.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DealDetailsQueryParam message.
         * @function verify
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DealDetailsQueryParam.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.idAirfareSource))
                return "idAirfareSource: string expected";
            if (!$util.isString(message.originIATA))
                return "originIATA: string expected";
            if (!$util.isString(message.destinationIATA))
                return "destinationIATA: string expected";
            switch (message.seatClass) {
            default:
                return "seatClass: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
            return null;
        };

        /**
         * Creates a DealDetailsQueryParam message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DealDetailsProtoBuf.DealDetailsQueryParam} DealDetailsQueryParam
         */
        DealDetailsQueryParam.fromObject = function fromObject(object) {
            if (object instanceof $root.DealDetailsProtoBuf.DealDetailsQueryParam)
                return object;
            var message = new $root.DealDetailsProtoBuf.DealDetailsQueryParam();
            if (object.idAirfareSource != null)
                message.idAirfareSource = String(object.idAirfareSource);
            if (object.originIATA != null)
                message.originIATA = String(object.originIATA);
            if (object.destinationIATA != null)
                message.destinationIATA = String(object.destinationIATA);
            switch (object.seatClass) {
            case "Economy":
            case 1:
                message.seatClass = 1;
                break;
            case "Premium_Economy":
            case 2:
                message.seatClass = 2;
                break;
            case "Business":
            case 3:
                message.seatClass = 3;
                break;
            case "First":
            case 4:
                message.seatClass = 4;
                break;
            }
            switch (object.status) {
            case "Sending":
            case 1:
                message.status = 1;
                break;
            case "Sent":
            case 2:
                message.status = 2;
                break;
            case "Auto":
            case 3:
                message.status = 3;
                break;
            case "Failed":
            case 4:
                message.status = 4;
                break;
            case "Bucket":
            case 5:
                message.status = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DealDetailsQueryParam message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @static
         * @param {DealDetailsProtoBuf.DealDetailsQueryParam} message DealDetailsQueryParam
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DealDetailsQueryParam.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.idAirfareSource = "";
                object.originIATA = "";
                object.destinationIATA = "";
                object.seatClass = options.enums === String ? "Economy" : 1;
                object.status = options.enums === String ? "Sending" : 1;
            }
            if (message.idAirfareSource != null && message.hasOwnProperty("idAirfareSource"))
                object.idAirfareSource = message.idAirfareSource;
            if (message.originIATA != null && message.hasOwnProperty("originIATA"))
                object.originIATA = message.originIATA;
            if (message.destinationIATA != null && message.hasOwnProperty("destinationIATA"))
                object.destinationIATA = message.destinationIATA;
            if (message.seatClass != null && message.hasOwnProperty("seatClass"))
                object.seatClass = options.enums === String ? $root.DealDetailsProtoBuf.SeatClass[message.seatClass] : message.seatClass;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.DealDetailsProtoBuf.DealStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this DealDetailsQueryParam to JSON.
         * @function toJSON
         * @memberof DealDetailsProtoBuf.DealDetailsQueryParam
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DealDetailsQueryParam.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DealDetailsQueryParam;
    })();

    /**
     * SeatClass enum.
     * @name DealDetailsProtoBuf.SeatClass
     * @enum {number}
     * @property {number} Economy=1 Economy value
     * @property {number} Premium_Economy=2 Premium_Economy value
     * @property {number} Business=3 Business value
     * @property {number} First=4 First value
     */
    DealDetailsProtoBuf.SeatClass = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "Economy"] = 1;
        values[valuesById[2] = "Premium_Economy"] = 2;
        values[valuesById[3] = "Business"] = 3;
        values[valuesById[4] = "First"] = 4;
        return values;
    })();

    /**
     * DealStatus enum.
     * @name DealDetailsProtoBuf.DealStatus
     * @enum {number}
     * @property {number} Sending=1 Sending value
     * @property {number} Sent=2 Sent value
     * @property {number} Auto=3 Auto value
     * @property {number} Failed=4 Failed value
     * @property {number} Bucket=5 Bucket value
     */
    DealDetailsProtoBuf.DealStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "Sending"] = 1;
        values[valuesById[2] = "Sent"] = 2;
        values[valuesById[3] = "Auto"] = 3;
        values[valuesById[4] = "Failed"] = 4;
        values[valuesById[5] = "Bucket"] = 5;
        return values;
    })();

    return DealDetailsProtoBuf;
})();

module.exports = $root;
