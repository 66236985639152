// cspell:ignore lpsv profitwell

import { createContext, useEffect, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Capacitor } from '@capacitor/core';
import ReactGA from 'react-ga';
import {
  FirebaseCrashlytics,
  RecordExceptionOptions,
  StackFrame,
} from '@capacitor-firebase/crashlytics';
import { initializeApp } from 'firebase/app';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import moment from 'moment';
import {
  AppTrackingTransparency,
  AppTrackingStatus,
} from 'capacitor-plugin-app-tracking-transparency';
import { hotjar } from 'react-hotjar';
import ReactPixel, { Options } from 'react-facebook-pixel';
import { AsyncReturnType } from 'type-fest';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'; // cspell:disable-line
import {
  Airfare,
  Deal,
  SubscriptionPlan,
  CreditCardIssuer,
  SeatClass,
} from '@faredrop/graphql-sdk';
import useStripe from '../hooks/useStripe';
import { isProductionEnvironment } from '@faredrop/utilities';
import { FareDropUser } from '@faredrop/types';
import useLogError from '../hooks/logError';
import StackTrace from 'stacktrace-js';

export interface AnalyticsContextValue {
  isGoogleAnalyticsInitialized: boolean; // Analytics have been initialized, but a user has not been set yet
  isGoogleAnalyticsReady: boolean; // Analytics are initialized and a user has been set. Analytics are ready for consumption
  logHotjarAnalyticsScreen: (screen: string) => void;
  logPixelAnalyticsScreen: (screen: string) => void;
  logGoogleAnalyticsScreen: (screen: string) => Promise<void>;
  logAnalyticsSignUp: (method: AuthMethod) => Promise<void>;
  logAnalyticsLogin: (method: AuthMethod, email?: string) => Promise<void>;
  logAnalyticsLogout: () => Promise<void>;
  logAnalyticsError: (errorType: string, error?: Error) => Promise<void>;
  logAnalyticsCancelSubscription: (reason?: string) => Promise<void>;
  logAnalyticsFAQ: (questionId: string) => Promise<void>;
  logAnalyticsViewAirfare: (
    deal: Pick<
      Deal,
      | 'idAirfareSource'
      | 'seatClass'
      | 'originIATA'
      | 'destinationIATA'
      | 'destinationRegion'
      | 'provider'
    >,
    airfare: Airfare
  ) => Promise<void>;
  logAnalyticsViewDeal: (deal: Deal) => Promise<void>;
  logAnalyticsUpgradeSubscription: (section: string) => Promise<void>;
  logAnalyticsLandingPageSectionView: (section: string) => Promise<void>;
  logAnalyticsInfluencerPageSectionView: (section: string) => Promise<void>;
  logAnalyticsMilesAndPointsPageSectionView: (section: string) => Promise<void>;
  logAnalyticsLandingPageEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsInfluencerPageEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsMilesAndPointsPageEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsOnboardingEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsSearch: (searchTerm: string) => Promise<void>;
  logAnalyticsSelectContent: (
    contentType: string,
    itemId: string
  ) => Promise<void>;
  logAnalyticsViewPlans: (
    name: string,
    plans: SubscriptionPlan[]
  ) => Promise<void>;
  logAnalyticsCheckout: (plan: SubscriptionPlan) => Promise<void>;
  logAnalyticsConvertFromFreeTrial: (plan: SubscriptionPlan) => Promise<void>;
  logAnalyticsPurchase: (
    receipt: AsyncReturnType<ReturnType<typeof useStripe>['getReceipt']>
  ) => Promise<void>;
  logAnalyticsOfferPageSectionView: (
    offer: OfferType,
    section: string
  ) => Promise<void>;
  logAnalyticsOfferEngagement: (
    offer: OfferType,
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsGiftPageSectionView: (section: string) => Promise<void>;
  logAnalyticsGiftEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsRedeemSubscriptionPageSectionView: (
    section: string
  ) => Promise<void>;
  logAnalyticsRedeemSubscriptionEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsPushNotificationActionPath: (path: string) => Promise<void>;
  logAnalyticsPushNotificationActionURL: (url: string) => Promise<void>;
  logAnalyticsPushNotificationActionDeal: (deal: Deal) => Promise<void>;
  logAnalyticsReviewsPageEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsAboutPageEngagement: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  logAnalyticsMilesAndPointsClick: (
    travelPortal: CreditCardIssuer
  ) => Promise<void>;
  logAnalyticsTravelPortalClick: (
    travelPortal: CreditCardIssuer
  ) => Promise<void>;
  logAnalyticsMilesAndPointsRedeemClick: (
    travelPortal: CreditCardIssuer
  ) => Promise<void>;
  logAnalyticsMilesAndPointsGuideClick: (
    travelPortal: CreditCardIssuer
  ) => Promise<void>;
  logAnalyticsDealSearch: (
    from: string,
    to: string,
    seatClass: SeatClass
  ) => Promise<void>;
  requestAppTracking: () => Promise<void>;
  initializeAnalyticsUserId: (user?: Partial<FareDropUser>) => void;
  getDeviceId: () => Promise<{
    client_id?: string | undefined;
    app_instance_id?: string | undefined;
  }>;
  appTrackingStatus: AppTrackingStatus | undefined;
}

// Since we set the AnalyticsProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
export const AnalyticsContext = createContext<AnalyticsContextValue>({
  isGoogleAnalyticsInitialized: false,
  isGoogleAnalyticsReady: false,
  logHotjarAnalyticsScreen: () => undefined,
  logPixelAnalyticsScreen: () => undefined,
  logGoogleAnalyticsScreen: async () => undefined,
  logAnalyticsSignUp: async () => undefined,
  logAnalyticsLogin: async () => undefined,
  logAnalyticsLogout: async () => undefined,
  logAnalyticsError: async () => undefined,
  logAnalyticsCancelSubscription: async () => undefined,
  logAnalyticsFAQ: async () => undefined,
  logAnalyticsViewAirfare: async () => undefined,
  logAnalyticsInfluencerPageSectionView: async () => undefined,
  logAnalyticsLandingPageSectionView: async () => undefined,
  logAnalyticsMilesAndPointsPageSectionView: async () => undefined,
  logAnalyticsInfluencerPageEngagement: async () => undefined,
  logAnalyticsLandingPageEngagement: async () => undefined,
  logAnalyticsMilesAndPointsPageEngagement: async () => undefined,
  logAnalyticsOnboardingEngagement: async () => undefined,
  logAnalyticsViewDeal: async () => undefined,
  logAnalyticsUpgradeSubscription: async () => undefined,
  logAnalyticsSearch: async () => undefined,
  logAnalyticsSelectContent: async () => undefined,
  logAnalyticsViewPlans: async () => undefined,
  logAnalyticsCheckout: async () => undefined,
  logAnalyticsConvertFromFreeTrial: async () => undefined,
  logAnalyticsPurchase: async () => undefined,
  logAnalyticsOfferPageSectionView: async () => undefined,
  logAnalyticsOfferEngagement: async () => undefined,
  logAnalyticsPushNotificationActionPath: async () => undefined,
  logAnalyticsPushNotificationActionURL: async () => undefined,
  logAnalyticsPushNotificationActionDeal: async () => undefined,
  logAnalyticsGiftPageSectionView: async () => undefined,
  logAnalyticsGiftEngagement: async () => undefined,
  logAnalyticsRedeemSubscriptionPageSectionView: async () => undefined,
  logAnalyticsRedeemSubscriptionEngagement: async () => undefined,
  logAnalyticsReviewsPageEngagement: async () => undefined,
  logAnalyticsAboutPageEngagement: async () => undefined,
  logAnalyticsMilesAndPointsClick: async () => undefined,
  logAnalyticsTravelPortalClick: async () => undefined,
  logAnalyticsMilesAndPointsRedeemClick: async () => undefined,
  logAnalyticsMilesAndPointsGuideClick: async () => undefined,
  logAnalyticsDealSearch: async () => undefined,
  requestAppTracking: async () => undefined,
  initializeAnalyticsUserId: () => undefined,
  getDeviceId: async () => ({}),
  appTrackingStatus: undefined,
});

interface AnalyticsProps {
  children: ReactNode;
}

interface AnalyticsEvent {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
}

export enum AuthMethod {
  COGNITO = 'cognito',
}

export enum ContentType {
  DEAL = 'deal',
  AIRFARE = 'airfare',
  FAQ = 'faq',
}

export enum OfferType {
  BUSINESS_CLASS_FOR_LIFE = 'bcfl',
  BUSINESS_CLASS_FOR_ECONOMY_12_MONTHS = 'bcfe12mo', // cspell:disable-line
  PERCENT_20_DISCOUNT = 'p20',
  YOUTUBE_PERCENT_20_DISCOUNT = 'ytp20',
  OCKEY_DOCKEY = 'ockeydockey',
  MATT_AND_ABBY = 'mattandabby',
  SHELBY_CHURCH = 'shelbychurch',
  SHENAE = 'shenae',
  SUMMER24 = 'summer24',
}

export enum AnalyticsEngagementId {
  BUSINESS_PLAN_GET_STARTED = 'business_plan_get_started',
  CHOOSE_BUSINESS_PLAN = 'choose_business_plan',
  CHOOSE_ECONOMY_PLAN = 'choose_economy_plan',
  CHOOSE_FREE_TRIAL_PLAN = 'choose_free_trial_plan',
  CHOOSE_LIMITED_PLAN = 'choose_limited_plan',
  CHOOSE_GLOBAL_PLAN = 'choose_global_plan',
  CHOOSE_PRO_PLAN = 'choose_pro_plan',
  CHOOSE_PLAN_REFUND_POLICY = 'choose_plan_refund_policy',
  CREATED_BY_YOUTUBE_CHANNEL = 'created_by_youtube_channel',
  DESCRIPTION_SIGN_UP_CLICK = 'description_sign_up_click',
  DESCRIPTION_DETAILS_CLICK = 'description_details_click',
  DESCRIPTION_DETAILS_CLICK_APP_IOS = 'description_details_click_app_ios',
  DESCRIPTION_DETAILS_CLICK_APP_ANDROID = 'description_details_click_app_android',
  DOWNLOAD_PAPER_AIRPLANE = 'download_paper_airplane',
  ECONOMY_PLAN_GET_STARTED = 'economy_plan_get_started',
  LIMITED_PLAN_GET_STARTED = 'limited_plan_get_started',
  GLOBAL_PLAN_GET_STARTED = 'global_plan_get_started',
  PRO_PLAN_GET_STARTED = 'pro_plan_get_started',
  FOOTER_ABOUT = 'footer_about',
  FOOTER_CONTACT_US = 'footer_contact_us',
  FOOTER_PRIVACY_POLICY = 'footer_privacy_policy',
  FOOTER_REFUND_POLICY = 'footer_refund_policy',
  FOOTER_TERMS_OF_SERVICE = 'footer_terms_of_service',
  FOOTER_BECOME_AN_AFFILIATE = 'footer_become_an_affiliate',
  GET_DEALS = 'deal_card_get_deals_button',
  GET_STARTED_ARROW = 'get_started_arrow',
  GET_STARTED_BLOCK_GET_STARTED = 'get_started_block_get_started',
  HEADER_FAQ = 'header_faq',
  HEADER_GET_STARTED = 'header_get_started',
  HEADER_GIFT = 'header_gift',
  HEADER_HOW_IT_WORKS = 'header_how_it_works',
  HEADER_LOGIN = 'header_login',
  HEADER_PRICING = 'header_pricing',
  HEADER_MILES_AND_POINTS = 'header_miles_and_points',
  HEADER_WHY_FAREDROP = 'why_faredrop',
  HERO_GET_STARTED = 'hero_get_started',
  HERO_KARA_AND_NATE = 'hero_kara_and_nate',
  LOGIN = 'login',
  LOGIN_LINK = 'login_link',
  MILES_AND_POINTS_ADVERTISER_DISCLOSURE = 'miles_and_points_advertiser_disclosure',
  MILES_AND_POINTS_CAROUSEL_PAGER = 'miles_and_points_carousel_pager',
  MILES_AND_POINTS_CHECK_IT_OUT = 'miles_and_points_check_it_out',
  MILES_AND_POINTS_HERO_BUTTON = 'miles_and_points_hero_button',
  NEXT_DEALS_PAGE = 'next_deals_page',
  OFFER_REFUND_POLICY = 'offer_refund_policy',
  PREVIOUS_DEALS_PAGE = 'previous_deals_page',
  PRICING_REFUND_POLICY = 'pricing_refund_policy',
  PROMOTION = 'promotion',
  PURCHASE_BUSINESS_GIFT = 'purchase_business_gift',
  PURCHASE_ECONOMY_GIFT = 'purchase_economy_gift',
  PURCHASE_GLOBAL_GIFT = 'purchase_global_gift',
  PURCHASE_GLOBAL_PRO_GIFT = 'purchase_global_pro_gift',
  QUESTION_COUNTRY_AVAILABILITY = 'question_country_availability',
  QUESTION_COVID_19 = 'question_covid_19',
  QUESTION_DEAL_CADENCE = 'question_deal_cadence',
  QUESTION_DEAL_TIME_BEFORE_DEPARTURE = 'question_deal_time_before_departure',
  QUESTION_DEALS_FROM_HOME_AIRPORT = 'question_deals_from_home_airport',
  QUESTION_REFUND_POLICY = 'question_refund_policy',
  QUESTION_WHY_FAREDROP = 'question_why_faredrop',
  QUESTION_FREE_T_SHIRT = 'question_free_t_shirt',
  QUESTION_FREE_T_SHIRT_SHIPPING = 'question_free_t_shirt_shipping',
  QUESTION_FREE_TRAVEL_DAY_BUNDLE = 'question_travel_day_bundle',
  QUESTION_FREE_TRAVEL_DAY_BUNDLE_SHIPPING = 'question_free_travel_day_bundle_shipping',
  NAV_TO_FAQ = 'nav_to_faq',
  REDEEM_BCFL_OFFER = 'redeem_bcfl_offer',
  REDEEM_GIFT = 'redeem_gift',
  REDEEM_REWARD = 'redeem_reward',
  RESEND_EMAIL_VERIFICATION_CODE = 'resend_email_verification_code',
  SAVE_DEPARTURE_AIRPORTS = 'save_departure_airports',
  SAVE_DESTINATION_REGIONS = 'save_destination_regions',
  SAVE_TRAVEL_MONTHS = 'save_travel_months',
  SELECT_HOME_AIRPORT = 'select_home_airport',
  SELECT_ORIGIN = 'select_origin',
  SELECT_HOME_ORIGIN = 'select_home_origin',
  SIGN_UP = 'sign_up',
  SOCIAL_PROOF_SCROLL = 'social_proof_scroll',
  TOGGLE_DESTINATION_REGION = 'toggle_destination_region',
  TOGGLE_TRAVEL_MONTH = 'toggle_travel_month',
  VERIFY_EMAIL_CODE = 'verify_email_code',
  VIEW_ORIGIN_DEALS = 'view_origin_deals',
  DAILY_DROP = 'daily_drop',
  BECOME_AN_AFFILIATE = 'become_an_affiliate',
  PERKS = 'perks',
}

export const AnalyticsProvider: FC<AnalyticsProps> = (props) => {
  const { logError } = useLogError();
  const [analyticsUser, setAnalyticsUser] = useState<Partial<FareDropUser>>();
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [appTrackingStatus, setAppTrackingStatus] =
    useState<AppTrackingStatus>();

  const [isGoogleAnalyticsInitialized, setIsGoogleAnalyticsInitialized] =
    useState(false); // Google Analytics library is ready to start emitting events
  const [isGoogleAnalyticsReady, setIsGoogleAnalyticsReady] = useState(false); // Analytics are initialized and if a user is signed in, the user Id has been configured
  const gaEventQueue = useRef<AnalyticsEvent[]>([]);
  const gaErrorQueue = useRef<RecordExceptionOptions[]>([]);

  const [isHotjarInitialized, setIsHotjarInitialized] = useState(false);

  const [isPixelInitialized, setIsPixelInitialized] = useState(false);
  const pixelEventQueue = useRef<AnalyticsEvent[]>([]);

  if (
    !Capacitor.isNativePlatform() &&
    isProductionEnvironment() &&
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  ) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  }

  useEffect(() => {
    // Initializes analytics with respect to the platform that the user is on
    const initializeAnalytics = async () => {
      if (Capacitor.isNativePlatform()) {
        await FirebaseCrashlytics.setEnabled({ enabled: true });
        setIsGoogleAnalyticsInitialized(true);
      } else {
        if (
          !process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID ||
          !process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID
        ) {
          console.error(
            'Firebase Analytics initialize secret value is not set for environment'
          );
        } else {
          // Firebase caches the instance because sometimes it throws "Firebase app already exists"
          // and there is no obvious way to verify if it's already initialized, so we need a try catch
          try {
            await initializeApp({
              apiKey: process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY,
              authDomain: process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN,
              projectId: process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID,
              storageBucket:
                process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET,
              messagingSenderId:
                process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID,
              appId: process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID,
              measurementId:
                process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID,
            });
          } catch (error) {
            if (error !== 'Firebase app already exists')
              throw new Error('Failed to initialize Firebase');
          }

          await FirebaseAnalytics.setEnabled({
            enabled: true,
          });

          setIsGoogleAnalyticsInitialized(true);
        }
      }
    };

    const initializeHotJar = () => {
      if (Capacitor.getPlatform() === 'web') {
        const id = process.env.REACT_APP_HOTJAR_ID;
        const version = process.env.REACT_APP_HOTJAR_VERSION;
        if (id && version) {
          hotjar.initialize(parseInt(id), parseInt(version));
          setIsHotjarInitialized(true);
        }
      }
    };

    initializeAnalytics().catch((error) =>
      console.warn('Failed to initialize analytics', error)
    ); // Async - fire and forget
    initializeHotJar();
  }, []);

  // Log queued Google Analytics events and errors
  useEffect(() => {
    const logQueuedEvents = async () => {
      const promises: Promise<void>[] = [];

      gaEventQueue.current.forEach((event) => {
        promises.push(FirebaseAnalytics.logEvent(event));
      });
      gaErrorQueue.current.forEach((crashlyticsPayload) => {
        // NOTE: This was causing EXC_BAD_ACCESS crashes on iOS. It was found that the input arg was incorrect and may have been causing the crash
        // If we still see the crashes, we may need to yank this out (calls outside of the queue seem to be working fine, so it's not completely obvious what is not ready by the time we want to call this)
        promises.push(FirebaseCrashlytics.recordException(crashlyticsPayload));
      });
      await Promise.all(promises);

      // Clear the queues
      gaEventQueue.current.length = 0;
      gaErrorQueue.current.length = 0;
    };

    if (isGoogleAnalyticsReady) {
      logQueuedEvents().catch((error) =>
        console.warn('Failed to log analytics queued events', error)
      ); // Async - fire and forget
    }
  }, [isGoogleAnalyticsReady]);

  // Log queued Pixel events
  useEffect(() => {
    if (isPixelInitialized) {
      pixelEventQueue.current.forEach((event) => {
        ReactPixel.track(event.name, event.params);
      });
    }
  }, [isPixelInitialized]);

  // Initialize App Tracking Transparency status
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      AppTrackingTransparency.getStatus()
        .then((response) => {
          setAppTrackingStatus(response.status);
        })
        .catch((err) => {
          logError(
            'Failed to get app tracking status',
            err && (err as Error).message
              ? (err as Error).message
              : 'Error or error message is undefined'
          ).catch((error) =>
            console.warn(
              'Failed to send error log to endpoint for app tracking transparency status',
              error
            )
          );
        });
    }
  }, []);

  // Determine if Google Analytics are ready to be logged
  useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (isGoogleAnalyticsInitialized && isUserInitialized) {
      if (
        platform === 'web' ||
        platform === 'android' ||
        (platform === 'ios' &&
          appTrackingStatus &&
          appTrackingStatus === 'authorized')
      ) {
        setIsGoogleAnalyticsReady(true);
      }
    }
  }, [isGoogleAnalyticsInitialized, isUserInitialized, appTrackingStatus]);

  // Set the Analytics User Id after Google Analytics and user have been initialized
  useEffect(() => {
    const setUserId = async () => {
      if (
        isGoogleAnalyticsInitialized &&
        isUserInitialized &&
        analyticsUser?.id
      ) {
        if (Capacitor.isNativePlatform()) {
          const platform = Capacitor.getPlatform();
          if (
            platform === 'android' ||
            (platform === 'ios' && appTrackingStatus === 'authorized')
          ) {
            await FirebaseCrashlytics.setUserId({ userId: analyticsUser.id });
          }
        } else {
          await FirebaseAnalytics.setUserId({
            userId: analyticsUser.id,
          });
        }
      }
    };
    setUserId().catch((err) => {
      logError(
        'Failed to set analytics userId',
        err && (err as Error).message
          ? (err as Error).message
          : 'Error or error message is undefined'
      ).catch((error) =>
        console.warn(
          'Failed to send error log to endpoint for analytics init failure',
          error
        )
      );
    }); // Async - fire and forget
  }, [
    isGoogleAnalyticsInitialized,
    analyticsUser,
    isUserInitialized,
    appTrackingStatus,
  ]);

  // Initialize Facebook Pixel after user is initialized
  useEffect(() => {
    const initializePixel = () => {
      if (Capacitor.getPlatform() === 'web') {
        const id = process.env.REACT_APP_FB_PIXEL_ID;

        if (id) {
          let advancedMatching;
          if (analyticsUser) {
            advancedMatching = {
              em: analyticsUser.email?.toLowerCase(),
              fn: analyticsUser.firstName?.toLowerCase(),
              ln: analyticsUser.lastName?.toLowerCase(),
              // Watch PR: https://github.com/zsajjad/react-facebook-pixel/pull/77
              // @ts-expect-error react-facebook-pixel is missing the external_id property in its types
              external_id: analyticsUser.id,
            };
          }

          const options: Options = {
            autoConfig: true,
            debug: false,
          };

          ReactPixel.init(id, advancedMatching, options);

          // Only track page view once
          if (!isPixelInitialized) {
            ReactPixel.pageView();
          }

          setIsPixelInitialized(true);
        }
      }
    };

    if (isUserInitialized) {
      initializePixel();
    }
  }, [isUserInitialized, analyticsUser]);

  const requestAppTracking = async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const getResponse = await AppTrackingTransparency.getStatus();
      if (getResponse.status === 'notDetermined') {
        const response = await AppTrackingTransparency.requestPermission();
        setAppTrackingStatus(response.status);
      }
    }
  };

  const initializeAnalyticsUserId = (user?: Partial<FareDropUser>) => {
    setAnalyticsUser(user);
    setIsUserInitialized(true);
  };

  const logGAEvent = async (event: AnalyticsEvent) => {
    if (
      !Capacitor.isNativePlatform() &&
      isProductionEnvironment() &&
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
    ) {
      ReactGA.event({
        category: event.name,
        action: event.name,
        dimension1: JSON.stringify(event.params),
      });
    }

    if (isGoogleAnalyticsReady) {
      await FirebaseAnalytics.logEvent(event);
    } else if (
      Capacitor.getPlatform() !== 'ios' ||
      appTrackingStatus === 'notDetermined'
    ) {
      gaEventQueue.current.push(event);
    }
  };

  // See packages/utilities-backend/src/google-analytics.ts for why this is necessary
  const getDeviceId = async () => {
    const retVal: {
      client_id?: string;
      app_instance_id?: string;
    } = {
      client_id: undefined,
      app_instance_id: undefined,
    };

    if (Capacitor.isNativePlatform()) {
      retVal.app_instance_id =
        (await FirebaseAnalytics.getAppInstanceId()).appInstanceId ?? undefined;
    } else {
      // https://stackoverflow.com/questions/20053949/how-to-get-the-google-analytics-client-id
      retVal.client_id = document.cookie
        .match(/_ga=(.+?);/)?.[1]
        .split('.')
        .slice(-2)
        .join('.');
    }

    return retVal;
  };

  const highestRole = analyticsUser?.roles?.length
    ? analyticsUser.roles[analyticsUser.roles.length - 1]
    : undefined;

  const logHotjarAnalyticsScreen = (screen: string) => {
    if (isHotjarInitialized) {
      hotjar.stateChange(screen);
    }
  };

  const logPixelAnalyticsScreen = (screen: string) => {
    logPixelEvent({
      name: 'ViewContent',
      params: {
        content_name: screen,
      },
    });
  };

  const logGoogleAnalyticsScreen = async (screen: string) => {
    const event = {
      name: 'custom_screen_view', // screen_view is reserved by GA  // TODO: BUG: I don't think this matters... https://firebase.google.com/docs/analytics/screenviews#manually_track_screens, the shape of this should be a firebase_screen and firebase_screen_class
      params: {
        screen,
      },
    };

    if (isGoogleAnalyticsReady) {
      if (Capacitor.isNativePlatform()) {
        // Sets the current screen name, which specifies the current visual context in your app. (Native only)
        await FirebaseAnalytics.setCurrentScreen({
          screenName: screen,
          screenClassOverride: screen,
        });
      }
      await FirebaseAnalytics.logEvent(event);
    } else if (
      Capacitor.getPlatform() !== 'ios' ||
      appTrackingStatus === 'notDetermined'
    ) {
      gaEventQueue.current.push(event);
    }
  };

  const logPixelEvent = (event: AnalyticsEvent) => {
    if (Capacitor.getPlatform() === 'web') {
      if (isPixelInitialized) {
        ReactPixel.track(event.name, event.params);
      } else {
        pixelEventQueue.current.push(event);
      }
    }
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
  const logAnalyticsSignUp = async (method: AuthMethod) => {
    logPixelEvent({
      name: 'Lead',
      params: {},
    });
    await logGAEvent({
      name: 'sign_up',
      params: {
        method,
      },
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
  const logAnalyticsLogin = async (method: AuthMethod, email?: string) => {
    if (
      // @ts-expect-error profitwell is sourced
      profitwell &&
      email &&
      isProductionEnvironment() &&
      (Capacitor.getPlatform() !== 'ios' || appTrackingStatus === 'authorized')
    ) {
      // @ts-expect-error profitwell is sourced
      profitwell('start', { user_email: email });
    }

    await logGAEvent({
      name: 'login',
      params: {
        method,
      },
    });
  };

  const logAnalyticsLogout = async () => {
    await logGAEvent({
      name: 'logout',
      params: {},
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
  const logAnalyticsSearch = async (searchTerm: string) => {
    logPixelEvent({
      name: 'Search',
      params: {
        search_string: searchTerm.substring(0, 100),
      },
    });
    await logGAEvent({
      name: 'search',
      params: {
        search_term: searchTerm.substring(0, 100), // Parameter values have a max length of 100
      },
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_content
  const logAnalyticsSelectContent = async (
    contentType: string,
    itemId: string
  ) => {
    await logGAEvent({
      name: 'select_content',
      params: {
        content_type: contentType,
        item_id: itemId,
      },
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#view_item_list
  const logAnalyticsViewPlans = async (
    name: string,
    plans: SubscriptionPlan[]
  ) => {
    const items: { item_id: string; item_name: string; price: number }[] = [];
    plans.forEach((plan) => {
      const planName = plan.name.toLowerCase();
      items.push({
        item_id: plan.id,
        item_name: planName,
        price: parseInt(plan.price),
      });
    });

    await logGAEvent({
      name: 'view_item_list',
      params: {
        item_list_name: name,
        items,
      },
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
  const logAnalyticsCheckout = async (plan: SubscriptionPlan) => {
    const planName = plan.name.toLowerCase();
    const price = parseInt(plan.price);
    logPixelEvent({
      name: 'InitiateCheckout',
      params: {
        currency: 'USD',
        value: price,
        contents: [
          {
            id: plan.id,
            quantity: 1,
          },
        ],
      },
    });
    await logGAEvent({
      name: 'begin_checkout',
      params: {
        currency: 'USD',
        value: price,
        items: [
          {
            item_id: plan.id,
            item_name: planName,
            price,
          },
        ],
      },
    });
  };

  const logAnalyticsConvertFromFreeTrial = async (plan: SubscriptionPlan) => {
    const planName = plan.name.toLowerCase();
    const price = parseInt(plan.price);
    logPixelEvent({
      name: 'ConvertFromFreeTrial',
      params: {
        currency: 'USD',
        value: price,
        contents: [
          {
            id: plan.id,
            quantity: 1,
          },
        ],
      },
    });
    await logGAEvent({
      name: 'convert_from_free_trial',
      params: {
        currency: 'USD',
        value: price,
        items: [
          {
            item_id: plan.id,
            item_name: planName,
            price,
          },
        ],
      },
    });
  };

  // G4 Recommended Event
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
  const logAnalyticsPurchase = async (
    receipt: AsyncReturnType<ReturnType<typeof useStripe>['getReceipt']>
  ) => {
    const params = {
      currency: receipt.currency.toUpperCase(),
      transaction_id: receipt.idTransaction,
      value: receipt.value,
      items: receipt.items.map((item) => {
        const gaItem = {
          item_id: item.idItem,
          item_name: item.name,
          price: item.price,
          quantity: item.quantity,
        };

        if (item.coupon) {
          return {
            ...gaItem,
            coupon: item.coupon,
          };
        } else {
          return gaItem;
        }
      }),
    };

    await logGAEvent({
      name: 'purchase',
      params,
    });
  };

  const logAnalyticsError = async (errorType: string, error?: Error) => {
    console.warn('Logging analytics error', errorType, error);
    const event = {
      name: 'custom_error', // error key word is reserved by GA
      params: {
        error_type: errorType,
      },
    };

    let stacktrace: StackFrame[] | undefined;
    let crashlyticsPayload: RecordExceptionOptions | undefined;
    if (error) {
      stacktrace = await StackTrace.fromError(error);
      crashlyticsPayload = {
        message: error.message,
        stacktrace,
      };
    }

    if (isGoogleAnalyticsReady) {
      const promises: Promise<void>[] = [FirebaseAnalytics.logEvent(event)];
      if (Capacitor.isNativePlatform() && crashlyticsPayload) {
        promises.push(FirebaseCrashlytics.recordException(crashlyticsPayload));
      }
      await Promise.all(promises);
    } else if (
      Capacitor.getPlatform() !== 'ios' ||
      appTrackingStatus === 'notDetermined'
    ) {
      gaEventQueue.current.push(event);
      if (Capacitor.isNativePlatform() && crashlyticsPayload) {
        gaErrorQueue.current.push(crashlyticsPayload);
      }
    }
  };

  const logAnalyticsCancelSubscription = async (reason?: string) => {
    await logGAEvent({
      name: 'ui_cancel_subscription',
      params: {
        reason,
      },
    });
  };

  const logAnalyticsFAQ = async (questionId: string) => {
    const event = {
      name: `select_faq_${questionId}`,
      params: {},
    };

    await Promise.all([
      logGAEvent(event),
      logAnalyticsSelectContent(ContentType.FAQ, questionId),
    ]);
  };

  const logAnalyticsViewAirfare = async (
    deal: Pick<
      Deal,
      | 'idAirfareSource'
      | 'seatClass'
      | 'originIATA'
      | 'destinationIATA'
      | 'destinationRegion'
      | 'provider'
    >,
    airfare: Airfare
  ) => {
    const departureMonth = moment(airfare.departureDate).format('MMMM');
    const gaEvent = {
      name: 'select_airfare',
      params: {
        seat_class: deal.seatClass,
        origin_iata: deal.originIATA,
        destination_iata: deal.destinationIATA,
        destination_region: deal.destinationRegion,
        provider: deal.provider,
        departure_month: departureMonth,
        price: airfare.price,
        role: highestRole,
      },
    };

    const facebookEvent = {
      name: 'SelectAirfare',
      params: {
        ...gaEvent.params,
      },
    };

    await Promise.all([
      logGAEvent(gaEvent),
      logPixelEvent(facebookEvent),
      logAnalyticsSelectContent(
        ContentType.AIRFARE,
        `${deal.idAirfareSource}#${deal.seatClass}#${deal.originIATA}#${deal.destinationIATA}#${departureMonth}`
      ),
      // NOTE: Unfortunately, we can't call Klaviyo (or potentially other analytics services) here due to context dependency order
    ]);
  };

  const logAnalyticsViewDeal = async (deal: Deal) => {
    const gaEvent = {
      name: 'select_deal',
      params: {
        seat_class: deal.seatClass,
        origin_iata: deal.originIATA,
        destination_iata: deal.destinationIATA,
        destination_region: deal.destinationRegion,
        provider: deal.provider,
        price: deal.minPrice,
        role: highestRole,
      },
    };

    const facebookEvent = {
      name: 'SelectDeal',
      params: {
        ...gaEvent.params,
      },
    };

    await Promise.all([
      logGAEvent(gaEvent),
      logPixelEvent(facebookEvent),
      logAnalyticsSelectContent(
        ContentType.DEAL,
        `${deal.idAirfareSource}#${deal.seatClass}#${deal.originIATA}#${deal.destinationIATA}`
      ),
      // NOTE: Unfortunately, we can't call Klaviyo (or potentially other analytics services) here due to context dependency order
    ]);
  };

  const logAnalyticsUpgradeSubscription = async (section: string) => {
    await logGAEvent({
      name: 'ui_upgrade_subscription',
      params: {
        section,
      },
    });
  };

  const logAnalyticsLandingPageSectionView = async (section: string) => {
    await logGAEvent({
      name: `lpsv_${section}`, // lpsv = Landing Page Section View
      params: {},
    });
  };

  const logAnalyticsInfluencerPageSectionView = async (section: string) => {
    await logGAEvent({
      name: `ipsv_${section}`, // lpsv = Landing Page Section View
      params: {},
    });
  };

  const logAnalyticsMilesAndPointsPageSectionView = async (section: string) => {
    await logGAEvent({
      name: `mapsv_${section}`, // lpsv = Landing Page Section View
      params: {},
    });
  };

  const logAnalyticsLandingPageEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `lpe_${engagementId}`, // lpe = Landing Page Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const logAnalyticsAboutPageEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `ape_${engagementId}`, // ape = About Page Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const logAnalyticsReviewsPageEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `rpe_${engagementId}`, // rpe = Reviews Page Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const logAnalyticsInfluencerPageEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `ipe_${engagementId}`, // lpe = Landing Page Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const logAnalyticsMilesAndPointsPageEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `mape_${engagementId}`, // lpe = Landing Page Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const logAnalyticsOnboardingEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `oe_${engagementId}`, // oe = Onboarding Engagement
      params: {},
    });

    trackPixelEvents(engagementId);
  };

  const trackPixelEvents = (engagementId: AnalyticsEngagementId) => {
    let event: AnalyticsEvent | undefined = undefined;
    switch (engagementId) {
      case AnalyticsEngagementId.FOOTER_CONTACT_US: {
        event = {
          name: 'Contact',
          params: {},
        };
        break;
      }
      case AnalyticsEngagementId.GET_STARTED_ARROW: {
        event = {
          name: 'CompleteRegistration',
          params: {},
        };
        break;
      }
      case AnalyticsEngagementId.CHOOSE_FREE_TRIAL_PLAN: {
        event = {
          name: 'StartFreeTrial',
          params: {},
        };
      }
    }

    if (event?.name) {
      logPixelEvent(event);
    }
  };

  const logAnalyticsOfferPageSectionView = async (
    offer: OfferType,
    section: string
  ) => {
    await logGAEvent({
      name: `${offer}sv_${section}`, // sv = Section View // cspell:disable-line
      params: {},
    });
  };

  const logAnalyticsOfferEngagement = async (
    offer: OfferType,
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `${offer}e_${engagementId}`, // e = Engagement
      params: {},
    });
  };

  const logAnalyticsGiftPageSectionView = async (section: string) => {
    await logGAEvent({
      name: `gpsv_${section}`, // gpsv = Gift Page Section View // cspell:disable-line
      params: {},
    });
  };

  const logAnalyticsGiftEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `gpe_${engagementId}`, // Gift Page Engagement
      params: {},
    });
  };

  const logAnalyticsRedeemSubscriptionPageSectionView = async (
    section: string
  ) => {
    await logGAEvent({
      name: `rspsv_${section}`, // rgpsv = Redeem Subscription Page Section View // cspell:disable-line
      params: {},
    });
  };

  const logAnalyticsRedeemSubscriptionEngagement = async (
    engagementId: AnalyticsEngagementId
  ) => {
    await logGAEvent({
      name: `rspe_${engagementId}`, // Redeem Subscription Page Engagement // cspell:disable-line
      params: {},
    });
  };

  const logAnalyticsPushNotificationActionPath = async (path: string) => {
    await logGAEvent({
      name: `pna_path`, // Push Notification Action
      params: {
        path,
        role: highestRole,
      },
    });
  };

  const logAnalyticsPushNotificationActionURL = async (url: string) => {
    await logGAEvent({
      name: `pna_url`, // Push Notification Action
      params: {
        url,
        role: highestRole,
      },
    });
  };

  const logAnalyticsPushNotificationActionDeal = async (deal: Deal) => {
    await logGAEvent({
      name: `pna_deal`, // Push Notification Action
      params: {
        seat_class: deal.seatClass,
        origin_iata: deal.originIATA,
        destination_iata: deal.destinationIATA,
        destination_region: deal.destinationRegion,
        provider: deal.provider,
        price: deal.minPrice,
        role: highestRole,
      },
    });
  };

  const logAnalyticsMilesAndPointsClick = async (
    travelPortal: CreditCardIssuer
  ) => {
    await logGAEvent({
      name: `map_${travelPortal.toLowerCase()}`, // Miles and Points {Travel Portal}
      params: {
        role: highestRole,
      },
    });
  };

  const logAnalyticsTravelPortalClick = async (
    travelPortal: CreditCardIssuer
  ) => {
    await logGAEvent({
      name: `tp_${travelPortal.toLowerCase()}`, // Travel Portal {Travel Portal}
      params: {
        role: highestRole,
      },
    });
  };

  const logAnalyticsMilesAndPointsRedeemClick = async (
    travelPortal: CreditCardIssuer
  ) => {
    await logGAEvent({
      name: `mapr_${travelPortal.toLowerCase()}`, // cspell:disable-line Miles and Points Redeem {Travel Portal}
      params: {
        role: highestRole,
      },
    });
  };

  const logAnalyticsMilesAndPointsGuideClick = async (
    travelPortal: CreditCardIssuer
  ) => {
    await logGAEvent({
      name: `mapg_${travelPortal.toLowerCase()}`, // cspell:disable-line Miles and Points Guide {Travel Portal}
      params: {
        role: highestRole,
      },
    });
  };

  const logAnalyticsDealSearch = async (
    from: string,
    to: string,
    seatClass: SeatClass
  ) => {
    await logGAEvent({
      name: `dsearch`, // cspell:disable-line Deal Search
      params: {
        from,
        to,
        seatClass,
        role: highestRole,
      },
    });
  };

  return (
    <GTMProvider state={{ id: process.env.REACT_APP_GTM_ID as string }}>
      <AnalyticsContext.Provider
        value={{
          isGoogleAnalyticsInitialized,
          isGoogleAnalyticsReady,
          logHotjarAnalyticsScreen,
          logPixelAnalyticsScreen,
          logGoogleAnalyticsScreen,
          logAnalyticsSignUp,
          logAnalyticsLogin,
          logAnalyticsLogout,
          logAnalyticsError,
          logAnalyticsCancelSubscription,
          logAnalyticsFAQ,
          logAnalyticsViewAirfare,
          logAnalyticsViewDeal,
          logAnalyticsUpgradeSubscription,
          logAnalyticsLandingPageSectionView,
          logAnalyticsInfluencerPageSectionView,
          logAnalyticsMilesAndPointsPageSectionView,
          logAnalyticsLandingPageEngagement,
          logAnalyticsInfluencerPageEngagement,
          logAnalyticsMilesAndPointsPageEngagement,
          logAnalyticsOnboardingEngagement,
          logAnalyticsSearch,
          logAnalyticsSelectContent,
          logAnalyticsViewPlans,
          logAnalyticsCheckout,
          logAnalyticsConvertFromFreeTrial,
          logAnalyticsPurchase,
          logAnalyticsOfferPageSectionView,
          logAnalyticsOfferEngagement,
          logAnalyticsGiftPageSectionView,
          logAnalyticsGiftEngagement,
          logAnalyticsRedeemSubscriptionPageSectionView,
          logAnalyticsRedeemSubscriptionEngagement,
          logAnalyticsPushNotificationActionPath,
          logAnalyticsPushNotificationActionURL,
          logAnalyticsPushNotificationActionDeal,
          logAnalyticsReviewsPageEngagement,
          logAnalyticsAboutPageEngagement,
          logAnalyticsMilesAndPointsClick,
          logAnalyticsTravelPortalClick,
          logAnalyticsMilesAndPointsRedeemClick,
          logAnalyticsMilesAndPointsGuideClick,
          logAnalyticsDealSearch,
          requestAppTracking,
          initializeAnalyticsUserId,
          getDeviceId,
          appTrackingStatus,
        }}
      >
        {props.children}
      </AnalyticsContext.Provider>
    </GTMProvider>
  );
};

export default AnalyticsContext;
