import { Browser } from '@capacitor/browser';
import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import produce from 'immer';
import moment from 'moment';
import { useState } from 'react';
import { $enum } from 'ts-enum-util';

import {
  ChangeSubscriptionPreFlightQuery,
  FareDropPlan,
  SubscriptionPlan as SubscriptionPlanGQL,
  SubscriptionStatus,
} from '@faredrop/graphql-sdk';
import {
  applyPromotion,
  isLimitedPlan,
  isSubscriptionActive,
  stripePlanIDToFareDropPlans,
} from '@faredrop/utilities';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useAnalyticsScreenName from '../hooks/analyticsScreenName';
import './../theme/SubscriptionPlanCard.css';
import SocialProofSection from './SocialProofSection';
import * as Yup from 'yup';
import { FormHelperText, TextField } from '@material-ui/core';
import useGifts from '../hooks/useGifts';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { parseError } from '../utilities/utils';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import { SubscriptionPlanCard } from './SubscriptionPlanCard';
import DeleteAccount from './DeleteAccount';
import CancelSubscription from './CancelSubscription';
import {
  calculateDiscountPrice,
  convertSubscriptionPlanTypeToGQL,
  getPlanName,
  isFrontendSubscriptionPromotion,
} from '../utilities/plans-utilities';
import { AllowedPath, SubscriptionPlan } from '@faredrop/types';
import useStripeHook from '../hooks/useStripe';
import { useDisplayDiscounts } from '../hooks/useDisplayDiscounts';

interface ChoosePlanProps {
  plans: SubscriptionPlanGQL[];
  preflightPlans?: IChangeSubscriptionPlanPreflight[];
  disabledPlanType?: string;
  disabledPlanButtonText?: string;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  onSubscriptionChange: (plan: SubscriptionPlanGQL) => Promise<void>;
  onCancelSubscription: () => Promise<void>;
  onCancelSubscriptionSchedule: (
    updatedPreflightPlans?: IChangeSubscriptionPlanPreflight[]
  ) => Promise<void>;
}

export type RedeemValues = {
  giftCode: string;
};

export type IChangeSubscriptionPlanPreflight =
  ChangeSubscriptionPreFlightQuery['changeSubscriptionPreFlight'][number];

const ChoosePlan: React.FC<ChoosePlanProps> = ({
  logAnalyticsEngagement,
  onSubscriptionChange,
  plans,
  preflightPlans,
  disabledPlanType,
  disabledPlanButtonText,
  onCancelSubscription,
  onCancelSubscriptionSchedule,
}) => {
  useAnalyticsScreenName('/plans');
  const [isLoading, setIsLoading] = useState(false);
  const [showGiftCodeForm, setShowGiftCodeForm] = useState(false);
  const { buildRedeemFailedMailTo } = useGifts();
  const { redeemSubscription } = useStripeHook();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { isApp } = useDevice();
  const userState = useUser();
  const { discounts } = useDisplayDiscounts();

  const url = new URL(window?.location?.href);

  const submitDisabled = (
    values: RedeemValues,
    touched: FormikTouched<RedeemValues>,
    errors: FormikErrors<RedeemValues>
  ) => {
    return (
      values.giftCode === '' || Boolean(touched.giftCode && errors.giftCode)
    );
  };

  const getDisplayPrice = (price?: number) => {
    if (!price) return '0';
    if (price > 0 && price < 1) return '1';
    else return Math.floor(price).toFixed(0);
  };

  const hasActivePlan =
    userState.user?.billing?.subscriptionStatus &&
    isSubscriptionActive(
      userState.user.billing.subscriptionStatus.toLowerCase()
    );
  const hasStripeCustomerId = userState.user?.billing?.idStripeCustomer;
  const showFreeTrial = url.searchParams.has('trial') && !hasStripeCustomerId;

  if (!showFreeTrial && url.searchParams.has('trial')) {
    url.searchParams.delete('trial');
    window.history.pushState({}, '', url.href);
  }

  let currentPlan: FareDropPlan | undefined = undefined;
  if (hasActivePlan) {
    const fareDropPlans = userState.user?.billing.idStripePlan
      ? stripePlanIDToFareDropPlans(userState.user.billing.idStripePlan)
      : undefined;
    currentPlan =
      fareDropPlans && hasActivePlan
        ? $enum(FareDropPlan).asValueOrThrow(
            fareDropPlans[fareDropPlans.length - 1]
          )
        : undefined;
  }

  const previousMember =
    userState.user?.billing.subscriptionStatus === SubscriptionStatus.Canceled;

  let title = '';
  if (!hasActivePlan) {
    title = `Once you've ${
      previousMember ? 're' : ''
    }subscribed to FareDrop, you can change your deal settings anytime!`;
  }

  const loadedPlans = preflightPlans || !hasActivePlan ? plans : undefined;

  const renderPlanSelection = (
    <>
      <h4
        style={{
          fontFamily: 'nexa',
          textAlign: 'center',
          marginBottom: '1em',
        }}
      >
        {title}
      </h4>
      {isApp && (
        <div
          onClick={async () => {
            await Browser.open({
              url: 'https://www.faredrop.com/?target=how-it-works',
            });
          }}
          style={{
            textAlign: 'center',
            width: '100%',
            fontFamily: 'nexa-bold',
            color: 'var(--ion-color-primary)',
            lineHeight: 1.5,
          }}
        >
          New to Faredrop?
          <br />
          Click here for more information!
        </div>
      )}
      {!loadedPlans ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonSpinner
            name="crescent"
            style={{ margin: '0 2em' }}
            color="primary"
          />
        </div>
      ) : (
        <IonRow
          className="row-vertical-align"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IonRow style={{ maxWidth: '1000px' }}>
            {loadedPlans.map((cardPlan: SubscriptionPlanGQL) => {
              const plan =
                isFrontendSubscriptionPromotion(
                  isLimitedPlan(userState.user?.billing.idStripePlan)
                ) &&
                // If a user has a plan, our backend is providing the pricing information, so no need to apply promotion
                // If a user is on the limited plan, we want to show promotion features (e.g., free t-shirt) for upgrades
                (!currentPlan || currentPlan === FareDropPlan.Limited)
                  ? convertSubscriptionPlanTypeToGQL(
                      applyPromotion(cardPlan as SubscriptionPlan)
                    )
                  : cardPlan;

              const isPriceNumber = !isNaN(parseInt(plan.price));

              let nextPlan: FareDropPlan | undefined = undefined;
              if (preflightPlans) {
                const nextPlanPreflight = preflightPlans.find(
                  (p) => !!p.nextPlan
                );
                if (nextPlanPreflight?.nextPlan) {
                  nextPlan = $enum(FareDropPlan).asValueOrThrow(
                    nextPlanPreflight.nextPlan
                  );
                }
              }

              let overridePrice: string | undefined = undefined;
              let discountPrice: string | undefined = undefined;
              if (preflightPlans) {
                const preflightPlan = preflightPlans.find(
                  (p) => p.plan === plan.planType
                );
                const updatedPrice =
                  preflightPlan?.nextInvoiceAmountNoDiscount &&
                  plan.planType !== FareDropPlan.Limited
                    ? getDisplayPrice(preflightPlan.nextInvoiceAmountNoDiscount)
                    : getDisplayPrice(preflightPlan?.nextInvoiceAmount);
                overridePrice =
                  updatedPrice !== getDisplayPrice(parseFloat(plan.price))
                    ? updatedPrice
                    : undefined;
                const nextInvoiceAmount = preflightPlan?.nextInvoiceAmount;
                discountPrice =
                  preflightPlan?.nextInvoiceAmountNoDiscount &&
                  plan.planType !== FareDropPlan.Limited
                    ? getDisplayPrice(nextInvoiceAmount)
                    : undefined;
              } else {
                discountPrice = calculateDiscountPrice(
                  plan,
                  plan.discount ?? undefined,
                  discounts
                );
              }

              let billingSubText: string | undefined = undefined;
              let buttonText =
                disabledPlanType?.toUpperCase() === plan.planType &&
                disabledPlanButtonText
                  ? disabledPlanButtonText
                  : plan.chooseText ?? `Choose ${getPlanName(plan.planType)}`;
              if (
                plan.planType === currentPlan &&
                plan.planType !== FareDropPlan.Limited &&
                !nextPlan
              ) {
                if (userState.user?.billing.autoRenew) {
                  billingSubText = `Renews on ${moment(
                    userState.user?.billing.stripePlanEnd
                  ).format('LL')}`;
                } else {
                  billingSubText = `Expires on ${moment(
                    userState.user?.billing.stripePlanEnd
                  ).format('LL')}`;
                }
              } else if (plan.planType === nextPlan) {
                billingSubText = `Scheduled change on ${moment(
                  userState.user?.billing.stripePlanEnd
                ).format('LL')}`;
                buttonText = 'Cancel Change';
              } else {
                billingSubText = isPriceNumber
                  ? plan.billingSubtext
                    ? plan.billingSubtext
                    : overridePrice || discountPrice
                    ? `Renews at full-price`
                    : undefined
                  : undefined;
              }

              const selectedPlanQueryParams: string | null =
                url.searchParams.get('selected-plan');
              let border: string | undefined = undefined;
              let buttonColor: string | undefined = undefined;
              let buttonTextColor: string | undefined = undefined;
              // if there is a selected-param query param,
              // and the user doesn't have a plan,
              // check to see if query param value is the same as this instance of plan.
              // If so, mark that as selected
              if (
                currentPlan === undefined &&
                selectedPlanQueryParams !== null &&
                plan.planType === selectedPlanQueryParams
              ) {
                border = '2px solid var(--ion-color-primary)';
                buttonColor = 'var(--ion-color-primary)';
                buttonText = disabledPlanType ?? 'Continue';
                buttonTextColor = 'white';
              }

              return (
                <SubscriptionPlanCard
                  key={plan.id + plan.name}
                  plan={plan}
                  border={border}
                  discountPrice={discountPrice}
                  overridePrice={overridePrice}
                  buttonText={buttonText}
                  buttonTextColor={buttonTextColor}
                  buttonColor={
                    disabledPlanType?.toUpperCase() === plan.planType
                      ? 'var(--ion-color-lighter-gray)'
                      : buttonColor
                  }
                  buttonStyle={{
                    cursor:
                      disabledPlanType?.toUpperCase() === plan.planType
                        ? 'default'
                        : undefined,
                  }}
                  buttonOnClickListener={async () => {
                    const url = new URL(window?.location?.href);
                    url.searchParams.set('selected-plan', plan.planType);
                    goWithStickyParamsLocation({
                      search: url.searchParams.toString(),
                    });
                    if (disabledPlanType?.toUpperCase() !== plan.planType) {
                      try {
                        if (plan.planType === nextPlan) {
                          const updated = produce(preflightPlans, (draft) => {
                            draft?.forEach((preflightPlan) => {
                              if (preflightPlan.nextPlan === nextPlan) {
                                delete preflightPlan.nextPlan;
                              }
                            });
                          });

                          await onCancelSubscriptionSchedule(updated);
                        } else {
                          await onSubscriptionChange(plan);
                        }
                      } catch (err) {
                        setIsLoading(false);
                      }
                    }
                  }}
                  sx={{
                    border: '2px solid #dadada',
                    background: 'white',
                  }}
                  billingText={
                    isPriceNumber
                      ? plan.billingText
                        ? plan.billingText
                        : currentPlan !== plan.planType &&
                          (overridePrice || discountPrice)
                        ? 'For the first year'
                        : 'Billed annually'
                      : undefined
                  }
                  billingSubText={billingSubText}
                />
              );
            })}
          </IonRow>
        </IonRow>
      )}
      {userState.user?.configuration.currency ? (
        <h6
          style={{
            fontFamily: 'nexa',
            textAlign: 'center',
            marginBottom: '3em',
          }}
        >
          *All FareDrop plans are in USD
        </h6>
      ) : (
        <div style={{ height: '6em' }} />
      )}
      {
        // Don't show gift redemption section if the user is already free trialing
        userState.hasPaymentInfo && !hasStripeCustomerId && (
          <h4
            style={{
              fontFamily: 'nexa',
              textAlign: 'center',
              marginBottom: '3em',
            }}
          >
            Have a Gift Code?{' '}
            <a
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => {
                setShowGiftCodeForm(true);
              }}
            >
              Redeem here
            </a>
          </h4>
        )
      }
      {hasActivePlan && currentPlan !== FareDropPlan.Limited && (
        <CancelSubscription onCancel={onCancelSubscription} />
      )}
    </>
  );

  const renderRedeemGift = (
    <IonRow
      style={{
        marginTop: '4em',
        marginBottom: '8em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IonRow style={{ maxWidth: '500px', padding: '2em' }}>
        <h4
          style={{
            fontFamily: 'nexa',
            textAlign: 'center',
            marginBottom: '1em',
          }}
        >
          Enter your Gift Code to start receiving incredible flight deals!
        </h4>
        <Formik
          initialValues={{
            giftCode: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            giftCode: Yup.string().required('Gift Code is required'),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting }
          ): Promise<void> => {
            try {
              setIsLoading(true);
              await redeemSubscription(values.giftCode);
              goWithStickyParamsPath(AllowedPath.GET_STARTED_WELCOME_PAID);
              setStatus({ success: true });
              setSubmitting(false);

              logAnalyticsEngagement &&
                (await logAnalyticsEngagement(
                  AnalyticsEngagementId.REDEEM_GIFT
                ));
            } catch (err) {
              setIsLoading(false);
              setStatus({ success: false });
              setErrors({ submit: parseError(err).join('. ') });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }): JSX.Element => {
            return (
              <form
                noValidate
                onSubmit={handleSubmit}
                style={{ width: '100%' }}
              >
                <TextField
                  error={Boolean(touched.giftCode && errors.giftCode)}
                  fullWidth
                  helperText={touched.giftCode && errors.giftCode}
                  label="Gift Code"
                  margin="normal"
                  name="giftCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.giftCode}
                  variant="outlined"
                />
                {errors.submit && (
                  <IonRow
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FormHelperText error>{errors.submit}</FormHelperText>
                    <FormHelperText error>
                      Having trouble?{' '}
                      <a
                        style={{ fontWeight: 'bold', color: 'darkred' }}
                        href={buildRedeemFailedMailTo(
                          values.giftCode,
                          errors.submit
                        )}
                      >
                        Click here
                      </a>{' '}
                      for assistance.
                    </FormHelperText>
                  </IonRow>
                )}
                <IonRow>
                  <button
                    className="button no-caps submit-button"
                    disabled={
                      submitDisabled(values, touched, errors) || isLoading
                    }
                    style={{
                      opacity: submitDisabled(values, touched, errors)
                        ? 0.5
                        : 1.0,
                    }}
                    type="submit"
                  >
                    {(isLoading && (
                      <IonSpinner color="white" name="crescent" />
                    )) || <p>Redeem</p>}
                  </button>
                </IonRow>
              </form>
            );
          }}
        </Formik>
      </IonRow>
    </IonRow>
  );

  const planComponent = showGiftCodeForm
    ? renderRedeemGift
    : renderPlanSelection;
  return (
    <>
      {planComponent}
      <SocialProofSection />
      <IonRow
        style={{
          boxShadow: '0px -100px 100px #fff',
          margin: '4em 0',
          display: 'flex',
        }}
      >
        <IonCol
          sizeXl="4"
          sizeLg="12"
          style={{ display: 'flex', margin: 'auto', marginBottom: '2em' }}
        >
          <DeleteAccount />
        </IonCol>
      </IonRow>
    </>
  );
};

export default ChoosePlan;
