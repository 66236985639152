import { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export interface StripeContextValue {
  stripePromise: ReturnType<typeof loadStripe>;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY ?? '');

// Since we set the StripeProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const StripeContext = createContext<StripeContextValue>({
  stripePromise: Promise.resolve(null),
});

interface StripePropsProps {
  children: ReactNode;
}

export const StripeProvider: FC<StripePropsProps> = (props) => {
  return (
    <StripeContext.Provider
      value={{
        stripePromise,
      }}
    >
      {props.children}
    </StripeContext.Provider>
  );
};

export default StripeContext;
