import useSWR, { Key, useSWRConfig } from 'swr';

import usePresentToast from './presentToast';
import { Fetcher, SWRConfiguration } from 'swr/dist/types';

const TIMEOUT_PREFIX = 'SWR_TIMEOUT';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useSWRTimeout<Data = any, SWRError = any>(
  ...args:
    | readonly [Key, string | undefined]
    | readonly [Key, Fetcher<Data> | null, string | undefined]
    | readonly [
        Key,
        SWRConfiguration<Data, SWRError> | undefined,
        string | undefined
      ]
    | readonly [
        Key,
        Fetcher<Data> | null,
        SWRConfiguration<Data, SWRError> | undefined,
        string | undefined
      ]
) {
  const { cache } = useSWRConfig();
  const { presentError } = usePresentToast();

  const generateTimeoutKey = (key: Key) => {
    if (typeof key === 'string') return key;
    else if (Array.isArray(key)) {
      return key.join('#');
    }
    return JSON.stringify(key);
  };

  const key = args[0];

  let friendlyErrorMessage: string | undefined;
  let fetcher: Fetcher<Data> | undefined;
  for (let i = 1; i < args.length; i++) {
    if (args[i]) {
      if (typeof args[i] === 'function') {
        fetcher = args[i] as Fetcher<Data>;
      }

      if (typeof args[i] === 'string') {
        friendlyErrorMessage = args[i] as string;
      }
    }
  }

  const { data, error, mutate, isValidating } = useSWR(
    key,
    async (...scopedArgs: string[]) => {
      const timeoutKey = generateTimeoutKey(scopedArgs);
      try {
        if (fetcher) {
          const result = await fetcher(...scopedArgs);
          cache.set(`${TIMEOUT_PREFIX}#${timeoutKey}`, false);
          return result;
        }

        return;
      } catch (error) {
        const errors: Error[] = (
          Array.isArray(error) ? error : [error]
        ) as Error[];
        if (errors.some((e) => (e as Error).name === 'AbortError')) {
          cache.set(`${TIMEOUT_PREFIX}#${timeoutKey}`, true);
        } else if (friendlyErrorMessage) {
          console.error(friendlyErrorMessage, error);
          presentError(friendlyErrorMessage);
        }
        throw errors;
      }
    },
    {
      errorRetryCount: 3,
    }
  );

  return {
    data,
    error,
    mutate,
    isValidating,
    timeout: cache.get(`${TIMEOUT_PREFIX}#${generateTimeoutKey(key)}`) ?? false,
  };
}

export default useSWRTimeout;
