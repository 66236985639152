import { Capacitor } from '@capacitor/core';

const config = {
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: Capacitor.isNativePlatform()
    ? process.env.REACT_APP_USER_POOL_CLIENT_ID_APP
    : process.env.REACT_APP_USER_POOL_CLIENT_ID,
};

export default config;
