import { IonCol, IonRow } from '@ionic/react';

import {
  SubscriptionPlan as SubscriptionPlanGQL,
  FareDropPlan,
} from '@faredrop/graphql-sdk';
import { SubscriptionPlan } from '@faredrop/types';
import { applyPromotion, isLimitedPlan } from '@faredrop/utilities';

import './../theme/DealsSection.css';
import './../theme/util.css';
import { SubscriptionPlanCard } from './SubscriptionPlanCard';
import './../theme/SubscriptionPlanSection.css';
import { useDevice } from '../hooks/useDevice';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import usePlans from '../hooks/usePlans';
import Loading from './Loading';
import {
  calculateDiscountPrice,
  convertSubscriptionPlanTypeToGQL,
  isFrontendSubscriptionPromotion,
} from '../utilities/plans-utilities';
import { useDisplayDiscounts } from '../hooks/useDisplayDiscounts';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import useUser from '../hooks/user';

interface ContainerProps {
  sx?: React.CSSProperties;
  onSelect?: (plan: FareDropPlan | string) => Promise<void>;
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  planDiscount?: number;
  showFreeTrial?: boolean;
  title?: string | null; // null will remove the title, undefined will show the default
  selectedPlan?: SubscriptionPlanGQL;
  selectedPlanButtonText?: string;
  notSelectedPlanButtonText?: string;
  hidePlans?: FareDropPlan[];
  hideBillingTextSection?: (plan: FareDropPlan) => boolean;
  removeBottomMargin?: boolean;
  reverse?: boolean;
}

const SubscriptionPlansSection: React.FC<ContainerProps> = ({
  sx,
  onSelect,
  logAnalyticsEngagement,
  planDiscount,
  showFreeTrial,
  title,
  selectedPlan,
  selectedPlanButtonText,
  notSelectedPlanButtonText,
  hidePlans,
  hideBillingTextSection,
  removeBottomMargin,
  reverse,
}) => {
  const { smoothScroll } = useLandingPageNavigation();
  const { isExtraSmallScreenSize } = useDevice();
  const { plans } = usePlans();
  const { discounts } = useDisplayDiscounts();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const userState = useUser();

  const updatedPlans: SubscriptionPlanGQL[] = [];
  if (plans) {
    if (reverse) {
      updatedPlans.push(...[...plans].reverse());
    } else {
      updatedPlans.push(...plans);
    }
  }

  const isLimited = isLimitedPlan(userState.user?.billing.idStripePlan);

  return (
    <IonRow style={{ backgroundColor: 'white', ...sx }} id="pricing">
      <IonCol className="subscription-plan-container">
        {title !== null && (
          <IonRow>
            <IonCol>
              <h2 className="subscription-section-title title-font mt-2">
                {title ?? 'Get deals from your home airport'}
              </h2>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol className="subscription-plan-wrapper" size="12">
            <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
              {(updatedPlans ?? []).length === 0 ? (
                <Loading />
              ) : (
                (updatedPlans ?? [])
                  .filter((p) => !hidePlans?.includes(p.planType))
                  .map((cardPlan) => {
                    const plan = isFrontendSubscriptionPromotion(isLimited)
                      ? convertSubscriptionPlanTypeToGQL(
                          applyPromotion(cardPlan as SubscriptionPlan)
                        )
                      : cardPlan;

                    const isPriceNumber = !isNaN(parseInt(plan.price));
                    return (
                      <SubscriptionPlanCard
                        key={plan.id + plan.name}
                        plan={plan}
                        buttonOnClickListener={async () => {
                          if (showFreeTrial) {
                            const url = new URL(window?.location?.href);
                            if (!url.searchParams.has('trial')) {
                              url.searchParams.append('trial', '');
                              window.history.pushState({}, '', url.href);
                            }
                          }

                          if (onSelect) {
                            await onSelect(plan.planType);
                          } else {
                            const url = new URL(window?.location?.href);
                            url.searchParams.set(
                              'selected-plan',
                              plan.planType
                            );
                            goWithStickyParamsLocation({
                              search: url.searchParams.toString(),
                            });
                            smoothScroll('#register');
                          }

                          logAnalyticsEngagement &&
                            (await logAnalyticsEngagement(
                              `${plan.planType.toLowerCase()}_plan_get_started` as AnalyticsEngagementId
                            ));
                        }}
                        discountPrice={calculateDiscountPrice(
                          plan,
                          planDiscount,
                          discounts
                        )}
                        discountImage={plan.discountImage ?? undefined}
                        sx={{
                          border: '2px solid #dadada',
                          minWidth: isExtraSmallScreenSize ? '100%' : undefined,
                          margin: isExtraSmallScreenSize ? '0 auto 2em' : '1em',
                        }}
                        billingText={
                          (discounts && discounts[plan.planType]) ||
                          (isPriceNumber &&
                            isFrontendSubscriptionPromotion(isLimited))
                            ? 'For the first year'
                            : isPriceNumber
                            ? plan.billingText
                              ? plan.billingText
                              : 'Billed annually'
                            : undefined
                        }
                        billingSubText={
                          (discounts && discounts[plan.planType]) ||
                          (isPriceNumber &&
                            isFrontendSubscriptionPromotion(isLimited))
                            ? 'Renews at full-price'
                            : plan.billingSubtext && isPriceNumber
                            ? plan.billingSubtext
                            : undefined
                        }
                        hideBillingTextSection={hideBillingTextSection}
                        border={
                          selectedPlan?.planType === plan.planType
                            ? '2px solid var(--ion-color-primary)'
                            : undefined
                        }
                        buttonText={
                          selectedPlan
                            ? selectedPlan.planType === plan.planType
                              ? selectedPlanButtonText ?? 'Selected Plan'
                              : notSelectedPlanButtonText ?? 'Select Plan'
                            : undefined
                        }
                        buttonTextColor={
                          selectedPlan?.planType === plan.planType
                            ? 'white'
                            : undefined
                        }
                        buttonColor={
                          selectedPlan?.planType === plan.planType
                            ? 'var(--ion-color-primary)'
                            : undefined
                        }
                      />
                    );
                  })
              )}
            </IonRow>
          </IonCol>
        </IonRow>
        {!removeBottomMargin && (
          <IonRow className="mb-3">
            {/* <IonCol>
              <h4
                style={{
                  fontFamily: 'nexa',
                  textAlign: 'center',
                }}
              >
                All plans include our{' '}
                <a
                  style={{ fontWeight: 'bold', cursor: 'auto' }}
                  // TODO: Link to FAQ instead
                  // onClick={() => {
                  //   goWithStickyParamsPath('/refund-policy');
                  //   logAnalyticsEngagement &&
                  //     logAnalyticsEngagement(
                  //       AnalyticsEngagementId.PRICING_REFUND_POLICY
                  //     );
                  // }}
                >
                  no questions asked, 90 day refund policy
                </a>
              </h4>
            </IonCol> */}
          </IonRow>
        )}
      </IonCol>
    </IonRow>
  );
};

export default SubscriptionPlansSection;
