import { IonCol, IonRow } from '@ionic/react';

import { getCurrencySymbol } from '@faredrop/utilities';

import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  price: number;
  usually: number;
  currency?: string;
  style?: React.CSSProperties;
  textColor?: string;
  usePercent?: boolean;
}

const DealPriceHeader: React.FC<ContainerProps> = ({
  currency,
  price,
  usually,
  style,
  textColor,
  usePercent,
}) => {
  const { isMediumScreenSizeOrSmaller } = useDevice();

  const percentDiff = Math.abs(Math.floor((1 - price / usually) * 100));

  const topText = usePercent ? `${percentDiff}%` : 'Usually';
  const bottomElement = usePercent ? (
    `${price > usually ? 'higher' : 'lower'} than average`
  ) : (
    <>
      <span
        style={{
          fontSize: 10,
          verticalAlign: 'text-top',
          marginTop: 2,
          marginRight: 2,
        }}
      >
        {getCurrencySymbol(currency)}
      </span>
      {Math.trunc(usually ?? 0)}
    </>
  );

  return (
    <IonRow
      className="row-vertical-align"
      style={{
        backgroundColor: 'var(--ion-color-primary)',
        borderRadius: 5,
        height: 'inherit',
        padding: '.6em 1em .4em',
        display: 'flex',
        zIndex: 10000000,
        width: '90%',
        ...style,
      }}
    >
      <IonCol
        className="br-white"
        style={{
          display: 'flex',
          paddingRight: !isMediumScreenSizeOrSmaller ? '1em' : undefined,
          borderColor: textColor,
        }}
      >
        <h2 style={{ color: textColor ?? 'white', margin: 'auto' }}>
          <span
            style={{
              fontSize: 16,
              verticalAlign: 'text-top',
              marginTop: 2,
              marginRight: 2,
            }}
          >
            {getCurrencySymbol(currency)}
          </span>
          {price}
        </h2>
      </IonCol>
      <IonCol style={{ display: 'flex' }}>
        <IonRow style={{ margin: 'auto' }}>
          <IonCol>
            <IonRow
              style={{
                justifyContent: usePercent ? 'center' : 'flex-end',
                marginBottom: usePercent ? 1 : 5,
              }}
            >
              <p
                style={{
                  color: textColor ?? 'white',
                  fontSize: usePercent ? undefined : 10,
                  margin: 0,
                }}
              >
                {topText}
              </p>
            </IonRow>
            <IonRow
              style={{
                justifyContent: 'flex-end',
                fontFamily: 'nexa',
              }}
            >
              <p
                style={{
                  color: textColor ?? 'white',
                  margin: 0,
                  fontSize: usePercent ? '10px' : undefined,
                }}
              >
                {bottomElement}
              </p>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default DealPriceHeader;
