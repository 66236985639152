import {
  IonCol,
  IonIcon,
  IonMenuToggle,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { arrowBackOutline, menuOutline } from 'ionicons/icons';
import { MouseEventHandler } from 'react';
import { useHistory } from 'react-router';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/MobileHeader.css';
import { FaredropLogo } from './FaredropLogo';
import { AllowedPath } from '@faredrop/types';

interface ContainerProps {
  showLoadingDiv?: boolean;
  menuHidden?: boolean;
  onMenuClickHandler?: () => void;
  showBackToHome?: boolean;
  backToHomeListener?: MouseEventHandler<HTMLAnchorElement>;
  backToHomeText?: string;
  bannerText?: string;
  optionsExcludeQueryParams?: string[];
}

const MobileHeader: React.FC<ContainerProps | undefined> = (props) => {
  const { isAuthenticated } = useAuth();
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const history = useHistory();

  const userState = useUser();
  const configInitializing = userState.isInitializing;

  return (
    <>
      <IonRow
        className="row-vertical-align desktop-menu"
        style={{
          justifyContent: 'left',
          display: `${
            isLargeScreenSizeOrSmaller && !props?.menuHidden ? 'flex' : 'none'
          }`,
          padding: '5px',
        }}
      >
        <IonCol>
          <FaredropLogo
            bw={false}
            excludeParams={props.optionsExcludeQueryParams}
          />
        </IonCol>
        {props?.menuHidden || isAuthenticated || (
          <IonCol>
            <IonMenuToggle autoHide={false}>
              <IonIcon
                className="hamburger-menu-toggle"
                onClick={() =>
                  props?.onMenuClickHandler && props?.onMenuClickHandler()
                }
                icon={menuOutline}
              />
            </IonMenuToggle>
          </IonCol>
        )}
      </IonRow>
      {props?.menuHidden && props?.showBackToHome && (
        <div
          style={{
            position: 'absolute',
            left: '1em',
            top: props.bannerText !== undefined ? '4em' : '2em',
          }}
        >
          <a
            onClick={(e) => {
              if (props?.backToHomeListener) {
                props?.backToHomeListener(e);
              } else {
                history.push(AllowedPath.LOGOUT);
              }
            }}
          >
            <button
              className="transparent-button md-button green-button"
              style={{
                display: 'flex',
                fontSize: '16px',
                color: 'var(--ion-color-primary)',
                border: 0,
              }}
            >
              <IonIcon icon={arrowBackOutline} style={{ margin: 'auto' }} />
              <p
                style={{
                  margin: 'auto',
                  paddingTop: 2,
                  paddingLeft: 5,
                  whiteSpace: 'nowrap',
                }}
              >
                {props.backToHomeText ?? 'Back to Home'}
              </p>
            </button>
          </a>
        </div>
      )}
      {configInitializing && props?.showLoadingDiv && (
        <div
          style={{
            display: 'contents',
            width: '100%',
            position: 'absolute',
            zIndex: -1,
            margin: 'auto',
          }}
        >
          <div
            style={{
              height: '100%',
              backgroundColor: 'white',
              margin: 'auto',
            }}
          >
            <p style={{ lineHeight: '2em', color: '#11111155' }}>
              <span>
                <IonSpinner
                  name="crescent"
                  style={{
                    width: '20px',
                    height: '20px',
                    opacity: 0.3,
                    verticalAlign: 'middle',
                    marginRight: '.5em',
                  }}
                  color="primary"
                />
              </span>
              Loading ...
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
