import { IonContent, IonPage } from '@ionic/react';
import DesktopHeader from '../components/DesktopHeader';
import HidingHeader from '../components/HidingHeader';
import MobileHeader from '../components/MobileHeader';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDevice } from '../hooks/useDevice';
import Footer from '../components/Footer';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { useDynamicBanner } from '../hooks/useDynamicBanner';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const TermsOfService: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsPath, goWithStickyParamsLocation } =
    useHistoryWithStickyParams();
  const dynamicBannerContent = useDynamicBanner();

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
        style={{ display: 'flex' }}
      >
        <div
          style={{
            padding: '6em',
            fontFamily: 'nexa',
            fontSize: 14,
            maxWidth: '750px',
            margin: '0 auto',
            lineHeight: '1.8em',
          }}
        >
          <h1
            style={{
              fontSize: 32,
              marginBottom: '1em',
              textAlign: 'center',
              fontFamily: 'nexa-bold',
            }}
          >
            TERMS OF SERVICE
          </h1>
          <p>
            <span>
              <br />
              <b style={{ fontFamily: 'nexa-bold' }}>OVERVIEW</b>
              <br />
              <br />
              This website is operated by FareDrop, Inc. Throughout the site,
              the terms “we”, “us” and “our” refer to FareDrop, Inc. FareDrop,
              Inc. offers this website, including all information, tools and
              services available from this site to you, the user, conditioned
              upon your acceptance of all terms, conditions, policies and
              notices stated here.
              <br />
              <br />
              By visiting our site and/ or purchasing something from us
              (including, without limitation a digital subscription)
              (collectively, “Products”), you engage in our “Service” and agree
              to be bound by the following terms and conditions (“Terms of
              Service”, “Terms”), including those additional terms and
              conditions and policies referenced herein and/or available by
              hyperlink. These Terms of Service apply to all users of the site,
              including without limitation users who are browsers, vendors,
              customers, merchants, and/ or contributors of content.
              <br />
              <br />
              Please read these Terms of Service carefully before accessing or
              using our website. By accessing or using any part of the site, you
              agree to be bound by these Terms of Service. If you do not agree
              to all the terms and conditions of this agreement, then you may
              not access the website or use any services. If these Terms of
              Service are considered an offer, acceptance is expressly limited
              to these Terms of Service.
              <br />
              <br />
              Any new features or tools which are added to the current website
              shall also be subject to the Terms of Service. You can review the
              most current version of the Terms of Service at any time on this
              page. We reserve the right to update, change or replace any part
              of these Terms of Service by posting updates and/or changes to our
              website. It is your responsibility to check this page periodically
              for changes. Your continued use of or access to the website
              following the posting of any changes constitutes acceptance of
              those changes.
              <br />
              <br />
              Our store and site is hosted by Amazon Web Service. We use Stripe
              as our payment processing and subscription management service.
              These providers provide us with the online e-commerce platform
              that allows us to sell our Products and services to you.
              <br />
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 1 - ONLINE STORE TERMS
              </b>
              <br />
              <br />
              By agreeing to these Terms of Service, you represent that you are
              at least the age of majority in your state or province of
              residence, or that you are the age of majority in your state or
              province of residence and you have given us your consent to allow
              any of your minor dependents to use this site.
              <br />
              <br />
              You may not use our Products for any illegal or unauthorized
              purpose nor may you, in the use of the Service, violate any laws
              in your jurisdiction (including but not limited to copyright
              laws).
              <br />
              <br />
              You must not transmit any worms or viruses or any code of a
              destructive nature.
              <br />
              <br />
              A breach or violation of any of the Terms will result in an
              immediate termination of your Services.
              <br />
              <br />
              An order by you for services or Products is deemed by us to be an
              offer to purchase, which we may accept or reject in our sole
              discretion. Our acceptance of an offer to purchase is binding on
              us only if made by written instrument or, if not by written
              instrument, by our issuance of log-in credentials to you for the
              Products ordered. Any automatic or computer generated response to
              an order by our internal electronic data exchange system or
              otherwise shall not be deemed acceptance of an order.
              <br />
              <br />
              You shall pay all sales, use, excise or similar taxes, or other
              charges, which we are required to pay, or to collect and remit, to
              any government (national, state or local) and which are imposed on
              or measured by the sale.
              <br />
              <br />
              We retain the right and title to the Products sold to you. You are
              permitted to use, on a non-exclusive, royalty bearing basis, the
              Products to obtain their benefits (namely reduced price airfare),
              but all source code, intellectual property, or other attributes
              remain the sole property of FareDrop, Inc.
              <br />
              <br />
              You shall have no right of set-off or withholding, and no
              deduction of any amounts due from you to us shall be made without
              our prior, express written approval.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 2 - GENERAL CONDITIONS
              </b>
              <br />
              <br />
              We reserve the right to refuse service to anyone for any reason at
              any time in compliance with applicable law.
              <br />
              <br />
              You understand that your content (not including credit card
              information), may be transferred un-encrypted and involve (a)
              transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or
              devices. Credit card information is always encrypted during
              transfer over networks.
              <br />
              <br />
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service or Products. However, you may
              “share a deal” on social media through our social sharing feature.
              You may not share or duplicate any portion of the Service or
              Products through any other means. You and members of your
              household may use your log-in credentials to access the Products
              on up to 5 devices. If you and your household members use your
              log-in credentials to access the Products on more than 5 devices
              then only the 5 most recently used devices will be able to access
              the Products. Except as provided herein, you agree not to permit
              any other person to use or access the Products using your log-in
              credentials and will use your best efforts to safeguard such
              credentials and prevent their unauthorized use.
              <br />
              <br />
              The headings used in this agreement are included for convenience
              only and will not limit or otherwise affect these Terms.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
              </b>
              <br />
              <br />
              We are not responsible if information made available on this site
              is not accurate, complete or current. The material on this site is
              provided for general information only and should not be relied
              upon or used as the sole basis for making decisions without
              consulting primary, more accurate, more complete or more timely
              sources of information. Any reliance on the material on this site
              is at your own risk.
              <br />
              <br />
              This site may contain certain historical information. Historical
              information, necessarily, is not current and is provided for your
              reference only. We reserve the right to modify the contents of
              this site at any time, but we have no obligation to update any
              information on our site. You agree that it is your responsibility
              to monitor changes to our site.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
              </b>
              <br />
              <br />
              Prices for our Products are subject to change without notice.
              <br />
              <br />
              We reserve the right at any time to modify or discontinue the
              Service (or any part or content thereof) or Products without
              notice at any time.
              <br />
              <br />
              We shall not be liable to you or to any third-party for any
              modification, price change, suspension or discontinuance of the
              Service or Products.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 5 - PRODUCTS OR SERVICES
              </b>
              <br />
              <br />
              Certain Products or services may be available exclusively online
              through the website. These Products or services may have limited
              quantities and are subject to return or exchange only according to
              our Return Policy which is accessible here{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                onClick={() => goWithStickyParamsPath('/refund-policy')}
              >
                http://www.faredrop.com/refund-policy
              </a>{' '}
              and is incorporated herein by reference.
              <br />
              <br />
              We reserve the right, but are not obligated, to limit the sales of
              our Products or services to any person, geographic region or
              jurisdiction. We may exercise this right on a case-by-case basis.
              We reserve the right to limit the quantities of any Products or
              services that we offer. All descriptions of Products or Product
              pricing are subject to change at anytime without notice, at the
              sole discretion of us. We reserve the right to discontinue any
              Product at any time. Any offer for any Product or service made on
              this site is void where prohibited.
              <br />
              <br />
              We do not warrant that the quality of any Products, services,
              information, or other material purchased or obtained by you will
              meet your expectations, be error free, be available for your use
              at any given time, or that any errors in the Products or Service
              will be corrected.
              <br />
              <br />
              Given the ever-changing and time-sensitive nature of our business
              we do not guarantee that a travel deal will still be active by the
              time you access it. We make no representation that any airline or
              other business will honor the travel deal identified in the
              Subscriptions (defined below). You acknowledge and agree that
              mistake fares and the other travel deals identified in the
              Subscriptions can be extremely short lived and may not honored by
              an airline or other business. We will use commercially reasonable
              efforts to provide you with Products which identify travel deals
              which you may actually use, however you acknowledge and agree that
              such travel deals may not always be available, error free, or
              honored by the airline or any other business. The Products consist
              only of information you may use with third parties (like airlines)
              and do not consist of a guarantee by us of any particular outcome.
              <br />
              <br />
              Our Products consist primarily of annual subscriptions for
              discounted airfare (“Subscriptions”). These Subscriptions may be
              cancelled at any time, provided however that refunds are addressed
              in accordance with our Return Policy which is accessible here{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                onClick={() => goWithStickyParamsPath('/refund-policy')}
              >
                http://www.faredrop.com/refund-policy
              </a>{' '}
              and is incorporated herein by reference. Pursuant to this Return
              Policy after a certain period of time no refunds will be issued.
              Your Subscription will renew automatically on or around the annual
              anniversary of your initial purchase date. You may opt out of
              auto-renewal at any time by accessing your Subscription account
              and updating your renewal preferences.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
              </b>
              <br />
              <br />
              We reserve the right to refuse any order you place with us. We
              may, in our sole discretion, limit or cancel quantities purchased
              per person, per household or per order. These restrictions may
              include orders placed by or under the same customer account, the
              same credit card, and/or orders that use the same billing and/or
              shipping address. In the event that we make a change to or cancel
              an order, we may attempt to notify you by contacting the e-mail
              and/or billing address/phone number provided at the time the order
              was made. We reserve the right to limit or prohibit orders that,
              in our sole judgment, appear to be placed by dealers, resellers or
              distributors.
              <br />
              <br />
              You agree to provide current, complete and accurate purchase and
              account information for all purchases made at our store. You agree
              to promptly update your account and other information, including
              your email address and credit card numbers and expiration dates,
              so that we can complete your transactions and contact you as
              needed.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 7 - OPTIONAL TOOLS
              </b>
              <br />
              <br />
              We may provide you with access to third-party tools over which we
              neither monitor nor have any control nor input.
              <br />
              <br />
              You acknowledge and agree that we provide access to such tools “as
              is” and “as available” without any warranties, representations or
              conditions of any kind and without any endorsement. We shall have
              no liability whatsoever arising from or relating to your use of
              optional third-party tools.
              <br />
              <br />
              Any use by you of optional tools offered through the site is
              entirely at your own risk and discretion and you should ensure
              that you are familiar with and approve of the terms on which tools
              are provided by the relevant third-party provider(s).
              <br />
              <br />
              We may also, in the future, offer new services and/or features
              through the website (including, the release of new tools and
              resources). Such new features and/or services shall also be
              subject to these Terms of Service.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 8 - THIRD-PARTY LINKS
              </b>
              <br />
              <br />
              Certain content, Products and services available via our Service
              may include materials from third-parties.
              <br />
              <br />
              Third-party links on this site may direct you to third-party
              websites that are not affiliated with us. We are not responsible
              for examining or evaluating the content or accuracy and we do not
              warrant and will not have any liability or responsibility for any
              third-party materials or websites, or for any other materials,
              Products, or services of third-parties.
              <br />
              <br />
              We are not liable for any harm or damages related to the purchase
              or use of Products, goods, services, resources, content, or any
              other transactions made in connection with any third-party
              websites. Please review carefully the third-party&apos;s policies
              and practices and make sure you understand them before you engage
              in any transaction. Complaints, claims, concerns, or questions
              regarding third-party products should be directed to the
              third-party.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
              </b>
              <br />
              <br />
              If, at our request, you send certain specific submissions (for
              example contest entries) or without a request from us you send
              creative ideas, suggestions, proposals, plans, or other materials,
              whether online, by email, by postal mail, or otherwise
              (collectively, &apos;comments&apos;), you agree that we may, at
              any time, without restriction, edit, copy, publish, distribute,
              translate and otherwise use in any medium any comments that you
              forward to us. We are and shall be under no obligation (1) to
              maintain any comments in confidence; (2) to pay compensation for
              any comments; or (3) to respond to any comments.
              <br />
              <br />
              We may, but have no obligation to, monitor, edit or remove content
              that we determine in our sole discretion are unlawful, offensive,
              threatening, libelous, defamatory, pornographic, obscene or
              otherwise objectionable or violates any party’s intellectual
              property or these Terms of Service.
              <br />
              <br />
              You agree that your comments will not violate any right of any
              third-party, including copyright, trademark, privacy, personality
              or other personal or proprietary right. You further agree that
              your comments will not contain libelous or otherwise unlawful,
              abusive or obscene material, or contain any computer virus or
              other malware that could in any way affect the operation of the
              Service or any related website. You may not use a false e-mail
              address, pretend to be someone other than yourself, or otherwise
              mislead us or third-parties as to the origin of any comments. You
              are solely responsible for any comments you make and their
              accuracy. We take no responsibility and assume no liability for
              any comments posted by you or any third-party.
              <br />
              <br />
              In consideration for your use of the Service, you hereby grant us
              a world-wide, non-exclusive, royalty-free, transferrable license
              to publish, display, or use in any way we see fit your customized
              Products, in whole or part, for our marketing, promotional,
              quality control, or any other purposes without attribution or
              payment to you.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 10 - PERSONAL INFORMATION
              </b>
              <br />
              <br />
              Your submission of personal information through the store is
              governed by our Privacy Policy which is accessible here{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                onClick={() => goWithStickyParamsPath('/privacy-policy')}
              >
                http://www.faredrop.com/privacy-policy
              </a>{' '}
              and is incorporated herein by reference.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS
              </b>
              <br />
              <br />
              Occasionally there may be information on our site or in the
              Service that contains typographical errors, inaccuracies or
              omissions that may relate to product descriptions, pricing,
              promotions, offers, product shipping charges, transit times and
              availability. We reserve the right to correct any errors,
              inaccuracies or omissions, and to change or update information or
              cancel orders if any information in the Service or on any related
              website is inaccurate at any time without prior notice (including
              after you have submitted your order).
              <br />
              <br />
              We undertake no obligation to update, amend or clarify information
              in the Service or on any related website, including without
              limitation, pricing information, except as required by law. No
              specified update or refresh date applied in the Service or on any
              related website, should be taken to indicate that all information
              in the Service or on any related website has been modified or
              updated.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 12 - PROHIBITED USES
              </b>
              <br />
              <br />
              In addition to other prohibitions as set forth in the Terms of
              Service, you are prohibited from using the site or its content:
              (a) for any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules,
              laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights
              of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual
              orientation, religion, ethnicity, race, age, national origin, or
              disability; (f) to submit false or misleading information; (g) to
              upload or transmit viruses or any other type of malicious code
              that will or may be used in any way that will affect the
              functionality or operation of the Service or of any related
              website, other websites, or the Internet; (h) to collect or track
              the personal information of others; (i) to spam, phish, pharm,
              pretext, spider, crawl, or scrape; (j) for any obscene or immoral
              purpose; or (k) to interfere with or circumvent the security
              features of the Service or any related website, other websites, or
              the Internet. We reserve the right to terminate your use of the
              Service or any related website for violating any of the prohibited
              uses.
              <br />
              <br />
              Without limiting the foregoing, you agree not to use the Service,
              Products, information, intellectual property, or data obtained
              from us to commercially compete with us. We reserve the right to
              terminate your ability to use our Service or Products in the event
              that we reasonably believe that your use violates these Terms of
              Service, otherwise causes, or has the potential to cause, harm to
              us (including, without limitation, competitive / business harm) or
              third parties, or is used for any purpose other than your
              individual / household use (such as scraping content or competing
              with us).
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
              </b>
              <br />
              <br />
              We do not guarantee, represent or warrant that your use of our
              Service will be uninterrupted, timely, secure or error-free.
              <br />
              <br />
              We do not warrant that the results that may be obtained from the
              use of the Service will be accurate or reliable.
              <br />
              <br />
              You agree that from time to time we may remove the Service for
              indefinite periods of time or cancel the Service at any time,
              without notice to you.
              <br />
              <br />
              You expressly agree that your use of, or inability to use, the
              Service is at your sole risk. THE SERVICE AND ALL PRODUCTS AND
              SERVICES DELIVERED TO YOU THROUGH THE SERVICE ARE (EXCEPT AS
              EXPRESSLY STATED BY US) PROVIDED &apos;AS IS&apos; AND &apos;AS
              AVAILABLE&apos; FOR YOUR USE, WITHOUT ANY REPRESENTATION,
              WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED,
              INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OR
              CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A
              PARTICULAR PURPOSE, DURABILITY, TITLE, AND NON-INFRINGEMENT. YOU
              ASSUMES ALL RISK WHATSOEVER AS TO THE RESULT OF THE USE OF
              PRODUCTS OR SERVICES PURCHASED, WHETHER USED ALONE OR IN
              COMBINATION WITH OTHER PRODUCTS OR SERVICES.
              <br />
              <br />
              In no case shall FareDrop, Inc. and our parent, subsidiaries,
              affiliates, partners, and their respective members, partners,
              shareholders, managers, officers, directors, agents, contractors,
              licensors, service providers, subcontractors, suppliers, interns
              and employees (collectively, the “FareDrop Parties”) be liable for
              any injury, loss, claim, or any direct, indirect, incidental,
              punitive, special, or consequential damages of any kind,
              including, without limitation lost profits, lost revenue, lost
              savings, loss of data, replacement costs, or any similar damages,
              whether based in contract, tort (including negligence), strict
              liability or otherwise and any reasonable attorneys’ fees,
              paralegal fees, accounting fees, court cost, and expenses, made by
              any third-party (collectively, “Damages”), arising from your use
              of any of the service or any products procured using the service,
              or for any other claim related in any way to your use of the
              Service or any Product, including, but not limited to, any errors
              or omissions in any content, or any loss or damage of any kind
              incurred as a result of the use of the Service or any content (or
              Product) posted, transmitted, or otherwise made available via the
              Service, even if advised of their possibility. Because some states
              or jurisdictions do not allow the exclusion or the limitation of
              liability for consequential or incidental damages, in such states
              or jurisdictions, our liability shall be limited to the maximum
              extent permitted by law.
              <br />
              <br />
              No claim by you of any kind including, but not limited to, claims
              for indemnification, whether as to quality or amount of product
              delivered or non-delivery, shall be greater in amount than the
              purchase price for the Products or services purchased from us
              hereunder in respect of which damages are claimed.
              <br />
              <br />
              Any claim by you arising out of these Terms of Service must be
              made within one (1) year of the accrual of such claim. Any claim
              made outside of this period of limitations shall be time-barred.
              <br />
              <br />
              Any representation by us regarding past performance of any of the
              Products is presented for informational purposes only and is not a
              promise or warranty of future similar performance and any such
              warranty is hereby expressly disclaimed. Your use of the Products
              is at your own risk.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 14 - INDEMNIFICATION
              </b>
              <br />
              <br />
              You agree to indemnify, defend and hold harmless the FareDrop
              Parties, from any Damages suffered by such FareDrop Parties due to
              or arising out of your breach of these Terms of Service or the
              documents they incorporate by reference, or your violation of any
              law or the rights of a third-party.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 15 - SEVERABILITY
              </b>
              <br />
              <br />
              In the event that any provision of these Terms of Service is
              determined to be unlawful, void or unenforceable, such provision
              shall nonetheless be enforceable to the fullest extent permitted
              by applicable law, and the unenforceable portion shall be deemed
              to be severed from these Terms of Service, such determination
              shall not affect the validity and enforceability of any other
              remaining provisions.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 16 - TERMINATION
              </b>
              <br />
              <br />
              The obligations and liabilities of the parties incurred prior to
              the termination date shall survive the termination of this
              agreement for all purposes. These Terms of Service are effective
              unless and until terminated by us.
              <br />
              <br />
              If in our sole judgment you fail, or we suspect that you have
              failed, to comply with any term or provision of these Terms of
              Service, we also may terminate these Terms of Services and our
              relationship with you at any time without notice and you will
              remain liable for all amounts due up to and including the date of
              termination; and/or accordingly may deny you access to our Service
              (or any part thereof).
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 17 - ENTIRE AGREEMENT
              </b>
              <br />
              <br />
              The failure of us to exercise or enforce any right or provision of
              these Terms of Service shall not constitute a waiver of such right
              or provision.
              <br />
              <br />
              These Terms of Service and any policies or operating rules posted
              by us on this site or in respect to The Service constitutes the
              entire agreement and understanding between you and us and govern
              your use of the Service, superseding any prior or contemporaneous
              agreements, communications and proposals, whether oral or written,
              between you and us (including, but not limited to, any prior
              versions of the Terms of Service).
              <br />
              <br />
              Any ambiguities in the interpretation of these Terms of Service
              shall not be construed against the drafting party.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 18 - GOVERNING LAW; VENUE
              </b>
              <br />
              <br />
              These Terms of Service and any separate agreements whereby we
              provide you Services shall be governed by and construed in
              accordance with the laws of Tennessee, United States (exclusive of
              its choice of law provisions). The UN Convention on Contracts for
              the International Sale of Goods shall not apply to these Terms of
              Service. IN THE EVENT OF A DISPUTE HEREUNDER, IT IS AGREED THAT
              VENUE LIES EXCLUSIVELY IN THE COURTS OF KNOX COUNTY, TENNESSEE,
              AND SUCH COURTS SHALL BE A PROPER FORUM IN WHICH TO ADJUDICATE
              SUCH DISPUTE. THE PARTIES AGREE TO WAIVE ANY DEFENSE THAT THEY MAY
              HAVE BASED UPON IMPROPER VENUE OR LACK OF PERSONAL JURISDICTION,
              AND TO SUBJECT THEMSELVES TO THE JURISDICTION AND VENUE OF ANY
              SUCH AFORESAID COURT. Unless otherwise specifically provided
              herein, all costs and expenses, including attorney’s fees,
              incident to this Agreement and the transactions contemplated
              herein shall be paid by the party who incurred the same, whether
              the transactions contemplated by this Agreement are consummated or
              not. You agree to adhere to all applicable US Export laws and
              regulations with respect to the Services.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 19 - CHANGES TO TERMS OF SERVICE
              </b>
              <br />
              <br />
              You can review the most current version of the Terms of Service at
              any time at this page.
              <br />
              <br />
              We reserve the right, at our sole discretion, to update, change or
              replace any part of these Terms of Service by posting updates and
              changes to our website. It is your responsibility to check our
              website periodically for changes. Your continued use of or access
              to our website or the Service following the posting of any changes
              to these Terms of Service constitutes acceptance of those changes.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 20 - FORCE MAJEURE
              </b>
              <br />
              <br />
              We shall not be liable or responsible to you, nor be deemed to
              have defaulted under or breached these Terms of Service, for any
              failure or delay in fulfilling or performing any term of these
              Terms of Service, when and to the extent such failure or delay is
              caused by or results from acts beyond our reasonable control,
              including, without limitation: (a) acts of God; (b) flood, fire,
              earthquake, or explosion; (c) war, invasion, hostilities (whether
              war is declared or not), terrorist threats or acts, riot, or other
              civil unrest; (d) government order or law; (e) actions, embargoes,
              or blockades in effect on or after the date of these Terms of
              Service; (f) action by any governmental authority; (g) national or
              regional emergency; (h) strikes, labor stoppages or slowdowns, or
              other industrial disturbances; and (i) pandemic, epidemic, or
              public health emergency.
            </span>
          </p>
          <br />
          <hr style={{ borderTop: '1px solid #ddd' }} />
          <br />
          <p>
            <span>
              <b style={{ fontFamily: 'nexa-bold' }}>
                SECTION 21 - CONTACT INFORMATION
              </b>
              <br />
              <br />
              Questions about the Terms of Service should be sent to us at{' '}
              <a
                style={{ fontFamily: 'nexa-bold' }}
                href="mailto:legal@faredrop.com"
              >
                legal@faredrop.com
              </a>
            </span>
          </p>
        </div>
        <Footer bgColor="primary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default TermsOfService;
