import { Capacitor } from '@capacitor/core';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import ChangePassword from '../components/ChangePassword';
import DesktopHeader from '../components/DesktopHeader';
import Loading from '../components/Loading';
import Login from '../components/Login';
import MobileHeader from '../components/MobileHeader';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import './../theme/LoginPage.css';
import './../theme/util.css';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import SocialProofSection from '../components/SocialProofSection';
import { HIDE_MEMBERSHIP_LINKS } from '../utilities/constants';
import { AllowedPath } from '@faredrop/types';

enum LoginStep {
  Login = 'Login',
  ChangePassword = 'Change Password',
}

const LoginPage: React.FC = () => {
  const { isInitialized, isAuthenticated, logout } = useAuth();
  const [pageLoaded, setPageLoaded] = useState(false);
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const [step, setStep] = useState<LoginStep>(LoginStep.Login);
  const [showMarketingContent, setShowMarketingContent] = useState(false);
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const [requiredPasswordUser, setRequiredPasswordUser] = useState<
    CognitoUser | undefined
  >();
  const [email, setEmail] = useState<string>();
  const { smoothScroll } = useLandingPageNavigation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryEmail = queryParams.get('email');
    queryEmail && setEmail(decodeURI(queryEmail));

    const queryUtmId = queryParams.get('utm_id');
    if (queryUtmId?.includes('remarketing')) {
      setShowMarketingContent(true);
    }
  }, []);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    } else if (isInitialized) {
      setPageLoaded(true);

      // Clear session
      logout().catch((error) => {
        console.warn('Failed to logout', error);
        throw error; // Show uh-oh screen
      });
    }
  }, [isInitialized]);

  const stepComponent: JSX.Element =
    step === LoginStep.Login ? (
      <Login
        hideRegister={HIDE_MEMBERSHIP_LINKS}
        onRegisterLinkPress={async () => smoothScroll('#register')}
        onNewPasswordRequired={(user: CognitoUser) => {
          setStep(LoginStep.ChangePassword);
          setRequiredPasswordUser(user);
        }}
        email={email}
      />
    ) : (
      <ChangePassword
        onCancel={() => setStep(LoginStep.Login)}
        user={requiredPasswordUser}
      />
    );
  return (
    <IonPage>
      {(!pageLoaded && <Loading />) || (
        <>
          <IonRow
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 100,
              padding: '15px 40px',
            }}
          >
            {isLargeScreenSizeOrSmaller ? (
              <MobileHeader
                menuHidden={true}
                backToHomeListener={() => {
                  window.location.reload();
                }}
              />
            ) : (
              <DesktopHeader
                optionsHidden={true}
                showBackToHome={true}
                ctaHidden={true}
              />
            )}
          </IonRow>
          <IonContent className="login-page">
            <IonRow className="row-vertical-align" style={{ padding: '2em' }}>
              <IonCol sizeXs="12" sizeLg="4">
                <h1 className="title-font" style={{ textAlign: 'center' }}>
                  {step}
                </h1>
                {!Capacitor.isNativePlatform() && (
                  <h2>Welcome back to FareDrop!</h2>
                )}
                {stepComponent}
              </IonCol>
            </IonRow>

            {showMarketingContent && <SocialProofSection />}
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default LoginPage;
