import { Deal } from '@faredrop/graphql-sdk';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { lockClosedOutline } from 'ionicons/icons';
import {
  forwardRef,
  Fragment,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAnalytics from '../hooks/analytics';
import useAppState from '../hooks/useAppState';
import { useDevice } from '../hooks/useDevice';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useVisibility from '../hooks/visibility';
import { isMatch } from '../utilities/deals-utilities';
import CarouselArrows from './CarouselArrows';
import { DealCard, IDestinationDeals } from './DealCard';
import NoDealsText from './NoDealsText';
import moment from 'moment';
import useAirports from '../hooks/airports';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { IPromotion, PromotionCard } from './PromotionCard';
import useKlaviyo from '../hooks/useKlaviyo';
import { CARD_WIDTH, HIDE_MEMBERSHIP_LINKS } from '../utilities/constants';
import { DealsDashboardList } from '../contexts/dealsDashboardContext';

interface ContainerProps {
  cardOpacity?: number;
  children?: ReactNode;
  deals: IDestinationDeals[];
  hidePlaceholderWhenEmpty?: boolean;
  lockDeals?: boolean;
  onSlideTransition?: (index: number) => void;
  onRefresh?: () => void;
  onSelect: (
    destinationDeals: IDestinationDeals,
    index: number,
    activeIndex: number,
    isDealLocked: boolean
  ) => Promise<void>;
  onVisible?: (
    destinationDeals: IDestinationDeals,
    index: number,
    activeIndex: number
  ) => Promise<void>;
  onChange?: (
    destinationDeals: IDestinationDeals,
    index: number,
    activeIndex: number
  ) => Promise<void>;
  showButton?: boolean;
  subtitle?: () => JSX.Element;
  sx?: React.CSSProperties;
  title?: string;
  viewLoaded: boolean;
  emptyComponent?: ReactNode;
  promotions?: IPromotion[];
  type?: DealsDashboardList;
  slidesPerView?: number;
  swiperStyle?: React.CSSProperties;
  swiperContainerStyle?: React.CSSProperties;
  arrowsStyle?: React.CSSProperties;
}

export type DealsSectionHandle = {
  setIndex: (index: number) => void;
};

export type PromotionCardRender = {
  data: IPromotion;
  element: HTMLDivElement;
};

const DealsSection = forwardRef<DealsSectionHandle, ContainerProps>(
  (
    {
      cardOpacity,
      deals,
      hidePlaceholderWhenEmpty,
      lockDeals,
      onSlideTransition,
      onRefresh,
      onSelect,
      onVisible,
      onChange,
      showButton,
      subtitle,
      sx,
      title,
      viewLoaded,
      emptyComponent,
      promotions,
      type,
      slidesPerView,
      swiperStyle,
      swiperContainerStyle,
      arrowsStyle,
    },
    ref
  ) => {
    const {
      isExtraSmallScreenSize,
      isMediumScreenSizeOrSmaller,
      isSmallScreenSizeOrSmaller,
    } = useDevice();
    const slidesRef = useRef<SwiperRef>(null); // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572#issuecomment-498242139
    const slidesContainerRef = useRef<HTMLIonRowElement>(null);
    const isRefVisible = useVisibility(slidesContainerRef);
    const { width } = useWindowDimensions();
    const sliderKeyRef = useRef('');
    const previousSliderKey = useRef(`${new Date()}`);
    const previousFirstDeal = useRef<Deal>();
    const promotionsCardsRef = useRef<PromotionCardRender[]>([]);
    const { activeTrigger } = useAppState();
    const { logAnalyticsUpgradeSubscription } = useAnalytics();
    const { getAirport } = useAirports();
    const { goWithStickyParamsPath } = useHistoryWithStickyParams();
    const { klaviyoPromotionForms, addKlaviyoPromotionForms } = useKlaviyo();
    const [allowSlideNext, setAllowSlideNext] = useState(true);

    // Expose component methods
    useImperativeHandle(ref, () => ({
      setIndex: (index: number) => {
        slidesRef.current?.swiper.slideTo(index, 1);
      },
    }));

    // If deals are refreshed, make sure to call onVisible if the section is visible
    useEffect(() => {
      const firstDeals = deals && deals.length > 0 ? deals[0] : undefined;
      if (previousFirstDeal.current && firstDeals) {
        const isActiveDealMatch = isMatch(
          firstDeals.activeDeal,
          previousFirstDeal.current
        );
        const dealsIndex = firstDeals.deals.findIndex(
          (deal) =>
            previousFirstDeal.current &&
            isMatch(deal, previousFirstDeal.current)
        );
        if (isActiveDealMatch || dealsIndex > -1) {
          callOnVisible().catch((error) => {
            console.warn(
              'Deals Section on visible handler failed after deals change'
            );
            throw error; // Show the uh-oh screen
          });
        }
      }
      previousFirstDeal.current = firstDeals
        ? firstDeals.activeDeal
        : undefined;
    }, [deals]);

    useEffect(() => {
      callOnVisible().catch((error) => {
        console.warn('Deals Section on visible handler failed');
        throw error; // Show the uh-oh screen
      });
    }, [isRefVisible, activeTrigger]);

    const callOnVisible = async () => {
      if (isRefVisible && onVisible && slidesRef.current) {
        const index = slidesRef.current?.swiper.activeIndex;
        if (index > -1 && index < deals.length) {
          await onVisible(
            deals[index],
            index,
            slidesRef.current.swiper.activeIndex
          );
        }
      }
    };

    const previousSlide = async (slidesRef: React.RefObject<SwiperRef>) => {
      if (slidesRef.current) {
        slidesRef.current?.swiper.slidePrev();
      }
    };

    const nextSlide = async (slidesRef: React.RefObject<SwiperRef>) => {
      if (slidesRef.current) {
        slidesRef.current?.swiper.slideNext();
      }
    };

    const buildKey = (destinationDeals: IDestinationDeals) => {
      const keyDeal = destinationDeals.keyDeal;
      let key = `${keyDeal.seatClass}#${keyDeal.originIATA}`;
      if (keyDeal.destinationIATA) {
        key += `#${keyDeal.destinationIATA}`;
      } else {
        key += `#${moment(keyDeal.created).valueOf()}`;
      }
      if (destinationDeals.deals.length === 0)
        key = `${keyDeal.idAirfareSource}#${key}`;
      return key;
    };

    const promotionsCards = () =>
      promotions
        ? promotions.map((promotion, i) => (
            <Fragment key={`promotion-slide-${promotion.type}-${i}`}>
              <PromotionCard
                reference={(el) =>
                  el
                    ? (promotionsCardsRef.current[i] = {
                        data: promotion,
                        element: el,
                      })
                    : null
                }
                promotion={promotion}
              />
            </Fragment>
          ))
        : null;

    const promotionSlide = (element: string, key: string) => (
      // Using SwiperSlide component for swiper behavior and style control. Remove/Change the component or its classes could affect the deals ads expected behavior.
      <SwiperSlide key={key}>
        <PromotionCard
          // element code comes from klaviyo
          form={<div dangerouslySetInnerHTML={{ __html: element }} />}
        />
      </SwiperSlide>
    );

    useEffect(() => {
      promotionsCardsRef.current.forEach((card, i) => {
        if (type && card.data.ref)
          addKlaviyoPromotionForms(type, {
            id: `${card.data.ref}-${i}`,
            el: card.element.innerHTML,
          });
      });
    }, [promotionsCardsRef, klaviyoPromotionForms]);

    const { slides, sliderKey } = useMemo((): {
      slides: JSX.Element[] | null;
      sliderKey: string;
    } => {
      const dealsKeys: string[] = [];

      const dealsSlides: JSX.Element[] = deals.map(
        (destinationDeals: IDestinationDeals, i: number) => {
          const dealKey = buildKey(destinationDeals);
          dealsKeys.push(dealKey);
          return (
            <SwiperSlide key={dealKey}>
              <DealCard
                originAirport={getAirport(
                  destinationDeals.activeDeal.originIATA
                )}
                destinationAirport={
                  destinationDeals.activeDeal.destinationIATA
                    ? getAirport(destinationDeals.activeDeal.destinationIATA)
                    : undefined
                }
                destinationDeals={destinationDeals}
                locked={lockDeals}
                openModalListener={async (e, isDealLocked) => {
                  e.stopPropagation();
                  onSelect &&
                    (await onSelect(
                      destinationDeals,
                      i,
                      slidesRef.current?.swiper.activeIndex ?? 0,
                      isDealLocked
                    ));
                }}
                sx={{
                  margin: isMediumScreenSizeOrSmaller ? '1em 0' : '1em',
                  opacity: cardOpacity ?? 1,
                  filter: cardOpacity ? 'sepia(.3)' : 'none',
                }}
              />
            </SwiperSlide>
          );
        }
      );

      // TODO: It is recommended to use swiper.appendSlide, prependSlide, removeSlide, or update instead of changing slides after initial render
      // We could either wait until promotions are loaded to draw the slides, or use above methods
      // Changing the order will cause the swiper to "reset" causing unwanted behavior
      // To make sure this is working properly, setIndex in dashboard on dashboard mount. Right now, the active index is reset, when it should be "sticky"
      if (type && klaviyoPromotionForms[type]?.length) {
        const adFreq = dealsSlides.length / klaviyoPromotionForms[type].length;
        const slides = [...dealsSlides];
        klaviyoPromotionForms[type].forEach((promotion, i) => {
          const key = `promotion-slide-${promotion.id}`;
          slides.splice(
            i == 0 ? 1 : i * adFreq,
            0,
            promotionSlide(promotion.el, key)
          );
          dealsKeys.splice(i == 0 ? 1 : i * adFreq, 0, key);
        });

        return { slides, sliderKey: dealsKeys.join('_') };
      } else {
        return {
          slides: dealsSlides,
          sliderKey: dealsKeys.join('_'),
        };
      }
    }, [deals, promotions, previousSliderKey]);

    // NOTE: This is probably not perfect!
    // Don't update key if current key is exact first subset of new deals (pagination)
    // TODO: This probably needs a second look. This is for past deals, but there is added complexity now with stacked deals
    sliderKeyRef.current = sliderKey;
    if (sliderKeyRef.current.indexOf(previousSliderKey.current) === 0) {
      // Pagination scenario, don't update key and cause a slider rerender
      sliderKeyRef.current = previousSliderKey.current;
    } else {
      previousSliderKey.current = sliderKeyRef.current;
    }
    if (!emptyComponent) {
      emptyComponent = <NoDealsText />;
    }

    return (
      <div id={`deals-section-${type}`} style={{ width: '100%' }}>
        {viewLoaded && (
          <IonRow
            style={{
              marginBottom:
                deals.length == 0 || isSmallScreenSizeOrSmaller ? 0 : '6em',
              marginTop: isSmallScreenSizeOrSmaller ? '4em' : 0,
              ...sx,
            }}
          >
            <IonCol>
              <div style={{ display: 'none' }}>{promotionsCards()}</div>
              <IonRow
                className="row-vertical-align"
                style={{ justifyContent: 'left', height: 'auto' }}
              >
                {subtitle && (
                  <IonCol>
                    <IonRow
                      className="row-vertical-align"
                      style={{
                        justifyContent: !isMediumScreenSizeOrSmaller
                          ? 'left'
                          : 'center',
                        display: 'flex',
                        margin: `0 ${isExtraSmallScreenSize ? '4%' : '8%'}`,
                      }}
                    >
                      <h2
                        style={{
                          marginBottom: 0,
                          textAlign: 'left',
                          width: isMediumScreenSizeOrSmaller ? '100%' : 'auto',
                          fontSize: isMediumScreenSizeOrSmaller ? 20 : 24,
                        }}
                      >
                        {title}
                      </h2>
                      {showButton && !HIDE_MEMBERSHIP_LINKS && (
                        <IonButton
                          style={{
                            height: '4em',
                            width: '15em',
                            textTransform: 'none',
                            marginLeft: 'auto',
                            marginRight: isMediumScreenSizeOrSmaller
                              ? 'auto'
                              : 0,
                            marginTop: isMediumScreenSizeOrSmaller
                              ? '1.5em'
                              : 0,
                            marginBottom: isMediumScreenSizeOrSmaller
                              ? '1.5em'
                              : 0,
                          }}
                          className="dim-on-hover"
                          onClick={async () => {
                            await logAnalyticsUpgradeSubscription(
                              'deals_dashboard'
                            );
                            goWithStickyParamsPath('/plans');
                          }}
                        >
                          <>
                            <IonIcon
                              icon={lockClosedOutline}
                              style={{
                                marginRight: '10px',
                                marginTop: '-2px',
                              }}
                            />
                            <p
                              style={{
                                fontFamily: 'nexa-bold',
                                margin: 'auto',
                                color: 'white',
                              }}
                              className="hover-illum" // cspell:disable-line
                            >
                              Upgrade Plan
                            </p>
                          </>
                        </IonButton>
                      )}
                    </IonRow>
                    {subtitle && (
                      <IonRow
                        className="row-vertical-align"
                        style={{ height: 'auto', justifyContent: 'left' }}
                      >
                        <h4
                          style={{
                            textAlign: 'left',
                            margin: isMediumScreenSizeOrSmaller
                              ? `1em ${isExtraSmallScreenSize ? '4%' : '8%'} 0`
                              : '.5em 8% 2em',
                            marginBottom: 0,
                            fontFamily: 'nexa',
                            fontSize: isMediumScreenSizeOrSmaller ? 16 : 20,
                            width: isMediumScreenSizeOrSmaller
                              ? '100%'
                              : 'auto',
                          }}
                        >
                          {subtitle()}
                        </h4>
                        {!showButton && (
                          <CarouselArrows
                            onRefresh={onRefresh}
                            nextSlide={nextSlide}
                            previousSlide={previousSlide}
                            slidesRef={slidesRef}
                            hideArrows={deals.length === 0}
                            style={arrowsStyle}
                          />
                        )}
                      </IonRow>
                    )}
                  </IonCol>
                )}
                {!subtitle && (
                  <h4
                    style={{
                      marginLeft: isExtraSmallScreenSize ? '4%' : '8%',
                      fontSize: isMediumScreenSizeOrSmaller ? 20 : 24,
                    }}
                  >
                    {title}
                  </h4>
                )}
                {!subtitle && (
                  <CarouselArrows
                    onRefresh={onRefresh}
                    nextSlide={nextSlide}
                    previousSlide={previousSlide}
                    slidesRef={slidesRef}
                    hideArrows={deals.length === 0}
                    style={arrowsStyle}
                  />
                )}
              </IonRow>
              {deals.length > 0 ? (
                <IonRow
                  className="row-vertical-align"
                  style={{
                    justifyContent: 'center',
                    height: 'initial',
                    paddingTop: '2em',
                    ...swiperContainerStyle,
                  }}
                  ref={slidesContainerRef}
                >
                  {
                    // Wait until view is loaded (viewLoaded) to display any slides...
                    viewLoaded && (
                      // NOTE: We need to be sure to set the key on our IonSlides which prevents a known
                      // issue where refreshing the content under the hood causes React to look for a Node
                      // that it can't find...
                      // https://github.com/ionic-team/ionic-framework/issues/18782#issuecomment-558075082
                      <Swiper
                        key={sliderKeyRef.current}
                        ref={slidesRef}
                        className={
                          isMediumScreenSizeOrSmaller
                            ? 'deals-carousel w-100'
                            : 'deals-carousel'
                        }
                        spaceBetween={10}
                        slidesPerView={
                          isExtraSmallScreenSize
                            ? 1.125
                            : slidesPerView ?? (width * 0.84) / CARD_WIDTH
                        }
                        style={{
                          overflowX: 'scroll',
                          margin: 0,
                          minWidth: '100%',
                          padding: isExtraSmallScreenSize
                            ? '0 0 0 10%'
                            : '0 8%',
                          ...swiperStyle,
                        }}
                        freeMode={true}
                        onSlideChangeTransitionStart={async () => {
                          if (onSlideTransition && slidesRef.current) {
                            const index = slidesRef.current?.swiper.activeIndex;
                            onSlideTransition(index);
                          }
                        }}
                        onSlideChange={async () => {
                          if (onChange && slidesRef.current) {
                            const index = slidesRef.current?.swiper.activeIndex;
                            if (index > -1 && index < deals.length) {
                              await onChange(
                                deals[index],
                                index,
                                slidesRef.current.swiper.activeIndex
                              );
                            }
                          }
                        }}
                        onActiveIndexChange={({ activeIndex, slides }) => {
                          if (slides.length) {
                            const isNextLastSlide =
                              slides.length > 1
                                ? slides.length - 1 <= activeIndex
                                : true;
                            setAllowSlideNext(!isNextLastSlide);
                          }
                        }}
                        allowSlideNext={allowSlideNext}
                      >
                        {slides}
                      </Swiper>
                    )
                  }
                </IonRow>
              ) : (
                (hidePlaceholderWhenEmpty && (
                  <div style={{ height: '4em' }} />
                )) ||
                emptyComponent
              )}
            </IonCol>
          </IonRow>
        )}
      </div>
    );
  }
);

export default DealsSection;
