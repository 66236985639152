import useSWRImmutable from 'swr/immutable';

import { FareDropPlan } from '@faredrop/types';
import { stripePlanIDByFareDropPlan } from '@faredrop/utilities';

import useFareDropPublicApiClient from './faredropPublicApiClient';
import useAuth from './auth';
import useUser from './user';

const useOtherDepartureAirportDeals = (search?: string) => {
  const { isInitialized, isAuthenticated } = useAuth();
  const userState = useUser();
  const { client } = useFareDropPublicApiClient();

  const otherDepartureAirportDealsKey = (search?: string) => {
    // Pro users will use deals from userDeals instead
    if (
      !search ||
      !isInitialized ||
      (isAuthenticated && userState.isInitializing) ||
      userState.user?.billing.idStripePlan ===
        stripePlanIDByFareDropPlan(FareDropPlan.PRO)
    ) {
      return null;
    }

    const key = [
      'OTHER_DEPARTURE_AIRPORT_DEALS',
      process.env.REACT_APP_VERSION ?? '',
      search,
    ];

    return key;
  };

  const requestKey = otherDepartureAirportDealsKey(search);

  const { data, error, isValidating } = useSWRImmutable(
    requestKey,
    async (_scopedPrefix, _scopedVersion, scopedSearch) => {
      return (
        await client.otherDepartureAirportDeals({
          search: scopedSearch,
        })
      ).data.otherDepartureAirportDeals;
    }
  );

  return {
    otherDepartureAirportDeals: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
  };
};

export default useOtherDepartureAirportDeals;
