import { IonCol, IonRow } from '@ionic/react';

import { TravelPortal } from '@faredrop/graphql-sdk';

import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  travelPortal: TravelPortal;
  price: number;
  pointsProgramIndex?: number;
  fontSize?: string;
  onClick?: () => void;
}

const PointEstimatePill: React.FC<ContainerProps> = ({
  travelPortal,
  price,
  pointsProgramIndex,
  fontSize,
  onClick,
}) => {
  const { isSmallScreenSizeOrSmaller, isExtraSmallScreenSize } = useDevice();

  const pointsProgram =
    pointsProgramIndex != null
      ? travelPortal.pointsPrograms[pointsProgramIndex]
      : travelPortal.pointsPrograms[0];

  const getPointText = () => {
    let pointsValue =
      Math.ceil(price / pointsProgram.dollarsPerPoint) +
      (travelPortal.pointBuffer ?? 0);
    let pointsString = pointsValue.toLocaleString('en-US');

    if (isSmallScreenSizeOrSmaller) {
      pointsValue = Math.ceil(pointsValue / 1000);
      pointsString = `${pointsValue}k`;
    } else {
      pointsString = pointsValue.toLocaleString('en-US');
    }

    return `${pointsString} ${
      travelPortal.currencyLabelShort ?? travelPortal.currencyLabel
    }`;
  };

  return (
    <IonRow
      key={travelPortal.creditCardIssuer}
      style={{
        height: isExtraSmallScreenSize ? '24px' : '30px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        backgroundColor: 'rgb(248, 246, 243)',
        paddingRight: '10px',
        cursor: onClick ? 'pointer' : undefined,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <IonCol
        size="auto"
        style={{
          paddingTop: isExtraSmallScreenSize ? 0 : undefined,
          paddingBottom: isExtraSmallScreenSize ? 0 : undefined,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={travelPortal.logo}
          style={{
            height: isExtraSmallScreenSize ? '18px' : '22px',
            width: isExtraSmallScreenSize ? '18px' : '22px',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
        />
      </IonCol>
      <IonCol
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'var(--ion-color-primary)',
          fontSize: fontSize
            ? fontSize
            : isSmallScreenSizeOrSmaller
            ? isExtraSmallScreenSize
              ? '12px'
              : '14px'
            : '16px',
          padding: 0,
          paddingBottom: !isExtraSmallScreenSize ? '1px' : undefined,
          paddingTop: isExtraSmallScreenSize ? '6px' : undefined,
        }}
        size="auto"
      >
        {getPointText()}
      </IonCol>
    </IonRow>
  );
};

export default PointEstimatePill;
