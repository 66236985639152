import { useState } from 'react';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';

import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/util.css';
import './../theme/RedeemWithCard.css';
import useAnalytics from '../hooks/analytics';
import Loading from '../components/Loading';
import useStripeHook from '../hooks/useStripe';
import { SubscriptionPlanCard } from '../components/SubscriptionPlanCard';
import { FareDropPlan as FareDropPlanGQL } from '@faredrop/graphql-sdk';
import { buildFrontendSubscriptionPlans } from '../utilities/plans-utilities';
import GetStarted, {
  UserAlreadyExistsBehavior,
} from '../components/GetStarted';
import HeroSection from '../components/HeroSection';
import HowItWorksSection from '../components/HowItWorksSection';
import SocialProofSection from '../components/SocialProofSection';
import { GetStartedBlock } from '../components/GetStartedBlock';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import { useHidingHeader } from '../hooks/useHidingHeader';
import Login from '../components/Login';
import { isAuthorized, stripePlanIDByFareDropPlan } from '@faredrop/utilities';
import { AllowedPath, FareDropPlan, FareDropRole } from '@faredrop/types';
import { $enum } from 'ts-enum-util';
import AsSeenIn from '../components/AsSeenIn';

interface ContainerProps {
  plan: FareDropPlanGQL;
  onMenuClickHandler: () => void;
}

enum RedeemWithCardStep {
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN',
  LOADING = 'LOADING',
}

const RedeemWithCard: React.FC<ContainerProps> = ({
  plan,
  onMenuClickHandler,
}) => {
  const { isLargeScreenSizeOrSmaller } = useDevice();
  const { logAnalyticsOnboardingEngagement } = useAnalytics();
  const userState = useUser();
  const noInternet = userState.timeout;
  const { createCheckoutSession, createUpgradeCheckoutSession } =
    useStripeHook();
  const plans = buildFrontendSubscriptionPlans();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const dynamicBannerContent = useDynamicBanner();

  const [step, setStep] = useState(RedeemWithCardStep.REGISTER);

  const redeemNewSubscription = async (code?: string) => {
    await createCheckoutSession(
      plan,
      AllowedPath.GET_STARTED_WELCOME_PAID.slice(1),
      [
        { key: 'refreshAccount', value: 'true' },
        {
          key: 'newPlan',
          value: stripePlanIDByFareDropPlan(
            $enum(FareDropPlan).asValueOrThrow(plan)
          ),
        },
      ],
      'offer/redeem',
      undefined,
      code
    );
  };

  let stepComponent = (
    <IonRow
      style={{ display: 'flex', justifyContent: 'center', textAlign: 'left' }}
    >
      <h4>Account Details</h4>
      <GetStarted
        onSubmit={async (_email, _firstName, _lastName, _idCognito, code) => {
          await redeemNewSubscription(code);
        }}
        submitButtonText="Next"
        acceptPromotionCode={true}
        onLoginLinkPress={async () => {
          setStep(RedeemWithCardStep.LOGIN);
        }}
        userAlreadyExistsBehavior={UserAlreadyExistsBehavior.SHOW_ERROR}
      />
    </IonRow>
  );

  if (step === RedeemWithCardStep.LOGIN) {
    stepComponent = (
      <IonRow
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <h2>Login to Redeem</h2>
        <Login
          onRegisterLinkPress={async () => setStep(RedeemWithCardStep.REGISTER)}
          overrideLogin={async (loggedInUser, _email, code) => {
            if (isAuthorized(loggedInUser, [FareDropRole.Limited])) {
              await createUpgradeCheckoutSession(
                plan,
                'deals',
                [
                  { key: 'refreshAccount', value: 'true' },
                  {
                    key: 'newPlan',
                    value: stripePlanIDByFareDropPlan(
                      $enum(FareDropPlan).asValueOrThrow(plan)
                    ),
                  },
                ],
                'offer/redeem',
                undefined,
                code
              );
            } else {
              // If no roles, useCheckout session
              await redeemNewSubscription(code);
            }
          }}
          submitButtonText="Next"
          acceptPromotionCode={true}
        />
      </IonRow>
    );
  } else if (step === RedeemWithCardStep.LOADING) {
    stepComponent = <Loading />;
  }

  return noInternet ? (
    <NoInternetConnectionModal />
  ) : (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            ctaHidden={true}
            logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
          />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <div style={{ paddingTop: '3em' }}>
          <HeroSection
            scrollTarget={'#offer-redeem'}
            callToAction="Redeem Subscription"
          />
          <HowItWorksSection />
          <AsSeenIn />
          <IonRow
            id="offer-redeem"
            className="redeem-with-card-section redeem-with-card-section-responsive"
          >
            <IonCol
              size="12"
              sizeXl="6"
              className="offer-container"
              style={{ flexDirection: 'column' }}
            >
              <IonRow className="row-vertical-align" style={{ width: '100%' }}>
                {plans?.map((plan) => {
                  if (plan.planType !== FareDropPlanGQL.Pro) return undefined;
                  return (
                    <SubscriptionPlanCard
                      key={plan.id}
                      plan={plan}
                      billingText="For the first year"
                      billingSubText="Renews at full-price"
                      discountPrice="FREE"
                      big={true}
                      border="none"
                      hideButton={true}
                    />
                  );
                })}
              </IonRow>
            </IonCol>
            <IonCol
              size="12"
              sizeXl="6"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  maxWidth: '600px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                {stepComponent}
              </div>
            </IonCol>
          </IonRow>
          <SocialProofSection />
          <GetStartedBlock
            callToAction="Redeem Subscription"
            scrollTarget="#offer-redeem"
          />
        </div>
        <Footer bgColor="secondary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default RedeemWithCard;
