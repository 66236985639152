import { useSWRConfig } from 'swr';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from 'swr/infinite';

import usePresentToast from './presentToast';
import { BareFetcher } from 'swr/dist/types';

const TIMEOUT_PREFIX = 'SWR_TIMEOUT';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useSWRInfiniteTimeout<Data = any, SWRError = any>(
  timeoutKey: string,
  getKey: SWRInfiniteKeyLoader,
  fetcher: BareFetcher<Data>,
  config:
    | SWRInfiniteConfiguration<Data, SWRError, BareFetcher<Data>>
    | undefined,
  friendlyErrorMessage: string | undefined
) {
  const { cache } = useSWRConfig();
  const { presentError } = usePresentToast();

  const { data, error, mutate, isValidating, size, setSize } = useSWRInfinite(
    (...loaderArgs) => getKey(loaderArgs[0], loaderArgs[1]),
    async (...scopedArgs) => {
      try {
        const result = await fetcher(...scopedArgs);
        cache.set(`${TIMEOUT_PREFIX}#${timeoutKey}`, false);
        return result;
      } catch (error) {
        const errors: Error[] = (
          Array.isArray(error) ? error : [error]
        ) as Error[];
        if (errors.some((e) => (e as Error).name === 'AbortError')) {
          cache.set(`${TIMEOUT_PREFIX}#${timeoutKey}`, true);
        } else if (friendlyErrorMessage) {
          console.error(friendlyErrorMessage, error);
          presentError(friendlyErrorMessage);
        }
        throw errors;
      }
    },
    {
      ...config,
      errorRetryCount: 3,
    }
  );

  return {
    data,
    error,
    mutate,
    isValidating,
    timeout: cache.get(`${TIMEOUT_PREFIX}#${timeoutKey}`) ?? false,
    size,
    setSize,
  };
}

export default useSWRInfiniteTimeout;
