import { IonCol, IonRow, IonSkeletonText, IonIcon } from '@ionic/react';
import { Tooltip } from '@mui/material';
import StarIcon from '@material-ui/icons/Star';
import { airplane } from 'ionicons/icons';
import _ from 'lodash';

import { Deal, SeatClass } from '@faredrop/graphql-sdk';
import { CountryCode } from '@faredrop/types';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import useUser from '../../hooks/user';
import useAirports from '../../hooks/airports';
import FlightBadges from '../FlightBadges';
import { BadgeLabel, formatAirportCity } from '../../utilities/deals-utilities';
import { useDevice } from '../../hooks/useDevice';

interface ContainerProps {
  originIATA: string;
  destinationIATA: string;
  seatClass: SeatClass;
  deal?: Deal;
  onShare: () => Promise<void>;
}

const DealDetailsHero: React.FC<ContainerProps> = ({
  originIATA,
  destinationIATA,
  seatClass,
  deal,
  onShare,
}) => {
  const { getAirportOrSubAirport } = useAirports();
  const {
    isExtraLargeScreenSize,
    isLargeScreenSize,
    isMediumScreenSize,
    isSmallScreenSizeOrSmaller,
    isExtraSmallScreenSize,
    isScreenLargerThanSize,
  } = useDevice();

  const userState = useUser();
  const homeOriginIATA = userState.user?.configuration.homeOriginIATA;

  const originAirport = getAirportOrSubAirport(originIATA);
  const destinationAirport = getAirportOrSubAirport(destinationIATA);

  const useSmallText = isLargeScreenSize || isMediumScreenSize;

  const getSizedHeroImage = () => {
    const base = `${getAssetsBaseUrl()}/iatas`;
    if (isExtraLargeScreenSize) {
      return `${base}/1000/350`;
    } else if (isLargeScreenSize) {
      return `${base}/700/350`;
    } else if (isMediumScreenSize) {
      return `${base}/450/350`;
    } else if (isScreenLargerThanSize(450)) {
      return `${base}/700/350`;
    } else {
      return `${base}/450/350`;
    }
  };

  return (
    <IonRow style={{ display: 'flex', marginBottom: '2em', width: '100%' }}>
      {isSmallScreenSizeOrSmaller && (
        // NOTE: use -55px if we want the "Deal Details" header bar to be level
        <IonRow
          style={{
            marginBottom: '-2em',
            height: '300px',
            width: '100%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
            overflow: 'hidden',
            backgroundColor: 'rgb(248, 246, 243)',
          }}
        >
          <img src={`${getSizedHeroImage()}/${destinationIATA}.jpg`} />
        </IonRow>
      )}
      <IonRow
        style={{
          marginTop: isSmallScreenSizeOrSmaller ? '2em' : '.5em',
          marginBottom: '2em',
          flex: 1,
          display: 'flex',
          ...(isSmallScreenSizeOrSmaller
            ? {
                marginTop: 0,
                marginBottom: 0,
                marginLeft: '1.5em',
                marginRight: '1.5em',
              }
            : {}),
        }}
      >
        <IonCol
          size="12"
          sizeMd="5"
          sizeLg="4"
          className={!isSmallScreenSizeOrSmaller ? 'ticket-info' : undefined}
          style={{
            display: 'flex',
            backgroundColor: 'white',
            padding: '5px',
            flex: 1,
            borderRadius: '20px',
            position: 'relative',
            boxShadow: '0px 6px 12px 0px #0000001A',
          }}
        >
          <IonRow
            style={{
              backgroundColor: 'var(--ion-color-primary)',
              flex: 1,
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <IonRow
              style={{
                color: 'white',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'nexa-bold',
                letterSpacing: '.15em',
              }}
            >
              DEAL DETAILS
            </IonRow>
            <IonRow
              style={{
                backgroundColor: 'white',
                paddingTop: '1em',
                paddingLeft: '1em',
                paddingRight: '1em',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <IonRow
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1em',
                }}
              >
                <IonRow
                  style={{
                    fontFamily: 'nexa-bold',
                    fontSize: '12px',
                    letterSpacing: '.1em',
                    lineHeight: '20px',
                    color: 'var(--ion-color-primary)',
                  }}
                >
                  DESTINATION
                </IonRow>
                <IonRow
                  className="deal-details-destination-city"
                  style={{ fontFamily: 'new-spirit,serif', display: 'grid' }}
                >
                  <IonSkeletonText
                    animated={true}
                    className={
                      deal && destinationAirport ? 'fadeout' : undefined
                    }
                    style={{
                      width: '180px',
                      height: isExtraSmallScreenSize ? '24px' : '37px',
                      borderRadius: '10px',
                      gridColumn: 1,
                      gridRow: 1,
                    }}
                  ></IonSkeletonText>
                  {!!deal && !!destinationAirport && (
                    <div
                      style={{
                        animation: 'fadeIn .5s',
                        gridColumn: 1,
                        gridRow: 1,
                      }}
                    >{`${destinationAirport?.city}${
                      destinationAirport?.countryCode !== CountryCode.Us
                        ? `, ${destinationAirport?.country}`
                        : ''
                    }`}</div>
                  )}
                </IonRow>
              </IonRow>
              <IonRow
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1em',
                }}
              >
                <IonRow
                  style={{
                    fontFamily: 'nexa-bold',
                    fontSize: '12px',
                    letterSpacing: '.1em',
                    lineHeight: '20px',
                    color: 'var(--ion-color-primary)',
                  }}
                >
                  DEPARTING FROM
                </IonRow>
                <IonRow
                  className="deal-details-origin-city"
                  style={{ fontFamily: 'nexa-bold' }}
                >
                  {!deal || !originAirport ? (
                    <IonRow style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{ paddingLeft: '10px' }}
                      >{`${originIATA}`}</div>
                    </IonRow>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        lineHeight: '27px',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          alignSelf: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {originIATA === homeOriginIATA ? (
                          <Tooltip title="Home Airport">
                            <StarIcon
                              style={{ marginRight: '5px', fontSize: '22px' }}
                              className="gray"
                            />
                          </Tooltip>
                        ) : (
                          <IonIcon
                            className="airplane-icon gray"
                            icon={airplane}
                          />
                        )}
                      </div>
                      <div style={{ paddingTop: '3px' }}>
                        {formatAirportCity(originAirport)}
                      </div>
                    </div>
                  )}
                </IonRow>
              </IonRow>
              <IonRow
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1em',
                }}
              >
                <IonRow
                  style={{
                    fontFamily: 'nexa-bold',
                    fontSize: '12px',
                    letterSpacing: '.1em',
                    lineHeight: '20px',
                    color: 'var(--ion-color-primary)',
                  }}
                >
                  SEAT CLASS
                </IonRow>
                <IonRow
                  className="deal-details-seatclass"
                  style={{ fontFamily: 'nexa-bold' }}
                >
                  {_.startCase(seatClass.toLowerCase())}
                </IonRow>
              </IonRow>
              <IonRow
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1em',
                  marginBottom: '1em',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <FlightBadges
                  size={useSmallText ? 'small' : undefined}
                  fontSize={useSmallText ? '14px' : undefined}
                  height={useSmallText ? 26 : undefined}
                  deal={deal}
                  includeLabels={true}
                  containerStyle={{
                    position: 'initial',
                    flexWrap: 'wrap',
                  }}
                  badgeStyle={{
                    marginBottom: '.5em',
                  }}
                  backgroundColor="#F8F6F3"
                  color="var(--ion-color-primary)"
                  exclude={[BadgeLabel.Business]}
                />
              </IonRow>
              <IonRow
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '1em',
                }}
              >
                <IonRow
                  style={{
                    fontFamily: 'nexa-bold',
                    color: 'var(--ion-color-primary)',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    await onShare();
                  }}
                >
                  Share this deal
                </IonRow>
              </IonRow>
            </IonRow>
          </IonRow>
        </IonCol>
        {!isSmallScreenSizeOrSmaller && (
          <IonCol
            size="0"
            sizeMd="7"
            sizeLg="8"
            style={{
              display: 'flex',
              backgroundColor: 'white',
              padding: '5px',
              flex: 1,
              borderRadius: '20px',
              boxShadow: '0px 6px 12px 0px #0000001A',
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                backgroundImage: `url(${getSizedHeroImage()}/${destinationIATA}.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                borderRadius: '20px',
              }}
            ></div>
          </IonCol>
        )}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsHero;
