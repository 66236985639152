import { useEffect, useState } from 'react';
import { IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import _ from 'lodash';

import {
  FareDropPlan,
  FareDropRole,
  Regions,
  SeatClass,
  UserConfigDestinationRegion,
} from '@faredrop/graphql-sdk';

import DesktopHeader from '../components/DesktopHeader';
import Dropdown from '../components/Dropdown';
import Footer from '../components/Footer';
import HidingHeader from '../components/HidingHeader';
import Loading from '../components/Loading';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import RegionsMap from '../components/RegionsMap';
import SettingsBackground from '../components/SettingsBackground';
import SettingsSideMenu, { MENU_OPTIONS } from '../components/SettingsSideMenu';
import { Tab } from '../contexts/tabContext';
import { useHidingHeader } from '../hooks/useHidingHeader';
import useUser, { sortRegions } from '../hooks/user';
import useTabs from '../hooks/useTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PAGES } from '../types/types';
import DestinationPreferencesCard from '../components/DestinationPreferencesCard';
import './../theme/DestinationPreferencesSettings.css';
import './../theme/util.css';
import { hasSeatClassAccessGQL } from '../utilities/auth';
import { useDevice } from '../hooks/useDevice';
import { getPlanName, isLimitedPlan } from '../utilities/plans-utilities';
import LimitedUpgradeCTA from '../components/LimitedUpgradeCTA';
import UpgradeModal from '../components/UpgradeModal';
import {
  HIDE_MEMBERSHIP_LINKS,
  HIDE_UPGRADE_MESSAGE,
} from '../utilities/constants';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { AllowedPath } from '@faredrop/types';

const Settings: React.FC = () => {
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const { updateSelectedTab } = useTabs();
  const { width } = useWindowDimensions();
  const { isExtraLargeScreenSize, isApp } = useDevice();
  const [destinationRegion, setDestinationRegion] = useState<Regions>();

  const userState = useUser();
  const isInitializing = userState.isInitializing;
  const userProfile = userState.user?.profile;
  const noInternet = userState.timeout;

  const regions = userState.user?.configuration.destinationRegions ?? [];

  useEffect(() => {
    updateSelectedTab(Tab.SETTINGS).catch((error) => {
      console.warn('Failed to select tab', Tab.SETTINGS, error);
      throw error; // Show uh-oh screen
    });
  }, []);

  const updateRegionsListener = async (
    regionConfig: UserConfigDestinationRegion
  ) => {
    const unchangedRegions = regions.filter(
      (r) => r.region !== regionConfig.region
    );
    const updatedRegions = [...unchangedRegions, regionConfig].sort(
      sortRegions
    );
    await userState.setDestinationRegions(updatedRegions);
  };

  const promptForUpgrade = async (
    regionConfig: UserConfigDestinationRegion
  ) => {
    setDestinationRegion(regionConfig.region);
  };

  const hasGlobalAccess =
    userProfile?.roles.includes(FareDropRole.Global) ||
    isLimitedPlan(userState.user?.billing.idStripePlan);
  const availableRegions = regions.filter(
    (r) => hasGlobalAccess || r.region === Regions.Domestic
  );

  const destinationPreferenceText = hasGlobalAccess
    ? 'Choose which regions you want to receive deals for and the max price you want to pay.'
    : 'Choose the max price you want to pay.';

  const isLimited = isLimitedPlan(userState.user?.billing?.idStripePlan);

  return (
    (isInitializing && <Loading />) ||
    (noInternet && <NoInternetConnectionModal />) || (
      <IonPage>
        {isExtraLargeScreenSize || !userState.hasPaymentInfo ? (
          <HidingHeader
            hideDecimal={parseInt(hideDecimal.toString())}
            bannerClickListener={
              !userState.hasPaymentInfo
                ? () => {
                    goWithStickyParamsLocation({
                      pathname: AllowedPath.PLANS,
                    });
                  }
                : undefined
            }
            hideToolbar={!userState.hasPaymentInfo && !isExtraLargeScreenSize}
          >
            <DesktopHeader loggedIn={true} showLoadingDiv={true} />
          </HidingHeader>
        ) : (
          <div
            style={{
              height: '2em',
              backdropFilter: 'blur(1.5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}
        <IonContent
          fullscreen={true}
          scrollEvents={true}
          onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
          className="settings-page-content"
        >
          <SettingsBackground />
          <IonRow
            style={{
              marginBottom: '10em',
              padding: isExtraLargeScreenSize
                ? '5em 0em 2em 0em'
                : isApp
                ? '1em 0em 0em 0em'
                : 0,
            }}
          >
            <IonCol size="10" style={{ margin: 'auto', padding: '0px' }}>
              <IonRow>
                <h1 className="title-font" style={{ marginTop: '2em' }}>
                  {isExtraLargeScreenSize ? 'Deal Settings' : 'Settings'}
                </h1>
              </IonRow>
              <IonRow
                className={!isExtraLargeScreenSize ? 'row-vertical-align' : ''}
              >
                <SettingsSideMenu
                  activeOption={PAGES.DESTINATION_PREFERENCES}
                  isVisible={isExtraLargeScreenSize}
                />
                <Dropdown
                  options={MENU_OPTIONS[1].options.map(
                    (option) => option.label
                  )}
                  placeholder={PAGES.DESTINATION_PREFERENCES}
                  onOptionClick={(option: string) => {
                    goWithStickyParamsPath(option);
                  }}
                  hrefOptions={true}
                  hidden={isExtraLargeScreenSize}
                  width={isExtraLargeScreenSize ? width / 2 : '100%'}
                />
                <IonCol
                  sizeLg="12"
                  sizeXl={isExtraLargeScreenSize ? '6' : '12'}
                  style={{ marginLeft: !isExtraLargeScreenSize ? 0 : '5em' }}
                  className="region-preferences-section-container"
                >
                  <IonRow style={{ marginBottom: '1em' }}>
                    <h2 style={{ width: '100%' }}>Destination Preferences</h2>
                    <p>{destinationPreferenceText}</p>
                  </IonRow>
                  {hasGlobalAccess && (
                    <RegionsMap
                      regions={regions}
                      updateRegionsListener={
                        isLimited ? promptForUpgrade : updateRegionsListener
                      }
                    />
                  )}
                  <LimitedUpgradeCTA
                    isLimitedPlan={isLimited}
                    linkText="Upgrade"
                    message="to change Destination Preferences."
                    style={{ marginBottom: '2em' }}
                  />
                  {availableRegions.map(
                    (region: UserConfigDestinationRegion) => {
                      return (
                        <DestinationPreferencesCard
                          key={region.region}
                          region={region}
                          updateRegionsListener={
                            isLimited ? promptForUpgrade : updateRegionsListener
                          }
                          isMaxButtonVisible={isExtraLargeScreenSize}
                          showBusinessSlider={
                            hasSeatClassAccessGQL(
                              userProfile?.roles,
                              SeatClass.Business
                            ) ?? false
                          }
                          onSliderStart={
                            isLimited ? promptForUpgrade : undefined
                          }
                        />
                      );
                    }
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <Footer bgColor="primary" isVisible={isExtraLargeScreenSize} />
        </IonContent>
        <UpgradeModal
          message={
            destinationRegion
              ? `Updating destination preferences is only available on the ${getPlanName(
                  FareDropPlan.Pro
                )} Plan. ${
                  HIDE_MEMBERSHIP_LINKS
                    ? HIDE_UPGRADE_MESSAGE
                    : 'Please upgrade plans to update destination preferences.'
                }`
              : undefined
          }
          onClose={() => {
            setDestinationRegion(undefined);
          }}
        />
      </IonPage>
    )
  );
};

export default Settings;
