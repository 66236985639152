import { IonButton, IonCol, IonIcon, IonModal, IonRow } from '@ionic/react';
import { cardOutline, closeOutline, paperPlaneOutline } from 'ionicons/icons';

import { TravelPortal } from '@faredrop/graphql-sdk';

import './../theme/util.css';
import './../theme/PointEstimates.css';
import useAnalytics from '../hooks/analytics';
import PointEstimatePill from './PointEstimatePill';
import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  travelPortal?: TravelPortal;
  price?: number;
  onDismiss: () => void;
}

const PointsEstimateModal: React.FC<ContainerProps> = ({
  travelPortal,
  price,
  onDismiss,
}) => {
  const { isApp, isExtraSmallScreenSize } = useDevice();
  const {
    logAnalyticsTravelPortalClick,
    logAnalyticsMilesAndPointsGuideClick,
    logAnalyticsMilesAndPointsRedeemClick,
  } = useAnalytics();

  // Points Program in Travel Portals have the option to override the targetUrl. If so, use that instead of the travelPortal targetUrl
  const getUrl = (urlProp: 'targetUrl' | 'redeemUrl' | 'guideUrl') => {
    let url: string | undefined | null = undefined;
    if (travelPortal) {
      for (const pointsProgram of travelPortal?.pointsPrograms ?? []) {
        if (pointsProgram[urlProp]) {
          url = pointsProgram[urlProp];
          break;
        }
      }
      if (!url) {
        url = travelPortal[urlProp];
      }
    }
    return url;
  };

  return (
    <IonModal
      isOpen={!!travelPortal}
      onIonModalDidDismiss={onDismiss}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          right: 0,
          padding: isApp ? '35px' : '25px',
          paddingTop: isApp ? '50px' : isApp ? '35px' : '25px',
        }}
        onClick={onDismiss}
        className="pointer-on-hover dim-on-hover"
      >
        <IonIcon
          icon={closeOutline}
          style={{
            fontSize: '25px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <IonRow
          style={{
            paddingLeft: isExtraSmallScreenSize ? '2em' : '3em',
            paddingRight: isExtraSmallScreenSize ? '2em' : '3em',
            paddingTop: isApp ? '6em' : '4em',
            paddingBottom: '2em',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '800px',
          }}
        >
          <IonRow
            style={{
              fontSize: isExtraSmallScreenSize ? '22px' : '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              textAlign: 'center',
              lineHeight: '1.25em',
              marginBottom: '1em',
            }}
          >
            Use your {travelPortal?.currencyLabel} to Book a Flight
          </IonRow>
          <IonRow
            style={{
              fontSize: isExtraSmallScreenSize ? '16px' : '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              marginBottom: '1.5em',
              lineHeight: '1.25em',
            }}
          >
            Booking flights through travel portals requires a few extra steps,
            but don’t worry because we&apos;ve got your back!
          </IonRow>
          {travelPortal && price && (
            <IonRow
              style={{
                display: 'flex',
                width: '90%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2em',
                flexDirection: 'column',
              }}
            >
              {travelPortal.pointsPrograms.length === 1 && (
                <PointEstimatePill
                  travelPortal={travelPortal}
                  price={price}
                  fontSize="16px"
                />
              )}
              {travelPortal.pointsPrograms.length > 1 && (
                <>
                  <IonRow style={{ width: '100%' }}>
                    <IonCol size="7" />
                    <IonCol
                      size="5"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        textAlign: isExtraSmallScreenSize ? 'right' : undefined,
                        fontSize: isExtraSmallScreenSize ? '14px' : undefined,
                      }}
                    >
                      Estimated {travelPortal.currencyLabelShort}
                    </IonCol>
                  </IonRow>
                  {travelPortal.pointsPrograms.map((pointsProgram, index) => {
                    const darkest = 0.25;
                    const lightest = 0.05;
                    const diff = darkest - lightest;
                    const step =
                      diff / (travelPortal.pointsPrograms.length - 1);
                    const alpha =
                      step * (travelPortal.pointsPrograms.length - index) -
                      lightest;
                    return (
                      <IonRow
                        key={pointsProgram.pointsProgram}
                        style={{
                          width: '100%',
                          backgroundColor: `rgba(44, 98, 92, ${alpha})`,
                          borderTopRightRadius: index === 0 ? '6px' : undefined,
                          borderTopLeftRadius: index === 0 ? '6px' : undefined,
                          borderBottomRightRadius:
                            index === travelPortal.pointsPrograms.length - 1
                              ? '6px'
                              : undefined,
                          borderBottomLeftRadius:
                            index === travelPortal.pointsPrograms.length - 1
                              ? '6px'
                              : undefined,
                          fontSize: isExtraSmallScreenSize ? '14px' : undefined,
                          padding: '2px',
                        }}
                      >
                        <IonCol size="7" style={{ alignItems: 'center' }}>
                          {pointsProgram.descriptor}
                        </IonCol>
                        <IonCol
                          size="5"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {Math.ceil(
                            price / pointsProgram.dollarsPerPoint
                          ).toLocaleString('en-US')}
                        </IonCol>
                      </IonRow>
                    );
                  })}
                </>
              )}
            </IonRow>
          )}
          <IonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginBottom: '1em',
            }}
          >
            <IonButton
              onClick={async () => {
                const targetUrl = getUrl('targetUrl');
                if (targetUrl) {
                  window.open(targetUrl, '_blank');
                }
                if (travelPortal) {
                  await logAnalyticsTravelPortalClick(
                    travelPortal.creditCardIssuer
                  );
                }
              }}
            >
              {!isExtraSmallScreenSize ? `I've got this! ` : ''}Take me to{' '}
              {travelPortal?.portalName}
            </IonButton>
          </IonRow>
          <IonRow>
            <IonCol
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                const redeemUrl = getUrl('redeemUrl');
                if (redeemUrl) {
                  window.open(redeemUrl, '_blank');
                }
                if (travelPortal) {
                  await logAnalyticsMilesAndPointsRedeemClick(
                    travelPortal.creditCardIssuer
                  );
                }
              }}
              sizeXs="12"
              sizeSm="6"
            >
              <IonRow style={{ paddingRight: '5px' }}>
                <IonCol
                  size="auto"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonIcon icon={paperPlaneOutline} color="primary" />
                </IonCol>
                <IonCol
                  style={{ color: 'var(--ion-color-primary)' }}
                  className="points-help-link"
                >
                  I need help using my points to book this flight
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                const guideUrl = getUrl('guideUrl');
                if (guideUrl) {
                  window.open(guideUrl, '_blank');
                }
                if (travelPortal) {
                  await logAnalyticsMilesAndPointsGuideClick(
                    travelPortal.creditCardIssuer
                  );
                }
              }}
              sizeXs="12"
              sizeSm="6"
            >
              <IonRow>
                <IonCol
                  size="auto"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonIcon icon={cardOutline} color="primary" />
                </IonCol>
                <IonCol
                  style={{ color: 'var(--ion-color-primary)' }}
                  className="points-help-link"
                >
                  How can I earn points to book flights in the future?
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonRow>
      </div>
    </IonModal>
  );
};

export default PointsEstimateModal;
