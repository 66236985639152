import { useEffect, useState } from 'react';
import usePresentToast from './presentToast';

export const useCodeQueryParam = () => {
  const { presentError } = usePresentToast();

  const [couponQueryParam, setCouponQueryParam] = useState<string>();
  const [codeQueryParam, setCodeQueryParam] = useState<string>();
  const [giftCodeQueryParam, setGiftCodeQueryParam] = useState<string>();
  const [couponOrGiftCodeQueryParam, setCouponOrGiftCodeQueryParam] =
    useState<string>();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Query params: code comes from gifter email, giftCode comes from giftee email, and coupon should be generic discounts
    const queryParams = new URLSearchParams(location.search);
    const codes: string[] = [];

    const codeParam = queryParams.get('code');
    const giftCodeParam = queryParams.get('giftCode');
    const couponParam = queryParams.get('coupon');

    if (codeParam) codes.push(codeParam);
    if (giftCodeParam) codes.push(giftCodeParam);
    if (couponParam) codes.push(couponParam);

    if (codes.length > 1)
      presentError(
        'Multiple promotion/gift codes presented - please refresh the page and try again'
      );

    setCouponQueryParam(couponParam ?? undefined);
    setCodeQueryParam(codeQueryParam ?? undefined);
    setGiftCodeQueryParam(giftCodeParam ?? codeParam ?? undefined);
    setCouponOrGiftCodeQueryParam(
      giftCodeParam ?? codeParam ?? couponParam ?? undefined
    );
    setIsInitialized(true);
  }, []);

  const removeCouponQueryParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('coupon');
    window.history.pushState({}, '', url.href);
    // if coupon param exists, set to undefined
    if (couponQueryParam) {
      setCouponQueryParam(undefined);
    }
    // if gift code and code param doesn't exist, set CouponOrGiftCode to undefined
    if (!codeQueryParam && !giftCodeQueryParam) {
      setCouponOrGiftCodeQueryParam(undefined);
    }
  };

  return {
    couponQueryParam,
    giftCodeQueryParam,
    couponOrGiftCodeQueryParam,
    couponsAreInitialized: isInitialized,
    removeCouponQueryParam,
  } as const;
};
