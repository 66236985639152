import { useDevice } from '../hooks/useDevice';

export interface IPromotion {
  type: 'klaviyo-form' | 'undefined';
  ref: string;
}

interface PromotionCardProps {
  promotion?: IPromotion;
  reference?: React.Ref<HTMLDivElement>;
  form?: JSX.Element;
  sx?: React.CSSProperties;
}

export const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  reference,
  form,
  sx,
}) => {
  const { isApp, isSmallScreenSizeOrSmaller, isExtraSmallScreenSize } =
    useDevice();

  return (
    <div
      className={
        isApp || isSmallScreenSizeOrSmaller
          ? 'deal-slide-wrapper deal-slide-wrapper-responsive'
          : 'deal-slide-wrapper deal-slide-wrapper-responsive flinch-on-hover'
      }
      style={{
        margin: isExtraSmallScreenSize ? 0 : 12,
        maxWidth: isSmallScreenSizeOrSmaller ? '90%' : '',
        ...sx,
      }}
      ref={reference}
    >
      {form ??
        (promotion ? (
          <div className={`${promotion.type}-${promotion.ref}`} />
        ) : null)}
    </div>
  );
};
