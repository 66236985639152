import { IonButton, IonCol, IonRow } from '@ionic/react';

import { getAssetsBaseUrl } from '@faredrop/utilities';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import './../theme/HeroSection.css';
import './../theme/util.css';
import './../theme/AlternateLanding.css';
import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  title?: string;
  subtitle?: string;
  influencerName?: string;
  discountPercent?: number;
  discountAmount?: number;
  imageSrc?: string;
  hideImageOnMobile?: boolean;
  showAirplaneGarnish?: boolean;
}

const AlternateLandingHeroSection: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
  title,
  subtitle,
  influencerName,
  discountPercent,
  discountAmount,
  imageSrc,
  hideImageOnMobile,
  showAirplaneGarnish,
}) => {
  const { smoothScroll } = useLandingPageNavigation();
  const {
    isLargeScreenSizeOrSmaller,
    isSmallScreenSizeOrSmaller,
    isScreenLargerThanSize,
  } = useDevice();

  const titleSectionName = influencerName ?? 'Kara & Nate';

  const titleSection = title ? (
    <h1 className="main-title title-font" style={{ width: '100%' }}>
      {title}
    </h1>
  ) : (
    <h1 className="main-title title-font" style={{ width: '100%' }}>
      <span>Explore the world like</span>
      <br />
      <span style={{ whiteSpace: 'nowrap', color: '#fcbc8f' }}>
        {titleSectionName}
      </span>
      &nbsp;with&nbsp;
      <br />
      <span>amazing flight deals</span>
    </h1>
  );

  const imageContainerStyle: React.CSSProperties = {
    position: 'relative',
    minHeight: 400,
    minWidth: 400,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isLargeScreenSizeOrSmaller ? '-6em' : undefined,
  };

  let discount: string | undefined = undefined;
  if (discountPercent) {
    discount = `${discountPercent}% discount applied`;
  } else if (discountAmount) {
    discount = `$${discountAmount} discount applied`;
  }

  return (
    <IonRow
      style={
        isLargeScreenSizeOrSmaller
          ? {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : undefined
      }
    >
      <img
        className="alternate-landing-hero"
        src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom-large.png`}
        style={
          isLargeScreenSizeOrSmaller
            ? {
                WebkitMaskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 40%, rgba(0,0,0,0))',
                maskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 40%, rgba(0,0,0,0))',
              }
            : {}
        }
      />
      <img
        className="alternate-landing-hero"
        src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-black.png`}
        style={
          isLargeScreenSizeOrSmaller
            ? {
                WebkitMaskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                maskImage:
                  'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                top: -40,
              }
            : { top: -40 }
        }
      />
      <IonRow
        style={{
          width: '100%',
          paddingTop: isScreenLargerThanSize(1352) ? undefined : '5em',
        }}
      >
        <IonCol sizeLg="6" sizeXs="12">
          <div className="alternate-landing-main-titles-container">
            {(discountPercent || discountAmount) && (
              <div
                style={{
                  fontSize: '22px',
                  backgroundColor: 'var(--ion-color-yellow)',
                  padding: 10,
                  borderRadius: '25px',
                  textAlign: 'center',
                  width: '14em',
                }}
                className="center-on-mobile"
              >
                {discount}
              </div>
            )}
            {titleSection}
            {subtitle && (
              <h5 className="main-subtitle gray" style={{ width: '90%' }}>
                {subtitle}
              </h5>
            )}
            <IonRow
              className="get-started-now-button-row"
              style={{ marginTop: '3em' }}
            >
              <IonButton
                className="get-started-button get-started-button-hero mb-1-5"
                style={{ position: 'absolute' }}
                onClick={async () => {
                  smoothScroll('#register');
                  logAnalyticsEngagement &&
                    (await logAnalyticsEngagement(
                      AnalyticsEngagementId.HERO_GET_STARTED
                    ));
                }}
              >
                Get started now
              </IonButton>
              <img src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`} />
            </IonRow>
            <IonRow className="stats-summary" style={{ marginTop: '1em' }}>
              <IonCol
                sizeXl="4"
                sizeXs="6"
                className="right-border stats-summary-col"
                style={{ padding: '0px 20px', lineHeight: '3em' }}
              >
                <h3
                  className="light-gray medium-small-text m-0"
                  style={{ letterSpacing: 0.4, lineHeight: '1.5em' }}
                >
                  <span className="primary-color">$450</span>&nbsp;&nbsp;saved
                  per flight on average
                </h3>
              </IonCol>
              <IonCol
                sizeXl="4"
                sizeXs="6"
                className="stats-summary-col"
                style={{
                  padding: '0px 20px',
                  paddingLeft: '20px',
                  letterSpacing: 0.4,
                }}
              >
                <h3 className="light-gray medium-small-text m-0">
                  <span className="primary-color">Hundreds</span> of ⭐⭐⭐⭐⭐
                  reviews
                </h3>
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
        {(!isLargeScreenSizeOrSmaller ||
          (isLargeScreenSizeOrSmaller && !hideImageOnMobile)) && (
          <IonCol
            sizeLg="6"
            sizeXs="12"
            className="alternate-landing-image-container"
            style={imageContainerStyle}
          >
            <img
              className="landing-phone-outline mobile-auto-margin alternate-landing-image"
              src={
                imageSrc ??
                `${getAssetsBaseUrl()}/influencers/${influencerName}.jpg`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${getAssetsBaseUrl()}/kara-and-nate/van-cropped.jpg`;
              }}
              style={{
                objectFit: 'contain',
                objectPosition: 'top',
                marginTop: '7em',
                marginRight: showAirplaneGarnish ? '6em' : '2em',
                height: '55%',
                borderRadius: `${
                  showAirplaneGarnish ? '50% 50%' : '15px 15px'
                } 15px 15px`,
                maxWidth: '500px',
              }}
            />
            {!isSmallScreenSizeOrSmaller && showAirplaneGarnish && (
              <img
                src={`${getAssetsBaseUrl()}/graphics/plane.png`}
                style={{
                  objectFit: 'contain',
                  objectPosition: 'top',
                  top: 0,
                  right: 0,
                  width: '50%',
                  marginTop: isScreenLargerThanSize(1352) ? '5em' : undefined,
                  position: 'absolute',
                  marginRight: isScreenLargerThanSize(1352) ? '4em' : undefined,
                }}
              />
            )}
          </IonCol>
        )}
      </IonRow>
    </IonRow>
  );
};

export default AlternateLandingHeroSection;
