import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import CreatedBySection from '../components/CreatedBySection';
import DesktopHeader from '../components/DesktopHeader';
import Footer from '../components/Footer';
import GetStarted from '../components/GetStarted';
import { GetStartedBlock } from '../components/GetStartedBlock';
import HidingHeader from '../components/HidingHeader';
import HomePageDealsSection from '../components/HomePageDealsSection';
import HowItWorksSection from '../components/HowItWorksSection';
import Loading from '../components/Loading';
import MobileHeader from '../components/MobileHeader';
import QuestionsSection from '../components/QuestionsSection';
import SocialProofSection from '../components/SocialProofSection';
import SubscriptionPlansSection from '../components/SubscriptionPlansSection';
import TrackVisibility from '../components/TrackVisibility';
import useAnalytics from '../hooks/analytics';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { useHidingHeader } from '../hooks/useHidingHeader';
import './../theme/Home.css';
import './../theme/util.css';
import AlternateLandingHeroSection from '../components/AlternateLandingHeroSection';
import { AllowedPath, STRIPE_COUPON_ID } from '@faredrop/types';
import { FareDropPlan as FareDropPlanGQL } from '@faredrop/graphql-sdk';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import SubscriptionPlansTable from '../components/SubscriptionPlansTable';
import AsSeenIn from '../components/AsSeenIn';
import useHandleNewSubscription from '../hooks/handleNewSubscription';
import { PROMOTION_IS_SHOPIFY_INTEGRATION } from '@faredrop/utilities';
import PromotionFreebieModal from '../components/PromotionFreebieModal';
import { isFrontendSubscriptionPromotion } from '../utilities/plans-utilities';

interface ContainerProps {
  coupon?: STRIPE_COUPON_ID;
  discounts?: string;
  discountAmount?: number;
  influencerName?: string;
  imageSource?: string;
  hidePlans?: FareDropPlanGQL[];
  plansParams?: [{ key: string; value: string }];
  onMenuClickHandler: () => void;
}

const Home: React.FC<ContainerProps> = ({
  coupon,
  discounts,
  discountAmount,
  influencerName,
  imageSource,
  hidePlans,
  plansParams,
  onMenuClickHandler,
}) => {
  const {
    logAnalyticsInfluencerPageSectionView,
    logAnalyticsInfluencerPageEngagement,
    logAnalyticsOnboardingEngagement,
  } = useAnalytics();
  const {
    isLargeScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
    isSmallScreenSizeOrSmaller,
  } = useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { isAuthenticated, isInitialized } = useAuth();
  const { goWithStickyParamsPath } = useHistoryWithStickyParams();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();
  const [loading, setLoading] = useState(true);
  const { smoothScroll } = useLandingPageNavigation();
  const dynamicBannerContent = useDynamicBanner();
  const { selectedPlanParam, createPlanFromPlanParam, handleNewSubscription } =
    useHandleNewSubscription();
  const [promotionFreebieModalPlan, setPromotionFreebieModalPlan] =
    useState<FareDropPlanGQL>();

  if (!influencerName) {
    influencerName =
      influencerName ?? window.location.pathname.split('/').pop();
  }

  useEffect(() => {
    // Set coupon code in search params on load
    if (coupon || discounts) {
      const url = new URL(window?.location?.href);

      const currentCoupon = url.searchParams.get('coupon');
      const currentDiscounts = url.searchParams.get('discounts');

      if (coupon && coupon !== currentCoupon) {
        url.searchParams.set('coupon', coupon);
      }
      if (discounts && discounts !== currentDiscounts) {
        url.searchParams.set('discounts', discounts);
      }
      window.history.pushState({}, '', url.href);
    }
  }, [coupon, discounts]);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      goWithStickyParamsPath(AllowedPath.DEALS);
    } else if (isInitialized) {
      setLoading(false);
    }
  }, [isInitialized]);

  // If landing page has target query param, scroll to target section on load
  useIonViewDidEnter(() => {
    const queryParams = new URLSearchParams(location.search);
    const target = queryParams.get('target');
    if (target != null) {
      smoothScroll(`#${target}`);
    }
    // TODO: Eventually, we should move all of our sticky query params to live in session storage (if possible)
    // so that we can clean all of our URLs from query params that aren't useful for the user to see. Leaving
    // this commented for now...
    // Cleans the URL of any analytics query params
    // history.pushState(undefined, '', `${location.protocol}//${location.host}`);
  }, [location.search]);

  return loading ? (
    <Loading />
  ) : (
    <IonPage style={{ scrollBehavior: 'smooth' }}>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          smoothScroll('#register');
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader
            loggedIn={false}
            logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
          />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => {
          setScrollYCurrent(e.detail.scrollTop);
        }}
      >
        <div
          style={{
            paddingTop: !isLargeScreenSizeOrSmaller ? '2em' : undefined,
          }}
        >
          <TrackVisibility
            onVisible={async () =>
              logAnalyticsInfluencerPageSectionView('hero')
            }
          >
            <AlternateLandingHeroSection
              discountAmount={discountAmount}
              influencerName={influencerName}
              logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
              showAirplaneGarnish={true}
              imageSrc={imageSource}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('deals')
            }
          >
            <HomePageDealsSection
              logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('how_it_works')
            }
          >
            <HowItWorksSection />
          </TrackVisibility>
          <AsSeenIn />
          <div id="register">
            <TrackVisibility
              onVisible={async () =>
                await logAnalyticsInfluencerPageSectionView('register')
              }
            >
              <div className="login-page register-home register-home-mobile">
                <h3 className="title-font" style={{ textAlign: 'center' }}>
                  Create an account to get started
                </h3>
                <div style={{ maxWidth: '500px' }}>
                  <GetStarted
                    onSubmit={async () => {
                      if (selectedPlanParam !== undefined) {
                        const subscriptionPlan =
                          createPlanFromPlanParam(selectedPlanParam);

                        if (
                          isFrontendSubscriptionPromotion() &&
                          PROMOTION_IS_SHOPIFY_INTEGRATION &&
                          selectedPlanParam !== FareDropPlanGQL.Pro
                        ) {
                          setPromotionFreebieModalPlan(selectedPlanParam);
                        } else {
                          await handleNewSubscription(
                            subscriptionPlan,
                            coupon,
                            true,
                            plansParams
                          );
                        }
                      } else {
                        const params = new URLSearchParams(
                          window.location.search
                        );

                        if (coupon && !params.has('coupon')) {
                          params.set('coupon', coupon.toString());
                        }
                        if (discounts && !params.has('discounts')) {
                          params.set('discounts', discounts);
                        }

                        plansParams?.forEach((p) => {
                          params.set(p.key, p.value);
                        });

                        goWithStickyParamsLocation({
                          pathname: '/plans',
                          search: `?${params.toString()}`,
                        });
                      }
                    }}
                    logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
                  />
                </div>
              </div>
            </TrackVisibility>
          </div>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('social_proof')
            }
          >
            <SocialProofSection
              logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('subscription_plans')
            }
          >
            {!isMediumScreenSizeOrSmaller && <SubscriptionPlansTable />}
            {isMediumScreenSizeOrSmaller && (
              <SubscriptionPlansSection
                logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
                reverse={isSmallScreenSizeOrSmaller}
                hidePlans={hidePlans}
              />
            )}
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('created_by')
            }
          >
            <CreatedBySection
              logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('questions')
            }
          >
            <QuestionsSection
              onCardOpen={async (questionId) =>
                await logAnalyticsInfluencerPageEngagement(questionId)
              }
            />
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              await logAnalyticsInfluencerPageSectionView('get_started')
            }
          >
            <GetStartedBlock
              logAnalyticsEngagement={logAnalyticsInfluencerPageEngagement}
            />
          </TrackVisibility>
        </div>
        <TrackVisibility
          onVisible={async () =>
            await logAnalyticsInfluencerPageSectionView('footer')
          }
        >
          <Footer
            bgColor="secondary"
            isVisible={true}
            onLinkPress={async (linkId) =>
              await logAnalyticsInfluencerPageEngagement(linkId)
            }
          />
        </TrackVisibility>
      </IonContent>
      <PromotionFreebieModal
        selectedPlan={promotionFreebieModalPlan}
        setIsModalActive={() => {
          setPromotionFreebieModalPlan(undefined);
        }}
        onSubmit={handleNewSubscription}
      />
    </IonPage>
  );
};

export default Home;
