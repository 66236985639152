import { useHistory } from 'react-router';
import { LocationDescriptorObject, LocationState, Path } from 'history';

import { UTM_WHITELIST } from '@faredrop/types';

export interface HistoryWithStickyParamsValue {
  buildStickyParamsLocationDescriptor(
    location: LocationDescriptorObject,
    excludeStickyParams?: string[]
  ): LocationDescriptorObject;
  goWithStickyParamsPath(
    path: Path,
    state?: LocationState,
    excludeStickyParams?: string[]
  ): void;
  goWithStickyParamsLocation(
    location: LocationDescriptorObject,
    excludeStickyParams?: string[]
  ): void;
  removeUnauthenticatedStickyParams(): void;
}

const unauthenticatedStickyParams = [
  'banner',
  'selected-plan', // For skipping plan selection if user selects the PRO plan before registration
];
// Note: Make sure these query params are being removed after use
const stickyParamsWhiteList = [
  'redirect',
  'coupon',
  'planId',
  'giftCode',
  'discounts',
  'discountExpiration',
  'airfare', // For selecting a specific airfare for deal details
  'dda', // Encoded deal details arguments for deal details page
  'cta', // Call To Action - firebase push id for the timestamp when CTA was performed (e.g., when a user logs in or signs up from deal details, we want to give them access to the converting deal regardless if their limited account has access or not)
  'prepromotion', // Force promotion content to be shown before sale window starts
  '_ga',
  '_gl',
  // Spark Loop
  'rh_ref',
  'sl_campaign',
  // First Promoter
  'fpr',
  // Impact
  'irclickid', // cspell:disable-line
  ...UTM_WHITELIST,
  ...unauthenticatedStickyParams,
];

const useHistoryWithStickyParams = (): HistoryWithStickyParamsValue => {
  const history = useHistory();

  const buildStickyParams = (excludeStickyParams?: string[]) => {
    // NOTE: It is recommended to use useLocation().search instead of history.location.search since history object is mutable, BUT..
    // We come across race conditions where useLocation().search state is not updated when we need it to be (e.g., in GuardedRoute when doing immediate redirects). Thus, we need to use history object since it's updated immediately
    // https://v5.reactrouter.com/web/api/history
    const params = new URLSearchParams(history.location.search);
    const updatedParams = new URLSearchParams(history.location.search);
    params.forEach((_value: string, key: string) => {
      if (
        !stickyParamsWhiteList.includes(key) ||
        excludeStickyParams?.includes(key)
      ) {
        updatedParams.delete(key);
      }
    });
    return updatedParams;
  };

  const goWithStickyParamsPath = (
    path: Path,
    state?: LocationState,
    excludeStickyParams?: string[]
  ) => {
    history.push({
      pathname: path,
      search: buildStickyParams(excludeStickyParams).toString(),
      state,
    });
  };

  const buildStickyParamsLocationDescriptor = (
    locationDescriptor: LocationDescriptorObject,
    excludeStickyParams?: string[]
  ) => {
    const params = new URLSearchParams(locationDescriptor.search);
    const stickyParams = buildStickyParams(excludeStickyParams);
    stickyParams.forEach((value: string, key: string) => {
      if (!params.has(key)) {
        params.append(key, value);
      }
    });

    const descriptor: LocationDescriptorObject = {
      pathname: locationDescriptor.pathname,
      search: `?${params.toString()}`,
      hash: locationDescriptor.hash,
      state: locationDescriptor.state,
      key: locationDescriptor.key,
    };

    return descriptor;
  };

  const goWithStickyParamsLocation = (
    locationDescriptor: LocationDescriptorObject,
    excludeStickyParams?: string[]
  ) => {
    history.push(
      buildStickyParamsLocationDescriptor(
        locationDescriptor,
        excludeStickyParams
      )
    );
  };

  const removeUnauthenticatedStickyParams = () => {
    const params = new URLSearchParams(history.location.search);
    const updatedParams = new URLSearchParams(history.location.search);
    params.forEach((_value: string, key: string) => {
      if (unauthenticatedStickyParams.includes(key)) {
        updatedParams.delete(key);
      }
    });

    if (params.toString() !== updatedParams.toString()) {
      history.push({
        search: updatedParams.toString(),
      });
    }
  };

  return {
    buildStickyParamsLocationDescriptor,
    goWithStickyParamsPath,
    goWithStickyParamsLocation,
    removeUnauthenticatedStickyParams,
  };
};

export default useHistoryWithStickyParams;
