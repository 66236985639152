import { IonSpinner } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useCodeQueryParam } from '../hooks/useCodeQueryParam';

interface CodeInputProps {
  discountDescription?: string;
  discountError?: string;
  onApply: (code?: string) => Promise<void>;
}

const CodeInput: FC<CodeInputProps> = ({
  discountDescription,
  discountError,
  onApply,
}) => {
  const { couponOrGiftCodeQueryParam } = useCodeQueryParam();

  const [code, setCode] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    if (couponOrGiftCodeQueryParam) {
      setCode(couponOrGiftCodeQueryParam);
      setApplyDisabled(true);
    }
  }, [couponOrGiftCodeQueryParam]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{ marginTop: '2em' }}>
        <div style={{ marginTop: 5, display: 'flex', width: '100%' }}>
          <input
            type="text"
            value={code}
            className="payment-form-gift-code-input"
            style={{
              width: '9.5em',
            }}
            maxLength={8}
            placeholder="Coupon/Gift Code"
            onChange={async (e) => {
              const updatedVal = e.currentTarget.value as string;
              setCode(updatedVal);

              if (updatedVal === '') setApplyDisabled(true);
              else if (applyDisabled) {
                setApplyDisabled(false);
                await onApply();
              }

              if (error) {
                setError(undefined);
              }
            }}
          />
          <button
            className="button no-caps submit-button-outline"
            disabled={applyDisabled}
            type="button"
            style={{
              margin: 'auto',
              marginLeft: '1em',
              transition: 'opacity 500ms',
              opacity: submitting ? 0.5 : 1.0,
              color: applyDisabled ? 'lightgray' : undefined,
              borderColor: applyDisabled ? 'lightgray' : undefined,
            }}
            onClick={async () => {
              if (code) {
                setSubmitting(true);
                try {
                  await onApply(code);
                  setApplyDisabled(true);
                  if (error) setError(undefined);
                } catch (error) {
                  setError(
                    'Failed to apply code, please try again or contact support: team@faredrop.com'
                  );
                } finally {
                  setSubmitting(false);
                }
              }
            }}
          >
            {(submitting && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IonSpinner
                  style={{ margin: 'auto 0' }}
                  color="var(--ion-color-primary)"
                  name="crescent"
                />
              </div>
            )) || <>Apply</>}
          </button>
        </div>
      </div>
      <p
        style={{
          width: '100%',
          textAlign: 'center',
          color: discountError
            ? 'var(--ion-color-danger)'
            : 'var(--ion-color-primary)',
        }}
      >
        <b>{discountError ?? discountDescription ?? ''}</b>
      </p>
      {error && (
        <p
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'var(--ion-color-danger)',
          }}
        >
          <b>{error}</b>
        </p>
      )}
    </div>
  );
};

export default CodeInput;
