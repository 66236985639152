import { Elements } from '@stripe/react-stripe-js';

import { SubscriptionPlan } from '@faredrop/graphql-sdk';

import PaymentForm, { SetupIntentReason } from './PaymentForm';
import useStripeReact from '../hooks/useStripeReact';
import ResponsiveModal from './ResponsiveModal';

interface PaymentModalProps {
  selectedPlan?: SubscriptionPlan;
  setupIntentReason?: SetupIntentReason;
  clientSecret?: string;
  onSubmit: (planId: string, setupIntentId?: string) => Promise<void>;
  onClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  selectedPlan,
  clientSecret,
  onSubmit,
  onClose,
}) => {
  const { stripePromise } = useStripeReact();

  const active = clientSecret !== '' && !!selectedPlan;

  return (
    <>
      {active && (
        <ResponsiveModal active={active} onClose={onClose}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm
              plan={selectedPlan}
              setupIntentReason={SetupIntentReason.UPGRADE}
              onSubmit={async (_elements, _stripe, setupIntent) => {
                await onSubmit(selectedPlan.id, setupIntent?.id);
              }}
            />
          </Elements>
        </ResponsiveModal>
      )}
    </>
  );
};

export default PaymentModal;
