import { IonCol, IonIcon, IonRow, IonSkeletonText } from '@ionic/react';
import { chevronBackCircleOutline, chevronForwardCircle } from 'ionicons/icons';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { Airfare, Deal, VerifiedAirfare } from '@faredrop/graphql-sdk';
import { FareDropPlan } from '@faredrop/types';
import {
  getCurrencySymbol,
  isAirfareExpired,
  stripePlanIDByFareDropPlan,
} from '@faredrop/utilities';

import './../../theme/util.css';
import './../../theme/DealDetails.css';

import { useDevice } from '../../hooks/useDevice';
import { usePrevious } from '../../hooks/previous';
import useUser from '../../hooks/user';
import useAuth from '../../hooks/auth';
import { QUERY_PARAM_HIDE_EXPIRY_MESSAGING } from '../../utilities/constants';

const NO_AIRFARE_PARAM = 'NO_AIRFARE_PARAM';

type IAirfareSlide = Pick<
  VerifiedAirfare,
  'departureDate' | 'totalPrice' | 'bookingUrl' | 'departureUrl' | 'returnDate'
>;

interface ContainerProps {
  deal?: Deal;
  isLoading?: boolean;
  verifiedAirfares?: IAirfareSlide[];
  bestAirfaresByMonth?: Airfare[];
  airfaresPerView: number;
  onSelect?: (departureDate: string) => void;
  onBook?: (airfareSlide: IAirfareSlide) => Promise<void>;
}

const DealDetailsAirfares: React.FC<ContainerProps> = ({
  deal,
  isLoading,
  verifiedAirfares,
  bestAirfaresByMonth,
  airfaresPerView,
  onSelect,
  onBook,
}) => {
  const {
    isSmallScreenSize,
    isExtraSmallScreenSize,
    isMediumScreenSizeOrSmaller,
  } = useDevice();
  const { isInitialized, isAuthenticated } = useAuth();
  const userState = useUser();
  const swiperRef = useRef<SwiperRef>(null);
  const componentInitialized = useRef(false);

  const previousDeal = usePrevious(deal);

  const [airfareSlides, setAirfareSlides] = useState<IAirfareSlide[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [lastViewableIndex, setLastViewableIndex] = useState<number>();
  const [queryParamDepartureDate, setQueryParamDepartureDate] =
    useState<string>();
  const [hideExpiryMessaging, setHideExpiryMessaging] =
    useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const airfareParam = queryParams.get('airfare');
    if (airfareParam) {
      setQueryParamDepartureDate(airfareParam);
    } else {
      setQueryParamDepartureDate(NO_AIRFARE_PARAM);
    }

    if (queryParams.has(QUERY_PARAM_HIDE_EXPIRY_MESSAGING)) {
      setHideExpiryMessaging(true);
    }
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      isInitialized &&
      (!isAuthenticated || !userState.isInitializing) &&
      verifiedAirfares &&
      bestAirfaresByMonth &&
      !componentInitialized.current &&
      queryParamDepartureDate != null
    ) {
      const airfareSlides: IAirfareSlide[] = [...verifiedAirfares];
      airfareSlides.sort((a, b) => {
        if (moment(a.departureDate).isBefore(moment(b.departureDate))) {
          return -1;
        } else if (moment(a.departureDate).isAfter(moment(b.departureDate))) {
          return 1;
        } else {
          return 0;
        }
      });

      let frontLoad = 0;
      if (verifiedAirfares.length < airfaresPerView) {
        const diff = airfaresPerView - verifiedAirfares.length;
        const load = Math.floor(diff / 2);
        frontLoad = load;
        let backLoad = load;
        if (diff % 2 !== 0) {
          backLoad = load + 1;
        }

        for (let i = frontLoad; i > 0; i--) {
          const previousAirfare = airfareSlides[0];
          const previousAirfareMoment = moment(
            previousAirfare.departureDate
          ).subtract(1, 'month');
          if (previousAirfareMoment.isSameOrAfter(moment(), 'month')) {
            const previousMonthBestAirfare = bestAirfaresByMonth?.find((a) =>
              moment(a.departureDate).isSame(previousAirfareMoment, 'month')
            );
            airfareSlides.unshift({
              totalPrice: previousMonthBestAirfare?.price,
              departureDate: previousAirfareMoment.format('YYYY-MM-DD'),
            });
          } else {
            backLoad++;
          }
        }

        for (let i = 0; i < backLoad; i++) {
          const nextAirfare = airfareSlides[airfareSlides.length - 1];
          const nextAirfareMoment = moment(nextAirfare.departureDate).add(
            1,
            'month'
          );
          const nextMonthBestAirfare = bestAirfaresByMonth?.find((a) =>
            moment(a.departureDate).isSame(nextAirfareMoment, 'month')
          );
          airfareSlides.push({
            totalPrice: nextMonthBestAirfare?.price,
            departureDate: nextAirfareMoment.format('YYYY-MM-DD'),
          });
        }
      }
      setAirfareSlides(airfareSlides);

      let queryParamIndex: number | undefined;
      if (queryParamDepartureDate !== NO_AIRFARE_PARAM) {
        queryParamIndex = airfareSlides.findIndex(
          (a) => a.departureDate === queryParamDepartureDate
        );
        if (queryParamIndex > -1) {
          setSelectedIndex(queryParamIndex);
          if (onSelect) {
            onSelect(airfareSlides[queryParamIndex].departureDate);
          }
        }
      }

      if (!queryParamIndex || queryParamIndex === -1) {
        let minPriceIndex = 0;
        let minPrice = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < airfareSlides.length; i++) {
          const airfareSlide = airfareSlides[i];
          const url = airfareSlide.bookingUrl ?? airfareSlide.departureUrl;
          if (
            // Url is required for users that have access to the deal, if they don't, they will be shown a modal instead
            (!!url ||
              (stripePlanIDByFareDropPlan(FareDropPlan.PRO) !==
                userState.user?.billing.idStripePlan &&
                !deal?.airfares)) &&
            airfareSlide.totalPrice &&
            airfareSlide.totalPrice < minPrice &&
            (hideExpiryMessaging || // If we are hiding the expired messaging, we can select any airfare
              !isAirfareExpired(airfareSlide) || // Don't select an old airfare
              isAirfareExpired(airfareSlides[airfareSlides.length - 1])) // If all deals are expired, select the airfare with the min price
          ) {
            minPrice = airfareSlide.totalPrice;
            minPriceIndex = i;
          }
        }
        // Only select index with min price on initialization or when the deal changes
        if (selectedIndex == null || deal !== previousDeal) {
          setSelectedIndex(minPriceIndex);
          if (onSelect) {
            onSelect(airfareSlides[minPriceIndex].departureDate);
          }
        } else {
          // If the screen size has changed, move to the already selected slide
          swiperRef.current?.swiper.slideTo(selectedIndex);
        }
      }
    }
  }, [
    deal,
    isLoading,
    verifiedAirfares,
    bestAirfaresByMonth,
    isExtraSmallScreenSize,
    isInitialized,
    isAuthenticated,
    userState.isInitializing,
    queryParamDepartureDate,
  ]);

  const getLastViewableIndex = (swiper: SwiperType) => {
    if (typeof swiper.params.slidesPerView === 'number') {
      return swiper.activeIndex + swiper.params.slidesPerView - 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      setLastViewableIndex(getLastViewableIndex(swiperRef.current.swiper));
    }
  }, [swiperRef.current]);

  const handleSlideChange = (swiper: SwiperType) => {
    setLastViewableIndex(getLastViewableIndex(swiper));

    // On mobile, automatically select the new slide
    if (isExtraSmallScreenSize && selectedIndex != null) {
      setSelectedIndex(swiper.activeIndex);
      if (onSelect && airfareSlides) {
        onSelect(airfareSlides[swiper.activeIndex].departureDate);
      }
    }
  };

  const handlePreviousSlide = async () => {
    if (swiperRef.current) {
      swiperRef.current?.swiper.slidePrev();

      // On mobile, automatically select the new slide
      if (isExtraSmallScreenSize && selectedIndex != null) {
        const newIndex = selectedIndex - 1;
        setSelectedIndex(newIndex);
        if (onSelect && airfareSlides) {
          onSelect(airfareSlides[newIndex].departureDate);
        }
      }
    }
  };

  const handleNextSlide = async () => {
    if (swiperRef.current) {
      swiperRef.current?.swiper.slideNext();

      // On mobile, automatically select the new slide
      if (isExtraSmallScreenSize && selectedIndex != null) {
        const newIndex = selectedIndex + 1;
        setSelectedIndex(newIndex);
        if (onSelect && airfareSlides) {
          onSelect(airfareSlides[newIndex].departureDate);
        }
      }
    }
  };

  const priceFormatted = (price: number) =>
    deal ? `${getCurrencySymbol(deal.currency)}${price}` : undefined;

  const swiper = swiperRef.current?.swiper;

  const areNavButtonsEnabled =
    airfareSlides && airfareSlides.length > airfaresPerView;
  const isBackButtonEnabled = areNavButtonsEnabled && swiper?.activeIndex !== 0;
  const isForwardButtonEnabled =
    areNavButtonsEnabled && lastViewableIndex !== airfareSlides.length - 1;

  const previousAirfareSlide =
    swiper && airfareSlides
      ? swiper?.activeIndex !== 0
        ? airfareSlides[swiper.activeIndex - 1]
        : undefined
      : undefined;
  const previousAirfareMoment = previousAirfareSlide
    ? moment(previousAirfareSlide.departureDate)
    : undefined;
  const backArrow = (
    <IonCol
      size="auto"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        opacity: isBackButtonEnabled ? 1 : 0.1,
        position: 'relative',
      }}
      onClick={handlePreviousSlide}
    >
      {isExtraSmallScreenSize &&
        !!previousAirfareSlide &&
        previousAirfareSlide?.totalPrice != null && (
          <div
            style={{
              position: 'absolute',
              color: 'var(--ion-color-gray-sub-text)',
              textAlign: 'center',
              top: '15px',
              lineHeight: '22px',
              left: 0,
              opacity: '.75',
            }}
          >
            <div>{previousAirfareMoment?.format('MMM')}</div>
            <div>{priceFormatted(previousAirfareSlide.totalPrice)}</div>
          </div>
        )}
      <IonIcon
        size="large"
        icon={chevronBackCircleOutline}
        style={{ cursor: !isBackButtonEnabled ? 'initial' : undefined }}
      />
    </IonCol>
  );

  const nextAirfareSlide =
    swiper && airfareSlides
      ? swiper?.activeIndex !== airfareSlides.length - 1
        ? airfareSlides[swiper.activeIndex + 1]
        : undefined
      : undefined;
  const nextAirfareMoment = nextAirfareSlide
    ? moment(nextAirfareSlide.departureDate)
    : undefined;
  const forwardArrow = (
    <IonCol
      size="auto"
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        opacity: isForwardButtonEnabled ? 1 : 0.1,
        position: 'relative',
      }}
      onClick={handleNextSlide}
    >
      {isExtraSmallScreenSize &&
        !!nextAirfareSlide &&
        nextAirfareSlide?.totalPrice != null && (
          <div
            style={{
              position: 'absolute',
              color: 'var(--ion-color-gray-sub-text)',
              textAlign: 'center',
              top: '15px',
              lineHeight: '22px',
              left: 0,
              opacity: '.75',
            }}
          >
            <div>{nextAirfareMoment?.format('MMM')}</div>
            <div>{priceFormatted(nextAirfareSlide.totalPrice)}</div>
          </div>
        )}
      <IonIcon
        size="large"
        icon={chevronForwardCircle}
        style={{ cursor: !isForwardButtonEnabled ? 'initial' : undefined }}
      />
    </IonCol>
  );

  const currencySymbol = getCurrencySymbol(deal?.currency);

  return (
    <IonRow
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: isLoading
          ? '4em'
          : isMediumScreenSizeOrSmaller
          ? undefined
          : '1.25em',
      }}
    >
      {isSmallScreenSize && (
        <IonRow
          style={{ width: '100%', paddingLeft: '1em', paddingRight: '1em' }}
        >
          <IonCol size="6" style={{ display: 'flex' }}>
            {backArrow}
          </IonCol>
          <IonCol
            size="6"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {forwardArrow}
          </IonCol>
        </IonRow>
      )}
      <IonRow
        style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
      >
        {!isSmallScreenSize ? backArrow : <></>}
        <IonCol className="deal-details-airfare-selector">
          <Swiper
            ref={swiperRef}
            style={{
              display: 'flex',
              flex: 1,
              backgroundColor: '#F8F6F3',
              borderRadius: '20px',
              height: '145px',
            }}
            allowSlideNext={true}
            slidesPerView={airfaresPerView}
            freeMode={true}
            onSlideChange={handleSlideChange}
            initialSlide={
              isExtraSmallScreenSize ||
              (selectedIndex ?? 0) > airfaresPerView - 1
                ? selectedIndex
                : undefined
            }
            centeredSlides={isExtraSmallScreenSize}
          >
            {!isLoading &&
              airfareSlides?.map((a, index) => {
                const departureMoment = moment(a.departureDate);
                const url = a.bookingUrl ?? a.departureUrl;
                const isSelectable =
                  // Url is required for users that have access to the deal, if they don't, they will be shown a modal instead
                  (!!url ||
                    (isInitialized &&
                      stripePlanIDByFareDropPlan(FareDropPlan.PRO) !==
                        userState.user?.billing.idStripePlan &&
                      !deal?.airfares)) &&
                  a.totalPrice != null;

                const isSelectedSlide =
                  isExtraSmallScreenSize || index === selectedIndex;
                const isSameYear = departureMoment.isSame(moment(), 'year');
                const isSlideBeforeFirstViewableSlide =
                  swiperRef.current?.swiper.activeIndex &&
                  index === swiperRef.current?.swiper.activeIndex - 1;
                const isLastViewableSlide = index === lastViewableIndex;
                const isPreviousToSelectedSlide =
                  index === (selectedIndex ?? -1) - 1;
                const showBorder =
                  !isSelectedSlide &&
                  !isPreviousToSelectedSlide &&
                  !isSlideBeforeFirstViewableSlide &&
                  !isLastViewableSlide;
                const isExpired = isAirfareExpired(a) && !hideExpiryMessaging;
                return (
                  <SwiperSlide
                    key={a.departureDate}
                    style={{ animation: 'fadeIn .5s' }}
                  >
                    <IonCol
                      style={{
                        backgroundColor: isSelectedSlide
                          ? 'rgba(181,216,223,.7)'
                          : undefined,
                        display: 'flex',
                        borderRadius: '20px',
                        color: isSelectedSlide
                          ? 'var(--ion-color-primary)'
                          : 'var(--ion-color-gray-sub-text)',
                        fontSize: isExtraSmallScreenSize ? '20px' : '16px',
                        cursor:
                          isSelectable && !isSelectedSlide
                            ? 'pointer'
                            : undefined,
                        padding: 0,
                        height: '100%',
                      }}
                      onClick={() => {
                        if (isSelectable) {
                          setSelectedIndex(index);

                          if (onSelect) {
                            onSelect(airfareSlides[index].departureDate);
                          }
                        }
                      }}
                    >
                      <IonRow
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderRight: showBorder
                            ? 'solid 1px #D9D9D9'
                            : undefined,
                          flex: 1,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          height: '100%',
                          opacity: isSelectable ? 1 : 0.4,
                        }}
                      >
                        <IonRow
                          style={{
                            lineHeight: '25px',
                            marginTop: '.5em',
                            marginBottom: isSelectedSlide ? '5px' : undefined,
                          }}
                        >
                          {departureMoment.format(
                            url
                              ? `MMM DD${!isSameYear ? ', YY' : ''}`
                              : `MMMM${!isSameYear ? ', YY' : ''}`
                          )}
                        </IonRow>
                        {a.totalPrice != null ? (
                          <>
                            {!isSelectedSlide && (
                              <IonRow
                                style={{ lineHeight: '25px', height: '25px' }}
                              >
                                {url ? departureMoment.format('dddd') : ''}
                              </IonRow>
                            )}
                            <IonRow
                              style={{
                                lineHeight: isSelectedSlide ? '30px' : '45px',
                                fontSize: isExtraSmallScreenSize
                                  ? '36px'
                                  : '32px',
                              }}
                            >
                              <IonCol
                                style={{
                                  padding: 0,
                                  fontSize:
                                    currencySymbol === '$' ? '18px' : '12px',
                                  marginRight: '-4px',
                                }}
                              >
                                {currencySymbol}
                              </IonCol>
                              <IonCol>{a.totalPrice}</IonCol>
                            </IonRow>
                            {isSelectedSlide && (
                              <IonRow
                                style={{
                                  display: 'flex',
                                  flexGrow: 1,
                                  width: '100%',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {!isExpired && (
                                  <div
                                    style={{
                                      color: 'white',
                                      backgroundColor:
                                        'var(--ion-color-primary)',
                                      flex: 1,
                                      borderRadius: '5px',
                                      margin: `0px ${
                                        isExtraSmallScreenSize ? '50px' : '30px'
                                      } 8px`,
                                      lineHeight: '32px',
                                      textAlign: 'center',
                                      fontSize: '14px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={async () => {
                                      if (onBook) {
                                        await onBook(a);
                                      }
                                    }}
                                  >
                                    Book
                                  </div>
                                )}
                                {isExpired && (
                                  <div
                                    style={{
                                      color: 'var(--ion-color-danger)',
                                      fontStyle: 'italic',
                                      lineHeight: '32px',
                                      textAlign: 'center',
                                      fontSize: '14px',
                                      marginBottom: '1.25em',
                                    }}
                                  >
                                    Expired
                                  </div>
                                )}
                              </IonRow>
                            )}
                          </>
                        ) : (
                          <IonRow
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flex: 1,
                              paddingBottom: '10px',
                            }}
                          >
                            <IonRow>No</IonRow>
                            <IonRow>Deals</IonRow>
                          </IonRow>
                        )}
                      </IonRow>
                    </IonCol>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          {(!airfareSlides || isLoading) && (
            <IonRow
              style={{
                height: '145px',
                display: 'flex',
                width: '100%',
                borderRadius: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <IonSkeletonText
                animated={true}
                style={{
                  width: '100px',
                  height: '20px',
                  borderRadius: '10px',
                  opacity: 0.8,
                }}
              />
            </IonRow>
          )}
        </IonCol>
        {!isSmallScreenSize ? forwardArrow : <></>}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsAirfares;
