import { Clipboard } from '@capacitor/clipboard';
import { IonIcon, IonRow } from '@ionic/react';
import { Tooltip } from '@mui/material';
import { copyOutline } from 'ionicons/icons';
import { Dispatch, SetStateAction } from 'react';

import { Deal, SeatClass } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';
import { getAssetsBaseUrl } from '@faredrop/utilities';

import { useDevice } from '../../hooks/useDevice';
import Modal from './../MobileModal';
import { useAirportsFromDeal } from '../../hooks/airportsFromDeal';
import usePresentToast from '../../hooks/presentToast';

interface ContainerProps {
  show: boolean;
  deal?: Deal;
  setIsModalActive: Dispatch<SetStateAction<boolean>>;
}

const DealDetailsShareModal: React.FC<ContainerProps> = ({
  show,
  deal,
  setIsModalActive,
}) => {
  const { isMediumScreenSizeOrSmaller, isSmallScreenSizeOrSmaller } =
    useDevice();
  const { presentSuccess } = usePresentToast();
  const [destinationAirport, originAirport] = useAirportsFromDeal(deal);

  const title = `Share this Deal`;
  const titleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: isMediumScreenSizeOrSmaller ? '2.5em' : '1.5em',
  };

  const currentUrl = new URL(window.location.origin + window.location.pathname);
  const cleaned = new URL(currentUrl);
  cleaned.searchParams.set(
    'dda',
    deal?.dda ?? currentUrl.searchParams.get('dda') ?? ''
  );

  const shares = [
    {
      name: 'Copy Link',
      type: 'ionicon',
      icon: copyOutline,
      onClick: async () => {
        await Clipboard.write({
          url: cleaned.toString(),
        });
        presentSuccess('Copied!');
      },
    },
    {
      name: 'Facebook',
      type: 'url',
      url: `${getAssetsBaseUrl()}/icons/facebook.png`,
      onClick: () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          new URL(
            `${process.env.REACT_APP_NO_LOCALHOST_URL_ORIGIN}${AllowedPath.DEAL_DETAILS}?dda=${deal?.dda}`
          ).toString()
        )}`;
        window.open(facebookShareUrl, '_blank');
      },
    },
    {
      name: 'X',
      type: 'url',
      url: `${getAssetsBaseUrl()}/icons/x.png`,
      onClick: () => {
        const xShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `I know where I'm heading next.. check out this deal FareDrop just sent me!`
        )}&url=${encodeURIComponent(
          new URL(
            `${process.env.REACT_APP_NO_LOCALHOST_URL_ORIGIN}${AllowedPath.DEAL_DETAILS}?dda=${deal?.dda}&hashtags=FareDrop`
          ).toString()
        )}`;
        window.open(xShareUrl, '_blank');
      },
    },
  ];

  return (
    <Modal
      active={show}
      modalListener={setIsModalActive}
      size={isMediumScreenSizeOrSmaller ? '12' : '9'}
      darkBg={true}
      showAsFullPage={isMediumScreenSizeOrSmaller}
      height="100%"
      sx={{
        position: isMediumScreenSizeOrSmaller ? 'absolute' : 'absolute',
        paddingLeft: '1em',
        paddingRight: '1em',
      }}
      closeButtonMargin={isMediumScreenSizeOrSmaller ? '10px' : undefined}
    >
      <IonRow
        style={{
          padding: '2em',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          marginBottom: '2em',
        }}
      >
        <IonRow>
          {isSmallScreenSizeOrSmaller && <h6 style={titleStyle}>{title}</h6>}
          {!isSmallScreenSizeOrSmaller && <h1 style={titleStyle}>{title}</h1>}
        </IonRow>
        {deal && (
          <IonRow style={{ marginBottom: '2em' }}>
            <div>{`$${deal.minPrice} ${originAirport?.city} to ${
              destinationAirport?.city
            } ${
              deal.seatClass === SeatClass.Business ? ' Business Class' : ''
            }`}</div>
          </IonRow>
        )}
        <IonRow style={{ maxWidth: '90%' }}>
          {shares.map((share) => {
            return (
              <Tooltip title={share.name} key={share.name}>
                <div
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '20px',
                    backgroundColor: 'var(--ion-color-primary)',
                    marginLeft: '8px',
                    marginRight: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    await share.onClick();
                  }}
                >
                  {share.type === 'ionicon' && !!share.icon && (
                    <IonIcon
                      icon={share.icon}
                      style={{ color: 'white', fontSize: '23px' }}
                    />
                  )}
                  {share.type === 'url' && !!share.url && (
                    <img
                      src={share.url}
                      style={{
                        height: '23px',
                        width: '23px',
                        borderRadius: '20px',
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            );
          })}
        </IonRow>
      </IonRow>
    </Modal>
  );
};

export default DealDetailsShareModal;
