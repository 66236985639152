import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { closeOutline, home } from 'ionicons/icons';
import { Dispatch, SetStateAction } from 'react';

import { Airport } from '@faredrop/graphql-sdk';

import './../theme/util.css';

import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import useSearchAirports from '../hooks/useSearchAirports';
import { DropdownOptionWithSubtext } from '../types/types';
import { formatAirportCity } from '../utilities/deals-utilities';
import { useDevice } from '../hooks/useDevice';
import { formatGeoCoderOption } from '../contexts/airportSearchContext';
import SearchBarWithResults from './SearchBarWithResults';

interface ContainerProps {
  selectedAirport: Airport | undefined;
  setSelectedAirport:
    | Dispatch<SetStateAction<Airport | undefined>>
    | ((airport: Airport | undefined) => void);
  logAnalyticsEngagement?: (
    engagementId: AnalyticsEngagementId
  ) => Promise<void>;
  dropdownSx?: React.CSSProperties;
}

const SelectHomeAirportComponent: React.FC<ContainerProps> = ({
  logAnalyticsEngagement,
  selectedAirport,
  setSelectedAirport,
  // dropdownSx, TODO:
}) => {
  const { isMediumScreenSizeOrSmaller } = useDevice();
  const { searchAirports, getAirport } = useSearchAirports();

  const onSearchChange = async (searchText?: string) => {
    let result: DropdownOptionWithSubtext[] | undefined = undefined;
    if (searchText) {
      const [origins] = await Promise.all([
        searchAirports(searchText, {
          originsOnly: true,
          useMapBoxFallback: true,
        }),
      ]);
      result = origins.map((d) =>
        formatGeoCoderOption(d, { useParentAirports: true })
      );
    }
    return result;
  };

  const onSearchSelect = async (option: DropdownOptionWithSubtext) => {
    const split = option.text.split('(');
    const iata = split[1].replace(')', '').trim();

    let searchText: string | undefined = undefined;
    const airport = await getAirport(iata);
    if (airport) {
      setSelectedAirport(airport);
      searchText = airport.name;
    }
    return {
      displayText: searchText,
    };
  };

  const onSearchSubmit = async () => {
    if (logAnalyticsEngagement) {
      await logAnalyticsEngagement(AnalyticsEngagementId.SELECT_HOME_AIRPORT);
    }
  };

  const removeAirport = async () => {
    setSelectedAirport(undefined);
  };

  const nameFormatted = formatAirportCity(selectedAirport, true);

  return (
    <>
      <div style={{ margin: '.5em 0 1em', position: 'relative' }}>
        {selectedAirport === undefined && (
          <>
            <p style={{ fontFamily: 'nexa-bold', margin: '1.5em 0 .5em 1em' }}>
              <small>Select your home airport</small>
            </p>
            <SearchBarWithResults
              style={{
                fontSize: 14,
                borderRadius: '30px',
                border: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                height: '50px',
                color: 'inherit',
                background: 'white',
              }}
              expandedStyle={{
                borderRadius: '30px 30px 0 0',
                borderBottom: 'none',
              }}
              className="origin-search"
              placeholder={
                selectedAirport === undefined
                  ? 'Search by city, airport, or ZIP code'
                  : 'Remove the airport below to add a different airport'
              }
              onChange={onSearchChange}
              onSelect={onSearchSelect}
              onSubmit={onSearchSubmit}
            />
          </>
        )}
      </div>
      {selectedAirport && (
        <IonRow
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonCol
            className="departure-airport-card"
            key={selectedAirport.iata}
            size="12"
            style={{ background: 'white', margin: 0, padding: '1em' }}
          >
            <div className="close" style={{ marginTop: 5 }}>
              <IonIcon icon={closeOutline} onClick={() => removeAirport()} />
            </div>
            <IonRow>
              <div style={{ display: 'flex', marginRight: '1em' }}>
                <IonIcon
                  size="medium"
                  className="no-pointer-on-hover"
                  icon={home}
                  style={{ margin: 'auto', verticalAlign: 'sub' }}
                />
              </div>
              <div>
                <h6
                  style={{
                    maxWidth: isMediumScreenSizeOrSmaller ? 200 : 320,
                    lineHeight: '1.5em',
                  }}
                >
                  {nameFormatted}
                </h6>
                <p
                  style={{
                    fontSize: '12px',
                    marginBottom: 5,
                  }}
                >
                  {selectedAirport.name}
                </p>
              </div>
            </IonRow>
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default SelectHomeAirportComponent;
