import { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';

export interface LandingPageNavigationContextValue {
  smoothScroll: (target: string) => void;
}

// Since we set the LandingPageNavigationProvider to wrap our entire application in _app.tsx, this is basically boilerplate code that keeps Typescript happy
const LandingPageNavigationContext =
  createContext<LandingPageNavigationContextValue>({
    smoothScroll: () => undefined,
  });

interface LandingPageNavigationProps {
  children: ReactNode;
}

export const LandingPageNavigationProvider: FC<LandingPageNavigationProps> = (
  props
) => {
  const location = useLocation();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  const smoothScroll = (target: string) => {
    if (
      location.pathname === '/' ||
      location.pathname.match('/offer/.*') ||
      location.pathname.match('/miles-and-points') ||
      location.pathname.match('/start-here') ||
      location.pathname.match('/new-plan')
    ) {
      document.querySelector(target)?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      goWithStickyParamsLocation({
        pathname: '/',
        search: `?target=${target.replace('#', '')}`,
      });
    }
  };

  return (
    <LandingPageNavigationContext.Provider
      value={{
        smoothScroll,
      }}
    >
      {props.children}
    </LandingPageNavigationContext.Provider>
  );
};

export default LandingPageNavigationContext;
