import { IonButton, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import DesktopHeader from '../components/DesktopHeader';
import HidingHeader from '../components/HidingHeader';
import MobileHeader from '../components/MobileHeader';
import { useHidingHeader } from '../hooks/useHidingHeader';
import { useDevice } from '../hooks/useDevice';
import './../theme/util.css';
import Footer from '../components/Footer';
import useAnalytics from '../hooks/analytics';
import useLandingPageNavigation from '../hooks/useLandingPageNavigation';
import { useState, useRef, useEffect } from 'react';
import { AnalyticsEngagementId } from '../contexts/analyticsContext';
import TrackVisibility from '../components/TrackVisibility';
import useAuth from '../hooks/auth';
import { useDynamicBanner } from '../hooks/useDynamicBanner';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import { getAssetsBaseUrl } from '@faredrop/utilities';

interface ContainerProps {
  onMenuClickHandler: () => void;
}

const MilesAndPoints: React.FC<ContainerProps> = ({ onMenuClickHandler }) => {
  const {
    logAnalyticsMilesAndPointsPageEngagement,
    logAnalyticsMilesAndPointsPageSectionView,
  } = useAnalytics();
  const { isAuthenticated } = useAuth();
  const { isMediumScreenSizeOrSmaller, isLargeScreenSizeOrSmaller } =
    useDevice();
  const { hideDecimal, setScrollYCurrent } = useHidingHeader(50);
  const { smoothScroll } = useLandingPageNavigation();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slidesRef = useRef<SwiperRef>(null);
  const dynamicBannerContent = useDynamicBanner();
  const { goWithStickyParamsLocation } = useHistoryWithStickyParams();

  useEffect(() => {
    slidesRef.current?.swiper.slideTo(currentSlideIndex);
  }, [currentSlideIndex]);

  return (
    <IonPage>
      <HidingHeader
        hideDecimal={parseInt(hideDecimal.toString())}
        promotionText={dynamicBannerContent}
        bannerClickListener={() => {
          goWithStickyParamsLocation({
            pathname: '/',
            search: '?target=register',
          });
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader onMenuClickHandler={onMenuClickHandler} />
        ) : (
          <DesktopHeader loggedIn={isAuthenticated} />
        )}
      </HidingHeader>
      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={(e) => setScrollYCurrent(e.detail.scrollTop)}
      >
        <div
          style={{
            paddingTop: !isLargeScreenSizeOrSmaller ? '5em' : undefined,
          }}
        >
          <TrackVisibility
            onVisible={async () =>
              logAnalyticsMilesAndPointsPageSectionView('hero')
            }
          >
            <IonRow>
              <img
                className="hero"
                src={`${getAssetsBaseUrl()}/backgrounds/cut-bottom-large.png`}
                style={
                  isMediumScreenSizeOrSmaller
                    ? {
                        WebkitMaskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                        maskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                      }
                    : {}
                }
              />
              <img
                className="hero"
                src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-black.png`}
                style={
                  isMediumScreenSizeOrSmaller
                    ? {
                        WebkitMaskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                        maskImage:
                          'linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0))',
                        top: -40,
                      }
                    : { top: -40 }
                }
              />
              <IonCol sizeXl="1" sizeLg="0"></IonCol>
              <IonCol size={isMediumScreenSizeOrSmaller ? '12' : '10'}>
                <IonRow>
                  <IonCol size={isMediumScreenSizeOrSmaller ? '12' : '5'}>
                    <div
                      className="main-titles-container"
                      style={{
                        textAlign: 'center',
                        marginTop: isMediumScreenSizeOrSmaller ? '4em' : '7em',
                      }}
                    >
                      <h1
                        className="main-title title-font"
                        style={{ width: '100%' }}
                      >
                        <span>FareDrop Deals +</span>
                        <br />
                        <span
                          style={{ whiteSpace: 'nowrap', color: '#fcbc8f' }}
                        >
                          Miles & Points
                        </span>
                        &nbsp;=&nbsp;
                        <br />
                        <span>Unreasonably Cheap Travel</span>
                      </h1>
                      <IonRow
                        className="get-started-now-button-row"
                        style={{ marginTop: '3em' }}
                      >
                        <IonCol style={{ display: 'flex' }}>
                          <IonButton
                            className="get-started-button get-started-button-hero mb-1"
                            style={{ width: '15em', margin: 'auto' }}
                            onClick={async () => {
                              // Link for "Airline Credit Cards" from RV
                              window.open(
                                'https://oc.brcclx.com/t/?lid=26682859&cr=22978&last_updated=1518630238',
                                '_blank'
                              );
                              await logAnalyticsMilesAndPointsPageEngagement(
                                AnalyticsEngagementId.MILES_AND_POINTS_HERO_BUTTON
                              );
                            }}
                          >
                            Check out our favorite cards!
                          </IonButton>
                          <img
                            style={{
                              position: 'absolute',
                              right: '1em',
                              marginLeft: '0em',
                              marginTop: '2em',
                              display: isMediumScreenSizeOrSmaller
                                ? 'none'
                                : 'block',
                            }}
                            src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow
                        style={{
                          display: 'flex',
                          marginTop: '2em',
                          marginBottom: '2em',
                        }}
                      >
                        <a
                          style={{
                            opacity: 0.5,
                            textDecoration: 'underline',
                            margin: 'auto',
                          }}
                          onClick={async () => {
                            smoothScroll('#disclosures');
                            await logAnalyticsMilesAndPointsPageEngagement(
                              AnalyticsEngagementId.MILES_AND_POINTS_ADVERTISER_DISCLOSURE
                            );
                          }}
                        >
                          <small>Advertiser Disclosure</small>
                        </a>
                      </IonRow>
                      <IonRow
                        className="stats-summary"
                        style={{ display: 'flex' }}
                      >
                        <h3
                          className="light-gray medium-small-text m-0"
                          style={{
                            letterSpacing: 0.4,
                            lineHeight: '1.5em',
                            textAlign: 'center',
                            width: isMediumScreenSizeOrSmaller ? '100%' : '60%',
                            margin: 'auto',
                            fontSize: 20,
                          }}
                        >
                          With a welcome bonus, you can often fly for&nbsp;
                          <span
                            className="primary-color"
                            style={{
                              textDecoration: 'underline',
                              color: '#fcbc8f',
                            }}
                          >
                            FREE
                          </span>
                          !
                        </h3>
                      </IonRow>
                    </div>
                  </IonCol>
                  <IonCol
                    size={isMediumScreenSizeOrSmaller ? '12' : '7'}
                    className="alternate-landing-image-container"
                    style={{
                      position: 'relative',
                      minHeight: 400,
                      minWidth: 400,
                    }}
                  >
                    <div
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'top',
                        right: 0,
                        left: 0,
                        width: isMediumScreenSizeOrSmaller ? '100%' : '40em',
                        marginTop: isMediumScreenSizeOrSmaller ? '1em' : '5em',
                        position: isMediumScreenSizeOrSmaller
                          ? 'relative'
                          : 'absolute',
                        background: 'white',
                        borderRadius: isMediumScreenSizeOrSmaller
                          ? '2em'
                          : '50%',
                        padding: isMediumScreenSizeOrSmaller ? '1em' : '4em',
                        marginLeft: isMediumScreenSizeOrSmaller ? '' : '4em',
                        height: isMediumScreenSizeOrSmaller ? '' : '40em',
                        display: 'flex',
                        boxShadow: isMediumScreenSizeOrSmaller
                          ? ''
                          : '5px 5px 5px rgba(0, 0, 0, .25)',
                      }}
                    >
                      <p
                        style={{
                          margin: 'auto',
                          fontSize: isMediumScreenSizeOrSmaller ? 16 : 22,
                          lineHeight: '2em',
                          fontFamily: 'nexa',
                          textAlign: 'center',
                          width: '90%',
                        }}
                      >
                        We want everyone to&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        experience travel at a price you can afford,&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        whether it is to your 1st country or your&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        100th, like our owners&nbsp;
                        <a
                          href="https://www.youtube.com/karaandnate"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <b>Kara and Nate</b>
                        </a>
                        .&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        FareDrop deals on their own can help you&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        visit new places at amazing prices.&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        Combined with travel rewards programs,&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        you can go even farther, often times for&nbsp;
                        {isMediumScreenSizeOrSmaller || <br />}
                        <b>&nbsp;FREE </b>!
                      </p>
                    </div>
                    <img
                      src={`${getAssetsBaseUrl()}/graphics/plane-filled.png`}
                      className="mobile-hide"
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'top',
                        top: 0,
                        right: 0,
                        width: '50%',
                        position: 'absolute',
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol sizeMd="1" sizeSm="0"></IonCol>
            </IonRow>
          </TrackVisibility>
        </div>
        <IonRow
          style={{
            padding: isMediumScreenSizeOrSmaller ? '1em' : '6em',
            fontFamily: 'nexa',
            fontSize: 16,
            justifyContent: 'center',
            lineHeight: '2em',
          }}
        >
          <IonCol size="11">
            <TrackVisibility
              onVisible={async () =>
                logAnalyticsMilesAndPointsPageSectionView('description')
              }
            >
              <h2
                className="title-font"
                style={{
                  textAlign: 'center',
                  marginTop: isMediumScreenSizeOrSmaller ? '1em' : '0',
                }}
              >
                Unreasonably cheap travel with miles & points
              </h2>
              <IonRow style={{ justifyContent: 'center', textAlign: 'center' }}>
                <IonCol sizeXs="12" sizeLg="8">
                  <p
                    style={{
                      fontSize: isMediumScreenSizeOrSmaller ? 16 : 20,
                      lineHeight: '2em',
                    }}
                  >
                    <b
                      style={{
                        letterSpacing: 1,
                        fontSize: isMediumScreenSizeOrSmaller ? 16 : 22,
                        color: 'var(--ion-color-primary)',
                      }}
                    >
                      Traveling doesn&apos;t have to break the bank!
                    </b>
                    &nbsp; FareDrop puts the power of travel into the hands of
                    our members. But a lot of our members might not realize that
                    they can book amazing FareDrop deals without having to spend
                    a penny. This is made possible through miles & points
                    programs offered by major card programs such as{' '}
                    <b>Capital One</b>, <b>Chase</b>, and others.
                  </p>
                  <p
                    style={{
                      fontSize: isMediumScreenSizeOrSmaller ? 16 : 20,
                      lineHeight: '2em',
                    }}
                  >
                    One of the easiest and fastest ways to get started with
                    using miles & points to unlock travel is through credit card
                    welcome bonuses which are awarded to cardholders for
                    spending a certain amount of money within a certain time
                    period upon receiving their card.
                  </p>
                  <h3
                    style={{
                      fontSize: isMediumScreenSizeOrSmaller ? 16 : 20,
                      lineHeight: '2em',
                      textAlign: 'center',
                    }}
                  >
                    Here are the different portals that you can use to book
                    FareDrop deals:
                  </h3>
                </IonCol>
              </IonRow>
            </TrackVisibility>
            <TrackVisibility
              onVisible={async () =>
                logAnalyticsMilesAndPointsPageSectionView(
                  'bank_portal_descriptions'
                )
              }
            >
              <IonRow
                style={{
                  justifyContent: 'center',
                  margin: isMediumScreenSizeOrSmaller ? '4em 0' : '4em 0 8em',
                }}
              >
                {isMediumScreenSizeOrSmaller ? (
                  <>
                    <IonRow
                      className="row-vertical-align"
                      style={{ marginBottom: '5em' }}
                    >
                      <IonCol size="12" style={{ textAlign: 'center' }}>
                        <img src={`${getAssetsBaseUrl()}/logos/cap-one.jpg`} />
                        <p
                          style={{
                            textAlign: 'center',
                            marginTop: '2em',
                            lineHeight: '2em',
                            fontSize: 16,
                          }}
                        >
                          The Capital One Purchase Eraser is by far the easiest
                          program to use with FareDrop. Simply book your deals
                          like you normally would and then “erase” your airfare
                          (at 1 cent per earned Capital One Venture Mile) with
                          90 days of booking with an eligible Capital One card.
                        </p>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      className="row-vertical-align"
                      style={{ marginBottom: '5em' }}
                    >
                      <IonCol size="12" style={{ textAlign: 'center' }}>
                        <img src={`${getAssetsBaseUrl()}/logos/chase.jpg`} />
                        <p
                          style={{
                            textAlign: 'center',
                            marginTop: '2em',
                            lineHeight: '2em',
                            fontSize: 16,
                          }}
                        >
                          Chase Ultimate Rewards Points are redeemable at 1.25 –
                          1.5 cents per point for airfares depending on the card
                          that you have. To use these points, flights must be
                          booked through the Chase Ultimate Rewards Travel
                          Portal.
                        </p>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      className="row-vertical-align"
                      style={{ marginBottom: '5em' }}
                    >
                      <IonCol size="12" style={{ textAlign: 'center' }}>
                        <img src={`${getAssetsBaseUrl()}/logos/amex.webp`} />
                        <p
                          style={{
                            textAlign: 'center',
                            marginTop: '2em',
                            fontSize: 16,
                          }}
                        >
                          American Express® Membership Rewards are redeemable at
                          1 cent per Point for airfares. To use these Points,
                          flights must be booked through the American Express
                          Membership Rewards Travel Portal.
                        </p>
                      </IonCol>
                    </IonRow>
                  </>
                ) : (
                  <>
                    <Swiper
                      ref={slidesRef}
                      slidesPerView={1}
                      onSlideNextTransitionEnd={async () => {
                        if (
                          slidesRef.current?.swiper.activeIndex !=
                          currentSlideIndex
                        ) {
                          setCurrentSlideIndex(currentSlideIndex + 1);
                        }
                      }}
                      onSlidePrevTransitionEnd={async () => {
                        if (
                          slidesRef.current?.swiper.activeIndex !=
                          currentSlideIndex
                        ) {
                          setCurrentSlideIndex(currentSlideIndex - 1);
                        }
                      }}
                    >
                      <SwiperSlide style={{ display: 'block' }}>
                        <IonRow className="row-vertical-align">
                          <IonCol size="4" style={{ padding: '3em' }}>
                            <img
                              src={`${getAssetsBaseUrl()}/logos/cap-one.jpg`}
                            />
                            <p
                              style={{
                                textAlign: 'center',
                                marginTop: '2em',
                                lineHeight: '1.5em',
                                fontSize: 16,
                              }}
                            >
                              The Capital One Purchase Eraser is by far the
                              easiest program to use with FareDrop. Simply book
                              your deals like you normally would and then
                              “erase” your airfare (at 1 cent per earned Capital
                              One Venture Mile) with 90 days of booking with an
                              eligible Capital One card.
                            </p>
                          </IonCol>
                          <IonCol size="8" style={{ padding: '3em' }}>
                            <IonRow className="row-vertical-align">
                              <IonCol style={{ margin: '1em ' }}>
                                <img
                                  src={`${getAssetsBaseUrl()}/deal-modals/phx-koa.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                    borderRadius: 6,
                                  }}
                                />
                              </IonCol>
                              <IonCol
                                style={{ margin: '1em', position: 'relative' }}
                              >
                                <img
                                  src={`${getAssetsBaseUrl()}/travel-portals/cap-one-portal-example.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                  }}
                                />
                                <img
                                  src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`}
                                  style={{
                                    transform: 'rotate(90deg)',
                                    float: 'left',
                                  }}
                                />
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </SwiperSlide>
                      <SwiperSlide style={{ display: 'block' }}>
                        <IonRow className="row-vertical-align">
                          <IonCol size="4" style={{ padding: '3em' }}>
                            <img
                              src={`${getAssetsBaseUrl()}/logos/chase.jpg`}
                            />
                            <p
                              style={{
                                textAlign: 'center',
                                marginTop: '2em',
                                lineHeight: '1.5em',
                                fontSize: 16,
                              }}
                            >
                              Chase Ultimate Rewards Points are redeemable at
                              1.25 – 1.5 cents per point for airfares depending
                              on the card that you have. To use these points,
                              flights must be booked through the Chase Ultimate
                              Rewards Travel Portal.
                            </p>
                          </IonCol>
                          <IonCol size="8" style={{ padding: '2em' }}>
                            <IonRow className="row-vertical-align">
                              <IonCol style={{ margin: '1em ' }}>
                                <img
                                  src={`${getAssetsBaseUrl()}/deal-modals/jfk-dxb.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                    borderRadius: 6,
                                  }}
                                />
                              </IonCol>
                              <IonCol
                                style={{ margin: '1em', position: 'relative' }}
                              >
                                <img
                                  src={`${getAssetsBaseUrl()}/travel-portals/chase-portal-example.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                  }}
                                />
                                <img
                                  src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`}
                                  style={{
                                    transform: 'rotate(90deg)',
                                    float: 'left',
                                  }}
                                />
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </SwiperSlide>
                      <SwiperSlide style={{ display: 'block' }}>
                        <IonRow className="row-vertical-align">
                          <IonCol size="4" style={{ padding: '3em' }}>
                            <img
                              src={`${getAssetsBaseUrl()}/logos/amex.webp`}
                            />
                            <p
                              style={{
                                textAlign: 'center',
                                marginTop: '2em',
                                fontSize: 16,
                              }}
                            >
                              American Express® Membership Rewards are
                              redeemable at 1 cent per Point for airfares. To
                              use these Points, flights must be booked through
                              the American Express Membership Rewards Travel
                              Portal.
                            </p>
                          </IonCol>
                          <IonCol size="8" style={{ padding: '2em' }}>
                            <IonRow className="row-vertical-align">
                              <IonCol style={{ margin: '1em ' }}>
                                <img
                                  src={`${getAssetsBaseUrl()}/deal-modals/ord-dub.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                    borderRadius: 6,
                                  }}
                                />
                              </IonCol>
                              <IonCol
                                style={{ margin: '1em', position: 'relative' }}
                              >
                                <img
                                  src={`${getAssetsBaseUrl()}/travel-portals/amex-portal-example.jpg`}
                                  style={{
                                    boxShadow:
                                      '2px 2px 10px rgba(0, 0, 0, .25)',
                                  }}
                                />
                                <img
                                  src={`${getAssetsBaseUrl()}/graphics/orange-arrow.svg`}
                                  style={{
                                    transform: 'rotate(90deg)',
                                    float: 'left',
                                  }}
                                />
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </SwiperSlide>
                    </Swiper>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      {[...Array(3).keys()].map((page, i) => {
                        return (
                          <div
                            key={page}
                            style={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              background: '#999',
                              margin: 5,
                              opacity: currentSlideIndex == i ? 1 : 0.2,
                            }}
                            onClick={async () => {
                              setCurrentSlideIndex(i);
                              await logAnalyticsMilesAndPointsPageEngagement(
                                AnalyticsEngagementId.MILES_AND_POINTS_CAROUSEL_PAGER
                              );
                            }}
                          ></div>
                        );
                      })}
                    </div>
                  </>
                )}
              </IonRow>
            </TrackVisibility>
          </IonCol>
        </IonRow>
        <IonRow>
          <TrackVisibility
            onVisible={async () =>
              logAnalyticsMilesAndPointsPageSectionView('check_it_out')
            }
          >
            <IonRow
              className="get-started-block-container row-vertical-align"
              style={{
                padding: '0',
                height: 'auto',
                marginBottom: '8em',
                background: 'transparent',
                marginTop: 0,
              }}
            >
              <IonCol sizeXs="12" sizeLg="12" style={{ padding: 0 }}>
                <img
                  className="get-started-block-bg"
                  src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-white.png`}
                />
                <img
                  className="get-started-block-bg-mobile"
                  src={`${getAssetsBaseUrl()}/backgrounds/globe-scribble-white-east.png`}
                />
                <div className="wrapper">
                  <h3
                    style={{
                      margin: 'auto',
                      textAlign: 'center',
                      marginBottom: '1em',
                    }}
                    className="title-font"
                  >
                    Click here to check out some of our favorite reward cards
                    and start saving even more money on your travels today!
                  </h3>
                  <IonButton
                    className="get-started-button"
                    onClick={async () => {
                      // Link for "Airline Credit Cards" from RV
                      window.open(
                        'https://oc.brcclx.com/t/?lid=26682859&cr=22978&last_updated=1518630238',
                        '_blank'
                      );
                      await logAnalyticsMilesAndPointsPageEngagement(
                        AnalyticsEngagementId.MILES_AND_POINTS_CHECK_IT_OUT
                      );
                    }}
                  >
                    Check it out!
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </TrackVisibility>
          <TrackVisibility
            onVisible={async () =>
              logAnalyticsMilesAndPointsPageSectionView('disclosures')
            }
          >
            <IonRow
              style={{
                justifyContent: 'center',
                width: '100%',
                marginBottom: '8em',
                display: 'flex',
                padding: '0 2em',
              }}
              id="disclosures"
            >
              <p
                style={{
                  fontFamily: 'nexa',
                  margin: 'auto',
                  lineHeight: '2em',
                  maxWidth: 1000,
                  marginBottom: '2em',
                }}
              >
                <b>Advertiser disclosure:</b> &nbsp;FareDrop is part of an
                affiliate sales network and receives compensation for sending
                traffic to partner sites, such as CreditCards.com. This
                compensation may impact how and where links appear. FareDrop
                does not include all financial companies or all available
                financial offers.
              </p>
              <p
                style={{
                  fontFamily: 'nexa',
                  margin: 'auto',
                  lineHeight: '2em',
                  maxWidth: 1000,
                }}
              >
                <b>Editorial disclosure:</b> &nbsp;Opinions expressed on
                FareDrop are author&apos;s alone, not those of any bank, credit
                card issuer, hotel, airline, or other entity. This content has
                not been reviewed, approved or otherwise endorsed by any of the
                entities included within the post.
              </p>
            </IonRow>
          </TrackVisibility>
        </IonRow>
        <Footer bgColor="primary" isVisible={true} />
      </IonContent>
    </IonPage>
  );
};

export default MilesAndPoints;
