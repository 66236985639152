import useSWR, { SWRConfiguration } from 'swr';

import {
  AirportQuery,
  AirportDesignation,
  Airport,
} from '@faredrop/graphql-sdk';
import useAnalytics from './analytics';
import useInactiveAirports from './useInactiveAirports';
import useFareDropPublicApiClient from './faredropPublicApiClient';

export type FrontendAirport = AirportQuery['airport'];

export interface AirportsValue {
  airports?: FrontendAirport[];
  destinations?: FrontendAirport[];
  origins?: FrontendAirport[];
  getAirport: (airportIATA: string) => FrontendAirport | undefined;
  getAirportOrSubAirport: (airportIATA?: string) => FrontendAirport | undefined;
  getAirportOrSubAirportWithBackendFallback: (
    airportIATA: string
  ) => Promise<FrontendAirport | undefined>;
}

const useAirports = (
  swrOptions: SWRConfiguration = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    refreshInterval: 0,
  }
): AirportsValue => {
  const { client } = useFareDropPublicApiClient();
  const { logAnalyticsError } = useAnalytics();
  const { getInactiveAirport } = useInactiveAirports();

  const airportsKey = () => [
    'ALL_Airports',
    process.env.REACT_APP_VERSION ?? '',
  ];

  const requestKey = airportsKey();

  const { data } = useSWR(
    requestKey,
    async () => {
      try {
        // Fetch all airports
        const airports = (await client.airports()).data.airports;

        const allAirports = [...airports.origins, ...airports.destinations];

        return allAirports as FrontendAirport[];
      } catch (error) {
        await logAnalyticsError('getAllAirports', error as Error);
        throw error;
      }
    },
    swrOptions
  );

  const getAirport = (airportIATA: string): FrontendAirport | undefined => {
    if (!data) return undefined;
    return (data as FrontendAirport[]).find(
      (airport) => airport.iata === airportIATA
    );
  };

  const getAirportOrSubAirport = (
    airportIATA?: string
  ): FrontendAirport | undefined => {
    if (!airportIATA || !data) return undefined;
    let airport = getAirport(airportIATA);
    if (!airport) {
      for (const a of data as FrontendAirport[]) {
        const subAirport = a.subAirports?.find((s) => s.iata === airportIATA);
        if (subAirport?.iata) {
          airport = {
            ...subAirport,
            __typename: 'Airport',
            iata: subAirport.iata,
          };
        }
      }
    }

    return airport;
  };

  const getAirportOrSubAirportWithBackendFallback = async (
    airportIATA: string
  ) => {
    let airport = getAirportOrSubAirport(airportIATA);
    if (!airport) {
      airport = await getInactiveAirport(airportIATA);
    }
    return airport;
  };

  return {
    airports: data,
    destinations: ((data as Airport[]) ?? []).filter(
      (airport) =>
        airport.isDestinationOrEntity === AirportDesignation.IsDestination
    ),
    origins: ((data as Airport[]) ?? []).filter(
      (airport) => airport.isOrigin === AirportDesignation.IsOrigin
    ),
    getAirport,
    getAirportOrSubAirport,
    getAirportOrSubAirportWithBackendFallback,
  };
};

export default useAirports;
