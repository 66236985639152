import useSWRImmutable from 'swr/immutable';

import { SubscriptionStatus } from '@faredrop/graphql-sdk';

import useFareDropApiClient from './faredropApiClient';
import usePresentToast from './presentToast';
import useFareDropPublicApiClient from './faredropPublicApiClient';
import useAuth from './auth';
import useUser from './user';

const useDealDetails = (search?: string) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const userState = useUser();
  const { client: authenticatedClient } = useFareDropApiClient();
  const { client: unauthenticatedClient } = useFareDropPublicApiClient();
  const { presentError } = usePresentToast();

  const dealDetailsKey = (
    search?: string,
    idCognito?: string,
    idStripePlan?: string,
    subscriptionStatus?: string
  ) => {
    if (
      !search ||
      !isInitialized ||
      (isAuthenticated && userState.isInitializing)
    ) {
      return null;
    }

    const key = [
      'DEAL_DETAILS',
      process.env.REACT_APP_VERSION ?? '',
      search,
      idCognito ?? '',
      idStripePlan ?? '',
      subscriptionStatus ?? '',
    ];

    return key;
  };

  const requestKey = dealDetailsKey(
    search,
    userState.user?.profile.id ?? undefined,
    userState.user?.billing.idStripePlan ?? undefined,
    userState.user?.billing.subscriptionStatus ?? undefined
  );

  const { data, error, isValidating } = useSWRImmutable(
    requestKey,
    async (
      _scopedPrefix,
      _scopedVersion,
      scopedSearch,
      _scopedIdCognito,
      _scopedIdStripePlan,
      _subscriptionStatus
    ) => {
      try {
        // The dealDetails endpoint is exposed from both the authenticated and unauthenticated APIs. The unauthenticated API will return less details
        const client =
          isAuthenticated &&
          userState.user?.billing.subscriptionStatus ===
            SubscriptionStatus.Active
            ? authenticatedClient
            : unauthenticatedClient;
        return (
          await client.dealDetails({
            search: scopedSearch,
          })
        ).data.dealDetails;
      } catch (error) {
        const errorMessage = (error as Error)?.message.toLowerCase();
        if (errorMessage.includes('not found')) {
          return undefined;
        } else if (errorMessage.includes('access denied')) {
          presentError('Access Denied - Please upgrade to view this deal');
          return undefined;
        } else {
          throw error;
        }
      }
    }
  );

  return {
    dealDetails: data,
    isInitializing: !data && !error,
    isLoading: isValidating,
    error,
  };
};

export default useDealDetails;
