import { CSSProperties, Fragment } from 'react';
import {
  OptionsObject,
  SnackbarKey,
  useSnackbar,
  VariantType,
} from 'notistack';
import Button from '@mui/material/Button';
import { useDevice } from './useDevice';

export interface PresentOption {
  title: string;
  action?: (key: SnackbarKey) => Promise<void>;
  style?: CSSProperties;
}

export interface PresentToastValue {
  presentError: (message?: string) => void;
  presentWarning: (message: string) => void;
  presentSuccess: (message: string) => void;
  presentOptions: (
    message: string,
    options: PresentOption[],
    snackbarOptions?: OptionsObject
  ) => void;
  dismiss: (key: SnackbarKey) => void;
}

const usePresentToast = (): PresentToastValue => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isApp } = useDevice();

  const present = (variant: VariantType, message: string) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      preventDuplicate: true,
      variant,
    });
  };

  const presentError = (message?: string) => {
    const presentMessage =
      message ??
      (isApp
        ? 'Error: Please try again'
        : 'Error: Please refresh and try again');

    present('error', presentMessage);
  };

  const presentWarning = (message: string) => {
    present('warning', message);
  };

  const presentSuccess = (message: string) => {
    present('success', message);
  };

  const presentOptions = (
    message: string,
    options: PresentOption[],
    snackbarOptions?: OptionsObject
  ) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      preventDuplicate: true,
      persist: true,
      variant: 'info',
      action: (key) => (
        <Fragment>
          {options.map((option) => {
            return (
              <Button
                key={option.title}
                size="small"
                onClick={() => option.action && option.action(key)}
                style={{ color: 'white', ...option.style }}
              >
                {option.title}
              </Button>
            );
          })}
        </Fragment>
      ),
      ...snackbarOptions,
    });
  };

  return {
    presentError,
    presentWarning,
    presentSuccess,
    presentOptions,
    dismiss: closeSnackbar,
  };
};

export default usePresentToast;
