import { ClientError } from 'graphql-request/dist/types';
import moment from 'moment';

export const getMonthFromTS = (ts: string) => {
  // In the form: MM-DD-YYYY
  // We could bring in momentJS here but since this is the only place where we are using it,
  // we might as well just work with the native Date library
  return moment(ts).format('MMMM, YYYY');
};

export const formatRegion = (regionName: string) => {
  return regionName
    .match(/[A-Z][a-z]+/g)
    ?.join(' ')
    .replace('Oceania', 'Australia / Oceania');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseError = (error: any): string[] => {
  const messages = [] as string[];

  if (error instanceof ClientError) {
    if (error.response.errors) {
      error.response.errors.forEach((e) => messages.push(e.message));
    } else messages.push(error.response.message);
  } else if (error instanceof Error) {
    messages.push(error.message);
  } else {
    messages.push(error);
  }

  return messages;
};

export const stripTrailingSlash = (str: string) => {
  if (str.slice(-1) === '/') {
    return str.slice(0, str.length - 1);
  }
  return str;
};

export function getFullHostUrl() {
  const protocol = window.location.protocol;
  const host = window.location.host;
  return `${protocol}//${host}`;
}
