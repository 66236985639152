import { IonIcon, IonSpinner } from '@ionic/react';
import { chevronBackCircleOutline, chevronForwardCircle } from 'ionicons/icons';
import { useState } from 'react';
import { SwiperRef } from 'swiper/react';

import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  onRefresh?: () => void;
  nextSlide: (ref: React.RefObject<SwiperRef>) => void;
  previousSlide: (ref: React.RefObject<SwiperRef>) => void;
  slidesRef: React.RefObject<SwiperRef>;
  hideArrows?: boolean;
  style?: React.CSSProperties;
}

const CarouselArrows: React.FC<ContainerProps> = ({
  onRefresh,
  nextSlide,
  previousSlide,
  slidesRef,
  hideArrows,
  style,
}) => {
  const { isExtraSmallScreenSize } = useDevice();
  const [refreshing, setRefreshing] = useState(false);

  return (
    <>
      {!isExtraSmallScreenSize && (
        <div
          style={{
            display: 'inline-flex',
            marginLeft: 'auto',
            marginRight: '8%',
            ...style,
          }}
        >
          {onRefresh && refreshing && (
            <IonSpinner
              name="crescent"
              style={{ margin: '0 2em' }}
              color="primary"
            />
          )}
          {onRefresh && !refreshing && (
            <a
              style={{ margin: '.5em 1em' }}
              onClick={async () => {
                setRefreshing(true);
                await onRefresh();
                setRefreshing(false);
              }}
            >
              Refresh
            </a>
          )}
          {!hideArrows && (
            <>
              <div
                style={{ marginRight: '.5em' }}
                onClick={() => {
                  previousSlide(slidesRef);
                }}
              >
                <IonIcon
                  size="large"
                  icon={chevronBackCircleOutline}
                  className="arrow-icon"
                />
              </div>
              <div
                style={{ marginRight: '.5em' }}
                onClick={() => {
                  nextSlide(slidesRef);
                }}
              >
                <IonIcon
                  size="large"
                  icon={chevronForwardCircle}
                  className="icon-fill arrow-icon"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CarouselArrows;
