import { IonCol, IonContent, IonIcon, IonModal, IonRow } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { ReactNode, useEffect, useRef } from 'react';

import Modal from './MobileModal';
import { useDevice } from '../hooks/useDevice';
import FabButton from './FabButton';

interface ResponsiveModalProps {
  title?: string;
  active: boolean;
  onClose: () => void;
  children: ReactNode;
}

const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  title,
  active,
  onClose,
  children,
}) => {
  const { isSmallScreenSizeOrSmaller, isMediumScreenSizeOrSmaller } =
    useDevice();

  const modalRef = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    if (active) {
      modalRef.current?.present().catch((error) => {
        console.warn('Failed to present modal', title, error);
        throw error; // Show uh-oh screen
      });
    } else {
      modalRef.current?.dismiss().catch((error) => {
        console.warn('Failed to dismiss modal', title, error);
        throw error; // Show uh-oh screen
      });
    }
  }, [active]);

  return (
    <>
      {!isMediumScreenSizeOrSmaller && (
        <Modal
          active={active}
          modalListener={onClose}
          size={isSmallScreenSizeOrSmaller ? '12' : '6'}
          darkBg={true}
          showAsFullPage={isSmallScreenSizeOrSmaller}
        >
          <IonRow
            style={{ width: '100%', height: '100%', paddingBottom: '1.5em' }}
          >
            <IonCol size="12" style={{ padding: '4em' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                {title && (
                  <IonRow style={{ marginBottom: '1em' }}>
                    <h1>{title}</h1>
                  </IonRow>
                )}
                {children}
              </div>
            </IonCol>
          </IonRow>
        </Modal>
      )}
      {isMediumScreenSizeOrSmaller && (
        <IonModal ref={modalRef} showBackdrop={true} style={{ height: '100%' }}>
          <IonContent>
            <IonRow>
              <FabButton
                onClick={async () => {
                  await modalRef.current?.dismiss();
                  onClose();
                }}
                top="1.5em"
              >
                <IonIcon
                  style={{ margin: '9px 11px 11px 11px' }}
                  icon={closeOutline}
                />
              </FabButton>
              <IonCol
                style={{
                  background:
                    'linear-gradient(180deg, #F8F6F3 0%, #F3F0EB 103.76%)',
                  paddingBottom: '2em',
                  position: 'absolute',
                  top: '100px',
                  height: '100%',
                  bottom: 0,
                }}
              >
                <IonRow className="row-vertical-align">
                  <IonCol
                    size="12"
                    style={{
                      background: 'white',
                      position: 'absolute',
                      borderRadius: 15,
                      padding: '30px 24px',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ justifyContent: 'center' }}>
                      {title && (
                        <IonRow style={{ marginBottom: '1em' }}>
                          <h1>{title}</h1>
                        </IonRow>
                      )}
                      {children}
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default ResponsiveModal;
