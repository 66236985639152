import { IonRow } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { FirebasePushID } from '@faredrop/firebase-push-id';
import { Deal } from '@faredrop/graphql-sdk';

import './../../theme/util.css';
import './../../theme/DealDetails.css';
import { useDevice } from '../../hooks/useDevice';
import {
  HIDE_MEMBERSHIP_LINKS,
  QUERY_PARAM_HIDE_EXPIRY_MESSAGING,
} from '../../utilities/constants';
import { isDealExpired } from '../../utilities/deals-utilities';

interface ContainerProps {
  deal?: Deal;
  noUser: boolean;
  onSignUpClick: () => void;
}

const DealDetailsNotice: React.FC<ContainerProps> = ({
  deal,
  noUser,
  onSignUpClick,
}) => {
  const {
    isSmallScreenSize,
    isSmallScreenSizeOrSmaller,
    isMediumScreenSizeOrSmaller,
  } = useDevice();
  const [hideExpiryMessaging, setHideExpiryMessaging] = useState(false);

  const dealMoment = deal
    ? FirebasePushID.getMoment(deal.idAirfareSource)
    : undefined;

  const dealIsExpired = isDealExpired(deal) && !hideExpiryMessaging;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has(QUERY_PARAM_HIDE_EXPIRY_MESSAGING)) {
      setHideExpiryMessaging(true);
    }
  }, []);

  return (
    <IonRow
      style={{
        marginBottom: isMediumScreenSizeOrSmaller
          ? isSmallScreenSize
            ? undefined
            : '1em'
          : '2em',
        marginTop: isMediumScreenSizeOrSmaller
          ? isSmallScreenSizeOrSmaller
            ? undefined
            : '-2.5em'
          : '-1em',
        marginLeft: '1em',
        marginRight: '1em',
        display: 'flex',
        flex: 1,
      }}
    >
      <IonRow
        style={{
          display: 'flex',
          justifyContent: isMediumScreenSizeOrSmaller ? 'center' : undefined,
          textAlign: 'center',
          flex: 1,
          paddingLeft: '2em',
          paddingRight: '2em',
          backgroundColor: dealIsExpired
            ? 'var(--ion-color-error-bg)'
            : 'var(--ion-color-success-bg)',
          borderRadius: '10px',
          lineHeight: '1.25em',
        }}
      >
        {dealIsExpired ? (
          <p>
            Expiry Notice: This deal is {moment().diff(dealMoment, 'days')} days
            old and may not be available any more.{' '}
            {noUser && !HIDE_MEMBERSHIP_LINKS ? (
              <>
                &nbsp;
                <span
                  style={{
                    textDecoration: 'underline',
                    color: 'var(--ion-color-primary)',
                    cursor: 'pointer',
                  }}
                  onClick={onSignUpClick}
                >
                  Sign up
                </span>
                &nbsp;to see more deals like this.
              </>
            ) : (
              ''
            )}
          </p>
        ) : !HIDE_MEMBERSHIP_LINKS ? (
          <p>
            Want to see more deals?&nbsp;
            <span
              style={{
                textDecoration: 'underline',
                color: 'var(--ion-color-primary)',
                cursor: 'pointer',
              }}
              onClick={onSignUpClick}
            >
              Sign up
            </span>
            &nbsp; for FareDrop to be notified when we find deals like this!
          </p>
        ) : (
          <></>
        )}
      </IonRow>
    </IonRow>
  );
};

export default DealDetailsNotice;
