import { IonCol, IonRow } from '@ionic/react';
import { useState } from 'react';

import { TravelPortal } from '@faredrop/graphql-sdk';

import PointsEstimateModal from '../components/PointsEstimateModal';
import PointEstimatePill from '../components/PointEstimatePill';
import useAnalytics from '../hooks/analytics';
import { useDevice } from '../hooks/useDevice';

interface ContainerProps {
  travelPortals?: TravelPortal[];
  price: number;
}

const PointEstimatePills: React.FC<ContainerProps> = ({
  travelPortals,
  price,
}) => {
  const { logAnalyticsMilesAndPointsClick } = useAnalytics();
  const {
    isScreenLargerThanSize,
    isLargeScreenSizeOrSmaller,
    isExtraLargeScreenSize,
    isMediumScreenSizeOrSmaller,
    isExtraSmallScreenSize,
  } = useDevice();

  const [travelPortal, setTravelPortal] = useState<TravelPortal>();

  const onPillClick = async (travelPortal: TravelPortal) => {
    setTravelPortal(travelPortal);
    await logAnalyticsMilesAndPointsClick(travelPortal.creditCardIssuer);
  };

  return (
    <>
      <IonRow
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: isMediumScreenSizeOrSmaller
            ? isExtraSmallScreenSize
              ? '-1em'
              : undefined
            : '.5em',
          flex: 1,
        }}
      >
        {!!travelPortals?.length && (
          <>
            {isLargeScreenSizeOrSmaller && (
              <IonRow
                style={{
                  fontSize: isExtraSmallScreenSize ? '18px' : '20px',
                  lineHeight: '1.1em',
                  textAlign: 'center',
                  fontFamily: 'nexa',
                  marginBottom: '.5em',
                  marginTop: isMediumScreenSizeOrSmaller ? '1em' : undefined,
                }}
              >
                Miles And Points Estimates
              </IonRow>
            )}
            <IonRow
              style={{
                display: 'flex',
                width: isScreenLargerThanSize(1600) ? '80%' : '100%',
              }}
            >
              {isExtraLargeScreenSize && (
                <IonCol
                  size="auto"
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '22px',
                    lineHeight: '1.1em',
                    textAlign: 'center',
                    fontFamily: 'nexa',
                  }}
                >
                  Miles and Points Estimates
                </IonCol>
              )}
              <IonCol
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: travelPortals.length === 1 ? '15px' : undefined,
                }}
              >
                <IonRow
                  className={
                    travelPortals.length > 1 ? 'ion-justify-content-evenly' : ''
                  }
                >
                  {travelPortals.map((travelPortal, index) => {
                    return isExtraSmallScreenSize &&
                      travelPortals.length > 2 ? (
                      <IonCol
                        key={travelPortal.creditCardIssuer}
                        style={{
                          padding: 0,
                          paddingRight: index === 0 ? '5px' : undefined,
                          paddingBottom: index === 1 ? '5px' : undefined,
                          display: 'flex',
                          justifyContent:
                            index === 0
                              ? 'flex-end'
                              : index === 1
                              ? 'flex-start'
                              : 'center',
                          marginTop: index === 2 ? '.5em' : undefined,
                        }}
                        sizeXs="6"
                      >
                        <IonRow style={{ padding: 0 }}>
                          <PointEstimatePill
                            travelPortal={travelPortal}
                            price={price}
                            onClick={async () =>
                              await onPillClick(travelPortal)
                            }
                          />
                        </IonRow>
                      </IonCol>
                    ) : (
                      <PointEstimatePill
                        key={travelPortal.creditCardIssuer}
                        travelPortal={travelPortal}
                        price={price}
                        onClick={async () => await onPillClick(travelPortal)}
                      />
                    );
                  })}
                </IonRow>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonRow>
      <PointsEstimateModal
        travelPortal={travelPortal}
        price={price}
        onDismiss={() => {
          setTravelPortal(undefined);
        }}
      />
    </>
  );
};

export default PointEstimatePills;
