import { FareDropRole } from '@faredrop/graphql-sdk';
import { AllowedPath } from '@faredrop/types';
import {
  IonIcon,
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from '@ionic/react';
import {
  airplane,
  person,
  newspaperOutline,
  giftOutline,
} from 'ionicons/icons';
import { useLocation } from 'react-router';
import useAuth from '../hooks/auth';
import { useDevice } from '../hooks/useDevice';
import usePushNotifications from '../hooks/usePushNotifications';
import useUser from '../hooks/user';
import useTabs from '../hooks/useTabs';
import { DEVICE_TYPES } from '../types/types';
import './../theme/util.css';
import { Tab } from '../contexts/tabContext';

enum TAB_BAR_MODES {
  IOS = 'ios',
  MD = 'md',
}

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
}

const FareDropTabs: React.FC<ContainerProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const userState = useUser();
  const { selectedTab } = useTabs();
  const { unreadNotifications } = usePushNotifications();
  const location = useLocation();
  const { isApp, isExtraLargeScreenSize } = useDevice();
  const tabsShown =
    (isApp || !isExtraLargeScreenSize) &&
    isAuthenticated &&
    !(
      userState.user?.profile.roles.length === 1 &&
      userState.user?.profile.roles.includes(FareDropRole.Profile)
    ) &&
    location.pathname !== AllowedPath.GET_STARTED_WELCOME_FREE &&
    location.pathname !== AllowedPath.GET_STARTED_WELCOME_PAID &&
    location.pathname !== AllowedPath.REDEEM_SUCCESS;

  const tabBarMode =
    isPlatform(DEVICE_TYPES.IPHONE) || isPlatform(DEVICE_TYPES.IPAD)
      ? TAB_BAR_MODES.IOS
      : TAB_BAR_MODES.MD;

  return (
    <IonTabs>
      {children}
      {!isExtraLargeScreenSize && tabsShown ? (
        <IonTabBar
          key="unhidden"
          slot="bottom"
          className="tab-bar"
          mode={tabBarMode}
          selectedTab={selectedTab}
          style={{ paddingBottom: '1em' }}
        >
          <IonTabButton
            tab={Tab.DEALS}
            href={AllowedPath.DEALS}
            selected={selectedTab === Tab.DEALS}
          >
            <IonIcon src="./assets/icon.svg" />
            <IonLabel style={{ marginTop: 5 }}>FareDrops</IonLabel>
            {unreadNotifications > 0 && (
              <div
                color="primary"
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 10,
                  position: 'absolute',
                  backgroundColor: 'var(--ion-color-primary)',
                  marginLeft: 15,
                  top: 10,
                }}
              ></div>
            )}
          </IonTabButton>
          {isApp && (
            <IonTabButton
              tab={Tab.DAILY_DROP}
              href="/daily-drop"
              selected={selectedTab === Tab.DAILY_DROP}
            >
              <IonIcon icon={newspaperOutline} />
              <IonLabel style={{ marginTop: 5 }}>Daily Drop</IonLabel>
            </IonTabButton>
          )}
          <IonTabButton
            tab={Tab.PERKS}
            href="/perks"
            selected={selectedTab === Tab.PERKS}
          >
            <IonIcon icon={giftOutline} />
            <IonLabel style={{ marginTop: 5 }}>Perks</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab={Tab.SETTINGS}
            href="/settings/departure-airports"
            selected={selectedTab === Tab.SETTINGS}
          >
            <IonIcon icon={airplane} style={{ transform: 'rotate(-45deg)' }} />
            <IonLabel style={{ marginTop: 5 }}>Settings</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab={Tab.ACCOUNT}
            href="/account/profile"
            selected={selectedTab === Tab.ACCOUNT}
          >
            <IonIcon icon={person} />
            <IonLabel style={{ marginTop: 5 }}>Account</IonLabel>
          </IonTabButton>
        </IonTabBar>
      ) : (
        <IonTabBar key="hidden"></IonTabBar>
      )}
    </IonTabs>
  );
};

export default FareDropTabs;
