import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonCol, IonContent, IonPage, IonRow, IonSpinner } from '@ionic/react';
import moment from 'moment';
import { $enum } from 'ts-enum-util';

import { AllowedPath, FareDropPlan, STRIPE_PLAN_ID } from '@faredrop/types';
import {
  ChangeSubscriptionType,
  FareDropPlan as GQLFareDropPlan,
  FareDropRole as GQLFareDropRole,
  SubscriptionPlan,
  SubscriptionStatus,
} from '@faredrop/graphql-sdk';

import ChoosePlan, {
  IChangeSubscriptionPlanPreflight,
} from '../components/ChoosePlan';
import DesktopHeader from '../components/DesktopHeader';
import MobileHeader from '../components/MobileHeader';
import NoInternetConnectionModal from '../components/NoInternetConnectionModal';
import { useDevice } from '../hooks/useDevice';
import useUser from '../hooks/user';
import './../theme/LoginPage.css';
import './../theme/util.css';
import useAnalytics from '../hooks/analytics';
import useAuth from '../hooks/auth';
import useHistoryWithStickyParams from '../hooks/historyWithStickyParams';
import Loading from '../components/Loading';
import usePresentToast from '../hooks/presentToast';
import useStripeHook from '../hooks/useStripe';
import usePlans from '../hooks/usePlans';
import {
  PROMOTION_IS_SHOPIFY_INTEGRATION,
  isLimitedPlan,
  isSubscriptionActive,
  stripePlanIDByFareDropPlan,
  stripePlanIDToFareDropPlans,
} from '@faredrop/utilities';
import PaymentModal from '../components/PaymentModal';
import ChangeSubscriptionModal from '../components/ChangeSubscriptionModal';
import { SetupIntentReason } from '../components/PaymentForm';
import { useCodeQueryParam } from '../hooks/useCodeQueryParam';
import useChurnKey from '../hooks/useChurnKey';
import {
  isFrontendSubscriptionPromotion,
  isSubscriptionDowngrade,
} from '../utilities/plans-utilities';
import useLogError from '../hooks/logError';
import useHandleNewSubscription from '../hooks/handleNewSubscription';
import PromotionFreebieModal from '../components/PromotionFreebieModal';

const ChoosePlanPage: React.FC = () => {
  const { isLargeScreenSizeOrSmaller, isSmallScreenSizeOrSmaller, isApp } =
    useDevice();
  const { logAnalyticsOnboardingEngagement } = useAnalytics();
  const { goWithStickyParamsLocation, goWithStickyParamsPath } =
    useHistoryWithStickyParams();
  const userState = useUser();
  const history = useHistory();
  const { isAuthenticated, refreshSession } = useAuth();
  const noInternet = userState.timeout;
  const [loading, setLoading] = useState(false);
  const { presentSuccess, presentError, presentWarning } = usePresentToast();
  const {
    fetchSetupIntentClientSecret,
    isCouponValid,
    redeemSubscription,
    changeSubscription,
    convertFreeTrialSubscription,
    releaseSchedule,
    changeSubscriptionPreFlight,
    createUpgradeCheckoutSession,
  } = useStripeHook();
  const {
    couponQueryParam,
    giftCodeQueryParam,
    removeCouponQueryParam,
    couponOrGiftCodeQueryParam,
    couponsAreInitialized,
  } = useCodeQueryParam();
  const { showChurnKeyModal, hideChurnKeyModal } = useChurnKey();
  const { logError } = useLogError();
  const { handleNewSubscription, hidePlansParam } = useHandleNewSubscription();

  const [coupon, setCoupon] = useState<string>();
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState('');
  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >();
  const [bannerText, setBannerText] = useState<string | undefined>(undefined);
  const [
    showConfirmChangeSubscriptionModal,
    setShowConfirmChangeSubscriptionModal,
  ] = useState(false);
  const [navigateTo, setNavigateTo] = useState<string>();
  const [preflightPlans, setPreflightPlans] =
    useState<IChangeSubscriptionPlanPreflight[]>();

  const { plans: unsortedPlans, isInitializing } = usePlans(
    couponOrGiftCodeQueryParam
  );
  const plans: SubscriptionPlan[] = [];
  if (unsortedPlans) {
    if (
      isSmallScreenSizeOrSmaller &&
      (!userState.user?.billing.idStripePlan ||
        !isSubscriptionActive(
          userState.user?.billing.subscriptionStatus?.toLowerCase()
        ))
    ) {
      plans.push(...[...unsortedPlans].reverse());
    } else {
      plans.push(...unsortedPlans);
    }
  }

  const [promotionFreebieModalPlan, setPromotionFreebieModalPlan] =
    useState<GQLFareDropPlan>();

  const hasActivePlan =
    isAuthenticated &&
    !!userState.user?.billing.idStripeCustomer &&
    !!userState.user.billing.idStripePlan &&
    // There is a weird state bug where user's are "active", but don't have a stripe subscription, so we are also checking other stripe attributes
    !!userState.user?.billing?.subscriptionStatus &&
    isSubscriptionActive(
      userState.user.billing.subscriptionStatus.toLowerCase()
    );
  const isLimited = isLimitedPlan(userState.user?.billing.idStripePlan);

  let currentPlan: FareDropPlan | undefined = undefined;
  let currentSubscriptionPlan: SubscriptionPlan | undefined = undefined;
  if (hasActivePlan) {
    const fareDropPlans = userState.user?.billing.idStripePlan
      ? stripePlanIDToFareDropPlans(userState.user.billing.idStripePlan)
      : undefined;
    currentPlan =
      fareDropPlans && hasActivePlan
        ? fareDropPlans[fareDropPlans.length - 1]
        : undefined;

    if (currentPlan) {
      currentSubscriptionPlan = plans?.find(
        (p) => p.planType === $enum(GQLFareDropPlan).asValueOrThrow(currentPlan)
      );
    }
  }

  const getCountdownClock = (expiresAt: number | undefined) => {
    const diff = moment(expiresAt).diff(moment(), 'seconds');

    return diff > 0
      ? `${Math.floor(diff / 60 / 60)} hours ${Math.floor(
          (diff % (60 * 60)) / 60
        )} minutes ${diff % 60} seconds`
      : 'Already Expired';
  };

  const goBackToHome = () => {
    if (
      isAuthenticated &&
      (userState.user?.profile.roles.includes(GQLFareDropRole.Limited) ||
        userState.user?.profile.roles.includes(GQLFareDropRole.Global) ||
        userState.user?.profile.roles.includes(GQLFareDropRole.Pro))
    ) {
      goWithStickyParamsLocation({
        pathname: AllowedPath.DEALS,
        search: `?${new URLSearchParams(window.location.search).toString()}`,
      });
    } else {
      history.push({ pathname: '/logout' });
    }
  };

  const handleUpgradeSession = async (subscriptionPlan: SubscriptionPlan) => {
    await createUpgradeCheckoutSession(
      subscriptionPlan.planType,
      'deals',
      [
        { key: 'refreshAccount', value: 'true' },
        { key: 'newPlan', value: subscriptionPlan.id },
      ],
      'plans',
      undefined,
      coupon
    );
  };

  useEffect(() => {
    if (couponsAreInitialized) {
      // If the user doesn't have a stripe plan and they have a gift code, automatically redeem the gift and redirect them to welcome page
      if (!userState.user?.billing.idStripePlan && giftCodeQueryParam) {
        setLoading(true);
        redeemSubscription(giftCodeQueryParam)
          .then(() => {
            goWithStickyParamsLocation({
              pathname: AllowedPath.GET_STARTED_WELCOME_PAID,
            });
          })
          .catch(() => {
            presentWarning('Invalid Gift Code. Please contact support.');
            setLoading(false);
          });
      } else if (couponQueryParam) {
        isCouponValid(couponQueryParam)
          .then((response) => {
            if (response && response.isValid) {
              setCoupon(couponQueryParam);
              setBannerText(
                response && response.expiresAt
                  ? `This coupon expires in: ${getCountdownClock(
                      response.expiresAt ?? undefined
                    )}`
                  : undefined
              );

              const intervalId = setInterval(() => {
                setBannerText(
                  response && response.expiresAt
                    ? `This coupon expires in: ${getCountdownClock(
                        response.expiresAt ?? undefined
                      )}`
                    : undefined
                );
              }, 1000);

              return () => clearInterval(intervalId);
            } else {
              presentWarning('Invalid coupon code.');

              setCoupon(undefined);
              removeCouponQueryParam();

              return;
            }
          })
          .catch(() =>
            presentWarning(
              'Failed to fetch coupon details - please refresh the page'
            )
          );
      }
    }
  }, [couponsAreInitialized]);

  // Stupid hack to support native app (directly navigating to another page causes the modal to freeze)
  useEffect(() => {
    if (navigateTo) {
      goWithStickyParamsPath(navigateTo);
      setNavigateTo(undefined);
    }
  }, [navigateTo]);

  const updatePreflightPlans = async () => {
    const preflightPlans = await changeSubscriptionPreFlight(
      undefined,
      couponOrGiftCodeQueryParam
    );

    for (const preflight of preflightPlans) {
      if (preflight.couponErrorMessage) {
        presentError(preflight.couponErrorMessage);

        // When retrieving preflight plans, if there is a coupon error message, then all the preflights will have that error message
        // We would need to change the return type to only return this information once, and since that is a pain for native, let's just deal with duplicate error messages
        // In this scenario, break after the first error message
        break;
      }
    }

    setPreflightPlans(preflightPlans);
  };

  useEffect(() => {
    if (couponsAreInitialized && hasActivePlan) {
      updatePreflightPlans().catch(() =>
        presentError(
          'Failed to update plan information - please refresh the page'
        )
      ); // Async - Fire and forget
    }
  }, [couponsAreInitialized, hasActivePlan]);

  const handleFreeTrial = async (plan: SubscriptionPlan) => {
    setSelectedPlan(plan);

    const clientSecret = await fetchSetupIntentClientSecret();
    setSetupIntentClientSecret(clientSecret);
  };

  let title = 'Choose Your Plan';
  if (hasActivePlan) title = 'Manage Membership';
  else if (
    userState.user?.billing.subscriptionStatus &&
    userState.user?.billing.subscriptionStatus === SubscriptionStatus.Canceled
  ) {
    title = 'Welcome back to FareDrop!';
  }

  return noInternet ? (
    <NoInternetConnectionModal />
  ) : loading ? (
    <Loading />
  ) : (
    <IonPage>
      {bannerText && (
        <IonRow
          className="header-promotion header-promotion-mobile no-pointer-on-hover"
          style={
            isApp
              ? {
                  backgroundColor: 'var(--ion-color-danger)',
                  height: 100,
                }
              : { backgroundColor: 'var(--ion-color-danger)' }
          }
        >
          <p style={isApp ? { marginTop: 50 } : {}}>{bannerText}</p>
        </IonRow>
      )}
      <IonRow
        style={{
          position: !bannerText ? 'absolute' : '',
          top: !bannerText ? 0 : '',
          zIndex: 100,
          backgroundColor: 'transparent',
          width: '100%',
          padding: '15px 40px',
        }}
      >
        {isLargeScreenSizeOrSmaller ? (
          <MobileHeader
            menuHidden={true}
            showBackToHome={promotionFreebieModalPlan == null}
            backToHomeListener={goBackToHome}
            backToHomeText={
              isAuthenticated &&
              !(
                userState.user?.profile.roles.length === 1 &&
                userState.user?.profile.roles.includes(GQLFareDropRole.Profile)
              )
                ? 'Back to Deals'
                : 'Back to Home'
            }
            bannerText={bannerText}
          />
        ) : (
          <DesktopHeader
            optionsHidden={true}
            ctaHidden={true}
            logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
            showBackToHome={promotionFreebieModalPlan == null}
            backToHomeListener={goBackToHome}
            backToHomeText={
              isAuthenticated &&
              userState.user?.profile.roles.includes(GQLFareDropRole.Limited)
                ? 'Back to Deals'
                : 'Back to Home'
            }
          />
        )}
      </IonRow>
      <IonContent className="login-page">
        <IonRow
          className="row-vertical-align"
          style={{
            padding: '6em 0em 0em 0em',
          }}
        >
          <IonCol sizeXs="12" sizeLg="8" sizeXl="12">
            <h1 className="title-font" style={{ textAlign: 'center' }}>
              {title}
            </h1>
            {isInitializing ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IonSpinner
                  name="crescent"
                  style={{
                    margin: 'auto',
                    marginTop: '1em',
                    width: '60px',
                    height: '60px',
                    opacity: 0.3,
                  }}
                  color="primary"
                />
              </div>
            ) : (
              <ChoosePlan
                plans={
                  plans?.filter((p) => !hidePlansParam?.includes(p.planType)) ??
                  []
                }
                preflightPlans={preflightPlans}
                disabledPlanType={currentPlan}
                disabledPlanButtonText="Current Plan"
                logAnalyticsEngagement={logAnalyticsOnboardingEngagement}
                onSubscriptionChange={async (plan: SubscriptionPlan) => {
                  // User is converting from free trial
                  if (
                    isAuthenticated &&
                    !userState.user?.billing.hasPaymentInfo &&
                    userState.user?.billing.subscriptionStatus ===
                      SubscriptionStatus.FreeTrial
                  ) {
                    await handleFreeTrial(plan);
                  }

                  // User is NOT subscribed
                  else if (!hasActivePlan) {
                    if (
                      isFrontendSubscriptionPromotion(isLimited) &&
                      PROMOTION_IS_SHOPIFY_INTEGRATION &&
                      plan.planType !== GQLFareDropPlan.Pro
                    ) {
                      setPromotionFreebieModalPlan(plan.planType);
                    } else {
                      await handleNewSubscription(plan, coupon);
                    }
                  }

                  // User is changing plan
                  else {
                    setSelectedPlan(plan);

                    const isDowngrade =
                      userState.user?.billing.idStripePlan && plan
                        ? isSubscriptionDowngrade(
                            $enum(STRIPE_PLAN_ID).asValueOrThrow(
                              userState.user.billing.idStripePlan
                            ),
                            plan.planType
                          )
                        : undefined;
                    if (isDowngrade) {
                      showChurnKeyModal(plan.planType, async () => {
                        setShowConfirmChangeSubscriptionModal(true);
                        hideChurnKeyModal();
                        return { message: '' }; // Hiding modal so message is not relevant (but it is required to make churnkey happy)
                      });
                    } else {
                      if (
                        isFrontendSubscriptionPromotion(isLimited) &&
                        PROMOTION_IS_SHOPIFY_INTEGRATION &&
                        plan.planType !== GQLFareDropPlan.Pro
                      ) {
                        setPromotionFreebieModalPlan(plan.planType);
                      } else {
                        await handleUpgradeSession(plan);
                      }
                    }
                  }
                }}
                onCancelSubscription={async () => {
                  try {
                    if (!currentPlan)
                      throw new Error('User is not subscribed to a plan');
                    if (!plans) throw new Error('No plans defined');
                    setSelectedPlan(currentSubscriptionPlan); // Our preflight request will provide cancel data if the current plan is provided
                    showChurnKeyModal(undefined, async () => {
                      setShowConfirmChangeSubscriptionModal(true);
                      hideChurnKeyModal();
                      return { message: '' }; // Hiding modal so message is not relevant (but it is required to make churnkey happy)
                    });
                  } catch (err) {
                    presentError(
                      'Failed to cancel subscription - please contact support at team@faredrop.com'
                    );
                    await logError(
                      'Error canceling subscription',
                      err && (err as Error).message
                        ? (err as Error).message
                        : 'Error or error message is undefined'
                    );
                  }
                }}
                onCancelSubscriptionSchedule={async (updatedPreflightPlans) => {
                  await releaseSchedule();
                  setPreflightPlans(updatedPreflightPlans);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonContent>
      {/* NOTE: Free trial conversion logic has not been tested after adding subscription upgrades */}
      <PaymentModal
        selectedPlan={selectedPlan}
        setupIntentReason={SetupIntentReason.FREE_TRIAL}
        clientSecret={setupIntentClientSecret}
        onSubmit={async (planId, setupIntentId) => {
          if (!setupIntentId)
            presentError(
              'Payment response is missing required data - contact FareDrop support: team@faredrop.com'
            );
          else {
            presentSuccess('Payment method accepted');
            convertFreeTrialSubscription(planId, setupIntentId, coupon)
              .then(() => {
                goWithStickyParamsPath(AllowedPath.DEALS);
              })
              .catch(() =>
                presentError(
                  'Failed to convert free trial subscription - please contact support: team@faredrop.com'
                )
              );
          }
        }}
        onClose={() => {
          setSetupIntentClientSecret('');
          setSelectedPlan(undefined);
        }}
      />
      <ChangeSubscriptionModal
        active={showConfirmChangeSubscriptionModal}
        newPlan={selectedPlan}
        onConfirm={async (
          nextPlan,
          changeSubscriptionType,
          idSetupIntent,
          confirmedCoupon
        ) => {
          if (!selectedPlan) {
            presentError('Please select a plan first');
          } else {
            try {
              const isImmediateCancellation =
                currentSubscriptionPlan?.planType === nextPlan &&
                changeSubscriptionType === ChangeSubscriptionType.Immediately;

              if (!currentSubscriptionPlan)
                throw new Error('Subscription not detected');

              const updatedUser =
                currentSubscriptionPlan.planType === nextPlan
                  ? await userState.cancelSubscription(changeSubscriptionType)
                  : isSubscriptionDowngrade(
                      currentSubscriptionPlan.id as STRIPE_PLAN_ID,
                      nextPlan
                    )
                  ? await changeSubscription(
                      nextPlan,
                      changeSubscriptionType,
                      idSetupIntent,
                      confirmedCoupon
                    )
                  : await createUpgradeCheckoutSession(
                      nextPlan,
                      'deals',
                      [
                        { key: 'refreshAccount', value: 'true' },
                        {
                          key: 'newPlan',
                          value: stripePlanIDByFareDropPlan(
                            $enum(FareDropPlan).asValueOrThrow(nextPlan)
                          ),
                        },
                      ],
                      'plans',
                      undefined,
                      confirmedCoupon
                    );

              if (updatedUser) {
                if (isImmediateCancellation) {
                  setPreflightPlans(undefined);
                }
                await Promise.all([
                  userState.refreshUser(updatedUser), // Update the dynamo user's billing information
                  refreshSession(), // Update the auth user's roles
                  isImmediateCancellation ? undefined : updatePreflightPlans(),
                ]);
              } else {
                return false; // Don't show confirmation step
              }
            } catch (error) {
              presentError(
                'Failed to update subscription. Please contact support: team@faredrop.com'
              );
            }
          }
          return true;
        }}
        onClose={() => {
          setShowConfirmChangeSubscriptionModal(false);
        }}
        onDone={() => {
          setNavigateTo(AllowedPath.DEALS); // Stupid hack to support native app
        }}
      />
      <PromotionFreebieModal
        selectedPlan={promotionFreebieModalPlan}
        setIsModalActive={() => {
          setPromotionFreebieModalPlan(undefined);
        }}
        onSubmit={async (subscriptionPlan) => {
          if (hasActivePlan) {
            await handleUpgradeSession(subscriptionPlan);
          } else {
            await handleNewSubscription(subscriptionPlan, coupon);
          }
        }}
      />
    </IonPage>
  );
};

export default ChoosePlanPage;
