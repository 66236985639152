import { Drivers, Storage } from '@ionic/storage';

// Referenced from: https://github.com/alexrindone/nubeer-flight-builder/blob/b8d6c6e309d98593374ebd2d5897022829bc4578/src/services/StorageService.tsx
// Thank you for your service :)

// TODO: StorageService is currently only being used on web for VerifyEmailPage
// See https://github.com/ionic-team/ionic-storage for extra implementation steps on native
class StorageService {
  private storage;
  private static instance: StorageService;

  private constructor() {
    this.storage = new Storage({
      name: 'localStorage',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB],
    });
    this.storage
      .create()
      .catch((error) => console.warn('Failed to create storage object', error));
  }

  public static getInstance(): StorageService {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  }

  async setData(key: string, value: unknown) {
    const res = await this.storage.set(key, value);
    return res;
  }

  async getData(key: string) {
    const keyVal = await this.storage.get(key);
    return keyVal;
  }

  async removeData(key: string) {
    const res = await this.storage.remove(key);
    return res;
  }

  async clearData() {
    return await this.storage.clear();
  }
}

export default StorageService;
