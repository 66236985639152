import { Capacitor } from '@capacitor/core';

import { FareDropPlan } from '@faredrop/graphql-sdk';
import { PROMOTION_IS_SHOPIFY_INTEGRATION } from '@faredrop/utilities';

import { getPlanName } from './plans-utilities';

// Enable to hide membership links when we get caught exposing app purchases
export const HIDE_MEMBERSHIP_LINKS = Capacitor.getPlatform() === 'ios';
export const HIDE_UPGRADE_MESSAGE =
  'Please visit the FareDrop website to manage your plan.';

// For desktop, setting the slidesPerView to width/CARD_WIDTH, the cards don't shrink or
// bunch if the user resizes the browser
export const CARD_WIDTH = 360;

const lockedDealRequirements = `This deal's details are only available on the ${getPlanName(
  FareDropPlan.Pro
)} Plan.`;
export const LOCKED_DEAL_MESSAGE = `${lockedDealRequirements} ${
  HIDE_MEMBERSHIP_LINKS
    ? HIDE_UPGRADE_MESSAGE
    : `Please upgrade to view this deal's details.`
} Check back tomorrow for more deals - deals unlock daily. As a member of the ${getPlanName(
  FareDropPlan.Limited
)} Plan, you'll also receive 3 deal notifications per week.`;

export const QUERY_PARAM_HIDE_EXPIRY_MESSAGING = 'hem';

export const SubscriptionPromotionUpgradeCTA = `${
  PROMOTION_IS_SHOPIFY_INTEGRATION
    ? "Hurry! FareDrop's Summer Sale ends soon. "
    : ''
}For a limited time, upgrade at 50% off${
  PROMOTION_IS_SHOPIFY_INTEGRATION
    ? ', get a FREE Travel Day Bundle, and be entered to win a FREE trip to an all-inclusive resort in Punta Cana'
    : '!'
} 🎉🎉`;

export const SubscriptionPromotionRegisterCTA = `${
  PROMOTION_IS_SHOPIFY_INTEGRATION
    ? "Hurry! FareDrop's Summer Sale ends soon. "
    : ''
}For a limited time, get FareDrop Pro for 50% off${
  PROMOTION_IS_SHOPIFY_INTEGRATION
    ? ', get a FREE Travel Day Bundle, and be entered to win a FREE trip to an all-inclusive resort in Punta Cana'
    : '!'
} 🎉🎉`;
